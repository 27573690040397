/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_finance_record_3  报账单表类型3：用于查看私人入账的table
 * @create: 2021-04-12 09:58
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_finance_record_3 extends Table{
    constructor() {
        super();
        this.name = "item_finance_record_3";
        this.CNName = "报销单";
        this.columnArray = [
            new Column("账单归属时间","recordYearMonth",ColumnType.Month,true).setTableView(100),
            new Column("用户名","userName",ColumnType.String,false).setTableView(80),
            new Column("账单标题","title",ColumnType.String,true).setTableView(100),
            new Column("账单描述","content",ColumnType.String,true).setTableView(100),
            new Column("金额","amount",ColumnType.Number,true).setTableView(100),
            new Column("已提交公司报账","reimburstate",ColumnType.String,false)
                .setEnum(["0","1"],["待提交","已提交"],["pink","green"]),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(50)];
    }
}