
<!--
* @program: tyh-oa 
* @author: ly
* @description: oaFooter
* @create: 2021-03-19 17:06
-->
<template>
    <div class="footer non-select">
        Copyright © 2021 贵州中色蓝图科技有限公司 &emsp; 版本：{{$version}}
    </div>
</template>

<script>
    export default {
        name: "oaFooter"
    }
</script>

<style scoped>

</style>