<!--
* @program: office_automation 
* @author: ly
* @component:MonthPicker 
* @description: 
* @create: 2022-03-18 10:47
-->
<template>
    <a-month-picker @change="handleChange" :disabledDate="disabledDate" style="width: 100%" v-model:value="date" :format="dateFormat" />
</template>

<script>
    import moment from "moment";

    export default {
        name: "MonthPicker",
        props:["value","startMonth"],
        emits:["update:value","change"],
        watch:{
            value(){
                this.init()
            }
        },
        data(){
            return{
                dateFormat: 'YYYY-MM',
                date:null,
            }
        },
        methods:{
            init(){
                this.date = this.value?moment(this.value):null;
            },
            disabledDate(current){
                if(this.startMonth){
                    return current.format(this.dateFormat) === this.startMonth || current.isBefore(moment(this.startMonth))
                }
                else{
                    return false
                }
            },
            handleChange(){
                if(this.date){
                    this.$emit("update:value",moment(this.date).locale('zh-cn').format(this.dateFormat));
                    this.$emit("change",moment(this.date).locale('zh-cn').format(this.dateFormat))
                }
                else{
                    this.$emit("update:value",null);
                    this.$emit("change",null);
                }
            }
        },
        mounted() {
            this.init()
        }
    }
</script>

<style scoped>

</style>