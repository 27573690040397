<!--
* @program: office_automation 
* @author: ly
* @component:departmentArrangement 
* @description: 部门任务分配
* @create: 2022-01-06 11:20
-->
<template>
    <div style="display: flex">
        <TagShow :visible="true" color="cyan" tag="部门内部绩效分配">
            <template #content>
                <div style="margin: 10px">由部门绩效池支出</div>
                <FcForm  :form-columns="formColumns" v-model:form-data="formData" :span-col="true" :view-model="viewModel"
                            style="width: 400px">
                    <template #action="{record}">
                        <a-button @click="saveData(record)" type="primary" style="margin: 10px">
                            提交
                        </a-button>
                    </template>
                </FcForm>
                <a-divider v-if="mainTask" style="margin: 0"/>
                <a-button v-if="mainTask" @click="addSubTask" type="primary" danger style="margin: 10px">
                    添加子任务
                </a-button>
            </template>
        </TagShow>
      <TagShow :visible="true" style="width: 400px" color="cyan" tag="绩效分配情况">
        <template #content>
          <div style="margin: 10px">任务下发预下绩效：{{formData.money}}</div>
          <div style="margin: 10px">作业绩效：{{num1}}</div>
          <div style="margin: 10px">负责人绩效：{{num2}}</div>
          <div v-if="mainTask" style="margin: 10px">子任务分配绩效：{{num3}}</div>
          <div style="margin: 10px">部门提成：{{formData.money - num1 - num2 - num3}}</div>
        </template>
      </TagShow>
      <TagShow :visible="true" style="width: 400px" color="green" tag="绩效分配示例说明">
        <template #content>
          <div style="margin: 10px">例如填入情况如下
            <br/>任务下发预下绩效：150000（一般为合同额15%）
            <br/>作业绩效：50000
            <br/>负责人绩效：2500
            <br/>子任务分配绩效：50000
            <br/>部门提成：任务下发预下绩效(150000) - 作业绩效(50000) - 负责人绩效(2500) - 子任务分配绩效(50000) = 47500
          </div>
        </template>
      </TagShow>
    </div>
    <div style="display: flex">
      <TagShow v-if="mainTask" :visible="true" color="red" tag="子任务分配">
        <template #content>
          <ArBaseTable  :argument="argument" :view-model="true" :page-size="3" >
            <template #action="{index,record}">
              <div class="action-column">
                <a-button @click="showDetail(record)"  title="查看" class="action-button" type="link">
                  <SearchOutlined class="pointer"/>
                </a-button>
                <a-button @click="editDetail(record)" title="修改" class="action-button" type="link">
                  <EditFilled class="pointer"/>
                </a-button>
                <a-button @click="honourDetail(record)" title="兑付" class="action-button" type="link">
                  <SwapOutlined class="pointer"/>
                </a-button>
                <a-popconfirm
                    ok-text="是"
                    cancel-text="否"
                    class="action-button"
                    title="确定删除吗?"
                    @confirm="deleteData(index,record)">
                  <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
              </div>
            </template>
          </ArBaseTable>
        </template>
      </TagShow>

    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <ArBaseForm :argument="formArg" :view-model="vModel" ref="bill_form">
            <template #action="{record}">
                <a-button @click="saveData2(record)" type="primary" style="margin: 10px">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>

  <a-modal v-model:visible="visible_df"
           destroyOnClose
           width="50%"
           :footer="null"
           :title="null">
      <depart-payment ref="departPayment" :projectId="projectId" :viewModel="false" :record="taskInfo"/>
  </a-modal>
</template>

<script>
    import FcForm from "../../../components/form/FcForm";
    import api_item_task from "../../../assets/api/itemTask";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import TagShow from "../../_components/TagShow";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import item_task3 from "../../../assets/tables/item_task3";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import {SearchOutlined,DeleteOutlined,EditFilled,SwapOutlined} from "@ant-design/icons-vue";
    import departPayment from "../departPayment/departPayment";

    export default {
        name: "departmentArrangement",
        components: {
          FcForm,TagShow,ArBaseTable,ArBaseForm,
          SearchOutlined,DeleteOutlined,EditFilled,SwapOutlined,departPayment
        },
        props: ["record", "viewModel"],
        computed:{
            mainTask(){
                return this.record.pid === "0"
            },
            num1(){//作业绩效
                try{
                    return this.formData.depManagExp
                }
                catch (e) {
                    return  0
                }
            },
            num2(){//负责人绩效
                try{
                    return this.formData.leaderPercent
                } 
                catch (e) {
                    return  0
                }
            },
            num3(){//子任务绩效
                let num = 0;
                for(let i in this.argument.dataSource){
                    num += this.argument.dataSource[i].money;
                }
                return num;
            }
        },
        data() {
            return {
                table:new item_task3(),
                formData: null,
                formColumns: [
                    new Column("下发部门绩效", "money", ColumnType.Number, false).setDefaultValue(0).setTableView(100),
                    new Column("作业绩效", "depManagExp", ColumnType.Number, true).setDefaultValue(0).setTableView(100),
                    new Column("负责人绩效", "leaderPercent", ColumnType.Number, true).setDefaultValue(0).setTableView(100),
                ],
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                vModel:false,
                visible:false,
              visible_df:false,
              projectId:null,
              taskInfo:null,
            }
        },
        methods: {
            init(){
                let data = {
                    pid:this.record.id
                };
                api_item_task.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            saveData(record) {
                api_item_task.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData2(record){
                record.itemId = this.record.itemId;
                record.pid = this.record.id;
                api_item_task.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.init();
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addSubTask(){
                this.visible = true;
                this.formArg.formData = this.table.getFormData();
                this.vModel = false;

            },
            showDetail(record){
                this.formArg.formData = record;
                this.visible = true;
                this.vModel = true;
            },
            editDetail(record){
                this.formArg.formData = record;
                this.visible = true;
                this.vModel = false;
            },
            honourDetail(record){
              this.projectId = record.itemId;
              this.taskInfo = record;
              this.visible_df = true;
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                api_item_task.delete.requestPOSTUrlParam(this,data,res=>{
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index, 1);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        created() {
            this.formData = {...this.record};
            this.argument.tableColumns = this.table.getTableColumns();
            this.formArg.formColumns = this.table.getFormColumns();
            this.init()

        }
    }
</script>

<style scoped>

</style>