<!--
* @program: office_automation 
* @author: ly
* @component:OtherWorkCalendar
* @description: 其他工作日历
* @create: 2022-03-14 10:56
-->
<template>
    <a-spin :spinning="spinning">
        <a-calendar :locale="locale" @select="showWork" mode="month" v-if="userId" v-model:value="calendarValue">
            <template #headerRender="{ value, type, onChange, onTypeChange }">
                <div style="padding: 10px;display: flex">
                    <a-row justify="flex-start" style="width: 400px;font-size: 16px" type="flex">
                        <a-col>
                            <a-radio-group :value="type" @change="e =>{mode = e.target.value ;return  onTypeChange(e.target.value)}"
                                           button-style="solid">
                                <a-radio-button value="month">工作日志</a-radio-button>
                                <!--                                <a-radio-button value="year">月度绩效考核分配</a-radio-button>-->
                            </a-radio-group>

                        </a-col>
                        <a-col>
                            <a-select
                                    :dropdown-match-select-width="false"
                                    :value="String(value.year())"
                                    @change="newYear => {onChange(value.clone().year(newYear));}"
                                    class="my-year-select"
                            >
                                <a-select-option
                                        :key="String(val)"
                                        class="year-item"
                                        v-for="val in getYears(value)"
                                >
                                    {{ val }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col>
                            <a-select
                                    :dropdown-match-select-width="false"
                                    :value="String(value.month())"
                                    @change="selectedMonth => {onChange(value.clone().month(parseInt(selectedMonth, 10)));}"
                            >
                                <a-select-option
                                        :key="String(index)"
                                        class="month-item"
                                        v-for="(val, index) in getMonths(value)"
                                >
                                    {{ val }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                    <a-button @click="refresh" shape="circle" type="default">
                        <template #icon>
                            <SyncOutlined/>
                        </template>
                    </a-button>
                </div>
            </template>
            <template #dateCellRender="{ current: value }">
                <ul class="events">
                    <li :key="item.id" v-for="item in getWorkList(value)">
                        <a-badge :text="item.workContent" @click="editWork(item,value)" status="success"/>
                    </li>
                </ul>
            </template>
        </a-calendar>
        <div v-else>
            <div style="height: 100%;overflow: hidden">
                <div class="non-select" style="font-size: 60px;margin-top: 300px">
                    欢迎使用
                </div>
                <div class="non-select" style="position: absolute;bottom: 20px;right: 100px;font-size: 16px">
                    版本：{{$version}}
                </div>
            </div>
        </div>
    </a-spin>
    <!--添加日工作记录 -->
    <a-modal :footer="null"
             destroyOnClose
             title="添加工作记录"
             v-model:visible="visible"
             width="500px"
    >
        <FcForm :form-columns="formColumns" :view-model="vModel" v-model:form-data="formData">
            <template #action="{record}">
                <a-button @click="saveData(record)" type="primary">
                    保存
                </a-button>
            </template>
        </FcForm>
    </a-modal>
    <!--日工作记录修改 -->
    <a-modal :footer="null"
             destroyOnClose
             title="工作记录"
             v-model:visible="visible2"
             width="500px"
    >
        <FcForm :form-columns="formColumns" :view-model="vModel" v-model:form-data="formData">
            <template #action="{record}">
                <a-button @click="saveData(record)" type="primary">
                    保存
                </a-button>
                <a-button @click="deleteData(record)" danger type="primary">
                    删除
                </a-button>
            </template>
        </FcForm>
    </a-modal>
</template>

<script>

    import {SyncOutlined} from "@ant-design/icons-vue";
    import item_work_situation2 from "../../../assets/tables/item_work_situation2";
    import workSituation from "../../../assets/api/workSituation";
    import moment from "moment";
    import FcForm from "../../../components/form/FcForm";
    import api_item_task_process_employee from "../../../assets/api/processEmployee";


    export default {
        name: "OtherWorkCalendar",
        props: ["userId"],
        watch: {
            userId() {
                this.init()
            },
        },
        components: {
            SyncOutlined, FcForm
        },
        data() {
            return {
                mode: "month",
                value: null,
                spinning: false,
                locale: {
                    "lang": {
                        "month": "月",
                        "year": "年",
                    },
                    "dateFormat": "YYYY-MM-DD",
                    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
                    "weekFormat": "YYYY-wo",
                    "monthFormat": "YYYY-MM"
                },
                dateFormat: "YYYY-MM-DD",
                monthFormat: "YYYY-MM",
                formColumns: null,//添加工作进度
                formData: null,
                visible: false,
                visible2: false,
                workList: [],
                currentMoment: null,
                calendarValue: null,
                vModel: true
            }
        },
        methods: {
            init() {
                let data = {
                    userId: this.userId,
                    processEmployeeId: "work"
                };
                workSituation.queryAll.requestPOST(this, data, res => {
                    if (res.data.flag) {
                        this.workList = res.data.data;
                    } else {
                        this.workList = [];
                    }
                });
            },
            refresh() {
                this.init();
            },
            getMonths(value) {
                const current = value.clone();
                const localeData = value.localeData();
                const months = [];

                for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                }
                return months;
            },
            getYears(value) {
                const year = value.year();
                const years = [];

                for (let i = year - 10; i < year + 10; i += 1) {
                    years.push(i);
                }
                return years;
            },
            getWorkList(value) {
                let works = [];
                for (let i in this.workList) {
                    if (value.isBetween(moment(this.workList[i].workDate), moment(this.workList[i].workDate).add(1, 'day'))) {
                        works.push(this.workList[i])
                    }
                }
                return works;
            },
            showWork(date) {
                if (this.visible2) {
                    return; //如果已经弹出修改匡则不弹出添加匡
                }
                let now = this.currentMoment.format(this.dateFormat);
                let yesterday = this.currentMoment.clone().add(-1, 'days').format(this.dateFormat);
                let dateFormat = date.format(this.dateFormat); //点击日历的时间
                // if(now === dateFormat || yesterday === dateFormat){
                    this.visible = true;
                    this.formData = new item_work_situation2().getFormData();
                    this.formData.workDate = date.format(this.dateFormat);
                    this.vModel = false
                // } else {
                //     //this.$message.error("目前只允许添加和修改当天的工作记录")
                // }
            },
            doSearch() {
                let data = {
                    userId: this.$store.getters.userInfo.id,
                    processEmployeeId: "work",
                };
                Object.assign(data, this.searchData);
                this.argument.loading = true;
                api_item_task_process_employee.queryAll.requestPOST(this, data, res => {
                    if (res.data.flag) {
                        this.argument.dataSource = res.data.data;
                    } else {
                        this.argument.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                    this.argument.loading = false;
                });
            },
            saveData(record) {
                if (!record.processEmployeeId) {
                    record.processEmployeeId = "work";
                }
                workSituation.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(record) {
                let data = {id: record.id};
                workSituation.delete.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        let arr = this.workList.filter(item => item.id === record.id);
                        let index = this.workList.indexOf(arr[0]);
                        this.workList.splice(index, 1);
                        this.visible2 = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editWork(item, value) {
                let now = this.currentMoment.format(this.dateFormat);
                let yesterday = this.currentMoment.clone().add(-1, 'days').format(this.dateFormat);
                let dateFormat = value.format(this.dateFormat); //点击日历的时间
                // if(now === dateFormat || yesterday === dateFormat){
                    this.formData = item;
                    this.visible2 = true;
                    this.vModel = false;
                // } else {
                //     this.formData = item;
                //     this.visible2 = true;
                //     this.vModel = true;
                // }
            },
        },
        created() {
            this.init();
            this.formColumns = new item_work_situation2().getFormColumns();
            let time = parseInt(localStorage.getItem("time"));
            this.currentMoment = moment(time);
        }
    }
</script>

<style scoped>

</style>