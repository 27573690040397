/**
 * 行政区划管理
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"

export default class area_item extends Table{
    constructor() {
        super();
        this.name = "area_item";
        this.CNName = "行政区划管理";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("区划名称","name",ColumnType.String,true).setTableView(),
            new Column("区划代码","code",ColumnType.String,true).setTableView(),
            new Column("描述","remarks",ColumnType.TextArea,true),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(250)];
    }
}