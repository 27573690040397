<!--
* @program: tyh-oa 
* @author: ly
* @component:billTable2 待入账账单用
* @description: 
* @create: 2021-04-07 14:32
-->
<template>
    <a-table :columns="cols" :dataSource="source"
             :loading="loading"
             :pagination="pagination"
             bordered
             class="myTable"
             :rowClassName = "rowClass"
             rowKey="id">
        <template #actions="{index,record}">
            <div class="action-column">
                <a-button @click="showDetail(index,record)" class="action-button" type="link">
                    <SearchOutlined class="pointer"/>
                </a-button>
                <a-popconfirm
                        @confirm="downloadFlows(record)"
                        cancel-text="否"
                        class="action-button"
                        ok-text="是"
                        title="导出流水？">
                    <a-button class="action-button" type="link">
                        <DownloadOutlined class="pointer"/>
                    </a-button>
                </a-popconfirm>
                <a-tooltip :key="'#fff'" title="入账操作">
                    <a-button v-if="checkAction(record)" @click="showModal(record)" class="action-button" type="link">
                        <SnippetsOutlined class="pointer" style="color: orange;"/>
                    </a-button>
                </a-tooltip>
            </div>
        </template>
        <template #String="{column,record}">
            <type-string :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Number="{column,record}">
            <type-number :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Enum="{column,record}">
            <type-enum :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Date="{column,record}">
            <type-date :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Month="{column,record}">
            <type-month :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Index="{column,record,index}">
            <type-index :column="column" :currentIndex="currentIndex" :index="index" :passedNum="passedNum"
                        :record="record" :viewModel="viewModel"/>
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Category="{column,record}">
            <type-category :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
                <a-input
                        :placeholder="`查询 ${column.title}`"
                        :value="selectedKeys[0]"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        ref="searchInput"
                        style="width: 188px; margin-bottom: 8px; display: block"
                />
                <a-button
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        type="primary"
                >
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                    查询
                </a-button>
                <a-button @click="handleReset(clearFilters)" size="small" style="width: 90px">
                    重置
                </a-button>
            </div>
        </template>
    </a-table>
    <a-modal :footer="null" title="账单入账" v-model:visible="modalVisible">
        <a-form layout="vertical">
            <a-form-item label="选择操作">
                <a-select class="search-item" v-model:value="type">
                    <a-select-option :key="item.id" v-for="item in selectOptions">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="请输入原因" v-if="type==='2' || type ==='3'">
                <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" placeholder="原因" v-model:value="reason"/>
            </a-form-item>
            <a-form-item label="提示" v-if="type==='4'">
                <a-textarea value="选择私人入账的时候，该账单会挂到当前用户下"/>
            </a-form-item>
        </a-form>
        <a-button @click="saveData" class="search-item" type="dashed">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import baseTableView from "../../../components/table/baseTableView";
    import {DownloadOutlined, SearchOutlined, SnippetsOutlined} from "@ant-design/icons-vue"
    import moment from "moment";

    export default {
        name: "billTable",
        mixins: [baseTableView],
        props:["dataSource","tableColumns"],
        computed:{
            source(){
                return this.dataSource;
            },
            cols(){
                return this.tableColumns.filter(column=>{return column.tableView});
            }
        },
        components: {
            SearchOutlined, SnippetsOutlined, DownloadOutlined
        },
        data() {
            return {
                reason: null,
                selectOptions: [
                    {id: "1", name: "对公入账"},
                    {id: "4", name: "私人入账"},
                    //{id:"2",name:"拒绝入账"},
                    {id: "3", name: "撤销入账"}],  //与数据库设置保持一致
                type: null,
                modalVisible: false,
                currentRecord: null
            }
        },
        methods: {
            showDetail(index, record) {
                this.$emit("event", this.$GetEmitData(this.$eventTags.showDetail, record));
                this.currentIndex = index;
            },
            showModal(record) {
                this.currentRecord = record;
                this.modalVisible = true;
            },
            saveData() {
                let data = {};
                if (this.type === "4") {
                    data = {
                        id: this.currentRecord.id,
                        entryState: this.type,
                        transferUser: this.$store.getters.userInfo.id
                    };
                } else {
                    data = {
                        id: this.currentRecord.id,
                        entryState: this.type,
                        remarks: this.reason,
                    };
                }
                this.$emit("event", this.$GetEmitData(this.$eventTags.saveData, data));
            },
            setModalVisible(bool) {
                this.modalVisible = bool;
            },
            visibleChange(visible) {
                if (visible) {
                    this.reason = ""
                }
            },
            downloadFlows(record) {
                this.$emit("event", this.$GetEmitData("downloadFlows", record));
            },
          checkAction(record){
           /* let time = parseInt(localStorage.getItem("time"));
            let YM_now = new moment(time).format("YYYY-MM");
            let day = new moment(time).format("DD");
            let flag = false;
            if(record.recordYearMonth === YM_now){// 当月
              if(day>20){
                flag = true;
              }
            }*/
            return record.submitState === "1" ;
          }

        },
    }
</script>

<style scoped>

</style>