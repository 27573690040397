<!--
* @program: office_automation 
* @author: ly
* @component:ProjectInflow
* @description: 项目收入
* @create: 2022-01-25 11:05
-->
<template>
    <div class="center-flex">
        <span class="statistic-label"> 合计：{{summary}} 元</span>
      <a-divider type="vertical" style="height: 40px; background-color: #7cb305" />
      <span v-if="type == '0'" style="font-size: 16px;color: #3a3a3a;font-weight: 900;"> 回款合计：{{ (summary*0.914).toFixed(2)}} 元</span><span v-if="type == '0'" style="font-size: 5px;color: #faad14;">（手续费比例：8.6%）</span>
        <MonthMoneySum :start-date="record.balanceDate" :records="argument.dataSource" date-seg="collectionTime" sum-seg="collectionAmount" />
    </div>
    <div style="height:600px">
        <ArScrollTable :argument="argument" :view-model="true" :page-size="12" >
            <template #action="{index,record}">
                <div class="action-column">
                    <a-button  type="link"  @click="showDetail(index,record)">
                        <template #icon><SearchOutlined /></template>
                    </a-button>
                </div>
            </template>
        </ArScrollTable>
    </div>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="收款"
             :maskClosable="vModel"
             :footer="null">
        <ArForm ref="ArForm" :view-model="vModel" :argument="formArg" />
    </a-modal>
</template>

<script>
    import { SearchOutlined} from "@ant-design/icons-vue";
    import ArScrollTable from "../../../components/table/ArScrollTable";
    import ArForm from "../../../components/form/ArForm";
    import item_collection_info from "../../../assets/tables/item_collection_info";
    import api_item_collection_info from "../../../assets/api/collectionInfo";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {companyColors, companyIds, companyNames} from "../../../assets/system/companies";
    import MonthMoneySum from "./MonthMoneySum";
    import moment from "moment";
    export default {
        name: "ProjectInflow",
        props:["record","value"],
        components:{
            ArScrollTable,ArForm,SearchOutlined,MonthMoneySum
        },
        watch:{
            record(){
                this.init()
            }
        },
        data(){
            return{
                table:null,
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                vModel:true,
                hasInvoice:true,
                summary:0,
              type:null,// 0:中色账户(虚拟账户);1:其他账户
            }
        },
        methods:{
            init(){
                this.type = this.record.bankName == '中色账户' ? '0' : '1';
                this.table = new item_collection_info();
                this.formArg.formColumns = this.table.getFormColumns();
                if(this.type == '0'){
                  this.argument.tableColumns = [
                    new Column("序号","_index",ColumnType.Index).setTableView(60),
                    new Column("项目名称","itemName",ColumnType.String).setTableView(150),
                    new Column("收款时间","collectionTime",ColumnType.Date).setTableView(80),
                    new Column("收款金额(元)","collectionAmount",ColumnType.Number).setTableView(100),
                    new Column("实际回款金额","sjhkje",ColumnType.Number).setTableView(100),
                    new Column("付款方", "payerName", ColumnType.String).setTableView(200),
                    new Column("收款方", "receiverId", ColumnType.Enum).setTableView(200)
                        .setEnum(companyIds, companyNames,companyColors),
                    new Column("操作","actions","actions").setTableView(80)
                  ];
                  this.formArg.formColumns.push( new Column("实际回款金额","sjhkje",ColumnType.Number).setTableView(100))
                }else{
                  this.argument.tableColumns = [
                    new Column("序号","_index",ColumnType.Index).setTableView(60),
                    new Column("项目名称","itemName",ColumnType.String).setTableView(150),
                    new Column("收款时间","collectionTime",ColumnType.Date).setTableView(80),
                    new Column("收款金额(元)","collectionAmount",ColumnType.Number).setTableView(100),
                    new Column("付款方", "payerName", ColumnType.String).setTableView(200),
                    new Column("收款方", "receiverId", ColumnType.Enum).setTableView(200)
                        .setEnum(companyIds, companyNames,companyColors),
                    new Column("操作","actions","actions").setTableView(80)
                  ];
                }

                this.argument.dataSource = [];

                this.formArg.formData = {};
                this.summary = 0;
                let data = {
                    bankId:this.record.id
                };
                this.argument.loading = true;
                api_item_collection_info.queryAllByObj.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                        for(let i in this.argument.dataSource){
                          if(this.type == '0'){
                            this.argument.dataSource[i].sjhkje = (this.argument.dataSource[i].collectionAmount*0.914).toFixed(2);
                          }

                            let date = this.argument.dataSource[i].collectionTime;
                            if(moment(date).isBefore(moment(this.record.balanceDate))){
                                continue
                            }
                            this.summary += this.argument.dataSource[i].collectionAmount*100;

                        }
                        this.summary = this.summary/100;
                        this.$emit("update:value",{value:this.summary,loading:false});
                    }
                    else{
                        this.$emit("update:value",{value:0,loading:false});
                    }
                })
            },
            refresh(){
                this.init()
            },
            showDetail(index,record){
                this.visible = true;
                this.vModel = true;
                this.formArg.formData = record;
                //this.formArg.formColumns = this.table.getFormColumns();
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>