<!--
* @program: office_automation 
* @author: ly
* @component:OtherOutcomeAudit 
* @description: 资金账户支出审核
* @create: 2022-02-18 11:02
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <a-tabs v-model:activeKey="activeKey" >
                <a-tab-pane  key="first" tab="项目支付审核" >
                    <OtherOutcomeAuditNow />
                </a-tab-pane>
                <a-tab-pane  key="second" tab="审核记录一览" >
                    <OtherOutcomeAuditHistory />
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import OtherOutcomeAuditHistory from "./OtherOutcomeAuditHistory";
    import OtherOutcomeAuditNow from "./OtherOutcomeAuditNow";

    export default {
        name: "OtherOutcomeAudit",
        components:{
            OtherOutcomeAuditHistory, OtherOutcomeAuditNow
        },
        data(){
            return{
                activeKey:"first",
            }
        },
    }
</script>

<style scoped>

</style>