<!--
* @program: TYH_office_automation 
* @author: ly
* @component:dataTypes 
* @description: 
* @create: 2021-12-06 14:17
-->
<template>
    <div>
        
    </div>
</template>

<script>
    
    import typeBooleanCheck from "./typeBooleanCheck";
    import typeDate from "./typeDate";
    import typeEnum from "./typeEnum";
    import typeString from "./typeString";
    import typeNumber from "./typeNumber";
    import typeBoolean from "./typeBoolean";
    import typeMonth from "./typeMonth";
    import typeIndex from "./typeIndex";
    import typeIdReplace from "./typeIdReplace";
    import typeIdReplace2 from "./typeIdReplace2";
    import typeCategory from "./typeCategory";
    import typePreViewFile from "./typePreViewFile";
    import typeAvatar from "./typeAvatar";
    import typeCategoryAssets from "./typeCategoryAssets";
    import typeSelector from "./typeSelector";
    import columnType from "../../assets/tables/parts/column_type";
    import typeObjectReplace from "./typeObjectReplace";
    import typePositionLevel from "./typePositionLevel";
    import typeTextArea from "./typeTextArea";
    import typeRemoteEnum from "./typeRemoteEnum";
    import typeFinancialSubject from "./typeFinancialSubject";
    import typeGiro from "./typeGiro";
    import typeStrArr from "./typeStrArr";

    import typeString2 from "./typeString2";

    import typeSelect from "./typeSelect";// 下拉调用后台值

    export default {
        name: "dataTypes",
        components:{
            typeBooleanCheck, typeDate, typeEnum,
            typeString, typeNumber, typeBoolean,
            typeMonth, typeIndex, typeIdReplace,
            typeIdReplace2, typeCategory, typePreViewFile,
            typeAvatar, typeCategoryAssets,typeSelector,
            typeObjectReplace,typePositionLevel,typeTextArea,
            typeRemoteEnum,typeFinancialSubject,typeGiro,typeStrArr,

          typeString2,typeSelect
        },
        computed:{
            type() {
                switch (this.column.slots.customRender) {
                    case columnType.String:{return "typeString";}
                    case columnType.Number:{return "typeNumber";}
                    case columnType.Date:{return "typeDate"}
                    case columnType.BooleanCheck:{return "typeBooleanCheck"}
                    case columnType.Enum:{return "typeEnum"}
                    case columnType.Boolean:{return "typeBoolean"}
                    case columnType.Month:{return "typeMonth"}
                    case columnType.Index:{return "typeIndex"}
                    case columnType.IdReplace:{return "typeIdReplace"}
                    case columnType.IdReplace2:{return "typeIdReplace2"}
                    case columnType.Category:{return "typeCategory"}
                    case columnType.PreViewFile:{return "typePreViewFile"}
                    case columnType.Avatar:{return "typeAvatar"}
                    case columnType.CategoryAssets:{return "typeCategoryAssets"}
                    case columnType.Selector:{return "typeSelector"}
                    case columnType.ObjectReplace:{return "typeObjectReplace"}
                    case columnType.PositionLevel:{return "typePositionLevel"}
                    case columnType.TextArea:{return "typeTextArea"}
                    case columnType.RemoteEnum:{return "typeRemoteEnum"}
                    case columnType.FinancialSubject:{return "typeFinancialSubject"}
                    case columnType.Giro:{return "typeGiro"}
                    case columnType.StrArr:{return "typeStrArr"}

                    case columnType.String2:{return "typeString2";}
                    case columnType.select:{return "typeSelect";}
                    default :{return "";}
                }
            },
        },
    }
</script>

<style scoped>

</style>