<!--
* @program: tyh-oa 
* @author: ly
* @component:personalBill 
* @description: 
* @create: 2021-04-07 10:49
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
              <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
              <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                <template #icon>
                  <SearchOutlined/>
                </template>
              </a-button>
              <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                <template #icon>
                  <SyncOutlined/>
                </template>
              </a-button>
              <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                <template #icon>
                  <PlusOutlined/>
                </template>
              </a-button>
              <span style="margin-left: 10px">审核状态：</span>
              <a-radio-group @change="radioChange" class="search-item" v-model:value="defaultQuery.approvalState">
                <a-radio :value="'0'">未完成审核</a-radio>
                <a-radio :value="'1'">已完成审核</a-radio>
              </a-radio-group>
            </div>
            <TwoParts pos="transform" @change="handleChange" ref="TwoParts">
              <template #left>
                <ArBaseTable   :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                  <template #action="{index,record}">
                    <div class = "action-column">
                      <a-button @click="showDetail(index,record)"
                                class="action-button" type="link">
                        <SearchOutlined class="pointer"/>
                      </a-button>
                      <a-button @click="editDetail(index,record)"
                                title="编辑账单信息"
                                class="action-button" type="link"
                                v-if="checkAction('edit',record)">
                        <EditFilled class="pointer"/>
                      </a-button>
                      <a-popconfirm
                          @confirm="cancelSubmit(index,record)"
                          cancel-text="否"
                          class="action-button"
                          ok-text="是"
                          title="已审核的账单无法撤销，是否撤销审核？"
                          v-if="checkAction('cancel',record)">
                        <a-tooltip title="撤销审核">
                          <a-button class="action-button" style="color: orange" type="link">
                            <RollbackOutlined class="pointer"/>
                          </a-button>
                        </a-tooltip>
                      </a-popconfirm>
                      <a-popconfirm
                          @confirm="downloadFlows(index,record)"
                          cancel-text="否"
                          class="action-button"
                          ok-text="是"
                          title="导出流水？"
                          v-if="checkAction('download',record)">
                        <a-tooltip title="导出账单流水">
                          <a-button class="action-button" style="color: green" type="link">
                            <DownloadOutlined class="pointer"/>
                          </a-button>
                        </a-tooltip>
                      </a-popconfirm>
                      <a-popconfirm
                          @confirm="submit(index,record)"
                          cancel-text="否"
                          class="action-button"
                          ok-text="提交"
                          title="提交审核？"
                          v-if="checkAction('submit',record)">
                        <a-tooltip title="提交审核">
                          <a-button class="action-button" style="color: green" type="link">
                            <ArrowUpOutlined class="pointer"/>
                          </a-button>
                        </a-tooltip>
                      </a-popconfirm>
                      <a-popconfirm
                          @confirm="deleteData(index,record)"
                          cancel-text="否"
                          class="action-button"
                          ok-text="是"
                          title="删除？"
                          v-if="checkAction('delete',record)">
                        <DeleteOutlined class="pointer" style="color: red"/>
                      </a-popconfirm>
                    </div>
                  </template>
                </ArBaseTable>
                <div style="margin-top: 10%;color: red;font-size: 18px;">【账单超过当月20号后将不允许提交审核，请及时处理个人当月20号之前的账单】</div>
              </template>
              <template #right>
                <personal-tabs :view-model="viewModel" ref="local_tabs"/>
              </template>
            </TwoParts>
        </div>

    </div>
    <a-modal :footer="null"
             :maskClosable="true"
             title="新建账单"
             v-model:visible="visible">
        <div style="display: flex;align-items:center;margin: 10px;justify-content: center;">
            <div>请选择账单月份：</div>
            <MonthPicker style="width: 120px" @change="monthSelect" v-model:value="recordYearMonth" :start-month="nowMonth"/>
        </div>
        <FcForm :form-columns="formColumns" :form-data="formData" :view-model="false">
            <template #action="{record}">
                <a-button @click="saveData(record)" type="primary">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-modal>
</template>

<script>
    import personalTabs from "../personalComponents/personalTabs";
    import item_finance_record from "../../../assets/tables/item_finance_record";
    import api_item_finance_record from "../../../assets/api/record"
    import api_item_finance_record_flow from "../../../assets/api/recordFlow";
    import TwoParts from "../../_components/TwoParts";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    import ICONS from "../../_components/ICONS";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import moment from "moment";
    import MonthPicker from "../../_components/MonthPicker";
    import FcForm from "../../../components/form/FcForm";

    export default {
        name: "personalBill",
        mixins: [ICONS],
        components: {
            personalTabs, TwoParts, ArBaseTable,  SearchBar,
            MonthPicker,FcForm
        },
        data() {
            return {
                table: new item_finance_record(),
                searchOptions: [
                    new SearchSegment("账单标题", "title"),
                ],
                searchData: null,
                defaultQuery: {
                    approvalState: "0"
                },
                authority: [],
                pageSize: 12,
                queryObject: {}, //用于存储查询数据
                visible: false,
                formColumns: null, //提供Form的字段集 this.table.getFormColumns()
                formData: null,//提供Form的formData
                argument: {
                    dataSource: null,//提供table的DataSource
                    tableColumns: null, //提供table的字-段集 this.table.getTableColumns()
                    loading: false,//table的loading状态
                    currentIndex: null //当前选中的条目
                },
                viewModel: true,
                YM:null,
                unSubmitBill:[],
                recordYearMonth:null,
                nowMonth:new moment().format("YYYY-MM"),
                deadline:24 //每个月最迟的账单提交日期
            }
        },
        methods: {
            init() {
                let data = {...this.defaultQuery};
                this.argument.loading = true;
                api_item_finance_record.queryAll.requestPOSTUrlParam(this, data, res => {
                    this.argument.loading = false;
                    if (res.data.flag) {
                        this.argument.dataSource = res.data.data;
                        this.unSubmitBill = res.data.data.filter(item=>item.submitState === "0");
                        /*if(this.unSubmitBill.length >0){
                            /!*this.$message.info("有未提交审核的账单，请确认账单月份，超过该月份将不允许提交审核")*!/
                          this.$message.info("有未提交审核的账单，请确认账单月份，超过当月20号后将不允许提交审核")
                        }*/
                    } else {
                        this.argument.dataSource = [];
                    }
                    this.$refs.arTable.setCurrentPage(1);
                });
            },
            doSearch() {
                let data = {...this.defaultQuery};
                Object.assign(data, this.searchData);
                this.argument.loading = true;
                api_item_finance_record.queryAll.requestPOSTUrlParam(this, data, res => {
                    this.argument.loading = false;
                    if (res.data.flag) {
                        this.argument.dataSource = res.data.data;
                    } else {
                        this.argument.dataSource = [];
                    }
                    this.$refs.arTable.setCurrentPage(1)
                });
            },
            showDetail(index, record) {
                this.viewModel = true;
                this.$emit("click")
                this.$refs.TwoParts.transform();
                let data = {id: record.id};
                api_item_finance_record.queryOne.requestPOSTUrlParam(this, data, res => {
                    this.argument.loading = false;
                    if (res.data.flag) {
                        this.formData = res.data.data;
                        if (this.formData.recordDTOS.length > 0) {
                            this.$refs.local_tabs.handleOpen([
                                {tag: "关联私人入账账单", component: "personalBillAddBills", record: record},
                                {tag: "账单详情", component: "personalBillViewer", record: record}
                            ]);
                        } else {
                            this.$refs.local_tabs.handleOpen([
                                {tag: "账单详情", component: "personalBillViewer", record: record}
                            ]);
                        }
                    }
                });
            },
            editDetail(index, record) {
                this.viewModel = false;

                this.$refs.TwoParts.transform();
                let rzr = this.$store.getters.userInfo.rzr;
                if (rzr) {
                    this.$refs.local_tabs.handleOpen([
                        {tag: "私人入账挂账账单", component: "personalBillAddBills", record: record},
                        {tag: "账单详情", component: "personalBillViewer", record: record}
                    ]);
                } else {
                    this.$refs.local_tabs.handleOpen([
                        {tag: "账单详情", component: "personalBillViewer", record: record}
                    ]);
                }
            },
            cancelSubmit(index, record) {

                let data = {id: record.id};
                api_item_finance_record.cancelSubmit.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        record.submitState = "0";
                        this.$message.success(res.data.msg);
                        this.showDetail(index, record);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            downloadFlows(index, record) {

                let data = {recordId: record.id};
                let fileName = record.title + ".xls";
                api_item_finance_record_flow.exportExcelByRecord.fileDownloadGet(this, data, fileName);
            },
            submit(index, record) {
                record.submitState = "1";
                api_item_finance_record.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        record.submitState = "1";
                        this.init();
                    } else {
                        /*record.submitState = "0";*/
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData(index, record) {

                let data = {id: record.id};
                api_item_finance_record.delete.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        /*this.argument.dataSource.splice(record.index, 1);*/
                      this.init();
                        this.$refs.local_tabs.handleOpen([]);
                        if (record.recordDTOS.length > 0) { //如果关联了账单 则删除关联
                            let ids = [];
                            for (let i in record.recordDTOS) {
                                ids.push(record.recordDTOS[i].id);
                            }
                            let data2 = {ids: ids};
                            api_item_finance_record.unBindPid.requestPOSTUrlParam(this, data2);
                        } else {
                            this.$message.success(res.data.msg);
                        }
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            refresh() {
                this.init()
            },
            addData() {
                this.visible = true;
                this.formData = this.table.getFormData();
                let time = parseInt(localStorage.getItem("time"));
                this.recordYearMonth = new moment(time).format("YYYY-MM");
                this.formData.title = this.recordYearMonth + "月账单";
                this.formData.recordYearMonth = this.recordYearMonth;
                this.formData.content = this.$store.getters.userInfo.name + "的" + this.formData.title;
            },
            radioChange() {
                this.init();
            },
            saveData(record) {
                record.userId = this.$store.getters.userInfo.id;
                record.amount = 0;
                record.invoiceAmount = 0;
                record.noInvoiceAmount = 0;
                api_item_finance_record.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            checkAction(action, record) {
                switch (action) {
                    case "edit": {
                        return record.submitState === "0";
                    }
                    case "submit": {//approvalState 审核状态  entryState 入账状态
                        if(record.approvalState === "0"){//审核未完成
                            if(record.entryState === "3"){//撤销入账
                                return true;
                            }
                            if(record.state === "3"){//被驳回的账单
                                return true;
                            }
                            let time = parseInt(localStorage.getItem("time"));
                            let ym = new moment(time).format("YYYY-MM");
                            let day = new moment(time).format("DD");
                            let flag = record.recordYearMonth === ym && day <= this.deadline;//当月20号之前允许提交
                            return record.submitState === "0" && flag;
                        }
                        else{
                            return false
                        }
                    }
                    case "delete": {
                        return record.submitState === "0";
                    }
                    case "cancel": {
                        let time = parseInt(localStorage.getItem("time"));
                        let day = new moment(time).format("DD");
                        return record.submitState === "1" && day <= this.deadline;
                    }
                    case "download": {
                        return record.submitState === "1";
                    }
                }
            },
            handleChange(type) {
                if (type === "transform") {
                    this.argument.tableColumns = [
                        new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                        new Column("账单标题", "title", ColumnType.String, true).setTableView(150),
                        new Column("操作", "actions", "actions", false).setTableView(100)
                    ]
                } else {
                    this.argument.tableColumns = this.table.getTableColumns();
                }
            },
            monthSelect(){
                this.formData.title = this.recordYearMonth + "月账单";
                this.formData.recordYearMonth = this.recordYearMonth;
                this.formData.content = this.$store.getters.userInfo.name + "的" + this.formData.title;
            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.defaultQuery.userId = this.$store.getters.userInfo.id;
            this.formColumns = this.table.getFormColumns();
            let time = parseInt(localStorage.getItem("time"));
            this.YM = new moment(time).format("YYYY-MM");
            this.init();
        }
    }
</script>

<style scoped>

</style>