/**
 * @program: tyh-oa
 * @author: ly
 * @description: category
 * @create: 2021-04-19 13:50
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class category extends Table{
    constructor(name,CNName) {
        super(name,CNName);
        this.name = "category";
        this.CNName = "报账消耗类别";
        this.columnArray = [
            new Column("类别","categoryName",ColumnType.String,true).setTableView(150),
            new Column("备注","remarks",ColumnType.String,true).setTableView(150),
        ];
        this.actions = new Column("操作","actions","actions",false).setTableView(80);
    }
}