<!--
* @program: office_automation 
* @author: ly
* @component:departmentPayApply 
* @description: 部门发起项目付款申请  与projectOutSourcePay 的部分功能保持一致
* @create: 2022-01-05 14:19
-->
<template>
    <div>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
            <template #icon><SyncOutlined /></template>
        </a-button>
        <a-tooltip v-if="!viewModel"  title="申请项目付款">
            <a-button class="search-item" type="dashed" shape="circle" @click="modalShow">
                <template #icon><PlusOutlined /></template>
            </a-button>
        </a-tooltip>
        <ArBaseTable @showRecord="showDetail1" :page-size="15" :argument="argument1" :view-model="true" :scroll="{x:1000,y:720}">
            <template #action="{index,record}">
                <div class="center-flex">
                    <a-button @click="showDetail1(index,record)"
                              class="action-button" type="link">
                        <SearchOutlined class="pointer"/>
                    </a-button>
                    <a-button @click="editDetail(index,record)"
                              class="action-button" type="link"
                              v-if="checkAction('edit',record)">
                        <EditFilled class="pointer"/>
                    </a-button>
                    <a-popconfirm
                            @confirm="cancelSubmit(index,record)"
                            cancel-text="否"
                            class="action-button"
                            ok-text="是"
                            title="已审核的申请无法撤销，是否撤销审核？"
                            v-if="checkAction('cancel',record)">
                        <a-tooltip title="撤销审核">
                            <a-button class="action-button" style="color: orange" type="link">
                                <RollbackOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </a-popconfirm>
                    <a-popconfirm
                            @confirm="downloadFlows(index,record)"
                            cancel-text="否"
                            class="action-button"
                            ok-text="是"
                            title="导出审批情况表？"
                            v-if="checkAction('download',record)">
                        <a-tooltip title="导出审批情况表">
                            <a-button class="action-button" style="color: green" type="link">
                                <DownloadOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </a-popconfirm>
                    <a-popconfirm
                            @confirm="submit(index,record)"
                            cancel-text="否"
                            class="action-button"
                            ok-text="提交"
                            title="提交审核？"
                            v-if="checkAction('submit',record)">
                        <a-tooltip title="提交审核">
                            <a-button class="action-button" style="color: green" type="link">
                                <ArrowUpOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </a-popconfirm>
<!--                    <a-tooltip v-if="checkAction('auditDone',record)" title="完成支付">-->
<!--                        <a-button @click="auditDone(index,record)" class="action-button" style="color: green" type="link">-->
<!--                            <CheckOutlined class="pointer"/>-->
<!--                        </a-button>-->
<!--                    </a-tooltip>-->
                    <a-popconfirm
                            @confirm="deleteData(index,record)"
                            cancel-text="否"
                            class="action-button"
                            ok-text="是"
                            title="删除？"
                            v-if="checkAction('delete',record)">
                        <DeleteOutlined class="pointer" style="color: red"/>
                    </a-popconfirm>
                </div>
            </template>
        </ArBaseTable>
        <a-modal v-model:visible="visible"
                 destroy-on-close
                 title="项目支付"
                 :maskClosable="false"
                 :footer="null">
            <projectOutSourcePayApply @refresh="init1" :view-model="viewModel" :form-data="formData" :project-id="projectId" />
        </a-modal>
        <a-modal v-model:visible="visible2"
                 :width="1200"
                 destroy-on-close
                 title="详情"
                 :maskClosable="false"
                 :footer="null">
            <ArBaseForm :argument="formArg" :view-model="vModel" />
            <a-divider >审核状态</a-divider>
            <projectOutSourcePayAuditStatus :record="formArg.formData" />
        </a-modal>
    </div>
</template>

<script>
    import ICONS from "../../_components/ICONS";
    import {DeleteOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons-vue";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import projectOutSourcePayApply from "../projectRelateInfo/projectOutSourcePayApply";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import projectOutSourcePayAuditStatus from "../projectRelateInfo/projectOutSourcePayAuditStatus";
    import item_outsourc_pay from "../../../assets/tables/item_outsourc_pay";
    import item_outsourc_pay_apply from "../../../assets/tables/item_outsourc_pay_apply";
    import payApply from "../../../assets/api/payApply";

    export default {
        name: "departmentPayApply",
        props:["projectId","viewModel"],
        mixins: [ICONS],
        components:{
            PlusOutlined,ArBaseTable,projectOutSourcePayApply,DeleteOutlined,SearchOutlined,
            ArBaseForm,projectOutSourcePayAuditStatus
        },
        computed:{
            summary1(){
                let sum=0;
                for(let i in this.argument1.dataSource){
                    sum += this.argument1.dataSource[i].payAmount*100;
                }
                return sum/100;
            }
        },
        data(){
            return{
                page:"0",
                table:new item_outsourc_pay(),
                table1:new item_outsourc_pay_apply(),
                argument1:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                    currentIndex:null, //当前选中的条目
                },
                formArg:{
                    formColumns:[],
                    formData:null
                },
                formData:null,
                visible:false,
                visible2:false,
                vModel:true,
                visible3:false,
                date:null,//支付日期
            }
        },
        methods:{
            init1(){
                this.visible = false;
                this.argument1.loading = true;
                let data = {itemId:this.projectId};
                payApply.queryAll.requestPOST(this,data,res=>{
                    this.argument1.loading = false;
                    if(res.data.flag){
                        this.argument1.dataSource = res.data.data;
                    }
                    else{
                        this.argument1.dataSource = []
                    }
                })
            },
            refresh(){
                this.init1()
            },
            modalShow(){
                this.visible = true;
                this.formData = this.table1.getFormData();
            },
            showDetail1(index,record){
                this.argument1.currentIndex = index;
                this.formArg.formData = record;
                this.formArg.formColumns = this.table1.getFormColumns();
                this.visible2 = true;
                this.vModel = true;
            },
            editDetail(index,record){
                this.argument1.currentIndex = index;
                this.visible = true;
                this.formData = record;
            },
            submit(index,record){
                this.argument1.currentIndex = index;
                let data = {
                    id: record.id
                };
                payApply.submit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init1();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            cancelSubmit(index,record){
                this.argument1.currentIndex = index;
                let data = {
                    id: record.id
                };
                payApply.cancelSubmit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init1();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            downloadFlows(index,record){
                this.argument1.currentIndex = index;
                let data = {id: record.id};
                let fileName = "审核详情.xls";
                payApply.exportById.fileDownload(this, data, fileName);
            },
            deleteData(index,record){
                this.argument1.currentIndex = index;
                let data = {id:record.id};
                payApply.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument1.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                record.itemId = this.projectId;
                payApply.save.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            auditDone(index,record){
                this.argument1.currentIndex = index;
                this.visible3 = true;
                this.formArg.formData = record;
                this.date = null;
            },
            payConfirm(){
                if(!this.date){
                    this.$message.error("请选择支付日期");
                    return
                }
                let data = {
                    id:this.formArg.formData.id,
                    payState:"1",
                    payTime:this.date
                };
                payApply.payConfirm.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible3 = false
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            radioChange(){
                switch (this.page) {
                    case "0":{
                        this.init0();
                        break;
                    }
                    case "1":{
                        this.init1();
                        break;
                    }

                }
            },
            checkAction(action, record) {
                /**
                 * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
                 * approvalState 是否全部审核通过：0：未完成；1：已完成
                 * payState 支付状态：0：未支付；1：已支付
                 */
                switch (action) {
                    case "edit": {
                        return record.submitState === "0" && record.payState === "0" && !this.viewModel;
                    }
                    case "submit": {
                        return record.submitState === "0" && record.payState === "0" && !this.viewModel;
                    }
                    case "delete": {
                        return record.submitState === "0" && record.payState === "0" && !this.viewModel;
                    }
                    case "cancel": {
                        return record.submitState === "1" && record.approvalState === "0" && !this.viewModel;
                    }
                    case "download": {
                        return record.submitState === "1" && record.approvalState === "1";
                    }
                    case "auditDone":{
                        return record.approvalState === "1" && record.payState === "0" && !this.viewModel;
                    }
                }
            },
        },
        created() {
            this.table.actions = [];
            this.argument1.tableColumns = this.table1.getTableColumns();
            this.init1();
        }
    }
</script>

<style scoped>

</style>