<!--
* @program: office_automation 
* @author: ly
* @component:qualityCheckGroup 
* @description: 质检组管理模块
* @create: 2022-01-12 15:39
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="addGroup">
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[30,30]" >
                <template #left>
                    <ArBaseTable :argument="argument" :view-model="true" :page-size="pageSize">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button class="action-button" type="link" @click="showDetail(index,record)">
                                    <SearchOutlined class="pointer" />
                                </a-button>
                                <a-button  class="action-button" type="link" @click="editDetail(index,record)">
                                    <ApartmentOutlined class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(index,record)">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <a-page-header v-if="currentGroup" style="border: 1px solid rgb(235, 237, 240)" title="质检组成员"/>
                    <a-button v-if="currentGroup && !viewModel" class="search-item" type="dashed" shape="circle" @click="addGroupMember">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                    <ArBaseTable v-if="currentGroup" :argument="argument2" :view-model="true" :page-size="pageSize">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData2(index,record)">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTable>
                    <welcome2 v-else/>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             :maskClosable="viewModel"
             title="添加质检组">
        <ArBaseForm :argument="formArg" :view-model="viewModel" >
            <template #action="{record}">
                <a-button @click="saveData(record)" type="primary">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :maskClosable="viewModel"
             :footer="null"
             title="添加质检组成员">
        <ArBaseForm :argument="formArg2" :view-model="viewModel" >
            <template #action="{record}">
                <a-button @click="saveData2(record)" type="primary">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
</template>

<script>
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import TwoParts from "../../_components/TwoParts";
    import {SearchOutlined, SyncOutlined,PlusOutlined,ApartmentOutlined,DeleteOutlined} from "@ant-design/icons-vue";
    import item_qc_group from "../../../assets/tables/item_qc_group";
    import item_qc_group_member from "../../../assets/tables/item_qc_group_member";
    // import qcGroup from "../../../assets/api/qcGroup";
    // import qcGroupMember from "../../../assets/api/qcGroupMember";
    import Welcome2 from "../../welcome2";
    export default {
        name: "qualityCheckGroup",
        components: {
            Welcome2,
            ArBaseTable,ArBaseForm,TwoParts,SyncOutlined,PlusOutlined,
            ApartmentOutlined,DeleteOutlined,SearchOutlined
        },
        data(){
            return{
                viewModel:false,
                pageSize:15,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                argument2:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                visible:false,
                visible2:false,
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                formArg2:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                currentGroup:null
            }
        },
        methods:{
            init(){
                this.currentGroup = null;
                this.visible = false;
                this.visible2 = false;
                this.formArg.formData = null;
                // qcGroup.queryAll.requestPOSTUrlParam(this,{},res=>{
                //     if(res.data.flag){
                //         this.argument.dataSource = res.data.data;
                //     }
                // })
            },
            addGroup(){
                this.visible = true;
                this.formArg.formData = new item_qc_group().getFormData();
                this.viewModel = false;
            },
            addGroupMember(){
               this.visible2 = true;
               this.formArg2.formData = new item_qc_group_member().getFormData();
                this.viewModel = false;
            },
            refresh(){
                this.init()
            },
            showDetail(index,record){
                this.viewModel = true;
                this.currentGroup = record;
                let data = {
                    groupId:record.id
                };
                this.argument2.loading = true;
                // qcGroupMember.queryAll.requestPOSTUrlParam(this,data,res=>{
                //     this.argument2.loading = false;
                //     if(res.data.flag){
                //         this.argument2.dataSource = res.data.data;
                //     }
                //     else{
                //         this.argument2.dataSource = []
                //     }
                // })
            },
            editDetail(index,record){
                this.viewModel = false;
                this.currentGroup = record;
                let data = {
                    groupId:record.id
                };
                this.argument2.loading = true;
                // qcGroupMember.queryAll.requestPOSTUrlParam(this,data,res=>{
                //     this.argument2.loading = false;
                //     if(res.data.flag){
                //         this.argument2.dataSource = res.data.data;
                //     }
                //     else{
                //         this.argument2.dataSource = []
                //     }
                // })
            },
            saveData(record){
                // qcGroup.saveOrUpd.requestPOST(this,record,res=>{
                //     if(res.data.flag){
                //         this.init();
                //         this.$message.success(res.data.msg);
                //     }
                //     else{
                //         this.$message.error(res.data.msg);
                //     }
                // })
            },
            saveData2(record){
                record.groupId = this.currentGroup.id;
                // qcGroupMember.saveOrUpd.requestPOST(this,record,res=>{
                //     if(res.data.flag){
                //         this.visible2 = false;
                //         let data = {
                //             groupId:this.currentGroup.id
                //         };
                //         this.argument2.loading = true;
                //         qcGroupMember.queryAll.requestPOSTUrlParam(this,data,res=>{
                //             this.argument2.loading = false;
                //             if(res.data.flag){
                //                 this.argument2.dataSource = res.data.data;
                //             }
                //             else{
                //                 this.argument2.dataSource = []
                //             }
                //         })
                //     }
                //     else{
                //         this.$message.error(res.data.msg);
                //     }
                // })
            },
            deleteData(index,record){
                let data = {
                    groupId:record.id
                };
                // qcGroupMember.queryAll.requestPOSTUrlParam(this,data,res=>{
                //     if(res.data.flag){
                //         this.$message.error("不能删除已经关联了质检人员的质检组")
                //     }
                //     else{
                //         let data2 = {
                //             id:record.id
                //         };
                //         qcGroup.delete.requestPOSTUrlParam(this,data2,res=>{
                //             if(res.data.flag){
                //                 this.argument.dataSource.splice(index,1);
                //                 this.$message.success(res.data.msg)
                //             }
                //             else{
                //                 this.$message.error(res.data.msg)
                //             }
                //         })
                //     }
                // })
            },
            deleteData2(index,record){
                let data = {
                    id:record.id
                };
                // qcGroupMember.delete.requestPOSTUrlParam(this,data,res=>{
                //     if(res.data.flag){
                //         this.argument2.dataSource.splice(index,1);
                //         this.$message.success(res.data.msg)
                //     }
                //     else{
                //         this.$message.error(res.data.msg)
                //     }
                // })
            }
        },
        created() {
            this.argument.tableColumns = new item_qc_group().getTableColumns();
            this.formArg.formColumns = new item_qc_group().getFormColumns();
            this.argument2.tableColumns = new item_qc_group_member().getTableColumns();
            this.formArg2.formColumns = new item_qc_group_member().getFormColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>