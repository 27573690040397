<!--
* @program: tyh-oa 
* @author: ly
* @component:billStatisticsToolBar
* @description: 
* @create: 2021-06-11 14:03
-->
<template>
    <div  class="search-bar">
        <div style="margin: 8px;font-weight: 900;font-size: 16px"> 选择部门：</div>
        <a-select v-model:value="departmentId" style="width: 200px" class="search-item">
            <a-select-option v-for="{id,fullName} in departments" :key="id" >
                {{fullName}}
            </a-select-option>
        </a-select>
<!--        <div v-is="currentComp" ref="i_selector"/>-->
      <a-range-picker
          :placeholder="['开始日期', '结束日期']"
          format="YYYY-MM"
          :value="value"
          :mode="mode2"
          @panelChange="handlePanelChange2"
          @change="handleChange"
      />
        <a-button class="search-item" style="margin-left: 20px" type="dashed" shape="circle" @click="doSearch">
            <template #icon><SearchOutlined /></template>
        </a-button>
    </div>
</template>

<script>
    import {InfoCircleOutlined,SearchOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import statisticsBar from "../../../../components/toolBar/statisticsBar";
    import api_sys_department from "../../../../assets/api/department";
    import moment from "moment";
    export default {
        name: "billStatisticsToolBar_date",
        mixins:[statisticsBar],
        components:{
            InfoCircleOutlined,SyncOutlined,SearchOutlined
        },
        data(){
            return{
              departments:[{id:"all",fullName:"全部"}],
              currentComp:"monthSelector",
              departmentId:"all",
              dateFormat: 'YYYY-MM',
              mode2:['month', 'month'],
              value:[],
            }
        },
        methods:{
            init(){
                api_sys_department.queryAll.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.departments=this.departments.concat(res.data.data);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            doSearch(){
              let startMonth = null;
              let endMonth = null;
              if(this.value != null && this.value.length > 0){
                startMonth =  new moment(this.value[0]).format("YYYY-MM");
                endMonth = new moment(this.value[1]).format("YYYY-MM");
              }
              if(startMonth == null || endMonth == null){
                this.$message.error("请选择查询时间段！")
                return;
              }
              let arg = {
                departId:this.departmentId,
                startMonth:startMonth,
                endMonth:endMonth,
              };
              if(this.departmentId === "all"){
                delete arg.departId;
              }
                let data = this.$GetEmitData(this.$eventTags.search,arg);
                this.$emit("event",data);
            },

            handlePanelChange2 (val, mode){
              this.value = val;
              this.mode2 = [
                mode[0] === 'date' ? 'month' : mode[0],
                mode[1] === 'date' ? 'month' : mode[1],
              ];
            },
          handleChange(val){
            this.value = val;
          },
          get_value(){
             let arg = [];
            if(this.value != null && this.value.length > 0){
              arg.push(new moment(this.value[0]).format("YYYY-MM"))
              arg.push(new moment(this.value[1]).format("YYYY-MM"))
            }
            return arg;
          },
        },
        created() {
          let time = parseInt(localStorage.getItem("time"));
          let yy = new moment(time).format("YYYY");
          let ym = new moment(time).format("YYYY-MM");
          this.value.push(yy+"-01");
          this.value.push(ym);
          this.init();
          this.doSearch();
        }
    }
</script>

<style scoped>

</style>