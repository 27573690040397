<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityProjectReport 
* @description: 
* @create: 2021-07-09 16:18
-->
<template>
    <FcForm style="width: 600px" :form-data="formData" :form-columns="formColumns" :view-model="viewModel" >
        <template #action="{record}">
            <a-button v-if="!viewModel"  type="primary" @click="saveReport(record)">
                提交
            </a-button>
        </template>
    </FcForm>
</template>

<script>
    import FcForm from "../../../components/form/FcForm";
    import item_qc_report_apply from "../../../assets/tables/item_qc_report_apply";
    import api_item_qc_report_apply from "../../../assets/api/qcReportApply";
    export default {
        name: "qualityProjectReport",
        props:{
            record:{required:true},
            viewModel:{record: false}
        },
        components:{
            FcForm
        },
        data(){
            return{
                table:new item_qc_report_apply(),
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                formData:null,//提供Form的formData
            }
        },
        methods:{
            init(){
                let data = {applyId:this.record.id};
                api_item_qc_report_apply.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.formData = res.data.data[0];
                    }
                    else{
                        this.formData = this.table.getFormData();
                    }
                })
            },
            saveReport(record){
                if(!record.applyId){
                    record.applyId = this.record.id;
                }
                api_item_qc_report_apply.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.formColumns = this.table.getFormColumns();
            this.formData = this.table.getFormData();
            this.init()
        }
    }
</script>

<style scoped>

</style>