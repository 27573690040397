/**
 * @description：
 * @author:rzl
 * @date:2022/7/25 14:30
 **/
import partyInfo from "../../../../assets/api/partyInfo";
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";
import {companyIds, companyNames,companyColors} from "../../../../assets/system/companies";

export default class invoice_record_item extends Table {
    constructor() {
        super();
        this.name = "invoice_record_item";
        this.CNName = "发票信息";
        this.columnArray = [
            new Column("所属项目","itemName",ColumnType.String,true).setTableView(200),
            new Column("开票金额", "invoiceAmount", ColumnType.Number, true).setTableView(100),
            new Column("开票日期", "invoiceTime", ColumnType.Date, true).setTableView(80),
            new Column("收款状态", "collectState", ColumnType.Enum, false).setGroup(1)
                .setEnum(["0", "1", "2"], ["未收款", "已收款", "部分收款"],["red","green","pink"]).setTableView(100),
           /* new Column("付款方", "partyId", ColumnType.ObjectReplace, true).setGroup(1).setTableView(150)
                .setObjectReplaceObject("partyInfo","name", {api: partyInfo.queryAll, tag: "queryText"}),
            new Column("收款方", "companyId", ColumnType.Enum, true).setEnum(companyIds, companyNames,companyColors).setGroup(1).setTableView(150),*/
        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(60)];
    }
}