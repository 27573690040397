<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentAssetsAuditR 
* @description: 
* @create: 2022-06-09 11:18
-->
<template>
    <a-radio-group v-if="viewModel" v-model:value="state" @change="selectChange">
        <a-radio value="2">通过</a-radio>
        <a-radio value="3">未通过</a-radio>
    </a-radio-group>
    <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="pageSize">
        <template #action="{index,record}">
            <div class="action-column">
                <a-popconfirm
                        v-if="!viewModel"
                        ok-text="是"
                        cancel-text="否"
                        title="通过审核吗?"
                        @confirm="accept(index,record)">
                    <a-button  type="link" >
                        <template #icon><CheckOutlined style="color: #00b30c" /></template>
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                        v-if="!viewModel"
                        ok-text="是"
                        cancel-text="否"
                        title="不通过审核吗?"
                        @confirm="refuse(index,record)">
                    <a-button  type="link" >
                        <template #icon><CloseOutlined style="color: red" /></template>
                    </a-button>
                </a-popconfirm>
                <a-tooltip title="查看审核状态">
                    <a-button  type="link" @click="showStep(record)" >
                        <template #icon><SearchOutlined /></template>
                    </a-button>
                </a-tooltip>
            </div>
        </template>
    </ArBaseTableFilter>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="不通过审核">
        <span>原因</span>
        <a-textarea v-model:value="reason" :auto-size="{ minRows: 2, maxRows: 6 }"/>
        <a-button size="small" type="primary" style="margin: 20px" @click="saveAudit">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :width="800"
             :footer="null"
             title="审核状态">
        <DepartmentSteps v-if="visible2" :steps="steps" />
    </a-modal>
</template>

<script>
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import {CheckOutlined, CloseOutlined, SearchOutlined} from "@ant-design/icons-vue";
    import DepartmentSteps from "./DepartmentSteps";
    import deviceBack from "../../../assets/api/deviceBack";
    import assetBackApproval from "../../../assets/api/assetBackApproval";
    import device_back from "../../../assets/tables/device_back";

    export default {
        name: "DepartmentAssetsAuditR",
        props:["dataSource","viewModel"],//viewModel true 显示审核历史   false  显示待审核  dataSource  待审核条目
        emits:["update:dataSource"],
        components:{
            ArBaseTableFilter,CheckOutlined,CloseOutlined,SearchOutlined,
            DepartmentSteps
        },
        data() {
            return {
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                pageSize:15,
                currentRecord:null,
                visible:false,
                reason:null,
                state:"2",
                currentIndex:null,
                steps:null,
                visible2:false
            }
        },
        methods: {
            init() {
                if(this.viewModel){
                    let data = {
                        userId:this.$store.getters.userInfo.id,
                        state:this.state
                    };
                    this.argument.loading = true;
                    deviceBack.queryMyApproval.requestPOSTUrlParam(this,data,res=>{
                        this.argument.loading = false;
                        if(res.data.flag){
                            this.argument.dataSource = res.data.data;
                        }
                        else{
                            this.argument.dataSource = [];
                        }
                    })
                }
                else{
                    this.argument.dataSource = this.dataSource;
                }
            },
            accept(index,record){
                let data = {
                    backId:record.id,
                    userId:this.$store.getters.userInfo.id,
                    state:"2",
                    remarks:""
                };
                assetBackApproval.audit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                        this.$emit("update:dataSource",this.argument.dataSource);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refuse(index,record){
                this.currentRecord = record;
                this.currentIndex = index;
                this.visible = true;
            },
            saveAudit(){
                let data = {
                    backId:this.currentRecord.id,
                    userId:this.$store.getters.userInfo.id,
                    state:"3",
                    remarks:this.reason
                };
                assetBackApproval.audit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(this.currentIndex,1);
                        this.$emit("update:dataSource",this.argument.dataSource);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            selectChange(){
                this.init()
            },
            showStep(record){
                this.steps = [
                    {approvalUserName:"提交审核",state:"0"}
                ];
                let data = {
                    "backId": record.id,
                };
                assetBackApproval.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.steps = this.steps.concat(res.data.data);
                        for(let i in this.steps){
                            switch (this.steps[i].state) {
                                case "0":{
                                    this.steps[i].status = "wait" ; //wait process finish error
                                    break;
                                }
                                case "1":{
                                    this.steps[i].status = "process" ; //wait process finish error
                                    this.steps[0].status = "finish";
                                    break;
                                }
                                case "2":{
                                    this.steps[i].status = "finish" ; //wait process finish error
                                    this.steps[0].status = "finish";
                                    break;
                                }
                                case "3":{
                                    this.steps[i].status = "error" ; //wait process finish error
                                    this.steps[0].status = "finish";
                                    break;
                                }
                            }
                        }
                        this.visible2 = true;
                    }
                })
            }
        },
        created() {
            this.init();
            this.argument.tableColumns = new device_back().getTableColumns();
        }
    }
</script>

<style scoped>

</style>