<!--
* @program: TYH_office_automation
* @author: ly
* @component:projectTaskViewer
* @description: 部门周报
* @create: 2021-06-30 15:06
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
           <TcBaseTable :title="true" :table-columns="tableColumns" :data-source="dataSource"
                             ref="weeks_table"  :scroll="{x:300,y:420}"
                             :view-model="true" :page-size="pageSize">
                    <template #title>
                        <div style="text-align: left">
                            <a-row :gutter="10">
                                <a-col :span="3">
                                    <a-select v-model:value="departmentId"
                                              @change="init">
                                        <a-select-option v-for="department in departments" :key="department.id">
                                            {{ department.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-col>
                                <a-col :span="4">
                                    <a-input v-model:value="itemName"  placeholder="项目名称"  @change="init"/>
                                </a-col>
                                <a-col :span="3">
                                    <a-week-picker v-model:value="queryDate" placeholder="请选择周查询" @change="init"/>
                                </a-col>
                                <a-col :span="3">
                                    <a-select allow-clear
                                            v-model:value="deptApproveState"
                                            label-in-value
                                            placeholder="部门领导审核状态"
                                            style="width: 180px;"
                                            :options="approveStateOptions"
                                            @change="init"
                                    >
                                    </a-select>
                                </a-col>
                                <a-col :span="3">
                                    <a-select allow-clear
                                            v-model:value="deptState"
                                            placeholder="部门领导提交状态"
                                            style="width: 180px;"
                                            :options="deptStateOptions"
                                            @change="init"
                                    >
                                    </a-select>
                                </a-col>
                                <a-col :span="3">
                                    <a-select allow-clear
                                            v-model:value="reviewState"
                                            label-in-value
                                            placeholder="总经办审阅状态"
                                            style="width: 180px;"
                                            :options="reviewStateOptions"
                                            @change="init"
                                    >
                                    </a-select>
                                </a-col>

                                <a-col :span="3">
                                    <a-select allow-clear
                                              v-model:value="jgFlag"
                                              label-in-value
                                              placeholder="是否监管项目"
                                              style="width: 180px;"
                                              :options="jgFlagOptions"
                                              @change="init"
                                    >
                                    </a-select>
                                </a-col>
                            </a-row>
                        </div>
                    </template>
                    <template #action="{index,record}">
                        <div class="action-column">

                            <a-button class="action-button" type="link"  @click="showData(record)">
                                <SnippetsOutlined class="pointer" title="查看"/>
                            </a-button>
                            <a-button v-if="record.reviewState == '0' && record.deptState == '0'" class="action-button" type="link"  @click="editData(record)">
                                <EditFilled class="pointer" title="修改"/>
                            </a-button>
                            <a-button v-if="record.deptApproveState == '0'" class="action-button" type="link"  @click="auditData(record)">
                                <FormOutlined class="pointer" title="审核"/>
                            </a-button>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定提交吗?"
                                    v-if="record.deptState == '0' && record.deptApproveState == '1'"
                                    @confirm="submit(index,record)">
                                <UpCircleOutlined style="color: #154a80" class="pointer" title="提交"/>
                            </a-popconfirm>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定撤回吗?"
                                    v-if="record.deptState == '1' && record.reviewState != '1'"
                                    @confirm="cancel(record)">
                                <RollbackOutlined style="color: #af9e45" class="pointer" title="撤回"/>
                            </a-popconfirm>
                            <a-button  class="action-button" type="link" @click="showDetail(index,record)">
                                <OrderedListOutlined class="pointer" title="项目情况"/>
                            </a-button>
                        </div>
                    </template>
                </TcBaseTable>
        </div>
    </div>

    <a-modal v-model:visible="visible"
             width="900px"
             title="周报">
        <a-form
                name="custom-validation"
                ref="formRef"
                :model="formData"
                :rules="rules"
                style="height:600px;overflow: auto"
                :maskClosable="false"
                :destroyOnClose="true"
        >
            <a-row>
                <a-col :span="12">
                    <a-form-item  label="周报时间" name="selectedDate">
                        <a-week-picker v-if="edit == 'add'" v-model:value="selectedDate" placeholder="请选择周报时间" />
                        <a-input v-else v-model:value="formData.weekDate" :disabled="true" />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item  label="总体完成进度" name="completeRatio">
                        <a-input v-model:value="formData.completeRatio" :disabled="true" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-form-item  label="项目名称" name="itemName">
                <a-input v-model:value="formData.itemName" :disabled="true"/>
            </a-form-item>
            <a-form-item  label="任务名称" name="taskTitle">
                <a-input v-model:value="formData.taskTitle" :disabled="true" />
            </a-form-item>

            <a-form-item  label="本周工作情况" name="thisWeekWork">
                <a-textarea v-model:value="formData.thisWeekWork" :disabled="edit == 'show'" placeholder="本周工作情况" :rows="4" />
            </a-form-item>
            <a-form-item  label="下周工作计划" name="nextWeekWork">
                <a-textarea v-model:value="formData.nextWeekWork" :disabled="edit == 'show'" placeholder="下周工作计划" :rows="4" />
            </a-form-item>
            <a-form-item  label="目前存在问题" name="existProblem">
                <a-textarea v-model:value="formData.existProblem" :disabled="edit == 'show'" placeholder="目前存在的问题" :rows="4" />
            </a-form-item>
            <a-form-item  label="目前应对措施" name="solveMethod">
                <a-textarea v-model:value="formData.solveMethod" :disabled="edit == 'show'" placeholder="目前应对措施" :rows="4" />
            </a-form-item>
            <a-form-item  label="备注" name="remarks">
                <a-textarea v-model:value="formData.remarks" :disabled="edit == 'show'"  :rows="4" />
            </a-form-item>
            <a-form-item v-if="formData.deptApproveState == '1'"  label="部门领导审核备注" name="solveMethod">
                <a-textarea v-model:value="formData.deptApproveRemark" :disabled="true" placeholder="目前应对措施" :rows="4" />
            </a-form-item>

            <a-form-item v-if="formData.deptApproveState == '1'"  label="总经办审阅意见" name="solveMethod">
                <a-textarea v-model:value="formData.reviewSugg" :disabled="true" placeholder="目前应对措施" :rows="4" />
            </a-form-item>

        </a-form>

        <template #footer>
            <a-button type="primary" html-type="submit" v-if="edit != 'show'" @click="saveData">保存</a-button>
        </template>
    </a-modal>

    <a-modal v-model:visible="visibleAudit"
             width="900px"
             :footer="null"
             title="审核">
        <a-form
                name="custom-validation"
                ref="formRef"

                :model="formData"
        >
            <a-form-item  label="审核备注" name="approveRemark">
                <a-textarea v-model:value="approveRemark"   :rows="4" />
            </a-form-item>

            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary"  @click="pass">通过</a-button>
                <a-button type="primary" danger  @click="notGo">不通过</a-button>
            </a-form-item>
        </a-form>
    </a-modal>


    <a-modal v-model:visible="visibleShow"
             width="900px"
             :footer="null"
             title="周报">
        <a-form
                name="custom-validation"
                ref="formRef"
                :model="formData"
                style="text-align: left"
        >
            <a-row>
                <a-col :span="12">
                    <a-form-item  label="周报时间" name="selectedDate">
                        <label>{{formData.weekDate}}</label>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item  label="总体完成进度" name="completeRatio">
                        <label>{{formData.completeRatio}}</label>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                    <a-form-item  label="项目名称" name="thisWeekWork">
                        <label>{{formData.itemName}}</label>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item  label="任务名称" name="thisWeekWork">
                        <label>{{formData.taskTitle}}</label>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                    <a-form-item  label="所属部门" name="thisWeekWork">
                        <label>{{formData.departmentName}}</label>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item  label="周报填写人" name="thisWeekWork">
                        <label>{{formData.createUserName}}</label>
                    </a-form-item>
                </a-col>
            </a-row>



            <a-form-item  label="本周工作情况" name="thisWeekWork">
                <label>{{formData.thisWeekWork}}</label>
            </a-form-item>
            <a-form-item  label="下周工作计划" name="nextWeekWork">
                <label>{{formData.nextWeekWork}}</label>
            </a-form-item>
            <a-form-item  label="目前存在问题" name="existProblem">
                <label>{{formData.existProblem}}</label>
            </a-form-item>
            <a-form-item  label="目前应对措施" name="solveMethod">
                <label>{{formData.solveMethod}}</label>
            </a-form-item>
            <a-form-item  label="备注" name="remarks">
                <label>{{formData.remarks}}</label>
            </a-form-item>

            <a-form-item  label="部门领导审核备注" name="solveMethod">
                <label>{{formData.deptApproveRemark}}</label>
            </a-form-item>

            <a-form-item label="总经办审阅意见" name="solveMethod">
                <label>{{formData.reviewSugg}}</label>
            </a-form-item>
        </a-form>
    </a-modal>


    <a-modal v-model:visible="visibleItem"
             width="1000px"
             :footer="null"
             title="项目情况">
        <div style="height: 700px;overflow: auto">
            <project-tabs  ref="local_tabs"/>
        </div>
    </a-modal>
</template>

<script>
    import moment from "moment"
    import {SnippetsOutlined,FormOutlined,SearchOutlined,OrderedListOutlined,
        EditFilled,UpCircleOutlined,RollbackOutlined} from "@ant-design/icons-vue"
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import weekWork from "../../../assets/api/weekWork";
    import projectTabs from "../projectViewer/projectTabs";
    import itemTask from "../../../assets/api/itemTask";

    export default {
        name: "DepartmentReport",
        components: {
            TcBaseTable,EditFilled,UpCircleOutlined,RollbackOutlined,
            SnippetsOutlined,FormOutlined,projectTabs,SearchOutlined,OrderedListOutlined
        },
        data() {
            return {
                spinning:false,
                pageSize:8,
                loading:false,
                dataSource: [],
                defaultOptions:{
                    weekDate:null,
                },
                visible:false,
                visibleShow:false,
                edit:'',

                queryDate:null,
                itemName:null,
                deptApproveState:null,
                approveStateOptions:[
                    {value: '0',label: '未审核'},
                    {value: '1',label: '通过'},
                    {value: '2',label: '未通过'},
                ],
                deptState:null,
                deptStateOptions:[
                    {value: '0',label: '未提交'},
                    {value: '1',label: '已提交'},
                ],
                reviewState:null,
                reviewStateOptions:[
                    {value: '0',label: '未审阅'},
                    {value: '1',label: '已审阅'},
                ],

                jgFlag:null,
                jgFlagOptions:[
                    {value: true,label: '是'},
                    {value: false,label: '否'},
                ],
                selectedDate: null,
                dateFormat: 'YYYY-WW',
                formData: {
                    id: null,
                    taskId: null,
                    weekDate: null,
                    thisWeekWork: null,
                    nextWeekWork: null,
                    completeRatio: null,
                    existProblem: null,
                    solveMethod: null,

                    reviewSugg: null,
                },
                visibleAudit:false,
                approveRemark:null,
                weekId:null,

                departmentId:null,

                rules:{
                    /* selectedDate:{
                         required: true,
                         message: '请选择周报时间',
                     },*/
                    thisWeekWork:{
                        required: true,
                        message: '请输入本周工作情况',
                    },
                    nextWeekWork:{
                        required: true,
                        message: '请输入下周工作计划',
                    },
                    existProblem:{
                        required: true,
                        message: '请输入目前存在的问题',
                    },
                    solveMethod:{
                        required: true,
                        message: '请输入目前应对措施',
                    },
                },
                rulesAudit:{
                    approveRemark:{
                        required: true,
                        message: '请输入审核备注',
                    },
                },

                visibleItem:false,
                departments:[],
            }
        },
        created() {
            this.departments = this.$store.getters.userInfo.departmentDTOList;
            if(this.departments.length>0){
                this.departmentId = this.departments[0].id;
            }
            this.tableColumns = [
                new Column("序号","_index",ColumnType.Index).setTableView(50),
                new Column("周报时间","weekDate",ColumnType.String,true).setTableView(80),
                new Column("完成进度","completeRatio",ColumnType.Number,true).setTableView(50),
                new Column("项目名称","itemName",ColumnType.String,true).setTableView(120),
              /*  new Column("任务名称","taskTitle",ColumnType.String,true).setTableView(120),*/
               /* new Column("审核状态","deptApproveState",ColumnType.String).setTableView(60)
                    .setEnum(["0","1","2"],["未审核","通过","不通过"],["pink","green","red"]),*/
                new Column("总经办审阅","reviewState",ColumnType.String).setTableView(60)
                    .setEnum(["0","1"],["未审阅","已审阅"],["pink","green"]),
                new Column("本周工作情况","thisWeekWork",ColumnType.TextArea,false).setTableView(110).setRows(4),
                new Column("下周工作计划","nextWeekWork",ColumnType.TextArea,true).setTableView(110).setRows(4),
                new Column("目前存在的问题","existProblem",ColumnType.TextArea,false).setTableView(100).setRows(4),
                new Column("目前应对措施","solveMethod",ColumnType.TextArea,false).setTableView(100).setRows(4),
                new Column("操作","actions","actions",false).setTableView(90),
            ];
            this.init();
        },
        methods: {
            init(){
                this.dataSource = [];
                this.loading = true;
                let data = {
                    page:1,
                    limit:this.pageSize,
                };
                let params = {
                    departId:this.departmentId,
                    writerState:'1',
                    itemName:this.itemName,
                    deptApproveState:this.deptApproveState != null ? this.deptApproveState.value : null,
                    deptState:this.deptState != null ? this.deptState.value : null,
                    reviewState:this.reviewState != null ? this.reviewState.value : null,
                    jgFlag:this.jgFlag != null ? this.jgFlag.value : null,
                }
                if(this.queryDate){
                    let year = moment(this.queryDate).format('YYYY');
                    let week = moment(this.queryDate).week()// .format('WW');
                    params.weekDate = year + '年第' + week + "周";
                }
                weekWork.queryAll.requestPOSTBothParam(this,{formParam:params,urlParam:data},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            editData(record){
                this.edit = 'edit';
                this.visible = true;
                this.formData = {...record};
            },
            showData(record){
                this.edit = 'show';
                this.visibleShow = true;
                this.formData = {...record};
            },
            saveData(){
                if(this.edit == 'add'){
                    let year = moment(this.selectedDate).format('YYYY');
                    let week = moment(this.selectedDate).week()//.format('WW');
                    this.formData.weekDate = year + '年第' + week + "周";
                }

                weekWork.saveOrUpd.requestPOST(this,this.formData,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            submit(index,record){
                this.weekId = record != null ? record.id : this.weekId;
                let data = {
                    id:this.weekId
                }
                weekWork.leaderSubmit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1)
                    }
                });
            },
            cancel(record){
                let data = {
                    id:record.id
                }
                weekWork.cancelLeaderSubmit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                    }
                });
            },
            auditData(record){
                this.approveRemark = null;
                this.weekId = record.id;
                this.visibleAudit = true;
            },
            pass(){
                if(this.approveRemark == null || this.approveRemark == ''){
                    this.$message.info("请输入审核备注");
                    return;
                }
                let data = {
                    id:this.weekId,
                    approveState:'1',
                    approveRemark:this.approveRemark
                }
                weekWork.approve.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.visibleAudit = false;
                        this.submit();
                        //this.init();
                    }
                });
            },
            notGo(){
                if(this.approveRemark == null || this.approveRemark == ''){
                    this.$message.info("请输入审核备注");
                    return;
                }
                let data = {
                    id:this.weekId,
                    approveState:'2',
                    approveRemark:this.approveRemark
                }
                weekWork.approve.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.visibleAudit = false;
                        this.init();
                    }
                });
            },

            showDetail(index,record){
                this.visibleItem = true;
                let data = {
                    id:record.taskId
                }
                itemTask.queryOne.requestPOSTBothParam(this,{urlParam:data,formParam:null},res=>{
                    if(res.data.flag){
                        this.$nextTick(()=>{
                            let info = res.data.data;
                            this.$refs.local_tabs.handleOpen([
                                {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:info.itemId},
                                {tag:"部门任务情况",component:"taskProcessList",viewModel:true,projectId:info.id,record:info},
                            ]);
                        })
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })

            },
        },

    }
</script>

<style scoped>

</style>
