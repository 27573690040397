<!--
*@description：项目汇总分析
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div class="oa-content">
        <div v-if="sum_flag" class="oa-window">
          <div class="ant-row" style="height: 55%;">
            <div class="ant-col-24 divhover" style="box-shadow: 0px 0px 5px #888888;">
              <div ref="chart_item" style="height:100% "></div>
            </div>

          </div>

          <div class="ant-row" style="height: 45%;margin-top: 5px">
            <div class="ant-col-12 divhover" style="box-shadow: 0px 0px 5px #888888;">
              <div ref="chart_jf" style="height:100% "></div>
            </div>

            <div class="ant-col-11.5 divhover" style="box-shadow: 0px 0px 5px #888888;margin-left: 10px;width: 100%">
              <div ref="chart_lw" style="height:100% "></div>
            </div>

          </div>

          <div v-if="loadingVisible" class="loading">
            <a-space>
              <a-spin size="large" />
            </a-space>
          </div>

        </div>
        <div v-else class="oa-window">
          <project-summary ref="projectSummary"  @event="eventHandle"/>
        </div>
    </div>

</template>

<script>
import Icons from "../../common/Icons";
import * as echarts from "echarts";
import { markRaw } from 'vue'
import contractInfo from "../../../assets/api/contractInfo";
import itemInfo from "../../../assets/api/itemInfo";
import {dateFmt} from "../../../assets/utils/dataUtil"
import projectSummary from "./projectSummary";

export default {
    name: "projectSummaryAnalyze",
    components:{
      projectSummary
    },
    mixins:[Icons],
    data(){
        return{
          chartsItem:null,
          chartsJf:null,
          chartsLw:null,
          chartsItemType:null,
          sum_flag:true,
          chartsCurrentData:null,
          loadingVisible:false,
        }
    },
    created() {
      setTimeout(()=>{
        this.init();
      },200);
    },
    methods:{
      init(){
        // 项目统计
        this.init_item();
        // 甲方项目合同金额
        this.init_jf();
        // 劳务协作项目合同金额
        //this.init_lw();
        // 项目类别

      },
      init_item(){
        this.loadingVisible = true;
        itemInfo.queryAll.requestPOST(this,{},res=>{
          let data = [];
          let item_data = [];
          if(res.data.flag){
            let list = res.data.data;
            item_data = res.data.data;
            // 数据按照时间分组
            let dataInfo = this.group(list);
            // 排序
            dataInfo.sort(function(a,b){
              return a.startTime > b.startTime ? 1 : -1
            });
            // 封装成分析需要的格式
            if(dataInfo.length > 0){
              let list_x = [];
              let list_y_jf = [];
              let list_y_lw = [];
              dataInfo.forEach((item,i)=>{
                list_x.push(item.startTime);
                list_y_jf.push(item.jfNum);
                list_y_lw.push(item.lwNum);
              })
              data.push(list_x);
              data.push(list_y_jf);
              data.push(list_y_lw);
            }

          }
          this.eachar_item(data);
          this.init_itemType(item_data);
          this.loadingVisible = false;
        })
      },
      eachar_item(data){
        if(this.chartsItem){
          this.chartsItem.clear();
        }
        let option = null;
        if(data.length <= 0){
          option = {
            title: {
              extStyle: {
                color: "grey",
                fontSize: 20

              },
              textStyle:{
                color:"#FFD700",
              },

              text: "项目统计：暂无数据",

              left: "center",

              top: "center"

            },
          }
        }else{
          option  = {
            title:{
              text:"项目统计",
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter: (params) => {
                var result = params[0].axisValueLabel + '<br>'
                params.forEach(function (item) {
                  if (item.value) {
                    result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                  }
                })
                // 保留数据
                this.chartsCurrentData = params[0]
                // 返回mousemove提示信息
                return result
              }
            },
            toolbox: {
              show: true,
              feature: {
                magicType: { show: true, type: ['line', 'bar'] },
                saveAsImage: { show: true },
                /*myTool:{ //自定义方法 配置   我这是为了实现 饼图切换为柱状图
                  show : true,
                  title : '详情',
                  icon: 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891',
                  onclick:()=>{
                    this.show_info();
                  }
                }*/
              }
            },
            legend: {

            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                //data: ['2016', '2017', '2018', '2019', '2020', '2021', '2022'],
                data:data[0],
                name:'年',
                axisLine: {//x轴线的颜色以及宽度
                  show: true,
                  lineStyle: {
                    color: "blue",
                    width: 0,
                    type: "solid",
                  },

                },
                axisLabel: {
                  textStyle: {
                    color: 'blue',
                    fontSize:20,

                  }
                },
                nameTextStyle:{
                  color:'#A9A9A9',
                  padding:[100,0,0,0],		//表示[上,右,下,左]的边距
                },
                triggerEvent:true,
              }
            ],
            yAxis: [
              {
                type:'value',
                name:'(份)',
                nameTextStyle:{
                  color:'#A9A9A9',
                  padding:[100,0,0,15],		//表示[上,右,下,左]的边距
                }
              }
            ],
            series: [
              {
                name: '甲方合作项目',
                type: 'line',
                emphasis: {
                  focus: 'series'
                },
                label: {
                  show: true,
                },
                //data: [320, 332, 301, 334, 390, 330, 320]
                data:data[1],
              },

              {
                name: '劳务协作项目',
                type: 'line',
                emphasis: {
                  focus: 'series'
                },
                label: {
                  show: true,
                },
                //data: [150, 232, 201, 154, 190, 330, 410]
                data:data[2]
              },
            ]
          };
        }

        this.chartsItem = markRaw(echarts.init(this.$refs.chart_item))//获取dom元素;
        this.chartsItem.setOption(option);
        /*this.chartsItem.on('click', (params)=> {
          this.sum_flag = false;
          if(params.componentType == "xAxis"){
            setTimeout(()=>{
              this.$refs.projectSummary.showInit(params.value);
            },200)
          }else{
            setTimeout(()=>{
              this.$refs.projectSummary.showInit(params.name);
            },200)
          }
        });*/

        /** 扩大点击范围 start **/
        // 防止点击调用多次
        this.chartsItem.getZr().off('click')
         // 鼠标移动到阴影范围 setCursorStyle('pointer')
        this.chartsItem.getZr().on('mousemove', param => {
           var pointInPixel= [param.offsetX, param.offsetY];
           if (this.chartsItem.containPixel('grid',pointInPixel)) {//若鼠标滑过区域位置在当前图表范围内 鼠标设置为小手
             this.chartsItem.getZr().setCursorStyle('pointer')
           }else{
             this.chartsItem.getZr().setCursorStyle('default')
           }
         })
        this.chartsItem.getZr().on('click', params => {

          // 获取到鼠标点击位置：
          const pointInPixel = [params.offsetX, params.offsetY]
          // 判断点击位置是否在显示图形区域
          if (this.chartsItem.containPixel('grid', pointInPixel)) {
            this.sum_flag = false;
            setTimeout(()=>{
              this.$refs.projectSummary.showInit(this.chartsCurrentData.axisValue);
            },200)
          }
        })
        /** 扩大点击范围 end **/

      },
      show_info(){
        this.$refs.projectSummary.showNow();
      },
      init_jf(){
        contractInfo.statistics.requestPOSTUrlParam(this,{itemType:'1'},res=>{
          let data = [];
          if(res.data.flag){
            let list = res.data.data;
            if(list.length > 0){
              let list_x = [];
              let list_y_sumAmount = [];
              let list_y_colleAmount = [];
              let list_y_notColleAmount = [];
              list.forEach((item,i)=>{
                list_x.push(item.year);
                let sumAmount = (item.sumAmount/10000).toFixed(2);
                let colleAmount = (item.colleAmount/10000).toFixed(2);
                let notColleAmount = (item.notColleAmount/10000).toFixed(2);
                list_y_sumAmount.push(sumAmount);
                list_y_colleAmount.push(colleAmount);
                list_y_notColleAmount.push(notColleAmount);
              })
              data.push(list_x);
              data.push(list_y_sumAmount);
              data.push(list_y_colleAmount);
              data.push(list_y_notColleAmount);
            }

          }
          this.eachar_jf(data);
        })
      },
      eachar_jf(data){
        if(this.chartsJf){
          this.chartsJf.clear();
        }
        let option = null;
        if(data.length <= 0){
          option = {
            title: {
              extStyle: {
                color: "grey",
                fontSize: 20

              },
              textStyle:{
                color:"#FFD700",
              },

              text: "甲方项目合同收款统计：暂无数据",

              left: "center",

              top: "center"

            },
          }
        }else{
          option  = {
            title:{
              text:"甲方项目合同收款统计",
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              selected: {
                '合同已收金额':false,
                '合同未收金额':false,
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                //data: ['2016', '2017', '2018', '2019', '2020', '2021', '2022'],
                data:data[0],
                name:'年',
                axisLine: {//x轴线的颜色以及宽度
                  show: true,
                },
              }
            ],
            yAxis: [
              {
                type: 'value',
                name:"金额(万元)",
                nameTextStyle:{
                  color:'#A9A9A9',
                  padding:[100,0,0,15],		//表示[上,右,下,左]的边距
                }
              }
            ],
            series: [
              {
                name: '合同总金额',
                type: 'bar',
                emphasis: {
                  focus: 'series'
                },
                label: {
                  show: true,
                  rotate: -30,
                },
                //data: [320, 332, 301, 334, 390, 330, 320]
                data:data[1],
              },

              {
                name: '合同已收金额',
                type: 'bar',
                stack: 'Ad',
                emphasis: {
                  focus: 'series'
                },
                label: {
                  show: true,
                  rotate: -30,
                },
                //data: [150, 232, 201, 154, 190, 330, 410]
                data:data[2]
              },

              {
                name: '合同未收金额',
                type: 'bar',
                stack: 'Ad',
                emphasis: {
                  focus: 'series'
                },
                label: {
                  show: true,
                  rotate: -30,
                },
                //data: [150, 232, 201, 154, 190, 330, 410]
                data:data[3]
              },

            ]
          };
        }
        this.chartsJf = markRaw(echarts.init(this.$refs.chart_jf));
        this.chartsJf.setOption(option);

      },
      init_lw(){
        contractInfo.statistics.requestPOSTUrlParam(this,{itemType:'2'},res=>{
          let data = [];
          if(res.data.flag){

            let list = res.data.data;
            if(list.length > 0){
              let list_x = [];
              let list_y_sumAmount = [];
              let list_y_colleAmount = [];
              let list_y_notColleAmount = [];
              list.forEach((item,i)=>{
                list_x.push(item.year);
                list_y_sumAmount.push(item.sumAmount);
                list_y_colleAmount.push(item.colleAmount);
                list_y_notColleAmount.push(item.notColleAmount);
              })
              data.push(list_x);
              data.push(list_y_sumAmount);
              data.push(list_y_colleAmount);
              data.push(list_y_notColleAmount);
            }

          }
          this.eachar_lw(data);
        })
      },
      eachar_lw(data){
        if(this.chartsLw){
          this.chartsLw.clear();
        }
        let option = null;
        if(data.length <= 0){
          option = {
            title: {
              extStyle: {
                color: "grey",
                fontSize: 20

              },
              textStyle:{
                color:"#FFD700",
              },

              text: "劳务项目合同收款统计：暂无数据",

              left: "center",

              top: "center"

            },
          }
        }else{
          option  = {
            title:{
              text:"劳务项目合同收款统计",
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {

            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                //data: ['2016', '2017', '2018', '2019', '2020', '2021', '2022'],
                data:data[0],
                name:'年',
                axisLine: {//x轴线的颜色以及宽度
                  show: true,
                },
                nameTextStyle:{
                  color:'#A9A9A9',
                  padding:[100,0,0,0],		//表示[上,右,下,左]的边距
                },
                triggerEvent:true,
              }
            ],
            yAxis: [
              {
                type: 'value',
                name:"金额(￥)",
                nameTextStyle:{
                  color:'#A9A9A9',
                  padding:[100,0,0,15],		//表示[上,右,下,左]的边距
                }
              }
            ],
            series: [
              {
                name: '合同总金额',
                type: 'bar',
                emphasis: {
                  focus: 'series'
                },
                label: {
                  show: true,
                  rotate: -30,
                },
                //data: [320, 332, 301, 334, 390, 330, 320]
                data:data[1],
              },

              {
                name: '合同已收金额',
                type: 'bar',
                stack: 'Ad',
                emphasis: {
                  focus: 'series'
                },
                label: {
                  show: true,
                  rotate: -30,
                },
                //data: [150, 232, 201, 154, 190, 330, 410]
                data:data[2]
              },

              {
                name: '合同未收金额',
                type: 'bar',
                stack: 'Ad',
                emphasis: {
                  focus: 'series'
                },
                label: {
                  show: true,
                  rotate: -30,
                },
                //data: [150, 232, 201, 154, 190, 330, 402.10]
                data:data[3]
              },

            ]
          };
        }
        this.chartsLw = markRaw(echarts.init(this.$refs.chart_lw));
        this.chartsLw.setOption(option);

      },
      init_itemType(data){
        let newArr = [];
        data.forEach((item,i)=>{
          let index = -1;
          let itemCategory = item.itemCategory;
          let isExists = newArr.some((newItem,j)=>{
            if(itemCategory == newItem.itemCategory){
              index = j;
              return true;
            }
          })
          if(!isExists){
            //项目分类：0：其他；1：系统研发；2：工程测量；3：规划编制；4：数据处理，不够再往后加
            let name = "";
            switch (itemCategory){
              case "1":{
                name = "系统研发"
                break;
              }
              case "2":{
                name = "工程测量"
                break;
              }
              case "3":{
                name = "规划编制"
                break;
              }
              case "4":{
                name = "数据处理"
                break;
              }
              case "0":{
                name = "其他"
                break;
              }
              default:{
                name = "未知";
              }
            }
            newArr.push({
              itemCategory:itemCategory,
              name:name,
              value:1
            })
          }else{
            newArr[index].value += 1;
          }
        })
        this.eachar_itemType(newArr);
      },
      eachar_itemType(data){
        if(this.chartsItemType){
          this.chartsItemType.clear();
        }
        let option = null;
        if(data.length <= 0){
          option = {
            title: {
              extStyle: {
                color: "grey",
                fontSize: 20

              },
              textStyle:{
                color:"#FFD700",
              },

              text: "项目类别统计",

              left: "center",

              top: "center"

            },
          }
        }else{
          option = {
            title: {
              text: '项目类别统计',
            /*  subtext: 'Fake Data',*/
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '项目类别统计',
                type: 'pie',
                radius: '50%',
                data: data,
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      formatter: '{b} : {c} ({d}%)'  //展示的文字   类型+百分比
                    },
                    labelLine: { show: true }
                  }
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
        }
        this.chartsJf = markRaw(echarts.init(this.$refs.chart_lw));
        this.chartsJf.setOption(option);
      },

      group(data){
        let newArr = [];
        data.forEach((item,i)=>{
          let index = -1;
          let date = dateFmt(item.startTime,"YYYY");
          let isExists = newArr.some((newItem,j)=>{
            if(date == newItem.startTime){
              index = j;
              return true;
            }
          })
          let jfNum = 0;
          let lwNum = 0;

          if(!isExists){
            if(item.itemType == '1'){
              jfNum = jfNum + 1;
            }else if(item.itemType == '2'){
              lwNum = lwNum + 1;
            }
            newArr.push({
              startTime:date,
              jfNum:jfNum,
              lwNum:lwNum,
            })
          }else{
            if(item.itemType == '1'){
              jfNum = newArr[index].jfNum + 1;
            }else if(item.itemType == '2'){
              lwNum = newArr[index].lwNum + 1;
            }
            newArr[index].jfNum = jfNum;
            newArr[index].lwNum = lwNum;
          }
        })
        return newArr;
      },
      eventHandle({type,arg}){
        switch (type) {
          case this.$eventTags.back:{
            this.sum_flag = true;
            this.init();
            break;
          }
        }
      },
    }
}
</script>

<style scoped>
.loading{
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.5);
  display:flex;
  justify-content:center;
  align-items:center;
}

</style>