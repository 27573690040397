<!--
* @program: TYH_office_automation 
* @author: ly
* @component:categoryChart 
* @description: 
* @create: 2021-06-18 11:16
-->
<template>
    <v-chart v-if="setting.datas.length>0" @click="handleClick"  :autoresize="true" class="chart" :option="option" />
    <div v-else style="text-align: center;font-size: 24px;margin: 40px">没有查询到相关数据</div>
    <a-drawer v-model:visible="visible"
              width="1200px"
              placement="right"
              :closable="false"
    >
        <div>合计：{{sum.toFixed(2)}}</div>
        <TcBaseTableFilter :download="true" :table-columns="tableColumns" :data-source="ds" :loading="loading" :page-size="20" :view-model="true" />
    </a-drawer>
</template>

<script>
    import VChart, { THEME_KEY } from "vue-echarts";
    import { ref } from "vue";
    import recordFlow from "../../../assets/api/recordFlow";
    import moment from "moment";
    import TcBaseTableFilter from "../../../components/table/TcBaseTableFilter";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    export default {
        name: "categoryChart",
        components: {
            VChart,TcBaseTableFilter
        },
        provide: {
            [THEME_KEY]: "light"
        },
        props:["setting","title","period","allUsers"],
        computed:{
            ds(){
                return this.dataSource.filter(item=>{
                    let time = new moment(item.flowTime);
                    return time.isBetween(new moment(this.period[0]).startOf("month").format("YYYY-MM-DD"),new moment(this.period[1]).endOf("month").format("YYYY-MM-DD"),null, '[]')
                })
            },
            sum(){
                let sum = 0;
                for(let i in this.ds){
                    if(this.ds[i].applyAmount){
                        sum += this.ds[i].applyAmount;
                    }
                    else if(this.ds[i].payAmount){
                        sum += this.ds[i].payAmount
                    }
                }
                return sum;
            }
        },
        setup (props) {
            //type,legend,xAxis,yAxis,series}
            const datas = ref(props.setting.datas);
            const ititles = ref(props.setting.titles);
            const chartTitle  = ref(props.title);
            const titles = [{
                text: chartTitle,
                left: 'center',
                textStyle: {
                    color: '#000',
                    fontWeight: 'normal',
                    fontSize: 20
                }}
            ];
            const getPiePosition = (idx)=>{
                let index = idx;
                let row = Math.floor(index/3);
                let pos = index%3;
                let left = pos*33.3;
                let top = row*30+5;
                return {
                    left:left,top:top
                }
            };
            const getTitlePosition = (idx)=>{
                let index = idx;
                let row = Math.floor(index/3);
                let pos = index%3;
                let left = pos*33.3+16;
                let top = row*30+35;
                return {
                    left:left,top:top
                }
            };
            for(let i in ititles.value){
                let {left,top} = getTitlePosition(i);
                let title =  {
                    text: ititles.value[i],
                    left: left+'%',
                    top: top+'%',
                    textAlign: 'center'
                };
                titles.push(title);
            }
            const option =ref({
                title: titles,
                series: datas.value.map(function (data, idx) {
                    let{top,left} = getPiePosition(idx);
                    return {
                        type: 'pie',
                        radius: [20, 80],
                        top: top + '%',
                        left: left+"%",
                        height: '40%',
                        width: 33.3+'%',
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        label: {
                            alignTo: 'edge',
                            formatter: '{name|{b}}\n{time|{c} 元}',
                            minMargin: 5,
                            edgeDistance: 10,
                            lineHeight: 15,
                            rich: {
                                time: {
                                    fontSize: 10,
                                    color: '#999'
                                }
                            }
                        },
                        labelLine: {
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 80
                        },
                        data: data
                    }
                })
            });
            const visible = ref(false);
            const loading = ref(false);
            const dataSource = ref([]);
            const tableColumns = ref([]);
            return { option,visible ,dataSource,tableColumns,loading};
        },
        methods:{
            handleClick(event){
                if(event.data.query){
                    this.tableColumns = [
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("用户名","createUserName",ColumnType.String,true).setTableView(80),
                        new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(200),
                        new Column("支出时间","flowTime",ColumnType.Date,true).setTableView(80),
                        new Column("项目支付","itemExpenditure",ColumnType.Boolean,true).setTableView(80),
                        new Column("项目","itemName",ColumnType.String,true).setTableView(200),
                        new Column("发票金额(元)","invoiceAmount",ColumnType.Number,true).setTableView(80),
                        new Column("报销金额(元)","applyAmount",ColumnType.Number,true).setTableView(80),
                        new Column("备注","remarks",ColumnType.String,true).setTableView(150)
                    ];
                    this.visible = true;
                    let data = {
                        categoryId:event.data.id,
                        entryState:"1"
                    };
                    this.loading = true;
                    recordFlow.queryAll.requestPOSTUrlParam(this,data,res=>{
                        this.loading =false;
                        if(res.data.flag){
                            this.dataSource = res.data.data;
                            for(let i in this.dataSource){
                                let createUser = this.dataSource[i].createUser;
                                let arr = this.allUsers.filter(item=>item.id === createUser);
                                if(arr.length>0){
                                    this.dataSource[i].createUserName = arr[0].name;
                                }
                                else{
                                    this.dataSource[i].createUserName = "已删除";
                                }
                            }
                        }
                        else{
                            this.dataSource = []
                        }
                    })
                }
                else{
                    if(event.data.name === "项目外协支付"){
                        this.tableColumns = [
                            new Column("序号","_index",ColumnType.Index).setTableView(60),
                            new Column("项目","itemName",ColumnType.String,true).setTableView(200),
                            new Column("付款合同名称","contractName",ColumnType.String,true).setTableView(200),
                            new Column("收款单位/个人","outSourceName",ColumnType.String,true).setTableView(100),
                            new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue("0").setTableView(100),
                            // new Column("付款公司", "payCompanyId", ColumnType.Enum, true).setTableView(150)
                            //     .setEnum(companyIds, companyNames,companyColors),
                            new Column("付款部门", "applyDepartName", ColumnType.String, true).setTableView(100),
                            //new Column("支付备注","remarks",ColumnType.String,true).setTableView(100),
                            new Column("支付描述","payReason",ColumnType.String,true).setTableView(100),
                        ]
                    }
                    else{
                        this.tableColumns = [
                            new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                            // new Column("支付类型","type",ColumnType.Enum).setTableView(150)
                            //     .setEnum(["1","2","3","4"],["银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"],["cyan","blue","green","red"]),
                            new Column("项目","itemName",ColumnType.String,true).setTableView(200),
                            new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(150),
                            new Column("支付金额","payAmount",ColumnType.Number,true).setTableView(100),
                            new Column("支付日期","payDate",ColumnType.Date,true).setTableView(100),
                            new Column("支付账户","payBankNum",ColumnType.String,true).setTableView(200),
                            new Column("说明","remarks",ColumnType.String,true).setTableView(200),
                        ]
                    }
                    this.visible = true;
                    this.dataSource = event.data.children;
                    for(let i in this.dataSource){
                        let createUser = this.dataSource[i].createUser;
                        let arr = this.allUsers.filter(item=>item.id === createUser);
                        if(arr.length>0){
                            this.dataSource[i].createUserName = arr[0].name;
                        }
                        else{
                            this.dataSource[i].createUserName = "已删除";
                        }
                    }
                }

            },

        }
    }
</script>

<style scoped>
    .chart {
        height: 100%;
    }
</style>
