/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_task  用于任务分配管理
 * @create: 2021-04-16 16:42
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import api_sys_department from "../api/department";
import userInfo from "../api/userInfo";
export default class item_task extends Table{
    constructor() {
        super();
        this.name = "item_task";
        this.CNName = "任务管理";
        this.columnArray = [
            new Column("任务类型","pid",ColumnType.Enum,false).setTableView(100)
                .setEnum(["0"],["主任务","子任务"],["green","red"]),
            new Column("项目名称","itemName",ColumnType.String).setTableView(200),
            new Column("任务标题","taskTitle",ColumnType.String,true).setTableView(200),
            new Column("任务内容","content",ColumnType.String,true)
                .setDefaultValue("按照合同约定完成项目内容"),
            new Column("分配部门","departmentId",ColumnType.RemoteEnum,true)
                .setRemoteObject("departmentName",{api:api_sys_department.queryAll,tag:"queryText"}),
            new Column("任务开始时间","startDate",ColumnType.Date,true).setTableView(100),
            new Column("预计完成时间","endDate",ColumnType.Date,true).setTableView(100),
            new Column("进度","completeRatio",ColumnType.Number).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("实际完成时间","completeDate",ColumnType.Date,false),
            new Column("完成状态","state",ColumnType.Enum,false)
                .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"])
                .setTriggerOtherColumn(["1","2"],(formData,cols)=>{
                    cols.filter(col=>{return col.dataIndex==="describe"})[0]?.setVisible(true);
                },(formData,cols)=>{
                    cols.filter(col=>{return col.dataIndex==="describe"})[0]?.setVisible(false);
                }),
            new Column("负责人","taskLeader",ColumnType.IdReplace,true).setTableView(100)
                .setIdReplaceObject("taskLeaderName",{api:userInfo.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("完成情况说明","describe",ColumnType.String,false).setVisible(false),
            // new Column("任务分配绩效","money",ColumnType.Number,false).setDefaultValue(0).setTableView(100),
            // new Column("绩效分配确认","moneyConfirm",ColumnType.Boolean,true)
            //     .setDefaultValue(false),
            // new Column("作业绩效","depManagExp",ColumnType.Number,false).setTableView(100),
            // new Column("负责人绩效","leaderPercent",ColumnType.Number,false).setTableView(100),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}