import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: item_other_pay
 * @create: 2022-02-18 14:36
 * //支出类型（1：卡间相互间转账；2：取现；3：存入，4：对外支出）
 **/

export default class item_other_pay extends Table{
    constructor() {
        super();
        this.name = "item_other_pay";
        this.CNName = "其他支付";
        this.columnArray = [
            new Column("支付类型","type",ColumnType.Enum)
                .setEnum(["1","2","3","4"],["银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"],["cyan","blue","green","red"]),
            new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(100),
            new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
            new Column("支付日期","payDate",ColumnType.Date,true).setTableView(80),
            new Column("支付账户","payBankId",ColumnType.Selector,true).setTableView(200)
                .setSelectorObject("payBankNum",[]),//payBankNum+payBankName
            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                .setSelectorObject("receiveBankNum",[]),
            new Column("说明","remarks",ColumnType.String,true),
            new Column("审核状态","approvalState",ColumnType.Enum).setEnum(["0","1"],["待审核","已审核"],["red","green"]).setTableView(80),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(60)];

    }
}