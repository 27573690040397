<!--
* @program: office_automation 
* @author: ly
* @component:UserPickerAll
* @description: 部门员工选择器
* @create: 2022-03-03 14:04
-->
<template>
    <div >
        <a-select
                v-model:value="selectIds"
                mode="multiple"
                style="width: 300px"
                placeholder="选择人员"
                :options="options"
                @change="handleChange"
                :filterOption="filterOption"
        >
        </a-select>
    </div>
</template>

<script>
    import userInfo from "../../assets/api/userInfo";
    export default {
        name: "UserPickerAll",
        props:["value"],
        emits:["update:value"],
        data(){
            return{
                options:[],
                selectIds:[],

            }
        },
        methods:{
            init(){
                this.options = [];
                this.$emit("update:value",this.selectIds);
                let data = {
                    disableState:"1",//["离职","在职"]
                    loginState:"0"//["正式员工","试用期","实习生"]
                };
                userInfo.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        res.data.data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                        for(let i in res.data.data){
                            this.options.push({value:res.data.data[i].id,label:res.data.data[i].name})
                        }
                    }
                })
            },
            handleChange(){
                this.$emit("update:value",this.selectIds);
            },
            filterOption(inputValue, option){
                return option.label.indexOf(inputValue) >=0;
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>