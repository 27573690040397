<!--
* @program: office_automation 
* @author: ly
* @component:projectIncomeViewer2 
* @description: 
* @create: 2022-02-17 10:35
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="visibleType = !visibleType" class="search-item" type="default">
                    <template #icon>
                        <BarChartOutlined style="color:green"/>
                    </template>
                    {{btnText}}
                </a-button>
                <SearchBar :search-options="searchOptions" v-model:value="searchData" v-show="!visibleType"/>
                <a-button @click="doSearch" class="search-item" shape="circle" type="dashed" v-show="!visibleType">
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="default" v-show="!visibleType">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>

                <a-button @click="openShow" style="margin-left: 10px">
                    <template #icon>
                        <BarChartOutlined style="color:green" title="收支统计"/>
                    </template>
                    收支统计
                </a-button>

            </div>
            <TwoParts :enable-change="false" :trans="[40,30]" pos="transform" ref="TwoParts" v-show="!visibleType">
                <template #left>
                    <TcBaseTable :data-source="dataSource" :loading="loading" :page-size="pageSize"
                                 :table-columns="tableColumns" :view-model="true" ref="project_table">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(index,record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-button @click="editDetail(index,record)" class="action-button" type="link">
                                    <EditFilled class="pointer"/>
                                </a-button>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <project-tabs ref="local_tabs"/>
                </template>
            </TwoParts>
            <projectStatistics :editable="true" v-show="visibleType"/>
        </div>
    </div>


    <!-- 收支统计 start -->
    <a-drawer
            :closable="false"
            :title="tj_title"
            :visible="visible"
            @close="onClose"
            destroyOnClose="true"
            height="60%"
            placement="bottom"
    >
        <div class="oa-content" style="height: 430px">
            <div class="oa-window" v-if="sum_flag">
                <div class="ant-row" style="height: 10%;">
                    <a-radio-group @change="changeRadio" name="radioGroup" v-model:value="value_radio">
                        <a-radio value="1">收入统计</a-radio>
                        <a-radio value="2">支出统计</a-radio>
                    </a-radio-group>
                </div>
                <div class="ant-row" style="height: 90%;">
                    <div class="ant-col-24 divhover" style="box-shadow: 0px 0px 5px #888888;">
                        <div ref="chart_item_year" style="height:100% "></div>
                    </div>
                </div>
            </div>
            <div class="oa-window" v-else>
                <div class="ant-row" style="height: 8%;">
                    <a-button @click="back" danger style="margin-left: 95%;">返回</a-button>
                </div>
                <div class="ant-row" style="height: 92%;">
                    <div class="ant-col-24 divhover" style="box-shadow: 0px 0px 5px #888888;">
                        <div ref="chart_item_month" style="height:100% "></div>
                    </div>
                </div>
            </div>
        </div>
    </a-drawer>
    <!-- 收支统计 end -->
</template>

<script>
    import {markRaw} from "vue";
    import * as echarts from "echarts";
    import projectStatistics from "../projectViewer/projectStatistics";
    import Icons from "../../common/Icons";
    import invoiceRecord from "../../../assets/api/invoiceRecord";
    import invoiceInto from "../../../assets/api/invoiceInto";
    import projectViewer from "../projectViewer/projectViewer";

    export default {
        name: "projectIncomeViewer2",
        mixins: [projectViewer, Icons],
        components: {
            projectStatistics
        },
        data() {
            return {
                value_radio: '1',
                sum_flag: true,
                chartsItem: null,
                tj_title: '收支统计',
                statCount_data: [],
                chartsCurrentData: null,
                data_ht: [],
                chartsItem_month: null,
            }
        },
        methods: {
            showDetail(index, record) {
                this.formData = record;
                this.$refs.local_tabs.handleOpen([
                    {tag: "项目收款一览", component: "projectIncome", viewModel: true, projectId: record.id},
                    {tag: "项目支付一览", component: "projectOutSourcePay", viewModel: true, projectId: record.id},
                ]);
                this.$refs.TwoParts.transform();
            },
            editDetail(index, record) {
                this.formData = record;
                this.$refs.local_tabs.handleOpen([
                    {tag: "项目收款", component: "projectIncome", viewModel: false, projectId: record.id},
                    {tag: "项目支付", component: "projectOutSourcePay", viewModel: false, projectId: record.id},
                ]);
                this.$refs.TwoParts.transform();
            },

            openShow() {
                this.value_radio = '1';
                this.openStatistic_sr();
            },
            changeRadio() {
                let type = this.value_radio;
                switch (type) {
                    case "1": {
                        this.openStatistic_sr();
                        break;
                    }
                    case "2": {
                        this.openStatistic_zc();
                        break;
                    }
                }
            },
            /** 收入统计 **/
            openStatistic_sr() {
                invoiceRecord.statCount.requestPOSTUrlParam(this, {}, res => {
                    let data = [];
                    if (res.data.flag) {
                        this.sum_flag = true;
                        this.visible = true;
                        let dataInfo = res.data.data;
                        this.data_ht = dataInfo;

                        // 封装成分析需要的格式
                        if (dataInfo.length > 0) {
                            let list_x = [];
                            let sumCount = []; // 发票总数
                            let sumAmount = [];// 发票总金额
                            let colleAmount = [];//已收款额
                            let noColleAmount = [];//未收款额
                            let colleCount = [];//已收款张数（全部收款完毕）
                            let partColleCount = []; //部分收款张数（收款但未收完）
                            let noColleCount = [];//未收款张数（一点没收）

                            dataInfo.forEach((item, i) => {
                                list_x.push(item.year);
                                sumCount.push(item.sumCount);
                                sumAmount.push((item.sumAmount / 10000).toFixed(2));
                                colleAmount.push((item.colleAmount / 10000).toFixed(2));
                                noColleAmount.push((item.noColleAmount / 10000).toFixed(2));
                                colleCount.push(item.colleCount);
                                partColleCount.push(item.partColleCount);
                                noColleCount.push(item.noColleCount);
                            })
                            data.push(list_x);
                            data.push(sumCount);
                            data.push(sumAmount);
                            data.push(colleAmount);
                            data.push(noColleAmount);
                            data.push(colleCount);
                            data.push(partColleCount);
                            data.push(noColleCount);
                        }

                    } else {
                        this.data_ht = [];
                        this.$message.error(res.data.msg);
                    }
                    setTimeout(() => {
                        this.statCount_data = data;
                        this.eachar_item(data);
                    }, 200)
                });
            },
            /** 支出统计 **/
            openStatistic_zc() {
                invoiceInto.statCount.requestPOSTUrlParam(this, {}, res => {
                    let data = [];
                    if (res.data.flag) {
                        this.sum_flag = true;
                        this.visible = true;
                        let dataInfo = res.data.data;
                        this.data_ht = dataInfo;

                        // 封装成分析需要的格式
                        if (dataInfo.length > 0) {
                            let list_x = [];
                            let sumCount = []; // 发票总张数
                            let sumAmount = [];// 发票总金额
                            let payAmount = [];//已付款额
                            let noPayAmount = [];//未付款额
                            let payCount = [];//已付款张数（全部付款完毕）
                            let partPayCount = []; //部分付款张数（付款但未付完）
                            let noPayCount = [];//未付款张数（一点没付款）

                            dataInfo.forEach((item, i) => {
                                list_x.push(item.year);
                                sumCount.push(item.sumCount);
                                sumAmount.push((item.sumAmount / 10000).toFixed(2));
                                payAmount.push((item.payAmount / 10000).toFixed(2));
                                noPayAmount.push((item.noPayAmount / 10000).toFixed(2));
                                payCount.push(item.payCount);
                                partPayCount.push(item.partPayCount);
                                noPayCount.push(item.noPayCount);
                            })
                            data.push(list_x);
                            data.push(sumCount);
                            data.push(sumAmount);
                            data.push(payAmount);
                            data.push(noPayAmount);
                            data.push(payCount);
                            data.push(partPayCount);
                            data.push(noPayCount);
                        }

                    } else {
                        this.data_ht = [];
                        this.$message.error(res.data.msg);
                    }
                    setTimeout(() => {
                        this.statCount_data = data;
                        this.eachar_item(data);
                    }, 200)
                });
            },

            onClose() {
                this.visible = false;
            },
            eachar_item(data) {
                if (this.chartsItem) {
                    this.chartsItem.clear();
                }
                let option = null;
                if (data.length <= 0) {
                    option = {
                        title: {
                            extStyle: {
                                color: "grey",
                                fontSize: 20

                            },
                            textStyle: {
                                color: "#FFD700",
                            },

                            text: "暂无数据",

                            left: "center",

                            top: "center"

                        },
                    }
                } else {
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            },
                            formatter: (params) => {
                                var result = params[0].axisValueLabel + '<br>'
                                params.forEach(function (item) {
                                    if (item.value) {
                                        result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                                    }
                                })
                                // 保留数据
                                this.chartsCurrentData = params[0]
                                // 返回mousemove提示信息
                                return result
                            }
                        },
                        legend: {
                            selected: {
                                '已收款张数': false,
                                '已付款张数': false,

                                '部分收款张数': false,
                                '部分付款张数': false,

                                '未收款张数': false,
                                '未付款张数': false,

                                '已收款额': false,
                                '已付款额': false,

                                '未收款额': false,
                                '未付款额': false,
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: data[0],
                                axisPointer: {
                                    type: 'shadow'
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '金额（万元）',
                                interval: 500,
                                axisLabel: {
                                    formatter: '{value} '
                                }
                            },
                            {
                                type: 'value',
                                name: '数量（份）',
                                interval: 30,
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            }
                        ],
                        series: [
                            {
                                name: '发票总数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[1]
                            },
                            {
                                name: this.value_radio == '1' ? '已收款张数' : '已付款张数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[5]
                            },
                            {
                                name: this.value_radio == '1' ? '部分收款张数' : '部分付款张数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[6]
                            },
                            {
                                name: this.value_radio == '1' ? '未收款张数' : '未付款张数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[7]
                            },
                            {
                                name: '发票总金额',
                                type: 'bar',
                                emphasis: {
                                    focus: 'series2'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[2]
                            },
                            {
                                name: this.value_radio == '1' ? '已收款额' : '已付款额',
                                type: 'bar',
                                stack: 'Ad2',
                                emphasis: {
                                    focus: 'series2'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[3]
                            },
                            {
                                name: this.value_radio == '1' ? '未收款额' : '未付款额',
                                type: 'bar',
                                stack: 'Ad2',
                                emphasis: {
                                    focus: 'series2'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[4]
                            },

                        ]
                    };
                }
                this.chartsItem = markRaw(echarts.init(this.$refs.chart_item_year))//获取dom元素;
                this.chartsItem.setOption(option);

                /** 扩大点击范围 start **/
                // 防止点击调用多次
                this.chartsItem.getZr().off('click')
                // 鼠标移动到阴影范围 setCursorStyle('pointer')
                this.chartsItem.getZr().on('mousemove', param => {
                    var pointInPixel = [param.offsetX, param.offsetY];
                    if (this.chartsItem.containPixel('grid', pointInPixel)) {//若鼠标滑过区域位置在当前图表范围内 鼠标设置为小手
                        this.chartsItem.getZr().setCursorStyle('pointer')
                    } else {
                        this.chartsItem.getZr().setCursorStyle('default')
                    }
                })
                this.chartsItem.getZr().on('click', params => {

                    // 获取到鼠标点击位置：
                    const pointInPixel = [params.offsetX, params.offsetY]
                    // 判断点击位置是否在显示图形区域
                    if (this.chartsItem.containPixel('grid', pointInPixel)) {
                        this.sum_flag = false;
                        let year = this.chartsCurrentData.axisValueLabel;
                        setTimeout(() => {
                            this.eachar_item_data(year);
                        }, 200)
                    }
                })
                /** 扩大点击范围 end **/

            },
            // 处理月份数据
            eachar_item_data(year) {
                let info = this.data_ht.filter(item => item.year === year);
                let data = [];
                let dataInfo = info[0].monthList;
                let type = this.value_radio;
                switch (type) {
                    case "1": {
                        // 封装成分析需要的格式
                        if (dataInfo.length > 0) {
                            let list_x = [];
                            let sumCount = []; // 发票总数
                            let sumAmount = [];// 发票总金额
                            let colleAmount = [];//已收款额
                            let noColleAmount = [];//未收款额
                            let colleCount = [];//已收款张数（全部收款完毕）
                            let partColleCount = []; //部分收款张数（收款但未收完）
                            let noColleCount = [];//未收款张数（一点没收）

                            dataInfo.forEach((item, i) => {
                                list_x.push(item.month);
                                sumCount.push(item.sumCount);
                                sumAmount.push((item.sumAmount / 10000).toFixed(2));
                                colleAmount.push((item.colleAmount / 10000).toFixed(2));
                                noColleAmount.push((item.noColleAmount / 10000).toFixed(2));
                                colleCount.push(item.colleCount);
                                partColleCount.push(item.partColleCount);
                                noColleCount.push(item.noColleCount);
                            })
                            data.push(list_x);
                            data.push(sumCount);
                            data.push(sumAmount);
                            data.push(colleAmount);
                            data.push(noColleAmount);
                            data.push(colleCount);
                            data.push(partColleCount);
                            data.push(noColleCount);
                        }
                        break;
                    }
                    case "2": {
                        // 封装成分析需要的格式
                        if (dataInfo.length > 0) {
                            let list_x = [];
                            let sumCount = []; // 发票总张数
                            let sumAmount = [];// 发票总金额
                            let payAmount = [];//已付款额
                            let noPayAmount = [];//未付款额
                            let payCount = [];//已付款张数（全部付款完毕）
                            let partPayCount = []; //部分付款张数（付款但未付完）
                            let noPayCount = [];//未付款张数（一点没付款）

                            dataInfo.forEach((item, i) => {
                                list_x.push(item.month);
                                sumCount.push(item.sumCount);
                                sumAmount.push((item.sumAmount / 10000).toFixed(2));
                                payAmount.push((item.payAmount / 10000).toFixed(2));
                                noPayAmount.push((item.noPayAmount / 10000).toFixed(2));
                                payCount.push(item.payCount);
                                partPayCount.push(item.partPayCount);
                                noPayCount.push(item.noPayCount);
                            })
                            data.push(list_x);
                            data.push(sumCount);
                            data.push(sumAmount);
                            data.push(payAmount);
                            data.push(noPayAmount);
                            data.push(payCount);
                            data.push(partPayCount);
                            data.push(noPayCount);
                        }
                        break;
                    }
                }

                this.eachar_item_month(data);
            },
            // 月份统计
            eachar_item_month(data) {
                if (this.chartsItem_month) {
                    this.chartsItem_month.clear();
                }
                let option = null;
                if (data.length <= 0) {
                    option = {
                        title: {
                            extStyle: {
                                color: "grey",
                                fontSize: 20

                            },
                            textStyle: {
                                color: "#FFD700",
                            },

                            text: "暂无数据",

                            left: "center",

                            top: "center"

                        },
                    }
                } else {
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            },
                            formatter: (params) => {
                                var result = params[0].axisValueLabel + '<br>'
                                params.forEach(function (item) {
                                    if (item.value) {
                                        result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                                    }
                                })
                                // 保留数据
                                this.chartsCurrentData = params[0]
                                // 返回mousemove提示信息
                                return result
                            }
                        },
                        legend: {
                            selected: {
                                '已收款张数': false,
                                '已付款张数': false,

                                '部分收款张数': false,
                                '部分付款张数': false,

                                '未收款张数': false,
                                '未付款张数': false,

                                '已收款额': false,
                                '已付款额': false,

                                '未收款额': false,
                                '未付款额': false,
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: data[0],
                                axisPointer: {
                                    type: 'shadow'
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '金额（万元）',
                                interval: 200,
                                axisLabel: {
                                    formatter: '{value} '
                                }
                            },
                            {
                                type: 'value',
                                name: '数量（份）',
                                interval: 10,
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            }
                        ],
                        series: [
                            {
                                name: '该年发票总数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[1]
                            },
                            {
                                name: this.value_radio == '1' ? '已收款张数' : '已付款张数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[5]
                            },
                            {
                                name: this.value_radio == '1' ? '部分收款张数' : '部分付款张数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[6]
                            },
                            {
                                name: this.value_radio == '1' ? '未收款张数' : '未付款张数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[7]
                            },
                            {
                                name: '该年发票总金额',
                                type: 'bar',
                                emphasis: {
                                    focus: 'series2'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[2]
                            },
                            {
                                name: this.value_radio == '1' ? '已收款额' : '已付款额',
                                type: 'bar',
                                stack: 'Ad2',
                                emphasis: {
                                    focus: 'series2'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[3]
                            },
                            {
                                name: this.value_radio == '1' ? '未收款额' : '未付款额',
                                type: 'bar',
                                stack: 'Ad2',
                                emphasis: {
                                    focus: 'series2'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[4]
                            },


                        ]
                    };
                }
                this.chartsItem_month = markRaw(echarts.init(this.$refs.chart_item_month))//获取dom元素;
                this.chartsItem_month.setOption(option);
            },
            back() {
                this.sum_flag = true;
                setTimeout(() => {
                    this.eachar_item(this.statCount_data);
                }, 200)
            },
        }
    }
</script>

<style scoped>

</style>