<!--
* @program: office_automation 
* @author: ly
* @component:AttendanceRuleSetting 
* @description: 考勤扣分规则设定
* @create: 2022-01-14 19:23
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <PlusOutlined/>
                    </template>
                </a-button>
            </div>
            <TwoParts ref="TwoParts" pos="transform" :enable-change="false" :trans="[50,50]">
                <template #left>
                    <ArBaseTable :argument="argument" :view-model="true" :page-size="pageSize">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(index,record)">
                                    <a-button class="search-item"  type="link">
                                        <DeleteOutlined  style="color: red" class="pointer"/>
                                    </a-button>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <div v-if="currentRecord" >
                        <a-page-header style="border: 1px solid rgb(235, 237, 240)">
                            <template #title>
                                <div class="center-flex">
                                    <div >{{currentRecord.title}}</div>
                                    <a-button @click="addData2" class="search-item" shape="circle" type="dashed">
                                        <template #icon><PlusOutlined/></template>
                                    </a-button>
                                </div>
                            </template>
                        </a-page-header>
                        <ArEditTableFilter :argument="argument2" :view-model="false" :page-size="pageSize" @save="saveData2" @delete="deleteData2"/>
                    </div>
                    <div v-else style="font-size: 40px;" class="non-select">
                        欢迎使用{{$sysName}}
                    </div>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="添加新规则">
        <span>规则标题：</span><a-textarea v-model:value="title" auto-size/>
        <span>备注：</span><a-textarea v-model:value="remarks" auto-size/>
        <a-button size="small" type="primary" style="margin: 20px" @click="dataSave">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import ArBaseTable from "../../../../components/table/ArBaseTable";
    import TwoParts from "../../../_components/TwoParts";
    import {SyncOutlined,PlusOutlined,SearchOutlined,DeleteOutlined} from "@ant-design/icons-vue"
    import ArEditTableFilter from "../../../../components/table/ArEditTableFilter";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import attendanceRule from "../../../../assets/api/attendanceRule";
    import ruleDetail from "../../../../assets/api/ruleDetail";
    export default {
        name: "AttendanceRuleSetting",
        components: {
            ArBaseTable,TwoParts,SyncOutlined,PlusOutlined,ArEditTableFilter,
            SearchOutlined,DeleteOutlined
        },
        data(){
            return{
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                        new Column("规则标题","title",ColumnType.String,true).setTableView(200),
                        new Column("备注","remarks",ColumnType.Date,true).setTableView(100),
                        new Column("操作","actions","actions").setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                argument2:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[//1：迟到；2：早退；3：缺卡；4：旷工；5：地点异常；6：设备异常
                        new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                        new Column("违规类型","type",ColumnType.Enum,true).setTableView(100)
                        .setEnum(["1","2","3","4","5","6"],["迟到","早退","缺卡","旷工","地点异常","设备异常"]),
                        new Column("不予扣分次数","allowCount",ColumnType.Number,true).setTableView(100),
                        new Column("不扣分的时间范围","timeTerm",ColumnType.Number,true).setTableView(150),
                        new Column("扣分/次","onceScore",ColumnType.Number,true).setTableView(100),
                        new Column("操作","actions","actions").setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                visible:false,
                title:null,
                remarks:null,
                currentRecord:null,
            }
        },
        methods:{
            init(){
                this.visible = false;
                this.argument2.dataSource = [];
                this.argument.loading = true;
                attendanceRule.queryAll.requestPOSTUrlParam(this,{},res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            refresh(){
                this.init()
            },
            init2(){
                let data = {
                    "ruleId": this.currentRecord.id,
                    "type": ""
                };
                this.argument2.loading = true;
                ruleDetail.queryAll.requestPOST(this,data,res=>{
                    this.argument2.loading = false;
                    if(res.data.flag){
                        this.argument2.dataSource = res.data.data;
                    }
                    else{
                        this.argument2.dataSource = [];
                    }
                })
            },
            showDetail(record){
                this.currentRecord = record;
                this.init2();
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                attendanceRule.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                        this.argument2.dataSource = [];
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            dataSave(){
                let data ={
                    title:this.title,
                    remarks:this.remarks
                };
                attendanceRule.saveOrUpdate.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            addData(){
                this.title = "";
                this.remarks = "";
                this.visible = true;
            },
            addData2(){
                let data = {
                    type:null,
                    allowCount:null,
                    onceScore:null,
                    remarks:""
                };
                this.argument2.dataSource.push(data);
            },
            saveData2(record){
                record.ruleId = this.currentRecord.id;
                ruleDetail.saveOrUpdate.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.init2();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData2(record){
                let data = {
                    id:record.id
                };
                ruleDetail.delete.requestPOSTUrlParam(this,data);
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>