<!--
* @program: tyh-oa 
* @author: ly
* @component:userCenterView 
* @description: 
* @create: 2021-05-25 16:11
-->
<template>
    <a-tabs   v-model:activeKey="activeKey">
        <a-tab-pane  key="setting1" tab="个人信息一览" >
            <user-info-view />
        </a-tab-pane>
        <a-tab-pane  key="setting2" tab="用户信息修改" >
            <user-info-edit />
        </a-tab-pane>
        <a-tab-pane  key="setting3" tab="用户密码修改" >
            <user-password-modify />
        </a-tab-pane>
        <template #renderTabBar="{ DefaultTabBar, ...props }">
            <component
                    :is="DefaultTabBar"
                    v-bind="props"
                    :style="{ zIndex: 1, background: '#fff', }"
            />
        </template>
    </a-tabs>
</template>

<script>
    import userInfoEdit from "./userInfoEdit";
    import userInfoView from "./userInfoView";
    import userPasswordModify from "./userPasswordModify";
    export default {
        name: "userCenterView",
        components:{
            userInfoEdit,userInfoView,userPasswordModify
        },
        data(){
            return{
                activeKey:"setting1"
            }
        }
    }
</script>

<style scoped>

</style>