<!--
* @program: office_automation 
* @author: ly
* @component:appMenuTreeSelect 
* @description: 
* @create: 2023-05-06 11:47
-->
<template>
    <div>
        <a-tree style="text-align: left;height:100%;overflow-y:auto"
                v-if="!loading"
                :tree-data="treeData"
                default-expand-all
                :selectable="false"
                :replace-fields="replaceFields"
                v-model:checkedKeys="checkedKeys"
                checkable
                checkStrictly
        >
            <template #title="{dataRef}">
                <div style="display: flex;height: 24px;width: 200px">
                    <div style="padding: 0 5px;height: 24px">【{{dataRef.text}}】</div>
                    <appMenuOpBind :mops="dataRef.permissions" v-model:sops="dataRef.sops" />
                </div>
            </template>
        </a-tree>
    </div>
</template>

<script>
    import appMenuViewer from "./appMenuViewer";
    import appMenuOpBind from "./appMenuOpBind";
    export default {
        name: "appMenuTreeSelect",
        extends:appMenuViewer,
        components:{
            appMenuOpBind
        },
        data(){
            return{
                checkedKeys:[],
            }
        },
        methods:{
            getCheckedKeys(){
                return this.checkedKeys.checked;
            },
            getTreeData(){
                return this.treeData;
            },
            setCheckedKeys(keys){
                this.checkedKeys = keys;
            },
            bindTwoTrees(MenuByRoleData){
                resetTreeData(this.treeData)
                let nodes = getAllNodes([],MenuByRoleData);
                this.checkedKeys = getAllNodeId([],MenuByRoleData)
                for(let item of nodes){
                    let node = getNode(item.id,this.treeData);
                    if(node){
                        node.sops = item.havePermis;
                    }
                }
            }
        }
    }
    function getNode(key,treeData){ //递归函数
        for(let node of treeData){
            if(node.id === key){
                return  node;
            }
            else {
                if(node.children && node.children.length>0){
                    let res = getNode(key,node.children);
                    if(res){
                        return res
                    }
                }
            }
        }
    }
    function resetTreeData(treeData) {
        for(let node of treeData){
            node.sops = "";
            if(node.children && node.children.length>0){
                resetTreeData(node.children);
            }
        }
    }
    function getAllNodeId (expandedKeys, treeData) {
        for (let item of treeData) {
            expandedKeys.push(item.id);
            if (item.children) {
                expandedKeys = getAllNodeId(expandedKeys, item.children)
            }
        }
        return expandedKeys
    }
    function getAllNodes (nodes, treeData) {
        for (let item of treeData) {
            nodes.push(item);
            if (item.children) {
                nodes = getAllNodes(nodes, item.children)
            }
        }
        return nodes
    }
</script>

<style scoped>

</style>