<!--
* @program: TYH_office_automation 
* @author: ly
* @component:phoneBool 
* @description: 
* @create: 2021-06-21 11:11
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <a-page-header
                    style="border: 1px solid rgb(235, 237, 240)"
                    title="内部员工通讯录"
            />
            <ArBaseTableFilter style="width:600px " :view-model="true" :argument="argument" :page-size="20"/>
        </div>
    </div>
</template>

<script>

    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import api_sys_user_info from "../../../assets/api/userInfo";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    export default {
        name: "phoneBool",
        components: {
            ArBaseTableFilter
        },
        data(){
            return{
                activeKey:"setting1",
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                        new Column("姓名","name",ColumnType.String,false).setTableView(200),
                        new Column("手机号","phone",ColumnType.String,false).setTableView(200),
                        new Column("电子邮箱","email",ColumnType.String,false).setTableView(100)
                    ], //提供table的字-段集 this.table.getTableColumns()

                    loading:false//table的loading状态
                }
            }
        },
        methods:{
            init(){
                this.argument.loading = true;
                let data = {disableState:"1"};
                api_sys_user_info.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.argument.loading = false;
                });
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>