<!--
* @program: office_automation 
* @author: ly
* @component:BankManager 
* @description: 银行账户管理
* @create: 2022-01-24 14:53
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
<!--                <a-tooltip title="添加新的银行账户">
                    <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                        <template #icon><PlusOutlined/></template>
                    </a-button>
                </a-tooltip>-->
              <div style="color: #022d5c;font-size: 18px;font-weight: 900;margin:10px">
                余额合计：{{allSum}}元<a-divider type="vertical" style="height: 40px; background-color: #7cb305" />
                其中贵州中色蓝图：{{sum1}}元<a-divider type="vertical" style="height: 40px; background-color: #7cb305" />
                分公司：{{sum2}}元<a-divider type="vertical" style="height: 40px; background-color: #7cb305" />
                中色：{{sum3}}元<span style="font-size: 5px;color: #faad14;">（项目收入*91.4%）+其他收入-项目支出-其他支出</span>
              </div>
            </div>
            <a-spin :spinning="loading" tip="正在查询数据库...">
                <div style="display: flex;flex-wrap: wrap;overflow: auto;height: 750px">
                    <a-card v-for="(item,idx) in dataSource" :key="idx" style="width: 400px;margin: 8px 8px;box-shadow: 0px 0px 5px #888888;">
                        <template #title>
                            {{item.bankName}}
                        </template>
                        <template #extra>
                            <a-button v-if="!item.visible" @click="showFlows(item)" type="link" >
                                流水
                            </a-button>
                            <a-button v-else @click="back(item)" danger type="link" >
                                返回
                            </a-button>
                        </template>
                      <span style="color: #5265ff;font-size: 16px;font-weight: 500;">余额：{{item.currentBlance}}元</span>
                      <span style="color: #faad14;font-size: 14px;">（{{new Date(item.updateTime).toLocaleDateString()}}）</span>
                        <FcForm style="width: 350px" :form-columns="getFormColumns(item)" :form-data="item" :view-model="true" />
                    </a-card>
                    <a-tabs v-if="visible" style="max-width: calc(100% - 450px)" v-model:activeKey="activeKey" >
                        <a-tab-pane  key="7" tab="流水汇总" >
                            <CardFlows  :record="currentRecord" :show-bill="showBill" />
                        </a-tab-pane>
                        <a-tab-pane v-if="showBill"  key="6" tab="账单一览" >
                            <ShowBills  />
                        </a-tab-pane>
                        <a-tab-pane  key="1" tab="项目收入流水" >
                            <ProjectInflow  :record="currentRecord" />
                        </a-tab-pane>
                        <a-tab-pane  key="2" tab="项目支出流水" >
                            <ProjectOutflow :record="currentRecord" />
                        </a-tab-pane>
                        <a-tab-pane  key="3" tab="其他收入" >
                            <OtherInflow  :record="currentRecord" />
                        </a-tab-pane>
                        <a-tab-pane  key="4" tab="其他支出" >
                            <OtherOutflow  :record="currentRecord" />
                        </a-tab-pane>
                        <a-tab-pane  key="5" tab="其他账户转入" >
                            <FromOtherBank  :record="currentRecord" />
                        </a-tab-pane>
                    </a-tabs>
                </div>
                <div style="border: 1px solid rgb(235, 237, 240);margin: 10px;font-size: 15px;color: red;">让账户的金额变动的流水日期需要在【账户初始余额日期】之后，录入在【账户初始余额日期】之前的流水会导致该银行卡出现各部分流水金额统计起来跟系统余额不同的情况。</div>
            </a-spin>
        </div>
    </div>
</template>

<script>
    import BankManager from "./BankManager";
    export default {
        name: "BankManager",
        mixins:[BankManager]
    }
</script>

<style scoped>

</style>