import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: message
 * @create: 2022-06-23 14:22
 private String type;                      //消息类型：1：企业公告通知；2：用户互动；3：系统通知；4：其他
 private String title;                     //标题
 private String content;                   //消息内容
 private String fileUrl;                   //附件路径（管理员发布全员公告时，可传附件资料等）
 private String state;                     //消息状态：0：草稿；1：正式发布
 **/

export default class message extends Table{
    constructor() {
        super();
        this.name = "message";
        this.CNName = "站内信";
        this.columnArray = [
            new Column("类型","type",ColumnType.String,true)
                .setEnum(["1","2","3","4"],["公告通知","用户互动","系统通知","其他"],["blue","green","red","pink"]),
            new Column("标题","title",ColumnType.String,true),
            new Column("内容","content",ColumnType.String,true),
            new Column("文件","fileUrl",ColumnType.PreViewFile,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}