<!--
*@description：成果类型查询组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar">
        <div style="background-color: #1890ff;padding: 6px;color: #fafafa;font-size: 16px;margin-right: 20px;">{{pidName}}</div>
        <a-input class="search-item ant-col-4" v-model:value="name" placeholder="成果类型名称" />
<!--        <a-input class="search-item ant-col-4" v-model:value="level" placeholder="级别：1,2,3,4....." />-->
        <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
            <template #icon><Search /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
            <template #icon><Reset /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="addData">
          <template #icon><Add /></template>
        </a-button>
    </div>
</template>

<script>
    import Icons from "../../../common/Icons";
    export default {
      name: "achieveTypeToolBar",
      mixins:[Icons],
      data(){
        return{
          name:null,
          level:null,
          pidName:'成果类型',
        }
      },
      methods:{
        doSearch(){
            let data = this.$GetEmitData(this.$eventTags.search,{name:this.name,level:this.level});
            this.$emit("event",data);
        },
        refresh(){
            this.name = null;
            this.level = null;
            this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        },
        addData(){
          this.$emit("event",this.$GetEmitData(this.$eventTags.add));
        },
        getParms(){
          let parms = {
            name:this.name,
            level:this.level,
          }
          return parms;
        },
        set_pidName(pidName){
          this.pidName = pidName;
        },
      }
    }
</script>

<style scoped>

</style>