/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class deviceTemp_item extends Table {
    constructor() {
        super();
        this.name = "deviceTemp_item";
        this.CNName = "固定资产汇总";
        this.columnArray = [
            new Column("固定资产类别名称","fixedCategoryName",ColumnType.String,true).setTableView(150),
            new Column("资产价值（汇总）","priceSum",ColumnType.Number,true).setTableView(100),
            new Column("操作","actions","actions",false).setTableView(80)
        ];

    }
}