/** 
* 自动生成文件，请勿修改 
* @name: 系统:短信发送管理
* @description: 短信发送管理(三期新增)
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const smsRecord = { 
	/** 1 summary:发送短信验证码测试	method:post */ 
	sendSms : new APIObject("/api/smsRecord/sendSms.do"), 
	/** 2 summary:查询验证码发送记录详情(直接查询阿里云接口)	method:post */ 
	querySmsDetail : new APIObject("/api/smsRecord/querySmsDetail.do"), 
	/** 3 summary:发送短信给夏总（部门负责人的月度考核完成后）	method:post */ 
	notify : new APIObject("/api/smsRecord/notify.do"), 
	/** 4 summary:物理删除短信发送记录-（根据多个id）	method:post */ 
	delPhysics : new APIObject("/api/smsRecord/delPhysics.do"), 
	/** 5 summary:发送短信给被考核人（各部门负责人打分完成后通知被打分人）	method:post */ 
	notifyStaff : new APIObject("/api/smsRecord/notifyStaff.do"), 
	/** 6 summary:分页查询	method:post */ 
	queryPage : new APIObject("/api/smsRecord/queryPage.do"), 
};
export default smsRecord;