<!--
* @program: office_automation 
* @author: ly
* @component:personalWorkCalendar 
* @description: 
* @create: 2022-03-14 10:56
-->
<template>
    <a-spin :spinning="spinning">
        <a-calendar v-model:value="calendarValue" v-if="userId"  mode="month" :locale="locale" @select="showWork">
            <template #headerRender="{ value, type, onChange, onTypeChange }">
                <div style="padding: 10px;display: flex">
                    <a-row type="flex" justify="flex-start" style="width: 400px;font-size: 16px">
                        <a-col>
                            <a-radio-group button-style="solid"  :value="type" @change="e =>{return  onTypeChange(e.target.value)}">
                                <a-radio-button value="month">工作日志</a-radio-button>
<!--                                <a-radio-button value="year">月度绩效考核分配</a-radio-button>-->
                            </a-radio-group>
                        </a-col>
                        <a-col>
                            <a-select
                                    :dropdown-match-select-width="false"
                                    class="my-year-select"
                                    :value="String(value.year())"
                                    @change="newYear => {onChange(value.clone().year(newYear));}"
                            >
                                <a-select-option
                                        v-for="val in getYears(value)"
                                        :key="String(val)"
                                        class="year-item"
                                >
                                    {{ val }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col>
                            <a-select
                                    :dropdown-match-select-width="false"
                                    :value="String(value.month())"
                                    @change="selectedMonth => {onChange(value.clone().month(parseInt(selectedMonth, 10)));}"
                            >
                                <a-select-option
                                        v-for="(val, index) in getMonths(value)"
                                        :key="String(index)"
                                        class="month-item"
                                >{{ val }}</a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                    <a-button  type="primary" @click="drawerVisible = true">
                        查看整体完成进度
                    </a-button>
                    <a-button  danger @click="workDelay">
                        任务延期申请
                    </a-button>
                    <a-button type="default" shape="circle" @click="refresh">
                        <template #icon><SyncOutlined /></template>
                    </a-button>
                </div>
            </template>
            <template #dateCellRender="{ current: value }">
                <div :class="getCssClass(value)">
                    <ul class="events">
                        <li v-for="item in getWorkList(value)" :key="item.id">
                            <a-badge @click="editWork(item,value)" status="success" :text="(item.workRatio?item.workRatio:0)+'%('+item.processTitle+')'" />
                        </li>
                    </ul>
                </div>
            </template>
            <template #monthCellRender="{ current: value }">
                <div  class="notes-month">
                    <ul class="events">
                        <li v-for="item in getMonthWorkList(value)" :key="item.id">
                            <a-badge @click="editMonthWork(item,value)" status="success" :text="item.processTitle+'：分配'+item.workRatio?item.workRatio:0+'%'" />
                        </li>
                    </ul>
                </div>
            </template>
        </a-calendar>
        <div v-else>
            <div style="height: 100%;overflow: hidden">
                <div style="font-size: 60px;margin-top: 300px" class="non-select">
                    欢迎使用
                </div>
                <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
                    版本：{{$version}}
                </div>
            </div>
        </div>
    </a-spin>
    <!--查看工序详情 -->
    <a-modal v-model:visible="modalVisible"
             width="600px"
             destroyOnClose
             :footer="null"
             title="详情">
        <ArForm  :view-model="true" :argument="formArg" />
    </a-modal>
    <!--添加日工作记录 -->
    <a-modal v-model:visible="visible"
            title="添加工作记录"
            destroyOnClose
            width="500px"
            :footer="null"
    >
        <span>选择任务工序：</span>
        <a-select v-model:value="currentProcessEmployeeId" style="width: 250px" class="search-item">
            <a-select-option v-for="item in workList" :key="item.id">
                {{ item.processTitle }}
            </a-select-option>
        </a-select>
        <FcForm :view-model="false" :form-columns="formColumns" v-model:form-data="formData" >
            <template #action="{record}">
                <a-button  type="primary" @click="saveData(record)">
                    保存
                </a-button>
            </template>
        </FcForm>
    </a-modal>
    <!--日工作记录修改 -->
    <a-modal v-model:visible="visible2"
            title="工作记录"
            destroyOnClose
            width="500px"
            :footer="null"
    >
        <FcForm :view-model="vModel" :form-columns="formColumns" v-model:form-data="formData" >
            <template #action="{record}">
                <a-button  type="primary" @click="saveData(record)">
                    保存
                </a-button>
                <a-button danger type="primary" @click="deleteData(record)">
                    删除
                </a-button>
            </template>
        </FcForm>
    </a-modal>
    <!--我的任务进度 -->
    <a-drawer :visible="drawerVisible"
            title="我的任务进度(点击工序能看到工序详情)"
              height="500px"
            placement="bottom"
            :closable="false"
            @close="drawerVisible = false"
    >
        <div style="margin: 10px" v-for="(item,idx) in workList" :key="idx.toString()">
            <a-tag  class="pointer" color="blue" @click="showProcess(item)" >{{item.processTitle}}</a-tag>
            <span>期限：{{new Date(item.endDate).toLocaleDateString()}}&emsp;</span>
            <span>总完成进度：【{{item.dayProcess.toFixed(2)}}%】&emsp;月度完成进度：</span>
            <span v-for="aa in item.monthSumProcess" :key="aa.month" >【{{aa.month}}完成{{aa.value}}%】</span>
<!--         deprecated   <span>&emsp;&emsp;月度考核指标已分配：【{{item.monthProcess}}%】</span>-->
            <a-button v-if="item.dayProcess >=99.9999 && !item.state"  type="primary" @click="finishWork(item)">
                提交工序完成
            </a-button>
<!--            <a-button  type="primary" @click="finishWork(item)">-->
<!--                提交工序完成-->
<!--            </a-button>-->
        </div>
    </a-drawer>

    <!--查看延期申请 -->
    <a-modal v-model:visible="visible5"
             destroy-on-close
             :footer="null"
             :maskClosable="viewModel"
             :width="800"
             title="任务延期详情">
        <span>选择任务工序：</span>
        <a-select v-model:value="currentProcessEmployeeId" style="width: 120px" class="search-item">
            <a-select-option v-for="item in workList" :key="item.id">
                {{ item.processTitle }}
            </a-select-option>
        </a-select>
        <span v-if="currentProcessEmployeeId">&emsp;&emsp;原定完成时间为：{{new Date(currentRecord.endDate).toLocaleDateString()}}</span>
        <delayApply v-if="currentProcessEmployeeId" :record="currentRecord" type="4" />
    </a-modal>
    <!--提交任务完成情况 -->
    <a-modal v-model:visible="visible6"
             destroy-on-close
             :footer="null"
             :maskClosable="viewModel"
             :width="500"
             title="提交完成情况">
<!--        <div>以提交完成的那一天作为任务的完成日期</div>-->
        <span>完成时间：</span><DatePicker style="width: 200px" v-model:value="taskDate" />
        <ArForm :argument="formArg" :view-model="false" >
            <template #action="{record}">
                <a-button type="primary" @click="saveWorkState(record)">
                    提交
                </a-button>
            </template>
        </ArForm>
    </a-modal>
</template>

<script>

    import {
        InfoCircleOutlined, SyncOutlined
    } from "@ant-design/icons-vue";
    import ArForm from "../../../components/form/ArForm";
    import item_work_situation from "../../../assets/tables/item_work_situation";
    import item_work_situation3 from "../../../assets/tables/item_work_situation3";
    import processEmployee from "../../../assets/api/processEmployee";
    import workSituation from "../../../assets/api/workSituation";
    import moment from "moment";
    import personal_task from "../../../assets/tables/personal_task";
    import FcForm from "../../../components/form/FcForm";
    import delayApply from "../../projectManager/projectDelayManager/delayApply";
    import DatePicker from "../../_components/DatePicker";

    export default {
        name: "personalWorkCalendar",
        props:["userId","processEmployeeList","viewModel"],
        watch:{
            userId(){
                this.init()
            },
            processEmployeeList(){
                this.init()
            }
        },
        computed:{
           currentRecord(){
               if(this.currentProcessEmployeeId){
                   return this.workList.filter(item=>item.id === this.currentProcessEmployeeId)[0]
               }
               else{
                   return null
               }
           }
        },
        components: {
            SyncOutlined,ArForm,FcForm,InfoCircleOutlined,delayApply,
            DatePicker
        },
        data(){
            return{
                mode:"month",
                value:null,
                spinning:false,
                locale:{
                    "lang": {
                        "month": "月",
                        "year": "年",
                    },
                    "dateFormat": "YYYY-MM-DD",
                    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
                    "weekFormat": "YYYY-wo",
                    "monthFormat": "YYYY-MM"
                },
                dateFormat: "YYYY-MM-DD",
                monthFormat: "YYYY-MM",
                formArg:{//工序详情
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                formColumns:null,//添加工作进度
                formData:null,
                visible:false,
                visible2:false,
                workList:[],
                modalVisible:false,
                drawerVisible:false,
                drawerTitle:"",
                currentProcessEmployeeId:null,
                currentMoment:null,
                calendarValue:null,
                vModel:true,
                visible3:false,
                visible4:false,
                visible5:false,
                visible6:false,

                taskDate:null,
            }
        },
        methods:{
            init(){
                this.workList = [...this.processEmployeeList];
                for(let i in this.workList){
                    this.workList[i].dayWorks=[];
                    this.workList[i].monthWorks=[];
                    let data={
                        userId:this.userId,
                        processEmployeeId:this.workList[i].id
                    };
                    workSituation.queryAll.requestPOST(this,data,res=>{
                        this.workList[i].dayProcess = 0; //本工序总进度
                        this.workList[i].monthSumProcess = []; //本月工序进度
                        if(res.data.flag){
                            this.workList[i].dayWorks = res.data.data;
                            for(let j in res.data.data){
                                this.workList[i].dayProcess += parseFloat(res.data.data[j].workRatio?res.data.data[j].workRatio:0);
                                let workMonth = moment(this.workList[i].dayWorks[j].workDate).format(this.monthFormat);
                                let arr = this.workList[i].monthSumProcess.filter(item=>item.month === workMonth);
                                if(arr.length >0){
                                    arr[0].value += this.workList[i].dayWorks[j].workRatio;
                                }
                                else{
                                    this.workList[i].monthSumProcess.push({month:workMonth,value:res.data.data[j].workRatio})
                                }
                            }
                        }
                        else{
                            this.workList[i].dayWorks = [];
                        }
                    });
                    // workRecord.queryAll.requestPOST(this,data,res=>{
                    //     this.workList[i].monthProcess = 0;
                    //     if(res.data.flag){
                    //         this.workList[i].monthWorks = res.data.data;
                    //         for(let j in res.data.data){
                    //             this.workList[i].monthProcess += parseInt(res.data.data[j].workRatio);
                    //         }
                    //     }
                    //     else{
                    //         this.workList[i].monthWorks = [];
                    //     }
                    // })
                }
            },
            refresh(){
                this.init();
            },
            getMonths(value) {
                const current = value.clone();
                const localeData = value.localeData();
                const months = [];

                for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                }
                return months;
            },
            getYears(value){
                const year = value.year();
                const years = [];

                for (let i = year - 10; i < year + 10; i += 1) {
                    years.push(i);
                }
                return years;
            },
            getWorkList(value){
                let works = [];
                for(let i in this.workList){
                    for(let j in this.workList[i].dayWorks){
                        if(value.isBetween(moment(this.workList[i].dayWorks[j].workDate),moment(this.workList[i].dayWorks[j].workDate).add(1, 'day'))  ){
                            works.push(this.workList[i].dayWorks[j])
                        }
                    }
                }
                return works;
            },
            getMonthWorkList(value){
                let works = [];
                for(let i in this.workList){
                    for(let j in this.workList[i].monthWorks){
                        let startMonth = moment(moment(this.workList[i].monthWorks[j].yearMonth).format('YYYY-MM'));
                        let endMonth = moment(moment(this.workList[i].monthWorks[j].yearMonth).format('YYYY-MM'));
                        if(value.isBetween(startMonth,endMonth.add(1, 'month'))){
                            works.push(this.workList[i].monthWorks[j])
                        }
                    }
                }
                return works;
            },
            showWork(date){
                if(this.visible2 || this.visible4){
                    return; //如果已经弹出修改匡则不弹出添加匡
                }
                let now = this.currentMoment.format(this.dateFormat);
                let yesterday = this.currentMoment.clone().add(-1, 'days').format(this.dateFormat);
                let dateFormat = date.format(this.dateFormat); //点击日历的时间
                // if(now === dateFormat || yesterday === dateFormat){
                    this.visible = true;
                    this.currentProcessEmployeeId = null;
                    this.formColumns = new item_work_situation().getFormColumns();
                    this.formData = new item_work_situation().getFormData();
                    this.formData.workDate = date.format(this.dateFormat);
                //}
                // else{
                //     this.$message.error("目前只允许添加和修改当天和前一天的工作记录")
                //     // this.visible = true;
                //     // this.formColumns = new item_work_situation().getFormColumns();
                //     // this.formData = new item_work_situation().getFormData();
                // }
            },
            showProcess(item){
                this.formArg.formData = item;
                this.modalVisible = true;
            },
            saveData(record){
                if(!record.id){
                    record.processEmployeeId = this.currentProcessEmployeeId;
                }
                record.workRatio = record.workRatio?record.workRatio:0;
                if(record.workRatio === 0 || record.workRatio>100 ){
                    this.$message.error("请填入正确的完成进度");
                    return
                }
                workSituation.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(record){
                let data = {id:record.id};
                workSituation.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        let arr = this.workList.filter(item=>item.id === record.processEmployeeId);
                        let arr2 = arr[0].dayWorks.filter(item=>item.id === record.id);
                        let index = arr[0].dayWorks.indexOf(arr2[0]);
                        arr[0].dayWorks.splice(index,1);
                        this.visible2 = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editWork(item,value){
                this.formColumns = new item_work_situation().getFormColumns();
                let now = this.currentMoment.format(this.dateFormat);
                let yesterday = this.currentMoment.clone().add(-1, 'days').format(this.dateFormat);
                // if(value.format(this.dateFormat) === now || value.format(this.dateFormat) === yesterday){
                    this.formData = item;
                    this.visible2 = true;
                    this.vModel = false;
                // }
                // else{
                //     this.formData = item;
                //     this.visible2 = true;
                //     this.vModel = true;
                // }
                // if(this.viewModel){
                //     this.vModel = this.viewModel
                // }
            },
            editMonthWork(item,value){
                this.formColumns = new item_work_situation3().getFormColumns();
                // if(value.format(this.monthFormat) !== this.currentMoment.format(this.monthFormat)){
                    this.formData = item;
                    this.visible4 = true;
                    this.vModel = true;
                // }
                // else{
                //     this.formData = item;
                //     this.visible4 = true;
                //     this.vModel = false;
                // }
            },
            workDelay(){
                this.visible5 = true;
                this.currentProcessEmployeeId = null
            },
            saveWorkState(record){
                if(this.taskDate){
                    record.completeDate = new Date(this.taskDate).valueOf();
                    processEmployee.saveOrUpd.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            record.state = true;
                            this.visible6 = false;
                            this.init();
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                else{
                    this.$message.error("请选择完成时间");
                }

            },
            finishWork(item){
                this.formArg.formData = item;
                this.visible6 = true;
            },
            getCssClass(value){
                let num = value.isoWeekday();
                if(num === 6 || num === 7){
                    return "weekend"
                }
                else{
                    return ""
                }
            }
        },
        created() {
            this.init();
            this.formArg.formColumns = new personal_task().getFormColumns();
            this.formColumns = new item_work_situation().getFormColumns();
            let time = parseInt(localStorage.getItem("time"));
            this.currentMoment = moment(time);
        }
    }
</script>

<style scoped>
    .weekend{
        width: 100%;height: 100%; background: #feffe7
    }
</style>