<!--
* @program: tyh-oa 
* @author: ly
* @component:billCategoryTree 
* @description: 
* @create: 2021-04-19 14:25
-->
<template>
    <div style="display: flex">
        <a-tooltip  title="添加大类">
            <a-button class="search-item" type="dashed"  @click="addGroup">
                <template #icon><PlusOutlined /></template>
            </a-button>
        </a-tooltip>
        <a-tooltip :key="'#fff'" title="刷新">
            <a-button class="search-item" type="dashed"  @click="refresh">
                <template #icon><SyncOutlined /></template>
            </a-button>
        </a-tooltip>
    </div>
    <a-table style="height: 600px;overflow-y: auto;max-width: 600px" :loading="loading" bordered :dataSource="dataSource" :columns="cols" rowKey="id" >
        <template #actions="{index,record}">
            <div style="text-align: center">
                <a-tooltip  title="添加子类">
                    <a-button v-if="checkColumn(record)" class="action-button" type="link" @click="addDetail(record)">
                        <PlusOutlined class="pointer"/>
                    </a-button>
                </a-tooltip>
                <a-button  class="action-button" type="link" @click="editDetail(index,record)">
                    <EditFilled class="pointer"/>
                </a-button>
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </a-table>
    <a-modal v-model:visible="visible"
             title="详情">
        <template #footer>
            <a-button key="back" @click="visible =false">取消</a-button>
            <a-button key="submit" type="primary"  @click="update">提交</a-button>
        </template>
        <a-form style="margin: 10px 100px" layout="horizontal"
                :model="currentRecord">
            <a-form-item label="类别">
                <a-input style="width: 200px" v-model:value="currentRecord.categoryName" />
            </a-form-item>
            <a-form-item label="备注">
                <a-textarea style="width: 200px" v-model:value="currentRecord.remarks" />
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import api_category from "../../../assets/api/category";
    import category from "../../../assets/tables/category";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {DeleteOutlined,PlusOutlined,EditFilled,SyncOutlined} from "@ant-design/icons-vue"
    export default {
        name: "billCategoryTree",
        components:{
            DeleteOutlined,PlusOutlined,EditFilled,SyncOutlined
        },
        data(){
            return{
                dataSource:[],
                table: new category(),
                cols:[],
                secondSource:[],
                visible:false,
                viewModel:false,
                currentRecord:{},
                loading:false
            }
        },
        methods:{
            init(){
                this.loading = true;
                api_category.queryOneLevel.requestPOST(this,{},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                })
            },
            addGroup(){//添加大类
                this.currentRecord = {};
                this.visible = true
            },
            addDetail(record){//添加子分类
                this.currentRecord = {parentId:record.id};
                this.visible = true
            },
            deleteData(index,record){
                let data ={id:record.id};
                api_category.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editDetail(index,record){
                this.visible = true;
                this.currentRecord = record;
            },
            checkColumn(record){
                return Object.prototype.hasOwnProperty.call(record, "children");
            },
            update(){
                api_category.saveOrUpd.requestPOST(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.init()
            }
        },
        created() {
            this.cols = [
                new Column("类别","categoryName",ColumnType.String,true).setTableView(150),
                new Column("备注","remarks",ColumnType.String,true).setTableView(150),
                new Column("操作","actions","actions").setTableView(80)];
            this.init();
        }
    }
</script>

<style scoped>

</style>