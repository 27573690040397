/**
 * @program: tyh-oa
 * @author: ly
 * @description: eventTags  用于标记Event事件的type，避免直接使用string 导致打错
 * @create: 2021-04-21 17:15
 **/

function EventTags() {
    this.delete = "delete";
    this.showDetail = "show_detail";
    this.editDetail = "edit_detail";
    this.search = "search";
    this.addData = "add_data";
    this.saveData = "save_data";
    this.refresh = "refresh";
    this.statistic = "statistic";
    this.addMember = "add_member";
    this.editInfo = "editInfo";
    this.audit = "audit";
    this.back = "back";


    /** 地图模块 **/
    this.query_text = "query_text";         // 查询条件
    this.wkt_show = "wkt_show";             // wkt显示
    this.map_query = "map_query";           // 查询条件
    this.map_cd = "map_cd";                 // 长度测量
    this.map_mj = "map_mj";                 // 面积测量
    this.map_ydyp = "map_ydyp";             // 用地预批
    this.map_dr_shp = "map_dr_shp";         // 导入shp分析
    this.map_djcx = "map_djcx";             // 点击查询
    this.map_qc = "map_qc";                 // 清除
    this.map_tcgl = "map_tcgl";             // 图层管理
    this.zoom_in = "zoom_in";               // 放大
    this.zoom_out = "zoom_out";             // 缩小
    this.zoom_reset = "zoom_reset";         // 复位
    this.hgfx_qc = "hgfx_qc";               // 合规分析-清除
    this.mapInfo_back = "mapInfo_back";                 // 查看信息返回

    this.add = "add";                       // 加
    this.reduce = "reduce";                 // 减
    this.relevancy = "relevancy";           // 关联
    this.periodBalance = "periodBalance";   // 设置期初余额

}

let tags = new EventTags();

export default tags;