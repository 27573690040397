<!--
* @program: office_automation 
* @author: ly
* @component:AttendanceRecordsFromMonth 
* @description: 
* @create: 2022-01-14 17:12
-->
<template>
    <div style="height: 100%">
        <div class="search-bar">
            <MonthSelector v-model:value="yearMonth" @change="monthChange"/>
        </div>
        <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="15" >
            <template #action="{record}">
                <div class="action-column">
                    <a-button @click="showDetail(record)" class="action-button" type="link">
                        <SearchOutlined class="pointer"/>
                    </a-button>
                </div>
            </template>
        </ArBaseTableFilter>
    </div>
    <a-modal v-model:visible="visible"
             :footer="null"
             title="考核详情">
        <FcForm  :form-columns="formColumns" :form-data="currentRecord" :view-model="true" :span-col="true"/>
    </a-modal>
</template>

<script>
    import MonthSelector from "../../../../components/SearchBar/MonthSelector";
    import ArBaseTableFilter from "../../../../components/table/ArBaseTableFilter";
    import attendance from "../../../../assets/api/attendance";
    import moment from "moment";
    import sys_attendance2 from "../../../../assets/tables/sys_attendance2";
    import {SearchOutlined} from "@ant-design/icons-vue"
    import FcForm from "../../../../components/form/FcForm";
    export default {
        name: "AttendanceRecordsFromMonth",
        components:{
            MonthSelector,ArBaseTableFilter,SearchOutlined,FcForm
        },
        data(){
            return{
                table:new sys_attendance2(),
                yearMonth:null,
                argument: {
                    dataSource: null,//提供table的DataSource
                    tableColumns: null, //提供table的字-段集 this.table.getTableColumns()
                    loading: false,//table的loading状态
                },
                visible:false,
                currentRecord:null,
                formColumns:[],
            }
        },
        methods:{
            init(){
                let data = {
                    userId:"",
                    yearMonth:this.yearMonth
                };
                attendance.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            monthChange(){
                this.init()
            },
            showDetail(record){
                this.currentRecord = record;
                this.visible = true;
            }
        },
        created() {
            this.yearMonth = moment(new Date().valueOf()).format("YYYY-MM");
            this.argument.tableColumns = this.table.getTableColumns();
            this.formColumns = this.table.getFormColumns();
            this.init();
        }
    }
</script>

<style scoped>

</style>