/**
 * @program: office_automation
 * @author: ly
 * @description: item_invoice_record_payoff
 * 外协开票给公司的发票（公司付款的发票）
 * @create: 2021-12-15 19:06
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";
import {companyIds, companyNames,companyColors} from "../system/companies";
import api_item_info from "../api/itemInfo";
import {SearchSegment} from "./parts/tableUtils";
import api_item_outsourc_info from "../api/outSourceInfo";

export default class item_invoice_record_payoff extends Table {
    constructor() {
        super();
        this.name = "item_invoice_record_payoff";
        this.CNName = "发票信息";
        this.columnArray = [
            //new Column("发票标题", "invoiceTitle", ColumnType.String, true).setTableView(150),
            new Column("开票金额", "invoiceAmount", ColumnType.Number, true).setTableView(80),
            new Column("已付款项", "payAmount", ColumnType.Number, false).setTableView(80).setGroup(1),
            new Column("付款状态", "payState", ColumnType.Enum, false).setGroup(1)
                .setEnum(["0", "1", "2"], ["未付款", "已付款", "部分付款"],["red","green","pink"]),
            new Column("开票日期", "invoiceTime", ColumnType.Date, true).setTableView(80),
            new Column("发票代码", "invoiceCode", ColumnType.String, true),
            new Column("发票号码", "invoiceNumber", ColumnType.String, true),
            new Column("发票类型", "invoiceType", ColumnType.Enum, true).setDefaultValue("1")
                .setEnum(["1", "2"], ["普票", "专票"],["cyan","pink"])
                .setTriggerOtherColumn("1", (formData, cols) => {
                    cols.filter(col => {
                        return col.dataIndex === "checkCode"
                    })[0]?.setVisible(true);
                }, (formData, cols) => {
                    cols.filter(col => {
                        return col.dataIndex === "checkCode"
                    })[0]?.setVisible(false);
                }),
            new Column("检验码", "checkCode", ColumnType.String, true),
            new Column("付款方", "partyId", ColumnType.Enum, true).setEnum(companyIds, companyNames,companyColors).setGroup(1).setTableView(150),
            new Column("收款方", "companyId", ColumnType.ObjectReplace, true).setGroup(1).setTableView(150)
                .setObjectReplaceObject("outSourceInfoDTO","name", {api:api_item_outsourc_info.queryAll,tag:"queryText"}),
            new Column("项目", "itemId", ColumnType.IdReplace2, true).setGroup(1).setTableView(250)
                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                        "startTime": "", "tenderWay": ""
                    }}),
            new Column("冲红", "flushRed", ColumnType.Boolean, true).setGroup(2)
                .setBoolTags(["未冲红", "已冲红"]).setDefaultValue(false),

        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(90).setFixed("right")];
        this.searchOptions = [
            new SearchSegment("发票标题", "invoiceTitle"),
            new SearchSegment("收款状态", "collectState")
                .setEnum(["0", "1", "2"], ["未收款", "已收款", "部分收款"]),
        ]
    }
}