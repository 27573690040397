<!--
* @program: office_automation 
* @author: ly
* @component:invoiceManager
* @description: 
* @create: 2021-12-16 14:25
-->
<template>
    <div class="oa-content">
        <div class="oa-window" >
            <div v-if="visible" >
                <a-layout-content style="display: inline-flex;margin: 25vh 10vw">
                    <a-card class="mode">
                        <a-button class="cover" type="primary" @click="comp = 'invoiceManagerIncome'">收入发票</a-button>
                    </a-card>
                    <a-card class="mode">
                        <a-button class="cover" type="primary" danger @click="comp = 'invoiceManagerPayoff'">支出发票</a-button>
                    </a-card>
                </a-layout-content>
            </div>
            <div v-else style="height: 100%">
                <a-button danger style="position: absolute" @click=" comp = null">返回</a-button>
                <div v-is="comp" style="height: 100%"/>
            </div>
        </div>
    </div>
</template>

<script>
    import invoiceManagerIncome from "./invoiceManagerIncome";
    import invoiceManagerPayoff from "./invoiceManagerPayoff";
    export default {
        name: "invoiceManager",
        components:{
            invoiceManagerIncome,invoiceManagerPayoff
        },
        computed:{
            visible(){
                return !this.comp;
            }
        },
        data(){
            return{
                comp:null
            }
        },
        methods:{

        }
    }
</script>

<style scoped>
    .mode{
        width: 25vw;
        height: 20vh;
        margin-right: 5vw;
        background: #a1a1a1;
    }
    .cover{
        width: 20vw;
        height: 20vh;
        font-size: 2vw;
    }
</style>