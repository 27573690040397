<template>


    <div class="container">
        <div class="box">内容块1</div>
        <div class="box">内容块2</div>
        <div class="box">内容块3</div>
    </div>
</template>
<script>
    import { defineComponent, onMounted, ref } from 'vue';
    export default defineComponent({
        setup() {
            const visible = ref(true);

            const afterVisibleChange = bool => {
                console.log('visible', bool);
            };

            const showDrawer = () => {
                visible.value = true;
            };

            const onClose = () => {
                visible.value = false;
            };

            onMounted(() => {
                visible.value = false;
            });
            return {
                visible,
                afterVisibleChange,
                showDrawer,
                onClose,
            };
        },
    });
</script>

<style>
    .container {
        max-width: 100%;
        padding: 0 20px;
        margin: 0 auto;
    }

    .box {
        width: 100%;
        float: left;
        margin-bottom: 20px;
        box-sizing: border-box;
    }

    @media (min-width: 768px) {
        .box {
            width: 50%;
        }
    }

    @media (min-width: 1200px) {
        .box {
            width: 33.33%;
        }
    }
</style>