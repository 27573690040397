<!--
* @program: tyh-oa 
* @author: ly
* @component:periodSelector 
* @description: 
* @create: 2021-06-11 14:42
-->
<template>
    <div class="search-item" style="display: flex">
       <div class="statistic-label"> 开始时间：</div> <a-month-picker @change="check" placeholder="请选择月份" style="width: 120px" v-model:value="date1" :format="dateFormat" />
       <div class="statistic-label"> 结束时间：</div> <a-month-picker @change="check" placeholder="请选择月份" style="width: 120px" v-model:value="date2" :format="dateFormat" />
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "periodSelector",
        data(){
            return{
                date1:null,
                date2:null,
                dateFormat: 'YYYY-MM',
                values:["2020-01","2030-12"] //允许的时间范围
            }
        },
        methods:{
            getValue(){
                let date1 = moment(this.date1).format(this.dateFormat);
                let date2 = moment(this.date2).format(this.dateFormat);
                return [date1,date2];
            },
            check(){//禁止date1 时间在date2之后
                if(moment(this.date1).isBefore(this.values[0])){
                    this.date1 = moment(this.values[0]);
                }
                if(moment(this.date2).isAfter(this.values[1])){
                    this.date2 = moment(this.values[1])
                }
                if(moment(this.date1).isAfter(this.date2)){
                    this.date1 = this.date2
                }
            }
        }

    }
</script>

<style scoped>

</style>