<!--
* @program: office_automation 
* @author: ly
* @component:StaffPromotionSetting 
* @description: 
* @create: 2022-03-08 10:21
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <span style="margin: 20px">考核表类型：</span>
                <a-select v-model:value="defaultQuery.type" style="width: 250px" @change="selectChange">
                    <a-select-option v-for="option in options" :key="option.id">{{option.name}}</a-select-option>
                </a-select>
                <span style="margin: 20px">分数合计：{{sumScore}}</span>
                <a-button class="search-item" type="dashed" shape="circle" @click="addData" >
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </div>
            <ArBaseTable :argument="argument" :view-model="true" :page-size="pageSize">
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button class="action-button" type="link" @click="showDetail(record)">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button v-if="record.judgeRoleName !=='系统评分'" class="action-button" type="link" @click="editDetail(record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="record.judgeRoleName !=='系统评分'"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </ArBaseTable>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <div>
            <span>打分角色：</span>
            <a-select v-model:value="roleId" style="width: 250px" @change="selectChange">
                <a-select-option v-for="option in roleOptions" :key="option.id">{{option.roleName}}</a-select-option>
            </a-select>
        </div>
        <ArBaseForm :argument="formArg" :view-model="viewModel" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
</template>

<script>
    import MonthlyAssessmentSetting from "../../staffAssessment/MonthlyAssessment/MonthlyAssessmentSetting";
    export default {
        name: "StaffPromotionSetting",
        mixins:[MonthlyAssessmentSetting],
        data() {
            return {
                options:[
                    {id:"9",name:"技术岗晋升考核"},
                    {id:"10",name:"工勤岗晋升考核"}
                ],
                defaultQuery: {
                    type:"9",
                },
            }
        },
    }
</script>

<style scoped>

</style>