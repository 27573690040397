<!--
* @program: tyh-oa 
* @author: ly
* @description: menuContent 每个菜单页面的基本样式
* @create: 2021-03-22 16:30
-->
<template>
    <a-layout style="height: 100%" >
        <a-layout-sider :collapsible="true" :collapsedWidth="40">
            <a-menu
                theme="dark"
                v-model:openKeys="openKeys"
                v-model:selectedKeys="selectedKeys"
                @openChange="onOpenChange"
                @click="handleClick">
                <sub-group-menu v-for="(group,index) in menuLists" :index="index" :groupMenus="group" :key="group.id" :openKeys="openKeys" />
            </a-menu>
        </a-layout-sider>
        <a-layout-content>
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import subGroupMenu from "./subGroupMenu";
    import {EmitData} from "../assets/utils/general";
    import welcome from "../view/welcome";
    import nonComponent from "../view/nonComponent";
    import {MenuUnfoldOutlined,MenuFoldOutlined} from "@ant-design/icons-vue"
    export default {
        name: "menuContent",
        components:{
            subGroupMenu,
            welcome,nonComponent,MenuUnfoldOutlined,MenuFoldOutlined
        },
        inject:["subMenus","menuKey"],//所选中的menuKey
        emits:{
            event:(data)=>{
                if(data instanceof EmitData){
                    return true;
                }
                else{
                    console.warn("menuContent event data type error!");
                    return false;
                }
            }
        },
        data(){
            return{
                menuLists:[],
                contentType:"welcome",
                openKeys:[],
                rootSubmenuKeys:[],
                selectedKeys:[],
                selectedKey:null,
            }
        },
        methods:{
            handleInit(menuLists,menuKey){ //如果传入menuKey则直接处于触发该menu的状态
                this.menuLists = menuLists;
                this.openKeys = [];
                this.rootSubmenuKeys =[];
                for(let i in this.menuLists){
                    if(this.openKeys.length === 0){
                        this.openKeys.push(this.menuLists[i].id);
                    }
                    this.rootSubmenuKeys.push(this.menuLists[i].id);
                }
                this.selectedKey = menuKey;
                if(this.selectedKey){
                    this.selectedKeys = [this.selectedKey];
                    this.handleClick({key:this.selectedKey});
                }
            },
            handleClick({key}){//通过菜单KEY找到它对应的控件名
                let type = "nonComponent";
                for(let i in this.menuLists){
                    for(let j in this.menuLists[i].children){
                        if(key === this.menuLists[i].children[j].id){
                            type = this.menuLists[i].children[j].component?this.menuLists[i].children[j].component:"nonComponent";
                        }
                    }
                }
                this.contentType = type;
            },
            onOpenChange(openKeys){
                if(openKeys.length>1){
                    this.openKeys.shift();
                }
            }
        },
        created() {
            this.handleInit(this.subMenus,this.menuKey);
        }
    }
</script>

<style scoped>

</style>