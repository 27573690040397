<!--
* @program: tyh-oa 
* @author: ly
* @component:billViewerAllsp2 账单查询   账单一览/本部门
* @description: 
* @create: 2021-05-27 11:25
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <span>月份：</span>
                <MonthPicker @change="doSearch" style="width: 120px" v-model:value="queryOptions.recordYearMonth" />
                <QueryFilter :filter="filter" v-model:value="queryOptions" @change="doSearch" />
            </div>
            <TwoParts ref="TwoParts" @change="handleChange" pos="transform">
                <template #left>
                    <TcBaseTable ref="bill_table" :view-model="true" :page-size="pageSize" @pageChange="handlePageChange"
                                 :loading="loading" :data-source="dataSource"  :table-columns="columns">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <template #icon>
                                        <SearchOutlined class="pointer"/>
                                    </template>
                                </a-button>
                                <a-popconfirm
                                        @confirm="downloadFlows(record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="导出流水？">
                                    <a-button class="action-button" type="link">
                                        <DownloadOutlined class="pointer"/>
                                    </a-button>
                                </a-popconfirm>
                                <a-popconfirm
                                        @confirm="submit(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="提交"
                                        title="提交审核？"
                                        v-if="checkAction(record)">
                                    <a-tooltip title="提交审核">
                                        <a-button class="action-button" style="color: green" type="link">
                                            <ArrowUpOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <billTabs ref="local_tabs" @event="eventHandle"/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import {computed} from "vue";
    import billViewerAll from "../billViewerAll/billViewerAll";
    import {dateFormat} from "../../../assets/utils/general";
    import api_item_finance_record from "../../../assets/api/record";

    export default {
        name: "billViewerAllsp2",
        mixins:[billViewerAll],
        provide(){
            return{
                toolBarTips:"菜单说明：部门负责人专用，可以查看本部门已经提交的账单",
                departments:computed(()=>this.departments)
            }
        },
        data(){
            return{
                queryOptions:{
                    //entryState:null, //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                    approvalState:"1", //审核状态（0：未完成；1：已完成）
                    submitState:"1", //提交状态（0：未提交；1：已提交）
                    recordYearMonth:dateFormat('YYYY-MM',new Date()), // 账单所属年月
                },
                departments:[],
                api_page:api_item_finance_record.queryPageCommon,
            }
        },
        created() {
            this.departments = this.$store.getters.userInfo.departmentDTOList;
            if(this.departments.length===1){
                this.departmentId = this.departments[0].id;
            }
            else{
                this.$message.error("部门信息错误，查询条件出错");
            }
        }
    }
</script>

<style scoped>

</style>