<!--
* @program: office_automation 
* @author: ly
* @component:projectInfo 
* @description: 
* @create: 2021-12-20 10:01
-->
<template>
    <div v-if="newProject">
        <a-layout-content style="display: inline-flex;margin: 25vh 10vw">
            <a-card class="mode">
                <a-button @click="selectType('0')" class="cover" type="primary">自研项目</a-button>
            </a-card>
            <a-card class="mode">
                <a-button @click="selectType('1')" class="cover" danger type="primary">甲方项目</a-button>
            </a-card>
            <a-card class="mode">
                <a-button @click="selectType('2')" class="cover" danger
                          style="background-color: Turquoise;border-color: Turquoise;" type="primary">劳务协作项目
                </a-button>
            </a-card>
            <a-card class="mode">
                <a-button @click="selectType('3')" class="cover" danger style="background-color: #6a33c2;border-color: #6130a5;"
                          type="primary">三方项目
                </a-button>
            </a-card>
        </a-layout-content>
    </div>
    <div style="height: 700px;overflow: auto" v-else>
        <a-spin :spinning="spinning">
            <a-page-header
                    style="border: 1px solid rgb(235, 237, 240)"
                    v-if="!viewModel"
            >
                <template #title>
                    <div style="display: flex">
                        <!--                        {{mFormData.itemType === '0'?'自研项目':'甲方项目'}}&nbsp;&nbsp;-->
                        {{getItemName()}}&nbsp;&nbsp;
                        <!--                        <div >{{mFormData.itemType === '0'?"项目预算":"项目金额"}}：</div>-->
                        <div>{{getItemMony()}}：</div>
                        <div>
                            <a-input-number
                                    :max="100000000"
                                    :min="0"
                                    style="width:200px" v-model:value="mFormData.itemBudget"/>

                        </div>
                        <div v-if="mFormData.itemBudget>0">&nbsp;&nbsp;{{cnNumber}}</div>

                        <div>&nbsp;&nbsp; 等效合同额：</div>
                        <a-input-number
                                :max="100000000"
                                :min="0"
                                style="width:200px" v-model:value="dxhte"/>
                        <a-button @click="saveDxhte" class="search-item" type="primary">
                            保存等效合同额
                        </a-button>
                    </div>
                </template>
            </a-page-header>
            <div style="display: flex;width: 100%;overflow: auto">
                <TagShow :color="'blue'" :tag="'基础信息'">
                    <template #content>
                        <FcForm :form-columns="formColumns" :view-model="viewModel" class="local-form"
                                v-model:form-data="mFormData"/>
                    </template>
                </TagShow>
                <TagShow :color="'pink'" :tag="'合同相关'" v-if="mFormData.itemType !== '0'">
                    <template #content>
                        <FcForm :form-columns="formColumns2" :view-model="viewModel" class="local-form"
                                v-model:form-data="mFormData"/>
                        <TagShow :tag="contractInfoDTO?'甲方合同信息':'未签订甲方合同'" color="pink">
                            <template #content>
                                <div style="padding: 8px" v-if="contractInfoDTO">
                                    签订公司：{{contractInfoDTO.sigCompanyName?contractInfoDTO.sigCompanyName:"签订公司数据错误"}}
                                    <br/>
                                    甲方：{{contractInfoDTO.associatedName?contractInfoDTO.associatedName:"甲方数据错误"}}
                                    <br/>
                                    合同金额：{{contractInfoDTO.amount?contractInfoDTO.amount:"未填入合同金额"}}
                                </div>
                            </template>
                        </TagShow>
                    </template>
                </TagShow>
                <TagShow :color="'cyan'" :tag="'归档状态'" v-if="mFormData.id">
                    <template #content>
                        <FcForm :form-columns="formColumns1" :view-model="viewModel" class="local-form"
                                v-model:form-data="mFormData"/>
                    </template>
                </TagShow>
                <TagShow :color="'orange'" tag="项目创建和审核" v-if="viewModel">
                    <template #content>
                        <FcForm :form-columns="formColumns3" :view-model="viewModel" class="local-form"
                                v-model:form-data="mFormData"/>
                    </template>
                </TagShow>
                <TagShow color="red" tag="项目完结信息" v-if="mFormData.completeFlag !== '0'">
                    <template #content>
                        <FcForm :form-columns="formColumns4" :view-model="viewModel" style="width:600px"
                                v-model:form-data="mFormData"/>
                    </template>
                </TagShow>
            </div>
            <a-divider style="margin:  5px 0"/>
            <a-button @click="saveData" type="primary" v-if="!viewModel">提交</a-button>
            <a-button @click="newProject = true" danger style="margin-left: 20px" v-if="!viewModel && !projectId">
                选择项目类型
            </a-button>
        </a-spin>
    </div>
</template>

<script>
    import FcForm from "../../../components/form/FcForm";
    import TagShow from "../../_components/TagShow";
    import item_info from "../../../assets/tables/item_info";
    import api_item_info from "../../../assets/api/itemInfo";

    export default {
        name: "projectInfo",
        props: ["projectId", "viewModel"],
        emits: ["refresh"],
        components: {
            FcForm, TagShow
        },
        watch: {
            projectId() {
                this.init()
            }
        },
        computed: {
            cnNumber() {
                return TransformToChinese(this.mFormData.itemBudget) + "元"
            }
        },
        data() {
            return {
                mFormData: {},
                table: new item_info(),
                formColumns: [],
                formColumns1: [],
                formColumns2: [],
                formColumns3: [],
                formColumns4: [],
                formColumns5: [],
                newProject: false,
                spinning: false,
                contractInfoDTO: null,
                dxhte: 0,
                dxhteEdit: false
            }
        },
        methods: {
            saveData() {
                api_item_info.saveOrUpd.requestPOST(this, this.mFormData, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            saveDxhte() {
                let data = {
                    id: this.projectId,
                    dxhte: this.dxhte
                };
                api_item_info.updDxhte.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            selectType(type) {
                this.newProject = false;
                this.mFormData.itemType = type;

                if (this.formColumns) {
                    for (let i = 0; i < this.formColumns.length; i++) {
                        let col = this.formColumns[i];
                        if (col.dataIndex == "partyInfoId") {
                            if (type == '1' || type == '3') {
                                col.visible = true;
                            } else {
                                col.visible = false;
                            }

                        }
                    }
                }
            },
            init() {
                if (this.projectId) {
                    this.newProject = false;
                    let data = {
                        id: this.projectId
                    };
                    this.spinning = true;
                    api_item_info.queryOne.requestPOSTUrlParam(this, data, res => {
                        this.spinning = false;
                        if (res.data.flag) {
                            let info = res.data.data;
                            this.dxhte = info.dxhte;

                            info.sumAmount = 0;
                            let contractInfoDTOs = info.contractInfoDTOs;
                            if (contractInfoDTOs != null && contractInfoDTOs.length > 0) {
                                contractInfoDTOs.forEach((item_c) => {
                                    if (item_c.type === '1' || item_c.type === '4') {
                                        info.partyInfoId = item_c.associatedKey;
                                        info.partyInfoName = item_c.associatedName;
                                    }
                                    if (item_c.type === '1' || item_c.type === '4' || item_c.type === '7') {
                                        info.sumAmount += item_c.amount;
                                    }
                                })
                            }
                            if (info.sumAmount > info.sumCollect) {
                                info.sumCollectNo = info.sumAmount - info.sumCollect
                            }


                            this.mFormData = info;
                            if (this.mFormData.contractInfoDTO) {
                                this.contractInfoDTO = this.mFormData.contractInfoDTO;
                                this.$emit("contractInfo", this.contractInfoDTO)
                            }
                            info.departmentName = info.departName;


                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                } else {
                    this.newProject = true;
                    this.mFormData = this.table.getFormData();
                }
            },
            getItemName() {
                let titile = "";
                let type = this.mFormData.itemType;
                switch (type) {
                    case "0": {
                        titile = "自研项目";
                        break;
                    }
                    case "1": {
                        titile = "甲方项目";
                        break;
                    }
                    case "2": {
                        titile = "劳务协作项目";
                        break;
                    }
                    case "3": {
                        titile = "三方项目";
                        break;
                    }
                }
                return titile;
            },
            getItemMony() {
                let titile = "";
                let type = this.mFormData.itemType;
                switch (type) {
                    case "0": {
                        titile = "项目预算";
                        break;
                    }
                    case "1": {
                        titile = "项目金额";
                        break;
                    }
                    case "2": {
                        titile = "项目金额";
                        break;
                    }
                    case "3": {
                        titile = "项目金额";
                        break;
                    }
                }
                return titile;
            },
        },
        created() {
            this.formColumns = this.table.getFormColumns().filter(item => item.group === 0);
            this.formColumns1 = this.table.getFormColumns().filter(item => item.group === 1);
            this.formColumns2 = this.table.getFormColumns().filter(item => item.group === 2);
            this.formColumns3 = this.table.getFormColumns().filter(item => item.group === 3);
            this.formColumns4 = this.table.getFormColumns().filter(item => item.group === 4);
            this.formColumns5 = this.table.getFormColumns().filter(item => item.group === 5);
            this.init()
        }
    }

    let chnNumChar = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    let chnUnitSection = ["", "万", "亿", "万亿", "亿亿"];
    let chnUnitChar = ["", "拾", "佰", "仟"];

    function numToChn(num) {
        let index = num.toString().indexOf(".");
        if (index !== -1) {
            let str = num.toString().slice(index);
            let a = "点";
            for (let i = 1; i < str.length; i++) {
                a += chnNumChar[parseInt(str[i])];
            }
            return a;
        } else {
            return '';
        }
    }

    //定义在每个小节的内部进行转化的方法，其他部分则与小节内部转化方法相同
    function sectionToChinese(section) {
        let str = '', chnstr = '', zero = false, count = 0;   //zero为是否进行补零， 第一次进行取余由于为个位数，默认不补零
        while (section > 0) {
            let v = section % 10;  //对数字取余10，得到的数即为个位数
            if (v === 0) {                    //如果数字为零，则对字符串进行补零
                if (zero) {
                    zero = false;        //如果遇到连续多次取余都是0，那么只需补一个零即可
                    chnstr = chnNumChar[v] + chnstr;
                }
            } else {
                zero = true;           //第一次取余之后，如果再次取余为零，则需要补零
                str = chnNumChar[v];
                str += chnUnitChar[count];
                chnstr = str + chnstr;
            }
            count++;
            section = Math.floor(section / 10);
        }
        return chnstr;
    }

    //定义整个数字全部转换的方法，需要依次对数字进行10000为单位的取余，然后分成小节，按小节计算，当每个小节的数不足1000时，则需要进行补零
    /**
     * @return {string}
     */
    function TransformToChinese(num) {
        let a = numToChn(num);
        num = Math.floor(num);
        let unitPos = 0;
        let strIns = '', chnStr = '';
        let needZero = false;

        if (num === 0) {
            return chnNumChar[0];
        }
        while (num > 0) {
            let section = num % 10000;
            if (needZero) {
                chnStr = chnNumChar[0] + chnStr;
            }
            strIns = sectionToChinese(section);
            strIns += (section !== 0) ? chnUnitSection[unitPos] : chnUnitSection[0];
            chnStr = strIns + chnStr;
            needZero = (section < 1000) && (section > 0);
            num = Math.floor(num / 10000);
            unitPos++;
        }

        return chnStr + a;
    }
</script>

<style scoped>
    .local-form {
        width: 490px
    }
</style>