<!--
* @program: TYH_office_automation 
* @author: ly
* @component:billToolBar2 
* @description: 
* @create: 2021-07-14 15:56
-->
<template>
    <div  class="search-bar">
        <div style="margin: 10px;color: black">查询范围:</div>
        <a-select v-model:value="value"
                  style="width: 120px"
                  class="search-item"
                  @change="filterChange"
        >
            <a-select-option v-for="option in kinds" :key="option.value">
                {{ option.label }}
            </a-select-option>
        </a-select>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
            <template #icon><SyncOutlined /></template>
        </a-button>
    </div>
</template>

<script>
    import {SyncOutlined} from "@ant-design/icons-vue"
    export default {
        name: "billToolBar2",
        components:{SyncOutlined},
        data(){
            return{
                value:"0",
                kinds:[
                    {value:"0",label:"待入账"},
                    //{value:"2",label:"拒绝入账"},
                    {value:"3",label:"撤销入账"},
                    {value:"1",label:"对公入账"},
                    {value:"4",label:"私人入账"},
                ],
                filter:{}
            }
        },
        methods:{
            filterChange(){
                switch (this.value) {
                    case -1:{
                        this.filter.entryState = null;
                        break;
                    }
                    default:{
                        this.filter.entryState = this.value;
                    }
                }
                this.$emit("event",this.$GetEmitData("filter_change",this.filter));
            },
            refresh(){
                this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
            }
        }
    }
</script>

<style scoped>

</style>