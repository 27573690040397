<!--
* @program: office_automation 
* @author: ly
* @component:memberMonthMoney 
* @description: 月度绩效分配
* @create: 2022-04-18 14:13
-->
<template>
    <a-spin :spinning="spinning">
        <a-calendar v-model:value="calendarValue" v-if="userId"  mode="year" :locale="locale" @select="showList">
            <template #headerRender="{ value, type, onChange, onTypeChange }">
                <div style="padding: 10px;display: flex">
                    <a-row type="flex" justify="flex-start" style="width: 400px;font-size: 16px">
                        <a-col>
                            <a-radio-group button-style="solid"  :value="type" @change="e =>{return  onTypeChange(e.target.value)}">
<!--                                <a-radio-button value="month">工作日志</a-radio-button>-->
                                <a-radio-button value="year">月度绩效考核分配</a-radio-button>
                            </a-radio-group>
                        </a-col>
                        <a-col>
                            <a-select
                                    :dropdown-match-select-width="false"
                                    class="my-year-select"
                                    :value="String(value.year())"
                                    @change="newYear => {onChange(value.clone().year(newYear));}"
                            >
                                <a-select-option
                                        v-for="val in getYears(value)"
                                        :key="String(val)"
                                        class="year-item"
                                >
                                    {{ val }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col>
                            <a-select
                                    :dropdown-match-select-width="false"
                                    :value="String(value.month())"
                                    @change="selectedMonth => {onChange(value.clone().month(parseInt(selectedMonth, 10)));}"
                            >
                                <a-select-option
                                        v-for="(val, index) in getMonths(value)"
                                        :key="String(index)"
                                        class="month-item"
                                >
                                    {{ val }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </div>
            </template>
            <template #monthCellRender="{ current: value }">
                <div  class="notes-month">
                    <ul class="events">
                        <li >
                            <a-badge v-if="getMonthMoney(value)" status="success" :text="getMonthMoney(value)" />
                        </li>
                    </ul>
                </div>
            </template>
        </a-calendar>
    </a-spin>
    <a-drawer v-model:visible="visible"
              height="400px"
              placement="bottom"
              :title="null">
        <TcBaseTable :data-source="dataSource" :table-columns="tableColumns" :view-model="true" :page-size="10"/>
    </a-drawer>
</template>

<script>
    import taskMonthUser from "../../../assets/api/taskMonthUser";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    export default {
        name: "memberMonthMoney",
        props:["userId"],
        components:{
            TcBaseTable
        },
        watch:{
            userId(){
                this.init()
            }
        },
        data() {
            return {
                calendarValue:null,
                locale:{
                    "lang": {
                        "month": "月",
                        "year": "年",
                    },
                    "dateFormat": "YYYY-MM-DD",
                    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
                    "weekFormat": "YYYY-wo",
                    "monthFormat": "YYYY-MM"
                },
                dateFormat: "YYYY-MM-DD",
                monthFormat: "YYYY-MM",

                dataSource:null,//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态

                allRecords:[],
                visible:false,
                spinning:false,
            }
        },
        methods: {
            init() {
                let data = {
                    userId:this.userId
                };
                this.spinning = true;
                taskMonthUser.queryAll.requestPOST(this,data,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        this.allRecords = res.data.data
                    }
                    else{
                        this.allRecords = [];
                    }
                })
            },
            getMonths(value) {
                const current = value.clone();
                const localeData = value.localeData();
                const months = [];

                for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                }
                return months;
            },
            getYears(value){
                const year = value.year();
                const years = [];

                for (let i = year - 10; i < year + 10; i += 1) {
                    years.push(i);
                }
                return years;
            },
            getMonthMoney(value){
                let sum = 0;
                let m = value.format(this.monthFormat);
                for(let i in this.allRecords){
                    if(m === this.allRecords[i].yearMonth){
                        sum += this.allRecords[i].completeMoney;
                        sum += this.allRecords[i].leaderMoney
                    }
                }
                return sum;
            },
            showList(date){
                this.dataSource = [];
                let m = date.format(this.monthFormat);
                for(let i in this.allRecords){
                    if(m === this.allRecords[i].yearMonth){
                        this.dataSource.push(this.allRecords[i])
                    }
                }
                if(this.dataSource.length >0){
                    this.visible = true;
                }
                else{
                    this.$message.error("没有绩效分配记录")
                }
            }
        },
        created() {
            this.init();
            this.tableColumns = [
                new Column("年月","yearMonth",ColumnType.Month,true).setTableView(80),
                new Column("任务名称","taskTitle",ColumnType.String,false).setTableView(300),
                new Column("用户名","userName",ColumnType.String,false).setTableView(100),
                new Column("作业绩效","completeMoney",ColumnType.Number,true).setTableView(100),
                new Column("负责人","leaderMoney",ColumnType.Number,true).setTableView(100),
                new Column("说明","remarks",ColumnType.String,true).setTableView(200),
            ];
        }
    }
</script>

<style scoped>

</style>