<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentAimSetting 
* @description: 部门年度目标绩效设置
* @create: 2022-01-14 10:55
-->
<template>
    <div style="display: flex;flex-wrap: wrap;">
        <a-card style="width: 300px;margin: 10px" size="small" v-for="(depart,idx) in departments"  :key="idx">
            <template #title>
                {{depart.name}}
                <a-button class="search-item" type="dashed" shape="circle" @click="addData(depart)">
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </template>
            <a-list :loading="loading" style="height: 200px;overflow: auto" size="small" bordered :data-source="depart.dataSource">
                <template #renderItem="{ item }">
                    <a-list-item class="center-flex">
                        {{ item.year }}年度:{{item.achiev}}元
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(item)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </a-list-item>
                </template>
            </a-list>
        </a-card>
    </div>

    <a-modal v-model:visible="visible"
             title="添加年度计划">
        <div>
            <span>年度：</span><a-input-number :min="2019" :max="2050" style="width:200px" v-model:value="year"/>
        </div>
        <div>
            <span>绩效：</span><a-input-number :min="0"  style="width:200px" v-model:value="achiev"/>
        </div>
        <template #footer>
            <a-button type="primary"  @click="saveData">
                提交
            </a-button>
        </template>
    </a-modal>
</template>

<script>
    import department from "../../../../assets/api/department";
    import depYearKpi from "../../../../assets/api/depYearKpi";
    import {PlusOutlined,DeleteOutlined} from "@ant-design/icons-vue"
    export default {
        name: "DepartmentAimSetting",
        components:{PlusOutlined,DeleteOutlined},
        data(){
            return{
                departments:[],
                visible:false,
                year:2019,
                achiev:null,
                currentDepartment:null,
                loading:false
            }
        },
        methods:{
           init(){
               department.queryAll.requestPOST(this,{},res=>{
                   if(res.data.flag){
                       this.departments = res.data.data.filter(item=>{return item.departType === "0"});
                       this.init2()
                   }
                   else{
                       this.departments = []
                   }
               });
            },
            init2(){
                for(let i in this.departments){
                    this.departments[i].dataSource = [];
                }
                this.loading = true;
                depYearKpi.queryAll.requestPOST(this,{},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        for(let i in res.data.data){
                            let arr = this.departments.filter(item=>item.id === res.data.data[i].departId);
                            if(arr.length ===1){
                                arr[0].dataSource.push(res.data.data[i]);
                            }
                        }
                    }
                });
            },
            addData(item){
                this.year = null;
                this.achiev = null;
                this.currentDepartment = item;
                this.visible = true;
            },
            saveData(){
               let data = {
                   "achiev": this.achiev,
                   "departId": this.currentDepartment.id,
                   "year": this.year
               };
               depYearKpi.saveOrUpd.requestPOST(this,data,res=>{
                   if(res.data.flag){
                       this.$message.success(res.data.msg);
                       this.init2();
                   }
                   else{
                       this.$message.error(res.data.msg);
                   }
               })
            },
            deleteData(item){
               let data = {
                   id:item.id
               };
               depYearKpi.delete.requestPOSTUrlParam(this,data,res=>{
                   if(res.data.flag){
                       this.$message.success(res.data.msg);
                       this.init2();
                   }
                   else{
                       this.$message.error(res.data.msg);
                   }
               })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>