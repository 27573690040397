<!--
* @program: office_automation 
* @author: ly
* @component:itemConfirmMoneyArrange
* @description: 项目兑付
* @create: 2022-04-06 15:34
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
          <div  class="search-bar">
            <a-select v-model:value="departmentId" style="width: 200px"
                      class="search-item" @change="doSearch"
                      placeholder="承担部门"
                      allow-clear>
              <a-select-option v-for="{id,fullName} in departments" :key="id" >
                {{fullName}}
              </a-select-option>
            </a-select>

            <a-input  class="search-item"
                      v-model:value="year" style="width:200px" placeholder="请输入项目所属年份" />
            <a-input  class="search-item"
                      v-model:value="name" style="width:200px" placeholder="请输入项目名称" />
          <a-select
              v-model:value="havePayRecord"
              style="width: 150px"
              placeholder="有无兑付记录"
              allow-clear
              :options="op_havePayRecord"
              @change="doSearch"
          >
          </a-select>

            <a-button class="search-item" style="margin-left: 20px" type="dashed" shape="circle" @click="doSearch">
              <template #icon><SearchOutlined /></template>
            </a-button>
            <a-button  class="search-item" type="default" shape="circle" @click="refresh">
              <template #icon><SyncOutlined title="重置"/></template>
            </a-button>

            <a-button type="primary" @click="downFile" style="margin-left: 10px">
              <template #icon><DownloadOutlined title="导出报表"/></template>导出报表
            </a-button>
<!--              <a-button type="primary" @click="getAllItem" style="margin-left: 10px">-->
<!--                  <template #icon><DownloadOutlined title="导出报表"/></template>getAllItem-->
<!--              </a-button>-->
          </div>
            <TcBaseTable   :table-columns="tableColumn" :loading="loading"
                           :data-source="dataSource" :view-model="true" :page-size="15" >
                <template #action="{record}">
                    <div class="action-column">
                        <a-tooltip title="拟下绩效">
                            <a-button  class="action-button" type="link" @click="confirmMoneys(record)">
                                <StrikethroughOutlined class="pointer" />
                            </a-button>
                        </a-tooltip>
                        <a-tooltip title="兑付记录">
                            <a-button @click="honourDetail(record)"  class="action-button" type="link">
                                <SwapOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip title="合作部门">
                            <a-button @click="honourDetail_dept(record)" class="action-button" type="link">
                                <ClusterOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </TcBaseTable>
        </div>
    </div>


  <a-modal v-model:visible="visible_money"
           @ok="handleOk"
           ok-text="确认"
           cancel-text="取消"
           title="拟下绩效">
    拟下绩效：<a-input-number  style="width: 60%"
                      v-model:value="confirmMoney"   />
  </a-modal>

  <a-modal v-model:visible="visible_df"
           destroyOnClose
           width="50%"
           :footer="null"
           :title="null">
    <depart-payment ref="departPayment" :projectId="projectId" :viewModel="viewModel" :record="taskInfo"/>
  </a-modal>

  <a-modal v-model:visible="visible_dep"
           destroyOnClose
           width="50%"
           :footer="null"
           :title="null">

    <ArBaseTable  :argument="argument" :view-model="true" :page-size="5" >
      <template #action="{record}" >
        <div class="action-column">
          <a-button @click="showDetail_dep(record)"  title="查看" class="action-button" type="link">
            <SearchOutlined class="pointer"/>
          </a-button>
        </div>
      </template>
    </ArBaseTable>
  </a-modal>


  <a-modal v-model:visible="visible_show"
           destroyOnClose
           :footer="null"
           :title="null">
    <ArBaseForm :argument="formArg"> </ArBaseForm>
  </a-modal>


  <div v-if="loadingVisible" class="loading">
    <a-space>
      <a-spin size="large" />
    </a-space>
  </div>
</template>

<script>
import {RollbackOutlined,SearchOutlined,ClusterOutlined,
  SyncOutlined,SwapOutlined,StrikethroughOutlined,DownloadOutlined,TeamOutlined} from "@ant-design/icons-vue"
import TcBaseTable from "../../../components/table/TcBaseTableFilter";
import ArBaseTable from "../../../components/table/ArBaseTable";
import ArBaseForm from "../../../components/form/ArBaseForm";
import DepartmentPicker from "../../../components/UserPicker/DepartmentPicker";
import FcForm from "../../../components/form/FcForm";
import itemInfo from "../../../assets/api/itemInfo";
import itemPerformance from "./config/itemPerformance";
import departPayment from "../departPayment/departPayment";
import api_sys_department from "../../../assets/api/department";
import api_item_task from "../../../assets/api/itemTask";
import item_task3 from "../../../assets/tables/item_task3";
import moment from "moment";
import taskMonthUser from "../../../assets/api/taskMonthUser";
import userMoneys_item from "./config/userMoneys_item";
import Column from "../../../assets/tables/parts/column";
import ColumnType from "../../../assets/tables/parts/column_type";

export default {
    name: "itemConfirmMoneyArrange",
    components:{
      DepartmentPicker,ArBaseTable,ArBaseForm,SyncOutlined,TeamOutlined,
      TcBaseTable,FcForm,departPayment,StrikethroughOutlined,DownloadOutlined,
      RollbackOutlined,SearchOutlined,SwapOutlined,ClusterOutlined
    },
    data() {
        return {
          departmentId:null,
          departments:[],
          year:null,
          name:null,
          activeKey:'1',

          havePayRecord:null,
          op_havePayRecord:[
            {value: true,label: '有兑付记录',},
            {value: false,label: '没有兑付记录',},
          ],

          table_item:new itemPerformance(),
          tableColumn:new itemPerformance().getTableColumns(),
          dataSource:[],
          loading:false,

          tableColumn2:[//合同情况
              new Column("项目年度","year",ColumnType.Number).setTableView(80),
              new Column("项目名称","name",ColumnType.String).setTableView(300),
              new Column("收款合同金额","incomeSum",ColumnType.Number).setTableView(100),
              new Column("已收款","ysk",ColumnType.Number).setTableView(100),
              new Column("未收款","wsk",ColumnType.Number).setTableView(100),
              new Column("付款合同金额","outcomeSum",ColumnType.Number).setTableView(100),
              new Column("最终核准金额","realPaySum",ColumnType.Number).setTableView(100),
              new Column("已支付","yzf",ColumnType.Number).setTableView(100),
              new Column("未支付","wzf",ColumnType.Number).setTableView(100),
          ],
          dataSource2:[],

          visible_df:false,
          projectId:null,
          taskInfo:null,
          viewModel:false,

          itemTaskDTO:null,
          visible_money:false,
          confirmMoney:0,

            currentItem:null,

          table:new item_task3(),
          visible_dep:false,
          argument:{
            dataSource:null,//提供table的DataSource
            tableColumns:[],
            loading:false,//table的loading状态
          },
          table_user:new userMoneys_item(),
          formArg:{
            formColumns:[],
            formData:null,
          },
          visible_show:false,

          loadingVisible:false,

          date1:null,
          date2:null,
          dateFormat: 'YYYY-MM',
        }
    },
    created() {
      this.init();
      this.depart();
    },
    methods: {
      depart(){
        api_sys_department.queryAll.requestPOST(this,{},res=>{
          if(res.data.flag){
            this.departments=res.data.data.filter(item=>item.departType === "0");
          }
          else{
            this.$message.error(res.data.msg);
          }
        });

      },
      init() {
        this.dataSource = [];
        let parms = {
          year:this.year,
          name:this.name,
          auditState:'1',
          haveTask:true,
          depId:this.departmentId,
          havePayRecord:this.havePayRecord,
        };
        this.loading = true;
        itemInfo.queryAllExport.requestPOST(this,parms,res=>{
          this.loading = false;
          if(res.data.flag){
            let list = res.data.data;
            if(list != null && list.length > 0){
              list.forEach((item,index)=>{
                  let contractInfos = item.contractInfos;
                  let amountSum = 0;
                 if(contractInfos != null && contractInfos.length > 0){
                   contractInfos.forEach((item_c,index_c)=>{
                        if(item_c.type == '1' || item_c.type == '4'){
                            amountSum = parseFloat(amountSum) + parseFloat(item_c.amount);
                        }
                   })
                 }
                item.amountSum = amountSum.toFixed(2);

                 if(item.itemTaskDTO == null){
                   item.money =  0;
                   item.confirmMoney =  0;
                   item.depName = null;
                   item.payAmountNo =  0;
                   item.payAmountYes =  0;
                 }else{
                   item.money = item.itemTaskDTO.money;
                   item.confirmMoney = item.itemTaskDTO.confirmMoney;
                   item.depName = item.itemTaskDTO.depName;
                    let paymentList = item.itemTaskDTO.paymentList;
                    let payAmountSum = 0
                    if(paymentList != null && paymentList.length > 0){
                      paymentList.forEach((item_p,index_p)=>{
                        payAmountSum = parseFloat(payAmountSum) + parseFloat(item_p.payAmount);
                      })

                    }
                   item.payAmountYes = payAmountSum.toFixed(2);
                   item.payAmountNo = (item.confirmMoney - payAmountSum).toFixed(2);
                 }

              })
            }
              this.dataSource = list.sort((a,b)=>{
                  return parseInt(b.year) - parseInt(a.year);
              });
          }
          else{
            this.dataSource = [];
            this.$message.error(res.data.msg);
          }
        });
      },
      init2() {
        this.dataSource2 = [];
        let parms = {
          year:this.year,
          name:this.name,
          auditState:'1',
          haveTask:true,
          depId:this.departmentId,
          startMonth:this.date1 == null ? null : moment(this.date1).format(this.dateFormat),
          endMonth:this.date2 == null ? null : moment(this.date2).format(this.dateFormat),
        };
        this.loading = true;
        taskMonthUser.itemPersonMoney.requestPOST(this,parms,res=>{
          this.loading = false;
          if(res.data.flag){
            let list = res.data.data;
            if(list != null && list.length > 0){
              list.forEach((item,index)=>{


              })
            }
            this.dataSource2 = list;
          }
          else{
            this.dataSource2 = [];
            this.$message.error(res.data.msg);
          }
        });
      },
      doSearch(){
          this.init();
      },
      refresh(){
          this.year = null;
          this.name = null;
          this.departmentId = null;
          this.havePayRecord = null;
          this.init();
      },
      downFile(){
        this.loadingVisible = true;
          let parms = {
              year:this.year,
              name:this.name,
              auditState:'1',
              haveTask:true,
              depId:this.departmentId,
              havePayRecord:this.havePayRecord,
          };
          let fileName = this.year == null || this.year == undefined || this.year == '' ? "年度部门绩效汇总表.xls" : this.year+"年度部门绩效汇总表.xls";
          itemInfo.exportReport.fileDownloadPost(this, parms,fileName );
        setTimeout(()=>{
          this.loadingVisible = false;
        },1000);
      },
      showDetail(record){
        this.formArg.formColumns = this.table_item.getFormColumns(),
        this.formArg.formData = record;
        this.visible_show = true;
        this.vModel = true;
      },
      confirmMoneys(record){
        if(record.itemTaskDTO == null){
          this.$message.success("项目还未分配任务！");
          return;
        }
        this.currentItem = record;
        this.itemTaskDTO = record.itemTaskDTO;
        this.confirmMoney = record.itemTaskDTO.confirmMoney;
        this.visible_money = true;
      },
      handleOk(){
        let parms ={
          id:this.currentItem.itemTaskDTO.id,
          confirmMoney:this.confirmMoney,
        };
        api_item_task.updMoney.requestPOSTUrlParam(this,parms,res=>{
          if(res.data.flag){
            this.visible_money = false;
              this.currentItem.confirmMoney = this.confirmMoney;
              this.currentItem.itemTaskDTO.confirmMoney = this.confirmMoney;
            this.$message.success(res.data.msg);
          }
          else{
            this.$message.error(res.data.msg);
          }
        });
      },
      honourDetail(record){
        this.projectId = record.id;
        this.taskInfo = record.itemTaskDTO;
        this.viewModel = false;
        this.visible_df = true;
      },
      honourDetail_dept(record){
        this.argument.tableColumns = this.table.getTableColumns();
        let parms = {
          pid:record.itemTaskDTO.id
        };
        api_item_task.queryAll.requestPOSTUrlParam(this,parms,res=>{
          if(res.data.flag){
            this.argument.dataSource = res.data.data;
          }
          else{
            this.argument.dataSource = []
          }
        })
        this.viewModel = false;
        this.visible_dep = true;
      },
      showUserMoneys(record){
        this.argument.dataSource = [];
        this.argument.tableColumns = this.table_user.getTableColumns();
        this.argument.dataSource = record.userMoneys;
        this.viewModel = false;
        this.visible_dep = true;
      },
      showDetail_dep(record){
        this.formArg.formColumns = this.table.getFormColumns(),
        this.formArg.formData = record;
        this.visible_show = true;
        this.vModel = true;
      },

      check(){//禁止date1 时间在date2之后
        if(moment(this.date1).isAfter(this.date2)){
          this.date1 = this.date2
        }
      },
        getAllItem(){
          itemInfo.queryAllItem.requestPOST(this,{"year": "2021"},res=>{
              if(res.data.flag){
                  console.log(res.data.data)
              }
              else{
                  this.$message.error(res.data.msg);
              }
          })
        }
    },
}
</script>

<style scoped>
.loading{
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.5);
  display:flex;
  justify-content:center;
  align-items:center;
}
</style>