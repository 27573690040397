<!--
* @program: TYH_office_automation 
* @author: ly
* @component:personalAssetsAccess 设备申请
* @description: 
* @create: 2021-06-22 10:25
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <a-radio-group class="search-item" v-model:value="queryOptions.state" @change="doSearch">
                    <a-radio-button value="1">启用</a-radio-button>
                </a-radio-group>
                <a-radio-group class="search-item" v-model:value="queryOptions.useState" @change="doSearch">
                    <a-radio-button value="0">未借出</a-radio-button>
                </a-radio-group>
                <a-radio-group class="search-item" v-model:value="queryOptions.mainFlag" @change="doSearch">
                    <a-radio-button value="1">主设备</a-radio-button>
                </a-radio-group>
                <assetsCategoryTree class="search-item" @change="doSearch" v-model:value="queryOptions.categoryId" />
                <a-button class="search-item" type="dashed"  @click="showHistory">
                    <template #icon><SearchOutlined /></template>申请历史
                </a-button>
            </div>
            <div class="main-local">
                <TcBaseTableFilter :loading="loading" :view-model="true" :page-size="pageSize" :table-columns="tableColumns" :data-source="dataSource" >
                    <template #action="{record}">
                        <div style="text-align: center">
                            <a-button v-if="!record.mainId"  @click="borrowAssets(record)" class="action-button" type="link">
                                申请
                            </a-button>
                        </div>
                    </template>
                </TcBaseTableFilter>
            </div>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="申请理由">
        <a-textarea v-model:value="reason" />
        <a-button size="small" type="primary" style="margin: 20px" @click="saveBorrow">
            提交
        </a-button>
    </a-modal>
    <a-drawer v-model:visible="modalVisible"
              height="600px"
              placement="bottom"
              :closable="false"
             title="申请历史"
             :footer="null">
        <TcBaseTable :view-model="true" :page-size="15" :table-columns="tableColumns2" :data-source="dataSource2" >

        </TcBaseTable>
    </a-drawer>
</template>

<script>
    import assetsView from "../../assetsManager/assetsView/assetsView";
    import api_device_apply from "../../../assets/api/deviceApply";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import device_apply from "../../../assets/tables/device_apply";
    export default {
        name: "personalAssetsAccess",
        components:{TcBaseTable},
        mixins:[assetsView],
        data(){
            return{
                dataSource2:[],
                tableColumns2:new device_apply().getTableColumns(),
                modalVisible:false
            }
        },
        methods:{
            borrowAssets(record){
                this.loading = true;
                let data = {
                    deviceId:record.id
                };
                this.currentRecord = record;
                api_device_apply.queryAll.requestPOST(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        let arr = res.data.data.filter(item=>{
                            return item.auditState === "0";
                        });
                        if(arr.length>0){
                            this.$message.error("【"+arr[0].userName+"】已申请该设备，但还未审核完成")
                        }
                        else{
                            this.visible = true;
                        }
                    }
                    else{
                        this.visible = true;
                    }
                })
            },
            showHistory(){
                let data = {userId: this.$store.getters.userInfo.id};
                api_device_apply.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource2 = res.data.data;
                        this.modalVisible = true
                    }
                })
            },
            saveBorrow(){
                let data = {
                    "deviceId": this.currentRecord.id,
                    "purposeDescri": this.reason,
                };
                api_device_apply.saveOrUpd.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>