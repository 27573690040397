<!--
* @program: office_automation 
* @author: ly
* @component:taskProcessProgressChart 
* @description: 用于展示任务工序的完成进度
* @create: 2022-01-06 17:11
-->
<template>
    <a-spin :spinning="spinning">
        <TagShow style="width: 300px" :color="'green'" :visible="visible" :tag="'【'+process.title+'】完成情况：'">
            <template #content>
                <div v-if="process.unsetPercent">有【{{process.unsetPercent}}%】的工作量没有分配</div>
                <div class="center-flex">
                    <a-popover trigger="hover" placement="bottom">
                        <template #content>
                            <div style="font-size: 16px;font-weight: 900;text-align: center">【{{process.title}}】完成详情</div>
                            <div class="center-flex" style="margin: 5px" v-for="(list,idxx) in process.children" :key="idxx" >
                                <div>
                                    <div>
                                        {{list.title+"(占比"+list.scale+"%)"}}<span v-if="list.percent===100">提交完成时间：{{list.completeDate}}</span>
                                    </div>
                                    <div>
                                        工期安排：{{list.startDate+"——"+list.endDate}}
                                    </div>
                                </div>
                                <a-progress style="width: 200px"   :percent="list.percent" />
                            </div>
                        </template>
                        <a-progress type="circle"  :percent="process.percent" />
                    </a-popover>
                </div>
            </template>
        </TagShow>
    </a-spin>
</template>

<script>

    import workSituation from "../../../assets/api/workSituation";
    import api_item_task_process_employee from "../../../assets/api/processEmployee";
    import TagShow from "../../_components/TagShow";
    export default {
        name: "taskProcessProgressChart",
        props:["record","percent","visible"],//工序的所有人员的工作日志  ，工序标题，工序分配的人员
        emits:["update:percent"], //本进度完成的整体状态
        components:{
            TagShow
        },
        data(){
            return{
                process:{},
                workSituations:[],
                spinning:false,
            }
        },
        methods:{
            init(){
                let data = {
                    processId:this.record.id
                };
                this.spinning = true;
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        for(let i in res.data.data){
                            let {userName,workRatio,startDate,endDate,completeDate} = res.data.data[i];
                            this.process.children.push(
                                new Process(userName,workRatio,startDate,endDate,completeDate)
                            );
                            this.process.unsetPercent -= res.data.data[i].workRatio;
                        }
                        this.process.unsetPercent = Math.round(this.process.unsetPercent);
                        workSituation.queryAll.requestPOST(this,data,res=>{
                            this.spinning = false;
                            if(res.data.flag){
                                this.workSituations = res.data.data;
                                this.progressCalc();
                            }
                            else{
                                if(res.data.msg !== "无数据"){
                                    this.$message.error(res.data.msg);
                                }
                            }
                        });
                    }
                    else{
                        this.spinning = false;
                        if(res.data.msg !== "无数据"){
                            this.$message.error(res.data.msg);
                        }
                    }
                });
            },
            progressCalc(){
                for(let i in this.workSituations){
                    let children = this.process.children.filter(item => item.title === this.workSituations[i].userName);
                    if(children.length === 1){
                        children[0].percent += this.workSituations[i].workRatio;
                    }
                }
                for(let i in this.process.children){
                    let realPercent = this.process.children[i].percent >100?100:this.process.children[i].percent;//超过100的工作量按100%计算进度
                    this.process.percent +=  realPercent * this.process.children[i].scale/100;
                }
                this.$emit("update:percent",this.process.percent);
            }
        },
        created() {
            this.process = new Process(this.record.title,0,this.record.startDate,this.record.endDate);
            this.init()
        }
    }
    class Process{
        constructor(title,scale,startDate,endDate,completeDate) {
            this.title = title;
            this.percent =  0; //本步骤的完成情况
            this.children = [];
            this.scale = scale; //本步骤占上一级的占比
            this.unsetPercent = 100; //children中scale合计不足100的情况，用100减去合计的值得到的结果
            this.startDate= startDate?new Date(startDate).toLocaleDateString():"未填写"; //任务开始时间
            this.endDate = endDate?new Date(endDate).toLocaleDateString():"未填写";   //任务结束时间
            this.completeDate = completeDate?new Date(completeDate).toLocaleDateString():"未提交";   //任务实际完成时间
        }
    }
</script>

<style scoped>

</style>