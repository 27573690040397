<!--
* @program: tyh-oa 
* @author: ly
* @component:billViewerAllsp3 账单一览/总经理  只看总经理的账单
* @description: 
* @create: 2021-05-27 11:25
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <span>月份：</span>
                <MonthPicker @change="doSearch" style="width: 120px" v-model:value="queryOptions.recordYearMonth" />
                <QueryFilter :filter="filter" v-model:value="queryOptions" @change="doSearch" />
            </div>
            <TwoParts ref="TwoParts" @change="handleChange" pos="transform">
                <template #left>
                    <TcBaseTable ref="bill_table" :view-model="true" :page-size="pageSize" @pageChange="handlePageChange"
                                 :loading="loading" :data-source="dataSource"  :table-columns="columns">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <template #icon>
                                        <SearchOutlined class="pointer"/>
                                    </template>
                                </a-button>
                                <a-popconfirm
                                        @confirm="downloadFlows(record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="导出流水？">
                                    <a-button class="action-button" type="link">
                                        <DownloadOutlined class="pointer"/>
                                    </a-button>
                                </a-popconfirm>
                                <a-popconfirm
                                        @confirm="submit(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="提交"
                                        title="提交审核？"
                                        v-if="checkAction(record)">
                                    <a-tooltip title="提交审核">
                                        <a-button class="action-button" style="color: green" type="link">
                                            <ArrowUpOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <billTabs ref="local_tabs" @event="eventHandle"/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import billViewerAll from "../billViewerAll/billViewerAll";
    import {dateFormat} from "../../../assets/utils/general";
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    import moment from "moment";

    export default {
        name: "billViewerAllsp3",
        mixins:[billViewerAll],
        data(){
            return{
                searchOptions:[
                    new SearchSegment("账单标题", "title"),
                ],
                queryOptions:{//当前页面的查询默认条件
                    recordYearMonth:dateFormat('YYYY-MM',new Date()),//当前月份
                    approvalState:"1",
                    submitState:"1",
                    userId:"45"  //夏总UserId
                },
            }
        },
      methods:{
        checkAction(record){
          if(record.entryState === "3"){//撤销入账
            return true;
          }
          let time = parseInt(localStorage.getItem("time"));
          let ym = new moment(time).format("YYYY-MM");
          let day = new moment(time).format("DD");
          let flag = false;
          if(record.recordYearMonth === ym){// 当月
            if(record.state === "3"){
              flag = true;
            }else if(day<=20){
              flag = true;
            }
          }
          return record.submitState === "0" && flag;
        }
      }
    }
</script>

<style scoped>

</style>