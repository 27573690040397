/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class depmentWater_item extends Table {
    constructor() {
        super();
        this.name = "depmentWater_item";
        this.CNName = "部门流水列表";
        this.columnArray = [
            new Column("报账人姓名","userName",ColumnType.String,true).setTableView(150),
            new Column("费用类别名称","categoryName",ColumnType.String,true).setTableView(150),
            new Column("报账单标题","recordTitle",ColumnType.String,true).setTableView(150),
            new Column("支出产生时间","flowTime",ColumnType.Date,true).setTableView(110),
            new Column("有无发票","invoiceState",ColumnType.Enum,true).setTableView(100)
                .setEnum([true,false],
                    ["有","无"],
                    ["Pink","MediumPurple"]),
            new Column("支出金额","invoiceAmount",ColumnType.Number,true).setTableView(150),
            new Column("报销金额","applyAmount",ColumnType.Number,true).setTableView(150),
        ];

    }
}