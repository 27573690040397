/**
 * 成果管理
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"

export default class achieveRecord_item extends Table{
    constructor() {
        super();
        this.name = "achieveRecord_item";
        this.CNName = "成果管理";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("成果名称","name",ColumnType.String,true).setTableView(),
            new Column("成果类型","typeName",ColumnType.String,true).setTableView(),
            new Column("行政区划","areaName",ColumnType.String,true).setTableView(),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(250)];
    }
}