<!--
* @program: tyh-oa 
* @author: ly
* @component:typeTextArea 
* @description: 
* @create: 2021-04-12 17:46
-->
<template>
    <a-textarea :disabled="!editable" @input="columnTrigger" :rows="rows"  v-model:value="record[column.dataIndex]"/>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeTextArea",
        mixins:[typeBase],
        computed:{
            rows(){
                return this.column.rows?this.column.rows:2
            }
        }
    }
</script>

<style scoped>

</style>