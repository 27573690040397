<!--
* @program: tyh-oa 
* @author: ly
* @component:baseTableView 
* @description: 
* @create: 2021-04-09 15:06
-->
<template>
    <a-table
            class="myTable"
            bordered
            :dataSource="source"
            :columns="cols" rowKey="id"
            :pagination="pagination"
            :loading="loading"
    >
        <template #String="{column,record}">
            <type-string :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Number="{column,record}">
            <type-number :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Enum="{column,record}">
            <type-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Date="{column,record}">
            <type-date :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Month="{column,record}">
            <type-month :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Index="{column,record,index}">
            <type-index :currentIndex="currentIndex" :index="index" :passedNum="passedNum" :viewModel="viewModel" :column="column" :record="record"/>
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Category="{column,record}">
            <type-category :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #CategoryAssets="{column,record}">
            <type-category-assets :viewModel="viewModel" :column="column" :record="record" />
        </template>
    </a-table>
</template>

<script>
    import baseTable from "./baseTable";
    export default {
        name: "baseTableView",
        mixins:[baseTable],
        data(){
            return{
                viewModel:true
            }
        }
    }
</script>

<style scoped>

</style>