/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_qc_report_process  质检工序报告一览
 * @create: 2021-04-25 11:42
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_qc_report_process extends Table{
    constructor() {
        super();
        this.name = "item_qc_report_process";
        this.CNName = "项目质检报告管理";
        this.columnArray = [
            // new Column("质检ID","processId",ColumnType.IdReplace,true)processTitle
            //     .setIdReplaceObject("processName",{}),
            new Column("质检工序","processTitle",ColumnType.String,false).setTableView(150),
            new Column("报告评价","assessment",ColumnType.Enum,false).setTableView(80)
                .setEnum(["0","1","2","3"],["不合格","合格","良好","优秀"],["red","pink","gold","green"]),
            new Column("质检总结","qcSummary",ColumnType.String,false).setTableView(200),
            new Column("报告附件","enclosureUrl",ColumnType.PreViewFile,false).setTableView(150),
            new Column("质检人","createUserName",ColumnType.String,false).setTableView(100),
            new Column("额外说明","remarks",ColumnType.String,false),
        ];
    }
}