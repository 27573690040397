<!--
* @program: office_automation 
* @author: ly
* @component:YearMoneyArrange 
* @description: 年度绩效分配
* @create: 2023-01-04 16:55
-->
<template>
    <a-spin :spinning="spinning">
        <div class="info-item">
            <span style="margin-left: 20px;font-size: 18px;font-weight: 600">项目拟下绩效</span>
            <span style="margin-left: 10px; font-size: 18px;font-weight: 600;color:#0350a4">{{confirmMoney}}</span>
            <span style="margin-left: 20px;font-size: 18px;font-weight: 600">已下兑付【年度-金额】</span>
            <span style="margin-left: 10px;font-size: 18px;font-weight: 600;color:#0350a4">{{paymentDetail.str}}</span>
<!--            <span style="margin-left: 20px;font-size: 18px;font-weight: 600">剩余兑付(项目拟下绩效 - 已下兑付)</span>-->
<!--            <span style="margin-left: 10px;font-size: 18px;font-weight: 600;color:#0350a4">{{confirmMoney - paymentSum}}</span>-->
        </div>
        <div style="margin: 5px 10px;background: #FFFFFF;box-shadow: 0px 2px 6px 1px rgba(106,121,146,0.15);">
            <div style="text-align: left; margin-left: 20px;font-size: 18px;font-weight: 600">兑付分配记录(合计{{arrangedDetail2.sum}})</div>
            <div v-for="item in YM.YearArrange" :key="item.year"
                 style="margin-left: 40px;text-align: left; font-size: 18px;font-weight: 600;color:#0350a4">
                {{item.year +" - "+(paymentDetail.yearSum[item.year]?paymentDetail.yearSum[item.year]:0)}}
                <span style="text-align: left; font-size: 16px;color:#012543">
                    {{YM.stringFyYear(item.year)}}
                    未分配：{{(paymentDetail.yearSum[item.year]?paymentDetail.yearSum[item.year]:0) - YM.yearSum(item.year)}}
                </span>
            </div>
        </div>
    </a-spin>
    <TcBaseTable :title="true" :table-columns="tableColumns" :data-source="source"
                 :view-model="true" :page-size="9" :scroll="{x:700}">
        <template #title >
            <span style="margin-left: 20px;font-size: 18px;font-weight: 600">兑付记录  年度：</span>
            <a-select  v-model:value="year" style="width:200px" class="search-item" :options="years"/>
            <a-tooltip  title="添加拟下绩效记录" >
                <a-button @click="addData" type="primary" style="margin:0 10px">
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-button @click="init" type="primary" style="margin:0 10px">
                <template #icon><SyncOutlined /></template>
            </a-button>
        </template>
        <template #action="{index,record}">
            <div class="action-column">
                <a-button class="action-button" type="link" @click="editDetail(record)">
                    <EditFilled class="pointer"/>
                </a-button>
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </TcBaseTable>

    <a-modal @cancel="init" :width="600" v-model:visible="visible" title="拟下绩效" :footer="null">
        <div style="margin-left: 20px;font-size: 18px;font-weight: 600">{{year}}年度兑付{{paymentDetail.yearSum[year]?paymentDetail.yearSum[year]:0}} 未分配：{{(paymentDetail.yearSum[year]?paymentDetail.yearSum[year]:0) - YM.yearSum(year)}}</div>
        <div style="display: flex;align-items: center;justify-content: center;">
            手动录入：
            <DepartmentUserPicker :members="members" :department-id="departmentId" v-model:value="user" />
            <a-input-number v-model:value="tMoney" :min="0"/>
            <a-button  type="primary" @click="handleOk">提交</a-button>
        </div>
        <div>注：自动填入工具只能分配参与工序工作的成员，其他人员只能手动录入。</div>
        <div v-if="workerRadio.length>0">
            <a-divider >辅助自动填入工具</a-divider>
            <div style="margin-left: 20px;font-size: 18px;font-weight: 600">任务负责人【{{record.taskLeaderName}}】比例合计：{{workerRadioSum.toFixed(2)}}%</div>
            <div style="display: flex;align-items: center;justify-content: center;">
                <div>分配总额：</div>
                <a-input-number v-model:value="money" :min="0"/>
                <a-button  type="link" @click="autofilled" style="cursor: pointer">批量填入</a-button>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;">
                <div style="width:200px" v-for="item in workerRadio" :key="item.id">
                    {{item.userName}}({{item.ratio}}%)
                    <a-input-number v-model:value="item.value" :precision="2" :min="0"/>
                </div>
            </div>
            <a-button  type="primary" @click="updateList">提交</a-button>
        </div>
        <div v-else>
            <div style="margin-left: 20px;font-size: 18px;font-weight: 600">
                未查询到【{{year}}】年度分配记录，请联系任务负责人【{{record.taskLeaderName}}】
            </div>
        </div>
    </a-modal>
    <a-modal v-model:visible="visible2"
             @ok="saveData2"
             ok-text="确认"
             cancel-text="取消"
             title="拟下绩效">
        绩效：<a-input-number style="width:100px" v-model:value="currentRecord.confirmMoney"/>
    </a-modal>
</template>

<script>
    import taskMonthUser from "../../../assets/api/taskMonthUser";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {PlusOutlined,EditFilled,DeleteOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import DepartmentUserPicker from "../../../components/UserPicker/DepartmentUserPicker";
    import taskUserRatio from "../../../assets/api/taskUserRatio";
    import userDepartRelation from "../../../assets/api/userDepartRelation";
    export default {
        name: "YearMoneyArrange",
        props:["record"],
        components:{
            PlusOutlined,EditFilled,DeleteOutlined,SyncOutlined,TcBaseTable,DepartmentUserPicker
        },
        computed:{
            historyPay(){
                let sum = 0;
                for(let item of this.paymentList){
                    let year = new Date(item.payDate).getFullYear();
                    if(year === 2021){//2021年的记录为历史兑付
                        sum += item.payAmount;
                    }
                }
                return sum;
            },
            confirmMoney(){
                if(this.record){
                    return this.record.confirmMoney
                }
                else{
                    return 0
                }
            },
            paymentList(){
                if(this.record && this.record.paymentList && this.record.paymentList.length>0 && this.departmentId){
                    return this.record.paymentList.filter(item=>{
                        return item.departId === this.departmentId
                    })
                }
                else{
                    return []
                }
            },
            paymentSum(){
                let sum = 0;
                for(let item of this.paymentList){
                    sum += item.payAmount;
                }
                return sum;
            },
            paymentDetail(){ //已兑付详情
                let str = "";
                let yearSum = {};
                for(let item of this.paymentList){
                    let year = new Date(item.payDate).getFullYear();
                    str += "【" + year + "-" + item.payAmount +"】";
                    yearSum[year] = item.payAmount;
                }
                return {str,yearSum}
            },
            arrangedMoneys2(){//往上提升一级可以触发computed
                return this.AM2.arrangedMoneys
            },
            arrangedDetail2(){ //已经分配的年度绩效详情
                let str = "";
                let sum = 0;
                for(let item of this.arrangedMoneys2){
                    str += item.name + ":";
                    for(let it of item.arrangedMoney){
                        str += "【" + it.year +"-" + it.money + "】";
                        sum += it.money;
                    }
                }
                if(str === ""){
                    str = "未查询到记录"
                }
                return {
                    str:str,
                    sum:sum
                }
            },

            departmentId(){
                let id = this.$store.getters.userInfo?.departmentDTOList[0].id;
                return id?id:null;
            },
            source(){//通过年度筛选的分配记录
                return this.dataSource.filter(item=>{
                    let arr = item.yearMonth.split("-");
                    return arr[0] === this.year.toString()
                })
            },
            workerRadioSum(){
                let sum = 0;
                if(this.workerRadio.length>0){
                    for(let item of this.workerRadio){
                        sum += item.ratio;
                    }
                }
                return sum;
            }
        },
        data() {
            return {
                tableColumns:[
                    //new Column("年度","yearMonth",ColumnType.Month,true).setTableView(80),
                    new Column("用户名","userName",ColumnType.String,false).setTableView(80),
                    new Column("拟下绩效","confirmMoney",ColumnType.Number,false).setTableView(100)
                        .setDefaultValue(0),
                    new Column("操作","actions","actions").setTableView(80).setPosition("right")
                ],
                AM2:new ArrangedMoneys(), //已分配的拟下绩效
                YM:new YearArranges(),
                dataSource:[],
                visible:false,
                visible2:false,
                years:[
                    {title:2021,value:2021},
                    {title:2022,value:2022},
                    {title:2023,value:2023},
                ],
                year:2023,
                currentRecord:{},
                spinning:false,
                user:null,
                money:0,
                tMoney:0,
                workerRadio:[],//工作量分配记录
                members:[]
            }
        },
        methods: {
            init() {
                this.AM2 = new ArrangedMoneys();
                this.spinning = true;
                let data = {
                    "taskId": this.record.id
                };
                taskMonthUser.queryAll.requestPOST(this,data,res=>{//得到每个人已经分配的绩效
                    if(res.data.flag){
                        for(let item of res.data.data){
                            let {userId,userName,yearMonth,confirmMoney} = item;
                            let m2 = new ArrangedMoney(userId,userName,yearMonth,confirmMoney); //拟下绩效
                            this.AM2.addArrangedMoney(m2);
                        }
                        this.YM.init(this.AM2.arrangedMoneys);
                        this.dataSource = res.data.data.filter(item=>{
                            let arr = item.yearMonth.split("-");
                            return arr[1] === "11";//以11月来计算年终
                        })
                    }
                    this.spinning = false;
                });
                let data2 = {
                    departmentId:this.departmentId,
                };
                userDepartRelation.queryAll.requestPOSTUrlParam(this,data2,res=>{
                    if(res.data.flag){
                        this.members = res.data.data;
                    }
                    else{
                        this.members = null;
                    }
                })
            },
            addData(){
                this.visible = true;
                let data = {taskId:this.record.id,year:this.year.toString()};
                taskUserRatio.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.workerRadio = res.data.data;
                    }
                    else{
                        this.workerRadio = [];
                    }
                })
            },
            handleOk(){
                let parms = {
                    completeMoney:0,
                    leaderMoney:0,
                    taskId:this.record.id,
                    completeRatio:0,
                    userId:this.user.id,
                    yearMonth:this.year+"-11",
                    confirmMoney:this.tMoney,
                };
                taskMonthUser.saveOrUpd.requestPOST(this,parms,res=>{
                    if(res.data.flag){
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(item){
                let parms = {
                    completeMoney:0,
                    leaderMoney:0,
                    taskId:this.record.id,
                    completeRatio:0,
                    userId:item.id,
                    yearMonth:this.year+"-11",
                    confirmMoney:item.confirmMoney,
                    remarks:item.remarks,
                };
                taskMonthUser.saveOrUpd.requestPOST(this,parms,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editDetail(record){
                this.visible2 = true;
                this.currentRecord = record;
            },
            saveData2(){
                let parms = {
                    id:this.currentRecord.id,
                    confirmMoney:this.currentRecord.confirmMoney,
                };
                taskMonthUser.modifyMoney.requestPOSTUrlParam(this,parms,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible2 = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(index,record){
                let parms = {
                    id:record.id,
                    confirmMoney:0,
                };
                taskMonthUser.modifyMoney.requestPOSTUrlParam(this,parms,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible2 = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            updateList(){
                for(let item of this.workerRadio){
                    let record = this.source.find(source=>source.userId === item.userId);
                    if(record){
                        let parms = {
                            id:record.id,
                            confirmMoney:item.value?Math.round(item.value * 100)/100:0,
                        };
                        taskMonthUser.modifyMoney.requestPOSTUrlParam(this,parms,res=>{
                            if(res.data.flag){
                                this.$message.success(res.data.msg);
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        })
                    }
                    else{
                        let parms = {
                            completeMoney:0,
                            leaderMoney:0,
                            taskId:this.record.id,
                            completeRatio:0,
                            userId:item.userId,
                            yearMonth:this.year+"-11",
                            confirmMoney:item.value?Math.round(item.value * 100) / 100:0,
                        };
                        taskMonthUser.saveOrUpd.requestPOST(this,parms,res=>{
                            if(res.data.flag){
                                this.$message.success(res.data.msg);
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        })
                    }
                }
            },
            autofilled(){
                for(let item of this.workerRadio){
                    if(item.ratio){
                        item.value = item.ratio * this.money/100
                    }
                }
            }
        },
        created() {
            this.init();

        }
    }
    class ArrangedMoneys{
        constructor() {
            this.arrangedMoneys = [];
        }
        addArrangedMoney(arrangedMoney){
            let arr = this.arrangedMoneys.filter(item=>{
                return item.name === arrangedMoney.name
            });
            if(arr.length > 0){
                arr[0].addItem(arrangedMoney);
            }
            else{
                this.arrangedMoneys.push(arrangedMoney)
            }
        }

    }
    class ArrangedMoney{
        constructor(id,name,yearMonth,money) {
            this.id = id;
            this.name = name;
            let arr = yearMonth.split("-");
            let year = parseInt(arr[0]);
            let month = parseInt(arr[1]);
            if(month === 12){year ++;}
            this.arrangedMoney = [{
                year:year,
                money:money
            }];
        }
        addItem(item){
            let {year , money} = item.arrangedMoney[0];
            let arr = this.arrangedMoney.filter(it=>{
                return it.year === year;
            });
            if(arr.length>0){
                arr[0].money += money
            }
            else{
                this.arrangedMoney.push(item.arrangedMoney[0]);
            }
        }
    }

    class YearArranges{
        constructor() {
            this.YearArrange = [];
        }
        init(arrangedMoneys){
            this.YearArrange = [];
            for(let am of arrangedMoneys){
                let {id,name,arrangedMoney} = am;
                for(let it of arrangedMoney){
                    let {year,money} = it;
                    let arr = this.YearArrange.filter(item=>{
                        return item.year === it.year
                    });
                    if(arr.length > 0){
                        arr[0].list.push({id,name,year,money});
                    }
                    else{
                        this.YearArrange.push({
                            year:year,
                            list:[{id,name,year,money}]
                        })
                    }
                }
            }
        }
        yearSum(year){
            let yearsum = 0;
            let item = this.YearArrange.find(item=>{
                return item.year === year;
            })
            if(item){
                for(let it of item.list){
                    yearsum += it.money;
                }
            }
            return yearsum
        }
        stringFy(){
            let str = "";
            if(this.YearArrange.length>0){
                for(let item of this.YearArrange){
                    str += item.year +"年度 ";
                    let yearsum = 0;
                    for(let it of item.list){
                        str += it.name + "（" + it.money +"）";
                        yearsum += it.money;
                    }
                    str += "合计："+yearsum + "  ";
                }
            }
            return str;
        }
        stringFyYear(year){
            let str = "";
            let item = this.YearArrange.find(item=>{
                return item.year === year;
            });
            if(item){
                let yearsum = 0;
                for(let it of item.list){
                    str += it.name + "[" + it.money +"]";
                    yearsum += it.money;
                }
                str = "分配合计"+yearsum.toFixed(2) + "：" + str;
            }
            return str
        }
    }
</script>

<style scoped>

</style>