<!--
* @program: tyh-oa 
* @author: ly
* @component:userPasswordModify 
* @description: 
* @create: 2021-05-25 17:42
-->
<template>
    <a-card class="user-card">
        <a-form style="margin: 10px" layout="horizontal"
                :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item  label="原密码" >
                <a-input  style="width:100%" v-model:value="originPW" @input="checkPassword" />
            </a-form-item>
            <a-form-item  label="新密码" >
                <a-input-password  style="width:100%" v-model:value="newPW" @input="checkPassword" />
            </a-form-item>
            <a-form-item  label="确认新密码" >
                <a-input-password  style="width:100%" v-model:value="newPW2" @input="checkPassword" />
            </a-form-item>
            <a-form-item  label="提示" >
                <a-alert :message="tips" :type="tipType" />
            </a-form-item>
        </a-form>
        <a-button  class="search-item" type="primary"  @click="saveData">
            提交
        </a-button>
    </a-card>
</template>

<script>
    import sys_user_info from "../../../assets/api/userInfo";
    import api from "../../../assets/api/_default";
    export default {
        name: "userPasswordModify",
        data(){
            return{
                labelCol:{ span: 6 },
                wrapperCol:{ span: 14 },
                originPW:null,
                newPW:null,
                newPW2:null,
                tipType:"success",
                tips:"请输入原密码"
            }
        },
        methods:{
            saveData(){
                let data = {
                    id:this.$store.getters.userInfo.id,
                    oldPassWord:this.originPW,
                    newPassWord:this.newPW
                };
                if(this.newPW === "000000" || this.newPW === "123456"){
                    this.$message.error("禁止使用初始密码");
                    return;
                }
                sys_user_info.passWordModify.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        api.logout.requestPOST(this,null,res=>{
                            if(res.data.flag){
                                localStorage.setItem("phone","");
                                localStorage.setItem("password","");
                                this.$router.push({ path: '/' });
                            }
                        });
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            checkPassword(){
                if(this.newPW !== this.newPW2){
                    this.tipType = "error";
                    this.tips = "两次输入的密码不一样"
                }
                else{
                    this.tipType = "success";
                    this.tips = "确认后请提交"
                }
                if(!this.newPW2){
                    this.tips = "请再次输入密码"
                }
                if(!this.newPW){
                    this.tips = "请输入密码"
                }
                if(!this.originPW){
                    this.tips = "请输入原密码"
                }
            }
        }
    }
</script>

<style scoped>

</style>