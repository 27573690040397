<!--
* @program: office_automation 
* @author: ly
* @component:SealSetting 
* @description: 印章设定
* @create: 2022-06-07 14:38
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
                <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <PlusOutlined/>
                    </template>
                </a-button>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false">
                <template #left>
                    <ArBaseTable :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                        <template #action="{index,record}">
                            <div class = "action-column">
                                <a-button @click="editDetail(record)"
                                          class="action-button" type="link">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-tooltip title="切换印章状态">
                                    <a-button @click="changeState(record)"
                                              class="action-button" type="link">
                                        <SyncOutlined style="color: #b34b1a" class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-popconfirm
                                        @confirm="deleteData(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="删除？">
                                    <DeleteOutlined class="pointer" style="color: red"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <welcome2 />
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal :footer="null"
             :maskClosable="true"
             title=""
             v-model:visible="visible">
        <ArForm :argument="formArg" :view-model="false">
            <template #action="{record}">
                <a-button @click="saveData(record)" type="primary">
                    提交
                </a-button>
            </template>
        </ArForm>
    </a-modal>
</template>

<script>
    import Welcome2 from "../../welcome2";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import ArForm from "../../../components/form/ArForm";
    import TwoParts from "../../_components/TwoParts";
    import {DeleteOutlined, EditFilled, PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import officialSeal from "../../../assets/api/officialSeal";

    export default {
        name: "SealSetting",
        components: {
            Welcome2,ArBaseTable,ArForm,TwoParts,
            SyncOutlined,PlusOutlined,EditFilled,DeleteOutlined
        },
        data() {
            return {
                pageSize:15,
                visible:false,
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(100),
                        new Column("印章名","name",ColumnType.String,true).setTableView(150),
                        new Column("状态","state",ColumnType.String,true).setTableView(100)
                            .setEnum(["0","1"],["停用","启用"],["red","green"]),
                        new Column("备注","remarks",ColumnType.String,true).setTableView(150),
                        new Column("操作","actions","actions").setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:[
                        new Column("印章名","name",ColumnType.String,true),
                        new Column("备注","remarks",ColumnType.String,true),
                    ], //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                }
            }
        },
        methods: {
            init() {
                officialSeal.queryAll.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            refresh(){
                this.init()
            },
            addData(){
                this.formArg.formData = {
                    name:null,
                    remarks:null,
                    state:"1"
                };
                this.visible = true;
            },
            editDetail(record){
                this.visible = true;
                this.formArg.formData = record;
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                officialSeal.delete.requestPOSTUrlParam(this,data,res=>{
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index, 1);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record){
                officialSeal.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            changeState(record){
                let state = record.state === "0"?"1":"0";
                let data = {
                    batchId:record.id,
                    state:state
                };
                officialSeal.batchModifyState.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        record.state = state;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>