<!--
* @program: office_automation 
* @author: ly
* @component:MonthProcessArrange 
* @description: 月度绩效分配
new Column("作业绩效","depManagExp",ColumnType.Number,false),
new Column("负责人绩效","leaderPercent",ColumnType.Number,false),
* @create: 2022-04-12 09:56
-->
<template>
    <a-spin :spinning="spinning">
        <div style="box-shadow: 0px 0px 5px #888888;height: 100px;width: 98%;margin-left: 10px;overflow: auto;">
          <a-descriptions style="padding: 0 10px;">
            <a-descriptions-item label="作业绩效">{{task.depManagExp}}</a-descriptions-item>
            <a-descriptions-item label="负责人绩效">{{task.leaderPercent}}</a-descriptions-item>
            <a-descriptions-item label="负责人">{{taskLeader.name}}</a-descriptions-item>
<!--            <a-descriptions-item label="项目进度">{{taskPercent_sum}}</a-descriptions-item>
            <a-descriptions-item label="已分作业绩效">{{workMoney_sum}}</a-descriptions-item>
            <a-descriptions-item label="已分负责人绩效">{{leadMoney_sum}}</a-descriptions-item>-->
            <a-descriptions-item label="作业绩效剩余">
              <span>{{(task.depManagExp - moneySum).toFixed(2)}} &nbsp;</span>
            </a-descriptions-item>
            <a-descriptions-item label="负责人绩效剩余">
              <span>{{(task.leaderPercent - moneySum2).toFixed(2)}}</span>
            </a-descriptions-item>
            <a-descriptions-item >
              <a-button type="primary" @click="showFphz">人员分配汇总详情</a-button>
            </a-descriptions-item>
<!--            <a-descriptions-item label="已分作业绩效"  :span="2" >
              <span v-for="item in members" :key="item.id">{{item.name}}:{{item.workMoney.toFixed(2)}}
                <a-divider type="vertical" style="height: 20px; background-color: #7cb305" />
              </span>
            </a-descriptions-item>-->


<!--            <a-descriptions-item label="已分负责人绩效" :span="2" >
              <span v-for="item in members" :key="item.id">{{item.name}}:{{item.leadMoney.toFixed(2)}}
                <a-divider type="vertical" style="height: 20px; background-color: #7cb305" />
              </span>
            </a-descriptions-item>-->

<!--            <a-descriptions-item label="工作量占比" :span="3" >
              <span v-for="item in members" :key="item.id">{{item.name}}:{{item.taskPercent}}
              <a-divider type="vertical" style="height: 20px; background-color: #7cb305" />
              </span>
            </a-descriptions-item>-->
          </a-descriptions>
        </div>
        <a-divider orientation="left" style="color: #7cb305">人员绩效分配详情</a-divider>
        <div style="display: flex">
            <TcBaseTable :title="true" :table-columns="argument.tableColumns" :data-source="ds"
                         :view-model="true" :page-size="9" :scroll="{x:700}">
                <template #title >
                    <span>月份：</span>
                    <MonthPicker  style="width: 120px" v-model:value="filterMonth" />

                  <a-tooltip  title="添加月度绩效分配" >
                    <a-button @click="addData" type="primary" style="margin:0 10px">
                      <template #icon><PlusOutlined /></template>
                    </a-button>
                  </a-tooltip>
                  <!--      <a-button @click="showAll">分配记录</a-button>
                    <a-radio-group v-model:value="currentMember">
                        <a-radio v-for="(item,index) in members" :value="item" :key="index">
                          {{item.name}}
                        </a-radio>
                      </a-radio-group>-->

                  <a-select v-model:value="currentMember"  placeholder="所有成员" style="width: 16%" allow-clear>
                    <a-select-option v-for="(item,index) in members" :key="index" :value="item" > {{item.name}}</a-select-option>
                  </a-select>

                </template>

                <template #action="{index,record}">
                    <div class="action-column">
<!--                      <a-button  class="action-button" type="link" @click="confirmMoneys(record)">-->
<!--                        <StrikethroughOutlined class="pointer" title="拟下绩效"/>-->
<!--                      </a-button>-->
                        <a-button v-if="allowMonth(record.yearMonth,yearMonth)" class="action-button" type="link" @click="editDetail(record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="allowMonth(record.yearMonth,yearMonth)"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </TcBaseTable>
        </div>
    </a-spin>
    <MonthProcessArrangeAdd ref="MonthProcessArrangeAdd" :arranged="{work:moneySum,lead:moneySum2}"  :members="members" :task="task" :argument="argument" @refresh="init"/>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :width="400"
             :footer="null"
             :title="getTitle">
        <a-form layout="horizontal">
            <a-form-item label="预 算 金 额( 作  业  绩  效   )">
                <a-input-number  style="width: 150px"  v-model:value="completeMoney" />
            </a-form-item>
            <a-form-item label="预算金额（负责人绩效）">
                <a-input-number  style="width: 150px"  v-model:value="leaderMoney" />
            </a-form-item>
            <a-form-item label="说  明">
                <a-textarea style="width: 270px;height: 70px" v-model:value="remarks" />
            </a-form-item>
        </a-form>
        <a-button size="small" type="primary" style="margin: 20px" @click="saveData">
            提交
        </a-button>
    </a-modal>

  <!-- 人员分配汇总详情 start -->
  <a-modal v-model:visible="visible_hz"
           destroyOnClose
           :footer="null"
           height="800px"
           width="50%"
           :closable="false"
           title="人员分配汇总详情">

    <!-- table start -->
    <ArBaseTable ref="pstable" :pagination="false" :argument="argument_fphz" :view-model="true"  :scroll="{x:800,y:720}">

    </ArBaseTable>
    <!-- table end -->
    <span style="font-size:20px">
      工作量占比：{{taskPercent_sum}}%<a-divider type="vertical" style="height: 20px; background-color: #7cb305" />
      已分作业绩效：{{workMoney_sum}}<a-divider type="vertical" style="height: 20px; background-color: #7cb305" />
      已分负责人绩效：{{leadMoney_sum}}
    </span>
  </a-modal>

  <!-- 人员分配汇总详情 end -->

  <a-modal v-model:visible="visible_money"
           @ok="handleOk"
           ok-text="确认"
           cancel-text="取消"
           title="拟下绩效">
    拟下绩效：<a-input-number  style="width: 60%"
                          v-model:value="confirmMoney"   />
  </a-modal>

</template>

<script>
import ArBaseTable from "../../../components/table/ArBaseTable";
import Icons from "../../common/Icons";

import taskMonthUser from "../../../assets/api/taskMonthUser";
import TcBaseTable from "../../../components/table/TcBaseTable";
import processEmployee from "../../../assets/api/processEmployee";
import itemTask from "../../../assets/api/itemTask";
import itemTaskProcess from "../../../assets/api/itemTaskProcess";
import item_month_task_user from "../../../assets/tables/item_month_task_user";
import moment from "moment"
import TagShow from "../../_components/TagShow";
import {SearchOutlined,DeleteOutlined,PlusOutlined,EditFilled,StrikethroughOutlined} from "@ant-design/icons-vue"
import MonthPicker from "../../_components/MonthPicker";
import {allowMonth} from "../../../assets/utils/general";

import MonthProcessArrangeAdd3 from "./MonthProcessArrangeAdd3";// 历史
import MonthProcessArrangeAdd from "./MonthProcessArrangeAdd";
import Column from "../../../assets/tables/parts/column";
import ColumnType from "../../../assets/tables/parts/column_type";


export default {
  name: "MonthProcessArrange",
  props:["record"], //task的记录
  mixins:[Icons],
  components:{
    ArBaseTable,MonthPicker,
      TagShow,SearchOutlined,DeleteOutlined,PlusOutlined,EditFilled,
      TcBaseTable,MonthProcessArrangeAdd,StrikethroughOutlined,
    MonthProcessArrangeAdd3
  },
  computed:{
      taskLeader(){
          let arr = this.members.filter(item=>item.leader);
          if(arr.length === 1){
              return arr[0]
          }
          else{
              return new ITask(null,"无任务负责人")
          }
      },
      ds(){
          if(!this.currentMember){
              return this.argument.dataSource.filter(item=>{
                  if(this.filterMonth){
                      return item.yearMonth === this.filterMonth
                  }
                  else{
                      return true
                  }
              })
          }
          else{
              return this.currentMember.moneyArrange.filter(item=>{
                  if(this.filterMonth){
                      return item.yearMonth === this.filterMonth
                  }
                  else{
                      return true
                  }
              })
          }
      },
      moneySum(){
          let sum =0;
          for(let i in this.members){
              sum += this.members[i].workMoney;
          }
          return sum
      },
      moneySum2(){
          let sum =0;
          for(let i in this.members){
              sum += this.members[i].leadMoney;
          }
          return sum
      },
      getTitle(){
          if(this.currentRecord){
              return this.currentRecord.userName + ":"+this.currentRecord.yearMonth
          }
          else{
              return ""
          }
      }
  },
  data() {
      return {
        argument:{
          dataSource:[],//提供table的DataSource
          tableColumns:new item_month_task_user().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
          formColumns:new item_month_task_user().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
          formData:{},
        },
        pageParam:{
          page: 1,
          limit: 10,
        },

          spinning:false,
          loading:false,//table的loading状态

          //工作填写记录
          processId:null,

          members:[],
          currentMember:null,
          task:{},
          taskWeight:0, //任务总权重
          visible:false,

          completeMoney:null,
          leaderMoney:null,
          remarks:null,
          currentRecord:null,
          yearMonth:null,//只允许编辑和修改本月数据

          filterMonth:null,
          allowMonth:allowMonth,
          completeRatio:0,

          // 人员分配汇总详情
        visible_hz:false,
        argument_fphz:{
          dataSource:[],//提供table的DataSource
          tableColumns:null,
          loading:false,
        },
        taskPercent_sum:0,// 项目进度
        workMoney_sum:0,// 已分作业绩效
        leadMoney_sum:0,// 已分负责人绩效

        visible_money:false,
        confirmMoney:0,
      }
  },
  created() {
    this.init();
    let time = parseInt(localStorage.getItem("time"));
    this.yearMonth = new moment(time).format("YYYY-MM")
  },
  methods: {
    init() {
      this.taskWeight = 0;
      this.members = [];
      let data = {
        "taskId": this.record.id
      };
      this.spinning = true;
      itemTask.queryOne.requestPOSTUrlParam(this,{id:this.record.id},res=>{
        this.spinning = false;
        if(res.data.flag){
          this.task = res.data.data;
        }
        else{
          this.task = this.record;
        }
        itemTaskProcess.queryAll.requestPOSTUrlParam(this,data,res=>{//计算当前task的权重和
          if(res.data.flag){
            for(let i in res.data.data){
              this.taskWeight += res.data.data[i].workRatio;
            }
            let leader = new ITask(this.task.taskLeader,this.task.taskLeaderName,this.taskWeight).setLeader();
            this.members.push(leader);
            processEmployee.queryAll.requestPOST(this,data,res=>{//得到项目内每个人的任务工序
              if(res.data.flag){
                for(let i in res.data.data){
                  let {userId,userName,processId,processTitle,processRatio,workRatio} = res.data.data[i];
                  let arr = this.members.filter(item =>item.id === userId);
                  if(arr.length>0){
                    arr[0].addProcess(new Process(processId,processTitle,processRatio,workRatio))
                  }
                  else{
                    let member = new ITask(userId,userName,this.taskWeight);
                    member.addProcess(new Process(processId,processTitle,processRatio,workRatio));
                    this.members.push(member)
                  }
                }

                /*** 处理分配汇总计算 **/
                setTimeout(()=>{
                  if(this.members != null && this.members.length > 0){
                    this.members.forEach((info,idx)=>{
                      /*info.workMoney = info.workMoney != null && info.workMoney != 0 ? parseFloat(info.workMoney).toFixed(2) : 0;
                      info.leadMoney = info.leadMoney != null && info.leadMoney != 0 ? parseFloat(info.leadMoney).toFixed(2) : 0;*/
                      this.taskPercent_sum += parseFloat(info.taskPercent.split("%")[0]);
                      this.workMoney_sum += info.workMoney;
                      this.leadMoney_sum += info.leadMoney;
                    })
                  }
                },300);


                taskMonthUser.queryAll.requestPOST(this,data,res=>{//得到每个人已经分配的绩效
                  if(res.data.flag){
                    this.argument.dataSource = res.data.data;
                    for(let i in this.argument.dataSource){
                      let arr = this.members.filter(item=>item.id === this.argument.dataSource[i].userId)
                      if(arr.length>0){
                        arr[0].addMoneyArrange(this.argument.dataSource[i])
                      }
                    else{
                        console.log(this.argument.dataSource[i])
                      }
                    }
                  }
                  else{
                    this.argument.dataSource = [];
                  }
                });
              }
            })
          }
        });
      });
    },
    addData(){
      let zyjx = (this.task.depManagExp - this.moneySum).toFixed(2);
      let fzrjx = (this.task.leaderPercent - this.moneySum2).toFixed(2);
      let sum = parseFloat(zyjx) + parseFloat(fzrjx);
      if(sum>0){
        this.$refs.MonthProcessArrangeAdd.open();
      }else{
        this.$message.info("作业绩效和负责人绩效剩余为0，不能进行月度绩效分配！");
      }
    },
    deleteData(index,record){
      let data = {
        id:record.id
      };
      taskMonthUser.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          if(!this.currentMember){
            this.argument.dataSource.splice(index,1);
          }
          else{
            this.currentMember.moneyArrange.splice(index,1);
          }
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    confirmMoneys(record){
      this.currentRecord = record;
      this.confirmMoney = record.confirmMoney;
      this.visible_money = true;
    },
    handleOk(){
      let parms = {
        id:this.currentRecord.id,
        confirmMoney:this.confirmMoney,
      };

      taskMonthUser.modifyMoney.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.visible_money = false;
          this.init();
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },
    editDetail(record){
      this.currentRecord = record;
      this.visible = true;
      this.completeMoney = record.completeMoney;
      this.leaderMoney = record.leaderMoney;
      this.remarks = record.remarks;
      this.completeRatio = record.completeRatio;
    },
    saveData(){
      let parms = {
        id:this.currentRecord.id,
        completeMoney:this.completeMoney,
        leaderMoney:this.leaderMoney,
        remarks:this.remarks,
        taskId:this.currentRecord.taskId,
        completeRatio:this.currentRecord.completeRatio,
        userId:this.currentRecord.userId,
        yearMonth:this.currentRecord.yearMonth,
        confirmMoney:this.confirmMoney,
      }

      taskMonthUser.saveOrUpd.requestPOST(this,parms,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.visible = false;
         /* this.currentRecord.completeMoney = this.completeMoney;
          this.currentRecord.leaderMoney = this.leaderMoney;
          this.currentRecord.remarks = this.remarks;*/
          this.init();
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },
    showFphz(){
      this.argument_fphz.tableColumns = [
        new Column("主键", "id", ColumnType.String, false).setVisible(false),
        new Column("姓名", "name", ColumnType.String, true).setTableView(100),
        new Column("工作量占比（%）", "taskPercent", ColumnType.Number, true).setTableView(80),
        new Column("已分作业绩效（￥）", "workMoney", ColumnType.Number, true).setTableView(80),
        new Column("已分负责人绩效（￥）", "leadMoney", ColumnType.Number, true).setTableView(80),
      ];
      this.argument_fphz.dataSource = this.members;
      this.visible_hz = true;
    },


  },

}
class ITask{
  /**
   *
   * @param id 用户id
   * @param name 姓名
   * @param taskWeight 任务工序的权重和
   * @param leader 是不是任务负责人
   * process:{
   *     processId,processTitle,processRatio,workRatio,finishRatio
   * }
   */
  constructor(id,name,taskWeight) {
    this.id = id;
    this.name = name;
    this.taskWeight = taskWeight?taskWeight:1;
    this.processList = [];
    this.workDiary = [];
    this.leader = false;
    this.moneyArrange = [];
  }
  get taskPercent(){ // 所有工序占总权重的百分比
    if(this.taskWeight > 0){
      let percent = 0;
      for(let i in this.processList){
        percent += this.processList[i].processRatio * this.processList[i].workRatio
      }
      percent = percent / this.taskWeight;
      return  percent.toFixed(2)+"%";
    }
    else{
      return "0%"
    }

  }
  get taskMonthPercent(){
    if(this.taskWeight > 0){
      let monthPercent = [];
      for(let i in this.processList){
        for(let j in this.processList[i].workDiary){
          let yearMonth = new moment(this.processList[i].workDiary[j].workDate).format("YYYY-MM");
          let processRatio = parseFloat(this.processList[i].workDiary[j].processRatio);
          let processEmpRatio = parseFloat(this.processList[i].workDiary[j].processEmpRatio);
          let workRatio = this.processList[i].workDiary[j].workRatio;

          let arr = monthPercent.filter(item => item.yearMonth === yearMonth);
          if(arr.length>0){
            arr[0].value += processRatio * processEmpRatio * workRatio /10000
          }
          else{
            monthPercent.push({
              yearMonth:yearMonth,
              value:processRatio * processEmpRatio * workRatio /10000
            })
          }
        }
      }
      for(let k in monthPercent){
        monthPercent[k].percent =  parseFloat((monthPercent[k].value / this.taskWeight *100).toFixed(2))
      }
      return  monthPercent;
    }
    else{
      return []
    }
  }
  get workMoney(){
    if(this.moneyArrange.length > 0){
      let money = 0;
      for(let i in this.moneyArrange){
        money += this.moneyArrange[i].completeMoney
      }
      return money
    }
    else{
      return 0
    }
  }
  get leadMoney(){
    if(this.moneyArrange.length > 0){
      let money = 0;
      for(let i in this.moneyArrange){
        money += this.moneyArrange[i].leaderMoney
      }
      return money
    }
    else{
      return 0
    }
  }
  setLeader(){
    this.leader = true;
    return this;
  }
  addProcess(process){
    this.processList.push(process);
    return this;
  }
  addMoneyArrange(item){
    this.moneyArrange.push(item);
    return this
  }
}
class Process{
  constructor(processId,processTitle,processRatio,workRatio,finishRatio) {
    this.processId = processId;
    this.processTitle = processTitle;
    this.processRatio = processRatio;
    this.workRatio = workRatio;
    this.finishRatio = finishRatio?finishRatio:0;
    this.MonthPercent = [];
  }
}
</script>

<style scoped>

</style>