<!--
* @program: office_automation 
* @author: ly
* @component:DoAssessment 
* @description: 年度考核评定
* @create: 2022-02-25 17:29
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-radio-group @change="radioChange" class="search-item" v-model:value="type">
                    <a-radio value="1">查看所有记录</a-radio>
                    <a-radio value="2">按用户查询</a-radio>
                    <a-radio value="0">按年度查看</a-radio>
                </a-radio-group>
                <div v-if="type === '0'">
                    <span>年度：</span>
                    <a-input-number style="margin: 5px;width: 100px" v-model:value="year" @change="yearChange" />
                </div>
                <a-select
                        style="width: 100px"
                        v-if="type === '2'"
                        show-search
                        v-model:value="userId"
                        placeholder="输入姓名查询"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @select="handleSelect"
                        @search="handleSearch"
                >
                    <a-select-option v-for="option in selectOptions" :key="option.id">
                        <a-tooltip :title="option.name">
                            <a-button style="padding: 0" type="text">
                                {{ option.name }}
                            </a-button>
                        </a-tooltip>
                    </a-select-option>
                </a-select>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[25,30]" >
                <template #left>
                    <ArBaseTable :key="'year'"  :argument="argument" :view-model="true" :page-size="12" >
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <MonthlyAssessmentViewer :assessment-name="currentRecord.typeName" :view-model="false" :assessment-data="AssessmentData" user-type="judge" />
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import ArBaseTable from "../../../../components/table/ArBaseTable";
    import TwoParts from "../../../_components/TwoParts";
    import {SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import MonthlyAssessmentViewer
        from "../../../talentManager/staffAssessment/MonthlyAssessment/MonthlyAssessmentViewer";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import assessmentYear from "../../../../assets/api/assessmentYear";
    import moment from "moment";
    import userInfo from "../../../../assets/api/userInfo";

    export default {
        name: "DoAssessment",
        components: {
            ArBaseTable,TwoParts,SyncOutlined, SearchOutlined,
            MonthlyAssessmentViewer
        },
        data(){
            return{
                year:null,
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:[
                        new Column("年","year",ColumnType.String).setTableView(100),
                        new Column("姓名","userName",ColumnType.String).setTableView(80),
                        new Column("考核标准","typeName",ColumnType.String).setTableView(120),
                        new Column("操作","actions","actions").setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                AssessmentData:null,
                viewModel:true,
                tips:"请选择查询年",
                type:"1",
                selectOptions:[],
                currentRecord:{}
            }
        },
        methods:{
            init(){
                this.type = "0";
                this.viewModel = true;
                this.AssessmentData = null;
                this.radioChange();
            },
            refresh(){
                this.init();
            },
            yearChange(){
                let data = {
                    year:this.year
                };
                this.argument.loading = true;
                assessmentYear.queryByJudgeUser.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            tableInit(){
                let data = {};
                this.argument.loading = true;
                assessmentYear.queryByJudgeUser.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            radioChange(){
                this.AssessmentData = null;
                this.selectOptions = [];
                this.userId = null;
                switch (this.type) {
                    case "0":{
                        this.yearChange();
                        break;
                    }
                    case "1":{
                        this.tableInit();
                        break;
                    }
                }
            },
            showDetail(record){
                this.currentRecord = record;
                this.AssessmentData = record.userDTOS;
                this.AssessmentData.sort((a,b)=>{
                    return a.sortOrder - b.sortOrder
                })
            },
            handleSearch(key){
                if(key){
                    key = key.replace(/'/g, '');
                    let data={
                        queryText:key,
                        disableState:"1",loginState:"0"
                    };
                    userInfo.queryAll.requestPOSTUrlParam(this,data,res=>{
                        if(res.data.flag){
                            res.data.data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                            this.selectOptions = res.data.data;
                        }
                        else{
                            this.selectOptions = [];
                        }
                    })
                }
            },
            handleSelect(){
                let data = {
                    userId:this.userId
                };
                this.argument.loading = true;
                assessmentYear.queryByJudgeUser.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            }
        },
        created() {
            this.year = moment(new Date().valueOf()).format("YYYY");
            this.init();
        }
    }
</script>

<style scoped>

</style>