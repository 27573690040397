<!--
*@description：成果查询组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar">
        <a-input class="search-item ant-col-4" v-model:value="name" placeholder="成果名称" />

      <!--成果类型 start-->
      <div style="margin-left: 5px">
          <a-tree-select
              v-model:value="treeValue_cglx"
              :tree-data="treeData_cglx"
              style="width: 300px;"
              :dropdownStyle="{height:'350px'}"
              allow-clear
              :show-checked-strategy="SHOW_PARENT"
              placeholder="成果类型"
          />
      </div>
      <!--成果类型 end-->

      <!--行政区划 start-->
      <div style="margin-left: 10px">
        <a-tree-select
            :value="treeValue_xzqh"
            :tree-data="treeData_xzqh"
            style="width: 600px;"
            :dropdownStyle="{height:'350px'}"
            @select="selectTree"
            placeholder="行政区划"

        />
      </div>
      <!--行政区划 end-->
        <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
            <template #icon><Search /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
            <template #icon><Reset /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="addData">
          <template #icon><Add /></template>
        </a-button>
    </div>


</template>

<script>
    import Icons from "../../../common/Icons";

    import { TreeSelect } from 'ant-design-vue';
    import { defineComponent, ref, watch } from 'vue';
    import achieveType_api from "../config/achieveType_api";
    import area_api from "../config/area_api";
    const SHOW_PARENT = TreeSelect.SHOW_PARENT;
    export default {
      name: "achieveTypeToolBar",
      mixins:[Icons],
      data(){
        return{
          name:null,
          SHOW_PARENT,
          treeValue_cglx:null,
          treeData_cglx:[],
          treeData_xzqh:[],
          treeValue_xzqh:null,
          xzqh_id:null,

          provId:null,// 省
          cityId:null,// 市
          countyId:null,//区
          streetId:null,//乡镇
          villageId:null,// 村

          defaultTitle: ''
        }
      },
      created() {
        this.init()
      },
      methods:{
        init(){
          let parms = {
            dictName:null,
            dictCode:null,
          };
          achieveType_api.getAllTree.requestPOSTUrlParam(this,parms,res=>{
            if(res.data.flag){
              let level = 0;
              var tempObj = this.convertToTreedatas_cglx(res.data.data,level);
              this.treeData_cglx = tempObj;
            }
            else{
              this.$message.error(res.data.msg)
            }
          })
          area_api.getAllTree.requestPOSTUrlParam(this, {},res=>{
            if(res.data.flag){
              let level = 0;
              var tempObj = this.convertToTreedatas_cglx(res.data.data,level);
              this.treeData_xzqh = tempObj;
            }
            else{
              this.$message.error(res.data.msg)
            }
          })
        },
        doSearch(){
            this.set_xzqh(this.treeData_xzqh);
            let data = this.$GetEmitData(this.$eventTags.search);
            this.$emit("event",data);
        },
        refresh(){
            this.name = null;
            this.treeValue_cglx = null;
            this.treeValue_xzqh = null;
            this.xzqh_id = null,
            this.provId = null;
            this.cityId = null;
            this.countyId = null;
            this.streetId = null;
            this.villageId = null;
            this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        },
        addData(){
          this.$emit("event",this.$GetEmitData(this.$eventTags.add));
        },
        getParms(){
          let parms = {
            name:this.name,
            typeId:this.treeValue_cglx,
            provId:this.provId,
            cityId:this.cityId,
            countyId:this.countyId,
            streetId:this.streetId,
            villageId:this.villageId,
          }
          return parms;
        },

        /**
         * 多级
         * 树选择框,构造转换，转换后端数据为树形选择需要的数据
         * @param data  后端数据
         * @returns {[]}  返回结构
         */
        convertToTreedatas_cglx(data,level){
          var returnData = [];
          //遍历数据
          for(var i = 0; i < data.length; i++){
            var tempObj = {
              value:data[i].id,
              key:data[i].id,
              title:data[i].name,
              level:level,
            };

            //判断是否存在子节点，如果存在则递归
            if(data[i].children != null && data[i].children.length > 0){
              tempObj.children = this.convertToTreedatas_cglx(data[i].children,level + 1);
            }
            //push到数据数组中
            returnData.push(tempObj);
          }
          return returnData;
        },
        set_xzqh(data){
          //遍历数据
          for(var i = 0; i < data.length; i++){
            let value = data[i].value;
            let level = data[i].level;
            let xzqh_id = this.xzqh_id;
            if(value == xzqh_id){
              switch (level){
                case 0:{
                  this.provId = xzqh_id;
                  break;
                }
                case 1:{
                  this.cityId = xzqh_id;
                  break;
                }
                case 2:{
                  this.countyId = xzqh_id;
                  break;
                }
                case 3:{
                  this.streetId = xzqh_id;
                  break;
                }
                case 4:{
                  this.villageId = xzqh_id;
                  break;
                }
              }
            }else{
              //判断是否存在子节点，如果存在则递归
              if(data[i].children != null && data[i].children.length > 0){
                this.set_xzqh(data[i].children);
              }
            }
          }
        },
        /**
         * @description 选择节点触发
         */
        selectTree(nodeValue){
          this.provId = null;
          this.cityId = null;
          this.countyId = null;
          this.streetId = null;
          this.villageId = null;
          this.setTitle(nodeValue)
        },
        /**
         * @description 设置当前选中对象
         */
        setTitle(value){
          this.xzqh_id = value;
          this.getSelectedItem(value, this.treeData_xzqh)
          this.$nextTick(()=>{
            this.treeValue_xzqh = this.defaultTitle;
          })
        },
        /**
         * @description 当前选中对象的title(拼接所有父级title)
         */
        getSelectedItem(value, data, title){
          for(let item of data){
            //在根节点找到对应选项
            if (!title && item.value == value ) {
              this.defaultTitle = item.title
            }
            //根节点未找到继续递归查找
            else if(!title && item.children){
              let lable = item.title
              this.getSelectedItem(value, item.children, lable);
            }

            //在子节点找到对应选项
            if (title && item.value == value){
              this.defaultTitle = title + '-' + item.title
            }
            //当前子节点未找到继续递归向下查找
            else if(title && item.children){
              let lable = title + '-' + item.title
              this.getSelectedItem(value, item.children, lable);
            }
          }
        },
      }
    }
</script>

<style scoped>

</style>