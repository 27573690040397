<!--
* @program: office_automation 
* @author: ly
* @component:ProjectSealApply 
* @description: 项目用章申请
* @create: 2022-06-08 14:19
-->
<template>
    <div>
        <a-button @click="init" class="search-item" shape="circle" type="dashed">
            <template #icon>
                <SyncOutlined class="pointer" />
            </template>
        </a-button>
        <a-tooltip v-if="!viewModel"  title="添加用章申请">
            <a-button  class="action-button" type="dashed" shape="circle" @click="addData">
                <PlusOutlined class="pointer" style="color: #1890ff"/>
            </a-button>
        </a-tooltip>
        <ArBaseTable :view-model="true" :page-size="15" :argument="argument">
            <template #action="{index,record}">
                <div class="action-column">
                    <a-button class="action-button" type="link" @click="showDetail(record)">
                        <SearchOutlined class="pointer"/>
                    </a-button>
                    <a-popconfirm
                            v-if="!viewModel && record.auditState === '0'"
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            title="确定删除吗?"
                            @confirm="deleteData(index,record)">
                        <DeleteOutlined  style="color: red" class="pointer"/>
                    </a-popconfirm>
                </div>
            </template>
        </ArBaseTable>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <ArForm :argument="formArg" :view-model="true" />
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             :title="null">
        <span>用章：</span>
        <a-select  mode="tags" v-model:value="sealId" style="width: 250px" class="search-item">
            <a-select-option v-for="item in seals" :key="item.id">
                {{ item.name }}
            </a-select-option>
        </a-select>
        <br />
        <span>印章用途：</span>
        <a-select v-model:value="purposeId" style="width: 250px" class="search-item">
            <a-select-option v-for="item in purposes" :key="item.id">
                {{ item.name }}
            </a-select-option>
        </a-select>
        <br />
        <UploadFile :view-model="false" v-model:value="fileUrl" title="上传盖章文件" />
        <br />
        <span>申请理由：</span>
        <a-textarea v-model:value="reason" :auto-size="{ minRows: 2, maxRows: 6 }"/>
        <a-button size="small" type="primary" style="margin: 20px" @click="saveData">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import {PlusOutlined,SearchOutlined,DeleteOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import seal_apply from "../../../assets/tables/seal_apply";
    import ArForm from "../../../components/form/ArForm";
    import sealApply from "../../../assets/api/sealApply";
    import sealPurpose from "../../../assets/api/sealPurpose";
    import officialSeal from "../../../assets/api/officialSeal";
    import UploadFile from "../../_components/UploadFile";
    export default {
        name: "ProjectSealApply",
        props:["projectId","viewModel"],
        components:{
            ArBaseTable,PlusOutlined,SearchOutlined,DeleteOutlined,ArForm,SyncOutlined,
            UploadFile
        },
        data() {
            return {
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                visible2:false,
                seals:[],
                sealId:[],
                purposes:[],
                purposeId:null,
                fileUrl:null,
                reason:null
            }
        },
        methods: {
            init() {
                this.argument.tableColumns = new seal_apply().getTableColumns();
                this.formArg.formColumns = new seal_apply().getFormColumns();
                let data = {
                    itemId:this.projectId
                };
                sealApply.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            addData(){
                this.visible2 =true;
                this.reason = null;
                this.fileUrl = null;
                this.purposeId = null;
                this.sealId = [];
            },
            showDetail(record){
                this.formArg.formData = record;
                this.visible = true;
            },
            deleteData(index,record){
                let data = {id:record.id};
                sealApply.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(){
                if(!this.fileUrl){
                    this.$message.error("请上传盖章文件")
                }
                let data = {
                    "fileUrl": this.fileUrl,
                    "itemId": this.projectId,
                    "purposeDescri": this.reason,
                    "purposeId": this.purposeId,
                    "sealId": this.sealId.toString(),
                    "userId": this.$store.getters.userInfo.id
                };
                sealApply.saveOrUpd.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.$message.success(res.data.msg);
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            sealPurpose.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.purposes = res.data.data;
                }
                else{
                    this.purposes = [];
                }
            });
            officialSeal.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.seals = res.data.data;
                }
                else{
                    this.seals = [];
                }
            });
            this.init()
        }
    }
</script>

<style scoped>

</style>