<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityReportList 
* @description: 
* @create: 2021-07-09 14:48
-->
<template>
    <ArBaseTable :page-size="15" :argument="argument" :view-model="true"/>
</template>

<script>
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import item_qc_report_process2 from "../../../assets/tables/item_qc_report_process2";
    import api_item_qc_report_process from "../../../assets/api/qcReportProcess";
    export default {
        name: "qualityReportList",
        components: {ArBaseTable},
        props:["record","viewModel"],
        data(){
            return{
                table:new item_qc_report_process2(),
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false//table的loading状态
                },
            }
        },
        methods:{
            init(){
                this.argument.tableColumns = this.table.getTableColumns();
                let data = {itemId:this.record.itemId};
                this.argument.loading = true;
                api_item_qc_report_process.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                });
            }
        },
        created() {
            this.init()
        }

    }
</script>

<style scoped>

</style>