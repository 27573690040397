<!--
* @program: office_automation 
* @author: ly
* @component:QCManager
* @description: 
* @create: 2022-07-01 10:24
-->
<template>
    <TagShow :visible="true" tag="一检" color="blue">
        <template #content>
            <a-button @click="init" class="search-item" shape="circle" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-button class="search-item" type="dashed" v-if="!viewModel" shape="circle" @click="addData" >
                <template #icon><PlusOutlined /></template>
            </a-button>
            <a-spin :spinning="loading1" tip="正在查询数据库...">
                <div v-if="qcList1.length>0" class="center-flex" style="width: 100%;overflow-x: auto">
                    <a-card v-for="(item,idx) in qcList1" :key="idx" style="width: 300px">
                        <FcForm style="width: 250px" :form-columns="fc1" :form-data="item" :view-model="true" >
                            <template #action2>
                                <a-button v-if="!viewModel" class="action-button" type="link" @click="editDetail(item)">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        v-if="!viewModel"
                                        title="确定删除吗?"
                                        @confirm="deleteData(idx,item)">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </template>
                        </FcForm>
                    </a-card>
                </div>
                <div v-else style="font-size: 20px">没有一检记录</div>
            </a-spin>
        </template>
    </TagShow>
    <TagShow :visible="true" tag="二检" color="red">
        <template #content>
            <QCManager2 :project-id="projectId" :view-model="viewModel" />
        </template>
    </TagShow>
    <a-modal v-model:visible="visible1"
             destroyOnClose
             :footer="null"
             title="一检">
        <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" placeholder="质检说明"
                    v-model:value="qcDescrib"/>
        <UploadFile title="质检文件" :view-model="false" v-model:value="qcFile" />
        <a-button size="small" type="primary" style="margin: 20px" @click="saveData">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             title="添加一检记录">
        <span>工序：</span>
        <a-select v-if="!viewModel" v-model:value="process" style="width: 300px" >
            <a-select-option v-for="option in processList" :key="option">{{option.title}}</a-select-option>
        </a-select>
        <br />
        <br />
        <span>质检说明：</span>
        <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" placeholder="质检说明" style="width: 300px"
                    v-model:value="qcDescrib"/>
        <br />
        <span>质检文件：</span>
        <UploadFile title="质检文件" :view-model="false" v-model:value="qcFile" />

        <a-button size="small" type="primary" style="margin: 20px" @click="saveData2">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import {
        DeleteOutlined,
        PlusOutlined,
        SyncOutlined,EditFilled
    } from "@ant-design/icons-vue";
    import FcForm from "../../../components/form/FcForm";
    import itemTaskProcess from "../../../assets/api/itemTaskProcess";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import UploadFile from "../../_components/UploadFile";
    import QCManager2 from "./QCManager2";
    import TagShow from "../../_components/TagShow";
    export default {
        name: "QCManager",
        props:["projectId","viewModel"],
        components:{
            UploadFile,
            PlusOutlined,SyncOutlined,DeleteOutlined,
            FcForm,EditFilled,
            QCManager2,TagShow
        },
        computed:{
            qcList1(){
                return this.processList.filter(item=>{
                    return !!item.qcDescrib
                });
            }
        },
        data() {
            return {
                loading1:false,
                processList:[],//工序列表 一检结果
                fc1:[
                    new Column("工序","title",ColumnType.String,true),
                    new Column("质检说明","qcDescrib",ColumnType.String,true),
                    new Column("附件","qcFile",ColumnType.PreViewFile,true)
                ],
                visible1:false,
                visible2:false,
                qcDescrib:null,
                qcFile:null,
                currentRecord:null,
                process:null
            }
        },
        methods: {
            init(){//一检查询
                let data = {
                    itemId:this.projectId
                };
                itemTaskProcess.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.processList = res.data.data;
                    }
                    else{
                        this.processList = [];
                    }
                })
            },
            addData(){
                this.visible2 = true;
                this.process = null;
            },
            editDetail(item){
                this.currentRecord = item;
                this.visible1 = true;
            },
            deleteData(idx,item){
                item.qcDescrib = null;
                item.qcFile = null;
                itemTaskProcess.saveOrUpd.requestPOST(this,item,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            saveData(){
                let item = {...this.currentRecord};
                item.qcDescrib = this.qcDescrib;
                item.qcFile = this.qcFile;
                itemTaskProcess.saveOrUpd.requestPOST(this,item,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            saveData2(){
                let item = {...this.process};
                item.qcDescrib = this.qcDescrib;
                item.qcFile = this.qcFile;
                itemTaskProcess.saveOrUpd.requestPOST(this,item,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>