<!--
* @program: office_automation 
* @author: ly
* @component:addWorkProcess 
* @description: 添加工作进度  为外协单位/工作人员
* @create: 2022-03-24 18:26
-->
<template>
    <a-modal v-model:visible="visible"
             title="工作记录"
             destroyOnClose
             width="600px"
             :footer="null"
    >
        <TagShow v-if="dataSource.length>0" color="red" :visible="true" tag="工作记录">
            <template #content>
                <TcBaseTable :view-model="true" :loading="loading" :page-size="5" :table-columns="formColumns" :data-source="dataSource" >
                    <template #action="{index,record}">
                        <div class="action-column">
                            <a-popconfirm
                                    v-if="!viewModel"
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(index,record)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </div>
                    </template>
                </TcBaseTable>
            </template>
        </TagShow>
        <TagShow v-if="workSum < 100 && !viewModel" color="blue" :visible="true" tag="添加工作记录">
            <template #content>
                <FcForm :view-model="false" :form-columns="formColumns" v-model:form-data="formData" >
                    <template #action="{record}">
                        <a-button  type="primary" @click="saveData(record)">
                            保存
                        </a-button>
                    </template>
                </FcForm>
            </template>
        </TagShow>
        <TagShow v-if="workSum === 100 && !viewModel" color="green" :visible="true" tag="填写工作完成情况">
            <template #content>
                <div class="center-flex">
                    <span>完成日期：</span><DatePicker style="width: 200px" v-model:value="taskDate" />
                </div>
                <FcForm style="width: 400px"  :view-model="false" :form-columns="formColumns2" v-model:form-data="currentProcessEmployee" >
                    <template #action="{record}">
                        <a-button  type="primary" @click="saveWorkState(record)">
                            保存
                        </a-button>
                    </template>
                </FcForm>
            </template>
        </TagShow>
    </a-modal>
</template>

<script>
    import workSituation from "../../../assets/api/workSituation";
    import FcForm from "../../../components/form/FcForm";
    import item_work_situation from "../../../assets/tables/item_work_situation";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import TagShow from "../../_components/TagShow";
    import processEmployee from "../../../assets/api/processEmployee";
    import personal_task from "../../../assets/tables/personal_task";
    import DatePicker from "../../_components/DatePicker";
    import {DeleteOutlined} from "@ant-design/icons-vue"
    export default {
        name: "addWorkProcess",
        components:{
            FcForm,TcBaseTable,TagShow,DatePicker,DeleteOutlined
        },
        computed:{
            workSum(){
                let sum = 0;
                for(let i in this.dataSource){
                    sum += this.dataSource[i].workRatio*100
                }
                return sum/100;
            },
            viewModel(){
                return !!this.currentProcessEmployee.state;
            }
        },
        data() {
            return {
                visible:false,
                formColumns:null,
                formData:null,
                currentProcessEmployee:{},
                dataSource:[],
                loading:false,
                formColumns2:[],
                taskDate:null,
            }
        },
        methods: {
            init(){
                let data = {
                    processEmployeeId:this.currentProcessEmployee.id
                };
                workSituation.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            open(record){
                this.formData = new item_work_situation().getFormData();
                this.visible = true;
                this.currentProcessEmployee = record;
                this.init();
            },
            saveData(record){
                if(!record.id){
                    record.processEmployeeId = this.currentProcessEmployee.id;
                }
                workSituation.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                workSituation.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            saveWorkState(record) {
                record.completeDate = new Date(this.taskDate).valueOf();
                processEmployee.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.init()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.formColumns = [
                new Column("日期","workDate",ColumnType.Date,true).setTableView(100),
                new Column("工作占比","workRatio",ColumnType.Number,true).setTableView(100)
                    .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                new Column("工作内容","workContent",ColumnType.String,true).setTableView(100),
                new Column("备注","remarks",ColumnType.String,true).setTableView(100),
                new Column("操作","actions","actions",false).setTableView(80),
            ];
            this.formColumns2= new personal_task().getFormColumns();
        }
    }
</script>

<style scoped>

</style>