<!--
* @program: office_automation 
* @author: ly
* @component:SealAudit 
* @description: 
* @create: 2022-06-09 15:46
-->
<template>
    <a-radio-group  v-model:value="state" @change="selectChange">
        <a-radio value="1">待审核</a-radio>
        <a-radio value="2">通过</a-radio>
        <a-radio value="3">未通过</a-radio>
    </a-radio-group>
    <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="pageSize">
        <template #action="{index,record}">
            <div class="action-column">
                <a-popconfirm
                        v-if="state === '1'"
                        ok-text="是"
                        cancel-text="否"
                        title="不通过审核吗?"
                        @confirm="accept(index,record)">
                    <a-button  type="link" >
                        <template #icon><CheckOutlined style="color: #00b30c" /></template>
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                        v-if="state === '1'"
                        ok-text="是"
                        cancel-text="否"
                        title="不通过审核吗?"
                        @confirm="refuse(index,record)">
                    <a-button  type="link" >
                        <template #icon><CloseOutlined style="color: red" /></template>
                    </a-button>
                </a-popconfirm>
                <a-tooltip title="查看审核状态">
                    <a-button  type="link" @click="showStep(record)" >
                        <template #icon><SearchOutlined /></template>
                    </a-button>
                </a-tooltip>
            </div>
        </template>
    </ArBaseTableFilter>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="不通过审核">
        <span>原因</span>
        <a-textarea v-model:value="reason" :auto-size="{ minRows: 2, maxRows: 6 }"/>
        <a-button size="small" type="primary" style="margin: 20px" @click="saveAudit">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             :width="800"
             title="审核状态">
        <DepartmentSteps v-if="visible2" :steps="steps" />
    </a-modal>
</template>

<script>
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import {CheckOutlined, CloseOutlined, SearchOutlined} from "@ant-design/icons-vue";
    import DepartmentSteps from "../../projectManager/DepartmentAudits/DepartmentSteps";
    import sealApply from "../../../assets/api/sealApply";
    import sealApplyApproval from "../../../assets/api/sealApplyApproval";
    import seal_apply from "../../../assets/tables/seal_apply";

    export default {
        name: "SealAudit",
        components:{
            ArBaseTableFilter,CheckOutlined,CloseOutlined,SearchOutlined,
            DepartmentSteps
        },
        data() {
            return {
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                pageSize:15,
                currentRecord:null,
                visible:false,
                reason:null,
                state:"1",
                currentIndex:null,
                steps:null,
                visible2:false
            }
        },
        methods: {
            init() {
                let data = {
                    userId:this.$store.getters.userInfo.id,
                    state:this.state
                };
                this.argument.loading = true;
                sealApply.allMyApproval.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            accept(index,record){
                let data = {
                    applyId:record.id,
                    userId:this.$store.getters.userInfo.id,
                    state:"2",
                    remarks:""
                };
                sealApplyApproval.audit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                        this.$emit("update:dataSource",this.argument.dataSource);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refuse(index,record){
                this.currentRecord = record;
                this.currentIndex = index;
                this.visible = true;
            },
            saveAudit(){
                let data = {
                    applyId:this.currentRecord.id,
                    userId:this.$store.getters.userInfo.id,
                    state:"3",
                    remarks:this.reason
                };
                sealApplyApproval.audit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(this.currentIndex,1);
                        this.$emit("update:dataSource",this.argument.dataSource);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            selectChange(){
                this.init()
            },
            showStep(record){
                this.steps = [
                    {approvalUserName:"提交审核",state:"0"}
                ];
                let data = {
                    "applyId": record.id,
                };
                sealApplyApproval.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.steps = this.steps.concat(res.data.data);
                        for(let i in this.steps){
                            switch (this.steps[i].state) {
                                case "0":{
                                    this.steps[i].status = "wait" ; //wait process finish error
                                    break;
                                }
                                case "1":{
                                    this.steps[i].status = "process" ; //wait process finish error
                                    this.steps[0].status = "finish";
                                    break;
                                }
                                case "2":{
                                    this.steps[i].status = "finish" ; //wait process finish error
                                    this.steps[0].status = "finish";
                                    break;
                                }
                                case "3":{
                                    this.steps[i].status = "error" ; //wait process finish error
                                    this.steps[0].status = "finish";
                                    break;
                                }
                            }
                        }
                        this.visible2 = true;
                    }
                })
            }
        },
        created() {
            this.init();
            this.argument.tableColumns = new seal_apply().getTableColumns();
        }
    }
</script>

<style scoped>

</style>