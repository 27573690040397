<!--
* @program: tyh-oa
* @author: ly
* @description: typeString 
* @create: 2021-03-23 10:01
-->
<template>
    <div >
        <a-textarea
                @input="columnTrigger"
                auto-size
                v-if="editable"
                v-model:value="record[column.dataIndex]"
        />
        <div v-else style="width: 100%">
            <OverSizeColString2 :text="cellValue"/>
        </div>
    </div>
</template>

<script>
    import typeBaseString2 from "./typeBaseString2";


    export default {
        name: "typeString2",
        mixins:[typeBaseString2],
    }
</script>

<style scoped>

</style>