<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentUserPicker 
* @description: 
* @create: 2023-01-05 13:51
-->
<template>
    <a-select
            v-model:value="selectId"
            style="width: 150px"
            placeholder="选择人员"
            :options="treeData"
            @change="selectConform"

    >
    </a-select>
</template>

<script>
    import {TreeSelect} from "ant-design-vue";
    import userDepartRelation from "../../assets/api/userDepartRelation";

    export default {
        name: "DepartmentUserPicker",
        props:["value","departmentId","members"],
        emits:["update:value"],
        computed:{
            user(){
                if(this.selectedData.length >0){
                    return {id:this.selectedData[0].key,name:this.selectedData[0].title}
                }
                else{
                    return null
                }
            }
        },
        data(){
            return{
                SHOW_CHILD:TreeSelect.SHOW_CHILD,
                departments:[],
                treeData:[],
                selectId:null,
                selectedData:[],
            }
        },
        methods:{
            init(){
                this.selectedData = [];
                this.$emit("update:value",this.user);
                if(this.departmentId){
                    this.departmentChange();
                }
                else{
                    this.$message.error("没有部门ID");
                }
            },
            departmentChange(){
                this.treeData = [];
                if(this.members){
                    for(let i in this.members){
                        let item = new treeDataItem(this.members[i].userId,this.members[i].userName);
                        this.treeData.push(item);
                    }
                }
                else{
                    this.selectId = null;
                    let data = {
                        departmentId:this.departmentId,
                        disableState:"1",
                        loginState:"0",
                        haveLevel:"1"
                    };
                    userDepartRelation.queryAll.requestPOSTUrlParam(this,data,res=>{
                        if(res.data.flag){
                            for(let i in res.data.data){
                                let item = new treeDataItem(res.data.data[i].userId,res.data.data[i].userName);
                                this.treeData.push(item);
                            }
                        }
                    })
                }
            },
            selectConform(){
                this.selectedData = this.treeData.filter(item=>{return this.selectId === item.key});
                this.$emit("update:value",this.user);
                console.log(this.user)
            }
        },
        created() {
            this.init();
        }
    }
    class treeDataItem{
        constructor(value,title) {
            this.value = value;
            this.key = value;
            this.title = title;
            this.label = title;
        }
    }
</script>

<style scoped>

</style>