<!--
* @program: TYH_office_automation 
* @author: ly
* @component:assetsCategoryTree 
* @description: 用于获取assetsCategory类别Id
* @create: 2021-06-25 16:39
-->
<template>
    <a-tree-select
            v-model:value="local_value"
            style="width: 200px"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            placeholder="输入查询/选择子分类"
            :replaceFields="replaceFields"
            :treeData="treeData"
            :treeNodeFilterProp="'categoryName'"
            @change="selectChange"
            allow-clear
            v-model:treeExpandedKeys="expandKey"
    />
</template>

<script>
    import api_device_category from "../../../assets/api/deviceCategory";

    export default {
        name: "assetsCategoryTree",
        props:{
            value:{required:true}
        },
        watch:{
            value(){
                this.local_value = this.value;
            }
        },
        data(){
            return{
                local_value:null,
                replaceFields :{
                    title: 'categoryName',
                    key:"id",
                    value:"id",
                },
                treeData:[],
                spinning:false,
                expandKey:[]
            }
        },
        methods:{
            categoryInit(){
                this.spinning = true;
                api_device_category.queryOneLevel.requestPOSTUrlParam(this,{},res=>{
                    this.spinning =false;
                    if(res.data.flag){
                        this.treeData = res.data.data;
                        for(let i in this.treeData){//禁止大类被选中
                            this.treeData[i]["selectable"]=false;
                        }
                        this.expandKey = [this.treeData[0].id];
                        this.local_value = this.treeData[0].children[0].id;
                        this.$emit('update:value', this.local_value);
                        this.$emit("change");
                    }
                })
            },
            selectChange(){
                this.$emit('update:value', this.local_value);
                this.$emit("change");
            }
        },
        created() {
            this.categoryInit()
        }
    }
</script>

<style scoped>

</style>