<!--
* @program: office_automation 
* @author: ly
* @component:MonthProcessArrange 
* @description: 月度绩效分配
new Column("作业绩效","depManagExp",ColumnType.Number,false),
new Column("负责人绩效","leaderPercent",ColumnType.Number,false),
* @create: 2022-04-12 09:56
{
  "completeMoney": 0,
  "completeRatio": 0,
  "id": "string",
  "remarks": "string",
  "taskId": "string",
  "userId": "string",
  "yearMonth": "string"
}
-->
<template>
    <a-modal v-model:visible="visible"
             width="900px"
             bodyStyle="height:700px;overflow: auto;"
             title="月度绩效分配"
             :destroyOnClose="true"
             :maskClosable="false"
    >
        <a-spin :spinning="spinning" >
            <div style="box-shadow: 0px 0px 5px #888888;height: 40px;width: 98%;margin-left: 10px;overflow: auto;">
              <a-descriptions :column="2" style="padding: 0 50px">
                <a-descriptions-item label="可以分配的作业绩效">{{ (task.depManagExp - arranged.work-workSum).toFixed(2)}}</a-descriptions-item>
                <a-descriptions-item label="可以分配的负责人绩效">{{ (task.leaderPercent - arranged.lead - leadSum).toFixed(2)}}</a-descriptions-item>
              </a-descriptions>
            </div>

          <a-divider style="color: #faad14;font-size: 18px;margin: 10px 0;"></a-divider>
          <div >
            <div class="ant-row">
              <div class="ant-col-24">
                <span>月份：</span><month-picker style="width: 120px" v-model:value="yearMonth" @change="setUserPercent"/>
                <!--                <span>项目月进度：</span>-->
                <span v-for="item in taskMonthPercent" :key="item">
                    <span v-if="item.yearMonth === yearMonth">【月进度：{{item.percent.toFixed(2)}}%】
<!--                      <a-divider type="vertical" style="height: 20px; background-color: #7cb305" />
                      绩效：{{((task.depManagExp+task.leaderPercent)*(item.value/item.weight)).toFixed(2)}}】-->
                    </span>
                </span>

                <a-button style="margin: 0 5px" type="primary" @click="computeProcess">
                  项目月进度
                </a-button>

              </div>


            </div>

            <div class="ant-row">
              <div class="ant-col-24">
                <span style="margin: 0 5px">分配作业绩效占比（总量）：</span>
                <a-input-number
                    :formatter="value => `${value}%`"
                    :parser="value => value.replace('%', '')"
                    style="width: 100px" :max="100" :min="0"  @change="setArrangePercent" v-model:value="arrangePercent" />
<!--                {{arrangeNum}}-->

                <a-divider type="vertical" style="height: 20px; background-color: #7cb305" />

                <span style="margin: 0 5px">分配作业绩效产值（可分配）：</span>
                <a-input-number
                    style="width: 100px"  :min="0" @change="setArrangeNum_v" v-model:value="arrangeNum_v" />

                <a-button  style="margin: 0 5px" type="primary" @click="calculate_fill" >
                  计算人员绩效
                </a-button>
                <a-button  style="margin: 0 5px" type="primary" @click="setUserPercent" >
                  重置
                </a-button>
              </div>
            </div>


            <a-divider style="color: #faad14;font-size: 18px;margin: 10px 0;"></a-divider>

            <div style="display: flex;flex-wrap: wrap;height:500px;overflow: auto; ">

              <a-card v-for="item in moneyArrange" :key="item.userId" style="width: 270px">
                <template #title>
                  <a-checkbox :value="item.userId" name="mycheckboxes" :defaultChecked="checkbox_flag" >{{item.name}}</a-checkbox>
                </template>

                <a-form layout="horizontal">
                  <a-form-item label="占 比 (当月)">
                    <a-input-number
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                        style="width: 100px"   @change="setPercent(item)" v-model:value="item.percent" />
                  </a-form-item>
                  <a-form-item label="作 业 绩 效 "> <!-- :max="task.depManagExp - arranged.work" -->
                    <a-input-number  style="width: 100px" @change="setCompleteMoney(item)" v-model:value="item.completeMoney" />
                  </a-form-item>
                  <a-form-item label="负责人绩效"><!-- :max="task.leaderPercent - arranged.lead" -->
                    <a-input-number  style="width: 100px" @change="setLeaderMoney(item)"  v-model:value="item.leaderMoney" />
                  </a-form-item>
                  <a-form-item label="说 明">
                    <a-textarea style="width: 150px;height: 70px" v-model:value="item.remarks" />
                  </a-form-item>
                </a-form>
              </a-card>
            </div>
          </div>

        </a-spin>

      <template #footer>
        <div class="ant-row">
          <div class="ant-col-16">
            <span>分配的作业绩效：{{workSum}}</span>
            <a-divider type="vertical" style="height: 20px; background-color: #7cb305" />
            <span>&nbsp; 分配的负责人绩效：{{leadSum}}</span>
            <a-divider type="vertical" style="height: 20px; background-color: #7cb305" />
            <span>&nbsp; 合计：{{ parseFloat(workSum) + parseFloat(leadSum)}}</span>
            <br/>
            <span v-if="overArranged" style="font-size: 18px;color: red;">{{zyjx_title}}</span>
          </div>

          <div class="ant-col-4" style="margin-left: 15%">
            <a-button key="back" @click="visible =false">取消</a-button>
            <a-button v-if="!overArranged " type="primary" @click="saveData">提交</a-button>
          </div>
        </div>
      </template>
    </a-modal>
</template>

<script>
import MonthPicker from "../../_components/MonthPicker";
import taskMonthUser from "../../../assets/api/taskMonthUser";
import workSituation from "../../../assets/api/workSituation";
import moment from "moment"
export default {
    name: "MonthProcessArrange",
    props:["members","task","arranged","argument"],//arranged 已分配的绩效合计work作业绩效  lead负责人绩效
    components:{MonthPicker},
    emits:["refresh"],
    data() {
        return {
          visible:false,
          yearMonth:null,
          moneyArrange:[],
          spinning:false,
          taskMonthPercent:[],
          arrangePercent:0,
          arrangeNum_v:0,
          zyjx_title:'分配的作业绩效超过可分配的作业绩效',
          overArranged_flag:false,// 是否超过可分配绩效值
          checkbox_flag:true,

          monthComplete:[],// 月成员绩效值
          checkbox_v:[],
        }
    },
    computed:{
        workSum(){
          // 获取所有复选框
          var checkboxes = document.getElementsByName('mycheckboxes');
            let sum = 0;
            if(checkboxes != null && checkboxes.length > 0){
              for(let i in this.moneyArrange){
                let checkbox_value = checkboxes[i]._value;
                let checkbox_checked = checkboxes[i].checked;
                if(checkbox_checked && (checkbox_value == this.moneyArrange[i].userId)){
                  let leaderMoney = this.moneyArrange[i].completeMoney;
                  sum += leaderMoney != null && leaderMoney != '' ? parseFloat(leaderMoney) : 0;
                }
              }
            }else{
              for(let i in this.moneyArrange){
                let leaderMoney = this.moneyArrange[i].completeMoney;
                sum += leaderMoney != null && leaderMoney != '' ? parseFloat(leaderMoney) : 0;
              }
            }

            sum = sum.toFixed(2);
            return sum;
        },
        leadSum(){
          // 获取所有复选框
          var checkboxes = document.getElementsByName('mycheckboxes');
          let sum = 0;
          if(checkboxes != null && checkboxes.length > 0){
            for(let i in this.moneyArrange){
              let checkbox_value = checkboxes[i]._value;
              let checkbox_checked = checkboxes[i].checked;
              if(checkbox_checked && (checkbox_value == this.moneyArrange[i].userId)){
                let leaderMoney = this.moneyArrange[i].leaderMoney;
                sum += leaderMoney != null && leaderMoney != '' ? parseFloat(leaderMoney) : 0;
              }
            }
          }else{
            for(let i in this.moneyArrange){
              let leaderMoney = this.moneyArrange[i].leaderMoney;
              sum += leaderMoney != null && leaderMoney != '' ? parseFloat(leaderMoney) : 0;
            }
          }

          sum = sum.toFixed(2);
          return sum;
        },
        arrangeNum(){//当月分配绩效合计
            return this.arrangePercent*(this.task.depManagExp+this.task.leaderPercent)/100
        },

        overArranged(){//是否超过当月分配绩效
            let bool1 = false;
            let bool2 = false;
            let bool3 = false;
            let bool4 = false;
            let bool5 = false;
            let sum = 0;

            for(let i in this.moneyArrange){
                sum += this.moneyArrange[i].completeMoney
            }
            bool5 = (this.task.depManagExp - this.arranged.work- this.workSum).toFixed(2) < 0;
            //bool1 =  sum >100;
           /* bool2 = (this.task.depManagExp - this.arranged.work - this.workSum) < 0;*/
            //bool2 = (this.arrangeNum_v  - this.workSum) < 0;
            bool3 = (this.task.leaderPercent - this.arranged.lead - this.leadSum) < 0;
           // console.log(bool5)
           /* bool4 = this.arrangeNum_v > (this.task.depManagExp - this.arranged.work);*/

            return bool1 || bool2 || bool3 || bool5 ;
        }
    },
    methods: {
      open(){
        this.visible = true;
        this.taskMonthPercent = [];
        this.arrangePercent = 0;
        this.arrangeNum_v = 0;
        let time = parseInt(localStorage.getItem("time"));
        this.yearMonth = new moment(time).format("YYYY-MM");

        /** 获取当前月份的成员绩效数据 ***/
        let jx_data = this.argument.dataSource;
        this.monthComplete = jx_data.filter(item=>item.yearMonth === this.yearMonth);
        this.init();
        // 查询进度
        this.computeProcess();
      },
        init() {
          this.moneyArrange = [];
          this.arrangeNum_v = 0;
          this.arrangePercent = 0;
          for(let i in this.members){
            let {id,name} = this.members[i];
            // 获取成员绩效占比
            let userInfo = this.monthComplete.filter(item=>item.userId === id);
            if(userInfo.length > 0){
              this.arrangeNum_v += userInfo[0].completeMoney;
              this.moneyArrange.push(new MoneyArrange(id,name,this.task.id,userInfo[0].completeRatio,userInfo[0].completeMoney,userInfo[0].leaderMoney,userInfo[0].id))
            }else{
              this.moneyArrange.push(new MoneyArrange(id,name,this.task.id,0,0,0))
            }

          }
          this.arrangePercent = (this.arrangeNum_v / (this.task.depManagExp+this.task.leaderPercent)*100).toFixed(2)
        },
      // 设置成员占比
      setUserPercent(){
        let jx_data = this.argument.dataSource;
        this.monthComplete = jx_data.filter(item=>item.yearMonth === this.yearMonth);
        this.init();

      },
        saveData(){
            if(this.task.depManagExp - this.arranged.work - this.workSum <0){
                this.$message.error("超过可以分配的作业绩效额");
                return
            }
            if(this.task.leaderPercent - this.arranged.lead - this.leadSum <0){
                this.$message.error("超过可以分配的负责人绩效额");
                return
            }
            /*for(let i in this.moneyArrange){
                this.moneyArrange[i].yearMonth = this.yearMonth;
                taskMonthUser.saveOrUpd.requestPOST(this,this.moneyArrange[i],res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }*/
            let taskMonthUserVOS = [];
            // 获取所有复选框
            var checkboxes = document.getElementsByName('mycheckboxes');
            for(let i in this.moneyArrange){
              let checkbox_value = checkboxes[i]._value;
              let checkbox_checked = checkboxes[i].checked;
              if(checkbox_checked && (checkbox_value == this.moneyArrange[i].userId)){
                this.moneyArrange[i].yearMonth = this.yearMonth;
                this.moneyArrange[i].completeRatio = this.moneyArrange[i].percent;
                this.moneyArrange[i].confirmMoney = 0;
                taskMonthUserVOS.push(this.moneyArrange[i]);
              }
            }
            let parms = {
              taskMonthUserVOS:taskMonthUserVOS
            }
          taskMonthUser.batchSaveOrUpd.requestPOST(this,parms,res=>{
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.spinning = false;
              this.visible = false;
              this.$emit("refresh");
            }
            else{
              this.spinning = false;
              this.$message.error(res.data.msg);
            }
          })
            this.spinning = true;
            setTimeout(()=>{

            },1500)
        },
        setCompleteMoney(item){

        },
        setPercent(item){

        },
        setLeaderMoney(item){

        },
        setArrangePercent(value){
            this.arrangeNum_v = this.arrangePercent*(this.task.depManagExp+this.task.leaderPercent)/100;
            // 封装成员对象
            for(let i in this.moneyArrange){
                this.moneyArrange[i].setArrangeNum(this.arrangeNum_v)
            }
        },
        /** 根据输入的产值计算绩效占比 **/
        setArrangeNum_v(value){
          this.arrangePercent = (value / (this.task.depManagExp+this.task.leaderPercent)*100).toFixed(2)

          // 可分配的作业绩效
    /*      let zyjx = this.task.depManagExp - this.arranged.work ;
          if(value > zyjx){
            this.overArranged_flag = true;
            this.zyjx_title = '分配的作业绩效超过可分配的作业绩效';
          }else {
            this.overArranged_flag = false;
            this.zyjx_title = '分配的作业绩效超过已分配的绩效';
          }*/

        },
        async computeProcess(){//获取所有人工序的完成进度
            this.spinning = true;
          let data = {
            taskId: this.task.id
          };
          let res = await workSituation.queryAll.requestPOSTAsync(data);
          for(let i in this.members){
            let currentMember = this.members[i];
            if(res.flag){
              let user_data = res.data.filter(item=>item.userId === currentMember.id);
              for(let j in currentMember.processList){
                let process_data = user_data.filter(item=>item.processId === currentMember.processList[j].processId);
                if(process_data.length > 0){
                  currentMember.processList[j].workDiary = process_data;
                  currentMember.processList[j].monthRatio = [];
                  currentMember.processList[j].finishRatio = 0;
                  for(let k in process_data){
                    let yearMonth = new moment(process_data[k].workDate).format("YYYY-MM");//年月
                    let workRatio = process_data[k].workRatio;//完成比例
                    currentMember.processList[j].finishRatio += workRatio;
                    let arr = currentMember.processList[j].monthRatio.filter(item=>item.yearMonth === yearMonth);
                    if(arr.length>0){
                      arr[0].workRatio += workRatio;
                    }
                    else{
                      currentMember.processList[j].monthRatio.push({yearMonth,workRatio})
                    }

                  }
                }else{
                  currentMember.processList[j].workDiary = [];
                }
              }
            }

          }
            this.spinning = false;

            this.taskMonthPercent = [];
            for(let i in this.members){
                let taskMonthPercent = this.members[i].taskMonthPercent;
                for(let j in taskMonthPercent){
                    let arr = this.taskMonthPercent.filter(item =>item.yearMonth === taskMonthPercent[j].yearMonth)
                    if(arr.length>0){
                        arr[0].value += taskMonthPercent[j].value;
                        arr[0].percent += parseFloat((taskMonthPercent[j].value/this.members[i].taskWeight*100).toFixed(2));

                    }else{
                        this.taskMonthPercent.push({
                            yearMonth:taskMonthPercent[j].yearMonth,
                            value:taskMonthPercent[j].value,
                            weight:this.members[i].taskWeight,
                            percent:parseFloat((taskMonthPercent[j].value/this.members[i].taskWeight*100).toFixed(2))
                        })
                    }
                }
            }
            //this.calculate_fill();
        },

        /*** 自动计算人员绩效 **/
        calculate_fill(){
     /*     let jx_data = this.argument.dataSource;
          this.monthComplete = jx_data.filter(item=>item.yearMonth === this.yearMonth);
          this.moneyArrange = [];
          for(let i in this.members){
            let {id,name} = this.members[i];
            // 获取成员绩效占比
            let userInfo = this.monthComplete.filter(item=>item.userId === id);
            if(userInfo.length > 0){
              this.moneyArrange.push(new MoneyArrange(id,name,this.task.id,userInfo[0].completeRatio,userInfo[0].completeMoney,userInfo[0].leaderMoney))
            }else{
              this.moneyArrange.push(new MoneyArrange(id,name,this.task.id,0,0,0))
            }

          }*/

          if(!this.yearMonth){
            this.$message.error("请选择月份！");
            return
          }
          if(this.arrangePercent == 0 || this.arrangeNum_v == 0){
            this.$message.error("请输入分配作业绩效产值！");
            return
          }
          // 获取所有复选框
          var checkboxes = document.getElementsByName('mycheckboxes');
          for(let i in this.moneyArrange){
            let checkbox_value = checkboxes[i]._value;
            let checkbox_checked = checkboxes[i].checked;
            if(checkbox_checked && (checkbox_value == this.moneyArrange[i].userId)){
              this.moneyArrange[i].completeMoney = this.moneyArrange[i].percent * this.arrangeNum_v/100;
              this.moneyArrange[i].setArrangeNum(this.arrangeNum_v)
            }
          }

          let sum_completeRatio = 0;// 总占比
          for(let i in this.moneyArrange){
            sum_completeRatio += this.moneyArrange[i].percent;
          }
         /* if(sum_completeRatio < 0){
            this.overArranged_flag = true;
            this.zyjx_title = '请先对选择人员占比进行分配';
          }*/
        },

    }
}
class MoneyArrange{
    constructor(id,name,taskId,percent,completeMoney,leaderMoney,processId) {
        this.id = processId;
        this.userId = id;
        this.name = name;
        this.taskId = taskId;
        this.percents = percent == null || percent == undefined ? 0 :percent;
        this.completeMoney = completeMoney == null || completeMoney == undefined ? 0 :completeMoney;
        this.leaderMoney = leaderMoney == null || leaderMoney == undefined ? 0 :leaderMoney;
        this.completeRatio = 0;
        this.remarks = "";
        this.arrangeNum = 0;

    }
    get percent(){
        if(this.arrangeNum > 0){
          return parseFloat((this.completeMoney/this.arrangeNum *100).toFixed(2));
        }
        else{
            return this.percents;
        }
    }
    set percent(percent){
      this.percents = percent;
        let money = this.arrangeNum * percent /100;
        this.completeMoney = money;

    }
    setArrangeNum(arrangeNum){
        this.arrangeNum = arrangeNum;
    }
}
</script>

<style scoped>

</style>