<!--
* @program: office_automation 
* @author: ly
* @component:otherPartyTabs 
* @description: 
* @create: 2022-06-09 16:26
-->
<template>
    <div class="tab-container" >
        <a-tabs @change="handleChange"  v-if="paneList && paneList.length>0" v-model:activeKey="activeKey" :size="size">
            <a-tab-pane  v-for="(pane,index) in paneList" :key="index.toString()" :tab="pane.tag" >
                <div style="height: 100%;overflow-y: auto"
                     :ref="pane.component"
                     v-is="pane.component"
                     :viewModel="pane.viewModel"
                     :record="pane.record"
                     @refresh="refresh" />
            </a-tab-pane>
        </a-tabs>
        <welcome2 v-else />
    </div>
</template>

<script>
    import detailInfo from "./relateTab/detailInfo";
    import partnerInfo from "./relateTab/partnerInfo";
    import Welcome2 from "../../welcome2";

    export default {
        name: "otherPartyTabs",
        props:["paneList"],
        components:{
            Welcome2,
            detailInfo,
            partnerInfo   //外协相关项目详情
        },
        emits:["refresh"],
        watch:{
            paneList(){
                if(parseInt(this.activeKey) >= this.paneList.length){
                    this.activeKey = "0"
                }
            }
        },
        data(){
            return{
                activeKey:"0",
                size:"small",
            }
        },
        methods:{
            refresh(){
                this.$emit("refresh");
            },
            handleChange(activeKey){
                let index = parseInt(activeKey);
                let tabComponent = this.paneList[index].component;
                if(this.$refs[tabComponent] && this.$refs[tabComponent].init){
                    this.$refs[tabComponent].init()
                }
            }
        }
    }
</script>

<style scoped>

</style>