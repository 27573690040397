<!--
* @program: TYH_office_automation 
* @author: ly
* @component:childBillTable
* @description: 
* @create: 2021-06-29 10:37
-->
<template>
    <a-table
            v-if="viewModel"
            class="myTable"
            bordered
            :dataSource="source"
            :columns="cols" rowKey="id"
            :pagination="pagination"
            :loading="loading"
    >
        <template #actions="{index,record}">
            <div style="text-align: center">
                <a-button class="action-button" type="link" @click="showDetail(index,record)">
                    <SearchOutlined class="pointer" />
                </a-button>
            </div>
        </template>
        <template #String="{column,record}">
            <type-string :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Number="{column,record}">
            <type-number :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Enum="{column,record}">
            <type-enum :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Date="{column,record}">
            <type-date :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Month="{column,record}">
            <type-month :viewModel="true" :column="column" :record="record" />
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Boolean="{column,record}">
            <type-boolean :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Index="{column,record,index}">
            <type-index :currentIndex="currentIndex" :index="index" :passedNum="passedNum" :viewModel="true" :column="column" :record="record"/>
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :viewModel="true" :column="column" :record="record" />
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Category="{column,record}">
            <type-category :viewModel="true" :column="column" :record="record" />
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :viewModel="true" :column="column" :record="record" />
        </template>
        <template #CategoryAssets="{column,record}">
            <type-category-assets :viewModel="true" :column="column" :record="record" />
        </template>
        <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
                <a-input
                        ref="searchInput"
                        :placeholder="`查询 ${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                />
                <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    <template #icon><SearchOutlined /></template>
                    查询
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    重置
                </a-button>
            </div>
        </template>
    </a-table>
    <a-table
            v-else
            class="myTable"
            bordered
            :dataSource="source"
            :columns="cols" rowKey="id"
            :pagination="pagination"
            :loading="loading"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
        <template #actions="{index,record}">
            <div style="text-align: center">
                <a-button class="action-button" type="link" @click="showDetail(index,record)">
                    <SearchOutlined class="pointer" />
                </a-button>
            </div>
        </template>
        <template #String="{column,record}">
            <type-string :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Number="{column,record}">
            <type-number :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Enum="{column,record}">
            <type-enum :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Date="{column,record}">
            <type-date :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Month="{column,record}">
            <type-month :viewModel="true" :column="column" :record="record" />
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Boolean="{column,record}">
            <type-boolean :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Index="{column,record,index}">
            <type-index :currentIndex="currentIndex" :index="index" :passedNum="passedNum" :viewModel="true" :column="column" :record="record"/>
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :viewModel="true" :column="column" :record="record" />
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Category="{column,record}">
            <type-category :viewModel="true" :column="column" :record="record" />
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :viewModel="true" :column="column" :record="record" />
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :viewModel="true" :column="column" :record="record" />
        </template>
        <template #CategoryAssets="{column,record}">
            <type-category-assets :viewModel="true" :column="column" :record="record" />
        </template>
        <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
                <a-input
                        ref="searchInput"
                        :placeholder="`查询 ${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                />
                <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    <template #icon><SearchOutlined /></template>
                    查询
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    重置
                </a-button>
            </div>
        </template>
    </a-table>
</template>

<script>
    import propsBaseTable2 from "../../../components/table/propsBaseTable2";
    export default {
        name: "childBillTable",
        mixins:[propsBaseTable2],
        data(){
            return{
                selectedRowKeys:[],
                selectedRows:[],
                selectionSetting: null,
            }
        },
        methods:{
            onSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
            },
            getSelectionKeys(){
                return this.selectedRowKeys;
            },
            getSelection(){
                return this.selectedRows;
            },
            setSelectionKeys(keys){
                this.selectedRowKeys = keys;
            }
        },
        created() {

        }
    }
</script>

<style scoped>

</style>