/** 
* 自动生成文件，请勿修改 
* @name: 用章:各人员用章审批流程设置
* @description: 用章-各人员用章审批流程-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const sealAuditProcess = { 
	/** 1 summary:人员用章审批流程-分页查询	method:post */ 
	queryPage : new APIObject("/api/sealAuditProcess/queryPage.do"), 
	/** 2 summary:人员用章审批流程-所有数据查询	method:post */ 
	queryAll : new APIObject("/api/sealAuditProcess/queryAll.do"), 
	/** 3 summary:人员用章审批流程-单条删除	method:post */ 
	delete : new APIObject("/api/sealAuditProcess/delete.do"), 
	/** 4 summary:人员用章审批流程-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/sealAuditProcess/saveOrUpd.do"), 
	/** 5 summary:人员用章审批流程-批量保存	method:post */ 
	batchSave : new APIObject("/api/sealAuditProcess/batchSave.do"), 
	/** 6 summary:人员用章审批流程-批量删除	method:post */ 
	batchDelete : new APIObject("/api/sealAuditProcess/batchDelete.do"), 
	/** 7 summary:人员用章审批流程-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/sealAuditProcess/queryOne.do"), 
};
export default sealAuditProcess;