<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityReportViewer 
* @description: 查看每个项目的质检结果
* @create: 2021-07-09 14:29
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData" />
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <div style="margin-left: 20px">完成状态：</div>
                <a-radio-group class="search-item" v-model:value="defaultQuery.state" @change="radioChange">
                    <a-radio value="0">未完成</a-radio>
                    <a-radio value="1">已完成</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[50,50]" >
                <template #left>
                    <TcBaseTable ref="local_table" :page-size="pageSize" :view-model="true" :data-source="dataSource"
                                 @pageChange = "handlePageChange"
                                 :table-columns="tableColumns" :loading="loading" >
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button class="action-button" type="link" @click="showDetail(record)">
                                    <SearchOutlined class="pointer" />
                                </a-button>
                                <a-tooltip v-if="record.state ==='0'"  title="项目质检评定">
                                    <a-button class="action-button" type="link" @click="applyEvaluate(record)">
                                        <StarOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-popconfirm
                                        v-if="record.state ==='0'"
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确认质检任务完成吗?"
                                        @confirm="qcFinish(record)">
                                    <a-tooltip title="质检任务完成">
                                        <a-button  class="action-button" type="link">
                                            <CheckOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <qualityTabs ref="local_tabs" />
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import qualityTabs from "../qualityComponents/qualityTabs";
    import qualityTaskViewer from "../qualityTaskViewer/qualityTaskViewer";
    import {StarOutlined,CheckOutlined} from "@ant-design/icons-vue"
    import api_item_qc_apply from "../../../assets/api/qcApply";
    export default {
        name: "qualityReportViewer",
        mixins:[qualityTaskViewer],
        components:{
            qualityTabs,StarOutlined,CheckOutlined
        },
        methods:{
            showDetail(record){
                this.currentRecord = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"项目质检报告",component:"qualityProjectReport",record:this.currentRecord,viewModel:true},
                    {tag:"质检组报告",component:"qualityReportList",record:this.currentRecord,viewModel:true},
                    {tag:"质检组",component:"qualityTaskStepGroup",record:this.currentRecord,viewModel:true},
                    {tag:"质检工序",component:"qualityTaskStepSet",record:this.currentRecord,viewModel:true},
                    {tag:"质检工序分配",component:"qualityTaskStepArrangement",record:this.currentRecord,viewModel:true},
                ]);
            },
            applyEvaluate(record){
                this.currentRecord = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"项目质检报告",component:"qualityProjectReport",record:this.currentRecord,viewModel:false},
                ]);
            },
            qcFinish(record){
                let {id,state} = record;
                state = "1";
                api_item_qc_apply.complete.requestPOSTUrlParam(this,{id,state},res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        mounted() {
            this.init()
        }
    }
</script>

<style scoped>

</style>