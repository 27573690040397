<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentManager
* @description: 部门管理
* @create: 2022-03-02 17:58
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button class="search-item" type="dashed"  @click="addData">
                    <template #icon><UsergroupAddOutlined style="color: #1890ff"/></template>
                    新增部门
                </a-button>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[60,50]" >
                <template #left>
                    <ArBaseTable :argument="argument" :view-model="true" :page-size="pageSize">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="editData(record)" class="action-button"  type="link">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-tooltip title="部门成员一览">
                                    <a-button  class="action-button" type="link" @click="memberShow(record)">
                                        <TeamOutlined style="color: #0081ff;"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip title="部门活动经费查询">
                                    <a-button  class="action-button" type="link" @click="MoneySum(record)">
                                        <AlignCenterOutlined style="color: #0081ff;"/>
                                    </a-button>
                                </a-tooltip>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定移除吗?"
                                        @confirm="deleteData(index,record)">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <div v-if="visible3">
                        <a-page-header style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;">
                            <template #title>
                                部门成员列表
                                <a-button class="search-item" type="dashed"  @click="addMember">
                                    <template #icon><UsergroupAddOutlined style="color: #1890ff"/></template>
                                    增加成员
                                </a-button>
                            </template>
                        </a-page-header>
                        <TcBaseTable :loading="loading" :view-model="true" :page-size="99"
                                     :data-source="dataSource" :table-columns="tableColumns" >
                            <template #action="{index,record}">
                                <div class="action-column">
                                    <a-popconfirm
                                            ok-text="是"
                                            cancel-text="否"
                                            class="action-button"
                                            title="确定移除成员吗?"
                                            @confirm="removeMember(index,record)">
                                        <DeleteOutlined  style="color: red" class="pointer"/>
                                    </a-popconfirm>
                                </div>
                            </template>
                        </TcBaseTable>
                    </div>
                    <div v-if="visible4" >
                        <a-page-header style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;" :title="'部门活动经费 (合计：'+num3+')'"/>
                        <TcBaseTable :table-columns="tc3"
                                     :data-source="queryInfoId.flowList" :view-model="true" :page-size="15" />
                    </div>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="部门">
        <ArBaseForm :argument="formArg" :view-model="false" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             title="添加成员">
        <UserPickerAll  v-model:value="users"/>
        <a-button  type="primary"  @click="saveMember">
            提交
        </a-button>
    </a-modal>

</template>

<script>
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import TwoParts from "../../_components/TwoParts";
    import {SearchOutlined,TeamOutlined, SyncOutlined,UsergroupAddOutlined,EditFilled,DeleteOutlined,AlignCenterOutlined} from "@ant-design/icons-vue";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import sys_department from "../../../assets/tables/sys_department";
    import sys_user_info_2 from "../../../assets/tables/sys_user_info_2";
    import department from  "../../../assets/api/department"
    import userDepartRelation from  "../../../assets/api/userDepartRelation"
    import Welcome2 from "../../welcome2";
    import UserPickerAll from "../../../components/UserPicker/UserPickerAll";
    import api_department from "../../../assets/api/department";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    export default {
        name: "DepartmentManager",
        components: {
            Welcome2,
            ArBaseTable,ArBaseForm,TwoParts,SearchOutlined,SyncOutlined,AlignCenterOutlined,
            UsergroupAddOutlined,EditFilled,TeamOutlined,DeleteOutlined,
            TcBaseTable,UserPickerAll
        },
        data(){
            return{
                table:new sys_department(),
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                currentRecord:null,

                dataSource:null,//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                users:null,
                visible2:false,
                queryInfoId:{},
                tc3:[
                    new Column("支出时间","flowTime",ColumnType.Date,true).setTableView(80),
                    new Column("发票金额(元)","invoiceAmount",ColumnType.Number,true).setTableView(100),
                    new Column("报销金额(元)","applyAmount",ColumnType.Number,true).setTableView(100),
                    new Column("报销凭证","invoiceUrl",ColumnType.PreViewFile,true).setTableView(100),
                    new Column("备注","remarks",ColumnType.String,true).setTableView(200),
                ],
                visible3:false,
                visible4:false
            }
        },
        computed:{
            num3(){
                if(this.queryInfoId.flowList && this.queryInfoId.flowList.length>0){
                    let sum = 0;
                    for(let item of this.queryInfoId.flowList){
                        sum += item.applyAmount
                    }
                    return sum;
                }
                else{
                    return 0
                }
            }
        },
        methods:{
            init(){
                this.argument.tableColumns = this.table.getTableColumns();
                this.formArg.formColumns = this.table.getFormColumns();
                this.tableColumns = new sys_user_info_2().getTableColumns();

                this.argument.loading = true;
                department.queryAll.requestPOST(this,{},res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            refresh(){
                this.init();
            },
            editData(record){
                this.currentRecord = record;
                this.formArg.formData = record;
                this.visible = true;
            },
            addData(){
                this.formArg.formData = this.table.getFormData();
                this.visible = true;
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                department.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            memberShow(record){
                this.visible3 = true;
                this.visible4 = false;
                this.currentRecord = record;
                let data = {
                    departmentId:record.id
                };
                this.loading = true;
                userDepartRelation.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            removeMember(index,record){
                let data = {id:record.id};
                userDepartRelation.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record){
                if(!record.id){
                    department.save.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.init();
                            this.visible=false;
                        }
                        else{
                            this.$message.error(res.data.msg)
                        }
                    });
                }
                else{
                    department.update.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.init();
                            this.visible=false;
                        }
                        else{
                            this.$message.error(res.data.msg)
                        }
                    });
                }
            },
            addMember(){
                this.users = null;
                this.visible2 = true;
            },
            saveMember(){
                let param=[];
                for(let i in this.users){
                    let data ={
                        "departmentId": this.currentRecord.id,
                        "userId": this.users[i]
                    };
                    param.push(data);
                }
                userDepartRelation.batchSaveOrUpd.requestPOST(this,{"userDepartRelationVOList":param},res=>{
                    if(res.data.flag){
                        this.visible2=false;
                        this.$message.success(res.data.msg);
                        let data = {
                            departmentId:this.currentRecord.id,
                        };
                        this.loading = true;
                        userDepartRelation.queryAll.requestPOSTUrlParam(this,data,res=>{
                            this.loading = false;
                            if(res.data.flag){
                                this.dataSource = res.data.data;
                            }
                            else{
                                this.dataSource = []
                            }
                        })
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            MoneySum(record){
                this.visible3 = false;
                this.visible4 = true;
                let data = {
                    id:record.id
                };
                api_department.queryInfoId.requestPOSTUrlParam(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.queryInfoId = res.data.data;
                    }
                    else{
                        this.queryInfoId = {};
                    }
                });
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>