<!--
* @program: office_automation 
* @author: ly
* @component:SealStepsAdd 
* @description: 
* @create: 2022-06-07 17:09
-->
<template>
    <div>
        用户：
        <span style="margin-right:5px" v-for="(user,index) in users" :key="index">{{user.name?user.name:user.userName}}</span>
    </div>
    <span>  印章用途：</span>
    <a-checkbox-group v-model:value="purposeIds" :options="purposes" />
    <div>
        <div >
            <a-button class="search-item" type="dashed"  @click="handleAdd">
                <template #icon><PlusOutlined /></template>
            </a-button>
            <a-popconfirm
                    ok-text="是"
                    cancel-text="否"
                    class="action-button"
                    title="确定保存吗？"
                    @confirm="handleSave">
                <a-button class="search-item" type="dashed">
                    <template #icon><CheckOutlined style="color: green" /></template>
                </a-button>
            </a-popconfirm>
        </div>
        <a-table bordered rowKey="id"
                 :pagination="{hideOnSinglePage: true}"
                 :data-source="dataSource"
                 :columns="columns">
            <template #Index="{column,record,index}">
                <type-index :currentIndex="null" :index="index" :passedNum="0" :viewModel="false" :column="column" :record="record"/>
            </template>
            <template #custom="{record,column}">
                <a-select v-model:value="record[column.dataIndex]"
                          show-search
                          placeholder="请输入审批人名字"
                          :default-active-first-option="false"
                          :show-arrow="false"
                          :filter-option="false"
                          :not-found-content="null"
                          @search="handleSearch"
                          style="width: 100%"
                >
                    <a-select-option v-for="option in options" :value="option.id" :key="option.id">
                        {{option.name}}
                    </a-select-option>
                </a-select>
            </template>
            <template #actions="{index}">
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </template>
        </a-table>
    </div>
</template>

<script>
    import typeIndex from "../../../components/dataPresentType/typeIndex";
    import {CheckOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons-vue";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {uuid} from "../../../assets/utils/general";
    import api_sys_user_info from "../../../assets/api/userInfo";
    import sealAuditProcess from "../../../assets/api/sealAuditProcess";

    export default {
        name: "SealStepsAdd",
        props:["users","purposes"],
        emits:["finish"],
        components:{
            typeIndex,DeleteOutlined,PlusOutlined,CheckOutlined
        },
        data(){
            return{
                dataSource:[],
                columns:[
                    new Column("序号","id",ColumnType.Index,false).setTableView(80),
                    new Column("审批人","userId","custom",true),
                    new Column("操作","actions","actions",false).setTableView(80)
                ],
                options:[],
                purposeIds:null
            }
        },
        methods:{
            handleAdd(){
                this.dataSource.push({
                    id:uuid(4,10)
                });
            },
            deleteData(index){
                this.dataSource.splice(index,1);
            },
            handleSearch(key){
                let data={queryText:key,disableState:"1",loginState:"0"};
                api_sys_user_info.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.options = res.data.data;
                    }
                })
            },
            handleSave(){
                if(this.purposeIds && this.purposeIds.length >0){
                    let auditUsers = "";
                    for(let i in this.dataSource){
                        auditUsers += this.dataSource[i].userId+",";
                    }
                    auditUsers = auditUsers.substr(0,auditUsers.length-1);
                    let args=[];
                    for(let i in this.users){
                        for(let j in this.purposeIds){
                            if(Object.prototype.hasOwnProperty.call(this.users[i],"userId")){
                                let arg = new Steps(this.users[i].userId,auditUsers,this.purposeIds[j]);
                                args.push(arg);
                            }
                            else{
                                let arg =new Steps(this.users[i].id,auditUsers,this.purposeIds[j]);
                                args.push(arg);
                            }
                        }
                    }
                    //let data = {processAuditListVO:args};
                    sealAuditProcess.batchSave.requestPOST(this,{"sealAuditProcessVOS":args},res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.$emit("finish");
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                else{
                    this.$message.error("请选择需要设定的用章类型")
                }

            }
        }
    }

    /**
     *
     "auditUsers": "string",
     "id": "string",
     "purposeId": "string",
     "remarks": "string",
     "userId": "string"
     */
    function Steps(userId,auditUsers,purposeId) { //auditUsers:审核人id组字符串，用 , 分割
        this.userId = userId;
        this.auditUsers = auditUsers;
        this.purposeId = purposeId; //类型：1：申领审批流程；2：归还审批流程
    }
</script>

<style scoped>

</style>