/**
 * @program: tyh-oa
 * @author: ssr
 * @description: sys_app_menu  菜单
 * @create: 2021-04-16 15:44
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class sys_menu extends Table{
    constructor() {
        super();
        this.name = "sys_app_menu";
        this.CNName = "菜单";
        this.columnArray = [
            new Column("父节点id","pid",ColumnType.String,false).setDefaultValue("0"),
            new Column("标题","text",ColumnType.String,true),
            new Column("菜单路径","pagePath",ColumnType.String,true),
            new Column("菜单图标路径","iconPath",ColumnType.String,true),
            new Column("菜单图标选中路径","selectedIconPath",ColumnType.String,true),
            new Column("排序","sort",ColumnType.Number,true),
            //new Column("权限","permissions",ColumnType.Number,true),  本字段单独处理，不需要放出来
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(200)];
    }
}