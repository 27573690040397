<!--
* @program: office_automation 
* @author: ly
* @component:partnerInfo 
* @description: 
* @create: 2022-06-10 10:06
-->
<template>
    <TcBaseTable :view-model="true" :loading="loading" :page-size="15" :table-columns="tableColumns" :data-source="dataSource" >
        <template #action="{record}">
            <div class="action-column">
                <a-button class="action-button" type="link" @click="showDetail(record)">
                    <SearchOutlined class="pointer"/>
                </a-button>
            </div>
        </template>
    </TcBaseTable>
</template>

<script>
    import itemInfo from "../../../../assets/api/itemInfo";
    import TcBaseTable from "../../../../components/table/TcBaseTable";
    import {SearchOutlined} from "@ant-design/icons-vue"
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    export default {
        name: "partnerInfo",
        props:["record"],
        components:{
            TcBaseTable,SearchOutlined
        },
        data() {
            return {
                loading:false,
                tableColumns:[
                    new Column("序号","_index",ColumnType.Index).setTableView(60),
                    new Column("参与项目名称","name",ColumnType.String).setTableView(250),
                    new Column("操作","actions","actions").setTableView(80)
                ],
                dataSource:[]
            }
        },
        watch:{
            record(){
                this.init()
            }
        },
        methods: {
            init() {
                let data = {
                    outId:this.record.id
                };
                itemInfo.queryByOutId.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            showDetail(record){
                this.$message.info("待开发")
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>