/**
 * @program: office_automation
 * @author: ly
 * @description: companies
 * @create: 2021-12-15 18:40
 * 数据来源为数据库
 *
 以前的指代
 ["0","1","2","3","4"],
 ["贵州图云慧空间信息技术有限公司",
 "北京中色地科测绘有限公司",
 "北京中色地科测绘有限公司贵州分公司",
 "北京中色测绘院有限公司",
 "河北中色测绘有限公司"]

 id
 :
 "4d85b844-f714-4390-b86d-cd1e4dbebb85"
 name
 :
 "中色资源环境工程股份有限公司"

**/

let companies = [
    {id:"2b5f21c6-750e-4f98-b0c1-c7068b700a27",name:"贵州图云慧空间信息技术有限公司",color:"gold"},
    {id:"5ce1d392-cf46-43a0-8097-fc1d37f57784",name:"贵州中色蓝图科技有限公司",color:"gold"},
    {id:"d0bc5391-7367-4b5b-97fb-12d7fc67cc6d",name:"贵州中色蓝图科技有限公司兴义分公司",color:"gold"},
    {id:"0303b748-93b2-4ae4-b737-558bf2f8b282",name:"贵州中色蓝图科技有限公司花溪分公司",color:"gold"},
    {id:"3d47a0a0-9131-4de2-bdf3-d570f16b744d",name:"贵州图云测绘产品质量检查有限公司",color:"gold"},
    {id:"70f61478-c716-477c-b0e7-9f2e333e6538",name:"北京中色测绘院有限公司",color:"purple"},
    {id:"aa60eee4-8212-4ccd-a21d-31fb7dff6f3f",name:"北京中色地科测绘有限公司",color:"purple"},
    {id:"0d89dd47-1f19-4e97-a565-eae226d6d743",name:"北京中色地科测绘有限公司贵州分公司",color:"purple"},
    {id:"15b60020-4691-4d3c-aa38-cd5e54b3b784",name:"中色蓝图科技股份有限公司",color:"pink"},
    {id:"4d85b844-f714-4390-b86d-cd1e4dbebb85",name:"中色资源环境工程股份有限公司",color:"pink"},
    {id:"9fac494d-d74e-49d2-ab39-7da87e0783a9",name:"中色蓝图科技股份有限公司贵州分公司",color:"pink"},
    {id:"1527858e-4494-4647-ab87-ebfcc2ec8935",name:"河北中色测绘有限公司",color:"red"},
];
let companyIds = [];
let companyNames = [];
let companyColors = [];

for(let comp of companies){
    let {id,name,color} = comp;
    companyIds.push(id);
    companyNames.push(name);
    companyColors.push(color);
}


export {companyIds,companyNames,companyColors}