<!--
*@description：抽屉组件：编辑页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <a-drawer
      :destroyOnClose="true"
      placement="bottom"
      :title="title"
      :visible="visible"
      @close="onClose"
      :get-container="false"
      height="400"
  >
    <!-- 搜索栏 start -->
    <config-tool-bar ref="toolBar" @event="eventHandle" />
    <!-- 搜索栏 end -->

    <!-- table start -->
    <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange" :scroll="{ y:350}" >
      <template v-slot:action="{index,record}">
        <div style="justify-content: center;;display: flex;">

          <a-button type="link" @click="showData(index,record)">
            <template #icon><Show title="查看"/></template>
          </a-button>

          <a-button type="link" @click="editData(index,record)">
            <template #icon><Edit title="编辑"/></template>
          </a-button>

          <a-popconfirm
              class="action-button"
              title="确定删除吗?"
              okText="确定"
              cancelText="取消"
              @confirm="deleteData(index,record)">

            <a-button type="link">
              <template #icon><Delete title="删除" style="color: red"/></template>
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ArBaseTable>
    <!-- table end -->

    <!-- 属性配置编辑 -->
    <achieve-config-edit ref="configEdit" @event="eventHandle"/>
  </a-drawer>
</template>

<script>
import Icons from "../../../common/Icons";
import achieveType_api from "../config/achieveType_api";
import ArBaseTable from "../../../../components/table/ArBaseTable";
import configToolBar from "./configToolBar";

import achieveConfig_item from "../config/achieveConfig_item";
import achieveConfigEdit from "../point/achieveConfigEdit";


export default {
  name: "achieveTypeDrawer",
  mixins:[Icons],
  components:{
    configToolBar,ArBaseTable,achieveConfigEdit
  },
  data(){
      return{
        visible: false,
        title:null,
        argument:{
          dataSource:null,//提供table的DataSource
          tableColumns:new achieveConfig_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
          loading:false,//table的loading状态
          currentIndex:null,
          formColumns:new achieveConfig_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
          formData:{},
        },
        pageParam:{
          page: 1,
          limit: 4,
        },
        typeId:null,
      }
  },
  methods:{
    open(formData){
      this.visible = true;
      this.title = "属性配置："+formData.name;
      this.typeId = formData.id;
      this.initConfig();
    },
    onClose() {
      this.visible = false;
    },
    initConfig(){
      this.argument.loading = true;
      let parms = {
        typeId:this.typeId,
        name:null,
        code:null,
      };
      this.pageParam.page = 1;
      this.pageParam.limit = 4;
      achieveType_api.pz_queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
        this.argument.loading = false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data;
          this.$refs.pstable.setTotalSize(res.data.count);
        }
      });
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      parms.typeId = this.typeId;
      this.argument.loading = true;
      achieveType_api.pz_queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data;
          this.$refs.pstable.setTotalSize(res.data.count);
        }
      });
    },
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
          this.argument.loading = true;
          let parms = this.$refs.toolBar.getParms();
          parms.typeId = this.typeId;
          this.pageParam.page = 1;
          this.pageParam.limit = 4;
          this.$refs.pstable.setCurrentPage(1);
          achieveType_api.pz_queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource =  res.data.data;
              this.$refs.pstable.setTotalSize(res.data.count);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          this.$refs.pstable.setCurrentPage(1);
          this.initConfig();
          break;
        }
        case this.$eventTags.add:{
          this.argument.formData = {};
          this.argument.formData.pid = this.pid;
          this.$refs.configEdit.open("新增信息","add",false,this.typeId,this.argument);
          break;
        }

        case this.$eventTags.editInfo:{
          this.$refs.pstable.setCurrentPage(1);
          this.initConfig();
          break;
        }

      }
    },
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.configEdit.open("查看信息","show",true,this.typeId,this.argument);
    },
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.configEdit.open("编辑信息","edit",false,this.typeId,this.argument);
    },
    deleteData(index,arg){
      let data = {id:arg.id};
      achieveType_api.pz_delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          this.initConfig();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
  }
}
</script>

