<!--
* @program: office_automation 
* @author: ly
* @component:MenuOpBind 
* @description: 
* @create: 2023-03-02 14:51
-->
<template>
    <a-tag style="cursor: pointer;font-size: 18px" @click="setOps(item)" v-for="item in mops" :key="item" :color="getEnable(item)?'blue':''">{{item.name}}</a-tag>
</template>

<script>

    export default {
        name: "MenuOpBind",
        props:["mops","sops"], //mops  menu所有能选的ops,sops  该角色拥有的ops
        emits:["update:sops"],
        data() {
            return {
                lops:[],//本地暂存的角色ops
            }
        },
        watch:{
            sops(){
                this.init()
            }
        },
        methods: {
            init() {
                if(this.sops){
                    this.lops = [...this.sops];
                }
                else{
                    this.lops = [];
                }
            },
            getEnable(item){
                let op = this.lops.find(i=>i.opcode === item.opcode);
                return !!op;
            },
            setOps(item){
                let op = this.lops.find(i=>i.opcode === item.opcode);
                if(op){
                    let index = this.lops.indexOf(op);
                    this.lops.splice(index,1);
                }
                else{
                    this.lops.push(item);
                }
                this.$emit("update:sops",this.lops);
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>