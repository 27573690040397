<!--
* @program: tyh-oa 
* @author: ly
* @component:billTable 待审核账单用tableList
* @description: 
* @create: 2021-04-07 14:32
-->
<template>
    <a-table :columns="cols" :dataSource="source"
             :loading="loading"
             :pagination="pagination"
             :rowClassName = "rowClass"
             bordered
             class="myTable"
             rowKey="id">
        <template #actions="{index,record}">
            <div class="action-column">
                <a-button @click="showDetail(index,record)" class="action-button" type="link">
                    <SearchOutlined class="pointer"/>
                </a-button>
                <a-popconfirm
                        @confirm="admit(record)" cancel-text="取消"
                        ok-text="通过"
                        title="确定通过吗？"
                >
                    <a-button class="action-button" type="link">
                        <CheckOutlined class="pointer" style="color: green"/>
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                        @confirm="reject(record)" @visibleChange="visibleChange"
                        cancel-text="取消"
                        ok-text="确定">
                    <template #title>
                        <a-form layout="vertical">
                            <a-form-item label="请输入驳回原因">
                                <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" placeholder="驳回原因"
                                            v-model:value="reason"/>
                            </a-form-item>
                        </a-form>
                    </template>
                    <a-button class="action-button" type="link">
                        <RollbackOutlined class="pointer" style="color: red;"/>
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                        @confirm="downloadFlows(record)"
                        cancel-text="否"
                        class="action-button"
                        ok-text="是"
                        title="导出流水？">
                    <a-button class="action-button" type="link">
                        <DownloadOutlined class="pointer"/>
                    </a-button>
                </a-popconfirm>
            </div>
        </template>
        <template #String="{column,record}">
            <type-string :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Number="{column,record}">
            <type-number :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Enum="{column,record}">
            <type-enum :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Date="{column,record}">
            <type-date :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Month="{column,record}">
            <type-month :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Index="{column,record,index}">
            <type-index :column="column" :currentIndex="currentIndex" :index="index" :passedNum="passedNum"
                        :record="record" :viewModel="viewModel"/>
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Category="{column,record}">
            <type-category :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :column="column" :record="record" :viewModel="viewModel"/>
        </template>
        <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
                <a-input
                        :placeholder="`查询 ${column.title}`"
                        :value="selectedKeys[0]"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        ref="searchInput"
                        style="width: 188px; margin-bottom: 8px; display: block"
                />
                <a-button
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        type="primary"
                >
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                    查询
                </a-button>
                <a-button @click="handleReset(clearFilters)" size="small" style="width: 90px">
                    重置
                </a-button>
            </div>
        </template>
    </a-table>
</template>

<script>
    import baseTableView from "../../../components/table/baseTableView";
    import {CheckOutlined, DownloadOutlined, RollbackOutlined, SearchOutlined} from "@ant-design/icons-vue"

    export default {
        name: "billTable",
        mixins: [baseTableView],
        props:["dataSource","tableColumns"],
        computed:{
            source(){
                return this.dataSource;
            },
            cols(){
                return this.tableColumns.filter(column=>{return column.tableView});
            }
        },
        components: {
            CheckOutlined, RollbackOutlined, SearchOutlined, DownloadOutlined
        },
        data() {
            return {
                reason: null,
            }
        },
        methods: {
            rowClass(record,index){
                if(index === this.currentIndex){
                    return "highlight-row"
                }
                else{
                    return ""
                }
            },
            showDetail(index, record) {
                this.$emit("event", this.$GetEmitData(this.$eventTags.showDetail, record));
                this.currentIndex = index;
            },
            admit(record) {
                this.$emit("event", this.$GetEmitData("admit", record));
            },
            reject(record) {
                this.$emit("event", this.$GetEmitData("reject", {reason: this.reason, record: record}));
            },
            visibleChange(visible) {
                if (visible) {
                    this.reason = ""
                }
            },
            downloadFlows(record) {
                this.$emit("event", this.$GetEmitData("downloadFlows", record));
            }
        },
    }
</script>

<style scoped>

</style>