<!--
* @program: TYH_office_automation 
* @author: ly
* @component:personalTask 个人任务工序一览
* @description: 
* @create: 2021-07-01 16:51
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-radio-group button-style="solid" @change="pageSwitch" v-model:value="switchModel">
                    <a-radio-button :value="true">项目工作</a-radio-button>
                    <a-radio-button :value="false">其他工作</a-radio-button>
                </a-radio-group>
                <SearchBar v-if="switchModel" :search-options="searchOptions" v-model:value="searchData"/>
                <a-button v-if="switchModel" @click="doSearch" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                </a-button>
                <a-button v-if="switchModel" @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
                <span v-if="switchModel" style="margin-left: 10px">完成情况：</span>
                <a-radio-group v-if="switchModel" @change="radioChange" class="search-item" v-model:value="defaultOptions.state">
                    <a-radio :value="'3'">未完成</a-radio>
                    <a-radio :value="'0'">按时完成</a-radio>
                    <a-radio :value="'1'">提前完成</a-radio>
                    <a-radio :value="'2'">延期完成</a-radio>
                </a-radio-group>
            </div>
            <TwoParts v-if="switchModel" ref="TwoParts" :enable-change="false" :trans="[30,30]" >
                <template #left>
                    <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="pageSize">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-tooltip title="项目评分">
                                    <a-button v-if="viewModel" @click="scoreShow(record)" class="action-button" type="link">
                                        <RadarChartOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </ArBaseTableFilter>
                </template>
                <template #right>
                    <personalWorkCalendar v-if="currentRecord" :view-model="viewModel" :user-id="userId" :process-employee-list="currentRecord.list"/>
                    <welcome2 substr="可以添加和修改当天和前一天的工作日志" v-else />
                </template>
            </TwoParts>
            <OtherWorkCalendar v-else :view-model="false" :user-id="userId"/>
        </div>
    </div>
</template>

<script>
    import personal_task from "../../../assets/tables/personal_task";
    import api_item_task_process_employee from "../../../assets/api/processEmployee";
    import {EditFilled,RadarChartOutlined,SyncOutlined,SearchOutlined} from "@ant-design/icons-vue";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import TwoParts from "../../_components/TwoParts";
    import api_item_prod_quality from "../../../assets/api/prodQuality";
    import {h} from "vue";
    import personalWorkCalendar from "./personalWorkCalendar";
    import OtherWorkCalendar from "./OtherWorkCalendar";
    import Welcome2 from "../../welcome2";
    export default {
        name: "personalTask",
        components: {
            Welcome2, SyncOutlined,SearchOutlined,
            SearchBar,ArBaseTableFilter,TwoParts, personalWorkCalendar,EditFilled,
            RadarChartOutlined,OtherWorkCalendar
        },
        data(){
            return{
                table:new personal_task(),
                taskList:[],
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                userId:this.$store.getters.userInfo.id,
                defaultOptions:{//当前页面的查询默认条件
                    userId:this.$store.getters.userInfo.id,
                    processType:"1", //生产工序
                    state:'3'
                },
                searchOptions:[
                    {value:"queryText",label:"关键字"}
                ],
                searchData:null,
                currentRecord:null,
                pageSize:15,
                viewModel:true,
                switchModel:true,
            }
        },
        methods:{
            init(){
                this.viewModel = false;
                this.defaultOptions.state = "3";
                this.taskList = [];
                this.searchData = null;
                let data = {};
                Object.assign(data,this.defaultOptions);
                this.argument.loading = true;
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        for(let i in res.data.data){
                            let {taskTitle,processTitle,id,taskId} = res.data.data[i];
                            let arr = this.taskList.filter(item=>item.taskId === taskId);
                            if(arr.length >0){
                                arr[0].list.push(res.data.data[i]);
                            }
                            else{
                                this.taskList.push({id:id,taskId:taskId,taskTitle:taskTitle,processTitle:processTitle,list:[res.data.data[i]]})
                            }
                        }
                        this.argument.dataSource = this.taskList;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                    this.argument.loading = false;
                });
            },
            refresh(){
                this.init();
            },
            doSearch(){
                let data = {};
                Object.assign(data,this.searchData);
                Object.assign(data,this.defaultOptions);
                this.argument.loading = true;
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                    this.argument.loading = false;
                });
            },
            radioChange(){
                this.viewModel = this.defaultOptions.state !== "3";
                this.currentRecord = null;
                this.taskList = [];
                let data = {};
                Object.assign(data,this.searchData);
                Object.assign(data,this.defaultOptions);
                this.argument.loading = true;
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        for(let i in res.data.data){
                            let {taskTitle,processTitle,id} = res.data.data[i];
                            let arr = this.taskList.filter(item=>item.taskTitle === taskTitle);
                            if(arr.length >0){
                                arr[0].list.push(res.data.data[i]);
                            }
                            else{
                                this.taskList.push({id:id,taskTitle:taskTitle,processTitle:processTitle,list:[res.data.data[i]]})
                            }
                        }
                        this.argument.dataSource = this.taskList;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                    this.argument.loading = false;
                });
            },
            pageSwitch(){
                this.currentRecord = null;
            },
            showDetail(record){
                this.currentRecord = record;
            },
            scoreShow(record){
                let data = {
                    userId:this.$store.getters.userInfo.id,
                    itemName:record.itemName
                };
                api_item_prod_quality.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        let {assessment,sumDescri} = res.data.data[0];
                        let arr = ["0","1","2","3"],arr2=["不合格","合格","良好","优秀"];
                        let index = arr.indexOf(assessment);
                        this.$notification.success(
                            {
                                message: "项目评分",
                                description: h('div',[
                                    h("div","综合评价："+ arr2[index]),
                                    h("div","评价总结："+ sumDescri),
                                ])
                            })
                    }
                    else{
                        this.$message.error("还未完成质检，暂无评分")
                    }
                });
            }

        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>