/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class deviceTempList_item extends Table {
    constructor() {
        super();
        this.name = "deviceTempList_item";
        this.CNName = "固定资产";
        this.columnArray = [
            new Column("设备名称","name",ColumnType.String,true).setTableView(150),
            new Column("设备编码名称","codeName",ColumnType.String,true).setTableView(100),
            new Column("设备编码","code",ColumnType.String,true).setTableView(150),
            new Column("设备启用状态","state",ColumnType.Enum,true).setTableView(80)
                .setEnum(["0","1"],
                    ["停用","启用"],
                    ["Pink","MediumPurple","CornflowerBlue","PowderBlue"]),
            new Column("借出标志","permanentFlag",ColumnType.Enum,true).setTableView(110)
                .setEnum(["0","1"],
                    ["公司内部周转","不拿回来"],
                    ["CornflowerBlue","PowderBlue"]),
            new Column("是否固定资产","fixedAsset",ColumnType.Enum,true).setTableView(80)
                .setEnum(["0","1"],
                    ["否","是"],
                    ["#EE7942","#79CDCD"]),
            new Column("资产价值","price",ColumnType.Number,true).setTableView(110),
            new Column("固定资产类别名称","fixedCategoryName",ColumnType.String,true).setTableView(150),
            new Column("设备分类名称","categoryName",ColumnType.String,true).setTableView(150),
        ];

    }
}