/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_task_process  生产工序管理  - 部门任务一览用
 * 工序完成使用
 * @create: 2021-04-16 16:48
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_task_process_2 extends Table{
    constructor() {
        super();
        this.name = "item_task_process_2";
        this.CNName = "生产工序管理";
        this.columnArray = [
            new Column("工序标题","title",ColumnType.String,false).setTableView(100),
            new Column("工序内容","content",ColumnType.String,false).setTableView(200),
            new Column("工作量权重","workRatio",ColumnType.Number,false).setNumberRange(0,200).setTableView(100),
            new Column("任务开始时间","startDate",ColumnType.Date,false).setTableView(100),
            new Column("预计完成时间","endDate",ColumnType.Date,false).setTableView(100),
            new Column("实际完成时间","completeDate",ColumnType.Date,true).setTableView(100),
            new Column("完成状态","state",ColumnType.Enum,false).setTableView(100)
                .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"]),
            new Column("完成情况说明","describe",ColumnType.String,true).setTableView(200),
            new Column("备注","remarks",ColumnType.String,false).setTableView(100),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(160)];
    }
}