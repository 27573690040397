<!--
* @program: HXQTDZSRemake 
* @author: ly
* @component:ArBaseTableFilter
* @description: 一次把所有结果查询出来列出，页面切换事件不作响应，所以加上了字段过滤器
* @emit事件：
* @create: 2021-10-09 16:25
-->
<template>
    <a-table
            bordered
            :childrenColumnName="'children10'"
            :dataSource="source"
            :columns="cols" :rowKey="rowKey"
            :pagination="pagination"
            :loading="loading"
            :rowClassName = "rowClass"
            :custom-row="customRow"
            :scroll="{x:offsetWidth,y:offsetHeight}"
    >
        <template #filterIcon="filtered">
            <SearchOutlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #actions="{index,column,record}">
            <slot name="action" :record="record" :index="getRealIndex(index)" :column="column"/>
        </template>
        <template #Index="{column,record,index}">
            <type-index :currentIndex="currentIndex"  :index="index" :passedNum="passedNum" :viewModel="viewModel" :column="column" :record="record"/>
        </template>


        <template #Selector="{column,record}">
            <type-selector :viewModel="viewModel" :column="column" :record="record"/>
        </template>
        <template #String="{column,record}">
            <type-string :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Number="{column,record}">
            <type-number :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Enum="{column,record}">
            <type-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Date="{column,record}">
            <type-date :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Month="{column,record}">
            <type-month :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Boolean="{column,record}">
            <type-boolean :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Category="{column,record}">
            <type-category :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #CategoryAssets="{column,record}">
            <type-category-assets :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #ObjectReplace="{column,record}">
            <type-object-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PositionLevel="{column,record}">
            <type-position-level :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #TextArea="{column,record}">
            <type-text-area :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #RemoteEnum="{column,record}">
            <type-remote-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #FinancialSubject="{column,record}">
            <type-financial-subject :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Giro="{column,record}">
            <type-giro :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #StrArr="{column,record}">
            <type-str-arr :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
                <a-input
                        ref="searchInput"
                        :placeholder="`查询 ${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                />
                <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    <template #icon><SearchOutlined /></template>
                    查询
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    重置
                </a-button>
            </div>
        </template>
<!--        <template v-if="download" #footer>-->
<!--            <a-button @click="downLoadExcel" type="primary" danger>-->
<!--                下载为excel-->
<!--            </a-button>-->
<!--        </template>-->
        <template v-if="title" #title>
            <slot name="title"/>
        </template>
    </a-table>
    <div v-if=" !loading" :class="paginationVisible?'table-download-btn':''" style="display: flex;width: 32px">
        <a-tooltip title="下载为Excel">
            <a-button @click="downLoadExcel" type="primary" danger>
                <template #icon><download-outlined /></template>
            </a-button>
        </a-tooltip>
    </div>
</template>

<script>
    import ArBaseTable from "./ArBaseTable";
    import {SearchOutlined} from "@ant-design/icons-vue";
    export default {
        name: "ArBaseTableFilter",
        mixins:[ArBaseTable],
        components:{
            SearchOutlined
        },
        methods:{
            handleSearch(selectedKeys, confirm, dataIndex){
                confirm();
                this.searchText = selectedKeys[0];
                this.searchedColumn = dataIndex;
            },
            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
        }
    }
</script>

<style scoped>

</style>