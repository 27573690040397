<!--
* @program: office_automation 
* @author: ly
* @component:MonthlyAssessmentSetting
* @description: 月度考核配置设定
* @create: 2022-01-14 10:23
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <span style="margin: 20px">考核表类型：</span>
                <a-select v-model:value="defaultQuery.type" style="width: 250px" @change="selectChange">
                    <a-select-option v-for="option in options" :key="option.id">{{option.name}}</a-select-option>
                </a-select>
                <span style="margin: 20px">分数合计：{{sumScore}}</span>
                <a-button class="search-item" type="dashed" shape="circle" @click="addData" >
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </div>
            <ArBaseTable :argument="argument" :view-model="true" :page-size="pageSize">
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button class="action-button" type="link" @click="showDetail(record)">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button v-if="record.judgeRoleName !=='系统评分'" class="action-button" type="link" @click="editDetail(record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="record.judgeRoleName !=='系统评分'"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </ArBaseTable>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <div>
            <span>打分角色：</span>
            <a-select v-model:value="roleId" style="width: 250px" @change="selectChange">
                <a-select-option v-for="option in roleOptions" :key="option.id">{{option.roleName}}</a-select-option>
            </a-select>
        </div>
        <ArBaseForm :argument="formArg" :view-model="viewModel" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
</template>

<script>
    import ArBaseTable from "../../../../components/table/ArBaseTable";
    import ArBaseForm from "../../../../components/form/ArBaseForm";
    import {SyncOutlined,PlusOutlined,SearchOutlined,DeleteOutlined,EditFilled} from "@ant-design/icons-vue";
    import dimension_conf from "../../../../assets/tables/dimension_conf";
    import dimensionConf from "../../../../assets/api/dimensionConf";
    import assessmentRole from "../../../../assets/api/assessmentRole";
    export default {
        name: "MonthlyAssessmentSetting",
        components: {
            ArBaseTable,ArBaseForm,SyncOutlined,PlusOutlined,SearchOutlined,DeleteOutlined,EditFilled
        },
        computed:{
           sumScore(){
               let score = 0;
               for(let i in this.argument.dataSource){
                   score += this.argument.dataSource[i].standardScore
               }
               return score;
           }
        },
        data(){
            return{
                table:new dimension_conf(),
                viewModel:true,
                pageSize:15,
                /**
                 *
                 0:试用期员工
                 1：作业员：2：生产部门负责人；3：综合-市场专员；
                 4：综合-投标专员；5:综合部负责人；6：办公财务-工勤人员；
                 7：办公财务-财务人员；8：人资部负责人；9：技术岗晋升考核；
                 10：工勤岗晋升考核;11：作业员年度考核：12：工勤岗年度考核；
                 13：技术管理岗年度考核；14：工勤管理岗年度考核；
                 */

                options:[
                    {id:"0",name:"试用期员工"},
                    {id:"1",name:"作业员"},
                    {id:"2",name:"生产部门负责人"},
                    {id:"3",name:"综合部财务部-市场专员"},
                    {id:"4",name:"综合部财务部-投标专员"},
                    {id:"5",name:"综合部财务部负责人"},
                    {id:"6",name:"办公人事部-工勤人员"},
                    {id:"7",name:"综合部财务部-财务人员"},
                    {id:"8",name:"办公人事部负责人"},
                    // {id:"9",name:"技术岗晋升考核"},
                    // {id:"10",name:"工勤岗晋升考核"},
                    // {id:"11",name:"作业员年度考核"},
                    // {id:"12",name:"工勤岗年度考核"},
                    // {id:"13",name:"技术管理岗年度考核"},
                    // {id:"14",name:"工勤管理岗年度考核"},
                ],
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                defaultQuery: {
                    type:"1",
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                roleOptions:[],
                roleId:null
            }
        },
        methods:{
            init(){
                let data = {};
                Object.assign(data,this.defaultQuery);
                dimensionConf.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data.sort((a,b)=>{
                            return  a.sortOrder - b.sortOrder
                        });

                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            selectChange(){
                this.init()
            },
            refresh(){
                this.init()
            },
            addData(){
                this.visible = true;
                this.viewModel = false;
                this.formArg.formData = this.table.getFormData();
                this.formArg.formData.type = this.defaultQuery.type;
                if(this.roleOptions.length === 0){
                    assessmentRole.queryAll.requestPOST(this,{},res=>{
                        if(res.data.flag){
                            this.roleOptions = res.data.data;
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
            },
            showDetail(record){
                this.formArg.formData = record;
                this.viewModel = true;
                this.visible = true;
                this.roleId = record.judgeRole;
            },
            saveData(record){
                record.judgeRole = this.roleId;
                dimensionConf.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editDetail(record){
                this.formArg.formData = record;
                this.viewModel = false;
                this.visible = true;
                this.roleId = record.judgeRole;
                if(this.roleOptions.length === 0){
                    assessmentRole.queryAll.requestPOST(this,{},res=>{
                        if(res.data.flag){
                            this.roleOptions = res.data.data;
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                dimensionConf.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.formArg.formColumns = this.table.getFormColumns();
            this.init();

        }
    }
</script>

<style scoped>

</style>