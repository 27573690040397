/**
 * @program: tyh-oa
 * @author: ly
 * @description: department 部门表
 private String fullName;                // 部门全称
 private String name;                    // 部门简称
 private String pid;                     // 父节点id（根节点为0） 暂时不用，只有一级部门，二级部门时候使用
 private String remarks;                     // 备注

 * @create: 2021-04-21 16:14
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";
import api_sys_user_info from "../api/userInfo";
export default class department extends Table{
    constructor() {
        super();
        this.name = "department";
        this.CNName = "部门信息表";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String).setVisible(false),
            new Column("部门","fullName",ColumnType.String,false).setTableView(150),
            new Column("部门名称","name",ColumnType.String,true)
                .setTriggerOtherColumn(null,(formData,cols)=>{
                    formData.fullName = formData.name;
                },(formData,cols)=>{
                    formData.fullName = formData.name;
                }),
            new Column("部门类型","departType",ColumnType.Enum,true).setTableView(80)
                .setEnum(["0","1"],["生产部门","非生产部门"],["blue","pink"]),
            new Column("负责人","leaderUser",ColumnType.IdReplace,true)
                .setTableView(80)
                .setIdReplaceObject("leaderUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("分管领导","managUser",ColumnType.IdReplace,true)
                .setTableView(80)
                .setIdReplaceObject("managUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("初始余额","balance",ColumnType.Number,true),
            new Column("初始余额日期","balanceDate",ColumnType.Date,true),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(100)];
    }
}