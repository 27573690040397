<!--
* @program: office_automation 
* @author: ly
* @component:assetsAuditSetting 
* @description: 
* @create: 2022-05-31 14:30
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <a-page-header
                    style="border: 1px solid rgb(235, 237, 240)"
                    title="申请、归还审核流程设定"
            />
            <div class="search-bar" >
                <a-button class="search-item" type="dashed"  @click="init">
                    查看全部人员
                </a-button>
                <div style="margin: 10px">选择部门</div>
                <a-select v-model:value="department" style="width: 250px"
                          class="search-item"
                          @change="departmentSelect">
                    <a-select-option v-for="item in departments"  :key="item.id">
                        {{item.fullName}}
                    </a-select-option>
                </a-select>
                <a-button class="search-item" type="dashed"  @click="groupSet">
                    批量申请流程设定
                </a-button>
                <a-button class="search-item" type="dashed"  @click="groupSet2">
                    批量归还流程设定
                </a-button>
                <a-button class="search-item" type="dashed"  @click="groupSet3">
                    批量统一设定
                </a-button>
            </div>
            <div style="width: 600px" >
                <TcSSTable :loading="loading" :scrolls="{y:600}" @selectChange="handleSelectChange" :view-model="true" :table-columns="tableColumns" :data-source="dataSource" :page-size="15" >
                    <template #action="{record}">
                        <div style="text-align: center">
                            <a-tooltip title="申请流程">
                                <a-button class="action-button" type="link" @click="editDetail1(record)">
                                    <EditFilled class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip title="归还流程">
                                <a-button class="action-button" type="link" @click="editDetail2(record)">
                                    <EditFilled style="color: #00b30c" class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip title="统一设定">
                                <a-button class="action-button" type="link" @click="editDetail3(record)">
                                    <EditFilled style="color: red" class="pointer"/>
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </TcSSTable>
            </div>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <TagShow color="green" v-if="!groupSetVisible"  :visible="true" tag="当前流程">
            <template #content>
                <StepsViewer v-if="currentSteps.length>0" :steps="currentSteps" />
                <div v-else>还未设定</div>
            </template>
        </TagShow>
        <TagShow color="blue" :visible="true" tag="流程设定">
            <template #content>
                <StepsAdd :users="selectedRows" :step-type="stepType"  />
            </template>
        </TagShow>
    </a-modal>
</template>

<script>
    import TcSSTable from "../../../components/table/TcSSTable";
    import api_sys_department_relation from "../../../assets/api/userDepartRelation";
    import api_sys_user_info from "../../../assets/api/userInfo";
    import api_sys_department from "../../../assets/api/department";
    import sys_department from "../../../assets/tables/sys_department";
    import sys_user_info from "../../../assets/tables/sys_user_info";
    import sys_user_info_2 from "../../../assets/tables/sys_user_info_2";
    import {EditFilled} from "@ant-design/icons-vue"
    import StepsAdd from "./StepsAdd";
    import StepsViewer from "./StepsViewer";
    import assetAudit from "../../../assets/api/assetAudit";
    import TagShow from "../../_components/TagShow";
    export default {
        name: "assetsAuditSetting",
        components:{
            TagShow,
            TcSSTable,EditFilled,StepsAdd,StepsViewer
        },
        data() {
            return {
                departmentTable:new sys_department(),
                userTable:new sys_user_info(),
                userTable2:new sys_user_info_2(),
                departments:[],
                tableColumns:[],
                dataSource:[],
                loading:false,
                visible:false,
                selectedRows:[],
                stepType:"1", //类型：1：申领审批流程；2：归还审批流程
                currentSteps:[],
                groupSetVisible:false
            }
        },
        methods: {
            init() {
                this.tableColumns = this.userTable.getTableColumns();
                this.loading = true;
                api_sys_user_info.queryAll.requestPOST(this,{disableState:"1",loginState:"0"},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    this.loading = false;
                });
                api_sys_department.queryAll.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.departments = res.data.data;
                    }
                });
            },
            departmentSelect(){
                let param  ={
                    departmentId:this.department,
                    disableState:"1",
                    haveLevel:"0"
                };
                this.loading = true;
                this.dataSource=[];
                api_sys_department_relation.queryAll.requestPOSTUrlParam(this,param,res=>{
                    this.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        this.dataSource = res.data.data;
                        this.tableColumns = this.userTable2.getTableColumns();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handleSelectChange(selectedRowKeys,selectedRows){
                this.selectedRows = selectedRows;
            },
            editDetail1(record){
                this.groupSetVisible = false;
                this.selectedRows = [record];
                this.stepType = "1";
                this.visible = true;
                this.currentSteps=[];
                let data;
                if(Object.prototype.hasOwnProperty.call(record,"userId")){
                    data = {
                        userId:record.userId,
                        type:this.stepType
                    };
                }
                else{
                    data = {
                        userId:record.id,
                        type:this.stepType
                    };
                }
                assetAudit.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        let arr = res.data.data.filter(item=>{
                            return  item.type === this.stepType
                        });
                        if(arr.length>0){
                            this.currentSteps =  arr[0].approvalUserName
                        }
                        else {
                            this.currentSteps = [];
                        }
                    }
                });
            },
            editDetail2(record){
                this.groupSetVisible = false;
                this.selectedRows = [record];
                this.stepType = "2";
                this.visible = true;
                this.currentSteps=[];
                let data;
                if(Object.prototype.hasOwnProperty.call(record,"userId")){
                    data = {
                        userId:record.userId,
                        type:this.stepType
                    };
                }
                else{
                    data = {
                        userId:record.id,
                        type:this.stepType
                    };
                }
                assetAudit.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        let arr = res.data.data.filter(item=>{
                            return  item.type === this.stepType
                        });
                        if(arr.length>0){
                            this.currentSteps =  arr[0].approvalUserName
                        }
                        else {
                            this.currentSteps = [];
                        }
                    }
                });
            },
            editDetail3(record){
                this.groupSetVisible = false;
                this.selectedRows = [record];
                this.stepType = ["1","2"];
                this.visible = true;
                this.currentSteps = [];
            },
            groupSet(){
                this.groupSetVisible = true;
                this.stepType = "1";
                if(this.selectedRows.length>0){
                    this.visible = true;
                }
                else{
                    this.$message.error("请选择需要设定的用户");
                }
            },
            groupSet2(){
                this.groupSetVisible = true;
                this.stepType = "2";
                if(this.selectedRows.length>0){
                    this.visible = true;
                }
                else{
                    this.$message.error("请选择需要设定的用户");
                }
            },
            groupSet3(){
                this.groupSetVisible = true;
                this.stepType = ["1","2"];
                if(this.selectedRows.length>0){
                    this.visible = true;
                }
                else{
                    this.$message.error("请选择需要设定的用户");
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>