<!--
* @program: tyh-oa 
* @author: ly
* @description: typeDate 
* @create: 2021-03-23 10:44
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center">{{presentValue}}</div>
    </div>
    <div v-else>
        <div v-if="editable">
            <a-date-picker :disabledDate="disabledDate" style="width: 100%" v-model:value="date" :format="dateFormat" @change="columnTrigger"/>
        </div>
        <div v-else>
            <div style="text-align: center">{{presentValue}}</div>
        </div>
    </div>
</template>

<script>

    import typeBase from "./typeBase";
    import moment from "moment"
    export default {
        name: "typeDate",
        mixins:[typeBase],
        watch:{
            date(){
                if(this.date instanceof moment){
                    this.record[this.column.dataIndex] = this.getDate();
                }
                else{
                    this.record[this.column.dataIndex] = null
                }
            },
            record(){
                this.init();
                this.columnTrigger();
            }
        },
        data(){
            return{
                dateFormat: 'YYYY/MM/DD',
                date:null,
            }
        },
        computed:{
            presentValue(){
                let date = this.cellValue;
                if(date){
                    return moment(date).locale('zh-cn').format(this.dateFormat);
                }
                return "无";
            },
        },
        methods:{
            init(){
                this.date = this.record[this.column.dataIndex]?moment(this.record[this.column.dataIndex]):null;
            },
            getDate(){
                let date = moment(this.date).locale('zh-cn').format(this.dateFormat);
                return new Date(date).valueOf();
            },
            disabledDate(current){
                if (this.column.enableTime) {
                    if(this.column.enableTime.length === 1){
                        let month = current.format("YYYY-MM");
                        return month !== this.column.enableTime[0];
                    }
                    else if(this.column.enableTime.length === 2){
                        return !current.isBetween(this.column.enableTime[0],this.column.enableTime[1],null, '[]')
                    }
                    else{
                        return false
                    }
                }
                else{
                    return  false;
                }
            }
        },
        mounted() {
           this.init();
        }
    }
</script>

<style scoped>

</style>