<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityApplyFormView 
* @description: 
* @create: 2021-07-02 17:20
-->
<template>
    <FcForm :view-model="true" :form-columns="formColumns" :form-data="record" />
</template>

<script>
    import FcForm from "../../../components/form/FcForm";
    import item_qc_apply from "../../../assets/tables/item_qc_apply";

    export default {
        name: "qualityApplyFormView",
        props:["record"],
        components:{
            FcForm
        },
        data(){
            return{
                table: new item_qc_apply(),
                formColumns:null,
            }
        },
        created() {
            this.formColumns = this.table.getFormColumns();
        }
    }
</script>

<style scoped>

</style>