import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: sys_user_info_extra
 * @create: 2021-12-23 13:59
 **/

export default class sys_user_info_extra extends Table{
    constructor(name,CNName) {
        super(name,CNName);
        this.name = "sys_user_info_extra";
        this.CNName = "用户花名册";
        this.columnArray = [
            new Column("籍贯","nativePlace",ColumnType.String,true),
            new Column("身份证号","idCardNumber",ColumnType.String,true)
                .setTriggerOtherColumn(null,()=>{},(formData,cols)=>{
                    if(formData && formData.idCardNumber.length === 18){
                        formData.sex = getSex(formData.idCardNumber);
                        let birthday = getBirth(formData.idCardNumber);
                        formData.birthday = birthday;
                        formData.age = getAge(birthday);
                    }
                }),
            new Column("性别","sex",ColumnType.String),
            new Column("生日","birthday",ColumnType.Date),
            new Column("年龄","age",ColumnType.String),
            new Column("现住址","currentAddr",ColumnType.String,true),
            new Column("婚姻状态","maritalStatus",ColumnType.Enum,true)
                .setEnum(["未婚","已婚","离婚","丧偶"],["未婚","已婚","离婚","丧偶"]),
            new Column("政治面貌","politiOutLook",ColumnType.String,true),
            new Column("民族","nation",ColumnType.String,true),
            new Column("紧急联系人","emergContact",ColumnType.String,true),
            new Column("紧急联系电话","emergPhone",ColumnType.String,true),

            new Column("毕业院校","graduSchool",ColumnType.String,true).setGroup(1),
            new Column("毕业时间","graduDate",ColumnType.Date,true).setGroup(1),
            new Column("学历","education",ColumnType.String,true).setGroup(1),
            new Column("学位","degree",ColumnType.String,true).setGroup(1),
            new Column("专业","major",ColumnType.String,true).setGroup(1),
            new Column("职称资质","titleQualify",ColumnType.String,true).setGroup(1)
                .setEnum(["助理工程师","工程师","高级工程师","无"],
                    ["助理工程师","工程师","高级工程师","无"],["blue","purple","red","white"]
                ),
            new Column("职称专业","titleProfession",ColumnType.String,true).setGroup(1),
            new Column("职称任职时间","titleGetDate",ColumnType.Date,true).setGroup(1),
            new Column("人事档案所在地","archivesPlace",ColumnType.String,true).setGroup(1),

            new Column("入职日期","inductionDate",ColumnType.Date,true).setGroup(2)
                .setTriggerOtherColumn(null,(formData,cols)=>{
                    formData.workingYear = workingYear(formData.inductionDate);
                },(formData,cols)=>{
                    formData.workingYear = workingYear(formData.inductionDate);
                }),
            new Column("工龄","workingYear",ColumnType.String).setGroup(2),
            new Column("职位","positionName",ColumnType.Enum,true).setGroup(2)
                .setEnum(["总经理","常务副总经理","副总经理","主任","副主任","项目组长","技术骨干","技术员","内勤","财务"],
                    ["总经理","常务副总经理","副总经理","主任","副主任","项目组长","技术骨干","技术员","内勤","财务"]),
            new Column("岗位级别","positionLevel",ColumnType.PositionLevel,true).setGroup(2),
            new Column("劳动合同","laborContractFlag",ColumnType.Boolean,true).setGroup(2)
                .setBoolTags(["未签订","已签订"]).setDefaultValue(false)
                .setTriggerOtherColumn(false,(formData,cols)=>{
                    cols.filter(col=>{return col.dataIndex==="contractNum"})[0]?.setVisible(false);
                    cols.filter(col=>{return col.dataIndex==="sigContractDate"})[0]?.setVisible(false);
                    cols.filter(col=>{return col.dataIndex==="contractTerm"})[0]?.setVisible(false);
                },(formData,cols)=>{
                    cols.filter(col=>{return col.dataIndex==="contractNum"})[0]?.setVisible(true);
                    cols.filter(col=>{return col.dataIndex==="sigContractDate"})[0]?.setVisible(true);
                    cols.filter(col=>{return col.dataIndex==="contractTerm"})[0]?.setVisible(true);
                }),
            new Column("劳动合同编号","contractNum",ColumnType.String,true).setGroup(2),
            new Column("签订劳动合同时间","sigContractDate",ColumnType.Date,true).setGroup(2),
            new Column("签订劳动合同期限","contractTerm",ColumnType.String,true).setGroup(2),
            new Column("任职资格证书","qualifCertif",ColumnType.String,true).setGroup(2),
            new Column("技能证书","certificate",ColumnType.String,true).setGroup(2),
            new Column("社保缴纳起始日期","insuranceDate",ColumnType.Date,true).setGroup(2),
            new Column("公积金缴纳起始日期","publicFundDate",ColumnType.Date,true).setGroup(2),

            new Column("试用期截止日期","trialExpireDate",ColumnType.Date,true).setGroup(3),
            new Column("工作经历","remarks",ColumnType.String,true).setGroup(3),
            new Column("简历附件","fileUrl",ColumnType.PreViewFile,true).setGroup(3),

            ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}

function getBirth(idCard) {
    let birthday = "";
    if(idCard !== null && idCard !== ""){
        if(idCard.length === 15){
            birthday = "19"+idCard.slice(6,12);
        } else if(idCard.length === 18){
            birthday = idCard.slice(6,14);
        }
        birthday = birthday.replace(/(.{4})(.{2})/,"$1/$2/");
        //通过正则表达式来指定输出格式为:1990-01-01
    }
    return birthday;
}

function getSex(idCard) {
    let sexStr = '';
    if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
        sexStr = '男';
    }
    else {
        sexStr = '女';
    }
    return sexStr;
}

function getAge(birthday) { //1900/01/01
    let date = new Date().toLocaleDateString();
    let year = parseInt(date.split("/")[0]);
    let mouth = parseInt(date.split("/")[1]);
    let day = parseInt(date.split("/")[2]);
    if(!birthday){
        return 0
    }
    let birthYear = parseInt(birthday.split("/")[0]);
    let birthMouth = parseInt(birthday.split("/")[1]);
    let birthDay = parseInt(birthday.split("/")[2]);
    let a = mouth*30 + day;
    let b = birthMouth*30 + birthDay;
    if(a - b >0){
        return year - birthYear;
    }
    else{
        return year - birthYear - 1;
    }
}

function workingYear(startDate) { //1900/01/01
    let date = new Date().toLocaleDateString();
    let year = parseInt(date.split("/")[0]);
    let mouth = parseInt(date.split("/")[1]);
    let day = parseInt(date.split("/")[2]);
    if(!startDate){
        return 0
    }
    let nDate = new Date(startDate).toLocaleDateString();
    let birthYear = parseInt(nDate.split("/")[0]);
    let birthMouth = parseInt(nDate.split("/")[1]);
    let birthDay = parseInt(nDate.split("/")[2]);
    let a = mouth*30 + day;
    let b = birthMouth*30 + birthDay;
    if(a - b >0){
        return year - birthYear;
    }
    else{
        return year - birthYear - 1;
    }
}