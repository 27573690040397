<!--
* @program: tyh-oa 
* @author: ly
* @component:baseProvide 
* @description: 
* @create: 2021-04-08 09:34
-->
<template>
    <div>
        
    </div>
</template>

<script>
    import {computed} from "vue";

    export default {
        name: "baseProvide",
        provide(){
            return{
                dataSource:computed(()=>this.dataSource),//提供table的DataSource
                tableColumns:computed(()=>this.table.getTableColumns()), //提供table的字-段集

                formColumns:computed(()=>this.table.getFormColumns()), //提供Form的字段集
                formData:computed(()=>this.formData),//提供Form的formData

                searchOptions:computed(()=>this.searchOptions),//提供searchBar的查询选项
                authority:computed(()=>this.authority),//提供权限
            }
        },
        data(){
            return{
                dataSource: [],
                searchOptions:[],
                authority:[],
                formData:{},
                table:{},
            }
        }
    }
</script>

<style scoped>

</style>