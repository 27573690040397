/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class otherPay_item extends Table {
    constructor() {
        super();
        this.name = "otherPay_item";
        this.CNName = "其他支出";
        this.columnArray = [
            new Column("支出项目","itemName",ColumnType.String,true).setTableView(150),
            new Column("费用类别名称","categoryName",ColumnType.String,true).setTableView(80),
            new Column("支出金额","payAmount",ColumnType.Number,true).setTableView(80),
            new Column("支出日期","payDate",ColumnType.Date,true).setTableView(80),
            new Column("支出类型","type",ColumnType.Enum,true).setTableView(80)
                .setEnum(["1","2","3","4"],
                    ["卡间相互间转账","取现","存入","对外支出"],
                    ["Pink","MediumPurple","CornflowerBlue","PowderBlue"]),
            new Column("支出银行名称","payBankName",ColumnType.String,true).setTableView(80),
            new Column("支出银行卡号","payBankNum",ColumnType.String,true).setTableView(80),
            new Column("接收银行名称","receiveBankName",ColumnType.String,true).setTableView(80),
            new Column("接收银行卡号","receiveBankNum",ColumnType.String,true).setTableView(80),
        ];

    }
}