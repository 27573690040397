import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: item_month_task_user
 * @create: 2022-04-13 14:25
 **/

export default class item_month_task_user extends Table{
    constructor() {
        super();
        this.name = "item_month_task_user";
        this.CNName = "月度绩效分配";
        this.columnArray = [
            new Column("年月","yearMonth",ColumnType.Month,true).setTableView(80),
            new Column("用户名","userName",ColumnType.String,false).setTableView(100),
            new Column("月度工作占比（参考）","completeRatio",ColumnType.Number,true).setTableView(150)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("预算金额(作业绩效)","completeMoney",ColumnType.Number,true).setTableView(150)
                .setDefaultValue(0),
            new Column("预算金额（负责人绩效）","leaderMoney",ColumnType.Number,true).setTableView(160)
                .setDefaultValue(0),
            // new Column("拟下绩效","confirmMoney",ColumnType.Number,false).setTableView(100)
            //     .setDefaultValue(0),
            new Column("说明","remarks",ColumnType.String,true).setTableView(200),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(100).setPosition("right")];
    }
}