<!--
* @program: tyh-oa 
* @author: ly
* @component:userInfoView 
* @description: 
* @create: 2021-05-25 16:14
-->
<template>
    <a-card class="user-card">
        <a-divider orientation="left">个人信息</a-divider>
        <ArBaseForm :view-model="true" :argument="argument" />
        <a-divider orientation="left">部门</a-divider>
        <div class="center-flex" style="flex-wrap: wrap;" >
            <a-tag style="margin: 5px" v-for="( department,index) in departmentNames" :color="tagColor(department)" :key="index">
                {{department}}
            </a-tag>
        </div>
        <a-divider orientation="left">角色</a-divider>
        <div class="center-flex" style="flex-wrap: wrap;" >
            <a-tag style="margin: 5px" v-for="( role,index) in roleNames" :key="index" :color="tagColor(role)">
                {{role}}
            </a-tag>
        </div>
        <a-divider orientation="left">岗位级别</a-divider>
        <div class="center-flex" style="flex-wrap: wrap;" >
            <a-tag style="margin: 5px" color="blue" >
                {{record.positionName}}
            </a-tag>
            <a-tag style="margin: 5px" color="red">
                {{record.positionLevel}}
            </a-tag>
        </div>
    </a-card>
</template>

<script>
    /**
     * position: "技术岗"
     positionLevel: "TQ-4"
     */
    import userInfoEdit from "./userInfoEdit";
    import {getTagColor} from "../../../assets/system/tagColors";
    import user_info from "../../../assets/tables/sys_user_info";
    import userExtra from "../../../assets/api/userExtra";
    export default {
        name: "userInfoView",
        mixins:[userInfoEdit],
        data(){
            return{
                viewModel:true,
                departmentNames:[],
                roleNames:[],
                record:{
                    positionLevel:null,
                    positionName:null
                },
            }
        },
        methods:{
            tagColor(name){
                return getTagColor(name);
            },
        },
        created() {
            let departmentDTOList = this.argument.formData.departmentDTOList;
            let roleDTOList = this.argument.formData.roleDTOList;
            this.departmentNames = [];
            for(let i in departmentDTOList){
                this.departmentNames.push(departmentDTOList[i].fullName);
            }
            this.roleNames = [];
            for(let j in roleDTOList){
                this.roleNames.push(roleDTOList[j].name);
            }
            this.argument.formColumns = new user_info().getFormColumns();
            let data={
                userId: this.argument.formData.id
            };
            userExtra.queryAll.requestPOST(this,data,res=>{
                if(res.data.flag){
                    this.record = res.data.data[0];
                }
                else{
                    this.record = {};
                }
            });

        }
    }
</script>

<style scoped>

</style>