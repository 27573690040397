<!--
* @program: office_automation 
* @author: ly
* @component:SoftwareShower 
* @description: 软件展示控件
* @create: 2022-05-26 14:25
-->
<template>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
        <template #title>
            <div style="display: flex;">
                <span >{{record.categoryName}}</span>
                <div style="margin-left: 20px">
                    <a-tooltip  title="添加软件">
                        <a-button class="search-item" type="dashed"  @click="addSoftware">
                            <template #icon><PlusOutlined /></template>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip :key="'#fff'" title="刷新">
                        <a-button class="search-item" type="dashed"  @click="refresh">
                            <template #icon><SyncOutlined /></template>
                        </a-button>
                    </a-tooltip>
                </div>
            </div>
        </template>
    </a-page-header>
    <TcScrollTable :page-size="15" :scrolls="{y:600}" :data-source="dataSource" :loading="loading"
                   :table-columns="tableColumns" :view-model="true" >
        <template #action="{index,record}">
            <div style="text-align: center">
                <a-tooltip  title="下载">
                    <a-button  class="action-button" type="link" @click="download(record)">
                        <DownloadOutlined class="pointer"/>
                    </a-button>
                </a-tooltip>
                <a-button  class="action-button" type="link" @click="editDetail(record)">
                    <EditFilled class="pointer"/>
                </a-button>
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </TcScrollTable>
    <SoftwareAdd ref="SoftwareAdd" @refresh="refresh" />
</template>

<script>
    import TcScrollTable from "../../../components/table/TcScrollTable";
    import softwareTools from "../../../assets/api/softwareTools";
    import software from "../../../assets/tables/software";
    import {DeleteOutlined,EditFilled,DownloadOutlined,PlusOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import SoftwareAdd from "./SoftwareAdd";
    import {GetCurrentFileUrl} from "../../../assets/utils/general";
    export default {
        name: "SoftwareShower",
        props:["record"],
        watch:{
            record(){
                this.init()
            }
        },
        components:{
            TcScrollTable,DeleteOutlined,EditFilled,DownloadOutlined,PlusOutlined,SyncOutlined,
            SoftwareAdd
        },
        data() {
            return {
                tableColumns:[],
                dataSource:[],
                loading:false,
                visible:false
            }
        },
        methods: {
            init() {
                let data = {
                    categoryId:this.record.id
                };
                this.loading = true;
                softwareTools.queryAll.requestPOST(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else {
                        this.dataSource = [];
                    }
                })
            },
            refresh(){
                this.init()
            },
            editDetail(record){
                this.$refs.SoftwareAdd.open(record,false);
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                softwareTools.delete.requestPOSTUrlParam(this,data,res=>{
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index, 1);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            addSoftware(){
                this.$refs.SoftwareAdd.add(this.record,false);
            },
            async download(record){
                let url = GetCurrentFileUrl(record.fileUrl);
                let fileName = record.fileUrl.split("/").pop();
                let response = await fetch(url); // 内容转变成blob地址
                let blob = await response.blob();  // 创建隐藏的可下载链接
                let objectUrl = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                a.click();
                a.remove();
            },
        },
        created() {
            this.tableColumns = new software().getTableColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>