<!--
* @program: office_automation 
* @author: ly
* @component:invoiceManagerPayoff 
* @description: 发票管理  支出发票
* @create: 2021-12-16 14:27
-->
<template>
    <div class="oa-content">
        <div class="oa-window" >
            <div class="search-bar">
                <SearchBar v-model:value="searchData" :search-options="searchOptions" />
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="addData">
                    <template #icon><PlusOutlined /></template>
                </a-button>
                <a-button class="search-item" type="default" shape="circle" @click="refresh">
                    <template #icon><SyncOutlined /></template>
                </a-button>
            </div>
            <TwoParts ref="TwoParts"  :trans="[60,50]" :enable-change="false" pos="transform">
                <template #left>
                    <ArBaseTable  ref="project_table" :argument="argument" :page-size="pageSize" :view-model="true" >
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button type="link" @click="showDetail(index,record)">
                                    <template #icon><SearchOutlined /></template>
                                </a-button>
                                <a-button type="link" @click="editDetail(index,record)">
                                    <template #icon><EditFilled /></template>
                                </a-button>
                              <a-popconfirm
                                  ok-text="是"
                                  cancel-text="否"
                                  class="action-button"
                                  title="确定删除吗?"
                                  @confirm="deleteData(index,record)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                              </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <invoiceFormPayoff v-if="visible" :formData="formData" :view-model="viewModel" @refresh="refresh"/>
                    <div v-else style="font-size: 40px;margin: 20vh" class="non-select">
                        欢迎使用{{$sysName}}
                    </div>
                    <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
                        版本：{{$version}}
                    </div>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import TwoParts from "../../_components/TwoParts";
    import {DeleteOutlined,EditFilled, PlusOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import invoiceFormPayoff from "./invoiceFormPayoff";
    import item_invoice_record_payoff from "../../../assets/tables/item_invoice_record_payoff";
    import invoiceInto from "../../../assets/api/invoiceInto";

    export default {
        name: "invoiceManagerPayoff",
        components:{
            SearchBar,TwoParts,SearchOutlined,PlusOutlined,SyncOutlined,EditFilled,
            ArBaseTable,invoiceFormPayoff,DeleteOutlined
        },
        data(){
            return{
                table:new item_invoice_record_payoff(),
                searchData:null,
                searchOptions:[],
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                    currentIndex:null //当前选中的条目
                },
                pageSize:15,
                formData:{},
                viewModel:true,
                visible:false,
            }
        },
        methods:{
            init(){
                this.argument.loading = true;
                invoiceInto.queryAll.requestPOST(this,{},res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                      let listData = res.data.data;
                      if(listData != null && listData.length > 0){
                        listData.forEach((item,index)=>{
                          item.itemName = item.itemInfo.name;
                        })
                      }
                      this.argument.dataSource = listData;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            refresh(){
                this.init();
            },
            doSearch(){
                let data = {};
                Object.assign(data,this.searchData);
                this.argument.loading = true;
                invoiceInto.queryAll.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                      let listData = res.data.data;
                      if(listData != null && listData.length > 0){
                        listData.forEach((item,index)=>{
                          item.itemName = item.itemInfo.name;
                        })
                      }
                        this.argument.dataSource = listData;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            showDetail(index,record){
                this.visible = true;
                this.viewModel = true;

                this.formData = record ;
            },
            editDetail(index,record){
              this.formData = {};
              this.visible = true;
                this.viewModel = false;
                this.formData = record ;
                this.formData.itemName = record.itemName;
            },
          deleteData(index,record){
            let data = {id:record.id};
            invoiceInto.delete.requestPOSTUrlParam(this,data,res=>{
              if(res.data.flag){
                this.argument.dataSource.splice(index,1);
                this.$message.success(res.data.msg);
              }
              else{
                this.$message.error(res.data.msg);
              }
            });
          },
            addData(){
                this.visible = false;
                setTimeout(()=>{
                    this.visible = true;
                });
                this.viewModel = false;
                this.argument.currentIndex = null;
                this.formData = this.table.getFormData();
            }
        },
        created() {
            this.searchOptions = this.table.searchOptions;
            this.argument.tableColumns = this.table.getTableColumns();
            this.formData = this.table.getFormData();
            this.init()
        }
    }
</script>

<style scoped>

</style>