<!--
* @program: TYH_office_automation 
* @author: ly
* @component:personalBillAddBills 
* @description: 
* @create: 2021-06-29 10:31
-->
<template>
    <div>
        <a-tooltip :key="'#fff'" title="添加关联账单">
            <a-button v-if="!viewModel" class="search-item"  @click="showRemains">
                <template #icon><PlusOutlined /></template>
            </a-button>
        </a-tooltip>
        <a-tooltip :key="'#fff'" title="删除关联账单">
            <a-button v-if="!viewModel" class="search-item"  @click="RemoveBills">
                <template #icon><MinusOutlined /></template>
            </a-button>
        </a-tooltip>
        <span> &emsp;&emsp;合计：{{summary}} 元</span>
        <childBillTable ref="list_table" :view-model="viewModel" @event="eventHandle"  :argument="argument" />
        <a-modal v-model:visible="visible"
                 destroyOnClose
                 width="700px"
                 title="未关联的私人入账账单">
            <childBillTable ref="modal_table" :argument="argument2" :view-model="viewModel" @event="eventHandle" />
            <template #footer>
                <a-button key="back"  @click="visible=false">取消</a-button>
                <a-button key="submit"  type="primary"  @click="addBills">提交</a-button>
            </template>
        </a-modal>
        <a-modal v-model:visible="formVisible"
                 destroyOnClose
                 width="700px"
                 :footer="null"
                 title="账单详情">
            <ArForm :argument="argumentForm" :view-model="true" />
        </a-modal>
    </div>
</template>

<script>
    import childBillTable from "./childBillTable";
    import {PlusOutlined,MinusOutlined} from "@ant-design/icons-vue"
    import item_finance_record_3 from "../../../assets/tables/item_finance_record_3";
    import api_item_finance_record from  "/src/assets/api/record"
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import ArForm from "../../../components/form/ArForm";
    export default {
        name: "personalBillAddBills",
        props:["viewModel","record"],//!viewModel 标记是否处于编辑状态
        components:{
            childBillTable,PlusOutlined,MinusOutlined,ArForm
        },
        data(){
            return{
                table:new item_finance_record_3(),
                visible:false,
                formVisible:false,
                argument:{//用于显示已经挂接的账单
                    dataSource:[],//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false//table的loading状态
                },
                argument2:{//用于显示还未挂接的账单
                    dataSource:[],//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false//table的loading状态
                },
                argumentForm:{
                    formColumns:[], //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData

                },

            }
        },
        computed:{
            summary(){
                let sum = 0;
                for(let i in this.argument.dataSource){
                    sum += this.argument.dataSource[i].amount*100;
                }
                return sum/100;
            }
        },
        methods:{
            init(){
                this.table = new item_finance_record_3();
                this.table.indexCol =  [new Column("序号","id",ColumnType.Index,false).setTableView(50)];
                this.argument.tableColumns = this.table.getTableColumns();
                this.argument2.tableColumns = this.table.getTableColumns();
                this.argumentForm.formColumns = this.table.getFormColumns();
                //当前账单的关联子帐单
                let data = {id:this.record.id};
                this.argument.loading = true;
                api_item_finance_record.queryOne.requestPOSTUrlParam(this,data, res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data.recordDTOS;
                    }
                    this.argument.loading = false;
                });
                //this.argument.dataSource = this.formData.value.recordDTOS;
            },
            showRemains(){//把还未关联pid的私人入账账单列出来
                //当前用户私人入账，还未向公司报账的账单
                let data2 = {
                    transferUser:this.$store.getters.userInfo.id,
                    reimburState:"",
                };
                this.argument2.loading = true;
                api_item_finance_record.queryAll.requestPOSTUrlParam(this,data2, res=>{
                    if(res.data.flag){
                        this.argument2.dataSource = res.data.data.filter(row=>{
                            return !row.pid;
                        });
                        if(this.argument2.dataSource.length>0){
                            this.visible = true;
                        }
                        else{
                            this.$message.info("没有尚未关联账单的私人入账账单");
                        }

                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.argument2.loading = false;
                });
            },
            addBills(){
                let data = {
                    ids : this.$refs.modal_table.getSelectionKeys(),
                    pid : this.record.id
                };
                api_item_finance_record.batchBindPid.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        /**
                         * 绑定成功之后添加source
                         */
                        this.argument.dataSource = this.argument.dataSource.concat(this.$refs.modal_table.getSelection());
                        this.visible =false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            RemoveBills(){
                let ids = this.$refs.list_table.getSelectionKeys();
                if(ids.length === 0 ){
                    this.$message.error("请选中账单");
                    return
                }
                let data = {ids:ids};
                api_item_finance_record.unBindPid.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        /**
                         * 成功后删除表中的对应行
                         */
                        this.argument.dataSource = this.argument.dataSource.filter(row=>{
                            return ids.indexOf(row.id) === -1;
                        });
                        this.$refs.list_table.setSelectionKeys([]);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            eventHandle({type,arg}){
                switch (type) {
                    case this.$eventTags.showDetail:{
                        this.argumentForm.formData = arg;
                        this.formVisible = true;
                        break;
                    }

                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>
    .ant-modal-body{
        padding: unset !important;
    }
</style>