<!--
* @program: tyh-oa 
* @author: ly
* @description: personalCenter 
* @create: 2021-03-24 15:06
-->
<template>
    <a-layout style="height: 100%" >
        <a-layout-sider :collapsible="true" :collapsedWidth="40">
            <a-menu
                    theme="dark"
                    mode="inline"
                    :inline-collapsed="false"
                    v-model:openKeys="openKeys"
                    v-model:selectedKeys="selectedKeys"
                    @openChange="onOpenChange"
                    @click="handleClick">
                <sub-group-menu v-for="(group,index) in menuLists" :index="index" :groupMenus="group" :key="group.id" />
            </a-menu>
        </a-layout-sider>
        <a-layout-content class="main-window">
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import menuContent from "../../components/menuContent";
    import personalBill from "./personalBill/personalBill";
    import userCenterView from "./userCenter/userCenterView";
    import phoneBook from "./userCenter/phoneBook";
    import personalAssetsAccess from "./personalAssets/personalAssetsAccess";
    import personalAssets from "./personalAssets/personalAssets";
    import personalTask from "./personalTask/personalTask";
    import personalQCTask from "./personalQCTask/personalQCTask";
    import personalScore from "./personalScore/personalScore";
    import PersonalAssessment from "./PersonalAssessment/PersonalAssessment";
    import TaskLeaderTool from "./TaskLeaderTool/TaskLeaderTool";
    import MySoftware from "./MySoftware/MySoftware";
    export default {
        name: "personalCenter",
        mixins:[menuContent],
        components:{
          personalBill,// 我的报账单
          userCenterView,// 个人资料
          phoneBook,// 内部员工通讯录
          personalAssetsAccess,// 设备申请
          personalAssets,// 设备记录
          personalTask,// 我的工作日志
          personalQCTask,// 我的质检任务
          personalScore,// 我的任务评分
          PersonalAssessment,// 考核/职位晋升中心
          TaskLeaderTool,// 任务负责人
          MySoftware,//软件工具
        }
    }
</script>

<style scoped>

</style>