<!--
* @program: office_automation 
* @author: ly
* @component:MonthSelector 
* @description: 月份选择器
* @create: 2022-01-14 14:56
-->
<template>
    <div>
        <span class="statistic-label"> 月份：</span>
        <a-month-picker @change="check" placeholder="请选择月份" style="width: 120px" v-model:value="month" :format="dateFormat" />
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        name: "MonthSelector",
        props:["value"],
        emits:["update:value","change"],
        data(){
            return{
                month:null,
                dateFormat: 'YYYY-MM',
                values:["2020-01","2099-12"] //允许的时间范围
            }
        },
        methods:{
            getValue(){
                if(this.month){
                    return moment(this.month).format(this.dateFormat);
                }
                else{
                    return null;
                }
            },
            check(){
                if(this.month && moment(this.month).isBefore(this.values[0])){
                    this.month = moment(this.values[0]);
                }
                if(this.month && moment(this.month).isAfter(this.values[1])){
                    this.month = moment(this.values[1]);
                }
                this.$emit('update:value', this.getValue());
                this.$emit('change', this.getValue());
            },
            setValue(value){
                this.month = value
            }
        },
        created() {
            if(this.value){
                this.month = moment(this.value);
            }

        }
    }
</script>

<style scoped>

</style>