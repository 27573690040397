/**
 * @program: 前台

 * @author: ly
 *
 * @create: 2020-08-20 15:10
 **/


/**
 * 随机生成UUID
 * @param {int}len   生成的UUID的长度
 * @param {int}radix 生成的UUID的进制
 * @returns {string}
 */
import moment from "moment"
import conf from "./conf";

function uuid(len, radix) {
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  let uuid = [], i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random()*16;
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}
/**
 * 返回object中的属性个数
 * @param {Object} obj
 * @returns {int}
 */
function countProperties (obj) {
  let count = 0;
  for (let property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      count++;
    }
  }
  return count;
}


/**
 * 固定object的某个属性值
 * @param obj
 * @param prop
 * @param val
 */
function frozenProperty(obj,prop,val){
  if(typeof val != "undefined" || val !=null){
    delete obj[prop];
    Object.defineProperty(obj,prop,{configurable:false,value:val});
  }
  else{
    let value = obj[prop];
    delete obj[prop];
    Object.defineProperty(obj,prop,{configurable:false,value:value});
  }
}

class EmitData {
  constructor(type,arg) {
    this.type = type;
    this.arg = arg;
  }
}

function checkAuth(auth,type) {
  if(auth && auth.length>0){
    return auth.indexOf(type) !== -1;
  }
  else{
    return false;
  }
}

function GetEmitData(type,arg) {
    return new EmitData(type,arg)
}


/**
 * @return {string}
 */
function GetCurrentFileUrl(url) {
  //let reg = new RegExp("C:/apache-tomcat-8.5.38-2020/webapps/tyhoa_file/","g");
  let reg = new RegExp(conf.fileReplacePath,"g");
  //let reg = new RegExp("D:/third_systems/resources/cqoa/webapps/tyhoa_file/","g");
  if(url){
    //return url.replace(reg,"http://192.168.10.190:2020/tyhoa_file/");
    return url.replace(reg,"../tyhoa_file/");
  }
  else{
    return "";
  }
}

function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "M+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "m+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}

function arrayDeleteItem(array,item) {
  let index = array.indexOf(item);
  array.splice(index,1);
  return array
}

// 手机号校验
function isPhoneNumber(phoneNum) {
  // let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  /*
    * 移动号码包括的号段：134/135/136/137,138,139；
  *                     147/148(物联卡号)；
  *                     150/151/152/157/158/159；
  *                     165（虚拟运营商）；
  *                     1703/1705/1706（虚拟运营商）、178；
  *                     182/183/184/187/188
  *                     198

  * 联通号段包括：130/131
  *               145
  *               155/156
  *               166/167(虚拟运营商)
  *               1704/1707/1708/1709、171
  *               186/186
  *
  * 电信号段包括： 133
  *                153
  *                162(虚拟运营商)
  *                1700/1701/1702(虚拟运营商)
  *                180/181/189
  *                191/199
  * */
  let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
  return reg.test(phoneNum);
}


// 两个时间内的所有yearMonth

function monthBetween(start,end) {
  if(start && end){
    let sM = new moment(start);
    let eM = new moment(end);
    let bMonth = [];
    while (eM >sM || sM.format("M") === eM.format("M")){
      bMonth.push(sM.format("YYYY-MM"));
      sM.add(1,"month");
    }
    return bMonth
  }
  else if(start && !end){
    let sM = new moment(start);
    return [sM.format("YYYY-MM")]
  }
  else{
    return []
  }
}

/**
 * time是否在时间段内
 * @param time 时间
 * @param period  时间段
 * @returns {boolean}
 */
function timeIsBetween(time,period) {
  let mo = moment(time);
  return  mo.isBetween(new moment(period[0]).startOf("month").format("YYYY-MM-DD"),new moment(period[1]).endOf("month").format("YYYY-MM-DD"),null, '[]')
}

/**
 * 是否允许编辑的月份
 * eg:time 如果仔允许的月份前一个月之后，就允许编辑
 * @param time 需要判断的时间
 * @param month 允许的月份
 * @returns {boolean}
 */
function allowMonth(time,month) {
  let mo = moment(time);
  return  mo.isBetween(new moment(month).subtract(1, 'month'), new moment(month), null, "[]")
}

function fixedNumber(number) {
  return  parseFloat(number.toFixed(2))
}
export {uuid,countProperties,EmitData,timeIsBetween,allowMonth,fixedNumber,
  checkAuth,GetEmitData,GetCurrentFileUrl,dateFormat,arrayDeleteItem,isPhoneNumber,monthBetween}
