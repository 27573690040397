<!--
* @program: office_automation 
* @author: ly
* @component:YearlyAssessment
* @description: 年度考核
* @create: 2022-01-14 10:23
-->
<template>
    <div style="height: 100%">
        <div class="search-bar">
            <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
            <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                <template #icon>
                    <SearchOutlined/>
                </template>
            </a-button>
            <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <span style="margin-left: 10px">状态：</span>
            <a-radio-group @change="radioChange" class="search-item" v-model:value="defaultQuery.disableState">
                <a-radio value="1">在职</a-radio>
                <a-radio disabled value="0">离职</a-radio>
            </a-radio-group>
            <a-button  type="primary" :loading="loading" @click="modalShow" >生成年度考核表</a-button>
            <a-button  type="primary" style="margin-left:10px " @click="reportShow" >下载年度考核表</a-button>
        </div>
        <TwoParts ref="TwoParts" :trans="[15,20]" :enable-change="false">
            <template #left>
                <ArBaseTableFilter  :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                    <template #action="{index,record}">
                        <div class = "action-column">
                            <a-button @click="showDetail(index,record)" class="action-button" type="link">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                        </div>
                    </template>
                </ArBaseTableFilter>
            </template>
            <template #right>
                <div v-if="record" >
                    <UserAssessment :record="record" :view-model="false" />
                </div>
                <div v-else>
                    <welcome2 />
                </div>
            </template>
        </TwoParts>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="生成年度考核报告">
        <UserPicker v-model:value="users" />
        <span>年度：</span>
        <a-input-number style="margin: 5px;width: 100px" v-model:value="year" />
        <br />
        <span style="margin: 20px">考核表类型：</span>
        <a-select v-model:value="type" style="width: 250px" >
            <a-select-option v-for="option in options" :key="option.id">{{option.name}}</a-select-option>
        </a-select>
        <a-button style="margin: 10px"  type="primary" @click="saveData">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             title="下载年度考核报告">
        <UserPicker v-model:value="users" />
        <span>年度：</span>
        <a-input-number style="margin: 5px;width: 100px" v-model:value="year" />
        <br />
        <a-popconfirm
                ok-text="是"
                cancel-text="否"
                title="确定下载吗?"
                @confirm="downloadReport">
            <a-button style="margin: 10px" type="primary" >
                下载
            </a-button>
        </a-popconfirm>
    </a-modal>
</template>

<script>
    import userInfoManager from "../../userInfoManager/userInfoManager";
    import UserAssessment from "../MonthlyAssessment/UserAssessment";
    import UserPicker from "../../../../components/UserPicker/UserPicker";
    import moment from "moment";
    import assessmentYear from "../../../../assets/api/assessmentYear";
    import kpi from "../../../../assets/api/kpi";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";

    export default {
        name: "YearlyAssessment",
        mixins:[userInfoManager],
        components:{
            UserAssessment,UserPicker
        },
        computed:{
            userIds(){
                let ids = [];
                for(let i in this.users){
                    ids.push(this.users[i].id)
                }
                return ids;
            }
        },
        data(){
            return{
                users:[],
                loading:false,
                year:"",
                visible:false,
                titles:['选择', '生成考核表'],
                options:[
                    {id:"11",name:"作业员年度考核"},
                    {id:"12",name:"工勤岗年度考核"},
                    {id:"13",name:"技术管理岗年度考核"},
                    {id:"14",name:"工勤管理岗年度考核"},
                ],
                type:"11",
                visible2:false
            }
        },
        methods:{
            showDetail(index,record){
                this.record = record;
            },
            modalShow(){
                this.year = moment(new Date().valueOf()).format("YYYY");
                this.visible = true;
                this.users = [];
            },
            saveData(){
                let data={
                    type:this.type,
                    year:this.year,
                    userIds:this.userIds.toString()
                };
                assessmentYear.generate.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            reportShow(){
                this.year = moment(new Date().valueOf()).format("YYYY");
                this.visible2 = true;
                this.users = [];
            },
            downloadReport(){
                for(let i in this.users){
                    let data = {
                        year:this.year,
                        userId:this.users[i].id
                    };
                    kpi.exportByUserMonth.fileDownload(this,data,"年度考核报告【"+this.year+"-"+this.users[i].name+"】.xls");
                }
            }
        },
        created() {
            this.argument.tableColumns = [
                new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                new Column("姓名","name",ColumnType.String,true).setTableView(100),
                new Column("操作","actions","actions",false).setTableView(60)
            ];
        }
    }
</script>

<style scoped>

</style>