<!--
*@description：根据字段配置值，后台查询返回
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center" >{{presentValue}}</div>
    </div>
    <div v-else>
        <a-select
                v-if="editable"
                v-model:value="record[column.dataIndex]"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @change="columnTrigger"
        >
            <a-select-option v-for="option in selectOptions" :key="option.id">
                {{ option.name }}
            </a-select-option>
        </a-select>
        <div style="text-align: center" v-else >{{presentValue}}</div>
    </div>
</template>

<script>
    import {computed} from "vue";
    import typeBase from "./typeBase";

    export default {
        name: "typeSelect",
        mixins:[typeBase],
        computed:{
            presentValue(){
                return this.record[this.column.idReplaceObject.replaceSegment]?this.record[this.column.idReplaceObject.replaceSegment]:"无"
            },
            //能够选择的选项
            selectOptions(){
                return this.defaultOptions.concat(this.options)
            }
        },
        data(){
            return{
                //默认选项
                defaultOptions:[{
                        id:this.record[this.column.dataIndex],
                        name:this.record[this.column.idReplaceObject.replaceSegment]}],
                //查询到的选项
                options:[],
                queryObject:computed(()=>this.column.idReplaceObject.queryObject)
            }
        },
      created() {
          this.handleSearch()
      },
      methods:{
            handleSearch(){
              let options = {};
              this.queryObject.api.requestPOSTUrlParam(this,options,res=>{
                if(res.data.flag){
                  let data = res.data.data;
                  let option_data = [];
                  if(data.length > 0){
                    for(let i=0;i<data.length;i++){
                      let info = data[i];
                      let parm = {
                        id:info[this.queryObject.keyValue[0]],
                        name:info[this.queryObject.keyValue[1]]
                      };
                      option_data.push(parm);
                    }
                  }
                  this.options = option_data;
                }
                else{
                  this.options =[];
                }
              },this)
            }
        }
    }
</script>

<style scoped>

</style>