<!--
* @program: office_automation 
* @author: ly
* @component:SealManager 
* @description: 用章管理
用章审核流程设定   印章使用类型设定    用章设定
* @create: 2022-06-07 14:25
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div v-if="visible" style="display: flex;flex-wrap: wrap;max-height: 100%;overflow: auto;">
                <div class="flat-component" v-for="item in showComps" :key="item.comp" @click="clickItem(item)" :style="item.style" >
                    {{item.title}}
                </div>
            </div>
            <div style="right: 100%" v-else>
                <a-page-header style="padding: 0; border: 1px solid rgb(235, 237, 240)">
                    <template #title>
                        <div style="display: flex;">
                            <div style="margin: 20px">
                                <a-button style="margin-right: 20px" danger @click = "visible = true" >返回</a-button>
                                <span >{{currentComp.title}}</span>
                            </div>
                        </div>
                    </template>
                </a-page-header>
                <div v-is="currentComp.comp" />
            </div>
        </div>
    </div>
</template>

<script>
    import SealAuditSetting from "./SealAuditSetting";
    import SealSetting from "./SealSetting";
    import UseSealSetting from "./UseSealSetting";
    import SealAudit from "./SealAudit";
    export default {
        name: "SealManager",
        components:{
            SealAuditSetting,SealSetting,UseSealSetting,SealAudit
        },
        computed:{
            showComps(){
                return this.components.filter(item=>item.enable)
            }
        },
        data(){
            return{
                components:[
                    {title:"用章审核",comp:"SealAudit",enable:true,
                        style:"width:300px;background-image: linear-gradient(#f00, #fff);"},
                    {title:"用章审核流程设定",comp:"SealAuditSetting",enable:true,
                        style:"width:300px;background-image: linear-gradient(#1890ff, #fff);"},
                    {title:"印章使用类型设定",comp:"UseSealSetting",enable:true,
                        style:"width:300px;background-image: linear-gradient(#1890ff, #fff);"},
                    {title:"印章设定",comp:"SealSetting",enable:true,
                        style:"width:300px;background-image: linear-gradient(#1890ff, #fff);"},
                ],
                currentComp:null,
                visible:true
            }
        },
        methods:{
            clickItem(item){
                this.currentComp = item;
                this.visible = false;
            }
        }
    }
</script>

<style scoped>

</style>