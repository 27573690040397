
import { createApp } from 'vue';

import {message } from 'ant-design-vue';
import andt from 'ant-design-vue';
import App from "./App";
import 'ant-design-vue/dist/antd.css';
import "./assets/main.css"
import {createRouter,createWebHashHistory,createMemoryHistory} from "vue-router"
import loginPage from "./view/loginPage";
import mainPage from "./view/mainPage";
import resetDefaultPassword from "./view/resetDefaultPassword";
import ForgetPassword from "./view/ForgetPassword";
import columnType from "./assets/tables/parts/column_type"
import {GetEmitData} from "./assets/utils/general";
import { createStore } from 'vuex'
import moment from "moment";
import eventTags from "./assets/system/eventTags"
import config from '../package.json'
import test from "./test/test";
import TwoParts from "./view/_components/TwoParts";
// 默认语言为 en-US，所以如果需要使用其他语言，推荐在入口文件全局设置 locale
import 'moment/dist/locale/zh-cn';
import conf from "./assets/utils/conf";

moment.locale('zh-cn');
moment.suppressDeprecationWarnings = true;
const app = createApp(App);
app.config.productionTip = false;
app.config.globalProperties.$message = message;
app.config.globalProperties.$dataType = columnType;
app.config.globalProperties.$GetEmitData = GetEmitData;
app.config.globalProperties.$version = config.version;
app.config.globalProperties.$eventTags = eventTags;
app.config.globalProperties.$sysName = conf.systemName;

const routes = [
    {
        path: '/', component: loginPage,
        meta:{
            title:"贵州中色蓝图管理系统"
        }
    },
    {
        path: '/main', component: mainPage,
        meta:{
            title:"贵州中色蓝图管理系统"
        }
     },
    { path: '/resetPassword', component: resetDefaultPassword },
    { path: '/test', component: test },
    { path: '/TwoParts', component: TwoParts },
    { path: '/ForgetPassword', component: ForgetPassword },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
});

const store = createStore({
    state () {
        return {
            userInfo: null
        }
    },
    mutations: {
        initUserInfo(state,userInfo){//存储登录角色信息
            state.userInfo = userInfo;
        }
    },
    getters: {
        userInfo: state => {
            return state.userInfo;
        },
    }
});

app.use(router);
app.use(andt);
app.use(store);
app.mount("#app");