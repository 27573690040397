<!--
* @program: office_automation 
* @author: ly
* @component:projectInvoicePayoff 
* @description: 支出发票管理
* @create: 2022-02-23 15:40
-->
<template>
    <div class="center-flex">
        <a-button v-if="!viewModel" class="search-item" type="primary" @click="addData">
            <template #icon><PlusOutlined /></template>添加发票
        </a-button>
        <a-button  class="search-item" type="dashed"  @click="refresh">
            <template #icon><SyncOutlined /></template>
        </a-button>
    </div>
    <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="12" >
        <template #action="{index,record}">
            <div class="action-column">
                <a-button  type="link"  @click="showDetail(index,record)">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button v-if="!viewModel"  type="link"  @click="editDetail(index,record)">
                    <template #icon><EditOutlined /></template>
                </a-button>
                <a-popconfirm
                        v-if="!viewModel && deleteCheck(record)"
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </ArBaseTableFilter>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="发票信息"
             :maskClosable="vModel"
             :footer="null">
        <ArForm ref="ArForm" :view-model="vModel" :argument="formArg" />
        <a-button v-if="!vModel" class="search-item" type="primary"  @click="saveData">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import ArForm from "../../../components/form/ArForm";
    import item_invoice_record_payoff_new from "../../../assets/tables/item_invoice_record_payoff_new";
    import invoiceInto from "../../../assets/api/invoiceInto";

    export default {
        name: "projectInvoicePayoff",
        props:["projectId","viewModel","contractInfo"],
        components:{
            PlusOutlined, ArBaseTableFilter,DeleteOutlined,EditOutlined,
            ArForm,SyncOutlined,SearchOutlined
        },
        data(){
            return{
                table:null,
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()

                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                vModel:true,
            }
        },
        methods:{
            init(){
                this.table = new item_invoice_record_payoff_new();
                this.argument.tableColumns = this.table.getTableColumns();
                this.formArg.formColumns = this.table.getFormColumns();
                let data = {itemId:this.projectId};
                this.argument.loading = true;
                invoiceInto.queryAll.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                })

            },
            refresh(){
                this.init()
            },
            addData(){
                this.vModel = false;
                this.visible = true;
                this.formArg.formData = this.table.getFormData();
                this.formArg.formData.itemId = this.projectId;
                if(this.contractInfo){
                    this.formArg.formData.partyId = this.contractInfo.sigCompany;
                }
                this.formArg.formColumns = this.table.getFormColumns();
            },
            showDetail(index,record){
                this.visible = true;
                this.vModel = true;
                this.formArg.formData = record;
                this.formArg.formColumns = this.table.getFormColumns();
            },
            editDetail(index,record){
                this.visible = true;
                this.vModel = false;
                this.formArg.formData = record;
                this.formArg.formColumns = this.table.getFormColumns();
            },
            deleteData(index,record){
                let data = {id:record.id};
                invoiceInto.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(){
                let record = this.formArg.formData;
                invoiceInto.saveOrUpdate.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });

            },
            deleteCheck(record){
                let time = parseInt(localStorage.getItem("time"));
                return new Date(record.createTime).toLocaleDateString() === new Date(time).toLocaleDateString();
            },
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>