<!--
* @program: tyh-oa 
* @author: ly
* @component:billTabs 
* @description: 
* @create: 2021-04-14 10:44
-->
<template>
    <div  class="tab-container" >
        <a-tabs v-if="local_visible" v-model:activeKey="activeKey" :size="size">
            <a-tab-pane  v-for="(pane,index) in paneList" :key="index.toString()" :tab="pane.tag" >
                <div style="height: 100%;overflow-y: auto" v-is="pane.component"  @event="eventHandle" :viewModel="true" :record="pane.record" />
            </a-tab-pane>
        </a-tabs>
        <div v-else style="font-size: 40px;margin: 20vh" class="non-select">
            欢迎使用{{$sysName}}
        </div>
        <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
            版本：{{$version}}
        </div>
    </div>
</template>

<script>
    import baseTabs from "../../../components/tabs/baseTabs";
    import personalBillViewer from "../../personalCenter/personalBill/personalBillViewer";
    export default {
        name: "billTabs",
        mixins:[baseTabs],
        components:{
            personalBillViewer
        },
    }
</script>

<style scoped>

</style>