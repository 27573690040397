import Column from "./column";
import ColumnType from "./column_type";

/**
 * @program: 前台
 * @author: ly
 * @decription:
 * @create: 2020-08-27 10:23
 **/

export default class Table{
    /**
     * @param name 表名
     * @param CNName 表中文名
     * @param columnArray 缩略版本的表字段
     */
    constructor(name,CNName) {
        this.name = name;
        this.CNName = CNName;
        this.columnArray = [];
        this.assFileArr = [];
        this.searchSegments = [];
        this._columnArray =[];
        this.actions = [];
        this.indexCol =  [
            new Column("序号","_index",ColumnType.Index).setTableView(60).setPosition("left")
        ];
    }
    getForTable(){
        return this.columnArray
    }
    set columnArray(columnArray){
        let keys = [];
        for(let i in columnArray){
            keys.push(columnArray[i].dataIndex);
        }
        if((new Set(keys)).size !== keys.length){ //确保keys不重复，keys有重复的时候失败
            this._columnArray = [
                new Column("有重复的序号","dataIndex",ColumnType.String,false),
            ];
        }
        else{
            this._columnArray = columnArray;
        }
    }
    get columnArray(){
        return this.indexCol.concat(this._columnArray.concat(this.actions));//带上 table的actions一起返回
    }

    setActions(actions){
        this.actions =actions;
        return this;
    }
    getFormData(){
        let data={};
        for(let i in this._columnArray){
            let seg = this._columnArray[i].dataIndex;
            data[seg] = this._columnArray[i].defaultValue;
        }
        return data;
    }
    getFormColumns(){
        return this._columnArray;
    }
    getTableColumns(){
        return this.columnArray;
    }
    setActionsColWidth(width){
        this.actions[0].setTableView(width);
    }
}

