<!--
* @program: office_automation 
* @author: ly
* @component:OtherOutflow 
* @description: 其他支出（除项目外协支出之外）
* @create: 2022-02-16 16:55
-->
<template>
    <div class="center-flex">
        <span class="statistic-label"> 合计：{{summary}} 元</span>
        <MonthMoneySum :start-date="record.balanceDate" :records="argument.dataSource" date-seg="payDate" sum-seg="payAmount" />
    </div>
    <div style="height:600px">
    <ArScrollTable :scrolls="{y:600}" :argument="argument" :view-model="true" :page-size="pageSize">
        <template #action="{record}">
            <div class="action-column">
                <a-button class="action-button" type="link" @click="showDetail(record)">
                    <SearchOutlined class="pointer"/>
                </a-button>
            </div>
        </template>
    </ArScrollTable>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <ArBaseForm :argument="formArg" :view-model="viewModel" >

        </ArBaseForm>
    </a-modal>
</template>

<script>
    import ArScrollTable from "../../../components/table/ArScrollTable";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import {SearchOutlined} from "@ant-design/icons-vue";
    import item_other_pay from "../../../assets/tables/item_other_pay";
    import otherPay from "../../../assets/api/otherPay";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import MonthMoneySum from "./MonthMoneySum";
    import moment from "moment";
    export default {
        name: "OtherOutflow",
        props:["record","value"],
        components: {
            ArScrollTable,ArBaseForm,SearchOutlined,MonthMoneySum
        },
        computed:{
            summary(){
                let sum=0;
                for(let i in this.argument.dataSource){
                    let date = this.argument.dataSource[i].payDate;
                    if(moment(date).isBefore(moment(this.record.balanceDate))){
                        continue
                    }
                    sum += this.argument.dataSource[i].payAmount*100;
                }
                return sum/100;
            }
        },
        watch:{
            record(){
                this.init()
            }
        },
        data(){
            return{
                table: new item_other_pay(),
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
            }
        },
        methods:{
            init(){
                this.visible = false;
                this.argument.loading = true;
                this.argument.dataSource = [];
                let data = {
                    "approvalState": "1",
                    "payBankId": this.record.id,
                };
                otherPay.queryAll.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                        this.$emit("update:value",{value:this.summary,loading:false});
                    }
                    else{
                        this.$emit("update:value",{value:0,loading:false});
                    }
                })
            },
            refresh(){
                this.init()
            },
            showDetail(record){
                this.formArg.formData = record;
                this.viewModel = true;
                this.visible = true;
            },
        },
        created() {
            this.argument.tableColumns = [
                new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                new Column("支付类型","type",ColumnType.Enum).setTableView(150)
                    .setEnum(["1","2","3","4"],["银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"],["cyan","blue","green","red"]),
                new Column("费用类别","categoryId",ColumnType.Category,true),
                new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                new Column("支付日期","payDate",ColumnType.Date,true).setTableView(100),
                new Column("接收账户","receiveBankId",ColumnType.Selector,true).setTableView(150)
                    .setSelectorObject("receiveBankNum",[]),
              new Column("账户名称","receiveBankName",ColumnType.String2,false).setTableView(100),
                new Column("操作","actions","actions",false).setTableView(80)
            ];
            this.formArg.formColumns = this.table.getFormColumns();
            this.init();
        }
    }
</script>

<style scoped>

</style>