
/**
 * @program: tyh-oa
 * @author: ly
 * @description: sys_user_info_2  用于查询部门人员时候显示
 * @create: 2021-04-21 18:05
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

export default class user_info extends Table{
    constructor() {
        super();
        this.name = "sys_user_info_2";
        this.CNName = "用户信息表";
        this.columnArray = [
            new Column("姓名","userName",ColumnType.String).setTableView(100),
            new Column("在职情况","disableState",ColumnType.Enum).setTableView(100)
                .setEnum(["0","1"],["离职","在职"],["red","green"]).setTableView(100),
            new Column("状态","loginState",ColumnType.Enum,false)
                .setEnum(["0","1","2"],["正式员工","试用期","实习生"],["cyan","green","pink"]).setTableView(80),

        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}