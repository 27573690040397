/**
 * @program: tyh-oa
 * @author: ly
 * @description: menu 用于定义顶层菜单及其权限
 * @create: 2021-03-22 14:02
 **/
import {uuid} from "../utils/general";

export class BaseMenu{
    /**
     *
     * @param title 菜单名称
     * @param component 菜单对应的component的name
     * @param id 菜单id
     */
    constructor(title,component,id) {
        this.id = id?id:uuid(8,16);
        this.title = title; //标题
        this.enable = true;//是否出现
        this.authority = [];//权限
        this.children =[];
        this.component=component;
    }
    setChildren(children){
        this.children = children;
        return this;
    }
    addChild(child){
        if(this.children instanceof Array){
            if(this.hasOwnChild(child)){//如果已经有该child则跳过
                return
            }
            this.children.push(child);
        }
        else{
            this.children = [child];
        }
        return this;
    }
    setTitle(title){
        this.title = title;
        return this;
    }
    setEnable(bool){
        this.enable = bool;
        return this;
    }
    setBelong(seg){
        this.belong = seg;
        return this;
    }
    addAuthority(auth){
        if(this.authority){
            this.authority.push(auth);
        }
        else{
            this.authority=[auth];
        }
        return this;
    }
    setAuth(auth){
        this.authority=auth;
        return this;
    }
    enableCurrentMenu(id,callback){
        if(this.id ===id){
            callback(this);
        }
        else{
            if(this.children && this.children.length>0){
                for(let i in this.children){
                    this.children[i].enableCurrentMenu(id,callback);
                }
            }
        }
    }
    setCallback(callback){
        this.callback = callback;
        return this;
    }
    hasOwnChild(chilb){
        let children = this.children.filter(child=>child.title===chilb.title);
        return children.length>0;
    }
}


export let menu =new BaseMenu().setChildren([
    new BaseMenu("个人中心","personalCenter").setChildren(
        [
            new BaseMenu("我的账单","").setChildren(
                [
                    new BaseMenu("账单一览","personalBill"),
                ]
            ),
            new BaseMenu("我的任务","").setChildren(
                [
                    /**
                     * 功能：项目工序一览（查）
                     */
                    new BaseMenu("项目工序"),

                    /**
                     * 功能：质检任务一览，质检任务提交质检记录
                     */
                    new BaseMenu("质检工序"),
                ]
            ),
            new BaseMenu("我的设备","").setChildren(
                [
                    new BaseMenu("设备一览"),
                    new BaseMenu("设备申请"),
                ]
            ),
            new BaseMenu("个人中心","").setChildren(
                [
                    new BaseMenu("资料修改")
                ]
            )
        ]
    ),
    new BaseMenu("报账管理","billManager").setChildren(
        [
            new BaseMenu("报账审核").setChildren(
                [
                    new BaseMenu("待审核","billViewer"),
                    new BaseMenu("待入账","billViewer2"),
                    new BaseMenu("所有账单一览","billViewerAll"),
                ]
            ),
            new BaseMenu("报账相关设定").setChildren(
                [
                    new BaseMenu("基础设定","billSetting")
                ]
            )
        ]
    ),
    new BaseMenu("项目生产管理","projectManager").setChildren(
        [
            new BaseMenu("项目管理").setChildren(
                [
                    /**
                     * 功能：项目一览（增删改查），项目分割成部门项目，
                     * 部门项目一览（增删改），部门项目指定负责部门，
                     * 项目合同一览（增删改）
                     * 项目收款一览（增删改查），外协支付一览（增删改）
                     * 项目消耗一览（增删改查），
                     */
                    new BaseMenu("项目一览","projectViewer"),
                    /**
                     * 功能：项目一览（单个项目统计，整体项目统计）
                     */
                    new BaseMenu("项目统计",""),
                ]
            ),
            new BaseMenu("部门任务管理").setChildren(
                [
                    /**
                     * 功能：部门项目一览（查），
                     * 指定部门项目负责人，部门项目分割成工序，
                     * 工序一览（增删改查），工序指定负责人，
                     * 质检结果分配
                     */
                    new BaseMenu("部门项目一览"),
                    /**
                     * 功能：新项目申请
                     */
                    new BaseMenu("新项目申请"),
                ]
            ),
            new BaseMenu("质检").setChildren(
                [
                    new BaseMenu("项目质检"),
                ]
            ),

        ]
    ),
    new BaseMenu("项目质检管理","qualityManager").setChildren(
        [
            new BaseMenu("项目质检").setChildren(
                [
                    new BaseMenu("待审核"),
                    new BaseMenu("未完成"),
                    new BaseMenu("已完成"),
                    /**
                     * 功能：质检申请一览（改查），质检申请通过/驳回，
                     * 质检组管理（增删改查），质检工序分割并指定工序负责人员，指定工序占比及工作量
                     * 质检结果评定,
                     * 功能相近，查询分类内置，及相关功能开启/隐藏
                     */
                    new BaseMenu("质检申请总览"),
                ]
            ),
        ]
    ),
    new BaseMenu("资产管理","assetsManager").setChildren(
        [
            new BaseMenu("设备管理").setChildren(
                [
                    new BaseMenu("设备申请审核/增、删、改、查"),
                    new BaseMenu("设备一览/增、删、改、查"),
                    new BaseMenu("类别管理/增、删、改、查"),
                ]
            ),
        ]
    ),
    new BaseMenu("人事管理","talentManager").setChildren(
        [
            new BaseMenu("内部人员管理").setChildren(
                [
                    new BaseMenu("部门/用户管理","userViewer"),
                    new BaseMenu("角色管理","roleViewer"),
                    new BaseMenu("菜单设定","menuViewer"),
                ]
            ),
            new BaseMenu("外部人员管理").setChildren(
                [
                    new BaseMenu("甲方管理"),
                    new BaseMenu("外协单位管理"),
                    new BaseMenu("外协人员管理"),
                ]
            )
        ]
    ),
]);

/**
 * 解析出MenuObjects
 * @param menus  接收解析JSON完成后的容器，类型必须是BaseMenu
 * @param menuJSON  数据库传来的查询结果。属性有{treeName,component,treeId,children}
 */
export function menuGenerate(menus,menuJSON){ //递归函数
    for(let i in menuJSON){
        let {treeName,component,treeId} = menuJSON[i];
        let child = new BaseMenu(treeName,component,treeId);
        menus.addChild(child);
        menuGenerate(child,menuJSON[i].children);
    }
}

/**
 *
 * @param menus
 * @param key
 * @param arr
 */
export function menuHasOwnKey(menus,key,arr) {
    if(menus && menus.length >0){
        for(let i in menus){
            let {id,children} = menus[i];
            if(id === key){
                arr.push(id === key);
            }
            menuHasOwnKey(children,key,arr);
        }
    }
}
