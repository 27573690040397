<!--
* @program: office_automation 
* @author: ly
* @component:workerProcessShower
* @description: 部门成员情况一览
* @create: 2022-01-10 16:09
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <div style="margin: 10px;color: black">部门:</div>
                <a-select v-model:value="defaultQuery.departmentId"
                          style="width: 200px"
                          class="search-item"
                          @change="selectDepartment"
                >
                    <a-select-option v-for="department in departments" :key="department.id">
                        {{ department.name }}
                    </a-select-option>
                </a-select>
                <span style="margin-left:10px ">在职状态：</span>
                <a-radio-group  @change="radioChange" class="search-item" v-model:value="disableState">
                    <a-radio value="1">在职</a-radio>
                    <a-radio value="0">离职</a-radio>
                </a-radio-group>
                <a-button class="search-item" type="primary" @click="visible =!visible" >
                    部门绩效一览
                </a-button>
                <a-button class="search-item" type="primary" @click="visible2 = true" >
                    部门考核一览
                </a-button>
            </div>
            <TwoParts v-if="visible" ref="TwoParts"  pos="transform" :enable-change="false" >
                <template #left>
                    <ArBaseTable :view-model="true" :page-size="pageSize" :argument="argument" >
                        <template #action="{record}">
                            <div class="action-column">
                                <a-tooltip title="工作安排">
                                    <a-button  class="action-button" type="link" @click="workArrange(record)">
                                        <SearchOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip title="工作日志记录">
                                    <a-button  class="action-button" type="link" @click="workDiary(record)">
                                        <CalendarOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip title="绩效分配">
                                    <a-button  class="action-button" type="link" @click="moneyRecord(record)">
                                        <AccountBookOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip title="考核记录">
                                    <a-button  class="action-button" type="link" @click="assessmentRecord(record)">
                                        <ProfileOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <div v-is="compType" :userId="userId" :view-model="viewModel" />
                </template>
            </TwoParts>
            <AllMembersMonthMoney v-else :users="allUsers" />
        </div>
    </div>
    <a-drawer v-model:visible="visible2"
              height="600px"
              placement="bottom"
              :closable="false"
              :title="null"
    >
        <DepartmentAssessmentViewer v-if="department" :department="department" />
    </a-drawer>
</template>

<script>
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import TwoParts from "../../_components/TwoParts";
    import {SearchOutlined,SyncOutlined,AccountBookOutlined,CalendarOutlined,ProfileOutlined} from "@ant-design/icons-vue"
    import api_sys_department_relation from "../../../assets/api/userDepartRelation";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import workCalendar from "./workCalendar";
    import memberWorkCalendar from "./memberWorkCalendar";
    import memberMonthMoney from "./memberMonthMoney";
    import AllMembersMonthMoney from "./AllMembersMonthMoney";
    import UserAssessment from "../../talentManager/staffAssessment/MonthlyAssessment/UserAssessment";
    import DepartmentAssessmentViewer from "./DepartmentAssessmentViewer";

    export default {
        name: "workerProcessShower",
        components:{
            ArBaseTable,TwoParts,SearchOutlined,SyncOutlined,AccountBookOutlined,ProfileOutlined,
            workCalendar,CalendarOutlined,memberWorkCalendar,memberMonthMoney,
            AllMembersMonthMoney,UserAssessment,DepartmentAssessmentViewer
        },
        computed:{
            department(){
                let arr = this.departments.filter(item=>item.id === this.defaultQuery.departmentId);
                if(arr.length>0){
                    return arr[0]
                }
                else{
                    return {}
                }
            }
        },
        data(){
            return{
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(50),
                        new Column("状态","loginState",ColumnType.Enum)
                            .setEnum(["0","1","2"],["正式员工","试用期","实习生"],["cyan","green","pink"]).setTableView(80),
                        new Column("姓名","userName",ColumnType.String).setTableView(80),
                        new Column("操作","actions","actions").setTableView(90),
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                pageSize:15,
                departments:[],
                defaultQuery:{
                    departmentId:null,
                    disableState:"1",
                    haveLevel:"0"
                },
                userId:null,
                disableState:"1",
                allUsers:[],
                compType:"",
                visible:true,
                visible2:false,
                viewModel:true

            }
        },
        methods:{
            init(){
                let data = {};
                Object.assign(data,this.defaultQuery);
                this.argument.loading = true;
                api_sys_department_relation.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        this.allUsers = res.data.data;
                    }
                    else{
                        this.allUsers = [];
                        this.$message.error(res.data.msg);
                    }
                    this.radioChange()
                });
            },
            refresh(){
                this.init()
            },
            selectDepartment(){
                this.init()
            },
            workArrange(record){
                this.userId = record.userId?record.userId:record.id;
                this.compType = "workCalendar";
                this.viewModel = true;
            },
            workDiary(record){
                this.userId = record.userId?record.userId:record.id;
                this.compType = "memberWorkCalendar";
                this.viewModel = true;
            },
            moneyRecord(record){
                this.userId = record.userId?record.userId:record.id;
                this.compType = "memberMonthMoney";
                this.viewModel = true;
            },
            assessmentRecord(record){
                this.userId = record.userId?record.userId:record.id;
                this.compType = "UserAssessment";
                this.viewModel = false;
            },
            radioChange(){
                switch (this.disableState) {
                    case "1":{
                        this.argument.dataSource = this.allUsers.filter(item=>item.disableState === "1");
                        break;
                    }
                    case "0":{
                        this.argument.dataSource = this.allUsers.filter(item=>item.disableState === "0");
                        break;
                    }
                }
            }
        },
        created() {
            this.departments = this.$store.getters.userInfo.departmentDTOList;
            if(this.departments.length>0){
                this.defaultQuery.departmentId = this.departments[0].id;
            }
            this.init()
        }
    }
</script>

<style scoped>

</style>