<!--
* @description：数据成果管理编辑
* @author:rzl
* @date:2022/6/7 15:11
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="55%"
           :destroyOnClose="true"
  >

   <a-form ref="bill_form"
           :form="form"
           style="overflow: auto;height:400px"
           :label-col="labelCol"
           :wrapper-col="wrapperCol"
   >
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name"

                      label="项目名称" >
           <a-select
               show-search
               v-model:value="itemName"
               placeholder="输入关键字查询"
               :default-active-first-option="false"
               :show-arrow="false"
               :filter-option="false"
               :not-found-content="null"
               @search="handleSearch"
               @change="columnTrigger"
           >
             <a-select-option v-for="option in itemData" :key="option.id">
               {{ option.name }}
             </a-select-option>
           </a-select>
         </a-form-item>
       </div>
     </div>

     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name"

                       label="成果名称" >
           <a-input
               v-model:value="name"
               name="name"
           />
         </a-form-item>
       </div>
     </div>
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="treeValue_xzqh" label="行政区划" >
           <a-tree-select
               :value="treeValue_xzqh"
               :tree-data="treeData_xzqh"
               :dropdownStyle="{height:'350px'}"
               @select="selectTree"

           />
         </a-form-item>
       </div>
     </div>
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name" label="成果类型" >
           <a-tree-select
               v-model:value="treeValue_cglx"
               :tree-data="treeData_cglx"
               :dropdownStyle="{height:'350px'}"
               allow-clear
               @change="cglx_html"
           />
         </a-form-item>
       </div>


     </div>

      <!----- 动态表单 start ------->
      <div v-for="(config,index) in confList" :key="index">
        <div class="ant-row" >
          <div class="ant-col-24">
            <a-form-item :ref="config.code" :label='config.name' >
              <div v-if="config.inputType == 'input'">
                <a-input v-model:value="config.textValue" :ref="config.code"/>
              </div>
              <div v-else-if="config.inputType == 'textarea'">
                <a-textarea v-model:value="config.textValue" :name="config.code"/>
              </div>
              <div v-else-if="config.inputType == 'data'">
                <a-date-picker
                    show-time
                    type="date"
                    style="width: 100%"
                    format="YYYY/MM/DD"
                    v-model:value="config.textValue"
                />
              </div>
              <div v-else-if="config.inputType == 'select'">
                <a-select v-model:value="config.textValue" :name="config.code">
                  <a-select-option v-for="(info,index) in config.input_list" :key="index" :value="info" >{{info}}</a-select-option>
                </a-select>
              </div>

              <div v-else-if="config.inputType == 'radio'">
                <a-radio-group v-model:value="config.textValue" :name="config.code">
                  <a-radio v-for="(info,index) in config.input_list" :key="index" :value="info">{{info}}</a-radio>
                </a-radio-group>
              </div>

              <div v-else-if="config.inputType == 'checkbox'">
                <a-checkbox-group v-model:value="config.textValue" :name="config.code">
                  <a-checkbox v-for="(info,index) in config.input_list" :key="index" :value="info" name="type">{{info}}</a-checkbox>
                </a-checkbox-group>
              </div>

            </a-form-item>
          </div>
        </div>
      </div>
      <!----- 动态表单 end ------->
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name" label="样例图片" >
           <div class="clearfix">
             <a-upload
                 :action="imageUrl"
                 list-type="picture-card"
                 v-model:file-list="fileList_img"
                 @change="handleChange"
                 :accept="accept_img"
                 withCredentials
             >
               <div v-if="fileList_img.length < 8">
                 <plus-outlined />
                 <div class="ant-upload-text">
                   上传图片
                 </div>
               </div>
             </a-upload>
             <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
               <img alt="example" style="width: 100%" :src="previewImage" />
             </a-modal>
           </div>
         </a-form-item>
       </div>
     </div>
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name" label="样例视频" >
           <a-upload
               :accept="accept_v"
               :action="video_url"
               @change="handleChange_v"
               withCredentials
               :showUploadList="false"
           >
             <a-button v-if="fileList_v.length < 5">
               <upload-outlined></upload-outlined>
               上传视频
             </a-button>
           </a-upload>
         </a-form-item>

         <div class="ant-row" v-for="(file,index) in fileList_v" :key="index">
           <div class="ant-col-18">
             <a @click="downloadFile(file.url,file.name)" >{{file.name}}</a>
           </div>
           <div class="ant-col-4">
             <a-button  type="link" @click="online_preview(file.url)">
               <template #icon><EyeTwoTone title="在线预览"/></template>
             </a-button>
             <a-popconfirm
                 class="action-button"
                 title="确定删除吗?"
                 okText="确定"
                 cancelText="取消"
                 @confirm="deleteData(index,file,'vi')">
               <a-button type="link">
                 <template #icon><Delete title="删除" style="color: red"/></template>
               </a-button>
             </a-popconfirm>
           </div>
         </div>

       </div>
     </div>
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name" label="附   件" >
           <a-upload
               :action="fj_url"
               @change="handleChange_fj"
               withCredentials
               name="file"
               :showUploadList="false"
           >
             <a-button v-if="fileList_fj.length < 10">
               <upload-outlined></upload-outlined>
               上传附件
             </a-button>
           </a-upload>
         </a-form-item>
         <div class="ant-row" v-for="(file,index) in fileList_fj" :key="index">
            <div class="ant-col-18">
              <a @click="downloadFile(file.url,file.name)" >{{file.name}}</a>
            </div>
           <div class="ant-col-4">
             <a-popconfirm
                 class="action-button"
                 title="确定删除吗?"
                 okText="确定"
                 cancelText="取消"
                 @confirm="deleteData(index,file,'fj')">
               <a-button type="link">
                 <template #icon><Delete title="删除" style="color: red"/></template>
               </a-button>
             </a-popconfirm>
           </div>
         </div>
       </div>
     </div>
   </a-form>
    <template #footer>
      <a-button type="primary" @click="submit">提交</a-button>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>

  <!-- 在线预览 -->
  <online-preview ref="onlinePreview"/>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref } from 'vue';

import area_api from "../config/area_api";
import achieveType_api from "../config/achieveType_api";
import file_api from "../common/config/file_api";
import {GetCurrentFileUrl} from "/src/assets/utils/general"
import achieveRecord_api from "../config/achieveRecord_api";
import {dateFormat} from "../../../../assets/utils/dataUtil"
import Icons from "../../../common/Icons";
import onlinePreview from "../common/point/onlinePreview";

export default {
  name: "dataProductsEdit",
  mixins:[Icons],
  components: {
    PlusOutlined,onlinePreview
  },
  data(){
    return{
      labelCol:{ span: 2 },
      wrapperCol:{ span: 21 },
      visible:false,
      modalTitle:null,
      viewModel:false,
      form:null,
      id:null,
      name:null,
      treeValue_cglx:null,
      clgxId:null,
      treeData_cglx:[],
      treeData_xzqh:[],
      treeValue_xzqh:null,
      xzqh_id:null,

      defaultTitle: '',
      titleId:'',
      provId:null,// 省
      cityId:null,// 市
      countyId:null,//区
      streetId:null,//乡镇
      villageId:null,// 村

      confList:[],// 动态表单内容

      /** 文件处理 ***/
      // 图片
      accept_img:"image/png,image/jpeg",
      fileList_img:[],
      previewVisible:false,
      previewImage:'',
      imageUrl:file_api.upload.url,

      // 视频
      accept_v:"video",
      fileList_v:[],
      video_url:file_api.upload.url,

      // 附件
      fileList_fj:[],
      fj_url:file_api.upload.url,

      // 项目搜索
      itemId:null,
      itemName:null,
      itemData:[],

    }
  },
  created() {
    this.init();
  },
  methods:{
    /** 打开编辑窗口 **/
    open(title,type,info){
      this.visible = true;
      this.modalTitle = title;

      this.confList = [];
      this.name = null;
      this.treeValue_cglx = null;
      this.treeValue_xzqh = null;
      this.xzqh_id = null;
      this.fileList_img = [];
      this.fileList_v = [];
      this.fileList_fj = [];
      this.provId = null;
      this.cityId = null;
      this.countyid = null;
      this.streetId = null;
      this.villageId = null;
      this.itemid = null;
      this.itemName = null;
      this.itemData = [];
      this.id = null;

      if(type == "edit"){
        this.id = info.id;
        this.name = info.name;
        this.treeValue_xzqh = info.areaName;
        this.treeValue_cglx = info.typeName;
        this.clgxId = info.typeId;
        this.provId = info.provId;
        this.cityId = info.cityId;
        this.countyid = info.countyid;
        this.streetId = info.streetId;
        this.villageId = info.villageId;
        this.itemid = info.itemId;
        this.itemName = info.itemName;

        this.fileList_v = [];
        this.fileList_fj = [];
        let imgUrl = info.imgUrl;
        this.fileList_img = imgUrl != null && imgUrl != '' ? imgUrl.split(";") : [];
        let videoUrl = info.videoUrl;
        let videoList = videoUrl != null && videoUrl != '' ? videoUrl.split(";") : [];
        if(videoList.length > 0){
          for (let i=0;i<videoList.length;i++){
            let vi = videoList[i];
            let num = i+1;
            let url = GetCurrentFileUrl(vi);
            let list = {
              name: '视频'+num,
              url:vi,
            }
            this.fileList_v.push(list)
          }
        }


        let fileUrl = info.fileUrl;
        let fileist = fileUrl != null && fileUrl != '' ? fileUrl.split(";") : [];
        if(fileist.length > 0){
          for (let i=0;i<fileist.length;i++){
            let vi = fileist[i];
            let num = i+1;
            let url = GetCurrentFileUrl(vi);
            let list = {
              name: '附件'+num,
              url:vi
            }
            this.fileList_fj.push(list)
          }
        }
        let str_json = info.propJson;
        let confList = str_json == null || str_json == '' ? [] : JSON.parse(str_json);
        let parms = {
          id:info.typeId,
        };
        achieveType_api.queryOne.requestPOSTUrlParam(this,parms,res=>{

          if(res.data.flag){
            let list = res.data.data.confList;
            if(list.length > 0){
              for(let infoc of list){
                let inputType = infoc.inputType;
                infoc.textValue = "";
                for(var key in confList){
                  let code = infoc.code;
                  if(key == code){
                    if(inputType == 'checkbox'){
                      infoc.textValue = confList[key].split(";");
                    }else{
                      infoc.textValue = confList[key];
                    }
                  }
                }

                if(inputType == 'select' || inputType == 'radio' || inputType == 'checkbox'){
                  let valueList = infoc.valueList;
                  let list = valueList.split(";");
                  infoc.input_list = [];
                  if(list.length > 0){
                    for(let lt of list){
                      if(lt != ''){
                        infoc.input_list.push(lt);
                      }
                    }
                  }

                }
              }
            }
            this.visible = true;
            this.confList = list;
          }
        });


      }

    },
    /** 初始化 **/
    init(){
      let parms = {
        dictName:null,
        dictCode:null,
      };
      achieveType_api.getAllTree.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          let level = 0;
          var tempObj = this.convertToTreedatas_cglx(res.data.data,level);
          this.treeData_cglx = tempObj;
        }
        else{
          this.$message.error(res.data.msg)
        }
      })

      area_api.getAllTree.requestPOSTUrlParam(this, {},res=>{
        if(res.data.flag){
          let level = 0;
          var tempObj = this.convertToTreedatas_cglx(res.data.data,level);
          this.treeData_xzqh = tempObj;
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    /**
     * 多级
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedatas_cglx(data,level){
      var returnData = [];
      //遍历数据
      for(var i = 0; i < data.length; i++){
        var tempObj = {
          value:data[i].id,
          key:data[i].id,
          title:data[i].name,
          level:level,
        };

        //判断是否存在子节点，如果存在则递归
        if(data[i].children != null && data[i].children.length > 0){
          tempObj.children = this.convertToTreedatas_cglx(data[i].children,level + 1);
        }
        //push到数据数组中
        returnData.push(tempObj);
      }
      return returnData;
    },
    /**
     * @description 选择节点触发
     */
    selectTree(nodeValue){
      this.provId = null;
      this.cityId = null;
      this.countyId = null;
      this.streetId = null;
      this.villageId = null;
      this.setTitle(nodeValue)
    },
    /**
     * @description 设置当前选中对象
     */
    setTitle(value){
      this.xzqh_id = value;
      this.getSelectedItem(value, this.treeData_xzqh)
      this.$nextTick(()=>{
        this.treeValue_xzqh = this.defaultTitle;
      })
    },
    /**
     * @description 当前选中对象的title(拼接所有父级title)
     */
    getSelectedItem(value, data, title,valueId){
      for(let item of data){
        //在根节点找到对应选项
        if (!title && item.value == value ) {
          this.defaultTitle = item.title;
          this.titleId = item.value;
        }
        //根节点未找到继续递归查找
        else if(!title && item.children){
          let lable = item.title;
          let value_id = item.value;
          this.getSelectedItem(value, item.children, lable,value_id);
        }

        //在子节点找到对应选项
        if (title && item.value == value){
          this.defaultTitle = title + '-' + item.title;
          this.titleId = valueId + '*' + item.value;
        }
        //当前子节点未找到继续递归向下查找
        else if(title && item.children){
          let lable = title + '-' + item.title;
          let value_id = valueId + '*' + item.value;
          this.getSelectedItem(value, item.children, lable,value_id);
        }
      }
    },

    /*** 文件处理 ****/

    /** 查看图片 start **/
    handlePreview(file){
      if (!file.url && !file.preview) {
        file.preview = this.getBase64(file.originFileObj);
      }
      let url = GetCurrentFileUrl(file.response.data.fileUrl);
      this.previewImage = url || file.preview;
      this.previewVisible = true;
    },
    /** 图片转base64 **/
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = error => reject(error);
      });
    },
    handleCancel(){
      this.previewVisible = false;
    },
    /** 图片上传处理 **/
    handleChange({file,fileList,event}){
      //this.fileList = fileList;
      if(file.status === "uploading"){ //status: "uploading"
        //

      }
      else if(file.status==="done"){//status: "done"
        let info = {
          uid:fileList.length + 1,
          name:file.name,
          status: 'done',
          url:file.response.data.fileUrl,
        };
        this.fileList_img.push(info);
      }
      else {
       //
      }
    },
    /** 查看图片 end **/

    /** 视频上传处理 **/
    handleChange_v({ file, fileList }){
      //this.fileList = fileList;
      if(file.status === "uploading"){ //status: "uploading"
        //

      }
      else if(file.status==="done"){//status: "done"
        let info = {
          uid:file.uid,
          name:file.name,
          status: 'done',
          url:file.response.data.fileUrl,
        };
        this.fileList_v.push(info);
      }
      else {
        //
      }
    },
    /** 文件上传处理 **/
    handleChange_fj({ file, fileList }){
      //this.fileList = fileList;
      if(file.status === "uploading"){ //status: "uploading"
        //

      }
      else if(file.status==="done"){//status: "done"
        let info = {
          uid:file.uid,
          name:file.name,
          status: 'done',
          url:file.response.data.fileUrl,
        };
        this.fileList_fj.push(info);
      }
      else {
        //
      }
    },
    /*** 文件处理 end ***/

    /** 成果类型动态表单 **/
    cglx_html(clgx_id){
      this.confList = [];
      this.clgxId = clgx_id;
      let parms = {
        id:this.treeValue_cglx,
      };
      achieveType_api.queryOne.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          let list = res.data.data.confList;
          if(list.length > 0){
            for(let info of list){
              let inputType = info.inputType;
              info.textValue = null;
              if(inputType == 'select' || inputType == 'radio' || inputType == 'checkbox'){
                let valueList = info.valueList;
                let list = valueList.split(";");
                info.input_list = [];
                if(list.length > 0){
                  for(let lt of list){
                    if(lt != ''){
                      info.input_list.push(lt);
                    }
                  }
                }

              }
            }
          }

          this.confList = list;
        }


      });
    },
    /** 提交 **/
    submit(){
      this.area_id();
      let imgUrl = this.deal_url(this.fileList_img);
      let videoUrl = this.deal_url(this.fileList_v);
      let fileUrl = this.deal_url(this.fileList_fj);
      let propJson = this.propJson();
      let parms ={
        itemId:this.itemid,
        id:this.id,
        typeId:this.clgxId,
        provId:this.provId,
        cityId:this.cityId,
        countyId:this.countyId,
        streetId:this.streetId,
        villageId:this.villageId,
        name:this.name,
        imgUrl:imgUrl,
        videoUrl:videoUrl,
        fileUrl:fileUrl,
        propJson:propJson == null ? "" : JSON.stringify(propJson),

      };
      achieveRecord_api.saveOrUpd.requestPOST(this,parms,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
          this.visible = false;
        }
        else{
          this.$message.error(res.data.msg);
        }
      })

    },
    /** 获取选中区划id **/
    area_id(){
      let list = this.titleId.split("*");
      if(list.length > 0){
        for(let i=0;i<list.length;i++){
          if(i == 0){
            this.provId = list[i];
          }else if(i == 1){
            this.cityId = list[i];
          }else if(i == 2){
            this.countyId = list[i];
          }else if(i == 3){
            this.streetId = list[i];
          }else if(i == 4){
            this.villageId = list[i];
          }
        }
      }
    },
    /** 处理文件url **/
    deal_url(objList){
      let url = '';
      if(objList.length > 0){
        for(let obj of objList){
          if((obj.response == undefined || obj.response == null) && (obj.name == undefined || obj.name == null) ){
            if(url == ''){
              url += obj;
            }else{
              url += ';'+obj;
            }
          }else if(obj.name != undefined && obj.name != null){
            if(url == ''){
              url += obj.url;
            }else{
              url += ';'+obj.url;
            }
          }else{
            let info = obj.response.data;
            if(url == ''){
              url += info.fileUrl;
            }else{
              url += ';'+info.fileUrl;
            }
          }
        }
      }
      return url;
    },
    /** 动态表单json **/
    propJson(){
      let parms = null;
      let confList = this.confList;
      if(confList != null && confList.length > 0){
        for(let info of confList){
          let textValue = info.textValue;
          if(textValue != null && textValue != ''){
            if(parms == null){
              parms = {};
            }
            let code = info.code;
            let dataType = info.inputType;
            if(dataType == 'checkbox'){
              let list = info.textValue;
              textValue = '';
              if(list != null && list.length > 0){
                for(let info of list){
                  if(textValue == ''){
                    textValue += info;
                  }else{
                    textValue += ";"+info;
                  }
                }
              }
            }else if(dataType == 'data'){
                textValue = dateFormat(info.textValue,'yyyy-MM-dd')
            }

            parms[code] =textValue;
          }

          /*parms[code] =[
              {
                "code": info.code,
                "dataType": info.dataType,
                "id": info.id,
                "inputType": info.inputType,
                "name": info.name,
                "require": info.require,
                "textValue": textValue,
                "typeId": info.typeId,
              }
            ]*/
        }
      }
      return parms;
    },
    /** 删除文件 **/
    deleteData(index,arg,type){
      switch (type){
        case "vi":{
          this.fileList_v.splice(arg.index,1);
          break;
        }
        case "fj":{
          this.fileList_fj.splice(arg.index,1);
          break;
        }
      }

    },

    /** 下载文件 ***/
    downloadFile(url,name){
      let data = {
        fileUrl:url
      };
      file_api.download.fileDownload(this,data,name);
    },
    /** 在线预览 **/
    online_preview(url){
      this.$refs.onlinePreview.setFileUrl(url);
    },

    /********** 项目 start ************/
    handleSearch(val){
      let parms = {
        name:val
      }
      achieveRecord_api.queryAll_item.requestPOST(this,parms,res=>{
        if(res.data.flag){
          let data = res.data.data;
          let option_data = [];
          if(data.length > 0){
            for(let i=0;i<data.length;i++){
              let info = data[i];
              let parm = {
                id:info.id,
                name:info.name
              };
              option_data.push(parm);
            }
          }
          this.itemData = option_data;
        }
      });
    },
    columnTrigger(val){
      this.itemid = val;
    },
    /********** 项目 end  *************/

  },

}
</script>

<style scoped>

</style>