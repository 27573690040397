<!--
* @program: office_automation 
* @author: ly
* @component:userInfoManager
* @description: 
* @create: 2021-12-22 19:10
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                    <template #icon><SearchOutlined/></template>
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                    <template #icon><PlusOutlined/></template>
                </a-button>
                <a-button class="search-item" type="text" >
                    选择部门
                </a-button>
                <a-select v-model:value="department" style="width: 250px"
                          class="search-item"
                          @change="departmentSelect">
                    <a-select-option v-for="item in departments"  :key="item.id">
                        {{item.fullName}}
                    </a-select-option>
                </a-select>
                <span style="margin-left: 10px">状态：</span>
                <a-radio-group @change="radioChange" class="search-item" v-model:value="defaultQuery.disableState">
                    <a-radio :value="'1'">在职</a-radio>
                    <a-radio :value="'0'">离职</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" :trans="[30,30]" :enable-change="false">
                <template #left>
                    <ArBaseTableFilter  :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                        <template #action="{index,record}">
                            <div class = "action-column">
                                <a-button @click="showDetail(index,record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-button @click="editDetail(index,record)" class="action-button" type="link">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="重置密码为：123456?"
                                        @confirm="resetPassword(record)">
                                    <a-tooltip title="重置密码">
                                        <WarningOutlined style="color: #ff40f0" class="pointer"/>
                                    </a-tooltip>
                                </a-popconfirm>
                                <a-popconfirm
                                        v-if="record.disableState ==='1'"
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="离职后将不能登录本系统，设置为离职状态?"
                                        @confirm="resign(index,record)">
                                    <a-tooltip title="离职">
                                        <CloseCircleOutlined style="color: #ff760a" class="pointer"/>
                                    </a-tooltip>
                                </a-popconfirm>
                                <a-popconfirm
                                        v-if="record.disableState ==='0'"
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="切换离职状态为在职?"
                                        @confirm="resign2(index,record)">
                                    <a-tooltip title="切换离职状态为在职">
                                        <CloseCircleOutlined style="color: #309080" class="pointer"/>
                                    </a-tooltip>
                                </a-popconfirm>
                                <a-popover v-if="record.loginState" title="状态设定" trigger="click">
                                    <template #content>
                                        <a-button v-if="record.loginState ==='1' || record.loginState ==='2'" class="action-button" style="color: blue" type="link" @click="loginStateSet(record,'0')">
                                            正式员工
                                        </a-button>
                                        <a-button v-if="record.loginState ==='0' || record.loginState ==='1'" class="action-button" style="color: purple" type="link" @click="loginStateSet(record,'2')">
                                            实习生
                                        </a-button>
                                        <a-button v-if="record.loginState ==='0' || record.loginState ==='2'" class="action-button" style="color: green" type="link" @click="loginStateSet(record,'1')">
                                            试用期
                                        </a-button>
                                    </template>
                                    <a-tooltip title="状态设定">
                                        <a-button class="action-button" type="link"><VerticalAlignMiddleOutlined /></a-button>
                                    </a-tooltip>
                                </a-popover>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(index,record)">
                                    <a-tooltip title="删除">
                                        <DeleteOutlined  style="color: red" class="pointer"/>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTableFilter>
                </template>
                <template #right>
                    <div v-if="record" style="display: flex;flex-wrap: wrap;">
                        <ExtraInfoShower :view-model="viewModel" :record="record" />
                    </div>
                    <div v-else>
                        <welcome2 />
                    </div>
                </template>
            </TwoParts>
        </div>
    </div>
    <ModalAddNewUser ref="ModalAddNewUser" />
</template>

<script>
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import {SearchOutlined,SyncOutlined,EditFilled,WarningOutlined,CloseCircleOutlined,
        DeleteOutlined,VerticalAlignMiddleOutlined,PlusOutlined} from "@ant-design/icons-vue";
    import userInfo from "../../../assets/api/userInfo";
    import TwoParts from "../../_components/TwoParts";
    import sys_user_info from "../../../assets/tables/sys_user_info";
    import ExtraInfoShower from "./ExtraInfoShower";
    import welcome2 from "../../welcome2";
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    import ModalAddNewUser from "./ModalAddNewUser";
    import api_sys_department_relation from "../../../assets/api/userDepartRelation";
    import api_sys_department from "../../../assets/api/department";
    export default {
        name: "userInfoManager",
        components:{
            ArBaseTableFilter,SearchOutlined,SyncOutlined,SearchBar,EditFilled,
            TwoParts,ExtraInfoShower,welcome2,WarningOutlined,DeleteOutlined,
            CloseCircleOutlined,VerticalAlignMiddleOutlined,PlusOutlined,
            ModalAddNewUser
        },
        data(){
            return{
                table:new sys_user_info(),
                pageSize:15,
                searchOptions:[
                    new SearchSegment("姓名/手机号", "queryText"),
                    new SearchSegment("状态", "loginState")
                    .setEnum(["0","1","2"],["正式员工","试用期","实习生"]),
                ],
                searchData:null,
                defaultQuery:{
                    disableState:"1", //默认只查询在职人员
                },
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                record:null,
                viewModel:true,
                departments:[],
                department:null,
                allUsers:[],
            }
        },
        methods:{
            async init(){
                this.department = null;
                this.argument.loading = true;

                let res = await userInfo.queryAll.requestPOSTUrlAsync(this.defaultQuery);
                this.argument.loading = false;
                if(res.flag){
                    res.data = res.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                    this.argument.dataSource = res.data;
                    this.allUsers = res.data;
                }
                else{
                    this.argument.dataSource = []
                }

            },
            refresh(){
                this.init();
                this.record = null;
                this.defaultQuery.disableState = "1";
            },
            doSearch(){
                let data = {};
                Object.assign(data,this.defaultQuery);
                Object.assign(data,this.searchData);
                this.argument.loading = true;
                userInfo.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        res.data.data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            radioChange(){
                let data = {};
                this.department = null;
                Object.assign(data,this.defaultQuery);
                Object.assign(data,this.searchData);
                this.argument.loading = true;
                userInfo.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        res.data.data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            showDetail(index,record){
                this.record = record;
                this.viewModel = true;
            },
            editDetail(index,record){
                this.record = record;
                this.viewModel = false;
            },
            resetPassword(record){
                let data = {
                    id:record.id
                };
                userInfo.passWordInitial.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                userInfo.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            resign(index,record){
                let data = {
                    id:record.id,
                    state:"0"
                };
                userInfo.stateModify.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            resign2(index,record){
                let data = {
                    id:record.id,
                    state:"1"
                };
                userInfo.stateModify.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            loginStateSet(record,state){
                let data = {
                    id:record.id,
                    loginState:state
                };
                userInfo.leaveOffice.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        record.loginState = state;
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })

            },
            addData(){
                this.$refs.ModalAddNewUser.open();
            },
            departmentSelect(){
                let param  ={
                    departmentId:this.department
                };
                this.defaultQuery.disableState = "1";
                this.argument.loading = true;
                api_sys_department_relation.queryAll.requestPOSTUrlParam(this,param,res=>{
                    this.argument.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        let ids = [];
                        for(let i in res.data.data){
                            let {userId} = res.data.data[i];
                            ids.push(userId);
                        }
                        this.argument.dataSource = this.allUsers.filter(item=>ids.indexOf(item.id) !==-1);
                    }
                    else{
                        this.argument.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                });
            },
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.init();
            api_sys_department.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.departments = res.data.data;
                }
            });
        }
    }
</script>

<style scoped>

</style>