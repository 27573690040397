/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工月度考核表-各维度打分记录
* @description: 员工月度考核表-各维度打分记录（三期新增）
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const userScore = { 
	/** 1 summary:查询-单条(根据主键)	method:post */ 
	queryOne : new APIObject("/api/userScore/queryOne.do"), 
	/** 2 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/userScore/batchSaveOrUpd.do"), 
	/** 3 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/userScore/queryPage.do"), 
	/** 4 summary:查询-不分页	method:post */ 
	queryAll : new APIObject("/api/userScore/queryAll.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/userScore/delete.do"), 
	/** 6 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/userScore/saveOrUpd.do"), 
	/** 7 summary:月度考核分数同步-单人单月	method:post */ 
	scoreSync : new APIObject("/api/userScore/scoreSync.do"), 
};
export default userScore;