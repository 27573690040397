<!--
*@description：汇总详情：
年度筛选、月度筛选、项目筛选
分析板块：已收款合同、未收款合同、收入汇总、发票汇总
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <mon-summary-tool-bar ref="monSummaryTool" @event="eventHandle" />
    <div class="ant-row" style="height: 45%;">
      <div class="ant-col-12 divhover" style="box-shadow: 0px 0px 5px #888888;">
        <a-divider orientation="left">已收款合同汇总：
          <span v-if="argument_ht.dataSource.length > 0">数量（份）:{{num_ht}} <a-divider type="vertical" style="height: 85%;top: 6%; background-color: #7cb305;" />  合同金额汇总：{{money_ht}}</span>
        </a-divider>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument_ht" :scroll="{ y:200}" :view-model="true"  >
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="showDetail(record,'查看收入详情','ht',index)">
                <template #icon><Show title="查看"/></template>
              </a-button>
            </div>
          </template>
        </ArBaseTable>
        <!-- table end -->
      </div>

      <div class="ant-col-11.5   divhover" style="box-shadow: 0px 0px 5px #888888;margin-left: 10px;width: 100%">
        <a-divider orientation="left">未收款合同汇总：
          <span v-if="argument_ht_ws.dataSource.length > 0">数量（份）:{{num_ht_ws}} <a-divider type="vertical" style="height: 85%;top: 6%; background-color: #7cb305;" />  合同金额汇总：{{money_ht_ws}}</span>
        </a-divider>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument_ht_ws" :scroll="{ y:200}" :view-model="true"  >
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="showDetail(record,'查看收入详情','ht_ws',index)">
                <template #icon><Show title="查看"/></template>
              </a-button>
            </div>
          </template>
        </ArBaseTable>
        <!-- table end -->
      </div>

    </div>
    <div class="ant-row" style="height: 48%;margin-top: 5px">
      <div class="ant-col-12 divhover" style="box-shadow: 0px 0px 5px #888888;">
      <a-divider orientation="left">收入汇总：
        <span v-if="argument_sr.dataSource.length > 0">数量（份）:{{num_sr}} <a-divider type="vertical" style="height: 80%;top: 6%; background-color: #7cb305;" />  收入金额汇总：{{money_sr}}</span>
      </a-divider>
      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument_sr"  :view-model="true"  :scroll="{ y:200}">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showDetail(record,'查看收入详情','sr',index)">
              <template #icon><Show title="查看"/></template>
            </a-button>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>


      <div class="ant-col-11.5 divhover" style="box-shadow: 0px 0px 5px #888888;margin-left: 10px;width: 100%">
        <a-divider orientation="left">发票汇总： <span v-if="argument_fp.dataSource.length > 0">数量（份）:{{num_fp}}</span><br>
          <span v-if="argument_fp.dataSource.length > 0">
          应收发票金额：{{money_fpys}}
            <a-divider type="vertical" style="height: 85%;top: 6%; background-color: #7cb305;" />  实收发票金额：{{money_fpss}}
            <a-divider type="vertical" style="height: 85%;top: 6%; background-color: #7cb305;" />  未收发票金额：{{money_fpws}}
          </span>
        </a-divider>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument_fp" :scroll="{ y:200}" :view-model="true" >
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="showDetail(record,'查看收入详情','fp',index)">
                <template #icon><Show title="查看"/></template>
              </a-button>
            </div>
          </template>
        </ArBaseTable>
        <!-- table end -->
      </div>

  </div>


  <div v-if="loadingVisible" class="loading">
    <a-space>
      <a-spin size="large" />
    </a-space>
  </div>

  <a-modal
      width="600px"
      :visible="modalVisible"
      :footer="null" :title="title"
      destroyOnClose
      :maskClosable="true"
      @cancel="modalVisible=false">
    <ArBaseForm :argument="formArg" :view-model="true" :span-col="true" >

    </ArBaseForm>
  </a-modal>
</template>

<script>
import Column from "../../../assets/tables/parts/column";
import ColumnType from "../../../assets/tables/parts/column_type";
import {MinusCircleOutlined} from "@ant-design/icons-vue"
import ArBaseTable from "../../../components/table/ArBaseTable";
import FcForm from "../../../components/form/FcForm";
import Icons from "../../common/Icons";
import ArBaseForm from "../../../components/form/ArBaseForm";

import monSummaryToolBar from "./components/monSummaryToolBar";
import {companyColors, companyIds, companyNames} from "../../../assets/system/companies";
import api_item_contract_info from "../../../assets/api/contractInfo";
import item_contract_info from "../../../assets/tables/item_contract_info";
import item_finance_income from "../../../assets/tables/item_finance_income";
import item_invoice_record from "../../../assets/tables/item_invoice_record";
import {dateFmt, getNowMonth} from "../../../assets/utils/dataUtil"
import income from "../../../assets/api/income";
import invoiceRecord from "../../../assets/api/invoiceRecord";
import contract_info_item from "./configs/contract_info_item";
import finance_income_item from "./configs/finance_income_item";
import invoice_record_item from "./configs/invoice_record_item";
import collectionInfo from "../../../assets/api/collectionInfo";
import contract_ws_item from "./configs/contract_ws_item"
import collection_item from "../../financialManager/financialCount/config/collection_item";

export default {
    name: "projectSummary",
    mixins:[Icons],
    components:{
      monSummaryToolBar,ArBaseTable,MinusCircleOutlined,FcForm,ArBaseForm
    },
    data(){
        return{
          loadingVisible:false,
          modalVisible:false,
          title:null,
          formArg:{
            formColumns:null, //提供Form的字段集
            formData:null,//提供Form的formData
          },
          pageParam:{
            page: 1,
            limit: 5,
          },
          argument_ht:{
            dataSource:[],//提供table的DataSource
            tableColumns:new contract_info_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
            formColumns:new item_contract_info().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
            formData:{},
          },
          argument_ht_ws:{
            dataSource:[],//提供table的DataSource
            tableColumns:new contract_info_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
            formColumns:new contract_ws_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
            formData:{},
          },
          argument_sr:{
            dataSource:[],//提供table的DataSource
            tableColumns:new finance_income_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
            formColumns:new collection_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
            formData:{},
          },
          argument_fp:{
            dataSource:[],//提供table的DataSource
            tableColumns:new invoice_record_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
            formColumns:new item_invoice_record().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
            formData:{},
          },

          yearMonth:null,// 年、月
          itemId:null,// 项目id
          num_ht:0,
          money_ht:0,

          num_ht_ws:0,
          money_ht_ws:0,

          num_sr:0,
          money_sr:0,

          num_fp:0,
          money_fpys:0,
          money_fpss:0,
          money_fpws:0,

        }
    },
    created() {

    },
    methods:{
      /**
      * @Description: 根据年统计
      * @params:
      * @return:
      */
      showInit(year){
        this.yearMonth = year;
        this.$refs.monSummaryTool.setDateFormat("YYYY");
        this.$refs.monSummaryTool.setValue(this.yearMonth);
        this.init();
      },
      /**
      * @Description: 统计当前月
      * @params:
      * @return:
      */
      showNow(){
        this.yearMonth = getNowMonth();
        this.$refs.monSummaryTool.setDateFormat("YYYY-MM");
        this.$refs.monSummaryTool.setValue(this.yearMonth);
        this.init();
      },
      init(){
        this.loadingVisible = true;
        this.init_ht();
        this.init_ht_ws();
        this.init_sr();
        this.init_fp();
      },
      init_ht(){
        this.num_ht = 0;
        this.money_ht = 0;
        this.argument_ht.dataSource = [];
        let parms = {
          yearMonth: this.yearMonth,
          itemId:this.itemId,
          colleState:1
        };
        this.loading = true;
        api_item_contract_info.queryByItem.requestPOSTUrlParam(this,parms,res=>{
          this.loading = false;
          if(res.data.flag){
            let listInfo = res.data.data;
            this.argument_ht.dataSource =  listInfo;
            if(listInfo.length > 0){
              this.num_ht = listInfo.length;
              for(let i in listInfo){
                this.money_ht = this.money_ht + listInfo[i].amount;
              }
              this.money_ht = this.money_ht.toFixed(2);
            }

          }
          else{
            this.argument_ht.dataSource = [];
          }
        })
      },
      init_ht_ws(){
        this.num_ht_ws = 0;
        this.money_ht_ws = 0;
        this.argument_ht_ws.dataSource = [];
        let parms = {
          yearMonth: this.yearMonth,
          itemId:this.itemId,
          colleState:0
        };
        this.loading = true;
        api_item_contract_info.queryByItem.requestPOSTUrlParam(this,parms,res=>{
          this.loading = false;
          if(res.data.flag){
            let listInfo = res.data.data;
            this.argument_ht_ws.dataSource =  listInfo;
            if(listInfo.length > 0){
              this.num_ht_ws = listInfo.length;
              for(let i in listInfo){
                this.money_ht_ws = this.money_ht_ws + listInfo[i].amount;
              }
              this.money_ht_ws = this.money_ht_ws.toFixed(2);
            }

          }
          else{
            this.argument_ht_ws.dataSource = [];
          }
        })
      },
      init_sr(){
        this.num_sr = 0;
        this.money_sr = 0;
        this.argument_sr.dataSource = [];
        let parms = {
          itemId:this.itemId,
          yearMonth: this.yearMonth,
        };
        this.loading = true;
        collectionInfo.queryAll.requestPOSTUrlParam(this,parms,res=>{
          this.loading = false;
          if(res.data.flag){
            let listInfo = res.data.data;
            // 计算出选择年份的数据
            //listInfo = this.itemId == null ? this.group_sr(listInfo) : listInfo;
            this.argument_sr.dataSource = listInfo;
            if(listInfo.length > 0){
              this.num_sr = listInfo.length;
              for(let i in listInfo){
                this.money_sr = this.money_sr + listInfo[i].collectionAmount;
              }
              this.money_sr = this.money_sr.toFixed(2);
            }
          }
          else{
            this.argument_sr.dataSource = [];
          }
        })
      },
      init_fp(){
        this.num_fp = 0;
        this.money_fpys = 0;
        this.money_fpss = 0;
        this.money_fpws = 0;
        this.argument_fp.dataSource = [];
        let parms = {
          //invoiceMonth: this.yearMonth,
          itemId:this.itemId,
        };
        this.loading = true;
        invoiceRecord.queryAll.requestPOST(this,parms,res=>{
          this.loading = false;
          this.loadingVisible = false;
          if(res.data.flag){
            let listInfo = res.data.data;
            listInfo = this.itemId == null ? this.group_fp(listInfo) : listInfo;
            if(listInfo.length > 0){
              this.num_fp = listInfo.length;
              for(let i in listInfo){
                this.money_fpys = this.money_fpys + listInfo[i].invoiceAmount;
                this.money_fpss = this.money_fpss + listInfo[i].collectAmount;

                listInfo[i].itemName = listInfo[i].itemInfo.name;
                listInfo[i].partyName = listInfo[i].partyInfo.name;
                listInfo[i].companyName = listInfo[i].companyInfo.name;
              }

              this.argument_fp.dataSource = listInfo;
              this.money_fpws = this.money_fpys - this.money_fpss;
              this.money_fpys = this.money_fpys.toFixed(2);
              this.money_fpss = this.money_fpss.toFixed(2);
              this.money_fpws = this.money_fpws.toFixed(2);
            }
          }
          else{
            this.argument_fp.dataSource = []
          }
        })
      },
      eventHandle({type,arg}){
        switch (type) {
          case this.$eventTags.search:{
              this.yearMonth = arg.yearMonth;
              this.itemId = arg.itemId;
              this.init_ht();
              this.init_ht_ws();
              this.init_sr();
              this.init_fp();
              break;
          }
          case this.$eventTags.refresh:{
              this.init();
              break;
          }
          case this.$eventTags.back:{
            this.$emit("event",this.$GetEmitData(this.$eventTags.back));
            break;
          }
        }
      },
      getFormColumns(item){
          return [
            new Column("合同名称","name",ColumnType.String),
            new Column("合同编号","number",ColumnType.String),
            new Column("合同金额","amount",ColumnType.Number),
            new Column("签订人","sigUserName",ColumnType.String),
            new Column("甲方","associatedKey",ColumnType.IdReplace)
                .setIdReplaceObject("associatedName",{}),
            new Column("签订公司","sigCompany",ColumnType.String)
                .setEnum(companyIds, companyNames,companyColors),
            new Column("签收时间","signDate",ColumnType.Date),
            new Column("签收人","signUserName",ColumnType.String),


          ]
      },
      getFormColumns_sr(item){
        return [
          new Column("项目名称","itemName",ColumnType.String),
          new Column("收入日期","collectDate",ColumnType.Date),
          new Column("入账账户","bankName",ColumnType.String),
          new Column("收款方式","collectType",ColumnType.Enum)
              .setEnum(["1","2"],["现金","对公打款"]),
          new Column("金额（元）","collectAmount",ColumnType.Number),
        ]
      },
      getFormColumns_fp(item){
        item.itemName = item.itemInfo.name;
        item.partyName = item.partyInfo.name;
        item.companyName = item.companyInfo.name;
        return [
          new Column("项目名称","itemName",ColumnType.String),
          new Column("开票金额", "invoiceAmount", ColumnType.Number),
          new Column("开票日期", "invoiceTime", ColumnType.Date),
          new Column("收款状态", "collectState", ColumnType.Enum, false).setGroup(1)
              .setEnum(["0", "1", "2"], ["未收款", "已收款", "部分收款"],["red","green","pink"]),
          new Column("付款方","partyName",ColumnType.String),
          new Column("收款方","companyName",ColumnType.String),
        ]
      },
      /**
       * 查看详情
       * @param record    数据
       * @param title     标题
       * @param type      类别：ht:合同；sr:收入；fp:发票
       */
      showDetail(record,title,type){
        this.formArg.formColumns = null;
        this.title = title;
        this.vModel = true;

        switch (type){
          case "ht":{
            this.formArg.formColumns = this.argument_ht.formColumns;
            this.formArg.formData = record;
            this.modalVisible = true;
            break;
          }
          case "ht_ws":{
            let money_ss = 0;
            let money_ws = 0;
            this.formArg.formColumns = this.argument_ht_ws.formColumns;
            let parms = {
              itemId:record.itemId,
            };
            this.loading = true;
            collectionInfo.queryAll.requestPOSTUrlParam(this,parms,res=>{
              this.loading = false;
              let dataInfo = record;
              if(res.data.flag){
                let listInfo = res.data.data;
                if(listInfo.length > 0){
                  this.num_ht = listInfo.length;
                  for(let i in listInfo){
                    money_ss += listInfo[i].collectionAmount;
                  }
                }
                money_ws = dataInfo.amount - money_ss;
                dataInfo.money_ss = money_ss.toFixed(2);
                dataInfo.money_ws = money_ws.toFixed(2);

              }
              this.formArg.formData = dataInfo;
              this.modalVisible = true;
            })
            break;
          }
          case "sr":{
            this.formArg.formColumns = this.argument_sr.formColumns;
            this.formArg.formData = record;
            this.modalVisible = true;
            break;
          }
          case "fp":{
            this.formArg.formColumns = this.argument_fp.formColumns;
            this.formArg.formData = record;
            this.modalVisible = true;
            break;
          }
        }


      },
      group_sr(data){
        let fmt = this.$refs.monSummaryTool.getFmt();
        let sr_date = this.$refs.monSummaryTool.getValue();
        let newArr = [];
        data.forEach((item,i)=>{
          let date = dateFmt(item.collectDate,fmt);
          if(date == sr_date){
            newArr.push(item);
          }
        })
        return newArr;
      },

      group_fp(data){
        let fmt = this.$refs.monSummaryTool.getFmt();
        let fp_date = this.$refs.monSummaryTool.getValue();
        let newArr = [];
        data.forEach((item,i)=>{
          let date = dateFmt(item.invoiceTime,fmt);
          if(date == fp_date){
            newArr.push(item);
          }
        })
        return newArr;
      },
    }
}
</script>

<style scoped>
.loading{
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.5);
  display:flex;
  justify-content:center;
  align-items:center;
}
</style>