<!--
* @program: office_automation 
* @author: ly
* @component:UserAssessment 
* @description: 
* @create: 2022-02-25 09:42
-->
<template>
    <div class="search-bar">
        <a-radio-group @change="radioChange" class="search-item" v-model:value="type">
            <a-radio value="0">按月份查看</a-radio>
            <a-radio value="1">查看所有记录</a-radio>
        </a-radio-group>
        <MonthSelector v-if="type === '0'" v-model:value="yearMonth" @change="monthChange"/>
    </div>
    <a-spin :spinning="loading">
        <div style="overflow: hidden;position: relative;height:500px ">
            <ArBaseTable  :key="'userId'" :argument="argument" :view-model="true" :page-size="12" >
                <template #action="{record}">
                    <div class="action-column">
                        <a-button @click="showDetail(record)" class="action-button" type="link">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button @click="downLoadFile(record)" class="action-button" type="link">
                            <DownloadOutlined class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="record.judgeEnd && record.agreeFlag !=='1'"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="发出短信提醒该员工评分已完成?"
                                @confirm="setMail(record)">
                            <a-tooltip title="短信提醒">
                                <a-button class="action-button" type="link">
                                    <MailOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                        </a-popconfirm>
                        <a-button
                                v-if="allowMonth(record.yearMonth,cYearMonth) && !viewModel"
                                @click="editDetail(record)" class="action-button" type="link">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="allowMonth(record.yearMonth,cYearMonth) && !viewModel"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </ArBaseTable>
            <a-drawer
                    placement="bottom"
                    :closable="false"
                    :height="450"
                    :visible="visible"
                    :get-container="false"
                    :wrap-style="{ position: 'absolute' }"
                    @close="visible= false"
            >
                <MonthlyAssessmentViewer :assessment-name="currentRecord.typeName" :view-model="vModel" :assessment-data="AssessmentData" :user-type="userType" />
            </a-drawer>
        </div>
    </a-spin>
</template>

<script>
    import MonthSelector from "../../../../components/SearchBar/MonthSelector";
    import ArBaseTable from "../../../../components/table/ArBaseTable";
    import sys_attendance from "../../../../assets/tables/sys_attendance";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import assessmentUser from "../../../../assets/api/assessmentUser";
    import moment from "moment";
    import {SearchOutlined,DeleteOutlined,EditFilled,DownloadOutlined,MailOutlined} from "@ant-design/icons-vue";
    import MonthlyAssessmentViewer from "./MonthlyAssessmentViewer";
    import {allowMonth} from "../../../../assets/utils/general";
    import kpi from "../../../../assets/api/kpi";
    import smsRecord from "../../../../assets/api/smsRecord";

    export default {
        name: "UserAssessment",
        props:["record","userId","viewModel"],//record userId使用其中之一来确定当前用户id
        watch:{
            record(){
                this.init()
            },
            userId(){
                this.init()
            }
        },
        components:{
            MonthSelector,ArBaseTable,SearchOutlined,DeleteOutlined,EditFilled,
            MonthlyAssessmentViewer,DownloadOutlined,MailOutlined
        },
        data(){
            return{
                table:new sys_attendance(),
                yearMonth:null,
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:[
                        new Column("年月","yearMonth",ColumnType.String).setTableView(100),
                        new Column("考核类型","typeName",ColumnType.String).setTableView(100),
                        new Column("得分","score",ColumnType.String).setTableView(100),
                        new Column("评分","judgeEnd",ColumnType.Boolean).setTableView(60)
                            .setBoolTags(["未完成","已完成"]),
                        new Column("同意","agreeFlag",ColumnType.Enum).setTableView(60)
                            .setEnum(["0","1","2"],["未表态","同意","不同意"],["pink","green","red"]),
                        new Column("操作","actions","actions").setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                AssessmentData:null,
                vModel:true,
                tips:"请选择查询年月",
                loading:false,
                type:"1",
                userType:"user",
                currentRecord:{},
                allowMonth:allowMonth,
                visible:false,
                cYearMonth:null
            }
        },
        methods:{
            init(){
                this.type = "1";
                this.vModel = true;
                this.radioChange();
            },
            monthChange(){
                let data = {
                    userId : this.record?this.record.id:this.userId,
                    yearMonth:this.yearMonth
                };
                this.loading = true;
                assessmentUser.queryAll.requestPOST(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                        for(let i in this.argument.dataSource){
                            this.argument.dataSource[i].judgeEnd = true;
                            this.argument.dataSource[i].agreeFlag = "1";
                            let score = 0;
                            for(let j in this.argument.dataSource[i].userDTOS){
                                score += this.argument.dataSource[i].userDTOS[j].obtainScore;
                                if(this.argument.dataSource[i].userDTOS[j].judgeEnd === false){
                                    this.argument.dataSource[i].judgeEnd = false
                                }
                                if(this.argument.dataSource[i].userDTOS[j].agreeFlag === "2"){
                                    this.argument.dataSource[i].agreeFlag = "2"
                                }
                                if(this.argument.dataSource[i].userDTOS[j].agreeFlag === "0"){
                                    this.argument.dataSource[i].agreeFlag = "0"
                                }
                            }
                            this.argument.dataSource[i].score = score.toFixed(2)
                        }
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            tableInit(){
                let data = {
                    userId : this.record?this.record.id:this.userId,
                    yearMonth:"",
                };
                assessmentUser.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data.sort((a,b)=>{
                            return new Date(b.yearMonth) - new Date(a.yearMonth)
                        });
                        for(let i in this.argument.dataSource){
                            this.argument.dataSource[i].judgeEnd = true;
                            this.argument.dataSource[i].agreeFlag = "1";
                            let score = 0;
                            for(let j in this.argument.dataSource[i].userDTOS){
                                score += this.argument.dataSource[i].userDTOS[j].obtainScore;
                                if(this.argument.dataSource[i].userDTOS[j].judgeEnd === false){
                                    this.argument.dataSource[i].judgeEnd = false
                                }
                                if(this.argument.dataSource[i].userDTOS[j].agreeFlag === "2"){
                                    this.argument.dataSource[i].agreeFlag = "2"
                                }
                                if(this.argument.dataSource[i].userDTOS[j].agreeFlag === "0"){
                                    this.argument.dataSource[i].agreeFlag = "0"
                                }
                            }
                            this.argument.dataSource[i].score = score.toFixed(2)
                        }
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            radioChange(){
                this.AssessmentData = null;
                switch (this.type) {
                    case "0":{
                        this.monthChange();
                        break;
                    }
                    case "1":{
                        this.tableInit();
                        break;
                    }
                }
            },
            deleteData(index,record){
                let ids=[];
                for(let i in record.userDTOS){
                    ids.push(record.userDTOS[i].id)
                }
                let data={
                    batchId:ids.toString()
                };
                assessmentUser.batchDelete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            showDetail(record){
                this.currentRecord = record;
                this.AssessmentData = record.userDTOS;
                this.AssessmentData.sort((a,b)=>{
                    return a.sortOrder - b.sortOrder
                });
                this.vModel = true;
                this.userType = "user";
                this.visible = true
            },
            editDetail(record){
                this.currentRecord = record;
                this.AssessmentData = record.userDTOS;
                this.AssessmentData.sort((a,b)=>{
                    return a.sortOrder - b.sortOrder
                });
                this.visible = true;
                this.vModel = false;
                this.userType = "judge";
            },
            downLoadFile(record){
                let {yearMonth,userId,userName} = record;
                let data = {
                    yearMonth:yearMonth,
                    userId : userId,
                };
                kpi.exportByUserMonth.fileDownloadGet(this,data,"月度考核报告【"+yearMonth+"-"+userName+"】.xls");
            },
            setMail(record){
                let {userId,yearMonth} = record;
                let data = {
                    userIds:userId,
                    month:yearMonth
                };
                smsRecord.notifyStaff.requestPOSTUrlParam(this,data);
            }
        },
        created() {
            let time = parseInt(localStorage.getItem("time"));
            this.yearMonth = new moment(time).format("YYYY-MM");
            this.cYearMonth = new moment(time).format("YYYY-MM");
            this.init();
        }

    }
</script>

<style scoped>

</style>