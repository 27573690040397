<!--
* @program: tyh-oa 
* @author: ly
* @description: qualityManager 
* @create: 2021-03-24 15:07
-->
<template>
    <a-layout style="height: 100%" >
        <a-layout-sider :collapsible="true" :collapsedWidth="40" >
            <a-menu
                    theme="dark"
                    mode="inline"
                    v-model:openKeys="openKeys"
                    v-model:selectedKeys="selectedKeys"
                    @openChange="onOpenChange"
                    @click="handleClick">
                <sub-group-menu v-for="(group,index) in menuLists" :index="index" :groupMenus="group" :key="group.id" />
            </a-menu>
        </a-layout-sider>
        <a-layout-content class="main-window">
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import menuContent from "../../components/menuContent";
    import qualityApplyViewer from "./qualityApplyViewer/qualityApplyViewer";
    import qualityTaskViewer from "./qualityTaskViewer/qualityTaskViewer";
    import qualityReportViewer from "./qualityReportViewer/qualityReportViewer";
    import qualityCheckGroup from "./qualityCheckGroup/qualityCheckGroup";
    import DataProducts from "./DataProducts/DataProducts";
    import DataProductsViewer from "./DataProducts/DataProductsViewer";
    import AreaDivision from "./DataProducts/AreaDivision";
    import AchieveType from "./DataProducts/AchieveType";
    export default {
        name: "qualityManager",
        mixins:[menuContent],
        components:{
          qualityApplyViewer,// 质检申请管理
          qualityTaskViewer,// 质检任务规划
          qualityReportViewer,// 质检任务评定
          qualityCheckGroup,// 质检组管理
          DataProducts,//数据成果管理
          DataProductsViewer, // 数据成果一览
          AreaDivision,// 行政区划维护
          AchieveType,// 成果类型管理
        }
    }
</script>

<style scoped>

</style>