/** 
* 自动生成文件，请勿修改 
* @name: 项目:项目额外信息（月度重大标记、完成目标）管理
* @description: 项目额外信息（月度重大标记、完成目标）管理==新增
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const itemExtra = { 
	/** 1 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/itemExtra/saveOrUpd.do"), 
	/** 2 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/itemExtra/queryOne.do"), 
	/** 3 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/itemExtra/batchDelete.do"), 
	/** 4 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/itemExtra/delete.do"), 
	/** 5 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/itemExtra/queryAll.do"), 
	/** 6 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/itemExtra/queryPage.do"), 
};
export default itemExtra;