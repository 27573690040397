<!--
* @program: HXQTDZSRemake 
* @author: ly
* @component:TcSelectTable 
* @description: 多条目选择，分页查询，需要处理页面切换的响应事件，不需要字段过滤器
* @emit事件：pageChange selectChange
* @create: 2021-10-09 17:32
-->
<template>
    <a-table
            :scroll="{x:offsetWidth,y:offsetHeight}"
            bordered
            :childrenColumnName="'children10'"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :dataSource="source"
            :columns="cols" :rowKey="rowKey"
            :pagination="pagination"
            :loading="loading"
            :rowClassName = "rowClass"
            :custom-row="customRow"
    >
        <template #actions="{index,column,record}">
            <slot name="action" :record="record" :index="getRealIndex(index)" :column="column"/>
        </template>
        <template #Index="{column,record,index}">
            <type-index :currentIndex="currentIndex"  :index="index" :passedNum="passedNum" :viewModel="viewModel" :column="column" :record="record"/>
        </template>


        <template #Selector="{column,record}">
            <type-selector :viewModel="viewModel" :column="column" :record="record"/>
        </template>
        <template #String="{column,record}">
            <type-string :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Number="{column,record}">
            <type-number :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Enum="{column,record}">
            <type-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Date="{column,record}">
            <type-date :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Month="{column,record}">
            <type-month :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Boolean="{column,record}">
            <type-boolean :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Category="{column,record}">
            <type-category :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #CategoryAssets="{column,record}">
            <type-category-assets :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #ObjectReplace="{column,record}">
            <type-object-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PositionLevel="{column,record}">
            <type-position-level :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #TextArea="{column,record}">
            <type-text-area :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #RemoteEnum="{column,record}">
            <type-remote-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #FinancialSubject="{column,record}">
            <type-financial-subject :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Giro="{column,record}">
            <type-giro :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #StrArr="{column,record}">
            <type-str-arr :viewModel="viewModel" :column="column" :record="record" />
        </template>
    </a-table>
    <div v-if=" !loading" :class="paginationVisible?'table-download-btn':''" style="display: flex;width: 32px">
        <a-tooltip title="下载为Excel">
            <a-button @click="downLoadExcel" type="primary" danger>
                <template #icon><download-outlined /></template>
            </a-button>
        </a-tooltip>
    </div>
    <a-divider />
    <slot name="action2" :selectedRows="selectedRows" :selectedRowKeys="selectedRowKeys"/>
</template>

<script>
    import TcBaseTable from "./TcBaseTable";
    export default {
        name: "TcSelectTable",
        mixins:[TcBaseTable],
        methods:{
            onSelectChange(selectedRowKeys, selectedRows){
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
                this.$emit("selectChange",selectedRowKeys,selectedRows)
            },
        },
        mounted() {
            this.init();
            this.pagination.onChange = (page)=>{
                this.currentPage = page;
                let data = { //结构与接口规定有关
                    page:page,
                    pageSize:this.pagination.defaultPageSize,
                    limit:this.pagination.defaultPageSize,
                };
                this.selectedRowKeys = [];
                this.selectedRows = [];
                this.pagination.current = page;
                this.$emit("pageChange",data);
                this.$emit("selectChange",[],[]);
                this.currentIndex = null;
            }
        }
    }
</script>

<style scoped>

</style>