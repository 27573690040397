<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentAccount 
* @description: 绩效池一览
* @create: 2022-04-06 15:34
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
          <div  class="search-bar">
            <div style="margin: 8px;font-weight: 900;font-size: 16px"> 选择部门：</div>
            <a-select v-model:value="departmentId" style="width: 200px" class="search-item" @change="doSearch">
              <a-select-option v-for="{id,fullName} in departments" :key="id" >
                {{fullName}}
              </a-select-option>
            </a-select>
            <a-month-picker   placeholder="请选择年搜索"
                              style="width: 200px" v-model:value="year"
                              @change="doSearch"
                              format="YYYY" />
            <a-button class="search-item" style="margin-left: 20px" type="dashed" shape="circle" @click="doSearch">
              <template #icon><SearchOutlined /></template>
            </a-button>
          </div>


          <performanceInfo ref="performanceInfo" :view-model="false" />
        </div>
    </div>
</template>

<script>
    import {RollbackOutlined,SearchOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import TcBaseTable from "../../../components/table/TcBaseTableFilter";
    import DepartmentPicker from "../../../components/UserPicker/DepartmentPicker";
    import FcForm from "../../../components/form/FcForm";
    import api_sys_department from "../../../assets/api/department";
    import performanceInfo from "./performanceInfo";
    export default {
        name: "performance",
        components:{
          DepartmentPicker,performanceInfo,
          TcBaseTable,FcForm,
          RollbackOutlined,SearchOutlined
        },
        data() {
            return {
              departmentId:null,
              departments:[],
              year:null,
            }
        },
        created() {
          this.init()
        },
        methods: {
          init() {
            api_sys_department.queryAll.requestPOST(this,{},res=>{
              if(res.data.flag){
                this.departments = this.$store.getters.userInfo.departmentDTOList;
                this.departmentId = this.departments[0].id;
                this.departments=this.departments.concat(res.data.data);
                this.doSearch();
              }
              else{
                this.$message.error(res.data.msg);
              }
            });

          },
          doSearch(){
            this.$refs.performanceInfo.doSearch(this.departmentId,this.year);
          },
        },

    }
</script>

<style scoped>

</style>