<!--
* @program: office_automation
* @author: ly
* @component:projectTaskShower
* @description: 项目任务一览
* @create: 2021-12-30 14:27
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <a-button class="search-item" type="default"  @click="visibleType = !visibleType">
                    <template #icon><BarChartOutlined style="color:green" /></template>
                    {{btnText}}
                </a-button>
                <div class="search-bar" v-show="!visibleType">
                    <SearchBar :search-options="searchOptions" v-model:value="searchData" />
                  <a-select
                      v-model:value="state"
                      label-in-value
                      style="width: 200px"
                      :options="options"
                      @change="handleChange"
                  >
                  </a-select>
                    <a-button  class="search-item" type="dashed" shape="circle" @click="doSearch">
                        <template #icon><SearchOutlined /></template>
                    </a-button>
                    <a-button  class="search-item" type="default" shape="circle" @click="refresh">
                        <template #icon><SyncOutlined /></template>
                    </a-button>
<!--                    <span>完成状态：</span>
                    <a-radio-group class="search-item" v-model:value="state">
                        <a-radio :value="null">未完成</a-radio>
                        <a-radio value="0">按时完成</a-radio>
                        <a-radio value="1">提前完成</a-radio>
                        <a-radio value="2">延期完成</a-radio>
                    </a-radio-group>-->
                  <a-button type="primary" @click="downLoadExcel" style="margin-left: 10px">
                    <template #icon><Download title="导出报表"/></template>按部门导出Excel
                  </a-button>
                </div>
            </div>
            <TwoParts v-show="!visibleType" ref="TwoParts"  @change="handleChange" :trans="[65,30]" pos="transform" >
                <template #left>
                    <TcBaseTable ref="project_table" :view-model="true" :page-size="pageSize"
                                 :loading="loading" :data-source="dataSource" :table-columns="tableColumns">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button  class="action-button" type="link" @click="showDetail(index,record)">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <project-tabs  ref="local_tabs"/>
                </template>
            </TwoParts>
            <projectTaskCategoryShower v-show="visibleType" :view-model="true" />
        </div>
    </div>
</template>

<script>
    import item_task from "/src/assets/tables/item_task"
    import itemTask from "../../../assets/api/itemTask";
    import TwoParts from "../../_components/TwoParts";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import {SearchOutlined,SyncOutlined,BarChartOutlined} from "@ant-design/icons-vue"
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import projectTaskCategoryShower from "./projectTaskCategoryShower";
    import projectTabs from "../projectViewer/projectTabs";
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    import department from "../../../assets/api/department";
    import ExportJsonExcel from "js-export-excel";
    import moment from "moment";
    import Icons from "../../common/Icons";
    export default {
        name: "projectTaskViewer",
      mixins:[Icons],
        components:{
            TwoParts,projectTabs,
            SearchBar,SearchOutlined,SyncOutlined,BarChartOutlined,
            TcBaseTable,projectTaskCategoryShower
        },
        computed:{
            btnText(){
                return this.visibleType?"切换到查询浏览":"切换到统计浏览"
            },
            dataSource(){
                let stateVelue = this.state.value;
                if(stateVelue == ''){
                  return this.allTasks;
                }else{
                  return this.allTasks.filter(item=>item.state === this.state.value)
                }
            }
        },
        data(){
            return{
                tableColumns:[],
                searchOptions:[
                    new SearchSegment("关键字", "queryText"),
                    new SearchSegment("绩效分配确认", "moneyConfirm")
                        .setEnum([false,true],["未确认","已确认"]),
                ],
                state:{value: '',label: '全部完成状态',},
                searchData:null,
                formColumns:[],
                formData:null,
                table:new item_task(),
                loading:false,
                pageSize:15,
                visibleType:false,
                allTasks:[],
                defaultQuery:{
                    pid:"0"
                },
              options:[
                {value: '',label: '全部完成状态',},
                {value: null,label: '未完成',},
                {value: '0',label: '按时完成',},
                {value: '1',label: '提前完成',},
                {value: '2',label: '延期完成',},
              ],
            }
        },
        methods:{
            init(){
                //this.state = null;
                this.tableColumns = [
                    new Column("序号","_index",ColumnType.Index).setTableView(60),
                    new Column("任务标题","taskTitle",ColumnType.String).setTableView(200),
                    new Column("任务开始时间","startDate",ColumnType.Date),
                    new Column("预计完成时间","endDate",ColumnType.Date),
                    new Column("进度","completeRatio",ColumnType.Number).setTableView(80)
                        .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                    // new Column("分配部门","departmentName",ColumnType.String).setTableView(100),
                    new Column("操作", "actions", "actions").setTableView(60)
                ];
                this.formColumns = this.table.getFormColumns();
                this.loading = true;
                this.searchData = null;
                this.pageSize = 15;
                let data = {...this.defaultQuery};
                itemTask.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.allTasks = res.data.data;
                    }
                    else{
                        this.allTasks = [];
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            doSearch(){
                let arg = {};
                Object.assign(arg,this.searchData);
                Object.assign(arg,this.defaultQuery);
                itemTask.queryAll.requestPOSTUrlParam(this,arg,res=>{
                    if(res.data.flag){
                        this.allTasks = res.data.data;
                    }
                    else{
                        this.allTasks = [];
                    }
                    this.loading = false;
                });
            },
            refresh(){
                this.init();
                this.$refs.TwoParts.transform();
            },
            showDetail(index,record){
                this.formData = record;
                console.log(record)
                this.$refs.local_tabs.handleOpen([
                    {tag:"项目任务",component:"projectTaskInfo",viewModel:true,projectId:record.itemId,record:null},
                    {tag:"部门任务情况",component:"taskProcessList",viewModel:true,projectId:record.id,record:record},
                    {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:record.itemId},
                    {tag:"项目合同信息",component:"projectContract",viewModel:true,projectId:record.itemId},
                    {tag:"任务延期历史",component:"projectDelayHistory",viewModel:true,record:record},
                    {tag:"质检情况",component:"QCManager",viewModel:true,projectId:record.itemId},
                  {tag:"部门兑付情况",component:"departPayment",viewModel:true,projectId:record.itemId},
                ]);
                this.$refs.TwoParts.transform();
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.tableColumns = [
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("任务标题","taskTitle",ColumnType.String).setTableView(200),
                        new Column("进度","completeRatio",ColumnType.Number).setTableView(80)
                            .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                        // new Column("分配部门","departmentName",ColumnType.String).setTableView(100),
                        new Column("操作", "actions", "actions").setTableView(60)
                    ]
                } else {
                    // this.tableColumns = [
                    //     new Column("序号","_index",ColumnType.Index).setTableView(60),
                    //     new Column("任务标题","taskTitle",ColumnType.String).setTableView(200),
                    //     new Column("任务开始时间","startDate",ColumnType.Date).setTableView(80),
                    //     new Column("预计完成时间","endDate",ColumnType.Date).setTableView(80),
                    //     new Column("进度","completeRatio",ColumnType.Number).setTableView(60)
                    //         .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                    //     // new Column("分配部门","departmentName",ColumnType.String).setTableView(100),
                    //     new Column("操作", "actions", "actions").setTableView(60)
                    // ];
                    this.tableColumns = this.table.getTableColumns().filter(column=>{return column.tableView});
                }
            },
            downLoadExcel(){
                let sheetFilter = [];
                let sheetHeader = [];
                let columnWidths = [];
                let departmentNames = [];
                for(let i in this.tableColumns){
                    sheetFilter.push(this.tableColumns[i].dataIndex);
                    sheetHeader.push(this.tableColumns[i].title);
                    columnWidths.push("8");
                }
                for(let k in this.dataSource){
                    if(departmentNames.indexOf(this.dataSource[k].departmentName) === -1){
                        departmentNames.push(this.dataSource[k].departmentName)
                    }
                }
                let datas =[];
                for(let j in departmentNames){
                    let sheetData = this.dataSource.filter(item=>{return item.departmentName === departmentNames[j]});
                    sheetData.forEach((item,index)=>{
                        item._index = index + 1;
                        for(let n in this.tableColumns){
                            let {type,dataIndex,boolTags,enumTags,enums,idReplaceObject} = this.tableColumns[n];
                            switch (type) {
                                case ColumnType.Date:{
                                    item[dataIndex] = new Date(item[dataIndex]).toLocaleDateString();
                                    break;
                                }
                                case ColumnType.Boolean :{
                                    if(boolTags){
                                        item[dataIndex] = item[dataIndex]?boolTags[1]:boolTags[0];
                                    }
                                    else{
                                        item[dataIndex] = item[dataIndex]?"是":"否";
                                    }
                                    break;
                                }
                                case ColumnType.Enum:{
                                    if(enumTags){
                                        let idx = enums.indexOf(item[dataIndex]);
                                        item[dataIndex] = enumTags[idx]?enumTags[idx]:"无";

                                    }
                                    break;
                                }
                                case ColumnType.IdReplace:{
                                    item[dataIndex] = item[idReplaceObject.replaceSegment]?item[idReplaceObject.replaceSegment]:"无";
                                    break;
                                }
                                case ColumnType.Month:{
                                    item[dataIndex] =  moment(item[dataIndex]).format("YYYY-MM");
                                    break;
                                }
                                case ColumnType.Category:{
                                    if(item["category"]){
                                        let pName = item["category"].parent.categoryName;
                                        item[dataIndex] = pName + " - " + item["category"].categoryName;
                                    }
                                    else if(item.categoryName){
                                        item[dataIndex] = item.categoryName;
                                    }
                                    else{
                                        item[dataIndex] = "";
                                    }
                                    break;
                                }
                            }
                        }
                    });
                    datas.push({
                        sheetFilter:sheetFilter,
                        sheetHeader:sheetHeader,
                        columnWidths:columnWidths,
                        sheetName:departmentNames[j],
                        sheetData:sheetData
                    })
                }
                let option={};
                option.fileName = '导出当前数据' ;//文件名
                option.datas=datas;
                new ExportJsonExcel(option).saveExcel();
            }
        },
        created() {
            this.init();
            department.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    let ids = [];
                    let names = [];
                    for(let i in res.data.data){
                        if(res.data.data[i].departType === "0"){//生产部门
                            ids.push(res.data.data[i].id);
                            names.push(res.data.data[i].name);
                        }
                    }
                    this.searchOptions.push(
                        new SearchSegment("部门", "departmentId").setEnum(ids,names),
                    )
                }
            })
        }
    }
</script>

<style scoped>

</style>
