<!--
* @program: office_automation 
* @author: ly
* @component:typeRemoteEnum 
* @description: 
* @create: 2022-01-05 12:37
-->
<template>
    <div v-if="viewModel" style="text-align: center">
        <a-tag  :color="color">{{presentValue}}</a-tag>
        <!--        <div v-else >{{getTags(record[column.dataIndex])}} </div>-->
    </div>
    <div v-else>
        <a-select v-if="editable"  v-model:value="record[column.dataIndex]" style="width: 100%" @change="columnTrigger">
            <a-select-option v-for="option in options" :key="option.id">{{option.name}}</a-select-option>
        </a-select>
        <div v-else style="text-align: center">
            <a-tag   :color="color">{{presentValue}}</a-tag>
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {computed} from "vue";

    export default {
        name: "typeRemoteEnum",
        mixins:[typeBase],
        computed:{
            presentValue(){
                return this.record[this.column.idReplaceObject.replaceSegment]?this.record[this.column.idReplaceObject.replaceSegment]:"无"
            },
        },
        data(){
            return{
                color:"",
                defaultColor:"purple",
                queryObject:computed(()=>this.column.idReplaceObject.queryObject),
                options:[]
            }
        },
        created() {
            this.queryObject.api.requestPOSTUrlParam(this,{},res=>{
                if(res.data.flag){
                    this.options = res.data.data;
                }
                else{
                    this.options =[];
                }
            })
        }
    }
</script>

<style scoped>

</style>