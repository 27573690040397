/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class income_item extends Table {
    constructor() {
        super();
        this.name = "income_item";
        this.CNName = "其他收入";
        this.columnArray = [
            new Column("项目名称","itemName",ColumnType.String,true).setTableView(150),
            new Column("类别名称","categoryName",ColumnType.String,true).setTableView(80),
            new Column("收入金额","collectAmount",ColumnType.Number,true).setTableView(80),
            new Column("收入日期","collectDate",ColumnType.Date,true).setTableView(80),
            new Column("收款方式","collectType",ColumnType.Enum,true).setTableView(80)
            .setEnum(["1","2"],
                ["现金（包括私人转账）","对公打款"],
                ["Pink","MediumPurple"]),
            new Column("银行名称","bankName",ColumnType.String,true).setTableView(80),
            new Column("支行名称","subBank",ColumnType.String,true).setTableView(80),
            new Column("银行卡号","bankNum",ColumnType.String,true).setTableView(80),
        ];

    }
}