<!--
* @program: office_automation 
* @author: ly
* @component:QCApplyAdd 
* @description: 
* @create: 2022-06-13 10:11
-->
<template>
    <a-radio-group class="search-item" v-model:value="type">
        <a-radio value="1">项目质检</a-radio>
        <a-radio value="2">任务质检</a-radio>
        <a-radio value="3">工序质检</a-radio>
    </a-radio-group>
    <div v-if="type === '2'">
        <span>任务选择</span>
        <a-select @select="selectChange" v-model:value="taskId" style="width: 250px" class="search-item">
            <a-select-option v-for="item in taskList"  :key="item.id">
                {{item.taskTitle}}
            </a-select-option>
        </a-select>
    </div>
    <div v-if="type === '3'">
        <span>工序选择</span>
        <a-select @select="selectChange2" v-model:value="processId" style="width: 250px" class="search-item">
            <a-select-option v-for="item in processList"  :key="item.id">
                {{item.title}}
            </a-select-option>
        </a-select>
    </div>
    <FcForm :view-model="false" :form-columns="formColumns" :form-data="formData">
        <template #action = "{record}">
            <a-button @click="saveData(record)"  type="primary">
                提交
            </a-button>
        </template>
    </FcForm>
</template>

<script>
    import itemTask from "../../../assets/api/itemTask";
    import itemTaskProcess from "../../../assets/api/itemTaskProcess";
    import FcForm from "../../../components/form/FcForm";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import qcApply from "../../../assets/api/qcApply";
    export default {
        name: "QCApplyAdd",
        props:["projectId"],
        emits:["refresh"],
        components:{
            FcForm
        },
        data() {
            return {
                type:"1", //["1","2","3"],["项目质检","任务质检","工序质检"]
                taskList:[],
                taskId:null,
                processList:[],
                processId:null,
                formColumns:[
                    new Column("质检标题","title",ColumnType.String,true),
                    new Column("质检描述","content",ColumnType.String,true),
                ], //提供Form的字段集 this.table.getFormColumns()
                formData:null,//提供Form的formData
                departments:[],
                departmentId:null
            }
        },
        methods: {
            init() {
                this.formData = {
                    title:null,
                    content:null
                };
                this.departments = this.$store.getters.userInfo.departmentDTOList;
                if(this.departments.length ===1){
                    this.departmentId = this.departments[0].id;
                }
                else{
                    this.$message.error("部门信息错误")
                }
            },
            saveData(record){
                let data = {...record};
                data.itemId = this.projectId;
                data.type = this.type;
                data.departmentId = this.departmentId;
                switch (this.type) {
                    case "1":{
                        data.originId = this.projectId;
                        break;
                    }
                    case "2":{
                        data.originId = this.taskId;
                        break;
                    }
                    case "3":{
                        data.originId = this.processId;
                        break;
                    }
                }
                qcApply.saveOrUpd.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.$emit("refresh");
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });

            },
            selectChange(id){
                let option = this.taskList.filter(item=>{ return item.id === id})[0];
                this.formData.title ="【"+ option.taskTitle+"】任务质检";
            },
            selectChange2(id){
                let option = this.processList.filter(item=>{ return item.id === id})[0];
                this.formData.title ="【"+ option.title+"】工序质检";
            }
        },
        created() {
            this.init();
            let data = {
                itemId:this.projectId
            };
            itemTask.queryAll.requestPOSTUrlParam(this,data,res=>{
                if(res.data.flag){
                    this.taskList = res.data.data;
                }
                else{
                    this.taskList = []
                }
            });
            itemTaskProcess.queryAll.requestPOSTUrlParam(this,data,res=>{
                if(res.data.flag){
                    this.processList = res.data.data;
                }
                else{
                    this.processList = [];
                }
            })
        }
    }
</script>

<style scoped>

</style>