<!--
*@description：项目设计书
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar">
      <a-select v-model:value="approveState"
                style="width: 120px" class="search-item"
                :options="searchOptions" />
      <div class="ant-col-8">
        <a-select
            show-search
            v-model:value="itemName"
            placeholder="输入项目名称查询"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            style="width: 100%;"
            @search="handleSearch"
            @change="columnTrigger"
        >
          <a-select-option v-for="option in itemData" :key="option.id">
            {{ option.name }}
          </a-select-option>
        </a-select>
      </div>

        <a-input class="search-item ant-col-4" v-model:value="docName" placeholder="文档名称" />
        <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
            <template #icon><Search /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
            <template #icon><Reset /></template>
        </a-button>

    </div>


</template>

<script>
    import Icons from "../../../common/Icons";

    import achieveRecord_api from "../../../qualityManager/DataProducts/config/achieveRecord_api";
    export default {
      name: "projectDocToolBar",
      mixins:[Icons],
      data(){
        return{
          docName:null,
          // 项目搜索
          approveState:"",
          searchOptions:[
            {value:"",label:"全部"},
            {value:"0",label:"未审核"},
            {value:"1",label:"通过"},
            {value:"2",label:"未通过"},
          ],
          itemId:null,
          itemName:null,
          itemData:[],
        }
      },
      created() {

      },
      methods:{
        /********** 项目 start ************/
        handleSearch(val){
          let parms = {
            name:val
          }
          achieveRecord_api.queryAll_item.requestPOST(this,parms,res=>{
            if(res.data.flag){
              let data = res.data.data;
              let option_data = [];
              if(data.length > 0){
                for(let i=0;i<data.length;i++){
                  let info = data[i];
                  let parm = {
                    id:info.id,
                    name:info.name
                  };
                  option_data.push(parm);
                }
              }
              this.itemData = option_data;
            }else{
              this.itemData = [];
            }
          });
        },
        columnTrigger(val){
          this.itemId = val;
        },
        /********** 项目 end  *************/
        doSearch(){
            let data = this.$GetEmitData(this.$eventTags.search);
            this.$emit("event",data);
        },
        refresh(){
          this.docName = null;
          this.itemData = [];
          this.itemId = null;
          this.itemName = null;
          this.approveState = "";
          this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        },
        getParms(){
          let parms = {
            docName:this.docName,
            itemId:this.itemId,
            approveState:this.approveState
          }
          return parms;
        },
      }
    }
</script>

<style scoped>

</style>