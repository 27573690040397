import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: sys_attendance
 * @create: 2022-01-14 15:18
 * 按人查询使用
 **/

export default class sys_attendance extends Table{
    constructor(name,CNName) {
        super(name,CNName);
        this.name = "sys_attendance";
        this.CNName = "月度考勤表";
        this.columnArray = [
            new Column("时间","yearMonth",ColumnType.Month,false).setTableView(100),
            new Column("应打卡天数","shouldDay",ColumnType.String,true).setTableView(120),
            new Column("正常天数","normalDay",ColumnType.String,true).setTableView(100),
            new Column("异常天数","unNormalDay",ColumnType.String,true).setTableView(100),
            new Column("标准工作时长","standHour",ColumnType.String,true).setTableView(120),
            new Column("实际工作时长","actualHour",ColumnType.String,true).setTableView(120),
            new Column("异常次数","unNormalCount",ColumnType.String,true).setTableView(100),
            new Column("迟到次数","delayCount",ColumnType.String,true).setTableView(100),
            new Column("迟到分钟","delayMinute",ColumnType.String,true).setTableView(100),
            new Column("早退次数","earlyCount",ColumnType.String,true).setTableView(100),
            new Column("早退时长","earlyMinute",ColumnType.String,true).setTableView(100),
            new Column("矿工次数","absentCount",ColumnType.String,true).setTableView(100),
            new Column("矿工时长","absentMinute",ColumnType.String,true).setTableView(100),
            new Column("缺卡次数","lackCount",ColumnType.String,true).setTableView(100),
            new Column("地点异常次数","addrUnNormal",ColumnType.String,true).setTableView(120),
            new Column("设备异常次数","deviceUnNormal",ColumnType.String,true).setTableView(120),
            new Column("补卡次数","repairLackCount",ColumnType.String,true).setTableView(100),
        ];
    }
}