/** 
* 自动生成文件，请勿修改 
* @name: 用章:公章用途管理模块
* @description: 公章用途管理模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const sealPurpose = { 
	/** 1 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/sealPurpose/queryAll.do"), 
	/** 2 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/sealPurpose/queryPage.do"), 
	/** 3 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/sealPurpose/saveOrUpd.do"), 
	/** 4 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/sealPurpose/batchDelete.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/sealPurpose/delete.do"), 
	/** 6 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/sealPurpose/queryOne.do"), 
};
export default sealPurpose;