<!--
*@description：区划编辑
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <a-modal v-model:visible="visible"
           :maskClosable="true"
           :title="modalTitle"  width="45%"
           :destroyOnClose="true"
           style="margin-top: 2%;"
  >

    <ar-form  ref="bill_form" :argument="argument"  :viewModel="viewModel"  />

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
      <a-button v-if="!viewModel" key="back" type="primary" @click="submit">提交</a-button>
    </template>
  </a-modal>
</template>

<script>
import ArForm from "../../../../components/form/ArForm";
import area_api from "../config/area_api";

export default {
  name: "areaEdit",
  inject:["arguments"],
  components:{
    ArForm
  },
  data(){
    return{
      visible:false,
      modalTitle:null,
      viewModel:null,
      pid:null,
      level:null,
      argument:null,
    }
  },
  methods:{
    open(modalTitle,type,viewModel,pid,level,argument){
      this.visible = true;
      this.modalTitle = modalTitle;
      this.type = type;
      this.viewModel = viewModel;
      this.pid = pid;
      this.level = level;
      this.argument = argument;
    },
    getParms(){
        let data = this.$refs.bill_form.getSource();
        data.userId = this.$store.getters.userInfo.id;
        return data;
    },
    submit(){
      let type = this.type;
      switch (type){
        case "add":{
          let  parms  = this.argument.formData;
          parms.pid = this.pid;
          parms.level = parseInt(this.level) + 1;
          area_api.saveOrUpd.requestPOST(this,parms,res=>{
            this.visible = false;
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.$emit("event",this.$GetEmitData(this.$eventTags.editInfo));
            }
            else{
              this.$message.error(res.data.msg);
            }
          })
          break;
        }
        case "edit":{
          let  parms  = this.argument.formData;
          area_api.saveOrUpd.requestPOST(this,parms,res=>{
            this.visible = false;
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.$emit("event",this.$GetEmitData(this.$eventTags.editInfo));
            }
            else{
              this.$message.error(res.data.msg);
            }
          })
          break;
        }
        case "show":{
          break;
        }
      }
    },
  }
}
</script>

<style scoped>

</style>