<!--
* @program: office_automation 
* @author: ly
* @component:projectCategoryShower 
* @description: 项目按照类别分类展示
与projectStatistics 保持一致
* @create: 2021-12-29 15:25
-->
<template>
    <a-spin :spinning="spinning">
        <a-page-header style="border: 1px solid rgb(235, 237, 240);padding: 0 20px;">
            <template #title>
                <div class="center-flex">
                    <a-button class="search-item" type="default" shape="circle" @click="init">
                        <template #icon><SyncOutlined /></template>
                    </a-button>
                    <div>项目总览（共{{totalSum}}个）</div>
                    <a-radio-group v-model:value="mode" >
                        <a-radio value="1">按年度统计</a-radio>
<!--                        <a-radio disabled value="2">按申请部门</a-radio>-->
                    </a-radio-group>
                    <div>点击数字能够看到具体项目列表</div>
                </div>
            </template>
        </a-page-header>
        <div v-if="visible" style="display: flex;flex-wrap: wrap;">
            <a-card class="project-card" v-for="(item,key) in group" :loading="item.loading" :key="key">
                <template #title>
                    {{item.year?item.year+"年启动项目":"未指定年份"}} ：<a-tag style="cursor: pointer" :color="'cyan'" @click="handleShow(item)">{{item.list.length}}</a-tag>个
                </template>
                <TagShow v-for="(tag,index) in item.groupData"   :color="tag.color" :tag="tag.label" :key="index">
                    <template #content>
                        <div style="display: flex;flex-wrap: wrap;justify-content: center;">
                            <div v-for="(list,idx) in tag.list" :key="idx" style="font-size: 8px;" class="center-flex">{{list.label}}：<a-tag style="cursor: pointer" :color="list.color" @click="handleShow(list)">{{list.list.length}}</a-tag>个 &nbsp;</div>
                        </div>
                    </template>
                </TagShow>
            </a-card>
        </div>
        <div v-else>
            <a-divider orientation="right" style="margin: 0">
                <div style="display: flex"><a-button danger @click = "topReturn" >返回</a-button>
                </div>
            </a-divider>
            <ArBaseTableFilter  v-show="tableVisible" :argument="argument" :page-size="15" :view-model="true" :download="true">
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button class="action-button" type="link" @click="showDetail(index,record)">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button class="action-button" type="link" @click="editDetail(index,record)">
                            <EditOutlined class="pointer"/>
                        </a-button>
                    </div>
                </template>
            </ArBaseTableFilter>
            <a-divider v-show="!tableVisible" orientation="right" style="margin: 0" >
                <a-button  danger @click = "tableVisible = true">返回</a-button>
            </a-divider>
            <project-tabs v-show="!tableVisible" ref="local_tabs"/>
        </div>
    </a-spin>
</template>

<script>
    import itemInfo from "/src/assets/api/itemInfo"
    import moment from "moment";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {SearchOutlined,SyncOutlined,EditOutlined,InfoCircleOutlined} from "@ant-design/icons-vue"
    import TagShow from "../../_components/TagShow";
    import projectTabs from "../projectViewer/projectTabs";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import item_info from "../../../assets/tables/item_info";
    export default {
        name: "projectCategoryShower",
        components:{ArBaseTableFilter,SearchOutlined,TagShow,InfoCircleOutlined,
            SyncOutlined,EditOutlined,projectTabs},
        props:["projectData"],
        data(){
            return{
                statisticList:[{}],
                group:[new statisGroup(0,[]),],
                spinning:false,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                visible:true,
                tableVisible:true,
                totalSum:0,
                defaultQuery:{
                    auditState:"1" //已通过审核
                },
                mode:"1"
            }
        },
        methods:{
            init(){
                this.statisticList = [{}] ;
                this.group=[new statisGroup(0,[])];
                this.spinning = true;
                if(this.projectData){
                    this.groupYears(this.projectData);
                    this.totalSum = this.projectData.length;
                    this.spinning = false;
                }
                else{
                    itemInfo.queryAll.requestPOST(this,this.defaultQuery,res=>{
                        if(res.data.flag){
                            this.groupYears(res.data.data);
                            this.totalSum = res.data.data.length;
                        }
                        this.spinning = false;
                    });
                }
                this.argument.tableColumns = new item_info().getTableColumns();
            },
            groupYears(data){
                for(let i in data){
                    let startTime = data[i].startTime;
                    let year ;
                    if(startTime){
                        year = moment(startTime).locale('zh-cn').format('YYYY');
                        year = parseInt(year);
                    }
                    else{
                        year = 0
                    }
                    let arr = this.group.filter(item => item.year === year);
                    if(arr.length > 0){
                        arr[0].list.push(data[i]);
                    }
                    else{
                        this.group.push(new statisGroup(year,[data[i]]))
                    }
                }
                this.group.sort((a,b)=>{
                    return a.year - b.year;
                });
                if(this.group[0].list.length === 0){
                    this.group.shift();
                }
                this.statisticGroup();
            },

            statisticGroup(){
                for(let i in this.group){
                    this.group[i].groupData.push(new tagShowList("合同情况", "orange", [
                        new tagShowList("无合同","pink",[]),
                        new tagShowList("有合同","green",[]),
                        new tagShowList("不需要","cyan",[]),
                    ]));
                    this.group[i].groupData.push(new tagShowList("招标情况", "cyan", [
                        new tagShowList("公开招标","cyan",[]),
                        new tagShowList("邀请招标","green",[]),
                        new tagShowList("议标","gold",[]),
                        new tagShowList("未指定","pink",[])
                    ]));
                    this.group[i].groupData.push(new tagShowList("任务大类", "blue", [
                        new tagShowList("其他","red",[]),
                        new tagShowList("系统研发","blue",[]),
                        new tagShowList("工程测量","orange",[]),
                        new tagShowList("规划编制","cyan",[]),
                        new tagShowList("数据处理","pink",[])
                    ]));
                    this.group[i].groupData.push(new tagShowList("项目类型", "red", [
                        new tagShowList("自研项目","red",[]),
                        new tagShowList("甲方项目","blue",[])
                    ]));
                    this.group[i].groupData.push(new tagShowList("工作完成情况", "green", [
                        new tagShowList("未完成","pink",[]),
                        new tagShowList("已完成","green",[]),
                    ]));
                }
                for(let i in this.group){
                    for(let j in this.group[i].list){
                        /**合同情况*/
                        if(this.group[i].list[j].contractState === "1"){//有合同
                            this.group[i].groupData[0].list[1].list.push(this.group[i].list[j]);
                        }
                        else if(this.group[i].list[j].contractState === "2"){//不需要合同
                            this.group[i].groupData[0].list[2].list.push(this.group[i].list[j]);
                        }
                        else{//无合同
                            this.group[i].groupData[0].list[0].list.push(this.group[i].list[j]);
                        }
                        /**招标情况
                         tenderWay = "1";//招标方式：1：公开招标；2：邀请招标；3：议标*/
                        if(this.group[i].list[j].tenderWay === "1"){
                            this.group[i].groupData[1].list[0].list.push(this.group[i].list[j]);
                        }
                        else if(this.group[i].list[j].tenderWay === "2"){
                            this.group[i].groupData[1].list[1].list.push(this.group[i].list[j]);
                        }
                        else if(this.group[i].list[j].tenderWay === "3"){
                            this.group[i].groupData[1].list[2].list.push(this.group[i].list[j]);
                        }
                        else{
                            this.group[i].groupData[1].list[3].list.push(this.group[i].list[j]);
                        }
                        /**任务大类 ["0","1","2","3","4"] ["其他","系统研发","工程测量","规划编制","数据处理"]*/
                        switch (this.group[i].list[j].itemCategory) {
                            case "0":{
                                this.group[i].groupData[2].list[0].list.push(this.group[i].list[j]);
                                break;
                            }
                            case "1":{
                                this.group[i].groupData[2].list[1].list.push(this.group[i].list[j]);
                                break;
                            }
                            case "2":{
                                this.group[i].groupData[2].list[2].list.push(this.group[i].list[j]);
                                break;
                            }
                            case "3":{
                                this.group[i].groupData[2].list[3].list.push(this.group[i].list[j]);
                                break;
                            }
                            case "4":{
                                this.group[i].groupData[2].list[4].list.push(this.group[i].list[j]);
                                break;
                            }
                            default:{
                                this.group[i].groupData[2].list[0].list.push(this.group[i].list[j]);
                                break;
                            }
                        }
                        /**项目类型 ["0","1"],["自研项目","甲方项目"],["cyan","blue"] */
                        if(this.group[i].list[j].itemType === "0"){
                            this.group[i].groupData[3].list[0].list.push(this.group[i].list[j]);
                        }
                        else{
                            this.group[i].groupData[3].list[1].list.push(this.group[i].list[j]);
                        }
                        /**完成情况*/
                        if(this.group[i].list[j].itemState === "1"){//已完成
                            this.group[i].groupData[4].list[1].list.push(this.group[i].list[j]);
                        }
                        else{//未完成
                            this.group[i].groupData[4].list[0].list.push(this.group[i].list[j]);
                        }
                    }
                }
            },
            handleShow(data){
                if(data.list.length > 0 ){
                    this.visible = false;
                    this.argument.dataSource = data.list;
                }
                else{
                    this.$message.info("没有数据")
                }
            },
            showDetail(index,record){
                this.tableVisible = false;
                if(record.itemType === "0"){//自研项目
                    this.$refs.local_tabs.handleOpen([
                        {tag:"项目任务",component:"projectTaskInfo",viewModel:true,projectId:record.id},
                        {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:record.id},
                    ]);
                }
                else{
                    this.$refs.local_tabs.handleOpen([
                        {tag:"项目任务",component:"projectTaskInfo",viewModel:true,projectId:record.id},
                        {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:record.id},
                        {tag:"项目合同信息",component:"projectContract",viewModel:true,projectId:record.id},
                    ]);
                }
            },
            editDetail(index,record){
                this.tableVisible = false;
                if(record.itemType === "0"){//自研项目
                    this.$refs.local_tabs.handleOpen([
                        {tag:"项目任务",component:"projectTaskInfo",viewModel:true,projectId:record.id},
                        {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:record.id},
                    ]);
                }
                else{
                    this.$refs.local_tabs.handleOpen([
                        {tag:"项目任务",component:"projectTaskInfo",viewModel:false,projectId:record.id},
                        {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:record.id},
                        {tag:"项目合同信息",component:"projectContract",viewModel:true,projectId:record.id},
                    ]);
                }
            },
            topReturn(){
                this.visible = true;
                this.tableVisible = true;
                this.argument.currentIndex = null;
            },
        },
        created() {
            this.init();
        }
    }
    class statisGroup{
        constructor(year,list) {
            this.year=year;
            this.list=list;
            this.loading=false;
            this.groupData=[];
        }
    }
    class tagShowList{
        constructor(label,color,list,visible) {
            this.label = label;
            this.color = color;
            this.list = list;
            this.visible = visible !== false;
        }
    }
</script>

<style scoped>

</style>