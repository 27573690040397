<!--
* @program: office_automation 
* @author: ly
* @component:performanceSummary 
* @description: 
* @create: 2023-01-11 14:59
-->
<template>
<!--    <div class="oa-content">-->
<!--        <div class="oa-window">-->
<!--            <a-tabs   v-model:activeKey="activeKey">-->
<!--                <a-tab-pane  key="1" tab="拟下绩效和兑付管理" >-->
<!--                    <itemConfirmMoneyArrange />-->
<!--                </a-tab-pane>-->
<!--                <a-tab-pane  key="2" tab="项目收入情况汇总" >-->
<!--                    系统数据出错-->
<!--                </a-tab-pane>-->
<!--                <a-tab-pane  key="3" tab="项目支付情况汇总" >-->
<!--                    系统数据出错-->
<!--                </a-tab-pane>-->
<!--                <template #renderTabBar="{ DefaultTabBar, ...props }">-->
<!--                    <component-->
<!--                            :is="DefaultTabBar"-->
<!--                            v-bind="props"-->
<!--                            :style="{ zIndex: 1, background: '#fff', }"-->
<!--                    />-->
<!--                </template>-->
<!--            </a-tabs>-->
<!--        </div>-->
<!--    </div>-->
    <itemConfirmMoneyArrange />
</template>

<script>
    import itemConfirmMoneyArrange from "./itemConfirmMoneyArrange";
    export default {
        name: "performanceSummary",
        components:{
            itemConfirmMoneyArrange
        },
        data() {
            return {
                activeKey:"1"
            }
        },
        methods: {
            init() {
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>