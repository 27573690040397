<!--
* @program: HXQTDZSRemake
* @author: ly
* @component:ArBaseTable
* @description: 一般用于分页查询，需要处理页面切换的响应事件，不需要字段过滤器
* @emit事件：pageChange
* childrenColumnName="'children10'" 禁止table出现展开状态
* @create: 2021-10-09 16:25
-->
<template>
    <a-table
            bordered
            :childrenColumnName="'children10'"
            :dataSource="source"
            :columns="cols" :rowKey="rowKey"
            :pagination="pagination"
            :loading="loading"
            :rowClassName = "rowClass"
            :custom-row="customRow"
            :scroll="{x:offsetWidth,y:offsetHeight}"
    >
        <template #actions="{index,column,record}">
            <slot name="action" :record="record" :index="getRealIndex(index)" :column="column"/>
        </template>
        <template #Index="{column,record,index}">
            <type-index :currentIndex="currentIndex"  :index="index" :passedNum="passedNum" :viewModel="viewModel" :column="column" :record="record"/>
        </template>
        <template #Selector="{column,record}">
            <type-selector :viewModel="viewModel" :column="column" :record="record"/>
        </template>
        <template #String="{column,record}">
            <type-string :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #String2="{column,record}">
          <type-string2 :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Number="{column,record}">
            <type-number :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Enum="{column,record}">
            <type-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Date="{column,record}">
            <type-date :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Month="{column,record}">
            <type-month :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Boolean="{column,record}">
            <type-boolean :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Category="{column,record}">
            <type-category :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #CategoryAssets="{column,record}">
            <type-category-assets :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #ObjectReplace="{column,record}">
            <type-object-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PositionLevel="{column,record}">
            <type-position-level :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #TextArea="{column,record}">
            <type-text-area :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #RemoteEnum="{column,record}">
            <type-remote-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #FinancialSubject="{column,record}">
            <type-financial-subject :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Giro="{column,record}">
            <type-giro :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #StrArr="{column,record}">
            <type-str-arr :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template v-if="title" #title>
            <slot name="title"/>
        </template>
    </a-table>
    <div v-if=" !loading" :class="paginationVisible?'table-download-btn':''" style="display: flex;width: 32px">
        <a-tooltip title="下载为Excel">
            <a-button @click="downLoadExcel" type="primary" danger>
                <template #icon><download-outlined /></template>
            </a-button>
        </a-tooltip>
    </div>
</template>

<script>
    import dataTypes from "../dataPresentType/dataTypes";
    import ExportJsonExcel from "js-export-excel";
    import columnType from "../../assets/tables/parts/column_type";
    import moment from "moment";
    import {SearchOutlined,DownloadOutlined} from "@ant-design/icons-vue"
    export default {
        name: "ArBaseTable",
        components:{SearchOutlined,DownloadOutlined},
        props:{
            /**
             argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                }
             */
            argument:{required:true},
            viewModel:{required:true},
            pageSize:{required:true},
            key:{required:false}, //rowKey
            download:{required:false}, //是否显示下载为excel按钮
            title:{required:false} //是否显示title
        },
        mixins:[dataTypes],
        emits:["showRecord"],
        watch:{
            argument(){
                this.init()
            }
        },
        provide(){
            return{
                provideTrigger:this.provideTrigger,
                presentType:"table"
            }
        },
        computed:{
            passedNum(){
                return this.pagination.defaultPageSize*(this.currentPage-1);
            },
            source(){
                return this.argument.dataSource;
            },
            cols(){
                return this.argument.tableColumns.filter(column=>{return column.tableView})
            },
            loading(){
                return this.argument.loading;
            },
            paginationVisible(){
                return this.pagination.total > this.pagination.defaultPageSize
            }
        },
        data(){
            return {
                rowKey:"id",
                currentPage:1,
                pagination:{
                    size:"small",
                    hideOnSinglePage: true,
                    defaultPageSize: 15,
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '15', '20'],
                    onChange: () => {}
                },
                selectedRowKeys: [],
                selectedRows:[],
                searchText:null,
                searchedColumn:null,
                currentIndex:null,
                realIndex:null,
                offsetWidth:0,
                offsetHeight:0,
            }
        },
        methods:{
            init(){
                this.pagination.defaultPageSize = this.pageSize?this.pageSize:15
            },
            provideTrigger(action){
                if(action){
                    action(this.source,this.argument.tableColumns);
                }
            },
            setTotalSize(num){
                this.pagination.total = num;
            },
            setCurrentPage(num){
                this.pagination.current = num;
            },
            getPageSize(){
                return this.pagination.defaultPageSize;
            },
            setPageSize(size){
                this.pagination.defaultPageSize = size;
            },
            getSelectedRows(){
                return this.selectedRows;
            },
            getSelectedRowKeys(){
                return this.selectedRowKeys
            },
            rowClass(record,index){
                if(index === this.currentIndex){
                    return "highlight-row"
                }
                else{
                    return ""
                }
            },
            customRow(record,index){
                let _this = this;
                return{
                    onClick(){
                        _this.currentIndex = index;
                    },
                    onDblclick(){
                        _this.$emit("showRecord",index,record);
                    }
                }
            },
            getRealIndex(index){
                let realIndex = this.passedNum + index ;
                return realIndex > this.source.length ?index:realIndex;
            },
            downLoadExcel(){
                let sheetFilter = [];
                let sheetHeader = [];
                let columnWidths = [];
                for(let i in this.cols){
                    sheetFilter.push(this.cols[i].dataIndex);
                    sheetHeader.push(this.cols[i].title);
                    columnWidths.push("8");
                }
                let sheetData = JSON.parse(JSON.stringify(this.source));
                sheetData.forEach((item,index)=>{
                    item._index = index + 1;
                    for(let n in this.cols){
                        let {type,dataIndex,boolTags,enumTags,enums,idReplaceObject} = this.cols[n];
                        switch (type) {
                            case columnType.Date:{
                                item[dataIndex] = new Date(item[dataIndex]).toLocaleDateString();
                                break;
                            }
                            case columnType.Number:{
                            item[dataIndex] = item[dataIndex]?item[dataIndex]:0;
                            break;
                            }
                            case columnType.Boolean :{
                                if(boolTags){
                                    item[dataIndex] = item[dataIndex]?boolTags[1]:boolTags[0];
                                }
                                else{
                                    item[dataIndex] = item[dataIndex]?"是":"否";
                                }
                                break;
                            }
                            case columnType.Enum:{
                                if(enumTags){
                                    let idx = enums.indexOf(item[dataIndex]);
                                    item[dataIndex] = enumTags[idx]?enumTags[idx]:"无";

                                }
                                break;
                            }
                            case columnType.IdReplace:{
                                item[dataIndex] = item[idReplaceObject.replaceSegment]?item[idReplaceObject.replaceSegment]:"无";
                                break;
                            }
                            case columnType.Month:{
                                item[dataIndex] =  moment(item[dataIndex]).format("YYYY-MM");
                                break;
                            }
                            case columnType.Category:{
                                if(item["category"]){
                                    let pName = item["category"].parent.categoryName;
                                    item[dataIndex] = pName + " - " + item["category"].categoryName;
                                }
                                else if(item.categoryName){
                                    item[dataIndex] = item.categoryName;
                                }
                                else{
                                    item[dataIndex] = "";
                                }
                                break;
                            }
                            case columnType.CategoryAssets:{
                                if(item["category"]){
                                    let pName = item["category"].parent.categoryName;
                                    item[dataIndex] = pName + " - " + item["category"].categoryName;
                                }
                                else if(item.categoryName){
                                    item[dataIndex] = item.categoryName;
                                }
                                else{
                                    item[dataIndex] = "";
                                }
                                break
                            }
                        }
                    }
                });
                let datas =[
                    {
                        sheetFilter:sheetFilter,
                        sheetHeader:sheetHeader,
                        columnWidths:columnWidths,
                        sheetName:"sheet",
                        sheetData:sheetData
                    }
                ];
                let option={};
                option.fileName = new Date().toLocaleDateString() ;//文件名
                option.datas=datas;
                new ExportJsonExcel(option).saveExcel();
            }
        },
        mounted() {
            this.init();
            this.pagination.onChange = (page)=>{
                this.currentPage = page;
                let data = { //结构与接口规定有关
                    page:page,
                    pageSize:this.pagination.defaultPageSize,
                    limit:this.pagination.defaultPageSize,
                };
                this.pagination.current = page;
                this.$emit("pageChange",data);
                this.currentIndex = null;
            };
            this.rowKey = this.key?this.key:"id";
            setTimeout(()=>{
                if(this.$el){
                    this.offsetWidth =  this.$el.parentElement?.offsetWidth -50;
                    this.offsetHeight =  this.$el.parentElement?.offsetHeight -200;
                    this.offsetHeight = this.offsetHeight > 500?this.offsetHeight:500;
                }
                else{
                    this.offsetHeight =  600;
                }
            },100)
        },
    }
</script>

<style scoped>

</style>
