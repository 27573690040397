/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class collection_item extends Table {
    constructor() {
        super();
        this.name = "collection_item";
        this.CNName = "项目收款记录";
        this.columnArray = [
            new Column("项目名称","itemName",ColumnType.String,true).setTableView(150),
            new Column("收款金额","collectionAmount",ColumnType.Number,true).setTableView(80),
            new Column("收款日期","collectionTime",ColumnType.Date,true).setTableView(80),
            new Column("付款方名称","payerName",ColumnType.String,true).setTableView(80),
            new Column("收款方名称","receiverName",ColumnType.String,true).setTableView(80),
            new Column("银行名称","bankName",ColumnType.String,true).setTableView(80),
            new Column("银行卡号","bankNum",ColumnType.String,true).setTableView(80),
        ];

    }
}