<!--
* @program: TYH_office_automation 
* @author: ly
* @component:personalAssets 我的设备一览/历史设备也在列
* @description: 
* @create: 2021-06-21 17:55
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed"  @click="showHistory">
                    <template #icon><SearchOutlined /></template>归还历史
                </a-button>
            </div>

                <ArBaseTable :argument="argument" :view-model="true" :page-size="15">
                    <template #action="{record}">
                        <div class="action-column">
                            <a-popconfirm
                                    v-if="record.useState === '0'"
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="归还设备？"
                                    @confirm="returnAssets(record)">
                                <a-button  class="action-button" type="link">
                                    归还
                                </a-button>
                            </a-popconfirm>
                        </div>
                    </template>
                </ArBaseTable>

        </div>
    </div>
    <a-drawer v-model:visible="visible"
              height="600px"
              placement="bottom"
              :closable="false"
              title="历史记录"
              :footer="null">
        <TcBaseTable :view-model="true" :page-size="15" :table-columns="tableColumns2" :data-source="dataSource2" >

        </TcBaseTable>
    </a-drawer>
</template>

<script>
    import device_my from "../../../assets/tables/device_my";
    import api_device_my from "../../../assets/api/myDevice";
    import api_device_back from "../../../assets/api/deviceBack";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import {SearchOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    export default {
        name: "personalAssets",
        components: {
            ArBaseTable,SearchBar,SearchOutlined,SyncOutlined
        },
        data(){
            return{
                table:new device_my(),
                searchData:null,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()

                    loading:false//table的loading状态
                },
                pageSize:15,
                queryOptions:{
                    // userId
                    // state 启用状态：0：停用，1：启用
                    // queryText 设备名称或编号，模糊查询
                    userId:null
                },
                searchOptions:[
                    new SearchSegment("关键字", "queryText")
                ],
                visible:false
            }
        },
        methods:{
            init(){
                this.queryOptions = {
                    userId: this.$store.getters.userInfo.id
                };
                this.argument.loading = true;
                api_device_my.queryAll.requestPOSTUrlParam(this,this.queryOptions,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                    this.argument.loading = false;
                });
            },
            refresh(){
                this.init()
            },
            returnAssets(record){
                let data = {
                    applyId:record.id
                };
                api_device_back.saveOrUpd.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            doSearch(){
                let data = {...this.searchData};
                api_device_my.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                    this.argument.loading = false;
                });
            },
            showHistory(){

            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>