<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentPicker 
* @description: 
* @create: 2022-04-11 15:58
-->
<template>
    <div style="margin: 10px">
<!--        <a-radio-group class="search-item" v-model:value="type">-->
<!--            <a-radio value="部门选择">部门选择</a-radio>-->
<!--        </a-radio-group>-->
<!--        <span>部门选择:</span>-->
        <a-select
            v-model:value="departmentId"
            @select="departmentChange"
            placeholder="部门选择"
            style="width: 250px"
            allow-clear
            class="search-item"
        >
            <a-select-option v-for="item in departments" :key="item.id">
                {{ item.name }}
            </a-select-option>
        </a-select>
    </div>
</template>

<script>
    import department from "../../assets/api/department";

    export default {
        name: "DepartmentPicker",
        props:["value","change"],
        emits:["update:value"],
        data(){
            return{
                type:"部门选择",
                departments:[],
                departmentId:null,
            }
        },
        methods:{
            departmentChange(){
                this.$emit("update:value",this.departmentId);
                this.$emit("change",this.departmentId);
            },
        },
        created() {
            this.departmentId = this.value;
            department.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.departments = res.data.data;
                }
                else{
                    this.departments = [];
                }
            })
        }
    }
</script>

<style scoped>

</style>