<!--
* @program: tyh-oa 
* @author: ly
* @component:menuViewer 菜单设定
* @description: 
* @create: 2021-05-20 11:01
-->
<template>
    <div style="height: 100%;width: 100%;background: white">
        <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
            <template #title>
                菜单设定
                <a-tooltip  title="添加大类">
                    <a-button class="search-item" type="dashed"  @click="addGroup">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                </a-tooltip>
                <a-tooltip  title="刷新">
                    <a-button class="search-item" type="dashed"  @click="refresh">
                        <template #icon><SyncOutlined /></template>
                    </a-button>
                </a-tooltip>
            </template>
        </a-page-header>
        <div style="width: 100%;height:calc(100% - 120px);display: flex;">
            <div style="width: 50%; height: 100%;min-width: 550px">
                <a-tree style="text-align: left;margin-left: 50px;height:100%;overflow-y:auto"
                            v-if="!loading"
                            :tree-data="treeData"
                            default-expand-all
                            :replace-fields="replaceFields"
                            showLine
                            :selectable="false"
                    >
                        <template #title="{dataRef}">
                            <div style="display: flex;height: 24px">
                                <div style="padding: 0 5px;height: 24px">【{{dataRef.treeName}}】</div>
                                <div v-if="dataRef.listOp && dataRef.listOp.length>0">权限：</div>
                                <div v-if="dataRef.listOp && dataRef.listOp.length>0">
                                    <a-tag v-for="item in dataRef.listOp" :key="item" color="blue">{{item.name}}</a-tag>
                                </div>
                                <a-button style="padding: 0 5px;height: 24px"  type="link" @click="menuAdd(dataRef)"><PlusOutlined /></a-button>
                                <a-button style="padding: 0 5px;height: 24px" type="link" @click="menuEdit(dataRef)"><EditOutlined /></a-button>
                                <a-popconfirm
                                        v-if="dataRef.children.length === 0"
                                        ok-text="是"
                                        cancel-text="否"
                                        title="确定删除吗?"
                                        @confirm="menuDelete(dataRef)">
                                    <a-button style="padding: 0 5px;height: 24px"  type="link"><DeleteOutlined  style="color: red" class="pointer"/></a-button>
                                </a-popconfirm>
                            </div>
                        </template>
                    </a-tree>
            </div>
            <div v-if="visible" style="width: 50%; height: 100%;overflow-y:auto">
                <a-page-header style="border: 1px solid rgb(235, 237, 240)"
                               :title="modalTitle"/>
                <a-form style="margin: 10px 100px" layout="horizontal"
                        :model="currentMenu">
                    <a-form-item label="菜单名">
                        <a-input style="width: 200px" v-model:value="currentMenu.title" />
                    </a-form-item>
                    <a-form-item label="组件名">
                        <a-input style="width: 200px" v-model:value="currentMenu.component" />
                    </a-form-item>
                </a-form>
                <a-button key="back" @click="visible = false">关闭</a-button>
                <a-button key="submit" type="primary"  @click="update">提交</a-button>
                <a-page-header v-if="operationVisible" style="border: 1px solid rgb(235, 237, 240)">
                    <template #title>
                        <div style="display: flex;">
                            <div>菜单权限设定(请勿随意修改)</div>
                            <div style="margin-left: 20px">
                                <MenuOpSet v-model:ops="currentOps" :menu-id="currentMenu.id"/>
                            </div>
                        </div>
                    </template>
                </a-page-header>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        DeleteOutlined,
        EditOutlined,
        PlusOutlined,
        SyncOutlined
    } from "@ant-design/icons-vue";
    import MenuOpSet from "./MenuOpSet";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import api_sys_menu from "../../../assets/api/menu";
    import sys_menu from "../../../assets/tables/sys_menu";
    export default {
        name: "menuViewer",
        components:{
            PlusOutlined,EditOutlined,
            DeleteOutlined,SyncOutlined,MenuOpSet
        },
        data(){
            return{
                loading:false,
                replaceFields :{
                    children: 'children',
                    title: 'treeName',
                    key:"treeId"
                },
                treeData:[],
                visible:false,
                currentMenu:{},
                modalTitle:null,
                operationVisible:false,
                operationTitle:"菜单权限",
                currentOps:[],
                columns:[
                    new Column("权限名","name",ColumnType.String,false).setTableView(100),
                    new Column("权限代码","opcode",ColumnType.String,false).setTableView(100),
                    new Column("操作","actions","actions",false).setTableView(100)
                ]
            }
        },
        methods:{
            init(){
                this.visible = false;
                this.loading = true;
                api_sys_menu.queryTreeOp.requestPOST(this,{},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.treeData = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            menuAdd(node){
                this.operationVisible = false;
                this.currentMenu = new sys_menu().getFormData();
                this.currentMenu.pid = node.treeId;
                this.visible = true;
                this.modalTitle = node.treeName+"——添加子菜单";
            },
            menuEdit(node){
                this.operationVisible = true;
                this.modalTitle = node.treeName+"——菜单编辑";
                this.currentMenu =
                    {
                        id:node.treeId,
                        pid:node.pid,
                        component:node.component,
                        title:node.treeName
                    };
                this.currentOps = node.listOp;
                this.visible = true;
            },
            menuDelete(node){
                let data = {id:node.treeId};
                api_sys_menu.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            addGroup(){
                this.currentMenu = new sys_menu().getFormData();
                this.visible = true;
                this.modalTitle = "添加新菜单"
            },
            refresh(){
                this.init()
            },
            update(){
                if(this.currentMenu.id){
                    api_sys_menu.update.requestPOST(this,this.currentMenu,res=>{
                        if(res.data.flag){
                            this.init();
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                else{
                    api_sys_menu.save.requestPOST(this,this.currentMenu,res=>{
                        if(res.data.flag){
                            this.init();
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }

            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>