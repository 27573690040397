/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_qc_apply2 质检申请管理 申请用
 * @create: 2021-04-25 11:19
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_qc_apply2 extends Table{
    constructor() {
        super();
        this.name = "item_qc_apply2";
        this.CNName = "质检申请管理";
        this.columnArray = [
            new Column("申请部门","departmentName",ColumnType.String,false).setTableView(100),
            new Column("质检类型","type",ColumnType.Enum,true).setTableView(80)
                .setEnum(["1","2","3"],["项目质检","任务质检","工序质检"],["blue","green","cyan"]),//质检类型（1：项目质检；2：任务质检；3：工序质检）
            new Column("项目","itemId",ColumnType.String,false)
                .setIdReplaceObject("itemName",{}),
            new Column("质检标题","title",ColumnType.String,true).setTableView(250),
            new Column("质检描述","content",ColumnType.String,true),
            new Column("申请时间","applyTime",ColumnType.Date,false),
            new Column("申请人","applyUser",ColumnType.String,false).setTableView(100)
                .setIdReplaceObject("applyUserName",{}),
            new Column("审核状态","auditState",ColumnType.String,false)
                .setEnum(["0","1","2"],["未审核","已通过","未通过"],["purple","green","red"]),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(60)];
    }
}