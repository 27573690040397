<!--
* @program: office_automation 
* @author: ly
* @component:MonthlyAssessment
* @description: 月度考核
* @create: 2022-01-14 10:22
-->
<template>
    <div style="height: 100%">
        <div class="search-bar">
            <span style="margin-left: 10px">查看方式：</span>
            <a-radio-group @change="radioChange" class="search-item" v-model:value="methodType">
                <a-radio value="user">按人</a-radio>
                <a-radio value="month">按时间</a-radio>
            </a-radio-group>
            <SearchBar v-if="methodType==='user'" :search-options="searchOptions" v-model:value="searchData"/>
            <a-button v-if="methodType==='user'" class="search-item" type="text" >
                选择部门
            </a-button>
            <a-select v-if="methodType==='user'" v-model:value="department" style="width: 250px"
                      class="search-item"
                      @change="departmentSelect">
                <a-select-option v-for="item in departments"  :key="item.id">
                    {{item.fullName}}
                </a-select-option>
            </a-select>
            <a-button v-if="methodType==='user'" @click="doSearch" class="search-item" shape="circle" type="dashed">
                <template #icon>
                    <SearchOutlined/>
                </template>
            </a-button>
            <a-button v-if="methodType==='user'" @click="refresh" class="search-item" shape="circle" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <MonthPicker @change="handleChange" v-if="methodType==='month'" style="width: 120px" v-model:value="yearMonth" />
            <a-button type="primary" style="margin-left:10px " :loading="loading" @click="modalShow" >生成月度考核表</a-button>
            <a-button v-if="methodType==='user'"  type="primary" style="margin-left:10px " @click="reportShow" >下载月度考核表</a-button>
            <a-button v-if="methodType==='month'" type="primary" style="margin-left:10px " @click="downloadAllReport" >下载当前月度考核表</a-button>
        </div>
        <TwoParts ref="TwoParts" :trans="[20,35]" :enable-change="false">
            <template #left>
                <ArBaseTableFilter   :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                    <template #action="{index,record}">
                        <div class = "action-column">
                            <a-button @click="showDetail(index,record)" class="action-button" type="link">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                        </div>
                    </template>
                </ArBaseTableFilter>
            </template>
            <template #right>
                <div v-if="record" >
                    <UserAssessment v-if="methodType==='user'" :record="record" :view-model="false" />
                    <MonthlyAssessmentViewer2 v-if="methodType==='month'" :view-model="true" user-type="user" :user-data="record"   />
                </div>
                <div v-else>
                    <welcome2 />
                </div>
            </template>
        </TwoParts>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="生成月度考核报告">
        <UserPicker v-model:value="users" />
        <MonthSelector style="margin: 5px" v-model:value="yearMonth" />
        <span style="margin: 20px">考核表类型：</span>
        <a-select v-model:value="type" style="width: 250px" >
            <a-select-option v-for="option in options" :key="option.id">{{option.name}}</a-select-option>
        </a-select>
        <a-button style="margin: 10px"  type="primary" @click="saveData">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             title="下载月度考核报告">
        <MonthSelector style="margin: 5px" v-model:value="yearMonth" />
        <UserPicker v-model:value="users" />
        <a-popconfirm
                v-if="users.length>0"
                ok-text="是"
                cancel-text="否"
                :title="'确定下载【'+yearMonth+'】的考核表吗?'"
                @confirm="downloadReport">
            <a-button style="margin: 10px" type="primary" >
                下载
            </a-button>
        </a-popconfirm>
    </a-modal>
</template>

<script>

    import userInfoManager from "../../userInfoManager/userInfoManager";
    import UserAssessment from "./UserAssessment";
    import MonthSelector from "../../../../components/SearchBar/MonthSelector";
    import assessmentUser from "../../../../assets/api/assessmentUser";
    import moment from "moment";
    import UserPicker from "../../../../components/UserPicker/UserPicker";
    import kpi from "../../../../assets/api/kpi";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import MonthPicker from "../../../_components/MonthPicker";
    import MonthlyAssessmentViewer2 from "./MonthlyAssessmentViewer2";

    export default {
        name: "MonthlyAssessment",
        mixins:[userInfoManager],
        components:{
            UserAssessment,MonthSelector,UserPicker,MonthPicker,MonthlyAssessmentViewer2,
        },
        computed:{
            userIds(){
                let ids = [];
                for(let i in this.users){
                    ids.push(this.users[i].id)
                }
                return ids;
            }
        },
        data(){
            return{
                users:[],
                loading:false,
                yearMonth:"",
                visible:false,
                titles:['选择', '生成考核表'],
                options:[
                    {id:"0",name:"试用期员工"},
                    {id:"1",name:"作业员"},
                    {id:"2",name:"生产部门负责人"},
                    {id:"3",name:"综合-市场专员"},
                    {id:"4",name:"综合-投标专员"},
                    {id:"5",name:"综合部负责人"},
                    {id:"6",name:"办公财务-工勤人员"},
                    {id:"7",name:"办公财务-财务人员"},
                    {id:"8",name:"人资部负责人"}
                ],
                type:"1",
                visible2:false,
                methodType:"user"
            }
        },
        methods:{
            showDetail(index,record){
                this.record = record;
            },
            modalShow(){
                this.yearMonth = moment(new Date().valueOf()).format("YYYY-MM");
                this.visible = true;
                this.users = [];
            },
            saveData(){
                let data={
                    type:this.type,
                    yearMonth:this.yearMonth,
                    userIds:this.userIds.toString()
                };
                assessmentUser.generate.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            reportShow(){
                this.yearMonth = moment(new Date().valueOf()).format("YYYY-MM");
                this.visible2 = true;
                this.users = [];
            },
            downloadReport(){
                for(let i in this.users){
                    let data = {
                        yearMonth:this.yearMonth,
                        userId:this.users[i].id
                    };
                    kpi.exportByUserMonth.fileDownloadGet(this,data,"月度考核报告【"+this.yearMonth+"-"+this.users[i].name+"】.xls");
                }
            },
            downloadAllReport(){
                for(let i in this.argument.dataSource){
                    let data = {
                        yearMonth:this.yearMonth,
                        userId:this.argument.dataSource[i].id
                    };
                    kpi.exportByUserMonth.fileDownloadGet(this,data,"月度考核报告【"+this.yearMonth+"-"+this.argument.dataSource[i].name+"】.xls");
                }
            },
            radioChange(){
                this.yearMonth = moment(new Date().valueOf()).format("YYYY-MM");
                switch (this.methodType) {
                    case "user":{
                        this.argument.tableColumns = [
                            new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                            new Column("姓名","name",ColumnType.String,true).setTableView(100),
                            new Column("状态","loginState",ColumnType.Enum,false)
                                .setEnum(["0","1","2"],["正式员工","试用期","实习生"],["cyan","green","pink"]).setTableView(80),
                            new Column("操作","actions","actions",false).setTableView(60)
                        ];
                        this.$refs.TwoParts.reset();
                        this.argument.dataSource = this.allUsers;
                        break;
                    }
                    case "month":{
                        this.$refs.TwoParts.transform();
                        this.argument.tableColumns = [
                            new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                            new Column("部门","depName",ColumnType.String,true).setTableView(100),
                            new Column("姓名","name",ColumnType.String,true).setTableView(100),
                            new Column("得分","score",ColumnType.Number,false).setTableView(60),
                            new Column("评级","level",ColumnType.String,false).setTableView(60),
                            new Column("情况","judgeEnd",ColumnType.Boolean,false).setTableView(60)
                            .setBoolTags(["未同意","已同意"]),
                            new Column("操作","actions","actions",false).setTableView(50)
                        ];
                        this.argument.dataSource = [];
                        this.handleChange();
                        break;
                    }
                }
            },
            handleChange(){
                let data = {
                    yearMonth:this.yearMonth
                };
                this.argument.loading = true;
                assessmentUser.queryScore.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        for(let i in res.data.data){
                            if(res.data.data[i].list.length > 0){
                                res.data.data[i].id = res.data.data[i].userId;
                                res.data.data[i].score = parseFloat(res.data.data[i].list[0].sumScore);
                                res.data.data[i].yearMonth = res.data.data[i].list[0].yearMonth;
                                res.data.data[i].judgeEnd = res.data.data[i].list[0].judgeEnd;
                                res.data.data[i].level = getLevel(res.data.data[i].score);
                            }
                        }
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                });
            }
        },
        mounted() {
            this.radioChange()
        }
    }
    function getLevel(score) {
        if(0 <= score && score<=10 ){return "一等"}
        else if (10 < score && score <= 20){return "二等"}
        else if (20 < score && score <= 30){return "三等"}
        else if (30 < score && score <= 40){return "四等"}
        else if (40 < score && score <= 50){return "五等"}
        else if (50 < score && score <= 60){return "六等"}
        else if (60 < score && score <= 70){return "七等"}
        else if (70 < score && score <= 80){return "八等"}
        else if (80 < score && score <= 90){return "九等"}
        else if (90 < score && score <= 100){return "十等"}
        else {return "数据错误"}
    }
</script>

<style scoped>

</style>