<!--
* @program: office_automation 
* @author: ly
* @component:projectTaskCategoryShower 
* @description: 已分配的项目任务统计浏览
* @create: 2021-12-30 11:50
-->
<template>
    <a-spin :spinning="spinning">
        <a-page-header style="border: 1px solid rgb(235, 237, 240);padding: 0 20px;">
            <template #title>
                <div class="center-flex">
                    已安排的项目任务（共{{totalSum}}个）
                    <a-button class="search-item" type="default" shape="circle" @click="init">
                        <template #icon><SyncOutlined /></template>
                    </a-button>
                    <a-popover trigger="hover" placement="bottom">
                        <template #content>
                            点击数字能够看到具体项目列表
                        </template>
                        <a-button  type="link" >
                            <template #icon><InfoCircleOutlined /></template>
                        </a-button>
                    </a-popover>
                </div>
            </template>
        </a-page-header>
        <div v-if="visible" style="display: flex;flex-wrap: wrap;">
            <a-card class="project-card" v-for="(item,key) in group" :loading="item.loading" :key="key">
                <template #title>
                    {{item.year?item.year+"年安排的项目任务":"未指定年份"}} ：<a-tag style="cursor: pointer" :color="'cyan'" @click="handleShow(item)">{{item.list.length}}</a-tag>个
                </template>
                <TagShow v-for="(tag,index) in item.groupData"   :color="tag.color" :tag="tag.label" :key="index">
                    <template #content>
                        <div style="display: flex;flex-wrap: wrap;justify-content: center;">
                            <div v-for="(list,idx) in tag.list" :key="idx" style="font-size: 8px;" class="center-flex">
                                <div v-if="list.list.length>0">
                                    {{list.label}}：<a-tag style="cursor: pointer" :color="list.color" @click="handleShow(list)">{{list.list.length}}个</a-tag> &nbsp;
                                </div>
                            </div>
                        </div>
                    </template>
                </TagShow>
            </a-card>
        </div>
        <div v-else>
            <a-divider orientation="right" style="margin: 0">
                <div style="display: flex"><a-button danger @click = "topReturn" >返回</a-button>
                </div>
            </a-divider>
            <ArBaseTableFilter  :argument="argument" :page-size="15" :view-model="true" :download="true">
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button class="action-button" type="link" @click="showDetail(index,record)">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button v-if="!viewModel" class="action-button" type="link" @click="editDetail(record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                v-if="!viewModel"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </ArBaseTableFilter>
        </div>
    </a-spin>
    <a-modal v-model:visible="modalVisible"
             width="600px"
             destroyOnClose
             :footer="null"
             title="详情">
        <FcForm :view-model="vModel" :form-data="formData" :form-columns="formColumns" >
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-modal>
</template>

<script>
    import api_item_task from "../../../assets/api/itemTask";
    import item_task from "../../../assets/tables/item_task";
    import moment from "moment";
    import {SearchOutlined,SyncOutlined,InfoCircleOutlined,EditFilled,DeleteOutlined} from "@ant-design/icons-vue"
    import TagShow from "../../_components/TagShow";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import api_sys_department from "../../../assets/api/department";
    import {getTagColor} from "../../../assets/system/tagColors";
    import FcForm from "../../../components/form/FcForm";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import userInfo from "../../../assets/api/userInfo";
    export default {
        name: "projectTaskCategoryShower",
        components:{
            ArBaseTableFilter,SearchOutlined,TagShow,InfoCircleOutlined,
            SyncOutlined,FcForm,EditFilled,DeleteOutlined
        },
        props:["viewModel"],
        data(){
            return{
                statisticList:[{}],
                group:[new statisGroup(0,[]),],
                spinning:false,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                visible:true,
                modalVisible:false,
                totalSum:0,
                departments:[],
                formData:null,
                formColumns:[],
                vModel:true,
            }
        },
        methods:{
            init(){
                this.visible = true;
                this.statisticList = [{}] ;
                this.group=[new statisGroup(0,[])];
                this.argument.tableColumns = new item_task().getTableColumns();
                this.formColumns =[
                    new Column("任务标题","taskTitle",ColumnType.String,true),
                    new Column("任务内容","content",ColumnType.String,true),
                    new Column("分配部门","departmentId",ColumnType.RemoteEnum,true)
                        .setRemoteObject("departmentName",{api:api_sys_department.queryAll,tag:"queryText"}),
                    new Column("任务负责人","taskLeader",ColumnType.IdReplace,false)
                        .setIdReplaceObject("taskLeaderName",{api:userInfo.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
                    new Column("任务开始时间","startDate",ColumnType.Date,true),
                    new Column("预计完成时间","endDate",ColumnType.Date,true),
                    new Column("备注","remarks",ColumnType.String,true),
                    new Column("进度","completeRatio",ColumnType.Number)
                        .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                    new Column("实际完成时间","completeDate",ColumnType.Date),
                    new Column("完成状态","state",ColumnType.Enum)
                        .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"]),
                    new Column("完成情况说明","describe",ColumnType.String),
                    new Column("下发部门绩效","money",ColumnType.Number,true).setDefaultValue(0),
                    new Column("绩效分配确认","moneyConfirm",ColumnType.Boolean,true).setDefaultValue(false),
                    new Column("作业绩效", "depManagExp", ColumnType.Number, false).setDefaultValue(0).setTableView(100),
                    new Column("负责人绩效", "leaderPercent", ColumnType.Number, false).setDefaultValue(0).setTableView(100),
                ];
                api_sys_department.queryAll.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.departments=res.data.data;
                        this.spinning = true;
                        api_item_task.queryAll.requestPOST(this,{},res=>{
                            if(res.data.flag){
                                this.groupYears(res.data.data);
                                this.totalSum = res.data.data.length;
                            }
                            this.spinning = false;
                        });
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            groupYears(data){
                for(let i in data){
                    let startTime = data[i].startDate;
                    let year ;
                    if(startTime){
                        year = moment(startTime).locale('zh-cn').format('YYYY');
                        year = parseInt(year);
                    }
                    else{
                        year = 0
                    }
                    let arr = this.group.filter(item => item.year === year);
                    if(arr.length > 0){
                        arr[0].list.push(data[i]);
                    }
                    else{
                        this.group.push(new statisGroup(year,[data[i]]))
                    }
                }
                this.group.sort((a,b)=>{
                    return a.year - b.year;
                });
                if(this.group[0].list.length === 0){
                    this.group.shift();
                }
                this.statisticGroup();
            },

            statisticGroup(){
                for(let i in this.group){
                    this.group[i].groupData.push(new tagShowList("分配部门", "orange", []));
                    for(let j in this.departments){
                        this.group[i].groupData[0].list.push(
                            new tagShowList(this.departments[j].name,getTagColor(this.departments[j].name),[]),
                        )
                    }
                    this.group[i].groupData.push(new tagShowList("工作完成情况", "cyan", [
                        new tagShowList("按时完成","green",[]),
                        new tagShowList("提前完成","blue",[]),
                        new tagShowList("延期完成","red",[]),
                        new tagShowList("未完成","purple",[]),
                    ]));
                }
                for(let i in this.group){
                    for(let j in this.group[i].list){
                        /**分配部门*/
                        for(let k in this.departments){
                            let name = this.departments[k].name;
                            if(this.group[i].list[j].departmentName === name){//项目合同状态
                                this.group[i].groupData[0].list.filter(item=>item.label===name)[0].list.push(this.group[i].list[j])
                            }
                        }
                        /**完成情况
                         state = "1";["0","1","2"],["按时完成","提前完成","延期完成"]*/
                        if(this.group[i].list[j].state === "0"){
                            this.group[i].groupData[1].list[0].list.push(this.group[i].list[j]);
                        }
                        else if(this.group[i].list[j].state === "1"){
                            this.group[i].groupData[1].list[1].list.push(this.group[i].list[j]);
                        }
                        else if(this.group[i].list[j].state === "2"){
                            this.group[i].groupData[1].list[2].list.push(this.group[i].list[j]);
                        }
                        else{
                            this.group[i].groupData[1].list[3].list.push(this.group[i].list[j]);
                        }
                    }
                }
            },
            handleShow(data){
                if(data.list.length > 0 ){
                    this.visible = false;
                    this.argument.dataSource = data.list;
                }
                else{
                    this.$message.info("没有数据")
                }
            },
            showDetail(index,record){
                this.modalVisible = true;
                this.formData = record;
                this.vModel = true;
            },
            editDetail(record){
                this.modalVisible = true;
                this.formData = record;
                this.vModel = false;
            },
            topReturn(){
                this.visible = true;
                this.argument.currentIndex = null;
            },
            saveData(record){
                api_item_task.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.modalVisible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                api_item_task.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        created() {
            this.init();
        }
    }
    class statisGroup{
        constructor(year,list) {
            this.year=year;
            this.list=list;
            this.loading=false;
            this.groupData=[];
        }
    }
    class tagShowList{
        constructor(label,color,list,visible) {
            this.label = label;
            this.color = color;
            this.list = list;
            this.visible = visible !== false;
        }
    }
</script>

<style scoped>

</style>