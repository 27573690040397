<!--
* @program: tyh-oa 
* @author: ly
* @component:billStatisticsByProject
* @description: 按时间统计账单
* @create: 2021-06-11 11:33
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <div style="margin: 8px;font-weight: 900;font-size: 16px"> 统计方式：</div>
                <a-select v-model:value="type" style="width: 200px" class="search-item" @select="selectChange">
                    <a-select-option v-for="{seg,label} in options" :key="seg" >
                        {{label }}
                    </a-select-option>
                </a-select>
                <div v-is="currentComp" ref="i_selector"/>
                <a-button class="search-item" style="margin-left: 20px" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-tooltip v-if="visible" :key="'#fff'" title="下载为Excel">
                    <a-button class="search-item" type="dashed" shape="circle"  @click="downLoadExcel" >
                        <template #icon><DownloadOutlined style="color: red" /></template>
                    </a-button>
                </a-tooltip>
                <span v-if="visible">合计：{{sum.toFixed(2)}}</span>
            </div>

                <projectChart v-if="visible" :setting="setting"/>
                <div style="margin:10% 30%;font-size: 36px" v-else></div>

        </div>
    </div>
</template>

<script>
    import billStatisticsToolBar from "./billStatisticsToolBar";
    import projectChart from "./projectChart";
    import api_fundSummary from "../../../assets/api/fundSummary"
    import {PlusOutlined, SearchOutlined, SyncOutlined,InfoCircleOutlined,DownloadOutlined} from "@ant-design/icons-vue";
    import monthSelector from "../../../components/toolBar/monthSelector";
    import yearSelector from "../../../components/toolBar/yearSelector";
    import periodSelector from "../../../components/toolBar/periodSelector";
    import excelJS from "exceljs";
    export default {
        name: "billStatisticsByProject",
        components:{
            billStatisticsToolBar,projectChart,InfoCircleOutlined,DownloadOutlined,
            SearchOutlined,PlusOutlined,SyncOutlined,monthSelector,yearSelector,periodSelector
        },
        computed:{
            sum(){
                let sum = 0;
                for(let i in this.resData){
                    sum += this.resData[i].applyAmount;
                }
                return sum;
            }
        },
        data(){
            return{
                visible:false,
                setting:{
                    legend:{
                        data: ['有发票金额', '无发票金额']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: []
                        }
                    ],
                    yAxis:[
                        {
                            type: 'value'
                        }
                    ],
                    series:[
                        {
                            name: '有发票金额',
                            type: 'bar',
                            stack: '总消耗',
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: '无发票金额',
                            type: 'bar',
                            stack: '总消耗',
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                    ]
                },
                options:[
                    {seg:"month",label:"按月统计",comp:"monthSelector"},
                    {seg:"year",label:"按年统计",comp:"yearSelector"},
                    //{seg:"period",label:"按时间段统计",comp:"periodSelector"}
                ], //[{seg:"title",label:"账单标题",type:"input"}]
                currentComp:"monthSelector",
                type:"month",
                queryTime:null,
                resData:[]
            }
        },
      created() {
         setTimeout(()=>{
           this.doSearch();
         },500);
      },
      methods:{
            doSearch(){
                this.setting = {
                    legend:{
                        data: ['有发票金额', '无发票金额']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: []
                        }
                    ],
                    yAxis:[
                        {
                            type: 'value'
                        }
                    ],
                    series:[
                        {
                            name: '有发票金额',
                            type: 'bar',
                            stack: '总消耗',
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: '无发票金额',
                            type: 'bar',
                            stack: '总消耗',
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                    ]
                };
                this.visible = false;
                this.queryTime = this.$refs.i_selector.getValue();
                switch (this.type) {
                    case "month":{
                        let data = {yearMonth:this.queryTime};
                        api_fundSummary.statiByMonth.requestPOSTUrlParam(this,data,res=>{
                            if(res.data.flag){
                                this.visible=true;
                                this.applySetting(res.data.data);
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                        break;
                    }
                    case "year":{
                        let data = {year:this.queryTime};
                        api_fundSummary.statiByYear.requestPOSTUrlParam(this,data,res=>{
                            if(res.data.flag){
                                this.visible=true;
                                this.applySetting(res.data.data);
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                        break;
                    }
                }
            },
            applySetting(data){
                this.resData = [];
                let sortData = data.sort((a,b)=>{
                    let suma = a.haveInvoiceAmount + a.noInvoiceAmount;
                    let sumb = b.haveInvoiceAmount + b.noInvoiceAmount;
                    return suma - sumb
                });
                for(let i in sortData){
                    let name = sortData[i].itemName?sortData[i].itemName:sortData[i].itemId;
                    let a = sortData[i].haveInvoiceAmount;
                    let b = sortData[i].noInvoiceAmount;
                    if(name !== "非项目支出"){//删除非项目支出
                        this.setting.xAxis[0].data.push(name);
                        this.setting.series[0].data.push(a);
                        this.setting.series[1].data.push(b);
                        this.resData.push(sortData[i]);
                    }
                }
            },
            downLoadExcel(){
                let workbook = new excelJS.Workbook();
                let worksheet = workbook.addWorksheet(this.queryTime);
                worksheet.columns  = [
                    {header:"项目名称",key:"itemName",width: 10},
                    {header:"报账额",key:"applyAmount",width: 10},
                ];
                for(let i in this.resData){
                    let row = parseInt(i) + 2;
                    worksheet.getRow(row).values = this.resData[i];
                }
                workbook.xlsx.writeBuffer().then((data)=>{
                    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const blob = new Blob([data], {type: EXCEL_TYPE});
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(blob) ;// 获取当前文件的一个内存URL
                    tagA.download = this.queryTime +"项目消耗统计.xlsx" ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                });
            },
            selectChange(seg){
                let option = this.options.filter(op=>{ return op.seg === seg})[0];
                this.currentComp = option.comp;
            }
        }
    }
</script>

<style scoped>

</style>