<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityCheckResult 
* @description: 查看质检结果
* @create: 2021-07-12 11:43
-->
<template>
    <div v-if="hasResult">
        <ArBaseTable :argument="argument" :view-model="true" :page-size="15" />
    </div>
    <div v-else>项目还没有质检报告</div>
</template>

<script>
    import item_qc_report_apply from "../../../assets/tables/item_qc_report_apply";
    import api_item_qc_report_apply from "../../../assets/api/qcReportApply";
    import ArBaseTable from "../../../components/table/ArBaseTable";

    export default {
        name: "qualityCheckResult",
        props:{
            projectId:{required:true},
        },
        components:{
            ArBaseTable
        },
        data(){
            return{
                table:new item_qc_report_apply(),
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                hasResult:false,
            }
        },
        methods:{
            init(){
                let data = {itemId:this.projectId};
                this.argument.formColumns =  this.table.getFormColumns();
                api_item_qc_report_apply.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                        this.hasResult = true
                    }
                    else{
                        this.argument.dataSource = [];
                        this.hasResult = false
                    }
                });
            },
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>