<!--
* @program: tyh-oa 
* @author: ly
* @component:userInfoEdit 
* @description: 
* @create: 2021-05-25 15:44
-->
<template>
    <a-card class="user-card">
        <ArBaseForm :view-model="false" :argument="argument" >
            <template #action="{record}">
                <a-button type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-card>
</template>

<script>
    import api_sys_user_info from "../../../assets/api/userInfo";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";

    export default {
        name: "userInfoEdit",
        components:{
            ArBaseForm
        },
        data(){
            return{
                argument:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                }
            }
        },
        methods:{
            saveData(record){
                api_sys_user_info.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            init(){
                this.argument.formData=this.$store.getters.userInfo;
                this.argument.formColumns = [
                    new Column("头像","headUrl",ColumnType.Avatar,true),
                    new Column("用户姓名","name",ColumnType.String,true),
                    // new Column("手机号","phone",ColumnType.String,true)
                    //     .setTriggerOtherColumn(null,
                    //         null,
                    //         (formData,cols)=>{formData["loginAccount"] = formData["phone"]}),
                    new Column("电子邮箱","emails",ColumnType.String,true)
                ]
            }
        },
        created() {
            this.init();
        }

    }
</script>

<style scoped>

</style>