<!--
* @program: office_automation 
* @author: ly
* @component:MySoftware 
* @description: 软件工具
* @create: 2022-05-24 11:36
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
                <template #title>
                    <div style="display: flex;">
                        <span >软件列表</span>
                        <a-tooltip :key="'#fff'" title="刷新">
                            <a-button class="search-item" type="dashed"  @click="refresh">
                                <template #icon><SyncOutlined /></template>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </a-page-header>
            <TcScrollTable :page-size="15" :scrolls="{y:600}" :data-source="dataSource" :loading="loading"
                           :table-columns="tableColumns" :view-model="true" >
                <template #action="{record}">
                    <div style="text-align: center">
                        <a-button  class="action-button" type="link" @click="download(record)">
                            <DownloadOutlined class="pointer"/>下载
                        </a-button>
                    </div>
                </template>
            </TcScrollTable>
        </div>
    </div>
</template>

<script>
    import TcScrollTable from "../../../components/table/TcScrollTable";
    import { DownloadOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import softwareTools from "../../../assets/api/softwareTools";
    import {GetCurrentFileUrl} from "../../../assets/utils/general";
    import software from "../../../assets/tables/software";

    export default {
        name: "MySoftware",
        components:{
            TcScrollTable,DownloadOutlined,SyncOutlined,
        },
        data() {
            return {
                tableColumns:[],
                dataSource:[],
                loading:false,
                visible:false
            }
        },
        methods: {
            init() {
                this.loading = true;
                softwareTools.queryAll.requestPOST(this,{},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else {
                        this.dataSource = [];
                    }
                })
            },
            refresh(){
                this.init()
            },
            async download(record){
                let url = GetCurrentFileUrl(record.fileUrl);
                let fileName = record.fileUrl.split("/").pop();
                let response = await fetch(url); // 内容转变成blob地址
                let blob = await response.blob();  // 创建隐藏的可下载链接
                let objectUrl = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                a.click();
                a.remove();
            },
        },
        created() {
            this.tableColumns = new software().getTableColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>