<!--
* @program: tyh-oa 
* @author: ly
* @description: welcomePosition
* @create: 2021-03-22 18:15
-->
<template>
    <div style="height: 100%;overflow: hidden">
        <div style="font-size: 60px;margin-top: 300px" class="non-select">
            {{str}}
        </div>
        <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
            版本：{{$version}}
        </div>
    </div>
</template>

<script>
    import userExtra from "../assets/api/userExtra";
    export default {
        name: "welcomePosition",
        data(){
            return{
                str:"",
            }
        },
        created() {
            let data = {
                userId: this.$store.getters.userInfo.id
            };
            userExtra.queryAll.requestPOST(this,data,res=>{
                if(res.data.flag){
                    this.extra = res.data.data[0];
                    this.str = "您的当前职位为："+res.data.data[0].positionLevel;
                }
                else{
                    this.str = "还未设定职位";
                }
            })
        }
    }
</script>

<style scoped>

</style>