<!--
* @program: office_automation 
* @author: ly
* @component:projectTaskInfo
* @description: 项目任务分配，现在每个项目都只分配给一个部门，所以不需要使用table来展示
* @create: 2021-12-15 15:21
-->
<template>
    <a-spin :spinning="spinning">
        <div v-if="ifNew" style="margin-top:15vh">
            <div v-if="viewModel" style="font-size: 40px;" class="non-select">本项目还未下达部门任务。</div>
            <div v-else style="font-size: 40px;" class="non-select">本项目还未下达部门任务，请先分配任务。</div>
            <div v-if="!viewModel" style="font-size: 40px;" class="non-select">分配完毕之后不能随意修改，请确认无误之后再提交</div>
            <a-divider v-if="!viewModel"/>
            <a-button v-if="!viewModel" type="primary"  @click="ifNew = false">
                分配任务
            </a-button>
        </div>
        <div v-else>
            <TagShow  :visible="true" color="green" tag="主任务">
                <template #content>
                    <div style="display: flex;justify-content: center;">
                        <TagShow style="width: 400px" :tag="'任务概况'" :color="'cyan'">
                            <template #content>
                                <div v-if="record" style="margin: 5px">
<!--                                    <span>创建部门：</span><a-tag color="blue">{{record.departmentName}}</a-tag><br/>-->
                                    <span>项目类型：</span><a-tag color="blue">{{itemTypeNames(record.itemType)}}</a-tag>
                                </div>
                                <FcForm :view-model="viewModel" v-model:form-data="formData" :form-columns="formColumns1"/>
                            </template>
                        </TagShow>
                        <a-divider v-if="assVisible" style="height: 300px;background-color: #022d5c;" type="vertical"/>
                        <TagShow style="width: 400px" v-if="assVisible" :tag="'绩效分配'" :color="'red'">
                            <template #content>
                                <FcForm :view-model="viewModelSp" v-model:form-data="formData" :form-columns="formColumns3"/>
                                <a-button v-if="!viewModelSp"  @click="setMoney(0.15)">合同额15%</a-button>
                                <a-button v-if="!viewModelSp"  @click="setMoney(0.015)">合同额1.5%</a-button>
                                <TagShow v-if="record && record.itemType==='1'" color="pink" :tag="contractInfoDTO?'甲方合同信息':'未签订甲方合同'">
                                    <template #content>
                                        <div style="padding: 8px" v-if="contractInfoDTO">
                                            签订公司：{{contractInfoDTO.sigCompanyName?contractInfoDTO.sigCompanyName:"签订公司数据错误"}}
                                            <br/>
                                            甲方：{{contractInfoDTO.associatedName?contractInfoDTO.associatedName:"甲方数据错误"}}
                                            <br/>
                                            合同金额：{{contractInfoDTO.amount?contractInfoDTO.amount:"未填入合同金额"}}
                                        </div>
                                    </template>
                                </TagShow>
                            </template>
                        </TagShow>
                        <a-divider v-if="assVisible && viewModel" style="height: 300px;background-color: #022d5c;" type="vertical"/>
                        <TagShow style="width: 400px" v-if="assVisible && viewModel" :tag="'完成情况'" :color="'green'">
                            <template #content>
                                <FcForm :view-model="true" v-model:form-data="formData" :form-columns="formColumns2" />
                                <span>任务总体完成进度为:【主任务】和【子任务】合并计算的整体进度</span>
                                <div>【主任务】自身进度可在【任务工序】中查看</div>
                            </template>
                        </TagShow>
                    </div>
                </template>
            </TagShow>
            <TagShow v-if="viewModel" :visible="true" color="red" tag="子任务">
                <template #content>
                    <TcBaseTable :table-columns="tableCols" :data-source="subTasks" :view-model="true" :page-size="3" />
                </template>
            </TagShow>
            <a-divider v-if="!viewModel" />
            <a-button v-if="!viewModel" type="primary"  @click="dataSave">
                提交
            </a-button>
        </div>

    </a-spin>
</template>

<script>
    import item_task from "../../../assets/tables/item_task";
    import api_item_task from "../../../assets/api/itemTask";
    import FcForm from "../../../components/form/FcForm";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import userInfo from "../../../assets/api/userInfo";
    import TagShow from "../../_components/TagShow";
    import api_sys_department from "../../../assets/api/department";
    import api_item_info from "../../../assets/api/itemInfo";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    export default {
        name: "projectTaskInfo",
        props:["projectId","viewModel","record"],
        components:{
            FcForm,TagShow,TcBaseTable
        },
        data(){
            return{
                table:null,
                tableCols:[
                    new Column("序号","_index",ColumnType.Index).setTableView(60),
                    new Column("任务标题","taskTitle",ColumnType.String).setTableView(200),
                    new Column("分配部门","departmentName",ColumnType.String).setTableView(200),
                    new Column("任务负责人","taskLeaderName",ColumnType.String).setTableView(100),
                    new Column("工作量占比","ratio",ColumnType.Number).setTableView(100)
                        .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                    new Column("任务开始时间","startDate",ColumnType.Date).setTableView(100),
                    new Column("预计完成时间","endDate",ColumnType.Date).setTableView(100),
                    new Column("进度","completeRatio",ColumnType.Number).setTableView(100)
                        .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                ],
                formColumns1:[
                    new Column("任务标题","taskTitle",ColumnType.String,true),
                    new Column("任务内容","content",ColumnType.String,true),
                    new Column("分配部门","departmentId",ColumnType.RemoteEnum,true)
                        .setRemoteObject("departmentName",{api:api_sys_department.queryAll,tag:"queryText"}),
                    new Column("任务负责人","taskLeader",ColumnType.IdReplace,false)
                        .setIdReplaceObject("taskLeaderName",{api:userInfo.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
                    new Column("任务开始时间","startDate",ColumnType.Date,true),
                    new Column("预计完成时间","endDate",ColumnType.Date,true),
                    new Column("备注","remarks",ColumnType.String,true),
                ],
                formColumns2:[
                    new Column("进度","completeRatio",ColumnType.Number)
                        .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                    new Column("实际完成时间","completeDate",ColumnType.Date,true),
                    new Column("完成状态","state",ColumnType.Enum,true)
                        .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"]),
                    new Column("完成情况说明","describe",ColumnType.String,true),
                ],
                formColumns3:[
                    new Column("下发部门绩效","money",ColumnType.Number,true).setDefaultValue(0),
                    new Column("绩效分配确认","moneyConfirm",ColumnType.Boolean,true).setDefaultValue(false),
                ],
                formData:{},
                subTasks:[],
                assVisible:false,
                ifNew:true,
                spinning:false,
                contractInfoDTO:null, //合同信息
                viewModelSp:false,

            }
        },
        methods:{
            init(){
                this.spinning = true;
                this.ifNew = true;
                this.table = new item_task();
                let data = {itemId:this.projectId};
                api_item_task.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        let mainTask = res.data.data.filter(item=>item.pid==="0");//pid=0为主任务
                        this.subTasks = res.data.data.filter(item=>item.pid !=="0");//pid=0为主任务
                        if(mainTask.length === 1){
                            this.formData = mainTask[0];
                            this.assVisible = true;
                            this.ifNew = false;
                            if(this.formData.money){
                                if(this.viewModel){
                                    this.viewModelSp = this.viewModel;
                                }
                                else{
                                    let arr = ["45","19"];
                                    this.viewModelSp = arr.indexOf(this.$store.getters.userInfo.id) === -1;
                                }
                            }
                            else{
                                this.viewModelSp = this.viewModel;
                            }
                        }
                        else{
                            this.$message.error("本项目已经下达"+ mainTask.length+"次，不符合系统设计要求，请联系管理员");
                            this.formData = this.table.getFormData();
                            if(this.record){
                                this.formData.taskTitle = this.record.name;
                                this.formData.startDate = this.record.startTime;
                                this.formData.endDate = this.record.endDate;
                            }
                        }
                    }
                    else{
                        this.formData = this.table.getFormData();
                        if(this.record){
                            this.formData.taskTitle = this.record.name;
                            this.formData.startDate = this.record.startTime;
                            this.formData.endDate = this.record.endDate;
                        }
                    }
                });
                let data2 = {
                    id:this.projectId
                };
                api_item_info.queryOne.requestPOSTUrlParam(this,data2,res=>{
                    this.spinning =false;
                    if(res.data.flag){
                        this.contractInfoDTO = res.data.data.contractInfoDTO;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            dataSave(){
                this.formData.itemId = this.projectId;
                api_item_task.saveOrUpd.requestPOST(this,this.formData,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            setMoney(num){
                if(this.contractInfoDTO && this.contractInfoDTO.amount){
                    this.formData.money = this.contractInfoDTO.amount * num
                }
                else{
                    this.$message.error("无甲方合同额信息，请预估绩效额")
                }

            },
          itemTypeNames(type){
              let typeName = "";
            switch (type){
              case "0":{
                typeName = "自研项目";
                break;
              }
              case "1":{
                typeName = "甲方项目";
                break;
              }
              case "2":{
                typeName = "劳务协作项目";
                break;
              }
              case "3":{
                typeName = "三方项目";
                break;
              }
            }
            return typeName;
          },
        },
        created() {
            this.init()
        },

    }
</script>

<style scoped>

</style>