<!--
* @description：项目设计书
* @author:rzl
* @date:2022/7/12 10:08
-->
<template>
    <a-button v-if="!viewModel" class="search-item" type="dashed" shape="circle" @click="addData">
        <template #icon><PlusOutlined /></template>
    </a-button>
    <a-spin :spinning="loading" tip="正在查询数据库...">
        <div v-if="dataSource.length>0" class="center-flex" style="display: flex;flex-wrap: wrap;justify-content: center;">
            <a-card v-for="(item,idx) in dataSource" :key="idx" style="width: 400px">
                <FcForm style="width: 350px" :form-columns="getFormColumns(item)" :form-data="item" :view-model="true" >
                    <template #action2>
                        <a-button class="action-button" type="link" @click="showDetail(item)">
                            <SearchOutlined class="pointer" title="查看"/>
                        </a-button>
                        <a-button v-if="!viewModel && (item.approveState == '0' || item.approveState == '2') " class="action-button" type="link" @click="editDetail(item)">
                            <EditFilled class="pointer" title="编辑"/>
                        </a-button>
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                v-if="!viewModel && (item.approveState == '0' || item.approveState == '2') "
                                title="确定删除吗?"
                                @confirm="deleteData(idx,item)">
                            <DeleteOutlined  style="color: red" class="pointer" title="删除"/>
                        </a-popconfirm>
                    </template>
                </FcForm>
            </a-card>
        </div>
        <div v-else style="font-size: 20px">还未添加设计书</div>
    </a-spin>
    <a-modal
            width="600px"
            :visible="modalVisible"
            :footer="null" :title="title"
            destroyOnClose
            :maskClosable="vModel"
            @cancel="modalVisible=false">
        <ArBaseForm :argument="formArg" :view-model="vModel" :span-col="true" >
            <template #action="{record}">
                <a-button type="primary" @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
</template>

<script>
    import {PlusOutlined,SearchOutlined,EditFilled,DeleteOutlined} from "@ant-design/icons-vue"
    import projectDesignBook_item from "../../../assets/tables/projectDesignBook_item";
    import ItemDoc from "../../../assets/api/ItemDoc";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import FcForm from "../../../components/form/FcForm";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {companyColors, companyIds, companyNames} from "../../../assets/system/companies";
    export default {
        name: "projectDesignBook",
        props:["projectId","viewModel"],
        components:{
            PlusOutlined,ArBaseForm,FcForm,
            SearchOutlined,EditFilled,DeleteOutlined
        },
        data(){
            return{
                table:new projectDesignBook_item(),
                modalVisible:false,
                title:null,
                formArg:{
                    formColumns:null, //提供Form的字段集
                    formData:null,//提供Form的formData
                },
                loading:false,
                formColumns:null,
                dataSource:[],//提供table的DataSource
                queryOptions:{
                    // "itemId": "string",
                    // "docName": "string",
                    // "approveState": "string",
                    // "approveUser": "string",
                },
                vModel:false,
            }
        },
        methods:{
            init(){
                this.formArg.formColumns = this.table.getFormColumns();
                this.formColumns = [];
                this.queryOptions={
                    "itemId": this.projectId,
                    "docName": "",
                    "approveState": "",
                    "approveUser": "",
                };
                this.loading = true;
                ItemDoc.queryAll.requestPOST(this,this.queryOptions,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
          getFormColumns(item){
            return [
              new Column("文档名称","docName",ColumnType.String),
              new Column("描述说明","describe",ColumnType.String,true),
              new Column("审核状态","approveState",ColumnType.Enum,false).setTableView(80)
                  .setEnum(["0","1","2"],["未审核","通过","不通过"],["cyan","green","red"]),
              new Column("审核意见","approveRemarks",ColumnType.String,true),
              new Column("文件","docFile",ColumnType.PreViewFile,true),
            ]

          },
            showDetail(record){
                this.title="数据详情";
                this.formArg.formData = record;
                this.modalVisible = true;
                this.vModel = true;
            },
            editDetail(record){
                this.title="数据详情";
                this.formArg.formData = record;
                this.modalVisible = true;
                this.vModel = false;
            },
            deleteData(index,record){
                let data={id:record.id};
                ItemDoc.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                ItemDoc.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.modalVisible = false;
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addData(){
                this.title = "添加新设计书";
                this.formArg.formData = this.table.getFormData();
                this.formArg.formData.itemId = this.projectId;
                this.modalVisible = true;
                this.vModel = false;
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>