

/**
 * @program: tyh-oa
 * @author: ly
 * @decription: 字段结构
 * @create: 2020-08-25 16:38
 **/

import ColumnType from "./column_type";
import moment from "moment";
import {uuid} from "../../utils/general";

class Column{
    /**
     * @param title 表头显示
     * @param dataIndex 对应dataObject内的属性字段/segment
     * @param type  字段类型/用于区别customRender
     * @param editable 是否允许编辑
     * @param visible 是否可见
     * @param enums type 为Enum时候的枚举数据
     * @param enumTags 当Enum类型时候，用于代替Enum值的TAG，
     * 如：数据库中["0","1"]类型,但是显示的时候却显示["未归档","已归档"],需要与enums一一对应
     * @param width 字段宽度
     * @param defaultValue  字段默认值
     * @param tableView true的情况为table的时候所显示的字段
     * @param idReplaceObject 用于idReplace的字段属性
     * @param boolTags 用于标记true 和 false的情况下 显示的文字，["是","否"]
     * @param replaceObject 用于ObjectReplace的字段属性
     * @oaram sorter: (a, b) => a.age - b.age,
     * @param categoryTrigger  字段触发器 [trigger,trigger2] true:trigger  false:trigger2
     * @param group  有时候需要将字段分区展示，用于区分字段的标记  默认group = 0
     * @children 表头分组使用
     * @param enableTime //用于设置可选时间
     */
    constructor (title,dataIndex,type,editable) {
        this.ref = "ref_"+uuid(8,16);//唯一标签  需要强制初始化的时候使用
        this.title = title;
        this.dataIndex = dataIndex;
        this.slots = new Slots(type);
        this.type = type;
        this.editable = !!editable;
        this.visible = true;
        this.width = 200;
        this.defaultValue = null;
        this.idReplaceObject = null;
        this.ellipsis = true;
        this.group = 0;
        if(type === ColumnType.Number){
            this.sorter = (a, b) =>{
                let as = getDeepObjectValue(a,this.indexArr);
                let bs = getDeepObjectValue(b,this.indexArr);
                return  (as?as:0) - (bs?bs:0);
            }
        }
        if(type === ColumnType.Date){
            this.sorter = (a ,b) =>{
                let as = getDeepObjectValue(a,this.indexArr);
                let bs = getDeepObjectValue(b,this.indexArr);
                as = as?moment(as):moment("1970-1-1");
                bs = bs?moment(bs):moment("1970-1-1");
                return  as.isAfter(bs)
            }
        }
        if(type === ColumnType.Month){
            this.sorter = (a ,b) =>{
                let as = getDeepObjectValue(a,this.indexArr);
                let bs = getDeepObjectValue(b,this.indexArr);
                as = as?moment(as):moment("1970-1-1");
                bs = bs?moment(bs):moment("1970-1-1");
                return  as.isAfter(bs)
            }
        }
        if(type === ColumnType.String){
            this.onFilter= (value, record) =>{
                let val = getDeepObjectValue(record,this.indexArr);
                if(val){
                    return val.includes(value)
                }
                else{
                    return false
                }

            };
            // this.sorter = (a ,b) =>{
            //     let as = a[this.dataIndex]?a[this.dataIndex]:"z";
            //     let bs = b[this.dataIndex]?b[this.dataIndex]:"z";
            //     return as.localeCompare(bs)
            // }
        }
        if(type === ColumnType.Enum){
            this.sorter = (a ,b) =>{
                let as = getDeepObjectValue(a,this.indexArr);
                let bs = getDeepObjectValue(b,this.indexArr);
                let indexA = this.enums.indexOf(as);
                let indexB = this.enums.indexOf(bs);
                return indexA - indexB;
            }
        }
        if(type === ColumnType.Boolean){
            this.sorter = (a ,b) =>{
                let as = getDeepObjectValue(a,this.indexArr);
                let bs = getDeepObjectValue(b,this.indexArr);
                as = !!as;
                bs = !!bs;
                return  as - bs;
            }
        }
    }

    get indexArr(){
        return this.dataIndex.split(".")
    }
    setVisible(bool){
        this.visible = bool;
        return this;
    }
    setEnum(enums,tags,colors){
        this.slots = new Slots(ColumnType.Enum);
        this.type = ColumnType.Enum;
  /*      this.sorter = (a ,b) =>{
            return a[this.dataIndex].localeCompare(b[this.dataIndex])
        };*/
        this.enums = enums;
        this.enumTags = tags?tags:null;
        this.enumColors = colors?colors:null;
        return this;
    }
    setDefaultValue(value){
        this.defaultValue = value;
        return this;
    }
    setTableView(width){
        this.tableView = true;
        if(width){
            this.width = width;
        }
        if(width === false){
            this.tableView = false
        }
        return this;
    }
    setPosition(pos){
        this.fixed=pos;
        return this;
    }
    setWidth(width){
        this.width = width;
        if(this.slots.getType() === ColumnType.Number){
            this.width +=40;
        }
        return this;
    }
    setTriggerOtherColumn(value,action,action2){
        if(!this.triggerOtherColumn){
            this.triggerOtherColumn = [];
        }
        this.triggerOtherColumn.push(new triggerOtherColumnObject(value,action,action2));
        return this;
    }
    setTriggerOtherTableColumn(value,action,action2){
        if(!this.TriggerOtherTableColumn){
            this.TriggerOtherTableColumn = [];
        }
        this.TriggerOtherTableColumn.push(new triggerOtherColumnObject(value,action,action2));
        return this;
    }
    setIdReplaceObject(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.slots = new Slots(ColumnType.IdReplace);
        return this;
    }
    setRemoteObject(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.slots = new Slots(ColumnType.RemoteEnum);
        return this;
    }
    setIdReplace2Object(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.slots = new Slots(ColumnType.IdReplace2);
        return this;
    }
    setObjectReplaceObject(objectName,seg,queryObject){
        this.replaceObject = new ObjectReplace(objectName,seg,queryObject);
        this.slots = new Slots(ColumnType.ObjectReplace);
        return this;
    }
    setBoolTags(tags){
        this.boolTags = tags;
        return this;
    }
    setChildren(children){
        this.children = children;
        return this;
    }
    setNumberRange(min,max){
        this.range = [min,max];
        return this;
    }
    setNumberFormatter(formatter,parser){
        this.formatter = formatter;
        this.parser = parser;
        return this;
    }
    setTitle(title){
        this.title = title;
        return this;
    }
    setSelectorObject(replaceSegment,options){
        this.selectorObject = new SelectorObject(replaceSegment,options);
        this.slots = new Slots(ColumnType.Selector);
        return this;
    }
    setCategoryTrigger(trigger,trigger2){
        this.categoryTrigger = [trigger,trigger2];
        return this;
    }
    setEditable(bool){
        this.editable = bool;
        return this;
    }
    setGroup(group){
        this.group = group;
        return this;
    }
    setCustomCell(customCell){
        this.customCell = customCell;
        return this;
    }

    setRows(num){
        this.rows = num;
        return this;
    }
    setEnableTime(time){
        this.enableTime = time;
        return this;
    }
    setDisabledDate(falg){
        this.disabledDate = falg;
        return this;
    }
    setFixed(pos){
        this.fixed = pos;
        return this
    }
    setClickHandler(fun){
        this.clickHandler = fun;
        return this;
    }

    /**
     * 下拉动态调用后台
     * @param replaceSegment    对应的字段
     * @param queryObject       编辑的时候使用的查询queryObject
     * @returns {Column}
     */
    setSelect(replaceSegment,queryObject){
        this.idReplaceObject = new SelectObject(replaceSegment,queryObject);
        this.slots = new Slots(ColumnType.select);
        return this;
    }
    setSlots(slots){
        this.slots = slots;
        return this;
    }
}
export default Column;

class SelectObject {
    /**
     *
     * @param replaceSegment 对应的字段
     * @param queryObject   编辑的时候使用的查询queryObject
     */
    constructor(replaceSegment,queryObject) {
        this.replaceSegment = replaceSegment;
        this.queryObject = queryObject;
    }
}

class triggerOtherColumnObject {
    /**
     *
     * @param value 触发值 可以是返回true false的函数
     * @param action 触发条件为true时候的回调函数
     * @param action2 触发条件为false时候的回调函数
     */
    constructor(value,action,action2) {
        this.value = value;
        this.action = action;
        this.action2 = action2;
    }
}

class IdReplaceObject {
    /**
     *
     * @param replaceSegment 对应的字段
     * @param queryObject   编辑的时候使用的查询queryObject
     * @param widthData
     */
    constructor(replaceSegment,queryObject,widthData) {
        this.replaceSegment = replaceSegment;
        this.queryObject = queryObject;
        this.widthData = widthData;
    }
}

class SelectorObject {
    /**
     *
     * @param replaceSegment 替换的字段
     * @param options  选项
     */
    constructor(replaceSegment,options) {
        this.replaceSegment = replaceSegment;
        this.options = options;
    }
}
class ObjectReplace {
    /**
     *
     * @param objectName 后台返回的用于读取的属性字段objectName
     * @param seg         object中用于表示的的字段
     * @param queryObject 编辑的时候使用的查询queryObject
     */
    constructor(objectName,seg,queryObject) {
        this.objectName = objectName;
        this.seg = seg;
        this.queryObject = queryObject;
    }
}

class Slots {
    constructor(type) {
        this.customRender = type;
        this.title = type;
        if(type === ColumnType.String){
            this.filterDropdown= 'filterDropdown';
            this.filterIcon= 'filterIcon';
        }
    }
    getType(){
        return this.customRender;
    }

}

function getDeepObjectValue(obj,arr) {
    let val = obj[arr[0]];
    arr.shift()
    if(val && arr.length>0){
        val = getDeepObjectValue(val,arr)
    }
    return val
}