/** 
* 自动生成文件，请勿修改 
* @name: 项目:任务管理模块============新增了一检附件上传
* @description: 项目-任务管理模块(增加接口：得到当前用户有参与工序的任务)
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const itemTask = { 
	/** 1 summary:单条更新任务的拟下绩效	method:post */ 
	updMoney : new APIObject("/api/itemTask/updMoney.do"), 
	/** 2 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/itemTask/queryOne.do"), 
	/** 3 summary:查询-分页(得到当前用户参与的任务)	method:post */ 
	queryPageMy : new APIObject("/api/itemTask/queryPageMy.do"), 
	/** 4 summary:查询-不分页(得到当前用户参与的任务)	method:post */ 
	queryAllMy : new APIObject("/api/itemTask/queryAllMy.do"), 
	/** 5 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/itemTask/queryAll.do"), 
	/** 6 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/itemTask/queryPage.do"), 
	/** 7 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/itemTask/batchDelete.do"), 
	/** 8 summary:保存/修改-单个任务	method:post */ 
	saveOrUpd : new APIObject("/api/itemTask/saveOrUpd.do"), 
	/** 9 summary:上传一检附件===========新增接口	method:post */ 
	qcFileUpload : new APIObject("/api/itemTask/qcFileUpload.do"), 
	/** 10 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/itemTask/delete.do"), 
};
export default itemTask;