<!--
* @program: tyh-oa 
* @author: ly
* @description: systemManager 
* @create: 2021-03-22 16:18
-->
<template>
    <a-layout style="height: 100%" >
        <a-layout-sider :collapsible="true" :collapsedWidth="40">
            <a-menu
                    theme="dark"
                    mode="inline"
                    v-model:openKeys="openKeys"
                    v-model:selectedKeys="selectedKeys"
                    @openChange="onOpenChange"
                    @click="handleClick">
                <sub-group-menu v-for="(group,index) in menuLists" :index="index" :groupMenus="group" :key="group.id" />
            </a-menu>
        </a-layout-sider>
        <a-layout-content class="main-window">
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import menuContent from "../../components/menuContent";
    import menuViewer from "../SystemSetting/Settings/menuViewer";
    import roleMenuSetting from "../SystemSetting/Settings/roleMenuSetting";
    import BillCategorySetting from "../SystemSetting/Settings/BillCategorySetting";
    import firstPartyManage from "./otherParty/firstPartyManage";
    import thirdPartyUserManage from "./otherParty/thirdPartyUserManage";
    import projectPayStepSetting from "../SystemSetting/Settings/projectPayStepSetting";
    import userInfoManager from "./userInfoManager/userInfoManager";
    import StaffAssessment from "./staffAssessment/StaffAssessment";
    import AuditSetting from "../financialManager/AuditSetting/AuditSetting";
    import DepartmentManager from "./DepartmentManager/DepartmentManager";
    import RoleSetting from "./roleManage/RoleSetting";
    import StaffPromotion from "./StaffPromotion/StaffPromotion";
    import SealManager from "./SealManager/SealManager";
    import UserExtraManager from "./UserExtraManager/UserExtraManager";
    export default {
        name: "systemManager",
        mixins:[menuContent],
        components:{
            RoleSetting,//内部人员管理-用户角色管理
            userInfoManager, //内部人员管理 -  员工管理
            StaffAssessment, //内部人员管理 -  员工考核

            firstPartyManage,//外部人员管理-甲方/外协单位管理
            thirdPartyUserManage,//外部人员管理-外协人员管理


            AuditSetting,//菜单管理-资金账户支付审核设定
            projectPayStepSetting, //菜单管理-项目支付审核设定
            BillCategorySetting,//菜单管理-内部账务类别设定
            menuViewer,//菜单管理-菜单设定
            roleMenuSetting,//菜单管理-角色设定

            DepartmentManager, //部门管理
            StaffPromotion, //员工晋升

            SealManager,     //用章设定
            UserExtraManager //简历管理
        }
    }
</script>

<style scoped>

</style>