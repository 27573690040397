/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_prod_quality  员工生产质量管理
 * @create: 2021-04-25 11:07
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_prod_quality extends Table{
    constructor() {
        super();
        this.name = "item_prod_quality";
        this.CNName = "员工生产质量管理";
        this.columnArray = [
            new Column("项目ID","itemId",ColumnType.String,true).setVisible(false),
            new Column("用户ID","userId",ColumnType.String,true).setVisible(false),
            new Column("人员类型","userType",ColumnType.Enum,false)
                .setEnum(["1","2","3"],["内部员工","外协单位","外协人员"],["green","purple","purple"]),
            new Column("综合评价","assessment",ColumnType.Enum,true)
                .setEnum(["0","1","2","3"],["不合格","合格","良好","优秀"],["red","pink","gold","green"]),
            new Column("评价总结","sumDescri",ColumnType.String,true),
        ];
    }
}