import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";
import fixedCategory from "../../assets/api/fixedCategory";
/**
 * @program: TYH_office_automation
 * @author: ly
 * @description: device
 * @create: 2021-06-21 10:56
 **/

export default class device  extends Table{
    constructor() {
        super();
        this.name = "device ";
        this.CNName = "设备表";
        this.columnArray = [
            new Column("类别","categoryId",ColumnType.CategoryAssets,true).setTableView(150),
            new Column("名称","name",ColumnType.String,true).setTableView(200),
            new Column("编码类别","codeName",ColumnType.Enum,true).setTableView(80)
                .setEnum(["P/N","S/N","MAC","其他"],["P/N","S/N","MAC","其他"],["pink","blue","cyan","red"]),
            new Column("编码","code",ColumnType.String,true).setTableView(200),
            new Column("主/从设备","mainId",ColumnType.Boolean).setTableView(80)
                .setBoolTags(["主设备","从设备"]),
            new Column("设备状态","state",ColumnType.String,false).setTableView(80).setDefaultValue("1")
                .setEnum(["0","1"],["停用","启用"],["orange","green"]),
            new Column("设备送出","permanentFlag",ColumnType.String,true).setTableView(100).setDefaultValue("0")
                .setEnum(["0","1"],["内部使用","已送出"],["blue","red"]),
            new Column("录入时间","createTime",ColumnType.Date),
            new Column("购入日期","buyDate",ColumnType.Date,true),
            new Column("是否固定资产","fixedAsset",ColumnType.Enum,true).setGroup(2)
                .setEnum(["0","1"],["否","是"],["pink","red"]).setTableView(110)
                .setTriggerOtherColumn('1',
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="fixedCategory"})[0].setVisible(true);
                        cols.filter(col=>{return col.dataIndex==="price"})[0].setVisible(true);
                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="fixedCategory"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="price"})[0].setVisible(false);
                    }
                ),
            new Column("固定资产类别","fixedCategory",ColumnType.String,true)
                .setSelect("categoryName",{api:fixedCategory.queryAll,keyValue:["id","categoryName"]}),
            new Column("资产价值","price",ColumnType.Number,true),
            new Column("使用人","currentUseUserName",ColumnType.String).setTableView(100),
            new Column("备注","remarks",ColumnType.String,true).setTableView(100),
           //new Column("设备状态","state",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(200)];
    }
}