/** 
* 自动生成文件，请勿修改 
* @name: 项目：任务-员工年度占比管理模块
* @description: 项目：任务-员工年度占比管理模块-------2023-12-27日新增
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const taskUserRatio = { 
	/** 1 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/taskUserRatio/batchDelete.do"), 
	/** 2 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/taskUserRatio/delete.do"), 
	/** 3 summary:查询-不分页	method:post */ 
	queryAll : new APIObject("/api/taskUserRatio/queryAll.do"), 
	/** 4 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/taskUserRatio/queryOne.do"), 
	/** 5 summary:保存/修改-单个	method:post */ 
	saveOrUpd : new APIObject("/api/taskUserRatio/saveOrUpd.do"), 
	/** 6 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/taskUserRatio/batchSaveOrUpd.do"), 
	/** 7 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/taskUserRatio/queryPage.do"), 
};
export default taskUserRatio;