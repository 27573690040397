<!--
* @program: tyh-oa 
* @author: ly
* @component:projectUseUp 项目损耗一览
* @description: 
* @create: 2021-04-16 10:56
-->
<template>
    <div>
        <a-descriptions  :size="'small'">
            <template #title>
                <span>消耗统计</span>
                <a-button class="search-item" type="link"  @click="tipsShow">
                    <template #icon><ExclamationCircleOutlined /></template>
                </a-button>
            </template>
            <a-descriptions-item label="总报销金额(元)" >{{sum.applyAmount.toFixed(2)}}</a-descriptions-item>
        </a-descriptions>
        <ArBaseTable :argument="argument" :view-model="true" :page-size="15" >
            <template #action="{record}">
                <div class="action-column">
                    <a-tooltip  title="查看所属账单">
                        <a-button class="action-button" type="link" @click="showBelong(record)">
                            <LinkOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </div>
            </template>
        </ArBaseTable>
    </div>
</template>

<script>
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {ExclamationCircleOutlined,LinkOutlined} from "@ant-design/icons-vue"
    import {uuid} from "../../../assets/utils/general";
    import {h} from "vue";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import item_finance_record_flow from "../../../assets/tables/item_finance_record_flow2";
    import recordFlow from "../../../assets/api/recordFlow";
    import api_item_finance_record from "../../../assets/api/record";
    import item_finance_record from "../../../assets/tables/item_finance_record";
    export default {
        name: "projectUseUp",
        props:["projectId"],
        components:{
            ArBaseTable,ExclamationCircleOutlined,LinkOutlined
        },
        data(){
            return{
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("支出时间","dateStr",ColumnType.Date,false).setTableView(100),
                        new Column("有发票金额(元)","haveInvoiceAmount",ColumnType.Number,true).setTableView(100),
                        new Column("无发票金额(元)","noInvoiceAmount",ColumnType.Number,true).setTableView(100),
                        new Column("报销金额(元)","applyAmount",ColumnType.Number,true).setTableView(100),
                    ], //提供table的字-段集 this.table.getTableColumns()

                    loading:false,//table的loading状态
                },
                queryOptions:{
                    itemId:null,
                    entryState:"1"
                },
                visible:false,
                sum:{
                    applyAmount:0,
                    noInvoiceAmount:0,
                    haveInvoiceAmount:0
                }
            }
        },
        methods:{
            init(){
              this.sum.applyAmount = 0;
                this.argument.tableColumns = new item_finance_record_flow().getTableColumns();
                this.queryOptions.itemId = this.projectId;
                recordFlow.queryAll.requestPOSTUrlParam(this,this.queryOptions,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                        for(let i in this.argument.dataSource){
                            this.sum.applyAmount += this.argument.dataSource[i].applyAmount;
                            this.argument.dataSource[i].id = uuid(8,16);
                        }
                    }
                })
            },
            flowsShow(){
                this.visible = true;
            },
            tipsShow(){
                this.$notification.info(
                    {
                        message: "消耗统计说明",
                        description: h('div',[
                            h("div",{style:{"color":"blue"}},"可以看到本项目所有的流水，包括入账和未入账的流水"),
                        ])
                    })
            },
            showBelong(record){
                let data={id:record.recordId};
                api_item_finance_record.queryOne.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        let {title,userName,recordYearMonth,entryState}=res.data.data;
                        this.$notification.success(
                            {
                                message: "账单标题："+title,
                                description: h('div',[
                                    h("div",{style:{"color":this.getEntryColor(entryState)}},"入账状态："+this.getEntryState(entryState)),
                                    h("div",{style:{"color":'red'}},"报账人："+userName),
                                    h("div","账单日期："+recordYearMonth),
                                ])
                            })
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            getEntryState(entryState){//与字段设定中要保持一致
                let table = new item_finance_record();
                let col = table.columnArray.filter(column=>{return column.dataIndex ==="entryState"})[0];
                let values = col.enums;
                let labels = col.enumTags;
                let index = values.indexOf(entryState);
                return labels[index];
            },
            getEntryColor(entryState){
                let table = new item_finance_record();
                let col = table.columnArray.filter(column=>{return column.dataIndex ==="entryState"})[0];
                let values = col.enums;
                let colors = col.enumColors;
                let index = values.indexOf(entryState);
                return colors[index];
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>