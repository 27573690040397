<!--
* @program: TYH_office_automation 
* @author: ly
* @component:YearMoneyArrangePer 
* @description: 项目年度工作量分配比例--项目负责人使用
* @create: 2023-12-29 17:36
-->
<template>
    <div class="info-item">
        <span style="margin-left: 20px;font-size: 18px;font-weight: 600">工序总权重（{{processListRadioSum}}）</span>
        <div v-for="item in processList" :key="item.year">
            <span @click="listShow(item.year)" style="cursor:pointer; margin-left: 20px; font-size: 18px;font-weight: 600;color:#0350a4">{{item.year}}年度</span>
            <span style="margin-left: 10px; font-size: 18px;font-weight: 600;color:#0350a4">工序权重{{item.sumRatio}}（{{(item.sumRatio/processListRadioSum*100).toFixed(2)}}%）</span>
        </div>
    </div>
    <div class="info-item">
        <span style="margin-left: 20px;font-size: 18px;font-weight: 600">工序分配权重占比</span>
    </div>
    <div class="info-item" >
        <div style="margin-left: 20px;font-size: 14px;">默认比例计算规则为：</div>
        <div style="margin-left: 20px;font-size: 14px;">1、工序年度按工序开始时间计算</div>
        <div style="margin-left: 20px;font-size: 14px;">2、跨年工序按开始年度计算 </div>
        <div style="margin-left: 20px;font-size: 14px;">3、只计算了工序占比并未计算工作完成量占比</div>
        <div style="margin-left: 20px;font-size: 14px;">4、若出现人员合计不足100%的情况则说明工序并未完全分配完毕</div>
    </div>
    <div class="info-item">
        <div v-for="item in processList" :key="item.year">
            <span @click="listShow(item.year)" style="cursor:pointer; margin-left: 20px; font-size: 18px;font-weight: 600;color:#0350a4">{{item.year}}年度（{{item.sumRatio}}）</span>
            <span v-for="member in item.workerRadio" :key="member.userId"
                  style="cursor:pointer; margin-left: 20px; font-size: 18px;font-weight: 600;color:#0350a4">
                    {{member.userName}}:{{member.radio.toFixed(2)}}（{{(member.radio/item.sumRatio*100).toFixed(2)}}%）
                </span>
        </div>
    </div>
    <div class="info-item">
        <TcBaseTable :title="true" :data-source="source2" :table-columns="tableColumns2" :page-size="10"  :view-model="true">
            <template #title >
                <span style="margin-left: 20px;font-size: 18px;font-weight: 600">年度工作占比分配 年度：</span>
                <a-select  v-model:value="year" style="width:200px" class="search-item" :options="years"/>
                <a-tooltip  title="添加拟下绩效记录" >
                    <a-button @click="addData" type="primary" style="margin:0 10px">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                </a-tooltip>
                <a-button @click="init" type="primary" style="margin:0 10px">
                    <template #icon><SyncOutlined /></template>
                </a-button>
            </template>
            <template #action="{index,record}">
                <div class="action-column">
                    <a-button class="action-button" type="link" @click="editDetail(record)">
                        <EditFilled class="pointer"/>
                    </a-button>
                    <a-popconfirm
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            title="确定删除吗?"
                            @confirm="deleteData(index,record)">
                        <DeleteOutlined  style="color: red" class="pointer"/>
                    </a-popconfirm>
                </div>
            </template>
        </TcBaseTable>
    </div>
    <a-modal :width="800" v-model:visible="visible" title="详情" :footer="null">
        <TcBaseTable v-if="visible" :data-source="source" :table-columns="tableColumns" :page-size="20"  view-model="true"/>
    </a-modal>

    <a-modal :width="800"
             @ok="saveData2"
             ok-text="确认"
             cancel-text="取消"
             v-model:visible="visible2"
             :title="currentRecord.userName"
    >
        年度：<a-input-number :max="2024" :precision="0" :min="2021" style="width:100px" v-model:value="currentRecord.year"/>
        占比：<a-input-number :max="100" :precision="2" :min="0" style="width:100px" v-model:value="currentRecord.ratio"/>
    </a-modal>
    <a-modal :width="800"
             @ok="saveData3"
             ok-text="确认"
             cancel-text="取消"
             v-model:visible="visible3"
             :title="year+'年度批量填入'"
    >
        <div class="info-item">
            <span style="margin-left: 20px;font-size: 14px;font-weight: 600">若系统内已有记录，请先删除记录后再使用本功能。</span>
        </div>
        <div class="info-item">
            <div v-for="member in workerRadio" :key="member.userId"
                  style="cursor:pointer; margin-left: 20px; font-size: 18px;font-weight: 600;color:#0350a4">
                    {{member.userName}}:{{member.radio.toFixed(2)}}（{{member.per}}%）
                <a-input-number :precision="2" style="width:100px" v-model:value="member.per"/>
            </div>
        </div>
        <div class="info-item">
            <span style="margin-left: 20px;font-size: 18px;font-weight: 600">合计：{{sumPer.toFixed(2)}}%</span>
            <span style="margin-left: 20px;font-size: 18px;font-weight: 600">{{sumPer>100?"合计不能超过100%":""}}</span>
        </div>
    </a-modal>
</template>

<script>
    import api_item_task_process_employee from "../../../assets/api/processEmployee";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import {DeleteOutlined, EditFilled, PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import taskUserRatio from "../../../assets/api/taskUserRatio";
    /*
    processId: "1a8f5394-eef7-4608-848f-71c76c95fbbf"
    processRatio: 5 //工序权重
    processTitle: "宣传资料制作"  工序标题
    userId: "9adb2860-add7-4ee4-bb64-81711598b544"
    userName: "张舒平"
    workRatio: 60 个人工序占比
    startDate： 开始时间   new Date(startDate).getFullYear();得到年度


     */
    export default {
        name: "YearMoneyArrangePer",
        props:["record"],
        components:{
            TcBaseTable,PlusOutlined,EditFilled,DeleteOutlined,SyncOutlined,
        },
        computed:{
            processList(){//通过startDate： 开始时间对工序进行年度分组
                let list = [];
                for(let it of this.dataSource){
                    let arr = list.find(item=>{
                        return item.year === new Date(it.startDate).getFullYear()
                    });
                    if(arr){
                        arr.list.push(it);
                    }
                    else{
                        list.push(new YearProcessList(new Date(it.startDate).getFullYear(),[it]))
                    }
                }
                return list.sort((a,b)=>{
                    return a.year - b.year
                })
            },
            processListRadioSum(){
                let sum = 0;
                for(let item of this.processList){
                    sum += item.sumRatio
                }
                return sum
            },
            source2(){
                return this.dataSource2.filter(item=>{
                    return item.year === this.year.toString()
                })
            },
            sumPer(){
                let per = 0;
                for(let item of this.workerRadio){
                    per += parseFloat(item.per)
                }
                return per
            }
        },
        data() {
            return {
                visible:false,
                dataSource:[],
                source: [],
                tableColumns: [
                    new Column("序号","_index",ColumnType.Index).setTableView(60),
                    new Column("工序名称","processTitle",ColumnType.String).setTableView(100),
                    new Column("工序权重","processRatio",ColumnType.String).setTableView(100),
                    new Column("开始时间","startDate",ColumnType.Date).setTableView(100),
                    new Column("分配人员","userName",ColumnType.String).setTableView(100),
                    new Column("人员工序占比","workRatio",ColumnType.Number)
                        .setNumberRange(0,100).setTableView(100)
                        .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                ],
                dataSource2:[],
                tableColumns2:[
                    new Column("序号","_index",ColumnType.Index).setTableView(60),
                    new Column("年度","year",ColumnType.String).setTableView(100),
                    new Column("姓名","userName",ColumnType.String).setTableView(100),
                    new Column("占比","ratio",ColumnType.Number).setTableView(100),
                    new Column("操作","actions","actions").setTableView(100)
                ],
                years:[
                    {title:2021,value:2021},
                    {title:2022,value:2022},
                    {title:2023,value:2023},
                ],
                year:2023,
                currentRecord:{},
                visible2:false,
                workerRadio:[],
                visible3:false,
            }
        },
        methods: {
            init() {
                let data = {taskId:this.record.id};
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                });
                taskUserRatio.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource2 = res.data.data;
                    }
                    else{
                        this.dataSource2 = [];
                        this.$message.error(res.data.msg)
                    }
                })
            },
            listShow(year){
                let arr = this.processList.find(item=>{
                    return  item.year === year;
                });
                if(arr){
                    this.source = arr.list;
                    this.visible = true;
                }
            },
            editDetail(record){
                this.currentRecord = {...record};
                this.visible2 = true;
            },
            saveData2(){
                let {id,year,taskId,userId,userType,ratio} = this.currentRecord;
                taskUserRatio.saveOrUpd.requestPOST(this,{id,year,taskId,userId,userType,ratio},res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible2 = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(index,record){
                let data= {id:record.id};
                taskUserRatio.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            addData(){
                let arr = this.processList.find(item=>{
                    return item.year === this.year
                });
                if(arr){
                    this.workerRadio = arr.clone().setRadio;
                }
                this.visible3 = true;
            },
            saveData3(){
                if(this.sumPer > 100){
                    this.$message.error("合计百分比超过100")
                }
                else {
                    let data = [];
                    for(let item of this.workerRadio){
                        let obj = {
                            year:this.year,
                            taskId:this.record.id,
                            userId:item.userId,
                            userType:"1",
                            ratio:item.per
                        };
                        data.push(obj)
                    }
                    taskUserRatio.batchSaveOrUpd.requestPOST(this,{taskUserRatioVOList:data},res=>{
                        if(res.data.flag){
                            this.init();
                            this.visible3 = false;
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            }

        },
        created() {
            this.init()
        }
    }
    class YearProcessList{
        constructor(year,list) {
            this.year = year;
            this.list = list;
            this.setRadio = this.workerRadio;
        }
        get sumRatio(){
            let ratio = 0;
            let processIds = [];
            for(let item of this.list){
                if(!processIds.includes(item.processId)){
                    ratio += item.processRatio;
                    processIds.push(item.processId)
                }
            }
            return ratio
        }
        get workerRadio(){
            let radios = [];
            for(let it of this.list){
                let {userId,userName,processRatio,workRatio} = it;
                let arr = radios.find(item=>{
                    return item.userId === userId
                });
                if(arr){
                    arr.radio += workRatio * processRatio/100;
                }
                else{
                    let radio = workRatio * processRatio/100;
                    radios.push({
                        userId:userId,
                        userName:userName,
                        radio:radio,
                        per:0,
                    })
                }
            }
            for(let ra of radios){
                ra.per = (ra.radio/this.sumRatio*100).toFixed(2);
            }
            return radios
        }
        clone(){
            return  new YearProcessList(this.year,[...this.list])
        }
    }
</script>

<style scoped>

</style>