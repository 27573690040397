<!--
* @program: office_automation 
* @author: ly
* @component:UserExtraManager 
* @description: 
* @create: 2023-02-23 10:58
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <a-radio-group v-model:value="disableState"  >
                    <a-radio value="1">在职人员</a-radio>
                    <a-radio value="0">离职人员</a-radio>
                </a-radio-group>
            </div>
            <div style="height: calc(100% - 100px);">
                <TcBaseTable :table-columns="tableColumn" :loading="loading"
                             :data-source="source" :view-model="true" :page-size="15" >
                    <template #action="{record}">
                        <div class="action-column">
                            <a-button class="action-button" type="link" @click="showDetail(record)">
                                <SearchOutlined class="pointer" title="显示"/>
                            </a-button>
                            <a-button class="action-button" type="link" @click="editDetail(record)">
                                <EditFilled class="pointer" title="编辑"/>
                            </a-button>
                        </div>
                    </template>
                </TcBaseTable>
            </div>
        </div>
    </div>

    <a-drawer v-model:visible="visible"
              width="50%"
              placement="right"
              :closable="false"
    >
        <FcForm style="width:600px" :form-columns="formColumns" v-model:form-data="mFormData" :view-model="viewModel" >
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-drawer>
</template>

<script>

    import TcBaseTable from "../../../components/table/TcBaseTableFilter";
    import FcForm from "../../../components/form/FcForm";
    import {
        EditFilled,
        SearchOutlined,
    } from "@ant-design/icons-vue";
    import userInfo from "../../../assets/api/userInfo";
    import sys_user_info_with_extra from "../../../assets/tables/sys_user_info_with_extra";
    import sys_user_info_extra2 from "../../../assets/tables/sys_user_info_extra2";
    import userExtra from "../../../assets/api/userExtra";

    export default {
        name: "UserExtraManager",
        components:{
            TcBaseTable,SearchOutlined,EditFilled,FcForm
        },
        computed:{
            source(){
                return this.dataSource.filter(item=>item.disableState === this.disableState)
            }
        },
        data() {
            return {
                departments:[],
                departmentId:null,
                tableColumn:new sys_user_info_with_extra().getTableColumns(),
                formColumns:new sys_user_info_extra2().getFormColumns(),
                dataSource:[],
                loading:false,
                disableState:"1", //默认只查询在职人员
                visible:false,
                mFormData:null,
                viewModel:true,
                currentUserId:null
            }
        },
        methods: {
            init() {
                this.loading = true;
                userInfo.queryAll.requestPOSTUrlParam(this,{},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            showDetail(record){
                this.currentUserId = record.id;
                let data = {
                    userId:record.id
                };
                userExtra.queryAll.requestPOST(this,data,res=>{
                    this.viewModel = true;
                    if(res.data.flag){
                        this.visible = true;
                        this.mFormData = res.data.data[0];
                    }
                    else{
                        this.$message.error("还未录入简历");
                        this.mFormData = new sys_user_info_extra2().getFormData();
                    }
                });
            },
            editDetail(record){
                this.currentUserId = record.id;
                let data = {
                    userId:record.id
                };
                userExtra.queryAll.requestPOST(this,data,res=>{
                    this.viewModel = false;
                    this.visible = true;
                    if(res.data.flag){
                        this.mFormData = res.data.data[0];
                    }
                    else{
                        this.mFormData = new sys_user_info_extra2().getFormData();
                    }
                });
            },
            saveData(record){
                record.userId = this.currentUserId;
                userExtra.saveOrUpdate.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.visible =false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        created() {
            this.init()

        }
    }
</script>

<style scoped>

</style>