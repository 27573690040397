/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class recordList_item extends Table {
    constructor() {
        super();
        this.name = "recordList_item";
        this.CNName = "部门报账信息";
        this.columnArray = [
            new Column("部门名称","departName",ColumnType.String,true).setTableView(150),
            new Column("时间","dateStr",ColumnType.String,true).setTableView(80),
            new Column("有发票报销(元)","haveInvoiceAmount",ColumnType.Number,true).setTableView(80),
            new Column("无发票报销(元)","noInvoiceAmount",ColumnType.Number,true).setTableView(80),
            new Column("合计(元)","applyAmount",ColumnType.Number,true).setTableView(80),
            new Column("操作","actions","actions",false).setTableView(80)
        ];

    }
}