/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_qc_process  质检工序管理
 * @create: 2021-04-25 11:38
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_qc_process extends Table{
    constructor() {
        super();
        this.name = "item_qc_process";
        this.CNName = "质检工序管理";
        this.columnArray = [
            //new Column("质检申请ID","applyId",ColumnType.Date,true),
            new Column("工序标题","title",ColumnType.String,true).setTableView(150),
            new Column("工序内容","content",ColumnType.String,true),
            new Column("工作量比例","workRatio",ColumnType.Number,true).setTableView(100)
                .setNumberRange(0,100).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("开始时间","startDate",ColumnType.Date,true).setTableView(100),
            new Column("结束时间","endDate",ColumnType.Date,true).setTableView(100),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(50)];
    }
}