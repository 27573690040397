<!--
* @program: TYH_office_automation 
* @author: ly
* @component:assetsCategorySetting 
* @description: 
* @create: 2021-06-21 17:18
-->
<template>
    <a-tabs   v-model:activeKey="activeKey" >
        <template #renderTabBar="{ DefaultTabBar, ...props }">
            <component
                    :is="DefaultTabBar"
                    v-bind="props"
                    :style="{ zIndex: 1, background: '#fff', }"
            />
        </template>
        <a-tab-pane  key="setting1" tab="设定设备类别" >
            <div style="height: 800px;overflow-y: auto">
            <a-tooltip  title="添加大类">
                <a-button class="search-item" type="dashed"  @click="addGroup">
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip :key="'#fff'" title="刷新">
                <a-button class="search-item" type="dashed"  @click="refresh">
                    <template #icon><SyncOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-table style="margin:0 30%;width: 40%;" bordered :dataSource="dataSource" :columns="cols" rowKey="id" >
                <template #actions="{index,record}">
                    <div style="text-align: center">
                        <a-tooltip  title="添加子类">
                            <a-button v-if="checkColumn(record)" class="action-button" type="link" @click="addDetail(record)">
                                <PlusOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                        <a-button  class="action-button" type="link" @click="editDetail(index,record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </a-table>
            </div>
            <a-modal v-model:visible="visible"
                     title="详情">
                <template #footer>
                    <a-button key="back" @click="visible =false">取消</a-button>
                    <a-button key="submit" type="primary"  @click="update">提交</a-button>
                </template>
                <a-form style="margin: 10px 100px" layout="horizontal"
                        :model="currentRecord">
                    <a-form-item label="类别">
                        <a-input style="width: 200px" v-model:value="currentRecord.categoryName" />
                    </a-form-item>
                    <a-form-item label="备注">
                        <a-textarea style="width: 200px" v-model:value="currentRecord.remarks" />
                    </a-form-item>
                </a-form>
            </a-modal>
        </a-tab-pane>
    </a-tabs>
</template>

<script>
    import api_device_category from "../../../assets/api/deviceCategory";
    import category from "../../../assets/tables/category";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {DeleteOutlined,PlusOutlined,EditFilled,SyncOutlined} from "@ant-design/icons-vue"
    export default {
        name: "assetsCategorySetting",
        components:{
            DeleteOutlined,PlusOutlined,EditFilled,SyncOutlined
        },
        data(){
            return{
                dataSource:[],
                table: new category(),
                cols:[],
                secondSource:[],
                visible:false,
                viewModel:false,
                currentRecord:{},
                activeKey:"setting1"
            }
        },
        methods:{
            init(){
                api_device_category.queryOneLevel.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                })
            },
            addGroup(){//添加大类
                this.currentRecord = {};
                this.visible = true
            },
            addDetail(record){//添加子分类
                this.currentRecord = {parentId:record.id};
                this.visible = true
            },
            deleteData(index,record){
                let data ={id:record.id};
                api_device_category.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editDetail(index,record){
                this.visible = true;
                this.currentRecord = record;
            },
            checkColumn(record){
                return Object.prototype.hasOwnProperty.call(record, "children");
            },
            update(){
                api_device_category.saveOrUpd.requestPOST(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.init()
            }
        },
        created() {
            this.cols = [
                new Column("类别","categoryName",ColumnType.String,true),
                new Column("备注","remarks",ColumnType.String,true).setTableView(100),
                new Column("操作","actions","actions").setTableView(40)];
            this.init();
        }
    }
</script>

<style scoped>

</style>