<!--
* @program: office_automation 
* @author: ly
* @component:PositionAimSetting
* @description: 职位月度目标绩效设置
* @create: 2022-01-14 10:55
-->
<template>
    <div>
        <a-button class="search-item" type="dashed" shape="circle" @click="addData" >
            <template #icon><PlusOutlined /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
            <template #icon><SyncOutlined /></template>
        </a-button>
        <a-radio-group class="search-item" v-model:value="value" @change="selectChange">
            <a-radio value="1">技术岗</a-radio>
            <a-radio value="2">工勤岗</a-radio>
            <a-radio value="3">管理岗</a-radio>
        </a-radio-group>
        <ArEditTableFilter :argument="argument" :page-size="15" :view-model="false" @save="dataSave" @delete="dataDelete" />
    </div>
</template>

<script>
    import ArEditTableFilter from "../../../../components/table/ArEditTableFilter";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import {PlusOutlined,SyncOutlined} from "@ant-design/icons-vue";
    import positionAchiev from "../../../../assets/api/positionAchiev";
    export default {
        name: "PositionAimSetting",
        components:{
            ArEditTableFilter,PlusOutlined,SyncOutlined
        },
        data(){
            return{
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                        new Column("岗位类型","type",ColumnType.String,false).setTableView(100)
                            .setEnum(["1","2","3"],["技术岗","工勤岗","管理岗"],["cyan","green","blue"]),
                        new Column("岗位级别","level",ColumnType.String,true).setTableView(100),
                        new Column("目标绩效（元）","achiev",ColumnType.Number,true).setTableView(100),
                        new Column("备注","remarks",ColumnType.String,true).setTableView(200),
                        new Column("操作","actions","actions",false).setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                value:"1"
            }
        },
        methods:{
            init(){
                let data = {
                    type:this.value
                };
                this.argument.loading = true;
                positionAchiev.queryAll.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else {
                        this.argument.dataSource = []
                    }
                })
            },
            selectChange(){
                let data = {
                    type:this.value
                };
                this.argument.loading = true;
                positionAchiev.queryAll.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else {
                        this.argument.dataSource = []
                    }
                })
            },
            refresh(){
                this.init()
            },
            dataSave(record){
                positionAchiev.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            dataDelete(record){
                let data = {
                    id:record.id
                };
                positionAchiev.delete.requestPOSTUrlParam(this,data);
            },
            addData(){
                let data = {
                    type:this.value,
                    level:null,
                    achiev:null,
                    remarks:null,
                };
                switch (this.value) {
                    case "1":{
                        data.level="TZ-";
                        break
                    }
                    case "2":{
                        data.level="TQ-";
                        break
                    }
                    case "3":{
                        data.level="TG-";
                        break
                    }
                }

                this.argument.dataSource.push(data);
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>