<!--
* @program: office_automation
* @author: ly
* @component:financialManager
* @description:
* @create: 2022-01-24 14:39
-->
<template>
    <a-layout style="height: 100%" >
        <a-layout-sider :collapsible="true" :collapsedWidth="40">
            <a-menu
                    theme="dark"
                    mode="inline"
                    v-model:openKeys="openKeys"
                    forceSubMenuRender
                    @openChange="onOpenChange"
                    @click="handleClick">
                <sub-group-menu v-for="(group,index) in menuLists" :index="index" :groupMenus="group" :key="group.id" />
            </a-menu>
        </a-layout-sider>
        <a-layout-content class="main-window">
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
import menuContent from "../../components/menuContent";
import BankManager from "./BankManager/BankManager";
import OtherIncome from "./OtherIncome/OtherIncome";
import FinancialSubject from "./FinancialSubject/FinancialSubject";
import BankViewer from "./BankManager/BankViewer";
import OtherOutcome from "./OtherOutcome/OtherOutcome";
import OtherOutcomeAudit from "./OtherOutcomeAudit/OtherOutcomeAudit";
import invoiceManager from "./invoiceManager/invoiceManager";
import yearBalance from "./yearBalanceMange/yearBalance";
import financeSuject from "./financeSujectMange/financeSuject";
import financeReport from "./financialCount/financeReport";

export default {
  name: "financialManager",
  mixins:[menuContent],
  components:{
    BankManager,//资金账户管理
    OtherIncome,//其他收入
    OtherOutcome,//资金账户支出
    FinancialSubject,//会计统计大类设定
    BankViewer, //资金账户一览
    OtherOutcomeAudit, //资金账户支出审核
    invoiceManager, //发票管理
    yearBalance,// 年初余额
    financeSuject,// 财务科目管理
    financeReport,// 财务报表
  }
}
</script>

<style scoped>

</style>
