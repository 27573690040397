<!--
* @description：项目其他信息一览：项目消耗一览、项目收款一览、项目支付一览、项目收入发票、项目支出发票
* @author:rzl
* @date:2022/7/12 10:08
-->
<template>
    <div class="tab-container" >
        <a-tabs @change="handleChange"  v-if="true" v-model:activeKey="activeKey" :size="size"  tab-position="left">
            <a-tab-pane  v-for="(pane,index) in paneList" :key="index.toString()" :tab="pane.tag" >
                <div style="height: 100%;overflow-y: auto"
                     :ref="pane.component"
                     v-is="pane.component"
                     :viewModel="pane.viewModel"
                     :projectId="pane.projectId"
                     @refresh="refresh" />
            </a-tab-pane>
        </a-tabs>
        <div v-else style="font-size: 40px;margin: 20vh" class="non-select">
            欢迎使用{{$sysName}}
            <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
                版本：{{$version}}
            </div>
        </div>
    </div>
</template>

<script>
    import baseTabs from "../../../components/tabs/baseTabs";
    import projectUseUp from "../projectRelateInfo/projectUseUp";
    import projectIncome from "../projectRelateInfo/projectIncome";
    import projectOutSourcePay from "../projectRelateInfo/projectOutSourcePay";
    import projectInvoice from "../projectRelateInfo/projectInvoice";
    import projectInvoicePayoff from "../projectRelateInfo/projectInvoicePayoff";

    export default {
        name: "projectOtherInfoList",
        mixins:[baseTabs],
        props:["projectId","viewModel"],
        emits:["refresh"],
        components:{
            projectIncome,projectUseUp,
            projectOutSourcePay,projectInvoice,projectInvoicePayoff,
        },
        data(){
            return{
                drawerVisible:true,
              paneList:[],
            }
        },
        created() {
          if(this.viewModel){
            this.paneList = [
              {tag:"项目消耗一览",component:"projectUseUp",viewModel:this.viewModel,projectId:this.projectId},
              {tag:"项目收款一览",component:"projectIncome",viewModel:this.viewModel,projectId:this.projectId},
              {tag:"项目支付一览",component:"projectOutSourcePay",viewModel:this.viewModel,projectId:this.projectId},
              {tag:"项目收入发票",component:"projectInvoice",viewModel:this.viewModel,projectId:this.projectId},
              {tag:"项目支出发票",component:"projectInvoicePayoff",viewModel:this.viewModel,projectId:this.projectId},
            ]
          }else{
            this.paneList = [
              {tag:"项目收款一览",component:"projectIncome",viewModel:this.viewModel,projectId:this.projectId},
              {tag:"项目支付一览",component:"projectOutSourcePay",viewModel:this.viewModel,projectId:this.projectId},
              {tag:"项目收入发票",component:"projectInvoice",viewModel:this.viewModel,projectId:this.projectId},
              {tag:"项目支出发票",component:"projectInvoicePayoff",viewModel:this.viewModel,projectId:this.projectId}
            ]
          }

        },
        methods:{
            refresh(){
                this.$emit("refresh");
            }
        }
    }
</script>

<style scoped>

</style>