<!--
* @program: office_automation 
* @author: ly
* @component:projectAudit 
* @description: 项目创建审核/项目完结审核
* @create: 2022-01-08 15:42

-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-badge :count="ds.length">
                    <a-button  class="search-item" type="default" @click="type = 1">
                        <template #icon><AlertOutlined /></template>
                        项目申请审核
                    </a-button>
                </a-badge>
                <a-badge v-if="enableAction" :count="ds2.length">
                    <a-button  class="search-item" type="default" @click="type = 2">
                        <template #icon><AlertOutlined /></template>
                        项目完结审核
                    </a-button>
                </a-badge>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <div v-if="type ===1" style="margin-left: 30px">申请部门：</div>
                <a-radio-group v-if="type ===1" v-model:value="department" @change="changeDepartment" >
                    <a-radio v-for="(item,index) in departmentList" :value="item" :key="index">{{item}}</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" @change="handleChange" pos="transform">
                <template #left>
                    <TcBaseTableFilter :table-columns="tableColumns" :data-source="dataSource" :view-model="true" :page-size="pageSize">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-tooltip v-if="type === 1" title="审核">
                                    <a-button @click="audit(index,record)" class="action-button" type="link">
                                        <PartitionOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip v-if="type === 2" title="审核">
                                    <a-button @click="audit2(index,record)" class="action-button" type="link">
                                        <PartitionOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </TcBaseTableFilter>
                </template>
                <template #right>
                    <project-tabs ref="local_tabs" @refresh="refresh"/>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <div>意见模板：</div>
        <a-button  type="link" @click="setNote('符合项目审核要求，通过审核')" >
            符合项目审核要求，通过审核
        </a-button>
        <a-button  type="link" style="color: red" @click="setNote('不符合项目审核要求。')" >
            不符合项目审核要求。
        </a-button>
        <FcForm :view-model="false" :form-columns="formColumns" :form-data="formData">
            <template #action="{record}">
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="通过审核吗?"
                        @confirm="accept(record)">
                    <a-button  type="primary" style="margin: 20px">
                        <span style="padding: 0 20px;">通过审核</span>
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="不通过审核吗?"
                        @confirm="refuse(record)">
                    <a-button  type="primary" danger style="margin: 20px">
                        <span style="padding: 0 20px;">不通过审核</span>
                    </a-button>
                </a-popconfirm>
            </template>
        </FcForm>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             :title="null">
        <TagShow color="red" tag="申请项目完结理由" :visible="true">
            <template #content>
                <div>{{formData.describe}}</div>
            </template>
        </TagShow>
        <a-popconfirm
                ok-text="是"
                cancel-text="否"
                class="action-button"
                title="通过审核吗?"
                @confirm="accept2">
            <a-button  type="primary" style="margin: 20px">
                <span style="padding: 0 20px;">通过审核</span>
            </a-button>
        </a-popconfirm>
        <a-popconfirm
                ok-text="是"
                cancel-text="否"
                class="action-button"
                title="不通过审核吗?"
                @confirm="refuse2">
            <a-button  type="primary" danger style="margin: 20px">
                <span style="padding: 0 20px;">不通过审核</span>
            </a-button>
        </a-popconfirm>
    </a-modal>
</template>

<script>
    import TcBaseTableFilter from "../../../components/table/TcBaseTableFilter";
    import FcForm from "../../../components/form/FcForm";
    import TwoParts from "../../_components/TwoParts";
    import { AlertOutlined, SearchOutlined, SyncOutlined,PartitionOutlined} from "@ant-design/icons-vue";
    import item_info from "../../../assets/tables/item_info";
    import itemInfo from "../../../assets/api/itemInfo";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import projectTabs from "./projectTabs";
    import TagShow from "../../_components/TagShow";
    export default {
        name: "projectAudit",
        components: {
            TagShow,
            TcBaseTableFilter,FcForm,TwoParts,SearchOutlined,SyncOutlined,
            PartitionOutlined,AlertOutlined,
            projectTabs
        },
        computed:{
            enableAction(){//显示项目完结审核
                let id = this.$store.getters.userInfo.id;
                return id === "2" || id === "45";
            }
        },
        watch:{
            type(){
                this.groupData()
            }
        },
        data(){
            return{
                dataSource: [],
                tableColumns:[],
                table:new item_info(),
                loading:false,
                pageSize:15,
                comp:"welcome2",
                viewModel:true,
                defaultQuery:{
                    auditState:"0"
                },
                defaultQuery2:{
                    completeFlag:"1"
                },
                visible:false,
                formColumns:[
                    new Column("审核状态","auditState",ColumnType.String,false).setDefaultValue("0").setGroup(3)
                        .setEnum(["0","1","2"],["未审核","已通过","未通过"],["pink","green","red"]).setDefaultValue("0"),
                    new Column("审核时间","auditTime",ColumnType.Date,false).setGroup(3),
                    new Column("审核人","auditUserName",ColumnType.String,false).setGroup(3),
                    new Column("审核意见","auditNote",ColumnType.TextArea,true).setGroup(3),
                ], //提供Form的字段集 this.table.getFormColumns()
                formData:{},//提供Form的formData
                department:null,
                departmentList:null,
                departmentData:{},
                ds:[],// 项目申请审核
                ds2:[],//项目完结审核
                type:null, //1 项目申请审核   2项目完结审核
                visible2:false
            }
        },
        methods:{
            init(){
                this.department = null;
                this.loading = true;
                this.pageSize = 15;
                this.comp = "welcome2";
                itemInfo.queryAll.requestPOST(this,this.defaultQuery,res=>{
                    if(res.data.flag){
                        this.ds = res.data.data;
                    }
                    else{
                        this.ds = [];
                        if(res.data.msg !== "无数据"){
                            this.$message.error(res.data.msg);
                        }
                    }
                    this.loading = false;
                });
                if(this.enableAction){
                    itemInfo.queryAll.requestPOST(this,this.defaultQuery2,res=>{
                        if(res.data.flag){
                            this.ds2 = res.data.data;
                        }
                        else{
                            this.ds2 = [];
                        }
                    });
                }
                setTimeout(()=>{
                    this.groupData()
                },500)
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.tableColumns = [
                        new Column("项目名称","name",ColumnType.String,false).setTableView(200),
                        new Column("操作", "actions", "actions", false).setTableView(80)
                    ]
                } else {
                    this.tableColumns = this.table.getTableColumns();
                }
            },
            refresh(){
                this.init();
            },
            showDetail(record){
                this.viewModel = true;
                if(this.type === 1){
                    this.$refs.local_tabs.handleOpen([
                        {tag:"项目详情",component:"projectInfo",viewModel:true,projectId:record.id},
                    ]);
                }
                else{
                    this.$refs.local_tabs.handleOpen([
                        {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:record.id},
                        {tag:"项目任务",component:"projectTaskInfo",viewModel:true,projectId:record.id},
                        {tag:"项目合同信息",component:"projectContract",viewModel:true,projectId:record.id},
                        {tag:"项目消耗一览",component:"projectUseUp",viewModel:true,projectId:record.id},
                        {tag:"项目收款一览",component:"projectIncome",viewModel:true,projectId:record.id},
                        {tag:"项目支付一览",component:"projectOutSourcePay",viewModel:true,projectId:record.id},
                        {tag:"项目收入发票",component:"projectInvoice",viewModel:true,projectId:record.id},
                        {tag:"项目支出发票",component:"projectInvoicePayoff",viewModel:true,projectId:record.id},
                    ]);
                }

            },
            audit(index,record){
                this.visible = true;
                this.formData = record;
            },
            audit2(index,record){
                this.visible2 = true;
                this.formData = record;
            },
            setNote(text){
                this.formData.auditNote = text
            },
            accept(record){
                record.auditState = "1";
                record.auditTime = new Date().valueOf();
                record.auditUser = this.$store.getters.userInfo.id;
                itemInfo.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            refuse(record){
                record.auditState = "2";
                record.auditTime = new Date().valueOf();
                record.auditUser = this.$store.getters.userInfo.id;
                itemInfo.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            accept2(){
                let data = {
                    completeFlag:"2",
                    id:this.formData.id,
                    describe:this.formData.describe
                };
                itemInfo.audit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible2 = false;
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            refuse2(){
                let data = {
                    completeFlag:"3",
                    id:this.formData.id,
                    describe:this.formData.describe
                };
                itemInfo.audit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible2 = false;
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            groupData(){
                if(!this.type){
                    return
                }
                let data ;
                if(this.type === 1){
                    data = this.ds;
                    this.dataSource = this.ds;
                }
                else{
                    data = this.ds2;
                    this.dataSource = this.ds2;
                }
                this.departmentList = [];
                this.departmentData = {};
                for(let i in data){
                    if(data[i].departName){
                        if(this.departmentList.indexOf(data[i].departName) === -1){
                            this.departmentList.push(data[i].departName);
                            this.departmentData[data[i].departName]=[];
                        }
                    }
                    else{
                        if(this.departmentList.indexOf("其他") === -1){
                            this.departmentList.push("其他");
                            this.departmentData["其他"]=[];
                        }
                    }
                }
                for(let i in data){
                    if(data[i].departName){
                        this.departmentData[data[i].departName].push(data[i])
                    }
                    else{
                        this.departmentData["其他"].push(data[i])
                    }
                }
            },
            changeDepartment(){
                this.dataSource = this.departmentData[this.department];
            }
        },
        created() {
            this.tableColumns = this.table.getTableColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>