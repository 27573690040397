<!--
*@description：成果类型配置编辑
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="45%"
           :destroyOnClose="true"
           style="margin-top: 2%;"
  >

    <ar-form  ref="bill_form" :argument="argument"  :viewModel="viewModel"  />

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
      <a-button v-if="!viewModel" key="back" type="primary" @click="submit">提交</a-button>
    </template>
  </a-modal>
</template>

<script>
import ArForm from "../../../../components/form/ArForm";
import achieveType_api from "../config/achieveType_api";

export default {
  name: "achieveConfigEdit",
  inject:["arguments"],
  components:{
    ArForm
  },
  data(){
    return{
      visible:false,
      modalTitle:null,
      viewModel:null,
      typeId:null,
      argument:null,
    }
  },
  methods:{
    /**
     * 打开配置编辑窗口
     * @param modalTitle  标题
     * @param type        类别：add:新增；edit:编辑
     * @param viewModel   是否可编辑
     * @param typeId      成果类别id
     * @param argument    信息
     */
    open(modalTitle,type,viewModel,typeId,argument){
      this.visible = true;
      this.modalTitle = modalTitle;
      this.type = type;
      this.viewModel = viewModel;
      this.typeId = typeId;
      this.argument = argument;
    },
    getParms(){
        let data = this.$refs.bill_form.getSource();
        data.userId = this.$store.getters.userInfo.id;
        return data;
    },
    /** 提交 **/
    submit(){
      let type = this.type;
      switch (type){
        case "add":{
          let  parms  = this.argument.formData;
          parms.typeId = this.typeId;
          achieveType_api.pz_saveOrUpd.requestPOST(this,parms,res=>{
            this.visible = false;
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.$emit("event",this.$GetEmitData(this.$eventTags.editInfo));
            }
            else{
              this.$message.error(res.data.msg);
            }
          })
          break;
        }
        case "edit":{
          let  parms  = this.argument.formData;
          achieveType_api.pz_saveOrUpd.requestPOST(this,parms,res=>{
            this.visible = false;
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.$emit("event",this.$GetEmitData(this.$eventTags.editInfo));
            }
            else{
              this.$message.error(res.data.msg);
            }
          })
          break;
        }
      }
    },
  }
}
</script>

<style scoped>

</style>