<!--
* @program: office_automation 
* @author: ly
* @component:memberWorkCalendar
* @description:
"workRatio": 1,
"workDate": 1647273600000,
"taskTitle": "白云区重点区域倾斜摄影信息化建设项目",
"processTitle": "工序1",
"workContent": "11",
"remarks": "11",

processEmployeeId:"work" 代表其他工作
* @create: 2022-03-14 10:56
-->
<template>
    <a-spin :spinning="spinning">
        <a-calendar v-model:value="calendarValue" v-if="userId"  mode="month" :locale="locale" @select="showWork">
            <template #headerRender="{ value, type, onChange, onTypeChange }">
                <div style="padding: 10px;display: flex">
                    <a-row type="flex" justify="flex-start" style="width: 400px;font-size: 16px">
                        <a-col>
                            <a-radio-group :value="type" @change="e =>{mode = e.target.value ;return  onTypeChange(e.target.value)}">
                                <a-radio-button value="month">工作日志</a-radio-button>
                            </a-radio-group>
                        </a-col>
                        <a-col>
                            <a-select
                                    :dropdown-match-select-width="false"
                                    class="my-year-select"
                                    :value="String(value.year())"
                                    @change="newYear => {onChange(value.clone().year(newYear));}"
                            >
                                <a-select-option
                                        v-for="val in getYears(value)"
                                        :key="String(val)"
                                        class="year-item"
                                >
                                    {{ val }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col>
                            <a-select
                                    :dropdown-match-select-width="false"
                                    :value="String(value.month())"
                                    @change="selectedMonth => {onChange(value.clone().month(parseInt(selectedMonth, 10)));}"
                            >
                                <a-select-option
                                        v-for="(val, index) in getMonths(value)"
                                        :key="String(index)"
                                        class="month-item"
                                >
                                    {{ val }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </div>
            </template>
            <template #dateCellRender="{ current: value }">
                <ul class="events">
                    <li v-for="(item ,idx) in getTasks(value)" :key="idx">
                        <a-badge  status="success" :text="item" />
                    </li>
                </ul>
            </template>
        </a-calendar>
        <div v-else>
            <div style="height: 100%;overflow: hidden">
                <div style="font-size: 60px;margin-top: 300px" class="non-select">
                    欢迎使用
                </div>
                <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
                    版本：{{$version}}
                </div>
            </div>
        </div>
    </a-spin>
    <a-drawer v-model:visible="visible"
              height="400px"
              placement="bottom"
              :title="null">
        <TcBaseTable :data-source="workList" :table-columns="tc" :view-model="true" :page-size="10"/>
    </a-drawer>


</template>

<script>

    import {InfoCircleOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import workSituation from "../../../assets/api/workSituation";
    import moment from "moment";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";


    export default {
        name: "memberWorkCalendar",
        props:["userId"],
        watch:{
            userId(){
                this.init()
            }
        },
        components: {
            SyncOutlined,TcBaseTable
        },
        data(){
            return{
                mode:"month",
                value:null,
                spinning:false,
                locale:{
                    "lang": {
                        "month": "月",
                        "year": "年",
                    },
                    "dateFormat": "YYYY-MM-DD",
                    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
                    "weekFormat": "YYYY-wo",
                    "monthFormat": "YYYY-MM"
                },
                dateFormat: "YYYY-MM-DD",
                monthFormat: "YYYY-MM",
                workRecords:[],
                calendarValue:null,
                workList:[],
                visible:false,
                tc:[
                    new Column("序号","_index",ColumnType.Index).setTableView(60),
                    new Column("日志时间","workDate",ColumnType.Date).setTableView(100),
                    new Column("记录时间","createTime",ColumnType.Date).setTableView(100),
                    new Column("项目任务","taskTitle",ColumnType.String).setTableView(200),
                    new Column("任务工序","processTitle",ColumnType.String).setTableView(100),
                    new Column("完成进度","workRatio",ColumnType.Number).setNumberRange(0,100).setTableView(80)
                        .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                    new Column("工作内容","workContent",ColumnType.String).setTableView(200),
                    new Column("备注","remarks",ColumnType.String).setTableView(200),
                ]
            }
        },
        methods:{
            init(){
                let data={
                    userId:this.userId,
                };
                this.spinning = true;
                workSituation.queryAll.requestPOST(this,data,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        this.workRecords = res.data.data;
                        for(let i in this.workRecords){
                            if(this.workRecords[i].processEmployeeId === "work"){
                                this.workRecords[i].taskTitle = "其他工作"
                            }
                        }
                    }
                    else{
                        this.workRecords = [];
                    }
                });
            },
            refresh(){
                this.init();
            },
            getMonths(value) {
                const current = value.clone();
                const localeData = value.localeData();
                const months = [];

                for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                }
                return months;
            },
            getYears(value){
                const year = value.year();
                const years = [];

                for (let i = year - 10; i < year + 10; i += 1) {
                    years.push(i);
                }
                return years;
            },
            getTasks(value){
                let works = [];
                let taskTitles = [];
                for(let i in this.workRecords){
                    if(value.isBetween(moment(this.workRecords[i].workDate),moment(this.workRecords[i].workDate).add(1, 'day'))  ){
                        works.push(this.workRecords[i])
                        if(taskTitles.indexOf(this.workRecords[i].taskTitle) === -1){
                            taskTitles.push(this.workRecords[i].taskTitle)
                        }
                    }
                }
                return taskTitles;
            },
            getWorkList(){

            },
            showWork(date){
                this.workList = [];
                let d = date.format(this.dateFormat);
                for(let i in this.workRecords){
                    if(moment(this.workRecords[i].workDate).format(this.dateFormat) === d){
                        this.workList.push(this.workRecords[i])
                    }
                }
                if(this.workList.length >0){
                    this.visible = true;
                }
            },
        },
        created() {
            this.init();
            let time = parseInt(localStorage.getItem("time"));
            this.currentMoment = moment(time);
        }
    }
</script>

<style scoped>

</style>