<!--
* @program: HXQTDZSRemake 
* @author: ly
* @component:ArBaseForm 
* @description: props 为argument
传入的formData 不会随着form的修改而改变
* @create: 2021-10-08 16:42
-->
<template>
    <div>
        <a-form style="margin: 10px" layout="horizontal"
                :label-col="labelCol" :wrapper-col="wrapperCol"
                :size="size"
        >
            <div v-for="(column,index) in cols" :key="index">
                <a-form-item >
                    <template #label>
                        {{column.title}}
                        <a-popover v-if="column.tips" trigger="hover" placement="bottom">
                            <template #content>
                                <a-list size="small" style="margin: 0" bordered :data-source="column.tips">
                                    <template #renderItem="{item}">
                                        <a-list-item>{{item}}</a-list-item>
                                    </template>
                                </a-list>
                            </template>
                            <a-button  type="link" >
                                <template #icon><InfoCircleOutlined /></template>
                            </a-button>
                        </a-popover>
                    </template>
                    <data-present :viewModel="viewModel" :record="source" :column="column" />
                </a-form-item>
                <a-divider v-if="viewModel" style="margin: 0"/>
            </div>
        </a-form>
        <slot v-if="!viewModel" name="action" :record="source" :columns="cols"/>
        <slot name="action2" :record="source" :columns="cols"/>
    </div>
</template>

<script>
    import dataPresent from "../dataPresentType/dataPresent";
    import {InfoCircleOutlined} from "@ant-design/icons-vue";
    import {computed} from "vue";
    export default {
        name: "ArBaseForm",
        props:{
            /**
             argument:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                }
             */
            argument:{required:true},
            spanCol:{required:false},
            viewModel:{required:true}
        },
        provide(){
            return{
                provideTrigger:this.provideTrigger,
                formColumns:computed(()=>this.cols),
                presentType:"form"
            }
        },
        computed:{
            cols(){
                if(!this.viewModel){
                    return  this.argument.formColumns.filter(col=>{
                        return col.editable === true && col.visible;
                    });
                }
                else{
                    return  this.argument.formColumns.filter(col=>col.visible);
                }
            },

        },
        components:{
            dataPresent,InfoCircleOutlined
        },
        data(){
            return{
                labelCol:{ span: 6 },
                wrapperCol:{ span: 14 },
                source:{},
                size:"small",
            }
        },
        methods:{
            getSource(){
                return this.source;
            },
            provideTrigger(action){
                if(action){
                    action(this.source,this.argument.formColumns);
                }
            }
        },
        created() {
            this.source = {...this.argument.formData};
            if(this.spanCol){
                this.labelCol={ span: 10 };
                this.wrapperCol={ span: 10 };
            }
        }
    }
</script>

<style scoped>

</style>