<!--
* @program: tyh-oa 
* @author: ly
* @component:billViewerAllsp7  账单一览/私人账户入账
* @description: 
* @create: 2021-05-27 11:25
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <span>月份：</span>
                <MonthPicker @change="doSearch" style="width: 120px" v-model:value="queryOptions.recordYearMonth" />
                <QueryFilter :filter="filter" v-model:value="queryOptions" @change="doSearch" />
                <a-button  class="search-item" type="link" @click="showTips">
                    <template #icon><InfoCircleOutlined /></template>
                </a-button>
            </div>
            <TwoParts ref="TwoParts" @change="handleChange" pos="transform">
                <template #left>
                    <TcBaseTable ref="bill_table" :view-model="true" :page-size="pageSize" @pageChange="handlePageChange"
                                 :loading="loading" :data-source="dataSource"  :table-columns="columns">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <template #icon>
                                        <SearchOutlined class="pointer"/>
                                    </template>
                                </a-button>
                                <a-popconfirm
                                        @confirm="downloadFlows(record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="导出流水？">
                                    <a-button class="action-button" type="link">
                                        <DownloadOutlined class="pointer"/>
                                    </a-button>
                                </a-popconfirm>
                                <a-tooltip title="查看挂账单">
                                    <a-button @click="showPBill(record)" class="action-button" type="link">
                                        <LinkOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <billTabs ref="local_tabs" @event="eventHandle"/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import item_finance_record from "../../../assets/tables/item_finance_record_2";
    import {dateFormat} from "../../../assets/utils/general";
    import billViewerAll from "../billViewerAll/billViewerAll";
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    import {InfoCircleOutlined,LinkOutlined} from "@ant-design/icons-vue"
    import api_item_finance_record from "../../../assets/api/record";

    export default {
        name: "billViewerAllsp7",
        mixins:[billViewerAll],
        components:{
            InfoCircleOutlined,LinkOutlined
        },
        data(){
            return{
                toolBarTips:"账单入账人专用，可以查看挂在自己名下的私人入账账单",
                table:new item_finance_record(),
                searchOptions:[
                    new SearchSegment("账单标题", "title"),
                    new SearchSegment("用户名", "userId")
                ],
                queryOptions:{
                    //entryState:null, //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                    approvalState:"1", //审核状态（0：未完成；1：已完成）
                    submitState:"1", //提交状态（0：未提交；1：已提交）
                    recordYearMonth:dateFormat('YYYY-MM',new Date()), // 账单所属年月
                    transferUser:this.$store.getters.userInfo.id
                },
            }
        },
        methods:{
            showTips(){
                this.$message.info(this.toolBarTips);
            },
            showPBill(record){
                if(record.pid){
                    let data ={id:record.pid};
                    api_item_finance_record.queryOne.requestPOSTUrlParam(this,data,res=>{
                        if(res.data.flag){
                            this.$refs.local_tabs.handleOpen([
                                {tag:"父账单",component:"personalBillViewer",record:res.data.data},
                            ]);
                        }
                    })
                }
                else{
                    this.$message.error("还未绑定父账单")
                }
            }
        }
    }
</script>

<style scoped>

</style>