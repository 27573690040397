<!--
* @program: tyh-oa 
* @author: ly
* @component:typeCategory 
* @description: 
* @create: 2021-04-19 16:47
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center" >{{categoryFullName}}</div>
    </div>
    <div v-else>
        <a-spin :spinning="spinning"  v-if="editable">
            <a-tree-select
                    v-model:value="record[column.dataIndex]"
                    style="width: 100%"
                    show-search
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    placeholder="输入关键字查询"
                    :replaceFields="replaceFields"
                    :treeData="treeData"
                    :treeNodeFilterProp="'categoryName'"
                    @change="columnTrigger"
                    tree-default-expand-all
                    @select="categoryTrigger"
            >

            </a-tree-select>
        </a-spin>
        <div style="text-align: center" v-else >{{categoryFullName}}</div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import api_category_role from "../../assets/api/categoryRole";
    export default {
        name: "typeCategory",
        mixins:[typeBase],
        computed:{
            categoryFullName(){
                if(this.record["category"]){
                    let pName = this.record["category"].parent.categoryName;
                    return pName + " - " + this.record["category"].categoryName;
                }
                else if(this.record.categoryName){
                    return this.record.categoryName;
                }
                else{
                    return "";
                }
            }
        },
        data(){
            return{
                replaceFields :{
                    title: 'categoryFullName',
                    key:"id",
                    value:"id",
                },
                treeData:[],
                spinning:false,
            }
        },
        methods:{
            categoryInit(){
                this.spinning = true;
                let data = {userId:this.$store.getters.userInfo.id};
                api_category_role.queryCategoryByUser.requestPOSTUrlParam(this,data,res=>{
                    this.spinning =false;
                    if(res.data.flag){
                        this.treeData = res.data.data;
                        let i = 0;
                        while (i < this.treeData.length){//出了收入类与转账类别之外都删除
                            if(this.treeData[i].categoryName.indexOf("收入类") === -1 && this.treeData[i].categoryName.indexOf("转账类") === -1){
                                this.treeData[i]["selectable"]=false;
                                let pName = this.treeData[i]["categoryName"];
                                this.treeData[i]["categoryFullName"]=pName;
                                if(this.treeData[i].children && this.treeData[i].children.length>0){
                                    for(let j in this.treeData[i].children){
                                        this.treeData[i].children[j]["categoryFullName"] = pName +" - "+ this.treeData[i].children[j]["categoryName"];
                                        /**
                                         * 手动触发 categoryTrigger
                                         */
                                        let id = this.treeData[i].children[j].id;
                                        if(id === this.record[this.column.dataIndex] && this.column.categoryTrigger){
                                            if(pName ==="项目消耗类"){
                                                this.provideTrigger(this.column.categoryTrigger[0]);
                                            }
                                            else{
                                                this.provideTrigger(this.column.categoryTrigger[1]);
                                            }
                                        }
                                    }
                                }
                                i++
                            }
                            else{
                                this.treeData.splice(i,1);
                            }
                        }
                    }
                })
            },
            categoryTrigger(value, node, extra){
                if(this.column.categoryTrigger){
                    try{
                        let pName = node.title.split(" - ")[0]
                        if(pName ==="项目消耗类"){
                            this.provideTrigger(this.column.categoryTrigger[0]);
                        }
                        else{
                            this.provideTrigger(this.column.categoryTrigger[1]);
                        }
                    }
                    catch (e) {
                        console.log(e)
                    }

                }
            }
        },
        created() {
            if(!this.viewModel){
                this.categoryInit();
            }

        }
    }
</script>

<style scoped>

</style>