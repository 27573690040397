/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_work_situation  工作日志记录管理
 * @create: 2021-04-25 11:50
 * 用于记录项目任务以外的工作
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_work_situation2 extends Table{
    constructor() {
        super();
        this.name = "item_work_situation2";
        this.CNName = "工作日志记录管理";
        this.columnArray = [
            new Column("日期","workDate",ColumnType.Date,false).setTableView(100),
            new Column("工作内容","workContent",ColumnType.String,true).setTableView(150),
            new Column("备注","remarks",ColumnType.String,true).setTableView(100),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}