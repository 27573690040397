<!--
* @program: tyh-oa 
* @author: ly
* @description: oaHeader  菜单初始化
* @create: 2021-03-19 16:50
-->
<template>
    <div class="header">
        <img  src="../assets/image/logo2.png" class="non-select"  width="64" height="64">
        <h1 class="title non-select">{{$sysName}}</h1>
    </div>
</template>

<script>
    export default {
        name: "oaHeader",
    }
</script>

<style scoped>
    .title{
        font-size: 40px;
        float: right;
        color: white;
        margin-left: 10px;
        font-weight: 900;
        font-family:"楷体","楷体_GB2312",serif;
    }
</style>