import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: sys_attendance
 * @create: 2022-01-14 15:18
 * 按月查询使用
 **/

export default class sys_attendance2 extends Table{
    constructor(name,CNName) {
        super(name,CNName);
        this.name = "sys_attendance2";
        this.CNName = "月度考勤表";
        this.columnArray = [
            new Column("用户名","userName",ColumnType.String,false).setTableView(100),
            new Column("应打卡天数","shouldDay",ColumnType.Number,true),
            new Column("正常天数","normalDay",ColumnType.Number,true),
            new Column("异常天数","unNormalDay",ColumnType.Number,true).setTableView(100),
            new Column("标准工作时长","standHour",ColumnType.Number,true).setTableView(120),
            new Column("实际工作时长","actualHour",ColumnType.Number,true).setTableView(120),
            new Column("异常次数","unNormalCount",ColumnType.Number,true).setTableView(100),
            new Column("迟到次数","delayCount",ColumnType.Number,true).setTableView(100),
            new Column("迟到分钟","delayMinute",ColumnType.Number,true).setTableView(100),
            new Column("早退次数","earlyCount",ColumnType.Number,true).setTableView(100),
            new Column("早退时长","earlyMinute",ColumnType.Number,true).setTableView(100),
            new Column("矿工次数","absentCount",ColumnType.Number,true).setTableView(100),
            new Column("矿工时长","absentMinute",ColumnType.Number,true).setTableView(100),
            new Column("缺卡次数","lackCount",ColumnType.Number,true).setTableView(100),
            new Column("地点异常次数","addrUnNormal",ColumnType.Number,true),
            new Column("设备异常次数","deviceUnNormal",ColumnType.Number,true),
            new Column("补卡次数","repairLackCount",ColumnType.Number,true).setTableView(100),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(60)];
    }
}