import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

/**
 * @description：
 * @author:rzl
 * @date:2022/7/12 11:10
 **/
export default class projectDesignBookAudit_item extends Table{
    constructor() {
        super();
        this.name = "projectDesignBookAudit_item";
        this.CNName = "项目设计书";
        this.columnArray = [
            new Column("项目id","itemId",ColumnType.String,false).setVisible(false),
            new Column("项目名称","itemName",ColumnType.String,true).setTableView(),
            new Column("文档名称","docName",ColumnType.String,true).setTableView(),
            new Column("描述说明","describe",ColumnType.String,true),
            new Column("审核状态","approveState",ColumnType.Enum,false).setTableView(80)
                .setEnum(["0","1","2"],["未审核","通过","不通过"],["cyan","green","red"]),
            new Column("审核意见","approveRemarks",ColumnType.String,true),
            new Column("文件","docFile",ColumnType.PreViewFile,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}