<!--
* @program: tyh-oa 
* @author: ly
* @description: assetsManager 
* @create: 2021-03-22 10:22
-->
<template>
    <a-layout style="height: 100%" >
        <a-layout-sider :collapsible="true" :collapsedWidth="40">
            <a-menu
                    theme="dark"
                    mode="inline"
                    v-model:openKeys="openKeys"
                    v-model:selectedKeys="selectedKeys"
                    @openChange="onOpenChange"
                    @click="handleClick">
                <sub-group-menu v-for="(group,index) in menuLists" :index="index" :groupMenus="group" :key="group.id" />
            </a-menu>
        </a-layout-sider>
        <a-layout-content class="main-window">
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import menuContent from "../../components/menuContent";
    import assetsView from "./assetsView/assetsView";
    import assetsCategorySetting from "./assetsCategorySetting/assetsCategorySetting";
    import assetsAudit from "./assetsAudit/assetsAudit";
    import assetsReturn from "./assetsReturn/assetsReturn";
    import assetsAuditSetting from "./assetsAuditSetting/assetsAuditSetting";

    import fixedAssetsType from "./fixedAssetsMange/fixedAssetsType";
    export default {
        name: "assetsManager",
        mixins:[menuContent],
        components:{
          assetsView,// 设备管理
          assetsCategorySetting,// 设备类别设定
          assetsAudit,// 申请
          assetsReturn,// 归还
          assetsAuditSetting,// 审核流程设定
          fixedAssetsType,// 固定资产类别
        }
    }
</script>

<style scoped>

</style>