<!--
*@description：成果类型管理
*@author:rzl
*@date:2021/6/18 13:45
-->
<template>
  <div class="main-css ant-row">
    <div class="ant-col-6" style="margin-left: 10px;">
      <achieve-type-tree ref="areaTree" @event="selectTree" />
    </div>
    <div class="ant-col-17" style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <achieve-type-tool-bar ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-button type="link" @click="configAttributes(index,record)">
              <template #icon><SettingTwoTone title="配置属性"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <achieve-type-edit ref="local_tabs" @event="eventHandle"/>

    <!-- 属性配置 -->
    <achieve-type-drawer ref="achieveTypeDrawer" @event="eventDrawer"/>
  </div>



</template>

<script>
import Icons from "../../common/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import achieveTypeToolBar from "./components/achieveTypeToolBar";
import achieveTypeTree from "./common/point/achieveTypeTree";
import achieveTypeEdit from "./point/achieveTypeEdit";

import achieveType_api from "./config/achieveType_api";
import achieveType_item from "./config/achieveType_item";
import achieveTypeDrawer from "./components/achieveTypeDrawer";

export default {
  name:"AchieveType",
  mixins:[Icons],
  components:{
    achieveTypeToolBar,ArBaseTable,achieveTypeTree,achieveTypeEdit,achieveTypeDrawer
  },
  data(){
    return {
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new achieveType_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new achieveType_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 10,
      },
      pid:"0",//上级id不能为空，根节点pid传0
      level:"0",// 级别：1,2,3,4.....
    };
  },

  mounted() {
    this.$refs.areaTree.getTreeData();
    this.init();
  },
  methods:{
    /** 初始化 **/
    init(){
      this.argument.loading = true;
      let parms = {
        pid:this.pid,
        name:null,
        level:null,
      };
      this.pageParam.page = 1;
      this.pageParam.limit = 10;
      achieveType_api.queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
        this.argument.loading = false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data;
          this.$refs.pstable.setTotalSize(res.data.count);
        }
      });
    },
    /** 回调方法 **/
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
          this.argument.loading = true;
          let parms = this.$refs.toolBar.getParms();
          parms.pid = this.pid;
          this.pageParam.page = 1;
          this.pageParam.limit = 10;
          this.$refs.pstable.setCurrentPage(1);
          achieveType_api.queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource =  res.data.data;
              this.$refs.pstable.setTotalSize(res.data.count);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          this.$refs.pstable.setCurrentPage(1);
          this.init();
          break;
        }
        case this.$eventTags.add:{
          this.argument.formData = {};
          this.argument.formData.pid = this.pid;
          this.$refs.local_tabs.open("新增信息","add",false,this.pid,this.level,this.argument);
          break;
        }
        case this.$eventTags.editInfo:{
          /** 获取字典树 **/
          this.$refs.areaTree.getTreeData();
          this.$refs.pstable.setCurrentPage(1);
          this.init();
          break;
        }

      }
    },
    /** 分页查询 **/
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      parms.pid = this.pid;
      this.argument.loading = true;
      achieveType_api.queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data;
          this.$refs.pstable.setTotalSize(res.data.count);
        }
      });
    },
    /** 查询树 **/
    selectTree({type,arg}){
      switch (type){
        case this.$eventTags.search:{
          this.pid = arg.id;
          this.level = arg.level;
          this.$refs.toolBar.set_pidName(arg.label);
          this.init();
          break;
        }
      }
    },
    /** 查看详情 **/
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.open("查看信息","show",true,this.pid,this.level, this.argument);
    },
    /** 编辑信息 **/
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.open("编辑信息","edit",false,this.pid,this.level,this.argument);
    },
    /** 配置属性 **/
    configAttributes(index,arg){
      this.$refs.achieveTypeDrawer.open(arg);
    },
    eventDrawer({type,arg}){

    },
    /** 删除信息 **/
    deleteData(index,arg){
      let data = {id:arg.id};
      achieveType_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          /** 获取字典树 **/
          this.$refs.areaTree.getTreeData();
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
  },
};
</script>
<style>

</style>