/** 
* 自动生成文件，请勿修改 
* @name: 系统:登录管理=======修改了（增加通过短信验证码重置密码）
* @description: 登录管理-登录与退出
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const _default = { 
	/** 1 summary:登录	method:post */ 
	login : new APIObject("/api/login.do"), 
	/** 2 summary:微信oepnId及phoneCode登录	method:post */ 
	wxOpneIdLogin : new APIObject("/api/wxOpneIdLogin.do"), 
	/** 3 summary:微信上账号密码登录	method:post */ 
	accountLogin : new APIObject("/api/accountLogin.do"), 
	/** 4 summary:微信登录	method:post */ 
	wxLogin : new APIObject("/api/wxLogin.do"), 
	/** 5 summary:微信openId登录	method:post */ 
	openIdLogin : new APIObject("/api/openIdLogin.do"), 
	/** 6 summary:获取短信验证码	method:get */ 
	getMsgCode : new APIObject("/api/getMsgCode.do"), 
	/** 7 summary:退出登录	method:post */ 
	logout : new APIObject("/api/logout.do"), 
	/** 8 summary:获取图形验证码图片	method:get */ 
	getCaptcha : new APIObject("/api/getCaptcha.do"), 
	/** 9 summary:密码重置	method:post */ 
	resetPwd : new APIObject("/api/resetPwd.do"), 
};
export default _default;