<!--
* @program: office_automation 
* @author: ly
* @component:MyPromotion
* @description: 我的晋升申请
* @create: 2022-03-08 13:51
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="addData" >
                    <template #icon><PlusOutlined /></template>
                </a-button>
                <a-radio-group @change="radioChange" class="search-item" v-model:value="success">
                    <a-radio value="0">待审核</a-radio>
                    <a-radio value="1">未通过</a-radio>
                    <a-radio value="2">通过</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[40,40]" >
                <template #left>
                    <ArBaseTable :argument="argument" :view-model="true" :page-size="pageSize">
                        <template #action="{record,index}">
                            <div class="action-column">
                                <a-button  class="action-button"  type="link" @click="showData(record)">
                                    <SearchOutlined  class="pointer"/>
                                </a-button>
                                <a-button  class="action-button"  type="link" @click="editData(record)">
                                    <EditOutlined  class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        @confirm="cancelSubmit(record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="已审核的申请无法撤销，是否撤销审核？"
                                        v-if="record.submitState">
                                    <a-tooltip title="撤销审核">
                                        <a-button class="action-button" style="color: orange" type="link">
                                            <RollbackOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                                <a-popconfirm
                                        @confirm="submit(record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="提交"
                                        title="提交审核？"
                                        v-else>
                                    <a-tooltip title="提交审核">
                                        <a-button class="action-button" style="color: green" type="link">
                                            <ArrowUpOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        title="确定删除吗?"
                                        v-if="!record.submitState"
                                        @confirm="deleteData(index,record)">
                                    <a-button  class="action-button" style="color: red"  type="link" >
                                        <DeleteOutlined  class="pointer"/>
                                    </a-button>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <div v-if="formArg.formData">
                        <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
                            <template #title>
                                <span style="margin-left: 20px">晋升数据：</span>
                                <a-radio-group v-model:value="comp" >
                                    <a-radio :value="true">晋升表</a-radio>
                                    <a-radio v-if="formArg.formData.id" :value="false">分项得分表</a-radio>
                                </a-radio-group>
                            </template>
                        </a-page-header>
                        <ArForm v-if="comp" :argument="formArg" :view-model="viewModel" >
                            <template #action = "{record}">
                                <a-button @click="saveData(record)"  type="primary">
                                    提交
                                </a-button>
                            </template>
                        </ArForm>
                        <PromotionPoints v-else user-type="user" v-model:value="formArg.formData" :view-model="true" />
                    </div>
                    <welcomePosition v-else/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import ArBaseTable from "../../../../components/table/ArBaseTable";
    import ArForm from "../../../../components/form/ArForm";
    import TwoParts from "../../../_components/TwoParts";
    import {SearchOutlined, SyncOutlined,PlusOutlined,EditOutlined,DeleteOutlined,RollbackOutlined,ArrowUpOutlined} from "@ant-design/icons-vue";
    import promotion_apply from "../../../../assets/tables/promotion_apply";
    import welcomePosition from "../../../welcomePosition";
    import promotionApply from "../../../../assets/api/promotionApply";
    import PromotionPoints from "../../../talentManager/StaffPromotion/StaffPromotionSetting/PromotionPoints";

    export default {
        name: "MyPromotion",
        components: {
            welcomePosition,
            ArBaseTable,ArForm,TwoParts,SearchOutlined,SyncOutlined,PlusOutlined,
            EditOutlined,DeleteOutlined,RollbackOutlined,ArrowUpOutlined,
            PromotionPoints
        },
        data(){
            return{
                table:new promotion_apply(),
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                comp:true,
                success:"0",
                dataSource:[],//所有记录，
            }
        },
        methods:{
            init(){
                let data = {
                    userId:this.$store.getters.userInfo.id,
                };
                this.argument.loading = true;
                this.formArg.formData = null;
                promotionApply.queryAll.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                    this.radioChange()
                })
            },
            refresh(){
                this.init();
            },
            addData(){
                this.formArg.formData = this.table.getFormData();
                this.viewModel = false;
            },
            showData(record){
                this.formArg.formData = record;
                this.viewModel = true;
            },
            editData(record){
                this.viewModel = false;
                this.formArg.formData = record;
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                promotionApply.delete.requestPOSTUrlParam(this,data,res=>{
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index, 1);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            submit(record){
                let data = {
                    id:record.id
                };
                promotionApply.submit.requestPOSTUrlParam(this,data,res=>{
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        record.submitState = true;
                        this.formArg.formData = null
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            cancelSubmit(record){
                let data = {
                    id:record.id
                };
                promotionApply.cancelSubmit.requestPOSTUrlParam(this,data,res=>{
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        record.submitState = false;
                        this.formArg.formData = null
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record){
                promotionApply.saveOrUpd.requestPOST(this,record,res=>{
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.refresh();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            radioChange(){
                this.argument.dataSource = this.dataSource.filter(item=>item.success===this.success);
            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.formArg.formColumns = this.table.getFormColumns();
            this.init();
        }
    }
</script>

<style scoped>

</style>