/** 
* 自动生成文件，请勿修改 
* @name: 项目:项目管理模块
* @description: 项目-项目管理模块-增删改查(二期修改，增加字段类型、类别、预算)
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:25条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const itemInfo = { 
	/** 1 summary:导出-绩效汇总那个大表结果	method:post */ 
	exportReport : new APIObject("/api/itemInfo/exportReport.do"), 
	/** 2 summary:查询-仅当前登录用户创建的（分页）	method:post */ 
	queryPageByUser : new APIObject("/api/itemInfo/queryPageByUser.do"), 
	/** 3 summary:统计各项目下报账支出情况-(导出)	method:get */ 
	exportItemExpend : new APIObject("/api/itemInfo/exportItemExpend.do"), 
	/** 4 summary:更新拟下绩效	method:post */ 
	upd : new APIObject("/api/itemInfo/upd.do"), 
	/** 5 summary:查询-所有项目数据（带出项目的附带数据：收款、合同、兑付、报账、外协付款、任务等）	method:post */ 
	queryAllItem : new APIObject("/api/itemInfo/queryAllItem.do"), 
	/** 6 summary:查询(根据承接公司id查询)-分页	method:post */ 
	queryPageByCompany : new APIObject("/api/itemInfo/queryPageByCompany.do"), 
	/** 7 summary:审核完结申请	method:post */ 
	audit : new APIObject("/api/itemInfo/audit.do"), 
	/** 8 summary:更新兑付	method:post */ 
	updduifu : new APIObject("/api/itemInfo/updduifu.do"), 
	/** 9 summary:查询-项目绩效兑付汇总那个大表结果	method:post */ 
	queryAllExport : new APIObject("/api/itemInfo/queryAllExport.do"), 
	/** 10 summary:项目合并================新需求	method:post */ 
	itemMerge : new APIObject("/api/itemInfo/itemMerge.do"), 
	/** 11 summary:导出项目质量档案excel表-项目质检得分情况-(导出)	method:post */ 
	exportItemQuality : new APIObject("/api/itemInfo/exportItemQuality.do"), 
	/** 12 summary:查询-仅当前登录用户创建的（不分页）	method:post */ 
	queryAllByUser : new APIObject("/api/itemInfo/queryAllByUser.do"), 
	/** 13 summary:保存/修改-单个项目	method:post */ 
	saveOrUpd : new APIObject("/api/itemInfo/saveOrUpd.do"), 
	/** 14 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/itemInfo/queryPage.do"), 
	/** 15 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/itemInfo/batchDelete.do"), 
	/** 16 summary:更新等效合同额	method:post */ 
	updDxhte : new APIObject("/api/itemInfo/updDxhte.do"), 
	/** 17 summary:查询-分页(小程序使用-带出项目的外协支付申请记录List)	method:post */ 
	wxPage : new APIObject("/api/itemInfo/wxPage.do"), 
	/** 18 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/itemInfo/delete.do"), 
	/** 19 summary:查询(根据承接公司id查询)-所有数据	method:post */ 
	queryAllByCompany : new APIObject("/api/itemInfo/queryAllByCompany.do"), 
	/** 20 summary:查询-查询项目的外协合同情况	method:post */ 
	queryOutPay : new APIObject("/api/itemInfo/queryOutPay.do"), 
	/** 21 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/itemInfo/queryOne.do"), 
	/** 22 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/itemInfo/batchSaveOrUpd.do"), 
	/** 23 summary:根据某外协id得到参与的项目及相关的质检结果	method:post */ 
	queryByOutId : new APIObject("/api/itemInfo/queryByOutId.do"), 
	/** 24 summary:导出个人质量档案excel表-某个人参与的项目质检得分情况-(导出)	method:get */ 
	exportPersonQuality : new APIObject("/api/itemInfo/exportPersonQuality.do"), 
	/** 25 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/itemInfo/queryAll.do"), 
};
export default itemInfo;