/**
 * @description：合同信息
 * @author:rzl
 * @date:2022/7/25 14:27
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class itemPerformance extends Table{
    constructor() {
        super();
        this.name = "itemPerformance";
        this.CNName = "项目绩效汇总";
        this.columnArray = [
            new Column("项目年度","year",ColumnType.Number).setTableView(80),
            new Column("项目名称","name",ColumnType.String).setTableView(300),
            new Column("项目类型","itemType",ColumnType.Enum,true).setDefaultValue("1")
                .setEnum(["0","1","2","3"],["自研项目","甲方项目","劳务协作项目","三方项目"],["cyan","blue","pink","green"]),
            new Column("任务大类","itemCategory",ColumnType.Enum,true)
                .setEnum(["0","1","2","3","4"],
                    ["其他","系统研发","工程测量","规划编制","数据处理"],
                    ["red","cyan","blue","pink","yellow"]),//0：其他；1：系统研发；2：工程测量；3：规划编制；4：数据处理
            new Column("合同金额","amountSum",ColumnType.Number).setTableView(100),
            new Column("预算金额","itemBudget",ColumnType.Number).setTableView(100),
            new Column("预下绩效","money",ColumnType.Number),
            new Column("拟下绩效","confirmMoney",ColumnType.Number).setTableView(100),
            new Column("已兑付","payAmountYes",ColumnType.Number).setTableView(100),
            new Column("未兑付","payAmountNo",ColumnType.Number).setTableView(100),
            new Column("承担部门","depName",ColumnType.String).setTableView(150),

        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}