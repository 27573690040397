<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityTabs 
* @description: 
* @create: 2021-07-02 16:02
-->
<template>
    <div class="tab-container">
        <a-tabs v-if="local_visible"  v-model:activeKey="activeKey" :size="size">
            <a-tab-pane v-for="(pane,index) in paneList" :key="index.toString()" :tab="pane.tag" >
                <div v-is="pane.component" @event="eventHandle" :record="pane.record" :viewModel="pane.viewModel"/>
            </a-tab-pane>
        </a-tabs>
        <div v-else style="font-size: 40px;margin: 20vh" class="non-select">
            欢迎使用{{$sysName}}
        </div>
        <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
            版本：{{$version}}
        </div>
    </div>
</template>

<script>
    import baseTabs from "../../../components/tabs/baseTabs";
    import qualityApplyFormView from "../qualityApplyViewer/qualityApplyFormView";
    import qualityTaskStepSet from "../qualityTaskViewer/qualityTaskStepSet";
    import qualityTaskStepArrangement from "../qualityTaskViewer/qualityTaskStepArrangement";
    import qualityTaskStepGroup from "../qualityTaskViewer/qualityTaskStepGroup";
    import qualityReportList from "../qualityReportViewer/qualityReportList";
    import qualityProjectReport from "../qualityReportViewer/qualityProjectReport";
    export default {
        name: "qualityTabs",
        mixins:[baseTabs],
        components:{
            qualityApplyFormView,qualityTaskStepGroup,
            qualityTaskStepArrangement,qualityTaskStepSet,qualityReportList,qualityProjectReport
        }
    }
</script>

<style scoped>

</style>