<!--
* @program: TYH_office_automation 
* @author: ly
* @component:SearBarTwoParts 
* @description: 
* @create: 2021-12-06 16:15
-->
<template>
    <div class="main-local">
        <div ref="leftDiv" class="left-part1">
            <slot ref="leftSlot" name="left"/>
        </div>
        <div style="height: 100%;">
            <div :class="arrowClass">
                <div v-if="enable" v-is="arrowIcon" @click="changeIcon"/>
            </div>
            <a-divider class="middle-line" type="vertical"/>
        </div>
        <div ref="rightDiv" class="right-part1" v-show="rightVisible">
            <slot ref="rightSlot" name="right"/>
        </div>
    </div>
</template>

<script>
    import {RightSquareOutlined,LeftSquareOutlined} from "@ant-design/icons-vue"
    export default {
        name: "SearBarTwoParts",
        emits:["change"],
        props:["enableChange","pos","trans"],
        //enableChange是否打开changeIcon 默认 true
        //pos 默认位置   默认 default
        //trans 左边DIV变换前的宽度和变换后的宽度数组 默认[40，20] [default,transform]
        components:{
            RightSquareOutlined,LeftSquareOutlined
        },
        data(){
            return{
                enable:true,
                defaultPos:40,
                transPos:20,
                currentPos:"default", //default transform
                rightVisible:true,
            }
        },
        computed:{
            arrowIcon(){
                if(this.currentPos === "default"){
                    return this.defaultPos - this.transPos >= 0 ? "LeftSquareOutlined" : "RightSquareOutlined" ;
                }
                else{
                    return this.defaultPos - this.transPos >= 0 ? "RightSquareOutlined" :"LeftSquareOutlined" ;
                }
            },
            arrowClass(){
                if(this.arrowIcon === "LeftSquareOutlined"){
                    return "left-arrow"
                }else{
                    return "right-arrow"
                }
            }
        },
        methods:{
            changeIcon(){
                if(this.currentPos === "default"){
                    this.transform();
                }
                else{
                    this.reset();
                }
            },
            transform(){
                this.currentPos = "transform";
                let right = 100 - this.transPos;
                this.$refs.leftDiv.style.width = this.transPos + "%";
                this.$refs.rightDiv.style.width = right + "%";
                this.rightVisible = right !== 0;
                this.$emit("change","transform");
            },
            reset(){
                this.currentPos = "default";
                let right = 100 - this.defaultPos;
                this.$refs.leftDiv.style.width = this.defaultPos + "%";
                this.$refs.rightDiv.style.width = right + "%";
                this.rightVisible = right !== 0;
                this.$emit("change","reset");
            }
        },
        created() {
            if(this.enableChange === false){
                this.enable = false
            }
            if(this.trans){
                this.defaultPos = this.trans[0];
                this.transPos = this.trans[1];
            }
        },
        mounted() {
            this.reset();
            if(this.pos === "transform"){
                this.transform();
            }
        }
    }
</script>

<style scoped>
    .left-arrow{
        font-size: 20px;
        color: #1890ff
    }
    .right-arrow{
        font-size: 20px;
        color: #00b30c
    }
    .left-part1{
        height: 100%;
        overflow-y: auto;
        transition:width 0.5s;
        -moz-transition:width 0.5s; /* Firefox 4 */
        -webkit-transition:width 0.5s; /* Safari and Chrome */
        -o-transition:width 0.5s; /* Opera */
    }
    .right-part1{
        height: 100%;
        overflow-y: auto;
        transition:width 0.5s;
        -moz-transition:width 0.5s; /* Firefox 4 */
        -webkit-transition:width 0.5s; /* Safari and Chrome */
        -o-transition:width 0.5s; /* Opera */
    }
</style>