<!--
* @program: tyh-oa 
* @author: ly
* @component:projectStatistics 项目一览-数量统计
* @description: 
* @create: 2021-04-02 09:34
-->
<template>
    <a-spin :spinning="spinning">
        <a-page-header style="border: 1px solid rgb(235, 237, 240);padding: 0 20px;">
            <template #title>
                <div class="center-flex">
<!--                    <a-button class="search-item" type="default" shape="circle" @click="init">-->
<!--                        <template #icon><SyncOutlined /></template>-->
<!--                    </a-button>-->
                    <div>项目总览（共{{totalSum}}个）</div>
                    <a-radio-group v-model:value="mode" >
                        <a-radio value="1">按年度统计</a-radio>
                    </a-radio-group>
                    <div>点击数字能够看到具体项目列表</div>
                </div>
            </template>
        </a-page-header>
        <div v-if="visible" style="display: flex;flex-wrap: wrap;">
            <a-card class="project-card" v-for="(item,key) in group" :loading="item.loading" :key="key">
                <template #title>
                    {{item.year?item.year+"年启动项目":"未指定年份"}} ：<a-tag style="cursor: pointer" :color="'cyan'" @click="handleShow(item)">{{item.list.length}}</a-tag>个
                </template>
                <TagShow v-for="(tag,index) in item.groupData"   :color="tag.color" :tag="tag.label" :key="index">
                    <template #content>
                        <div style="display: flex;flex-wrap: wrap;justify-content: center;">
                            <div v-for="(list,idx) in tag.list" :key="idx" style="font-size: 8px;" class="center-flex">{{list.label}}：<a-tag style="cursor: pointer" :color="list.color" @click="handleShow(list)">{{list.list.length}}</a-tag>个 &nbsp;</div>
                        </div>
                    </template>
                </TagShow>
            </a-card>
        </div>
        <div v-else>
            <a-divider orientation="right" style="margin: 0">
                <a-button danger  @click = "topReturn" >返回</a-button></a-divider>
            <ArBaseTableFilter  v-show="tableVisible" :argument="argument" :page-size="15" :view-model="true" :download="true" >
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button class="action-button" type="link" @click="showDetail(index,record)">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button v-if="editable" class="action-button" type="link" @click="editDetail(index,record)">
                            <EditOutlined class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="editable"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </ArBaseTableFilter>
            <a-divider v-show="!tableVisible" orientation="right" style="margin: 0" >
                <a-button  danger @click = "tableVisible = true">返回</a-button>
            </a-divider>
            <project-tabs v-show="!tableVisible" ref="local_tabs"/>
        </div>
    </a-spin>
</template>

<script>
    import projectCategoryShower from "../projectTaskViewer/projectCategoryShower";
    import itemInfo from "../../../assets/api/itemInfo";
    import {DeleteOutlined} from "@ant-design/icons-vue"
    export default {
        name: "projectStatistics",
        mixins:[projectCategoryShower],
        components:{DeleteOutlined},
        props:["editable"],
        methods:{
            showDetail(index,record){
                this.tableVisible = false;
                this.$refs.local_tabs.handleOpen([
                    {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:record.id},
                    {tag:"项目合同信息",component:"projectContract",viewModel:true,projectId:record.id},
                    {tag:"项目消耗一览",component:"projectUseUp",viewModel:true,projectId:record.id},
                    {tag:"项目收款一览",component:"projectIncome",viewModel:true,projectId:record.id},
                    {tag:"项目支付一览",component:"projectOutSourcePay",viewModel:true,projectId:record.id},
                ]);
            },
            editDetail(index,record){
                this.tableVisible = false;
                this.$refs.local_tabs.handleOpen([
                    {tag:"项目基本信息",component:"projectInfo",viewModel:false,projectId:record.id},
                    {tag:"项目合同信息",component:"projectContract",viewModel:false,projectId:record.id},
                    {tag:"项目收款",component:"projectIncome",viewModel:false,projectId:record.id},
                    {tag:"项目支付",component:"projectOutSourcePay",viewModel:false,projectId:record.id},
                ]);
            },
            deleteData(index,record){
                let data = {id:record.id};
                itemInfo.delete.requestPOSTUrlParam(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>