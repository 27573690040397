<!--
* @program: office_automation 
* @author: ly
* @component:SystemSetting 
* @description: 
* @create: 2022-02-18 09:24
-->
<template>
    <a-layout style="height: 100%" >
        <a-layout-sider :collapsible="true" :collapsedWidth="40">
            <a-menu
                    theme="dark"
                    mode="inline"
                    v-model:openKeys="openKeys"
                    v-model:selectedKeys="selectedKeys"
                    @openChange="onOpenChange"
                    @click="handleClick">
                <sub-group-menu v-for="(group,index) in menuLists" :index="index" :groupMenus="group" :key="group.id" />
            </a-menu>
        </a-layout-sider>
        <a-layout-content class="main-window">
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import menuViewer from "./Settings/menuViewer";
    import roleMenuSetting from "./Settings/roleMenuSetting";
    import BillCategorySetting from "./Settings/BillCategorySetting";
    import AuditSetting from "../financialManager/AuditSetting/AuditSetting";
    import projectPayStepSetting from "./Settings/projectPayStepSetting";
    import menuContent from "../../components/menuContent";
    import SMSSetting from "./SMSSetting/SMSSetting";
    import SoftwareCategory from "./SoftwareCategory/SoftwareCategory";
    import companySystemMange from "./companySystemMange/companySystemMange";
    import appMenuViewer from "./Settings/appMenuViewer";

    export default {
        name: "SystemSetting",
        mixins:[menuContent],
        components:{
            menuViewer,//菜单设定
            roleMenuSetting,//角色设定
            BillCategorySetting,//内部账务类别设定
            AuditSetting,//资金账户支付审核设定
            projectPayStepSetting, //菜单管理-项目支付审核设定
            SMSSetting, //短信发送设定
            SoftwareCategory, //工具软件类别设置
          companySystemMange,// 公司系统管理
            appMenuViewer //微信小程序app菜单设定
        }
    }
</script>

<style scoped>

</style>