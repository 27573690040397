import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @description：
 * @author:rzl
 * @date:2022/7/12 11:10
 **/
export default class projectDesignBook_item extends Table{
    constructor() {
        super();
        this.name = "projectDesignBook_item";
        this.CNName = "项目设计书";
        this.columnArray = [
            new Column("文档名称","docName",ColumnType.String,true),
            new Column("描述说明","describe",ColumnType.String,true),
            new Column("文件","docFile",ColumnType.PreViewFile,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}