/**
 * @program: tyh-oa
 * @author: ly
 * @description: APIObject
 * @create: 2021-03-19 14:17
 **/
import axios from "axios";
import conf from "./conf";
axios.defaults.withCredentials = true;

class APIObject {
    constructor(url) {
        this.url = APIObject.baseUrl()  + url;
    }
    static baseUrl(){
        return conf.apiBaseUrl;
    }
    /**
     *
     * @param data 传入参数
     * @param callback 回调函数
     * @param _this vue的实例  若传入则会提示catch
     */
    requestGET(_this,data,callback){
        axios.get(this.url,{params:data})
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.msg);
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.flag){
                        _this.$message.success(res.data.msg);
                    }
                    else{
                        _this.$message.error(res.data.msg);
                    }
                }
            })
            .catch(error=>{
                if(_this){
                    _this.$message.error({content:error.toString(),key:"APIObject"});
                }})
    }
    /**
     *
     * @param data 传入参数
     * @param callback 回调函数
     * @param _this vue的实例 若传入则会提示catch
     */
    requestPOST(_this,data,callback){
        axios.post(this.url,data)
            .then(res=> {
                    if(res.data.code === 401){
                        _this.$message.error(res.data.msg);
                    }
                    if (callback) {
                        callback(res)
                    } else {
                        if(res.data.flag){
                            _this.$message.success(res.data.msg);
                        }
                        else{
                            _this.$message.error(res.data.msg);
                        }
                    }
                }
                )
            .catch(error=>{
                if(_this){
                    _this.$message.error({content:error.toString(),key:"APIObject"});
                }})
    }
    async requestPOSTAsync(data){
        let res = await axios.post(this.url,data);
        return res.data;
    }
    async requestPOSTUrlAsync(data){
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);

        let res = await axios.post(url,data);
        return res.data;
    }
    /**
     * POST情况下用url传递参数
     * @param data 传入参数
     * @param callback 回调函数
     * @param _this vue的实例 若传入则会提示catch
     */
    requestPOSTUrlParam(_this,data,callback){
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);
        axios.post(url)
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.msg);
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.flag){
                        _this.$message.success(res.data.msg);
                    }
                    else{
                        _this.$message.error(res.data.msg);
                    }
                }
            })
            .catch(error=>{
                if(_this){
                    _this.$message.error({content:error.toString(),key:"APIObject"});
                }})
    }
    requestPOSTBothParam(_this,{urlParam,formParam},callback){
        let url = this.url+"?";
        for(let i in urlParam){
            if(urlParam[i] !== null && urlParam[i] !==""){
                let str = i+"="+urlParam[i];
                url += str + "&";
            }
        }
        url = url.substr(0,url.length-1);
        axios.post(url,formParam)
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.msg);
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.flag){
                        _this.$message.success(res.data.msg);
                    }
                    else{
                        _this.$message.error(res.data.msg);
                    }
                }
            })
            .catch(error=>{
                if(_this){
                    _this.$message.error({content:error.toString(),key:"APIObject"});
                }})
    }
    fileDownload(_this,data,fileName){//下载文件使用
        let url = this.url+"?";
        let suffix = '';
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
            let s = data[i];
            suffix = s.substr(s.lastIndexOf(".") );
        }
        url = url.substr(0,url.length-1);
        axios({
            url,
            responseType: 'blob',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then(res =>{
            if(res.data.code === 401){
                _this.$message.error(res.data.msg);
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    _this.$message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName + suffix ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(error=>{
            if(_this){
                _this.$message.error({content:error.toString(),key:"APIObject"});
            }});
    }
    fileDownloadPost(_this,data,fileName){//下载文件使用
        let url = this.url;
        axios({
            url,
            responseType: 'blob',
            method: 'POST',
            data: data, // 参数
            headers: {
                'Content-Type':  'application/json;charset=UTF-8'
            }
        }).then(res =>{
            if(res.data.code === 401){
                _this.$message.error(res.data.msg);
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    _this.$message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(error=>{
            if(_this){
                _this.$message.error({content:error.toString(),key:"APIObject"});
            }});
    }
    fileDownloadGet(_this,data,fileName){//下载文件使用
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);
        axios({
            url,
            responseType: 'blob',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then(res =>{
            if(res.data.code === 401){
                _this.$message.error(res.data.msg);
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    _this.$message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(error=>{
            if(_this){
                _this.$message.error({content:error.toString(),key:"APIObject"});
            }});
    }

    getUrl(url){
        return APIObject.baseUrl() + url;
    }
}


export default APIObject;