/** 
* 自动生成文件，请勿修改 
* @name: 项目:质检申请模块
* @description: 质检申请模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const qcApply = { 
	/** 1 summary:查询-不分页	method:post */ 
	queryAll : new APIObject("/api/qcApply/queryAll.do"), 
	/** 2 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/qcApply/queryPage.do"), 
	/** 3 summary:质检完成状态更改-单条	method:post */ 
	complete : new APIObject("/api/qcApply/complete.do"), 
	/** 4 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/qcApply/batchSaveOrUpd.do"), 
	/** 5 summary:审核-单条	method:post */ 
	audit : new APIObject("/api/qcApply/audit.do"), 
	/** 6 summary:保存/修改-单个	method:post */ 
	saveOrUpd : new APIObject("/api/qcApply/saveOrUpd.do"), 
	/** 7 summary:绑定质检负责人-单条	method:post */ 
	bindQcLeader : new APIObject("/api/qcApply/bindQcLeader.do"), 
	/** 8 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/qcApply/queryOne.do"), 
	/** 9 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/qcApply/batchDelete.do"), 
	/** 10 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/qcApply/delete.do"), 
};
export default qcApply;