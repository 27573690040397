/** 
* 自动生成文件，请勿修改 
* @name: 资产:我的设备模块
* @description: 我的设备模块-根据用户id查询其借用过的所有设备
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:2条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const myDevice = { 
	/** 1 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/myDevice/queryPage.do"), 
	/** 2 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/myDevice/queryAll.do"), 
};
export default myDevice;