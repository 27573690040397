/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_qc_report_apply  项目质检报告管理
 * @create: 2021-04-25 11:30
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_qc_report_apply extends Table{
    constructor() {
        super();
        this.name = "item_qc_report_apply";
        this.CNName = "项目质检报告管理";
        this.columnArray = [
            new Column("报告评价","assessment",ColumnType.Enum,true).setTableView(100)
                .setEnum(["0","1","2","3"],["不合格","合格","良好","优秀"],["red","pink","gold","green"])
                .setTriggerOtherColumn("0",
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="qcNextDate"})[0].setVisible(true);
                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="qcNextDate"})[0].setVisible(false);
                    }),
            new Column("下次质检时间","qcNextDate",ColumnType.Date,true).setTableView(100),
            new Column("质检总结","qcSummary",ColumnType.String,true).setTableView(100),
            new Column("报告附件","enclosureUrl",ColumnType.PreViewFile,true).setTableView(100),
            new Column("额外说明","remarks",ColumnType.String,true).setTableView(100),
        ];
    }
}