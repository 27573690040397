<!--
* @program: TYH_office_automation 
* @author: ly
* @component:departmentTaskViewer 
* @description: 部门任务管理
* @create: 2021-07-01 10:22
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar v-model:value="searchData" :search-options="searchOptions" />
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <div style="margin: 10px;color: black">部门:</div>
                <a-select v-model:value="defaultOptions.departmentId"
                          style="width: 200px"
                          class="search-item"
                          @change="selectDepartment"
                >
                    <a-select-option v-for="department in departments" :key="department.id">
                        {{ department.name }}
                    </a-select-option>
                </a-select>
                <a-radio-group @change="radioChange" class="search-item" v-model:value="defaultOptions.state">
                    <a-radio :value="'3'">未完成</a-radio>
                    <a-radio :value="'0'">按时完成</a-radio>
                    <a-radio :value="'1'">提前完成</a-radio>
                    <a-radio :value="'2'">延期完成</a-radio>
                    <a-radio :value="null">全部</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" @change="handleChange" :trans="[45,35]" pos="transform">
                <template #left>
                    <TcBaseTable :view-model="true" :page-size="pageSize" :download="true"
                                 :data-source="dataSource" :table-columns="tableColumns" ref="project_table" :loading="loading">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button  class="action-button" type="link" @click="showDetail(index,record)">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-tooltip v-if="!record.state" title="任务工作">
                                    <a-button  class="action-button" type="link" @click="editDetail(index,record)">
                                        <ApartmentOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
<!--                                v-if="!record.state"-->
                                <a-tooltip   title="提交任务完成">
                                    <a-button  class="action-button" type="link" @click="finishTask(index,record)">
                                        <CheckOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip v-if="!record.state" title="任务延期申请">
                                    <a-button  class="action-button" type="link" @click="delayApply(index,record)">
                                        <FallOutlined  style="color: red" class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip v-if="record.state"  title="任务评分">
                                    <a-button  class="action-button" type="link" @click="workScoreSet(index,record)">
                                        <FormOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <project-tabs ref="local_tabs" @refresh="refresh"/>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible3"
             destroy-on-close
             title="提交任务完成情况"
             :width="800"
             :footer="null">
        <span>完成时间：</span><DatePicker style="width: 200px" v-model:value="taskDate" />
        <ArBaseForm :view-model="false" :argument="formArg" >
            <template #action="{record}">
                <a-button type="primary" @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
    <a-modal v-model:visible="visible4"
             destroy-on-close
             :footer="null"
             :width="1000"
             title="任务延期申请">
        <delayApply :record="currentRecord" type="1" />
    </a-modal>

  <!-- 每月进度 start -->
  <a-modal v-model:visible="visible_taskMonths"
           destroy-on-close
           :footer="null"
           width="30%"
           :maskClosable="true"
           :closable="false"
           :title="title_taskMonths">

    <div style="height: 500px;overflow-y: auto" >
      <a-timeline >
        <a-timeline-item v-for="(line,index) in taskMonthsData"  v-bind:key="index"    style="width: 90%;margin-top: 9px;margin-left: 15px;">
          <div  style="height: 200px;background-color:#f0f0f0;">
            <div class="ant-row" >
              <div class="ant-col-10">
                <p style="color: #faad14;font-size: 16px;">时间：{{line.yearMonth}}</p>
              </div>
              <div class="ant-col-8">
                <p style="font-size: 16px; color: #52c41a;">占比：{{line.completeRatio}}%</p>
              </div>
            </div>
            <div class="ant-row" style="height: 82%;background-color: #d9d9d9;overflow: auto;flex-flow: wrap!important;">
              <div class="ant-col-8" v-for="(info,in_ex) in taskMonthsData[index].personList" :key="in_ex">
                <a-tag  color="#2db7f5" style="height: 30px;margin: 10px;font-size: 15px;" >
                  {{info.userName}}（{{info.completeRatio}}%）
                </a-tag>
              </div>
            </div>
          </div>
        </a-timeline-item>
      </a-timeline>
    </div>


  </a-modal>
  <!-- 每月进度 end -->

</template>

<script>
import {  h } from 'vue';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
    import { notification } from 'ant-design-vue';
    import item_task2 from "/src/assets/tables/item_task2"
    import api_item_task from "/src/assets/api/itemTask"
    import projectTabs from "../projectViewer/projectTabs";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import TwoParts from "../../_components/TwoParts";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import {SearchOutlined,CheckOutlined,SyncOutlined,FallOutlined,ApartmentOutlined,FormOutlined} from "@ant-design/icons-vue";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import delayApply from "../projectDelayManager/delayApply";
    import DatePicker from "../../_components/DatePicker";
    import userInfo from "../../../assets/api/userInfo";
    export default {
        name: "departmentTaskViewer",
        components:{
            CheckOutlined,SyncOutlined, projectTabs,
            TwoParts,TcBaseTable, SearchOutlined,
            SearchBar,ArBaseForm,FallOutlined,ApartmentOutlined,FormOutlined,
            delayApply,DatePicker
        },
        data(){
            return{
                dataSource: [],
                tableColumns:[],
                currentIndex:null,
                searchOptions:[
                    {value:"queryText",label:"关键字"}],
                searchData:null,
                authority:[],
               /* table:new item_task2(),*/
                table:null,
                loading:false,
                pageSize:15,
                departments:null,
                defaultOptions:{
                    departmentId:null,
                    state:"3"
                },
                visible2:false,//质检发起申请modal
                currentRecord:null,
                visible3:false,

                formArg:{
                    formColumns:[
                        new Column("完成情况说明","describe",ColumnType.TextArea,true),
                    ], //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible4:false,
                taskDate:null,

              visible_taskMonths:false,
              title_taskMonths:'月进度详情',
              taskMonthsData:[],
            }
        },
        created() {
          this.table = [
            new Column("任务类型","pid",ColumnType.Enum,false).setTableView(100)
                .setEnum(["0"],["主任务","子任务"],["green","red"]),
            new Column("任务标题","taskTitle",ColumnType.String,true).setTableView(200),
            new Column("是否监管项目","monitorFlag",ColumnType.Enum,true).setTableView(120)
                .setEnum([true,false],["是","否"],["red","#79CDCD"]).setDefaultValue(false),
            new Column("任务内容","content",ColumnType.String,true),
            new Column("进度","completeRatio",ColumnType.Number).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', ''))
                .setCustomCell((record,index) => {
                  return {
                    style: {
                      //可以定义样式
                      'color': '#096dd9',
                      'font-size': '15px',
                      'cursor': 'pointer',
                    },
                  };
                })
                .setClickHandler((record,column) => {
                  this.taskMonth(record,column);
                }),
            // new Column("分配部门","departmentId",ColumnType.IdReplace,true).setTableView(100)
            //     .setIdReplaceObject("departmentName",{api:api_sys_department.queryAll,tag:"queryText"}),
            new Column("任务负责人","taskLeader",ColumnType.IdReplace,true).setTableView(120)
                .setIdReplaceObject("taskLeaderName",{api:userInfo.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("任务开始时间","startDate",ColumnType.Date,true),
            new Column("预计完成时间","endDate",ColumnType.Date,true).setTableView(100),
            new Column("实际完成时间","completeDate",ColumnType.Date,false),
            new Column("完成状态","state",ColumnType.Enum,false)
                .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"]),
            new Column("完成情况说明","describe",ColumnType.String,false).setVisible(false),
            new Column("任务分配绩效","money",ColumnType.Number,false).setDefaultValue(0).setTableView(100),
            new Column("作业绩效","depManagExp",ColumnType.Number,false),
            new Column("负责人绩效","leaderPercent",ColumnType.Number,false),
            new Column("备注","remarks",ColumnType.String,true),
            new Column("操作","actions","actions").setTableView(120)
          ];
          this.tableColumns = this.table;
          this.departments = this.$store.getters.userInfo.departmentDTOList;
          if(this.departments.length>0){
            this.defaultOptions.departmentId = this.departments[0].id;
          }
          this.openInit();
        },
        methods:{
          openInit(){
            api_item_task.queryAll.requestPOSTUrlParam(this,this.defaultOptions,res=>{
              if(res.data.flag){
                let list = res.data.data;
                if(list.length > 0){
                  let itemNames = "";
                  list.forEach((item,index)=>{
                    let monitorFlag = item.monitorFlag;
                    if(monitorFlag){
                      itemNames += item.itemName +";";
                    }
                  })
                  if(itemNames.length > 0){
                    this.openNotification(itemNames);
                  }
                }
              }
            })
          },
          openNotification(itemNames){
            notification.open({
              message: '以下为监管项目，请及时处理！',
              key:'item_jg',
              placement:'bottomRight',
              duration:'3',
              icon: h(InfoCircleOutlined, {
                style: 'color: red',
              }),
              style: {
                height:'100px',
                marginBottom:'1px',
                overflow:"auto",
                backgroundColor:'#bfbfbf',

              },
              description:itemNames,

            });
          },
            init(){
                this.searchData = null;
                this.loading = true;
                api_item_task.queryAll.requestPOSTUrlParam(this,this.defaultOptions,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            radioChange(){
                this.loading = true;
                api_item_task.queryAll.requestPOSTUrlParam(this,this.defaultOptions,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            showDetail(index,record){
                this.currentRecord = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"任务详情",component:"projectTaskInfo",viewModel:true,projectId:record.itemId},
                    {tag:"任务工序",component:"taskProcessList",viewModel:true,record:record},
                    {tag:"任务分配",component:"departmentArrangement",viewModel:false,record:record},
                    {tag:"月度绩效分配",component:"MonthProcessArrange",viewModel:false,record:record},
                    {tag:"年度绩效分配",component:"YearMoneyArrange",viewModel:false,record:record},
                    // {tag:"质检报告",component:"qualityCheckResult",viewModel:true,projectId:record.itemId},
                    // {tag:"成员生产质量评价",component:"qualityUserScoreView",viewModel:true,projectId:record.itemId},
                    {tag:"支付申请",component:"departmentPayApply",viewModel:false,projectId:record.itemId},
                    {tag:"用章申请",component:"ProjectSealApply",viewModel:false,projectId:record.itemId},
                    {tag:"质检情况",component:"QCManager",viewModel:false,projectId:record.itemId},
                ]);
            },
            editDetail(index,record){
                this.currentRecord = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"任务工序",component:"taskProcessList",viewModel:false,record:record},
                    {tag:"任务分配",component:"departmentArrangement",viewModel:false,record:record},
                    {tag:"月度绩效分配",component:"MonthProcessArrange",viewModel:false,record:record},
                    {tag:"年度绩效分配",component:"YearMoneyArrange",viewModel:false,record:record},
                    {tag:"支付申请",component:"departmentPayApply",viewModel:false,projectId:record.itemId},
                    {tag:"用章申请",component:"ProjectSealApply",viewModel:false,projectId:record.itemId},
                    {tag:"质检情况",component:"QCManager",viewModel:false,projectId:record.itemId},
                ]);
            },
            qcApply(index,record){
                this.currentIndex = index;
                this.currentRecord = record;
                this.visible2 = true;
            },
            selectDepartment(){
                this.loading = true;
                api_item_task.queryAll.requestPOSTUrlParam(this,this.defaultOptions,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            refresh(){
                this.init();
            },
            doSearch(){
                let arg = {};
                Object.assign(arg,this.defaultOptions);
                Object.assign(arg,this.searchData);
                this.loading = true;
                api_item_task.queryAll.requestPOSTUrlParam(this,arg,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.tableColumns = [
                        new Column("任务类型","pid",ColumnType.Enum,false).setTableView(100)
                            .setEnum(["0"],["主任务","子任务"],["green","red"]),
                        new Column("任务标题","taskTitle",ColumnType.String,false).setTableView(200),
                      new Column("是否监管项目","monitorFlag",ColumnType.Enum,true).setTableView(120)
                          .setEnum([true,false],["是","否"],["red","#79CDCD"]).setDefaultValue(false),
                        new Column("操作", "actions", "actions", false).setTableView(150)
                    ]
                } else {
                    this.tableColumns = this.table;
                }
            },
            finishTask(index,record){
                this.visible3 = true;
                this.formArg.formData = record;
            },
            saveData(record){
                /** 以当前时间为完成时间
                 let time = localStorage.getItem("time");
                 record.completeDate = parseInt(time);
                 */
                record.completeDate = new Date(this.taskDate).valueOf();
                api_item_task.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.visible3 = false;
                        this.$message.success(res.data.msg);
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            delayApply(index,record){
                this.currentRecord = record;
                this.visible4 = true;
            },
            workScoreSet(index,record){
                this.currentRecord = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"成员生产质量评价",component:"qualityUserScore",viewModel:false,projectId:record.itemId},
                ]);
            },
          /*** 查询每月进度 **/
          taskMonth(record,column){
            this.title_taskMonths = record.itemName+"：月进度详情（"+record.completeRatio+"%)";
            let completeRatio = record.completeRatio;
            if(completeRatio == null || completeRatio == 0){
              this.$message.info("项目还未有进度！");
              return;
            }
            let parms = {
              id:record.id
            };
            api_item_task.queryOne.requestPOSTUrlParam(this,parms,res=>{
              if(res.data.flag){
                this.taskMonthsData = res.data.data.taskMonths;
                // 排序
                this.taskMonthsData.sort(function(a,b){
                  return a.yearMonth < b.yearMonth ? 1 : -1
                });
                this.visible_taskMonths = true;
              }
              else{
                this.$message.error(res.data.msg);
              }
            })
          },

        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>

</style>