/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_info 项目一览用
 * 甲方项目使用
 * @create: 2021-04-07 18:47
 *
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import api_sys_user_info from  "../api/userInfo"
import partyInfo from "../api/partyInfo";
export default class item_info extends Table{
    constructor() {
        super();
        this.name = "item_info";
        this.CNName = "项目详情";
        this.columnArray = [
            new Column("项目类型","itemType",ColumnType.Enum,true).setDefaultValue("1").setTableView(90)
                .setEnum(["0","1","2","3"],["自研项目","甲方项目","劳务协作项目","三方项目"],["cyan","blue","pink","green"])
                .setTriggerOtherColumn("0",
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="itemBudget"})[0].setTitle("项目预算");
                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="itemBudget"})[0].setTitle("项目金额");
                    }),
            new Column("任务大类","itemCategory",ColumnType.Enum,true)
                .setEnum(["0","1","2","3","4"],
                    ["其他","系统研发","工程测量","规划编制","数据处理"],
                    ["red","cyan","blue","pink","yellow"]),//0：其他；1：系统研发；2：工程测量；3：规划编制；4：数据处理
            new Column("项目名称","name",ColumnType.String,true).setTableView(250),
            new Column("年度","year",ColumnType.String,true).setTableView(80),
            new Column("分配部门","departmentName",ColumnType.String,false).setTableView(150),
            new Column("甲方名称","partyInfoId",ColumnType.IdReplace,true).setTableView(150)
                .setIdReplaceObject("partyInfoName",{api:partyInfo.queryAll,tag:"queryText"}),
            // new Column("项目负责人","itemLeader",ColumnType.IdReplace,true).setTableView(80)
            //     .setIdReplaceObject("itemLeaderName",{api:api_sys_user_info.queryAll,tag:"queryText"}),
            new Column("合同","contractState",ColumnType.String,true).setGroup(2).setTableView(80)
                .setTableView(80).setEnum(["0","1","2"],["无","有","不需要"],["pink","green","cyan"]).setDefaultValue("0"),
            new Column("签订公司","sigCompanyName",ColumnType.String).setTableView(120),
            new Column("收款责任人","collectUser",ColumnType.String,true).setTableView(120),
            new Column("项目内容","content",ColumnType.String,true),
            new Column("创建时间","createTime",ColumnType.Date,true).setTableView(80),
            new Column("启动时间","startTime",ColumnType.Date,true).setTableView(80),
            new Column("预计完成时间","endDate",ColumnType.Date,true).setTableView(100),


            new Column("开标日期","tenderDate",ColumnType.Date,true).setGroup(2),
            new Column("招标方式","tenderWay",ColumnType.Enum,true).setGroup(2)
                .setEnum(["1","2","3"],["公开招标","邀请招标","议标"],["blue","orange","purple"]),
            new Column("投标保证金","margin",ColumnType.Number,true).setGroup(2),
            new Column("保证金退还日期","marginRefundTime",ColumnType.Date,true).setGroup(2),
            new Column("质保金","retentionMoney",ColumnType.Number,true).setGroup(2),
            new Column("质保金退还日期","zbjRefundTime",ColumnType.Date,true).setGroup(2),
            new Column("收款状态","collectionState",ColumnType.Enum,true).setGroup(2).setTableView(80)
                .setEnum(["0","1"],["未完成","已完成"],["pink","green"]).setDefaultValue("0"),


            new Column("归档编号","archiveCode",ColumnType.String,true).setGroup(1),
            new Column("归档提交时间","archiveSubmitTime",ColumnType.Date,true).setGroup(1),
            new Column("归档提交人","archiveSubmitUser",ColumnType.IdReplace,true).setGroup(1)
                .setIdReplaceObject("archiveSubmitUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("归档接收时间","archiveReceiveTime",ColumnType.Date,true).setGroup(1),
            new Column("归档接收人","archiveReceiveUser",ColumnType.IdReplace,true).setGroup(1)
                .setIdReplaceObject("archiveReceiveUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("预算金额","itemBudget",ColumnType.Number,false).setDefaultValue(0).setTableView(100),//项目金额
            new Column("总合同额","sumAmount",ColumnType.Number,false).setTableView(100),
            new Column("等效合同额","dxhte",ColumnType.Number,false).setTableView(100),
            new Column("已收款额","sumCollect",ColumnType.Number,false).setTableView(120),
            new Column("未收款额","sumCollectNo",ColumnType.Number,false).setTableView(120),
            new Column("项目状态","itemState",ColumnType.Enum,true).setTableView(90)
                .setEnum(["0","1"],["未完结","已完结"],["pink","green"]).setDefaultValue("0"),

            new Column("是否监管项目","monitorFlag",ColumnType.Enum,true)
                .setEnum([true,false],["是","否"],["red","#79CDCD"]).setDefaultValue(false),

            new Column("质检状态","qualityState",ColumnType.Enum,true)
                .setEnum(["0","1","2"],["未质检","未通过","已通过"],["pink","red","green"]).setDefaultValue("0"),

            new Column("创建人","createUserName",ColumnType.String).setGroup(3),
            new Column("创建部门","departName",ColumnType.String).setGroup(3).setTableView(90),
            new Column("审核人","auditUserName",ColumnType.String).setGroup(3),
            new Column("审核时间","auditTime",ColumnType.Date).setGroup(3),
            new Column("审核状态","auditState",ColumnType.Enum).setDefaultValue("0").setGroup(3)
                .setEnum(["0","1","2"],["未审核","已通过","未通过"],["pink","green","red"]).setDefaultValue("0"),
            new Column("审核意见","auditNote",ColumnType.String).setGroup(3),

            new Column("项目完结审核","completeFlag",ColumnType.Enum).setGroup(4)
                .setEnum(["0","1","2","3"],["未申请","申请中","通过审核","未通过审核"],["pink","orange","green","red"]),
            new Column("归档状态","archiveState",ColumnType.Enum,true).setDefaultValue("0").setGroup(1).setTableView(90)
                .setEnum(["0","1"],["未归档","已归档"],["pink","green"]),
            new Column("项目完全说明","describe",ColumnType.String).setGroup(4),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(100).setPosition("right")];
    }
}