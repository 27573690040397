
/**
 * @program: office_automation
 * @author: ly
 * @description: item_finance_income
 * 其他收入记录
 * @create: 2022-01-25 11:52
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";
import api_item_info from "../api/itemInfo";

export default class item_finance_income extends Table{
    constructor() {
        super();
        this.name = "item_finance_income";
        this.CNName = "其他收入记录";
        this.columnArray = [
            new Column("所属项目","itemId",ColumnType.IdReplace2,true)
                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                        "startTime": "", "tenderWay": ""
                    }}),
            new Column("收入类别","categoryId",ColumnType.FinancialSubject,true).setTableView(100),
            new Column("收入日期","collectDate",ColumnType.Date,true).setTableView(100),
            new Column("银行名称","bankName",ColumnType.String,false),
            new Column("办卡支行名称","subBank",ColumnType.String,false),
            new Column("入账账户","bankId",ColumnType.Selector,true).setTableView(250)
                .setSelectorObject("bankNum",[]),
            new Column("收款方式","collectType",ColumnType.Enum,true).setTableView(100)
                .setEnum(["1","2"],["现金","对公打款"]),
            new Column("金额（元）","collectAmount",ColumnType.Number,true).setTableView(100),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}