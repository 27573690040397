<!--
* @program: office_automation 
* @author: ly
* @component:AllMembersMonthMoney 
* @description: 部门所有成员月绩效分配一览
* @create: 2022-04-26 15:59
-->
<template>
    <div>
        <span style="margin: 10px">开始月份</span><month-picker @change="submit" style="width: 150px" v-model:value="startMonth" />
        <span style="margin: 10px">结束月份</span><month-picker @change="submit" style="width: 150px" v-model:value="endMonth" />
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
          <template #icon><SyncOutlined /></template>
        </a-button>
<!--        <a-button  type="primary"  @click="submit">确定</a-button>-->
        <TcBaseTable :title="true" :data-source="dataSource" :table-columns="tableColumns" :view-model="true" :page-size="15">
            <template #title>
                ∑合计:{{allSum.toFixed(2)}}
            </template>
        </TcBaseTable>
    </div>
</template>

<script>
    import {SyncOutlined} from "@ant-design/icons-vue"
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import taskMonthUser from "../../../assets/api/taskMonthUser";
    import MonthPicker from "../../_components/MonthPicker";
    import {monthBetween} from "../../../assets/utils/general";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";

    export default {
        name: "AllMembersMonthMoney",
        props:["users"],
        components:{
            TcBaseTable,MonthPicker,SyncOutlined
        },
        computed:{
           ids(){
               let ids=[];
               for(let i in this.users){
                   ids.push(this.users[i].userId)
               }
               return ids
            },
            allSum(){
               let sum = 0;
               for(let i in this.dataSource){
                   sum += this.dataSource[i].sum
               }
               return sum
            }
        },
        data() {
            return {
                startMonth:null,
                endMonth:null,
                allRecords:[],
                tableColumns:[],
                dataSource:[],
                mUsers:[],
            }
        },
        methods: {
            init() {
                this.mUsers = [...this.users];
                for(let i in this.mUsers){
                    this.mUsers[i].records=[];
                }
                let data = {
                    userId:this.ids.toString()
                };
                this.spinning = true;
                taskMonthUser.queryAll.requestPOST(this,data,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        this.allRecords = res.data.data;
                        for(let j in this.allRecords){
                            let {userId} = this.allRecords[j];
                            let arr = this.mUsers.filter(item=>item.userId === userId);
                            arr[0].records.push(this.allRecords[j]);
                        }
                        this.monthGroup()
                    }
                    else{
                        this.allRecords = [];
                    }
                })
            },
            refresh(){
              this.startMonth = null;
              this.endMonth = null;
              this.dataSource = [];
              this.tableColumns = [];
              this.init()
            },
            monthGroup(){
                for(let i in this.mUsers){
                    this.mUsers[i].monthGroup=[];
                    for(let j in this.mUsers[i].records){
                        let {yearMonth,completeMoney,leaderMoney} = this.mUsers[i].records[j];
                        let arr = this.mUsers[i].monthGroup.filter(item=>item.yearMonth === yearMonth);
                        if(arr.length>0){
                            arr[0].sum += completeMoney;
                            arr[0].sum += leaderMoney;
                        }
                        else{
                            this.mUsers[i].monthGroup.push({
                                yearMonth:yearMonth,
                                sum:completeMoney + leaderMoney
                            })
                        }
                    }
                    this.mUsers[i].monthGroup = this.mUsers[i].monthGroup.sort((a,b)=>{
                        return new Date(a.yearMonth) - new Date(b.yearMonth)
                    })
                }
            },
            submit(){
                let mBetween = monthBetween(this.startMonth,this.endMonth);
                this.tableColumns = [
                    new Column("姓名","name",ColumnType.String).setTableView(100),
                ];
                for(let i in mBetween){
                    this.tableColumns.push(
                        new Column(mBetween[i],mBetween[i],ColumnType.Number).setTableView(100),
                    )
                }
                this.tableColumns.push(new Column("合计","sum",ColumnType.Number).setTableView(100));
                this.dataSource = [];
                for(let j in this.mUsers){
                    this.dataSource.push(generateDS(this.mUsers[j]));
                }
                for(let n in this.dataSource){
                    let sum = 0;
                    for(let m in mBetween){
                        let month = mBetween[m];
                        sum += this.dataSource[n][month]?this.dataSource[n][month]:0;
                    }
                    this.dataSource[n].sum = sum;
                }
            }
        },
        created() {
            this.init()
        }
    }
    function generateDS(user) {
        let data = {};
        data.name = user.userName;
        for(let i in user.monthGroup){
            let {yearMonth,sum} = user.monthGroup[i];
            data[yearMonth] = sum;
        }
        return data;
    }
</script>

<style scoped>

</style>