<!--
* @program: office_automation 
* @author: ly
* @component:OutSourcePayApply 
* @description: 所有外协支付审核完毕，待支付的
* @create: 2022-03-22 14:06
-->
<template>
    <TcBaseTable :page-size="15" :table-columns="tableColumns" :data-source="dataSource" :view-model="true">
        <template #action="{record}">
            <div class="center-flex">
                <a-popconfirm
                        @confirm="downloadFlows(record)"
                        cancel-text="否"
                        class="action-button"
                        ok-text="是"
                        title="导出审批情况表？"
                        v-if="checkAction('download',record)">
                    <a-tooltip title="导出审批情况表">
                        <a-button class="action-button" style="color: green" type="link">
                            <DownloadOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </a-popconfirm>
                <a-tooltip v-if="checkAction('auditDone',record)"  title="完成支付">
                    <a-button @click="auditDone(record)" class="action-button" style="color: green" type="link">
                        <CheckOutlined class="pointer"/>
                    </a-button>
                </a-tooltip>
            </div>
        </template>
    </TcBaseTable>
    <a-modal v-model:visible="visible"
             :width="600"
             destroy-on-close
             title="确认支付完成"
             :footer="null">
        <div class="center-flex">
            <div style="width: 200px">请选择支付日期：</div>
            <DatePicker style="width: 200px" v-model:value="date" />
        </div>
        <div class="center-flex">
            <div style="width: 200px">选择支付资金账户：</div>
            <a-select v-model:value="cardId"
                      style="width:300px" class="search-item"
                      :options="cardOptions"
            />
        </div>
        <div class="center-flex">
            <div style="width: 200px">选择支付发票：</div>
            <invoicePayoffSelector :projectId="projectId" v-model:invoiceId="invoiceId" />
        </div>
        <a-divider/>
        <a-button type="primary" @click="payConfirm">
            提交
        </a-button>
    </a-modal>
</template>

<script>

    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {companyColors, companyIds, companyNames} from "../../../assets/system/companies";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import invoicePayoffSelector from "../../financialManager/invoiceManager/invoicePayoffSelector";
    import DatePicker from "../../_components/DatePicker";
    import bankBalance from "../../../assets/api/bankBalance";
    import {DownloadOutlined,CheckOutlined} from "@ant-design/icons-vue"
    import payApply from "../../../assets/api/payApply";
    export default {
        name: "OutSourcePayApply",
        components:{
            TcBaseTable,invoicePayoffSelector,DatePicker,
            DownloadOutlined,CheckOutlined
        },
        props:["dataSource"],
        data() {
            return {
                cardOptions:[],
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                projectId:null,
                invoiceId:null,
                cardId:null,
                visible:false,
                date:null,
                currentRecord:null
            }
        },
        methods: {
            init() {
                bankBalance.queryAll.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.cardOptions = [];
                        for(let i in res.data.data){
                            this.cardOptions.push({
                                id:res.data.data[i].id,
                                value:res.data.data[i].id,
                                label:res.data.data[i].bankName +"【"+ res.data.data[i].bankNum + "】"
                            })
                        }
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            checkAction(action, record) {
                /**
                 * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
                 * approvalState 是否全部审核通过：0：未完成；1：已完成
                 * payState 支付状态：0：未支付；1：已支付
                 */
                switch (action) {
                    case "edit": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "submit": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "delete": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "cancel": {
                        return record.submitState === "1" && record.approvalState === "0" ;
                    }
                    case "download": {
                        return record.submitState === "1" && record.approvalState === "1";
                    }
                    case "auditDone":{
                        return record.approvalState === "1" && record.payState === "0" ;
                    }
                }
            },
            auditDone(record){
                this.projectId = record.itemId;
                this.invoiceId = null;
                this.cardId = null;
                this.currentRecord = record;
                this.visible = true
            },
            downloadFlows(record){
                let data = {id: record.id};
                let fileName = "审核详情.xls";
                payApply.exportById.fileDownload(this, data, fileName);
            },
            payConfirm(){
                if(!this.date){
                    this.$message.error("请选择支付日期");
                    return
                }
                let data = {
                    id:this.currentRecord.id,
                    payState:"1",
                    payTime:this.date,
                    bankId:this.cardId,
                    invoiceId:this.invoiceId
                };
                payApply.payConfirm.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.currentRecord.payState = "1";
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
        },
        created() {
            this.init();
            this.tableColumns = [
                new Column("序号","_index",ColumnType.Index).setTableView(60),
                new Column("项目名称","itemName",ColumnType.String).setTableView(200),
                new Column("付款合同名称","contractName",ColumnType.String).setTableView(100),
                new Column("付款合同编号","contractNum",ColumnType.String).setTableView(100),
                new Column("收款单位/个人","outSourceName",ColumnType.String).setTableView(100),
                new Column("申请支付金额","payAmount",ColumnType.Number).setTableView(100),
                new Column("付款公司", "payCompanyId", ColumnType.Enum).setTableView(100)
                    .setEnum(companyIds, companyNames,companyColors),
                new Column("付款部门", "applyDepartName", ColumnType.String).setTableView(100),
                new Column("支付描述","payReason",ColumnType.String).setTableView(100),
                new Column("操作","actions","actions").setTableView(80)
            ];
        }
    }
</script>

<style scoped>

</style>