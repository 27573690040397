/** 
* 自动生成文件，请勿修改 
* @name: 财务:现金流量表会计科目设置模块
* @description: 财务-现金流量表会计科目设置==================现金流量表导出使用
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:14条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const cashSubject = { 
	/** 1 summary:会计科目-批量删除	method:post */ 
	batchDelete : new APIObject("/api/cashSubject/batchDelete.do"), 
	/** 2 summary:会计科目-全部数据查询（只模糊一级科目名称）	method:post */ 
	queryAll : new APIObject("/api/cashSubject/queryAll.do"), 
	/** 3 summary:利润表-(导出)	method:get */ 
	exportProfitReport : new APIObject("/api/cashSubject/exportProfitReport.do"), 
	/** 4 summary:根据月份及科目id得到收入/支出流水详情列表(展示-现金流量表和利润表使用的)	method:get */ 
	detailList : new APIObject("/api/cashSubject/detailList.do"), 
	/** 5 summary:会计科目-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/cashSubject/queryOne.do"), 
	/** 6 summary:资产负债表-(导出)	method:get */ 
	exportAssetReport : new APIObject("/api/cashSubject/exportAssetReport.do"), 
	/** 7 summary:现金流量表-页面展示(计算)	method:get */ 
	calCashReport : new APIObject("/api/cashSubject/calCashReport.do"), 
	/** 8 summary:资产负债表-页面展示(计算)	method:get */ 
	calAssetReport : new APIObject("/api/cashSubject/calAssetReport.do"), 
	/** 9 summary:会计科目-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/cashSubject/saveOrUpd.do"), 
	/** 10 summary:现金流量表-(导出)	method:get */ 
	exportCashReport : new APIObject("/api/cashSubject/exportCashReport.do"), 
	/** 11 summary:利润表-页面展示(计算)	method:get */ 
	calProfitReport : new APIObject("/api/cashSubject/calProfitReport.do"), 
	/** 12 summary:根据年月得到该年1月份 - 该月收入/支出流水详情列表(展示-资产负债表使用的)	method:get */ 
	detailListForZiChan : new APIObject("/api/cashSubject/detailListForZiChan.do"), 
	/** 13 summary:会计科目-单条删除	method:post */ 
	delete : new APIObject("/api/cashSubject/delete.do"), 
	/** 14 summary:会计科目-全部数据查询（资产负债表期初数查询）	method:post */ 
	queryAllBalance : new APIObject("/api/cashSubject/queryAllBalance.do"), 
};
export default cashSubject;