/** 
* 自动生成文件，请勿修改 
* @name: 资产:各人员设备申领/归还审批流程
* @description: 资产-各人员设备申领/归还审批流程
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assetAudit = { 
	/** 1 summary:保存-批量	method:post */ 
	batchSave : new APIObject("/api/assetAudit/batchSave.do"), 
	/** 2 summary:所有数据查询	method:post */ 
	queryAll : new APIObject("/api/assetAudit/queryAll.do"), 
	/** 3 summary:批量-删除	method:post */ 
	batchDelete : new APIObject("/api/assetAudit/batchDelete.do"), 
	/** 4 summary:单条数据查询	method:post */ 
	queryOne : new APIObject("/api/assetAudit/queryOne.do"), 
	/** 5 summary:分页查询	method:post */ 
	queryPage : new APIObject("/api/assetAudit/queryPage.do"), 
	/** 6 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/assetAudit/saveOrUpd.do"), 
	/** 7 summary:单条-删除	method:post */ 
	delete : new APIObject("/api/assetAudit/delete.do"), 
};
export default assetAudit;