/**
 * @description：公司系统管理
 * @author:rzl
 * @date:2022/7/25 14:30
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class companySystemMange_item extends Table {
    constructor() {
        super();
        this.name = "companySystemMange_item";
        this.CNName = "每年度期初余额记录";
        this.columnArray = [
            new Column("主键", "id", ColumnType.String, false).setVisible(false),
            new Column("缩略图", "image", ColumnType.Avatar, true).setTableView(100),
            new Column("名称", "name", ColumnType.String, true).setTableView(200),
            new Column("访问网址", "url", ColumnType.String, true).setTableView(100),
            new Column("排序", "sort", ColumnType.String, true).setTableView(80),
            new Column("功能描述", "describe", ColumnType.TextArea, true),
            new Column("备注", "remarks", ColumnType.TextArea, true),
        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(100)];
    }
}