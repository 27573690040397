<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityTaskViewer 
* @description: 
* @create: 2021-07-05 09:27
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData" />
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <div style="margin-left: 20px">完成状态：</div>
                <a-radio-group class="search-item" v-model:value="defaultQuery.state" @change="radioChange">
                    <a-radio value="0">未完成</a-radio>
                    <a-radio value="1">已完成</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[50,50]" >
                <template #left>
                    <TcBaseTable ref="local_table" :page-size="pageSize" :view-model="true" :data-source="dataSource"
                                 @pageChange = "handlePageChange"
                                 :table-columns="tableColumns" :loading="loading" >
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button class="action-button" type="link" @click="showDetail(index,record)">
                                    <SearchOutlined class="pointer" />
                                </a-button>
                                <a-tooltip v-if="record.state ==='0'" title="设定质检组">
                                    <a-button  class="action-button" @click="groupSetting(record)" type="link">
                                        <UsergroupAddOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip v-if="record.state ==='0'" title="设定质检任务">
                                    <a-button  class="action-button" @click="taskSetting(record)"  type="link">
                                        <SettingOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
<!--                                <a-popconfirm-->
<!--                                        v-if="record.state ==='0'"-->
<!--                                        ok-text="是"-->
<!--                                        cancel-text="否"-->
<!--                                        class="action-button"-->
<!--                                        title="确认质检任务完成吗?"-->
<!--                                        @confirm="qcFinish(record)">-->
<!--                                    <a-tooltip title="质检任务完成">-->
<!--                                        <a-button  class="action-button" type="link">-->
<!--                                            <CheckOutlined class="pointer"/>-->
<!--                                        </a-button>-->
<!--                                    </a-tooltip>-->
<!--                                </a-popconfirm>-->
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <qualityTabs ref="local_tabs" />
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="stepVisible"
             destroy-on-close
             title="质检设定"
             :width="800"
             :maskClosable="false"
             :footer="null">
        <qualitySteps :record="currentRecord" @finish="stepVisible = false" @refresh="init"/>
    </a-modal>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="质检组"
             :width="800"
             :maskClosable="false"
             :footer="null">
        <qualityTaskStepGroup :view-model="false" :record="currentRecord" @finish="visible = false" @refresh="init"/>
    </a-modal>
</template>
<script>
    import qualityTabs from "../qualityComponents/qualityTabs";
    import item_qc_apply from "../../../assets/tables/item_qc_apply";
    import api_item_qc_apply from "../../../assets/api/qcApply";
    import qualitySteps from "./qualitySteps";
    import {SearchOutlined,SyncOutlined,SettingOutlined,UsergroupAddOutlined} from "@ant-design/icons-vue"
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import TwoParts from "../../_components/TwoParts";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import qualityTaskStepGroup from "./qualityTaskStepGroup";
    export default {
        name: "qualityTaskViewer",
        components:{
            SearchOutlined,SyncOutlined,SettingOutlined,UsergroupAddOutlined,
            qualityTabs,qualitySteps,TcBaseTable,TwoParts,SearchBar,qualityTaskStepGroup
        },
        data(){
            return{
                table: new item_qc_apply(),
                dataSource:[],
                tableColumns:[],
                loading:false,
                pageSize:15,
                searchData:null,
                searchOptions:[
                    {value:"queryText",label:"关键字"}
                ],
                defaultQuery:{
                    auditState:"1", //通过审核
                    state:"0" //未完成质检
                },
                stepVisible:false,
                currentRecord:null,
                visible:false
            }
        },
        methods:{
            init(){
                this.searchData = null;
                this.defaultQuery.state = "0";
                let pageData={
                    page:1,
                    limit: this.pageSize
                };
                let data = {};
                Object.assign(data,this.defaultQuery);
                this.loading = true;
                api_item_qc_apply.queryPage.requestPOSTBothParam(this,{urlParam:pageData,formParam:data}, res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.local_table.setTotalSize(res.data.count);
                        this.$refs.local_table.setCurrentPage(1);
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                });
            },
            doSearch(){
                let pageData={
                    page:1,
                    limit: this.pageSize
                };
                let data = {};
                Object.assign(data,this.defaultQuery);
                Object.assign(data,this.searchData);
                api_item_qc_apply.queryPage.requestPOSTBothParam(this,{urlParam:pageData,formParam:data}, res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.local_table.setTotalSize(res.data.count);
                        this.$refs.local_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            refresh(){
                this.init()
            },
            showDetail(index,record){
                this.currentRecord = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"申请详情",component:"qualityApplyFormView",record:this.currentRecord},
                    {tag:"质检组",component:"qualityTaskStepGroup",record:this.currentRecord,viewModel:true},
                    {tag:"质检工序",component:"qualityTaskStepSet",record:this.currentRecord,viewModel:true},
                    {tag:"质检工序分配",component:"qualityTaskStepArrangement",record:this.currentRecord,viewModel:true},
                ]);
            },
            handlePageChange(arg){
                let data = {};
                Object.assign(data,this.defaultQuery);
                Object.assign(data,this.searchData);
                api_item_qc_apply.queryPage.requestPOSTBothParam(this,{urlParam:arg,formParam:data}, res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.local_table.setTotalSize(res.data.count);
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                });
            },
            radioChange(){
                let pageData={
                    page:1,
                    limit: this.pageSize
                };
                let data = {};
                Object.assign(data,this.defaultQuery);
                Object.assign(data,this.searchData);
                api_item_qc_apply.queryPage.requestPOSTBothParam(this,{urlParam:pageData,formParam:data}, res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.local_table.setTotalSize(res.data.count);
                        this.$refs.local_table.setCurrentPage(1);
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                });
            },
            taskSetting(record){
                this.stepVisible =  true;
                this.currentRecord = record;
            },
            groupSetting(record){
                this.visible =  true;
                this.currentRecord = record;
            }
        },
        created() {
            this.tableColumns = this.table.getTableColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>