<!--
* @program: office_automation 
* @author: ly
* @component:ProjectFullFilled
* @description: 项目完结申请
* @create: 2022-04-21 14:55
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar  :search-options="searchOptions" v-model:value="searchData" />
                <a-button  class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button  class="search-item" type="default" shape="circle" @click="refresh">
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <span>完结情况：</span>
                <a-radio-group class="search-item" v-model:value="condition" >
                    <a-radio value="0">未申请</a-radio>
                    <a-radio value="1">审核中</a-radio>
                    <a-radio value="3">未通过审核</a-radio>
                    <a-radio value="2">项目完结</a-radio>
                </a-radio-group>
            </div>
            <TwoParts  ref="TwoParts" @change="handleChange" pos="transform" :trans="[100,25]" >
                <template #left>
                    <TcBaseTable :download="true" ref="project_table" :view-model="true" :page-size="pageSize"
                                 :loading="loading" :data-source="dataSource" :table-columns="tableColumns">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button  class="action-button" type="link" @click="showDetail(index,record)">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-tooltip v-if="record.completeFlag === '0' ||record.completeFlag === '3'" title="发起完结申请">
                                    <a-button  class="action-button" type="link" @click="apply(record)">
                                        申请
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <project-tabs  ref="local_tabs"/>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="项目完结申请">
        <div>完结申请说明：
            <a-button size="small" type="link" style="margin: 20px" @click="reason = '项目任务工作已完成，质检已完成，付款/收款已完成，项目资料已归档'">
                填入模板
            </a-button>
        </div>
        <a-textarea placeholder="请填入项目完结申请说明" v-model:value="reason" :auto-size="{ minRows: 2, maxRows: 6 }"/>
        <a-button size="small" type="primary" style="margin: 20px" @click="saveAudit">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import projectViewer from "../projectViewer/projectViewer";
    import itemInfo from "../../../assets/api/itemInfo";
    export default {
        name: "ProjectFullFilled",
        mixins:[projectViewer],
        computed:{
            dataSource(){
                return this.tableSource.filter(item=>{
                    return item.completeFlag === this.condition
                });
            },
            notReceived(){//待收款项目数量
                return this.tableSource.filter(item=>{
                    return item.itemState === "1" && item.collectionState === "0"
                }).length;
            }
        },
        data() {
            return {
                condition :"0",
                visible:false,
                reason:null,
                currentRecord:null,
            }
        },
        methods: {
            apply(record){
                this.currentRecord = record;
                this.visible = true;
                this.reason = null;
            },
            saveAudit(){
                if(!this.reason){
                    this.$message.error("请填入完结申请原因");
                    return
                }
                let data = {
                    id:this.currentRecord.id,
                    completeFlag:"1",
                    describe:this.reason
                };
                itemInfo.audit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.currentRecord.completeFlag = "1"
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });

            }
        }
    }
</script>

<style scoped>

</style>