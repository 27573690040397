/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_outsourc_pay   项目支付
 * @create: 2021-04-16 16:58
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import api_item_outsourc_info from "../api/outSourceInfo"
export default class item_outsourc_pay extends Table{
    constructor() {
        super();
        this.name = "item_outsourc_pay";
        this.CNName = "项目支付";
        this.columnArray = [
            new Column("支付单位/个人","outSourcId",ColumnType.IdReplace,true).setTableView(150)
                .setIdReplaceObject("outSourcName",{api:api_item_outsourc_info.queryAll,tag:"queryText"}),
            new Column("支付时间","payTime",ColumnType.Date,true).setTableView(100),
            new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue("0").setTableView(150),
           // new Column("支付备注","remarks",ColumnType.String,true).setTableView(200),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];

    }
}