<!--
* @program: office_automation 
* @author: ly
* @component:OtherIncome 
* @description: 其他收入
* @create: 2022-01-24 17:23
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-tooltip title="添加新的其他收入">
                    <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                        <template #icon><PlusOutlined/></template>
                    </a-button>
                </a-tooltip>
                <span style="margin: 10px;font-size: 16px">只能修改和删除当天创建的条目</span>
            </div>
            <TwoParts ref="TwoParts" @change="handleChange"  :trans="[50,100]" :enable-change="true" >
                <template #left>
                    <ArBaseTable :download="true" :argument="argument" :view-model="true" :page-size="pageSize">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button class="action-button" type="link" @click="showDetail(record)">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-button  class="action-button" type="link" @click="editDetail(record)">
                                    <EditOutlined class="pointer"/>
                                </a-button>
<!--                             待恢复   v-if="deleteCheck(record)"-->
                                <a-popconfirm

                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(index,record)">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <div v-if="visible2" >
                        <a-page-header style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;" title="流水信息"/>
                        <ArForm :argument="formArg" :view-model="true" />
                    </div>
                    <welcome2 v-else />
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             :width="1000"
             destroyOnClose
             :footer="null"
             :title="null">
        <ArBaseForm :argument="formArg" :view-model="viewModel" >
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
</template>

<script>
    import Welcome2 from "../../welcome2";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import TwoParts from "../../_components/TwoParts";
    import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import item_finance_income from "../../../assets/tables/item_finance_income";
    import bankBalance from "../../../assets/api/bankBalance";
    import income from "../../../assets/api/income";
    import ArForm from "../../../components/form/ArForm";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import api_item_info from "../../../assets/api/itemInfo";
    export default {
        name: "OtherIncome",
        components: {
            Welcome2,ArForm,
            ArBaseTable,ArBaseForm,TwoParts,SearchOutlined,SyncOutlined,PlusOutlined,EditOutlined,DeleteOutlined,
        },
        data(){
            return{
                table: new item_finance_income(),
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                visible2:false,
            }
        },
        methods:{
            init(){
                this.visible = false;
                this.visible2 = false;
                this.argument.loading = true;
                income.queryAll.requestPOST(this,{},res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        for(let i in res.data.data){
                            if(res.data.data[i].bankName){
                                res.data.data[i].bankNum = res.data.data[i].bankName+"【"+res.data.data[i].bankNum+"】";
                            }
                        }
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.init()
            },
            addData(){
                this.visible = true;
                this.visible2 = false;
                this.formArg.formData = this.table.getFormData();
                this.viewModel = false;
            },
            saveData(record){
                income.saveOrUpdate.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            showDetail(record){
                this.formArg.formData = record;
                this.viewModel = true;
                this.visible2 = true;
            },
            editDetail(record){
                this.formArg.formData = record;
                this.viewModel = false;
                this.visible = true;
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                income.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            deleteCheck(record){
                let time = parseInt(localStorage.getItem("time"));
                return new Date(record.createTime).toLocaleDateString() === new Date(time).toLocaleDateString();
            },
            handleChange(pos){
                if (pos === "transform") {
                    this.argument.tableColumns = [
                        new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                        new Column("所属项目","itemId",ColumnType.IdReplace2,true).setTableView(100)
                            .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                                    "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                                    "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                                    "startTime": "", "tenderWay": ""
                                }}),
                        new Column("收入类别","categoryId",ColumnType.FinancialSubject,true).setTableView(100),
                        new Column("收入日期","collectDate",ColumnType.Date,true).setTableView(100),
                        new Column("银行名称","bankName",ColumnType.String,false),
                        new Column("办卡支行名称","subBank",ColumnType.String,false),
                        new Column("入账账户","bankId",ColumnType.Selector,true).setTableView(250)
                            .setSelectorObject("bankNum",[]),
                        new Column("收款方式","collectType",ColumnType.Enum,true).setTableView(100)
                            .setEnum(["1","2"],["现金","对公打款"]),
                        new Column("金额（元）","collectAmount",ColumnType.Number,true).setTableView(100),
                        new Column("备注","remarks",ColumnType.String,true).setTableView(100),
                    ]
                } else {
                    this.argument.tableColumns = this.table.getTableColumns();
                }
            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.formArg.formColumns = this.table.getFormColumns();
            this.init();
            bankBalance.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    let cards = [];
                    for(let i in res.data.data){
                        cards.push({
                            id:res.data.data[i].id,
                            name:res.data.data[i].bankName +"【"+ res.data.data[i].bankNum + "】"
                        })
                    }
                    this.table.getFormColumns().filter(item=>item.dataIndex === "bankId")[0]
                        .setSelectorObject("bankNum",cards);
                }
                else{
                    this.$message.error(res.data.msg);
                }
            });
        }
    }
</script>

<style scoped>

</style>