<!--
* @description：地图查询条件
* @author:rzl
* @date:2022/1/20 11:40
-->

<template>
  <div class="xm_query">
    <div class="ant-row queryrow">
      <div class="ant-col-10">
        <a-select
            show-search
            v-model:value="itemName"
            placeholder="输入项目名称查询"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            style="width: 100%;"
            @search="handleSearch"
            @change="columnTrigger"
        >
          <a-select-option v-for="option in itemData" :key="option.id">
            {{ option.name }}
          </a-select-option>
        </a-select>
      </div>

      <div class="ant-col-5" style="margin-left: 10px">
        <a-input
            label-in-value
            v-model:value="name"
            placeholder="请输入成果名称"
            showSearch
            :allowClear="true"
        >
        </a-input>
      </div>

      <!--行政区划 start-->
      <div class="ant-col-5" style="margin-left: 10px">
        <a-tree-select
            :value="treeValue_xzqh"
            :tree-data="treeData_xzqh"
            style="width: 100%"
            :dropdownStyle="{height:'350px'}"
            @select="selectTree"
            @change="clearxzqh"
            placeholder="行政区划"
            allow-clear
        />
      </div>
      <!--行政区划 end-->

      <div class="ant-col-4" >
        <a-button type="primary"  @click="query">
          <template #icon><Search /></template>
          查询
        </a-button>
        <a-button style="margin-left: 5px" @click="reset">
          <template #icon><Reset /></template>
          重置
        </a-button>
      </div>
    </div>

    <!--成果类型 start-->
    <div class="ant-row queryrow" >
      <div class="ant-col-4" style="margin-right: 18px;">
        <a-tree-select
            v-model:value="treeValue_cglx"
            :tree-data="treeData_cglx"
            style="width: 300px;"
            :dropdownStyle="{height:'350px'}"
            allow-clear
            placeholder="成果类型"
            @change="cglx_html"
        />
      </div>

      <!----- 动态表单 start ------->
      <div  style="margin-right: 5px;width: 400px" v-for="(config,index) in confList" :key="index" >
          <div v-if="config.inputType == 'input' || config.inputType == 'textarea'" style="margin-bottom: 5px;">
            <a-input v-model:value="config.textValue" :ref="config.code"  :placeholder="config.name"/>
          </div>
          <div v-else-if="config.inputType == 'data'" style="margin-bottom: 5px;">
            <a-date-picker
                show-time
                type="date"
                style="width: 100%"
                format="YYYY/MM/DD"
                v-model:value="config.textValue"
                :placeholder="config.name"
            />
          </div>
          <div v-else-if="config.inputType == 'select' || config.inputType == 'radio'" style="margin-bottom: 5px;">
            <a-select v-model:value="config.textValue" :name="config.code" :placeholder="config.name" style="width: 100%;" allow-clear>
              <a-select-option v-for="(info,index) in config.input_list" :key="index" :value="info" >{{info}}</a-select-option>
            </a-select>
          </div>
          <div v-else-if="config.inputType == 'checkbox'" style="margin-bottom: 5px;">
            <a-select v-model:value="config.textValue" mode="multiple"  :name="config.code" :placeholder="config.name" style="width: 100%;" allow-clear>
              <a-select-option v-for="(info,index) in config.input_list" :key="index" :value="info" >{{info}}</a-select-option>
            </a-select>
          </div>
      </div>
      <!----- 动态表单 end ------->

    </div>
    <!--成果类型 end-->


  </div>

  <div class="xm_tool">

    <img class="imgClass" title="长度测量" :src="img_cd"  @mouseenter="enter_op('cd')" @mouseleave="leave_op('cd')"  @click="click_op('cd')" >

    <img class="imgClass" title="面积测量" :src="img_mj"  @mouseenter="enter_op('mj')" @mouseleave="leave_op('mj')"  @click="click_op('mj')" >

    <img class="imgClass" title="自定义范围分析" :src="img_ydyp"  @mouseenter="enter_op('ydyp')" @mouseleave="leave_op('ydyp')"  @click="click_op('ydyp')" >

    <a-upload
        v-model:file-list="fileList"
        name="shpFile"
        :action="actionUrl"
        @change="handleChange"
        :showUploadList="false"
        :accept="accept"
        withCredentials

    >
      <img class="imgClass" title="导入范围分析" :src="img_dr"  @mouseenter="enter_op('dr')" @mouseleave="leave_op('dr')"  @click="click_op('dr')" >
    </a-upload>

    <img class="imgClass" title="清除" :src="img_qc"  @mouseenter="enter_op('qc')" @mouseleave="leave_op('qc')"  @click="click_op('qc')" >
  </div>
  <!-- 遮罩层 -->
  <mask-layer ref="maskLayer"/>
</template>

<script>
import Icons from "../../../common/Icons";
import maskLayer from "../common/point/maskLayer";
import achieveGeom_api from "../config/achieveGeom_api";
import achieveType_api from "../config/achieveType_api";
import area_api from "../config/area_api";
import achieveRecord_api from "../config/achieveRecord_api";
import {dateFormat} from "../../../../assets/utils/dataUtil";


export default {
  name: "mapToolBar",
  mixins:[Icons],
  components:{
    maskLayer
  },
  data(){
    return{
      name:null,// 成果名称
      pageParam:{
        page: 1,
        limit: 10,
      },

      geomWkt:null, // 查询条件geom
      cl_type:null,// 测量选择值
      visible_cl: false,

      cx_type:null,// 宗地查询选择值
      visible_cxfx: false,
      
      /** 行政区划 **/
      treeData_xzqh:[],
      treeValue_xzqh:null,
      xzqh_id:null,

      defaultTitle: '',
      titleId:'',
      provId:null,// 省
      cityId:null,// 市
      countyId:null,//区
      streetId:null,//乡镇
      villageId:null,// 村

      /** 成果类型 ***/
      treeValue_cglx:null,
      treeData_cglx:[],
      clgxId:null,

      confList:[],// 动态表单内容

      /*** 上传shp分析 ***/
      accept:".zip",//Vimage/png,image/jpeg
      file:null,
      fileList:[],
      previewVisible:false,
      actionUrl:achieveRecord_api.shpToWkt.url,
      baseImageUrl:"d:",

      img_cd: require('../common/images/map/长度-默认.png'),
      flag_cd:false,

      img_mj: require('../common/images/map/面积-默认.png'),
      flag_mj:false,

      img_ydyp: require('../common/images/map/分析-默认.png'),
      flag_ydyp:false,

      img_dr: require('../common/images/map/导入-默认.png'),
      flag_dr:false,
      
      img_qc: require('../common/images/map/清除-默认.png'),
      flag_qc:false,

      // 项目搜索
      itemId:null,
      itemName:null,
      itemData:[],
    }
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      let parms = {
        dictName:null,
        dictCode:null,
      };
      achieveType_api.getAllTree.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          let level = 0;
          var tempObj = this.convertToTreedatas_cglx(res.data.data,level);
          this.treeData_cglx = tempObj;
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
      area_api.getAllTree.requestPOSTUrlParam(this, {},res=>{
        if(res.data.flag){
          let level = 0;
          var tempObj = this.convertToTreedatas_cglx(res.data.data,level);
          this.treeData_xzqh = tempObj;
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    /**
     * 多级
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedatas_cglx(data,level){
      var returnData = [];
      //遍历数据
      for(var i = 0; i < data.length; i++){
        var tempObj = {
          value:data[i].id,
          key:data[i].id,
          title:data[i].name,
          level:level,
        };

        //判断是否存在子节点，如果存在则递归
        if(data[i].children != null && data[i].children.length > 0){
          tempObj.children = this.convertToTreedatas_cglx(data[i].children,level + 1);
        }
        //push到数据数组中
        returnData.push(tempObj);
      }
      return returnData;
    },
    set_xzqh(data){
      //遍历数据
      for(var i = 0; i < data.length; i++){
        let value = data[i].value;
        let level = data[i].level;
        let xzqh_id = this.xzqh_id;
        if(value == xzqh_id){
          switch (level){
            case 0:{
              this.provId = xzqh_id;
              break;
            }
            case 1:{
              this.cityId = xzqh_id;
              break;
            }
            case 2:{
              this.countyId = xzqh_id;
              break;
            }
            case 3:{
              this.streetId = xzqh_id;
              break;
            }
            case 4:{
              this.villageId = xzqh_id;
              break;
            }
          }
        }else{
          //判断是否存在子节点，如果存在则递归
          if(data[i].children != null && data[i].children.length > 0){
            this.set_xzqh(data[i].children);
          }
        }
      }
    },
    clearxzqh(nodeValue){
      if(nodeValue == null || nodeValue == undefined){
        this.treeValue_xzqh = null;
        this.xzqh_id = null;
        this.provId = null;
        this.cityId = null;
        this.countyId = null;
        this.streetId = null;
        this.villageId = null;
      }
    },
    /**
     * @description 选择节点触发
     */
    selectTree(nodeValue){
      this.provId = null;
      this.cityId = null;
      this.countyId = null;
      this.streetId = null;
      this.villageId = null;
      this.setTitle(nodeValue)
    },
    /**
     * @description 设置当前选中对象
     */
    setTitle(value){
      this.xzqh_id = value;
      this.getSelectedItem(value, this.treeData_xzqh)
      this.$nextTick(()=>{
        this.treeValue_xzqh = this.defaultTitle;
      })
    },
    /**
     * @description 当前选中对象的title(拼接所有父级title)
     */
    getSelectedItem(value, data, title,valueId){
      for(let item of data){
        //在根节点找到对应选项
        if (!title && item.value == value ) {
          this.defaultTitle = item.title;
          this.titleId = item.value;
        }
        //根节点未找到继续递归查找
        else if(!title && item.children){
          let lable = item.title
          let value_id = item.value;
          this.getSelectedItem(value, item.children, lable,value_id);
        }

        //在子节点找到对应选项
        if (title && item.value == value){
          this.defaultTitle = title + '-' + item.title
          this.titleId = valueId + '*' + item.value;
        }
        //当前子节点未找到继续递归向下查找
        else if(title && item.children){
          let lable = title + '-' + item.title
          let value_id = valueId + '*' + item.value;
          this.getSelectedItem(value, item.children, lable,value_id);
        }
      }
    },

    /** 成果类型动态表单 **/
    cglx_html(clgx_id){
      if(clgx_id == null || clgx_id == undefined){
        this.confList = null;
        return;
      }
      this.clgxId = clgx_id;
      let parms = {
        id:this.treeValue_cglx,
      };
      achieveType_api.queryOne.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          let list = res.data.data.confList;
          if(list.length > 0){
            for(let info of list){
              let inputType = info.inputType;
              // info.textValue = null;
              if(inputType == 'select' || inputType == 'radio' || inputType == 'checkbox'){
                let valueList = info.valueList;
                let list = valueList.split(";");
                info.input_list = [];
                if(list.length > 0){
                  for(let lt of list){
                    if(lt != ''){
                      info.input_list.push(lt);
                    }
                  }
                }

              }
            }
          }
          this.confList = list;
        }
      });
    },

    /*** 查询条件封装 start ***/
    set_geomWkt(geomWkt){
      this.geomWkt = geomWkt;
    },
    close_geomWkt(){
      this.geomWkt = null;
    },
    query(){
      this.area_id();
      let propJson = this.propJson();
      let parms ={
        itemId:this.itemId,
        typeId:this.clgxId,
        provId:this.provId,
        cityId:this.cityId,
        countyId:this.countyId,
        streetId:this.streetId,
        villageId:this.villageId,
        name:this.name,
        geomWkt:this.geomWkt,
        propJson:propJson == null ? "" : JSON.stringify(propJson),

      };
      achieveRecord_api.queryAll.requestPOST(this,parms,res=>{
        if(res.data.flag){
          this.$emit("event",this.$GetEmitData(this.$eventTags.query_text,res.data.data));
        }
        else{
          this.$message.error(res.data.msg);
          this.$emit("event",this.$GetEmitData(this.$eventTags.query_text,null));
        }
      })
    },
    /** 获取选中区划id **/
    area_id(){
      let list = this.titleId.split("*");
      if(list.length > 0){
        for(let i=0;i<list.length;i++){
          if(i == 0){
            this.provId = list[i];
          }else if(i == 1){
            this.cityId = list[i];
          }else if(i == 2){
            this.countyId = list[i];
          }else if(i == 3){
            this.streetId = list[i];
          }else if(i == 4){
            this.villageId = list[i];
          }
        }
      }
    },
    /** 动态表单json **/
    propJson(){
      let parms = null;
      let confList = this.confList;
      if(confList != null && confList.length > 0){
        for(let info of confList){
          let textValue = info.textValue;
          if(textValue != null && textValue != ''){
            if(parms == null){
              parms = {};
            }
            let code = info.code;
            let dataType = info.inputType;
            if(dataType == 'checkbox'){
              let list = info.textValue;
              textValue = '';
              if(list != null && list.length > 0){
                for(let info of list){
                  if(textValue == ''){
                    textValue += info;
                  }else{
                    textValue += ";"+info;
                  }
                }
              }
            }else if(dataType == 'data'){
              textValue = dateFormat(info.textValue,'yyyy-MM-dd')
            }

            parms[code] =textValue;
          }
        }
      }
      return parms;
    },
    /*** 查询条件封装 end ***/

    /** 重置 **/
    reset(){
      this.itemId = null;
      this.itemName = null;
      this.itemData = [];
      this.name = null;
      this.treeValue_xzqh = null;
      this.provId = null;
      this.cityId =  null;
      this.countyId = null;
      this.streetId = null;
      this.villageId = null;
      this.treeValue_cglx = null;
      this.confList = null;
      this.clgxId = null;
      this.titleId = '';
      this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
    },
    /**  清除 **/
    reset_tool(){
      this.name = null;
      this.treeValue_xzqh = null;
      this.provId = null;
      this.cityId =  null;
      this.countyId = null;
      this.streetId = null;
      this.villageId = null;
      this.treeValue_cglx = null;
      this.confList = null;
      this.clgxId = null;
      this.titleId = '';
    },


    /********* 左侧地图工具 start ************/
    /**
     * @Description:鼠标悬停进入
     * @params: 操作类别
     * @return:
     * @Author: rzl
     * @Date: 2022/4/13
     */
    enter_op(opType){
      switch (opType){
        case "cd":{
          if(!this.flag_cd){
            this.img_cd = require('../common/images/map/长度-悬停.png');
          }
          break;
        }
        case "mj":{
          if(!this.flag_mj){
            this.img_mj = require('../common/images/map/面积-悬停.png');
          }
          break;
        }
        case "ydyp":{
          if(!this.flag_ydyp){
            this.img_ydyp = require('../common/images/map/分析-默认.png');
          }
          break;
        }
        case "dr":{
          if(!this.flag_dr){
            this.img_dr = require('../common/images/map/导入-悬停.png');
          }
          break;
        }
        case "qc":{
          if(!this.flag_qc){
            this.img_qc = require('../common/images/map/清除-悬停.png');
          }
          break;
        }
        case this.$eventTags.zoom_in:{
          this.img_zoom_in = require('../common/images/map/放大-悬停.png');
          break;
        }
        case this.$eventTags.zoom_reset:{
          this.img_zoom_reset = require('../common/images/map/重置视图-悬停.png');
          break;
        }
        case this.$eventTags.zoom_out:{
          this.img_zoom_out = require('../common/images/map/缩小-悬停.png');
          break;
        }
      }
    },
    /**
     * @Description: 鼠标离开
     * @params: 操作类别
     * @return:
     * @Author: rzl
     * @Date: 2022/4/13
     */
    leave_op(opType){
      switch (opType){
        case "cd":{
          if(!this.flag_cd){
            this.img_cd = require('../common/images/map/长度-默认.png');
          }
          break;
        }
        case "mj":{
          if(!this.flag_mj){
            this.img_mj = require('../common/images/map/面积-默认.png');
          }
          break;
        }
        case "ydyp":{
          if(!this.flag_ydyp){
            this.img_ydyp = require('../common/images/map/分析-默认.png');
          }
          break;
        }
        case "dr":{
          if(!this.flag_dr){
            this.img_dr = require('../common/images/map/导入-默认.png');
          }
          break;
        }
        case "qc":{
          if(!this.flag_qc){
            this.img_qc = require('../common/images/map/清除-默认.png');
          }
          break;
        }
        case this.$eventTags.zoom_in:{
          this.img_zoom_in = require('../common/images/map/放大-默认.png');
          break;
        }
        case this.$eventTags.zoom_reset:{
          this.img_zoom_reset = require('../common/images/map/重置视图-默认.png');
          break;
        }
        case this.$eventTags.zoom_out:{
          this.img_zoom_out = require('../common/images/map/缩小-默认.png');
          break;
        }
      }
    },
    /**
     * @Description: 点击
     * @params: 操作类别
     * @return:
     * @Author: rzl
     * @Date: 2022/4/13
     */
    click_op(opType){
      switch (opType){
        case "cd":{
          if(this.flag_cd){
            this.flag_cd = false;
            this.img_cd = require('../common/images/map/长度-悬停.png');
            this.$emit("event",this.$GetEmitData(this.$eventTags.map_qc));
          }else{
            this.flag_cd = true;
            this.img_cd = require('../common/images/map/长度-点击.png');
            this.clearOther("cd");
            this.$emit("event",this.$GetEmitData(this.$eventTags.map_cd));
          }


          break;
        }
        case "mj":{
          if(this.flag_mj){
            this.flag_mj = false;
            this.img_mj = require('../common/images/map/面积-悬停.png');
            this.$emit("event",this.$GetEmitData(this.$eventTags.map_qc));
          }else{
            this.flag_mj = true;
            this.img_mj = require('../common/images/map/面积-点击.png');
            this.clearOther("mj");
            this.$emit("event",this.$GetEmitData(this.$eventTags.map_mj));
          }
          break;
        }
        case "ydyp":{
          if(this.flag_ydyp){
            this.flag_ydyp = false;
            this.img_ydyp = require('../common/images/map/分析-默认.png');
            this.$emit("event",this.$GetEmitData(this.$eventTags.map_qc));
          }else{
            this.flag_ydyp = true;
            this.img_ydyp = require('../common/images/map/用地预批-点击.png');
            this.clearOther("ydyp");
            this.$emit("event",this.$GetEmitData(this.$eventTags.map_ydyp));
          }

          break;
        }
        case "dr":{
          this.flag_dr = true;
          this.img_dr = require('../common/images/map/导入-点击.png');
          this.clearOther("dr");
          break;
        }
        case "qc":{
          this.flag_qc = this.flag_qc == true ? false : true;
          this.img_qc = require('../common/images/map/清除-点击.png');
          this.clearOther("qc");

          this.$emit("event",this.$GetEmitData(this.$eventTags.map_qc));
          break;
        }
      }
    },
    /**
     * @Description:点击选中一个操作后，将其他操作样式清除
     * @params: 操作类别
     * @return:
     * @Author: rzl
     * @Date: 2022/4/13
     */
    clearOther(type){
      switch (type){
        case "cd":{
          this.flag_mj = false;
          this.flag_ydyp = false;
          this.flag_dr = false;
          this.flag_qc = false;
          this.img_mj = require('../common/images/map/面积-默认.png');
          this.img_ydyp = require('../common/images/map/分析-默认.png');
          this.img_dr = require('../common/images/map/导入-默认.png');
          this.img_qc = require('../common/images/map/清除-默认.png');
          break;
        }
        case "mj":{
          this.flag_cd = false;
          this.flag_ydyp = false;
          this.flag_dr = false;
          this.flag_qc = false;
          this.img_cd = require('../common/images/map/长度-默认.png');
          this.img_ydyp = require('../common/images/map/分析-默认.png');
          this.img_dr = require('../common/images/map/导入-默认.png');
          this.img_qc = require('../common/images/map/清除-默认.png');
          break;
        }
        case "ydyp":{
          this.flag_cd = false;
          this.flag_mj = false;
          this.flag_dr = false;
          this.flag_qc = false;
          this.img_cd = require('../common/images/map/长度-默认.png');
          this.img_mj = require('../common/images/map/面积-默认.png');
          this.img_dr = require('../common/images/map/导入-默认.png');
          this.img_qc = require('../common/images/map/清除-默认.png');
          break;
        }
        case "dr":{
          this.flag_cd = false;
          this.flag_mj = false;
          this.flag_ydyp = false;
          this.flag_qc = false;
          this.img_cd = require('../common/images/map/长度-默认.png');
          this.img_mj = require('../common/images/map/面积-默认.png');
          this.img_ydyp = require('../common/images/map/分析-默认.png');
          this.img_qc = require('../common/images/map/清除-默认.png');
          break;
        }
        case "djcx":{
          this.flag_cd = false;
          this.flag_mj = false;
          this.flag_ydyp = false;
          this.flag_dr = false;
          this.flag_qc = false;
          this.img_cd = require('../common/images/map/长度-默认.png');
          this.img_mj = require('../common/images/map/面积-默认.png');
          this.img_ydyp = require('../common/images/map/分析-默认.png');
          this.img_dr = require('../common/images/map/导入-默认.png');
          this.img_qc = require('../common/images/map/清除-默认.png');
          break;
        }
        case "qc":{
          this.flag_cd = false;
          this.flag_mj = false;
          this.flag_ydyp = false;
          this.flag_dr = false;
          this.img_cd = require('../common/images/map/长度-默认.png');
          this.img_mj = require('../common/images/map/面积-默认.png');
          this.img_ydyp = require('../common/images/map/分析-默认.png');
          this.img_dr = require('../common/images/map/导入-默认.png');
          break;
        }
      }
    },


    /*************** 上传shp分析 start ****************/
    handleChange({file,fileList,event}){
      if(file.status === "uploading"){ //status: "uploading"
        this.loadingVisible = true;

      }
      else if(file.status==="done"){//status: "done"
        let flag = file.response.flag
        if(flag){
          this.loadingVisible = false;
          this.flag_dr = false;
          this.img_dr = require('../common/images/map/导入-默认.png');
          let data = this.$GetEmitData(this.$eventTags.map_dr_shp,file.response.data);
          this.$emit("event",data);

        }else{
          this.$message.error(file.response.msg);
        }

      }
      else {
        this.loadingVisible = false;
      }
    },
    /*************** 上传shp分析 end ****************/

    /********* 左侧地图工具 end ************/

    /********** 项目 start ************/
    handleSearch(val){
      let parms = {
        name:val
      }
      achieveRecord_api.queryAll_item.requestPOST(this,parms,res=>{
        if(res.data.flag){
          let data = res.data.data;
          let option_data = [];
          if(data.length > 0){
            for(let i=0;i<data.length;i++){
              let info = data[i];
              let parm = {
                id:info.id,
                name:info.name
              };
              option_data.push(parm);
            }
          }
          this.itemData = option_data;
        }else{
          this.itemData = [];
        }
      });
    },
    columnTrigger(val){
      this.itemId = val;
    },
    /********** 项目 end  *************/
  }
}
</script>

<style scoped>
.xm_query{
  z-index: 1000;
}
.xm_tool{
  z-index: 1000;
  width: 3%;
  text-align: center;
  padding: 30px 0;
  line-height: 40px;
  background:rgb(0 0 0 / 25%);
  position: absolute;
  margin-top: 8%;
}
.queryrow{
  margin: 6px;
}

.imgClass{
  width: 60px;
  cursor: pointer;
}
</style>