/**
 * @description：合同信息
 * @author:rzl
 * @date:2022/7/25 14:27
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";
import contractType from "../../contractType";

export default class contract_info_item extends Table{
    constructor() {
        super();
        this.name = "contract_info_item";
        this.CNName = "合同信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String).setVisible(false),
            new Column("所属项目","itemName",ColumnType.String,true).setTableView(200),
            new Column("类别","type",ColumnType.Enum,true).setTableView(80)
                .setEnum(contractType.value,contractType.label,contractType.color), //合同类别：1：甲方合同；2：外协合同
           /* new Column("合同名称","name",ColumnType.String,true).setTableView(200),*/
            new Column("合同金额","amount",ColumnType.Number,true).setTableView(100),
            new Column("签订时间","sigDate",ColumnType.Date,true).setTableView(100),
            new Column("签订人","sigUserName",ColumnType.String,true).setTableView(100)
           /* new Column("盖章情况","sealFlag",ColumnType.Enum,true).setTableView(100)
                .setEnum(["0","1"],["未盖章","已盖章"],["red","green"]), //合同盖章情况：0：未盖章；1：已盖章*/

        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}