<!--
* @program: tyh-oa 
* @author: ly
* @description: welcome
* @create: 2021-03-22 18:15
-->
<template>
    <div class="main-window" style="height: 100%">
        <div style="position: absolute;top: 40%;left: 30%; font-size: 60px" class="non-select">
            欢迎使用{{$sysName}}
        </div>
        <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
            版本：{{$version}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "welcome",
    }
</script>

<style scoped>

</style>