/** 
* 自动生成文件，请勿修改 
* @name: 项目:员工生产质量管理模块
* @description: 项目-员工生产质量管理模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const prodQuality = { 
	/** 1 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/prodQuality/batchSaveOrUpd.do"), 
	/** 2 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/prodQuality/batchDelete.do"), 
	/** 3 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/prodQuality/queryAll.do"), 
	/** 4 summary:保存/修改-单个员工生产质量	method:post */ 
	saveOrUpd : new APIObject("/api/prodQuality/saveOrUpd.do"), 
	/** 5 summary:查询用户-与该项目相关的生产人员	method:post */ 
	queryUserByItem : new APIObject("/api/prodQuality/queryUserByItem.do"), 
	/** 6 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/prodQuality/queryPage.do"), 
	/** 7 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/prodQuality/queryOne.do"), 
	/** 8 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/prodQuality/delete.do"), 
};
export default prodQuality;