<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentAccount 
* @description: 部门绩效池一览
* @create: 2022-04-06 15:34
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <span>部门：</span>
                <a-select v-model:value="departmentId" @select="handleSelect">
                    <a-select-option v-for="option in departments" :key="option.id">
                        {{ option.name }}
                    </a-select-option>
                </a-select>
            </div>
            <AccountInfo :departmentId="departmentId" :view-model="false" />
        </div>
    </div>
</template>

<script>
    import AccountInfo from "./AccountInfo";
    export default {
        name: "DepartmentAccount",
        components:{
            AccountInfo
        },
        data() {
            return {
                departmentId:null,
                departments:[],
            }
        },
        methods: {
            init() {
                this.departments = this.$store.getters.userInfo.departmentDTOList;
                this.departmentId = this.departments[0].id;
            },
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>