<!--
* @program: office_automation 
* @author: ly
* @component:projectDelayHistory 
* @description: 
* @create: 2022-01-07 16:26
-->
<template>
    <div>
        <a-page-header style="border: 1px solid rgb(235, 237, 240)"
                       title="任务延期历史"
        />
        <ArBaseTable :argument="argument" :view-model="true" :page-size="15" />
    </div>
</template>

<script>
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import progressApply from "../../../assets/api/progressApply";
    export default {
        name: "projectDelayHistory",
        components:{ArBaseTable},
        props:["record"],
        data(){
            return{
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("原计划时间","oldDate",ColumnType.Date).setTableView(100),
                        new Column("延期到","newDate",ColumnType.Date,true).setTableView(100),
                        new Column("延迟天数","delayDay",ColumnType.Number).setTableView(100),
                        new Column("申请缘由","reason",ColumnType.String,true).setTableView(300),
                        new Column("审核状态","approveState",ColumnType.String).setTableView(100)
                            .setEnum(["0","1","2"],["未审核","通过","不通过"],["pink","green","red"]),
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                }
            }
        },
        created() {
            this.argument.loading = true;
            let data = {
                originalId:this.record.id
            };
            progressApply.queryAll.requestPOST(this,data,res=>{
                this.argument.loading = false;
                if(res.data.flag){
                    this.argument.dataSource = res.data.data;
                }
                else{
                    this.argument.dataSource = [];
                }
            })
        }
    }
</script>

<style scoped>

</style>