<!--
* @program: office_automation 
* @author: ly
* @component:MonthProcessArrange 
* @description: 月度绩效分配
new Column("作业绩效","depManagExp",ColumnType.Number,false),
new Column("负责人绩效","leaderPercent",ColumnType.Number,false),
* @create: 2022-04-12 09:56
-->
<template>
    <a-spin :spinning="spinning">
        <div>
            <TagShow tag="概况" color="red" :visible="true">
                <template #content>
                    <span>作业绩效：{{task.depManagExp}} &nbsp;&nbsp;</span>
                    <span>负责人绩效：{{task.leaderPercent}}&nbsp;&nbsp;</span>
                    <span>负责人：{{taskLeader.name}}&nbsp;&nbsp;</span><br />
                    <span>作业绩效剩余：{{(task.depManagExp - moneySum).toFixed(2)}} &nbsp;</span>
                    【<span v-for="item in members" :key="item.id">{{item.name}}:{{item.workMoney}}&nbsp;</span>】
                    <span>负责人绩效剩余：{{(task.leaderPercent - moneySum2).toFixed(2)}}</span>
                    【<span v-for="item in members" :key="item.id">{{item.name}}:{{item.leadMoney}}&nbsp;</span>】
                    <br/>
                    <span>工作量占比：</span>
                    <span v-for="item in members" :key="item.id">{{item.name}}:{{item.taskPercent}}</span>
                </template>
            </TagShow>
        </div>
        <a-tooltip  title="添加月度绩效分配">
            <a-button @click="addData" type="primary" style="margin-right: 10px">
                <template #icon><PlusOutlined /></template>
            </a-button>
        </a-tooltip>
        <a-button @click="showAll">分配记录</a-button>
        <span>成员：</span>
        <a-radio-group v-model:value="currentMember" >
            <a-radio v-for="(item,index) in members" :value="item" :key="index">
                {{item.name}}
            </a-radio>
        </a-radio-group>
        <div style="display: flex">
            <TcBaseTable :title="true" :table-columns="tableColumns" :data-source="ds" :view-model="true" :page-size="10">
                <template #title>
                    绩效分配记录
                    <span>月份：</span>
                    <MonthPicker  style="width: 120px" v-model:value="filterMonth" />
                </template>
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button v-if="allowMonth(record.yearMonth,yearMonth)" class="action-button" type="link" @click="editDetail(record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="allowMonth(record.yearMonth,yearMonth)"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </TcBaseTable>
<!--            <TcBaseTable  :title="true" :table-columns="tableColumns2" :data-source="ds2" :view-model="true" :page-size="10">-->
<!--                <template #title>-->
<!--                    <span>工作填写记录：</span>-->
<!--                    <a-radio-group v-if="currentMember" v-model:value="processId" >-->
<!--                        <a-radio v-for="(item,index) in currentMember.processList"-->
<!--                                 :value="item.processId" :key="index">{{item.processTitle}}</a-radio>-->
<!--                    </a-radio-group>-->
<!--                    <br/>-->
<!--                    <span v-if="processId">月进度：{{monthSum}}</span>-->
<!--                </template>-->
<!--            </TcBaseTable>-->
        </div>
    </a-spin>
    <MonthProcessArrangeAdd3 ref="MonthProcessArrangeAdd" :arranged="{work:moneySum,lead:moneySum2}"  :members="members" :task="task" @refresh="init"/>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :width="300"
             :footer="null"
             :title="getTitle">
        <a-form layout="horizontal">
            <a-form-item label="作业绩效">
                <a-input-number  style="width: 150px"  v-model:value="completeMoney" />
            </a-form-item>
            <a-form-item label="负责人绩效">
                <a-input-number  style="width: 150px"  v-model:value="leaderMoney" />
            </a-form-item>
            <a-form-item label="说明">
                <a-textarea style="width: 150px" v-model:value="remarks" />
            </a-form-item>
        </a-form>
        <a-button size="small" type="primary" style="margin: 20px" @click="saveData">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import taskMonthUser from "../../../assets/api/taskMonthUser";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import processEmployee from "../../../assets/api/processEmployee";
    import itemTask from "../../../assets/api/itemTask";
    import itemTaskProcess from "../../../assets/api/itemTaskProcess";
    import workSituation from "../../../assets/api/workSituation";
    import item_month_task_user from "../../../assets/tables/item_month_task_user";
    import item_work_situation from "../../../assets/tables/item_work_situation";
    import moment from "moment"
    import TagShow from "../../_components/TagShow";
    import MonthProcessArrangeAdd3 from "./MonthProcessArrangeAdd3";
    import {SearchOutlined,DeleteOutlined,PlusOutlined,EditFilled} from "@ant-design/icons-vue"
    import MonthPicker from "../../_components/MonthPicker";
    import {allowMonth} from "../../../assets/utils/general";

    export default {
        name: "MonthProcessArrange3",
        props:["record"], //task的记录
        components:{
            MonthPicker,
            TagShow,SearchOutlined,DeleteOutlined,PlusOutlined,EditFilled,
            TcBaseTable,MonthProcessArrangeAdd3
        },
        computed:{
            taskLeader(){
                let arr = this.members.filter(item=>item.leader);
                if(arr.length === 1){
                    return arr[0]
                }
                else{
                    return new ITask(null,"无任务负责人")
                }
            },
            ds(){
                if(!this.currentMember){
                    return this.dataSource.filter(item=>{
                        if(this.filterMonth){
                            return item.yearMonth === this.filterMonth
                        }
                        else{
                            return true
                        }
                    })
                }
                else{
                    return this.currentMember.moneyArrange.filter(item=>{
                        if(this.filterMonth){
                            return item.yearMonth === this.filterMonth
                        }
                        else{
                            return true
                        }
                    })
                }
            },
            ds2(){
                if(!this.currentMember){
                    return []
                }
                let arr = this.currentMember.processList.filter(item=>item.processId === this.processId)
                if(arr.length>0){
                    return arr[0].workDiary
                }
                else{
                    return []
                }
            },
            monthSum(){
                let month = [];
                for(let i in this.ds2){
                    let yearMonth = new moment(this.ds2[i].workDate).format("YYYY-MM")
                    let arr = month.filter(item=>item.yearMonth === yearMonth)
                    if(arr.length >0){
                        arr[0].value += this.ds2[i].workRatio;
                    }
                    else{
                        month.push({
                            yearMonth:yearMonth,
                            value:this.ds2[i].workRatio
                        })
                    }
                }
                let str = "";
                for(let j in month){
                    str += month[j].yearMonth +"【"+ month[j].value + "%】"
                }
                return str;
            },
            moneySum(){
                let sum =0;
                for(let i in this.members){
                    sum += this.members[i].workMoney;
                }
                return sum
            },
            moneySum2(){
                let sum =0;
                for(let i in this.members){
                    sum += this.members[i].leadMoney;
                }
                return sum
            },
            getTitle(){
                if(this.currentRecord){
                    return this.currentRecord.userName + ":"+this.currentRecord.yearMonth
                }
                else{
                    return ""
                }
            }
        },
        data() {
            return {
                spinning:false,
                //绩效分配记录
                dataSource:[],//提供table的DataSource
                tableColumns:[], //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态

                //工作填写记录
                processId:null,
                dataSource2:null,//提供table的DataSource
                tableColumns2:[], //提供table的字-段集 this.table.getTableColumns()
                loading2:false,//table的loading状态

                members:[],
                currentMember:null,
                task:{},
                taskWeight:0, //任务总权重
                visible:false,

                completeMoney:null,
                leaderMoney:null,
                remarks:null,
                currentRecord:null,
                yearMonth:null,//只允许编辑和修改本月数据

                filterMonth:null,
                allowMonth:allowMonth,
                completeRatio:0
            }
        },
        methods: {
            init() {
                this.taskWeight = 0;
                this.members = [];
                let data = {
                    "taskId": this.record.id
                };
                this.spinning = true;
                itemTask.queryOne.requestPOSTUrlParam(this,{id:this.record.id},res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        this.task = res.data.data;
                    }
                    else{
                        this.task = this.record;
                    }
                    itemTaskProcess.queryAll.requestPOSTUrlParam(this,data,res=>{//计算当前task的权重和
                        if(res.data.flag){
                            for(let i in res.data.data){
                                this.taskWeight += res.data.data[i].workRatio;
                            }
                            let leader = new ITask(this.task.taskLeader,this.task.taskLeaderName,this.taskWeight).setLeader();
                            this.members.push(leader);
                            processEmployee.queryAll.requestPOST(this,data,res=>{//得到项目内每个人的任务工序
                                if(res.data.flag){
                                    for(let i in res.data.data){
                                        let {userId,userName,processId,processTitle,processRatio,workRatio} = res.data.data[i];
                                        let arr = this.members.filter(item =>item.id === userId);
                                        if(arr.length>0){
                                            arr[0].addProcess(new Process(processId,processTitle,processRatio,workRatio))
                                        }
                                        else{
                                            let member = new ITask(userId,userName,this.taskWeight);
                                            member.addProcess(new Process(processId,processTitle,processRatio,workRatio));
                                            this.members.push(member)
                                        }
                                    }
                                    taskMonthUser.queryAll.requestPOST(this,data,res=>{//得到每个人已经分配的绩效
                                        if(res.data.flag){
                                            this.dataSource = res.data.data;
                                            for(let i in this.dataSource){
                                                let arr = this.members.filter(item=>item.id === this.dataSource[i].userId)
                                                if(arr.length>0){
                                                    arr[0].addMoneyArrange(this.dataSource[i])
                                                }
                                            }
                                        }
                                        else{
                                            this.dataSource = [];
                                        }
                                    });
                                }
                            })
                        }
                    });
                });
            },
            async computeProcess(){//获取这个人所有工序的完成进度
                this.processId = null;
                this.spinning = true;
                for(let i in this.currentMember.processList){
                    let data = {
                        "processId": this.currentMember.processList[i].processId,
                        "userId": this.currentMember.id,
                    };
                    let res = await workSituation.queryAll.requestPOSTAsync(data);
                    if(res.flag){
                        this.currentMember.processList[i].workDiary = res.data;
                        for(let j in res.data){
                            this.currentMember.processList[i].finishRatio += res.data[j].workRatio;
                        }
                    }
                    else{
                        this.currentMember.processList[i].workDiary = [];
                    }
                }
                this.spinning = false;
            },
            showAll(){
                this.currentMember = null;
                this.processId = null;
            },
            addData(){
                this.$refs.MonthProcessArrangeAdd.open();
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                taskMonthUser.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        if(!this.currentMember){
                            this.dataSource.splice(index,1);
                        }
                        else{
                            this.currentMember.moneyArrange.splice(index,1);
                        }
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            editDetail(record){
                this.currentRecord = record;
                this.visible = true;
                this.completeMoney = record.completeMoney;
                this.leaderMoney = record.leaderMoney;
                this.remarks = record.remarks;
                this.completeRatio = record.completeRatio;
            },
            saveData(){
                this.currentRecord.completeMoney = this.completeMoney;
                this.currentRecord.leaderMoney = this.leaderMoney;
                this.currentRecord.remarks = this.remarks;
                taskMonthUser.saveOrUpd.requestPOST(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init();
            this.tableColumns = new item_month_task_user().getTableColumns();
            this.tableColumns2 = new item_work_situation().getTableColumns();
            this.tableColumns2.pop();
            let time = parseInt(localStorage.getItem("time"));
            this.yearMonth = new moment(time).format("YYYY-MM")
        }
    }

    class ITask{
        /**
         *
         * @param id 用户id
         * @param name 姓名
         * @param taskWeight 任务工序的权重和
         * @param leader 是不是任务负责人
         * process:{
         *     processId,processTitle,processRatio,workRatio,finishRatio
         * }
         */
        constructor(id,name,taskWeight) {
            this.id = id;
            this.name = name;
            this.taskWeight = taskWeight?taskWeight:1;
            this.processList = [];
            this.workDiary = [];
            this.leader = false;
            this.moneyArrange = [];
        }
        get taskPercent(){ // 所有工序占总权重的百分比
            if(this.taskWeight > 0){
                let percent = 0;
                for(let i in this.processList){
                    percent += this.processList[i].processRatio * this.processList[i].workRatio
                }
                percent = percent / this.taskWeight;
                return  percent.toFixed(2)+"%";
            }
            else{
                return "0%"
            }

        }
        get taskMonthPercent(){
            if(this.taskWeight > 0){
                let monthPercent = [];
                for(let i in this.processList){
                    for(let j in this.processList[i].workDiary){
                        let yearMonth = new moment(this.processList[i].workDiary[j].workDate).format("YYYY-MM");
                        let processRatio = parseFloat(this.processList[i].workDiary[j].processRatio);
                        let processEmpRatio = parseFloat(this.processList[i].workDiary[j].processEmpRatio);
                        let workRatio = this.processList[i].workDiary[j].workRatio;

                        let arr = monthPercent.filter(item => item.yearMonth === yearMonth);
                        if(arr.length>0){
                            arr[0].value += processRatio * processEmpRatio * workRatio /10000
                        }
                        else{
                            monthPercent.push({
                                yearMonth:yearMonth,
                                value:processRatio * processEmpRatio * workRatio /10000
                            })
                        }
                    }
                }
                for(let k in monthPercent){
                    monthPercent[k].percent =  parseFloat((monthPercent[k].value / this.taskWeight *100).toFixed(2))
                }
                return  monthPercent;
            }
            else{
                return []
            }
        }
        get workMoney(){
            if(this.moneyArrange.length > 0){
                let money = 0;
                for(let i in this.moneyArrange){
                    money += this.moneyArrange[i].completeMoney
                }
                return money
            }
            else{
                return 0
            }
        }
        get leadMoney(){
            if(this.moneyArrange.length > 0){
                let money = 0;
                for(let i in this.moneyArrange){
                    money += this.moneyArrange[i].leaderMoney
                }
                return money
            }
            else{
                return 0
            }
        }
        setLeader(){
            this.leader = true;
            return this;
        }
        addProcess(process){
            this.processList.push(process);
            return this;
        }
        addMoneyArrange(item){
            this.moneyArrange.push(item);
            return this
        }
    }
    class Process{
        constructor(processId,processTitle,processRatio,workRatio,finishRatio) {
            this.processId = processId;
            this.processTitle = processTitle;
            this.processRatio = processRatio;
            this.workRatio = workRatio;
            this.finishRatio = finishRatio?finishRatio:0;
            this.MonthPercent = [];
        }
    }
</script>

<style scoped>

</style>