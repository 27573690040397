<!--
* @program: TYH_office_automation 
* @author: ly
* @component:billStatisticsByDepartment 
* @description: 按部门和时间统计消耗
* @create: 2021-08-12 15:52
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <bill-statistics-tool-bar_date ref="billBarDate" @event="eventHandle" />

          <div class="ant-row" style="height: 45%;">
            <div class="ant-col-24 divhover" style="box-shadow: 0px 0px 5px #888888;">
              <div ref="chart_item" style="height:100% "></div>
            </div>

          </div>

          <div class="ant-row" style="height: 40%;margin-top: 5px;">
            <div class="ant-col-24 divhover" style="box-shadow: 0px 0px 5px #888888;">
              <a-divider orientation="left">合计（￥）：{{allSum.toFixed(2)}}
                      <a-divider type="vertical" style="height: 85%;top: 6%; background-color: #7cb305;" />有发票：{{sum1.toFixed(2)}}
                      <a-divider type="vertical" style="height: 85%;top: 6%; background-color: #7cb305;" />  无发票：{{sum2.toFixed(2)}}
              </a-divider>


                <ArBaseTable :download="true" :view-model="true" :argument="argument" :page-size="15" :scroll="{ y:250}">
                  <template #action="{index,record}">
                    <a-button type="link" @click="showDetail(index,record)">详情</a-button>
                  </template>
                </ArBaseTable>

            </div>

          </div>


        </div>
    </div>

    <a-modal v-model:visible="visible"
             destroyOnClose
             :width="800"
             :footer="null"
             :title="dep_title">
        <ArBaseTable :download="false"  :view-model="true" :argument="argument2" :page-size="6" >
            <template #action="{index,record}">
<!--                <a-button type="link" @click="remove(index,record)">
                    <template #icon><MinusCircleOutlined style="color: red"/></template>
                </a-button>-->
              <a-button type="link" @click="showDetail_user(record,index)">
                <template #icon><Show title="查看"/></template>
              </a-button>
            </template>
        </ArBaseTable>

        <div v-if="flag_user">
          <a-divider orientation="left">月份：{{flag_title}} <a-divider type="vertical" style="height: 85%;top: 6%; background-color: #7cb305;" />合计金额：{{flag_applyAmount}}</a-divider>

          <ArBaseTable :download="false"  :view-model="true" :argument="argument3" :pagination="false" :scroll="{ y:250}">
          </ArBaseTable>
        </div>
        <span style="font-size:20px">部门报账总金额（￥）：{{sumMoney.toFixed(2)}}</span>
    </a-modal>
</template>

<script>
import {markRaw} from "vue";
import * as echarts from "echarts";
import Icons from "../../common/Icons";

    import billStatisticsToolBar_date from "./components/billStatisticsToolBar_date";
    import api_fundSummary from "../../../assets/api/fundSummary";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {MinusCircleOutlined} from "@ant-design/icons-vue"
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import {getNextMonth} from "../../../assets/utils/dataUtil";

    export default {
        name: "billStatisticsByDepartment",
      mixins:[Icons],
        components:{
          billStatisticsToolBar_date,ArBaseTable,MinusCircleOutlined
        },
        computed:{
            sumMoney(){
                let sum = 0;
                for(let i in this.argument2.dataSource){
                    sum += this.argument2.dataSource[i].applyAmount;
                }
                return sum;
            },
            allSum(){
                let sum = 0;
                for(let i in this.argument.dataSource){
                    sum += this.argument.dataSource[i].applyAmount;
                }
                return sum;
            },
            sum1(){
                let sum = 0;
                for(let i in this.argument.dataSource){
                    sum += this.argument.dataSource[i].haveInvoiceAmount;
                }
                return sum;
            },
            sum2(){
                let sum = 0;
                for(let i in this.argument.dataSource){
                    sum += this.argument.dataSource[i].noInvoiceAmount;
                }
                return sum;
            },
        },
        data(){
            return{
                visible:false,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("部门名称","departName",ColumnType.String,true).setTableView(200),
                       /* new Column("时间","dateStr",ColumnType.String,true).setTableView(80),*/
                        new Column("有发票报销(元)","haveInvoiceAmount",ColumnType.Number,true).setTableView(80),
                        new Column("无发票报销(元)","noInvoiceAmount",ColumnType.Number,true).setTableView(80),
                        new Column("合计(元)","applyAmount",ColumnType.Number,true).setTableView(80),
                        new Column("操作","actions","actions",false).setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()

                    loading:false//table的loading状态
                },
                argument2:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("月份","monthStr",ColumnType.String,true).setTableView(120),
                        new Column("有发票报销(元)","haveInvoiceAmount",ColumnType.Number,true).setTableView(150),
                        new Column("无发票报销(元)","noInvoiceAmount",ColumnType.Number,true).setTableView(150),
                        new Column("合计(元)","applyAmount",ColumnType.Number,true).setTableView(150),
                        new Column("操作","actions","actions",false).setTableView(60)
                    ], //提供table的字-段集 this.table.getTableColumns()

                    loading:false//table的loading状态
                },
              dep_title:"",
              argument3:{
                dataSource:null,//提供table的DataSource
                tableColumns:[
                  new Column("序号","_index",ColumnType.Index).setTableView(60),
                  new Column("姓名","userName",ColumnType.String,true).setTableView(150),
                  new Column("有发票报销(元)","haveInvoiceAmount",ColumnType.Number,true).setTableView(150),
                  new Column("无发票报销(元)","noInvoiceAmount",ColumnType.Number,true).setTableView(150),
                  new Column("合计(元)","applyAmount",ColumnType.Number,true).setTableView(150),
                ], //提供table的字-段集 this.table.getTableColumns()

                loading:false//table的loading状态
              },
              flag_user:false,
              flag_title:"",
              flag_applyAmount:0,
              chartsItem:null,

              date_interval:[],// 查询时间区间数组
            }
        },

      methods:{
          eventHandle({type,arg}){
              switch (type) {
                  case this.$eventTags.search:{
                    api_fundSummary.statiMonthDep.requestPOSTUrlParam(this,arg,res=>{
                      this.argument.loading = false;
                      if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                      }
                      else{
                        this.argument.dataSource = [];
                        this.$message.error(res.data.msg);
                      }
                      this.cypher_date();
                      this.fz_eachar();
                    });
                      break;
                  }
                  case this.$eventTags.refresh:{

                      break;
                  }

              }
          },
          showDetail(index,record){
            this.dep_title = "报账详情："+record.departName;
            this.flag_user = false;
            let monthSumList = record.monthSumList;
            if(monthSumList.length>0){
                  this.visible = true;
                  let arr = [];
                  this.argument2.dataSource = arr.concat(record.monthSumList);
            }
            else{
                this.$message.error("无数据");
            }
          },
          showDetail_user(record,index){
            this.flag_title = record.monthStr;
            this.flag_applyAmount = record.applyAmount;
            let userSumList = record.userSumList;
            if(userSumList.length>0){
              this.flag_user = true;
              let arr = [];
              this.argument3.dataSource = arr.concat(record.userSumList);
            }
            else{
              this.$message.error("无数据");
            }
          },
          remove(index,record){
              this.argument2.dataSource.splice(index,1);
          },
          /** 根据开始时间、结束时间拆分成时间数组 **/
          cypher_date(){
            this.date_interval = [];
            let date = this.$refs.billBarDate.get_value();
            if(date.length > 0){
              let startMonth = date[0];
              let endMonth = date[1];
              this.date_interval.push(startMonth);
              let next = 1;
              for(let i=0;i<next;i++){
                // 获取下个月
                let nextMonth = getNextMonth(this.date_interval[i]);
                if(nextMonth <= endMonth){
                  this.date_interval.push(nextMonth);
                  next++;
                }
              }
            }else {
              this.date_interval = [];
            }
          },
          /*** 统计分析 start ***/
          // 封装
          fz_eachar(){
            let data = this.argument.dataSource;
            let series_data = [];
            if(data.length > 0 && this.date_interval.length > 0){
              let date_interval = this.date_interval;
              data.forEach((item_i,i)=>{// 处理部门
                let serie = {
                  name: item_i.departName,
                  type: 'line',
                  emphasis: {
                    focus: 'series'
                  },
                  label: {
                    show: true,
                  },
                  data: []
                }
                let serie_data = [];
                date_interval.forEach((item_j,j)=>{
                  let monthSumList = item_i.monthSumList;
                  // 处理查询月份数据
                  if(monthSumList.length > 0){
                    let flag = false;
                    let applyAmount = 0; // 月总金额
                    monthSumList.forEach((item_k,k)=>{
                      let monthStr = item_k.monthStr;
                      if(item_j == monthStr){
                        flag = true;
                        applyAmount = item_k.applyAmount;
                      }
                    })
                    if(flag){
                      serie_data.push(applyAmount);
                    }else {
                      serie_data.push(0);
                    }
                  }
                })
                serie.data = serie_data;

                series_data.push(serie);

              })
            }else{// 无数据
              series_data = [];
            }
            this.eachar_item(series_data);
          },
          eachar_item(data){
              if(this.chartsItem){
                this.chartsItem.clear();
              }
              let option = null;
              if(data.length <= 0){
                option = {
                  title: {
                    extStyle: {
                      color: "grey",
                      fontSize: 20

                    },
                    textStyle:{
                      color:"#FFD700",
                    },

                    text: "暂无数据",

                    left: "center",

                    top: "center"

                  },
                }
              }else{
                option  = {
                  title:{
                    text:"部门报账统计",
                    x:'center',
                  },
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'shadow'
                    },
                    formatter: (params) => {
                      var result = params[0].axisValueLabel + '<br>'
                      params.forEach(function (item) {
                        if (item.value) {
                          result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                        }
                      })
                      // 保留数据
                      this.chartsCurrentData = params[0]
                      // 返回mousemove提示信息
                      return result
                    }
                  },
                 /* toolbox: {
                    show: true,
                    feature: {
                      magicType: { show: true, type: ['line', 'bar'] },
                      saveAsImage: { show: true },
                    }
                  },*/
                  legend: {
                    y:'bottom',
                    x:'center',
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '13%',
                    containLabel: true
                  },
                  xAxis: [
                    {
                      type: 'category',
                      data:this.date_interval,
                      name:'时间',
                      axisLine: {//x轴线的颜色以及宽度
                        show: true,
                        lineStyle: {
                          color: "blue",
                          width: 0,
                          type: "solid",
                        },

                      },
                      axisLabel: {
                        textStyle: {
                          color: 'blue',
                          fontSize:20,

                        }
                      },
                      nameTextStyle:{
                        color:'#A9A9A9',
                        padding:[100,0,0,0],		//表示[上,右,下,左]的边距
                      },
                      triggerEvent:true,
                    }
                  ],
                  yAxis: [
                    {
                      type:'value',
                      name:'(金额)',
                      nameTextStyle:{
                        color:'#A9A9A9',
                        padding:[100,0,0,15],		//表示[上,右,下,左]的边距
                      }
                    }
                  ],
                  series: data
                };
              }

              this.chartsItem = markRaw(echarts.init(this.$refs.chart_item))//获取dom元素;
              this.chartsItem.setOption(option);
            },
          /*** 统计分析 end ***/
        }
    }
</script>

<style scoped>

</style>