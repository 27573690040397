<!--
* @program: office_automation 
* @author: ly
* @component:projectDelayManager
* @description: 项目延期审核
* @create: 2021-12-31 09:29
-->
<template>
    <div class="oa-content">
        <div v-if="visible" class="oa-window">
            <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
                <template #title>
                    <div class="center-flex">
                        <div style="margin-left:30px ">项目延期审核</div>
                        <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                            <template #icon><SyncOutlined /></template>
                        </a-button>
                    </div>
                </template>
            </a-page-header>
            <a-layout-content style="display: inline-flex;margin:20px">
                <div v-for="(item,index) in applyStatus" :key="index" style="display: flex">
                    <a-card >
                        <a-badge :count="item.list.length">
                            <a-button :loading="statusLoading" :disabled="item.list.length === 0"  type="primary" @click="showApply(item)">{{item.label}}</a-button>
                        </a-badge>
                    </a-card>
                    <a-card >
                        <a-button  danger type="primary" @click="showHistory(item)">{{item.label}}延期审核历史</a-button>
                    </a-card>
                </div>
            </a-layout-content>
        </div>
        <div v-if="!visible" class="oa-window">
            <a-page-header style="border: 1px solid rgb(235, 237, 240)">
                <template #title>
                    <div class="center-flex">
                        <a-button danger type="primary" @click="visible = true">返回</a-button>
                        <div style="margin-left:30px ">{{currentTitle}}</div>
                        <div v-if="historyVisible">&nbsp;&nbsp;&nbsp; 审核情况：</div>
                        <a-radio-group v-if="historyVisible" v-model:value="defaultQuery.approveState" @change="radioChange" >
                            <a-radio :value="'1'">通过</a-radio>
                            <a-radio :value="'2'">不通过</a-radio>
                        </a-radio-group>
                    </div>
                </template>
            </a-page-header>
            <TwoParts ref="TwoParts" @change="handleChange" :trans="[100,40]"  pos="transform">
                <template #left>
                    <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="pageSize">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-tooltip title="查看延期数据详情">
                                    <a-button @click="showDetail(index,record)" class="action-button" type="link">
                                        <SearchOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip v-if="!historyVisible" title="审批">
                                    <a-button  @click="audit(index,record)" class="action-button" type="link">
                                        <EditFilled class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </ArBaseTableFilter>
                </template>
                <template #right>
                    <delayDataShower :record="currentRecord" />
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="modalVisible"
             destroyOnClose
             width="400px"
             :footer="null"
             :title="null">
        <a-radio-group style="margin: 20px" v-model:value="approveState" >
            <a-radio :value="'1'">通过</a-radio>
            <a-radio :value="'2'">不通过</a-radio>
        </a-radio-group>
        <a-textarea placeholder="请输入审核批注" :rows="3"  v-model:value="approveRemark"/>
        <a-divider />
        <a-button  type="primary"  @click="saveData">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import {SearchOutlined,SyncOutlined,EditFilled} from "@ant-design/icons-vue"
    import TwoParts from "../../_components/TwoParts";
    import item_progress_apply from "../../../assets/tables/item_progress_apply";
    import progressApply from "../../../assets/api/progressApply";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import delayDataShower from "./delayDataShower";
    export default {
        name: "projectDelayManager",
        components: {
            ArBaseTableFilter,TwoParts,SearchOutlined,SyncOutlined,EditFilled,
            delayDataShower
        },
        data(){
            return{
                table:new item_progress_apply(),
                applyStatus:[//["1","2","3","4"],["生产任务","生产工序","质检工序","员工工序"]
                    {value:"1",label:"项目",list:[]},
                    // {value:"2",label:"项目工序",list:[]},
                    // {value:"4",label:"员工任务",list:[]},
                    // {value:"3",label:"质检工序",list:[]},
                ],
                statusLoading:false,
                HistoryStatus:[//["1","2","3","4"],["生产任务","生产工序","质检工序","员工工序"]
                    {value:"1",label:"项目",list:[]},
                    // {value:"2",label:"项目工序",list:[]},
                    // {value:"3",label:"质检工序",list:[]},
                    // {value:"4",label:"员工任务",list:[]},
                ],
                defaultQuery:{
                    approveState:"0", //["0","1","2"],["未审核","通过","不通过"]+
                    applyType:"1"
                },
                currentTitle:null,
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:true,
                spinning:false,
                historyVisible:false,
                modalVisible:false,
                approveState:null,
                approveRemark:null,
                currentRecord:null,
                currentIndex:null,
            }
        },
        methods:{
            init(){
                this.argument.tableColumns = this.table.getTableColumns();
                this.applyStatus=[//["1","2","3","4"],["生产任务","生产工序","质检工序","员工工序"]
                    {value:"1",label:"项目",list:[]},
                    // {value:"2",label:"项目工序",list:[]},
                    // {value:"4",label:"员工任务",list:[]},
                    // {value:"3",label:"质检工序",list:[]},
                ];
                this.defaultQuery.approveState ="0";
                this.statusLoading = true;
                let data = {};
                Object.assign(data,this.defaultQuery);
                progressApply.queryAll.requestPOST(this,data,res=>{
                    this.statusLoading = false;
                    if(res.data.flag){
                        for(let i in res.data.data){
                            let status = res.data.data[i].applyType;
                            this.applyStatus.filter(item=>item.value === status)[0].list.push(res.data.data[i])
                        }
                    }
                })
            },
            showHistory(item){
                if(this.argument.tableColumns.filter(item =>item.dataIndex === "approveRemark").length>0){
                    this.argument.tableColumns.filter(item =>item.dataIndex === "approveRemark")[0].setTableView(200);
                }
                this.visible = false;
                this.historyVisible = true;
                this.currentTitle = item.label + " —— 审核历史";
                this.defaultQuery.approveState = "1";
                this.defaultQuery.applyType = item.value;
                this.argument.loading = true;
                progressApply.queryAll.requestPOST(this,this.defaultQuery,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            showApply(item){
                if(this.argument.tableColumns.filter(item =>item.dataIndex === "approveRemark").length>0){
                    this.argument.tableColumns.filter(item =>item.dataIndex === "approveRemark")[0].setTableView(false);
                }
                this.visible = false;
                this.historyVisible = false;
                this.currentTitle = item.label + " —— 待审核";
                this.argument.dataSource = item.list;
            },
            radioChange(){
                this.argument.loading = true;
                progressApply.queryAll.requestPOST(this,this.defaultQuery,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            audit(index,record){
                this.modalVisible = true;
                this.approveState = null;
                this.approveRemark = null;
                this.currentRecord = record;
                this.currentIndex = index;
            },
            saveData(){
                let data = {
                    id:this.currentRecord.id,
                    approveState:this.approveState,
                    approveRemark:this.approveRemark
                };
                progressApply.approve.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.modalVisible = false;
                        this.argument.dataSource.splice(this.currentIndex,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.argument.tableColumns = [
                        new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                        new Column("原计划时间","oldDate",ColumnType.Date).setTableView(100),
                        new Column("延期到","newDate",ColumnType.Date,true).setTableView(100),
                        new Column("申请人","applyUserName",ColumnType.String).setTableView(100),
                        new Column("审核状态","approveState",ColumnType.Enum).setTableView(100)
                            .setEnum(["0","1","2"],["未审核","通过","不通过"],["pink","green","red"]),
                        new Column("操作", "actions", "actions", false).setTableView(80)
                    ];
                } else {
                    this.argument.tableColumns = this.table.getTableColumns();
                }
            },
            showDetail(index,record){
                this.currentRecord = record;
                this.$refs.TwoParts.transform();
            },
            refresh(){
                this.init()
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>