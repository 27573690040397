<!--
* @program: tyh-oa 
* @author: ly
* @component:personalBillSteps 账单审核流程
* @description: 
* @create: 2021-04-14 10:25
-->
<template>
    <div class="center-flex">
        <a-steps style="width: 60%" size="small" >
            <a-step v-for="({approvalUserName,updateTime,remarks,status},key) in steps"
                    :status = "status"
                    :sub-title="getTime(updateTime)"
                    :key="key">
                <template #title>
                    <div style="display: flex;justify-content: space-around;height: 24px;align-items: center;">
                        <div>{{approvalUserName}}</div>
                        <a-popover v-if="remarks" trigger="hover" placement="top">
                            <template #content>
                                {{remarks}}
                            </template>
                            <a-button  type="link" >审核意见</a-button>
                        </a-popover>
                    </div>
                </template>
            </a-step>
        </a-steps>
    </div>
</template>

<script>
    import api_item_finance_record_approval from "../../../assets/api/recordApproval"
    import moment from "moment";
    export default {
        name: "personalBillSteps",
        props:["record","flowIds"],
        emits:["update:flowIds"],
        data(){
            return{
                steps:[
                    {approvalUserName:"提交审核",state:"0"}
                ],
                modalVisible:false,
                remarks:null,
                dateFormat: 'YYYY/MM/DD HH:mm:SS',
                current:0,
                userId:this.$store.getters.userInfo.id
            }
        },
        methods:{
            init(){
                if(this.record.id){
                    let data = {
                        recordId:this.record.id
                    };
                    api_item_finance_record_approval.queryAll.requestPOSTUrlParam(this,data,res=>{
                        if(res.data.flag){
                            this.steps = this.steps.concat(res.data.data);

                            /**获取当前角色审核的账单条目 */
                            let flows = res.data.data.filter(item=>{
                                return item.approvalUser === this.userId;
                            });
                            if(flows.length>0){
                                this.$emit("update:flowIds",flows[0].flowIds);
                            }


                            for(let i in this.steps){
                                switch (this.steps[i].state) {
                                    case "0":{
                                        this.steps[i].status = "wait" ; //wait process finish error
                                        break;
                                    }
                                    case "1":{
                                        this.steps[i].status = "process" ; //wait process finish error
                                        this.current = parseInt(i);
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                    case "2":{
                                        this.steps[i].status = "finish" ; //wait process finish error
                                        this.current = parseInt(i);
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                    case "3":{
                                        this.steps[i].status = "error" ; //wait process finish error
                                        this.current = parseInt(i);
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                }
                            }
                        }
                    })
                }
            },
            getTime(timeStamp){
                if(timeStamp){
                    return moment(timeStamp).locale('zh-cn').format(this.dateFormat);
                }
                else{
                    return "";
                }
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>