<!--
*@description：行政区划维护
*@author:rzl
*@date:2021/6/18 13:45
-->
<template>
  <div class="main-css ant-row">
    <div class="ant-col-6" style="margin-left: 10px;">
      <area-tree ref="areaTree" @event="selectTree" />
    </div>
    <div class="ant-col-17" style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <area-tool-bar ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <area-edit ref="local_tabs" @event="eventHandle"/>

  </div>


</template>

<script>
import Icons from "../../common/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import areaToolBar from "./components/areaToolBar";
import areaTree from "./common/point/areaTree";
import areaEdit from "./point/areaEdit";

import area_api from "./config/area_api";
import area_item from "./config/area_item";


export default {
  name:"AreaDivision",
  mixins:[Icons],
  components:{
    areaToolBar,ArBaseTable,areaTree,areaEdit
  },
  data(){
    return {
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new area_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new area_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 10,
      },
      pid:"0",//上级id ，默认不传就是省级为0
      level:"0",// 级别：0:全国；1：省；2：市；3：区县；4乡镇街道；5村
    };
  },

  mounted() {
    this.$refs.areaTree.getTreeData();
    this.init();
  },
  methods:{
    /** 初始化 **/
    init(){
      this.argument.loading = true;
      let parms = {
        pid:this.pid,
        name:null,
        code:null,
      };
      this.pageParam.page = 1;
      this.pageParam.limit = 10;
      area_api.queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
        this.argument.loading = false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data;
          this.$refs.pstable.setTotalSize(res.data.count);
        }
      });
    },
    /** 回调方法 **/
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
          this.argument.loading = true;
          let parms = this.$refs.toolBar.getParms();
          parms.pid = this.pid;
          this.pageParam.page = 1;
          this.pageParam.limit = 10;
          this.$refs.pstable.setCurrentPage(1);
          area_api.queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource =  res.data.data;
              this.$refs.pstable.setTotalSize(res.data.count);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          this.$refs.pstable.setCurrentPage(1);
          this.init();
          break;
        }
        case this.$eventTags.add:{
          this.argument.formData = {};
          this.argument.formData.pid = this.pid;
          this.$refs.local_tabs.open("新增信息","add",false,this.pid,this.level,this.argument);
          break;
        }
        case this.$eventTags.editInfo:{
          /** 获取字典树 **/
          this.$refs.areaTree.getTreeData();
          this.$refs.pstable.setCurrentPage(1);
          this.init();
          break;
        }

      }
    },
    /** 分页查询 **/
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      parms.pid = this.pid;
      this.argument.loading = true;
      area_api.queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data;
          this.$refs.pstable.setTotalSize(res.data.count);
        }
      });
    },
    /** 查询树 **/
    selectTree({type,arg}){
      switch (type){
        case this.$eventTags.search:{
          this.pid = arg.id;
          this.level = arg.level;
          this.$refs.toolBar.set_pidName(arg.label);
          this.init();
          break;
        }
      }
    },
    /** 查看详情 **/
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.open("查看信息","show",true,this.pid,this.level, this.argument);
    },
    /** 编辑信息 **/
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.open("编辑信息","edit",false,this.pid,this.level,this.argument);
    },
    /** 删除信息 **/
    deleteData(index,arg){
      let data = {id:arg.id};
      area_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          /** 获取字典树 **/
          this.$refs.areaTree.getTreeData();
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
  },
};
</script>
<style>

</style>