/**
 * 成果类别管理
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    /** 成果类型配置 **/
    saveOrUpd:new APIObject("/api/achieveType/saveOrUpd.do"),// 保存/修改-单条
    getAllTree:new APIObject("/api/achieveType/getAllTree.do"),// 查询所有-树形结构（成果类型树形结构）
    queryAll:new APIObject("/api/achieveType/queryAll.do"),// 查询-所有数据
    delete:new APIObject("/api/achieveType/delete.do"),// 删除-单条
    queryOne:new APIObject("/api/achieveType/queryOne.do"),// 查询-单条
    getAllRoot:new APIObject("/api/achieveType/getAllRoot.do"),//查询所有-第一级根节点
    queryPage:new APIObject("/api/achieveType/queryPage.do"),//查询-分页
    batchDelete:new APIObject("/api/achieveType/batchDelete.do"),// 删除-批量

    /** 成果属性配置管理 **/
    pz_batchDelete:new APIObject("/api/achieveProConf/batchDelete.do"),// 删除-批量
    pz_delete:new APIObject("/api/achieveProConf/delete.do"),// 删除-单条
    pz_saveOrUpd:new APIObject("/api/achieveProConf/saveOrUpd.do"),// 保存/修改-单条
    pz_queryOne:new APIObject("/api/achieveProConf/queryOne.do"),// 查询-单条
    pz_queryPage:new APIObject("/api/achieveProConf/queryPage.do"),// 查询-分页
    pz_queryAll:new APIObject("/api/achieveProConf/queryAll.do"),// 查询-所有数据

};

export default api;