/**
 * @program: tdzs
 * @author: ly
 *  @description: 用于定义字段类型 用于form 或table column的时候区别显示
 * @create: 2020-08-25 16:33
 **/

function ColumnType(){
    this.String ="String";
    this.String2 ="String2";// 去除字体居中，改为使用时自定义样式
    this.TextArea ="TextArea"; //用于多行展示的String类型
    this.Number ="Number";
    this.Date = "Date";
    this.Month = "Month"; //用于显示月份
    this.Enum ="Enum";  //枚举类型
    this.File = "File"; //字段为选择文件上传时使用
    this.Boolean = "Boolean"; //用于显示true false 都显示的情况
    this.BooleanCheck = "BooleanCheck"; //用于显示 true 的情况
    this.Index = "Index"; //用于显示table的第一个column，表示序号
    this.IdReplace = "IdReplace"; //用于表示数据库中存放的是ID，但是显示的时候却需要显示名字,显示名字由后台传出的字段中，并且在编辑的时候可以通过查询关键字来进行查询,查询方式使用postUrl
    this.IdReplace2 = "IdReplace2"; //用于表示数据库中存放的是ID，但是显示的时候却需要显示名字，显示名字由后台传出的字段中，并且在编辑的时候可以通过查询关键字来进行查询，查询方式使用post
    this.ObjectReplace = "ObjectReplace"; //用于表示数据库中存放的是ID，但是显示的时候却需要显示名字，显示名字在后台传出的Object中，并且在编辑的时候可以通过查询关键字来进行查询，查询方式使用postUrl
    this.Category =  "Category"; //用于选择报销时候的费用类别
    this.CategoryAssets =  "CategoryAssets"; //用于选择设备类别
    this.PreViewFile = "PreViewFile" ; //可以预览的文件类型，用于上传报销凭证和展示
    this.Avatar = "Avatar"; //用于用户头像，上传和预览
    this.Selector = "Selector"; //给字段特定的selector
    this.PositionLevel = "PositionLevel"; //岗位级别选择使用
    this.RemoteEnum = "RemoteEnum";
    this.FinancialSubject = "FinancialSubject"; //会计统计大类使用
    this.Giro = "Giro"; //用于转账类型
    this.StrArr = "StrArr"; //用于展示 String Array 的类型

    this.select = "select";// 下拉动态调用值
}

ColumnType.prototype.getType = function (string) {
    if(this[string] !==null){
        return this[string];
    }
    else{
        return null;
    }
};
ColumnType.prototype.getString = function (type) {
    for(let i in ColumnType){
        if(type === ColumnType[i]){
            return i;
        }
    }
    return null;
};

let columnType = new ColumnType();
export default columnType;