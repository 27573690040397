<!--
* @program: office_automation 
* @author: ly
* @component:projectOutSourcePayApply 
* @description: 
* @create: 2021-12-21 15:19
-->
<template>
    <a-spin :spinning="spinning">
        <div>
            <span>申请部门：</span>
            <a-select v-model:value="departmentId">
                <a-select-option v-for="option in departments" :key="option.id">
                    {{ option.name }}
                </a-select-option>
            </a-select>
        </div>
        <a-radio-group class="search-item" v-model:value="visible">
            <a-radio :value="true">有合同</a-radio>
            <a-radio :value="false">无合同</a-radio>
        </a-radio-group>
        <div v-if="visible" class="center-flex">
            <div v-if="selectOptions.length>0">
                <div class="center-flex">
                    <div>合同：</div>
                    <a-select  v-model:value="contractId" @select="handleSelect"
                               style="width: 300px" class="search-item">
                        <a-select-option v-for="option in selectOptions" :key="option.id">
                            <a-tooltip :title="'合同编号:  '+option.number">
                                <a-button style="padding: 0" type="text">
                                    {{option.name }}
                                </a-button>
                            </a-tooltip>
                        </a-select-option>
                    </a-select>
                </div>
                <div class="center-flex">
                    <div>收款单位/个人：</div>
                    <a-select  v-model:value="contractId" @select="handleSelect"
                               style="width: 300px" class="search-item">
                        <a-select-option v-for="option in selectOptions" :key="option.id">
                            <a-tooltip :title="'合同名:  '+option.name">
                                <a-button style="padding: 0" type="text">
                                    {{option.associatedName }}
                                </a-button>
                            </a-tooltip>
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div v-else>暂无合同</div>
        </div>
        <a-divider />
        <FcForm v-if="visible2" style="width: 400px;" :view-model="viewModel" v-model:form-data="mFormData"  :form-columns="formColumns" >
            <template #action="{record}">
                <a-button class="search-item" type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-spin>
</template>

<script>
    import FcForm from "../../../components/form/FcForm";
    import api_item_contract_info from "../../../assets/api/contractInfo";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import api_item_outsourc_info from "../../../assets/api/outSourceInfo";
    import {companyColors, companyIds, companyNames} from "../../../assets/system/companies";
    import payApply from "../../../assets/api/payApply";
    export default {
        name: "projectOutSourcePayApply",
        components :{FcForm},
        props:["formData","viewModel","projectId"],
        emits:["refresh"],
        data(){
            return{
                contractId:null,
                selectOptions:[],
                mFormData:null,
                spinning:false,
                visible:false,
                visible2:true,
                departments:[],
                departmentId:null,
            }
        },
        computed:{
            formColumns(){
                if(this.formData.history){
                    return [
                        new Column("收款单位/个人","outSourceId",ColumnType.IdReplace,true).setTableView(200)
                            .setIdReplaceObject("outSourceName",{api:api_item_outsourc_info.queryAll,tag:"queryText"}),
                        new Column("付款公司", "payCompanyId", ColumnType.Enum, true).setTableView(200)
                            .setEnum(companyIds, companyNames,companyColors),
                        new Column("付款部门", "applyDepartName", ColumnType.String, false).setTableView(100),
                        new Column("申请支付金额","payAmount",ColumnType.Number,true).setDefaultValue("0").setTableView(100),
                        new Column("支付描述","payReason",ColumnType.String,true).setTableView(200),
                        new Column("支付时间","payTime",ColumnType.Date,true).setTableView(100),
                    ]
                }
                else {
                    if(this.visible){
                        return [
                            new Column("收款单位/个人","outSourceId",ColumnType.IdReplace,false).setTableView(200)
                                .setIdReplaceObject("outSourceName",{api:api_item_outsourc_info.queryAll,tag:"queryText"}),
                            new Column("付款公司", "payCompanyId", ColumnType.Enum, false).setTableView(200)
                                .setEnum(companyIds, companyNames,companyColors),
                            new Column("付款部门", "applyDepartName", ColumnType.String, false).setTableView(100),
                            new Column("申请支付金额","payAmount",ColumnType.Number,true).setDefaultValue("0").setTableView(100),
                            new Column("支付描述","payReason",ColumnType.String,true).setTableView(200),
                        ]
                    }
                    else{
                        return [
                            new Column("收款单位/个人","outSourceId",ColumnType.IdReplace,true).setTableView(200)
                                .setIdReplaceObject("outSourceName",{api:api_item_outsourc_info.queryAll,tag:"queryText"}),
                            new Column("付款公司", "payCompanyId", ColumnType.Enum, true).setTableView(200)
                                .setEnum(companyIds, companyNames,companyColors),
                            new Column("付款部门", "applyDepartName", ColumnType.String, false).setTableView(100),
                            new Column("申请支付金额","payAmount",ColumnType.Number,true).setDefaultValue("0").setTableView(100),
                            new Column("支付描述","payReason",ColumnType.String,true).setTableView(200),
                        ]
                    }

                }
            }
        },
        methods:{
            init(){
                let data = {
                    itemId: this.projectId,
                    itemName: "",
                    name: "",
                    number: "",
                    type: ""
                };
                this.spinning = true;
                api_item_contract_info.queryAll.requestPOST(this,data,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        this.selectOptions = res.data.data;
                    }
                })
            },
            handleSelect(){
                /**
                 * associatedKey 签署方id associatedName 签署方名称
                 * sigCompany 签订公司id
                 */
                let {associatedKey,associatedName,sigCompany} = this.selectOptions.filter(option=>{return option.id === this.contractId})[0];
                this.mFormData.outSourceId = associatedKey;
                this.mFormData.outSourceName = associatedName;
                this.mFormData.payCompanyId = sigCompany;
                this.mFormData.contractId = this.contractId;

                this.visible2 = false;
                setTimeout(()=>{
                    this.visible2 = true
                },100)
            },
            saveData(record){
                if(this.departmentId){
                    record.applyDepart = this.departmentId;
                }
                else{
                    this.$message.error("部门信息错误");
                    return
                }
                record.itemId = this.projectId;
                if(record.history){
                    payApply.save.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            this.$emit("refresh");
                            this.$message.success(res.data.msg)
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else{
                    payApply.saveOrUpd.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            this.$emit("refresh");
                            this.$message.success(res.data.msg)
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
            }
        },
        created() {
            this.mFormData = {...this.formData};
            this.mFormData.outSourceName = this.formData.outSourceName;
            if(this.mFormData.contractId){
                this.visible = true;
                this.selectOptions = [
                    {id:this.mFormData.contractId,name:this.mFormData.contractNum,associatedName:this.mFormData.outSourceName}
                    ];
                this.contractId = this.mFormData.contractId;
            }
            this.departments = this.$store.getters.userInfo.departmentDTOList;
            this.departmentId = this.departments[0].id;
            this.init();
        }

    }
</script>

<style scoped>

</style>