/** 
* 自动生成文件，请勿修改 
* @name: 系统:员工年度工资记录
* @description: 员工年度工资记录
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const userSalary = { 
	/** 1 summary:导入-年度员工基础工资等数据导入	method:post */ 
	importExcel : new APIObject("/api/userSalary/importExcel.do"), 
	/** 2 summary:导出-员工年终绩效计算汇总表	method:post */ 
	personExport : new APIObject("/api/userSalary/personExport.do"), 
	/** 3 summary:导出-年度员工基础工资模板(员工实时名单数据)	method:get */ 
	exportExcel : new APIObject("/api/userSalary/exportExcel.do"), 
	/** 4 summary:查询-员工年终绩效计算汇总表	method:post */ 
	personReport : new APIObject("/api/userSalary/personReport.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/userSalary/delete.do"), 
	/** 6 summary:新增/修改-单条	method:post */ 
	saveOrUpdate : new APIObject("/api/userSalary/saveOrUpdate.do"), 
	/** 7 summary:根据id查询	method:post */ 
	queryOne : new APIObject("/api/userSalary/queryOne.do"), 
	/** 8 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/userSalary/batchDelete.do"), 
	/** 9 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/userSalary/queryPage.do"), 
	/** 10 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/userSalary/queryAll.do"), 
};
export default userSalary;