<!--
* @program: office_automation 
* @author: ly
* @component:RoleSetting 
* @description: 
* @create: 2022-03-07 11:48
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                    <template #icon><SearchOutlined/></template>
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button class="search-item" type="text" >
                    选择部门
                </a-button>
                <a-select v-model:value="department" style="width: 250px"
                          class="search-item"
                          @change="departmentSelect">
                    <a-select-option v-for="item in departments"  :key="item.id">
                        {{item.fullName}}
                    </a-select-option>
                </a-select>
                <span style="margin-left: 10px">状态：</span>
                <a-radio-group @change="radioChange" class="search-item" v-model:value="defaultQuery.disableState">
                    <a-radio :value="'1'">在职</a-radio>
                    <a-radio :value="'0'">离职</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" :trans="[30,30]" :enable-change="false">
                <template #left>
                    <ArBaseTableFilter  :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                        <template #action="{index,record}">
                            <div class = "action-column">
                                <a-button @click="editDetail(index,record)" class="action-button" type="link">
                                    <EditFilled class="pointer"/>
                                </a-button>
                            </div>
                        </template>
                    </ArBaseTableFilter>
                </template>
                <template #right>
                    <div v-if="record" style="display: flex;flex-wrap: wrap;">
                        <UserRoleSetting :view-model="viewModel" :user-id="record.id" :roles="allRoles" />
                    </div>
                    <div v-else>
                        <welcome2 />
                    </div>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import userInfoManager from "../userInfoManager/userInfoManager";
    import UserRoleSetting from "./UserRoleSetting";
    export default {
        name: "RoleSetting",
        mixins:[userInfoManager],
        components:{
            UserRoleSetting
        },
        data() {
            return {
                allRoles:null,
            }
        }
    }
</script>

<style scoped>

</style>