/** 
* 自动生成文件，请勿修改 
* @name: 系统:角色管理-系统角色管理模块
* @description: 系统角色管理模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const role = { 
	/** 1 summary:查询-全部不分页(根据userId查询用户所拥有的角色)	method:post */ 
	findRoleByUser : new APIObject("/api/role/findRoleByUser.do"), 
	/** 2 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/role/delete.do"), 
	/** 3 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/role/batchSaveOrUpd.do"), 
	/** 4 summary:启用、禁用-单条	method:post */ 
	stateModify : new APIObject("/api/role/stateModify.do"), 
	/** 5 summary:查询-单条(根据主键)	method:post */ 
	queryOne : new APIObject("/api/role/queryOne.do"), 
	/** 6 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/role/batchDelete.do"), 
	/** 7 summary:查询-全部不分页(根据name,roleState)	method:post */ 
	queryAll : new APIObject("/api/role/queryAll.do"), 
	/** 8 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/role/saveOrUpd.do"), 
	/** 9 summary:查询-分页(根据name,roleState)	method:post */ 
	queryPage : new APIObject("/api/role/queryPage.do"), 
	/** 10 summary:启用、禁用-批量	method:post */ 
	batchstateModify : new APIObject("/api/role/batchstateModify.do"), 
};
export default role;