<!--
* @program: tyh-oa 
* @author: ly
* @description: loginPage
* @create: 2021-03-19 16:16
-->
<template>
    <a-layout style="height: 100%">
        <a-layout-header class="header">
            <oaBanner/>
        </a-layout-header>
        <a-layout-content class="main">
            <a-card class="card" title="登录">
                <a-form
                        :label-col="labelCol"
                        :model="formData"
                        :wrapper-col="wrapperCol">
                    <a-form-item label="用户名">
                        <a-input :maxlength="11" v-model:value="formData.phone"
                                 v-on:keyup.enter="login"
                        >
                            <template #prefix>
                                <UserOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item label="密码">
                        <a-input-password type="password" v-model:value="formData.password"
                                          v-on:keyup.enter="login">
                            <template #prefix>
                                <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                            </template>
                        </a-input-password>
                    </a-form-item>
                    <a-form-item label="验证码" v-if="imageCode">
                        <a-input v-model:value="formData.result"
                                 v-on:keyup.enter="login">
                            <template #prefix>
                                <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item label="计算" v-if="imageCode">
                        <img :src="image"/>
                    </a-form-item>
                </a-form>
                <a-checkbox style="margin:5px" v-model:checked="checked">记住密码</a-checkbox>
                <a-button @click="login" style="margin-left:15px" type="primary" v-on:keyup.enter="login">
                    登录
                </a-button>
                <a-button @click="forgetPassword" style="margin:5px" type="link">
                    忘记密码
                </a-button>
            </a-card>
            <oa-footer/>
        </a-layout-content>
    </a-layout>
</template>

<script>
    import oaBanner from "./oaBanner";
    import oaFooter from "./oaFooter";
    import {LockOutlined, UserOutlined} from '@ant-design/icons-vue';
    import api from "../assets/api/_default";
    import api_item_task_process_employee from "../assets/api/processEmployee";

    export default {
        name: "loginPage",
        components: {
            oaBanner, oaFooter, UserOutlined, LockOutlined
        },
        data() {
            return {
                formData: {
                    phone: '',
                    password: '',
                    result: null,
                },
                labelCol: {span: 6},
                wrapperCol: {span: 16},
                checked: false,
                imageCode: false,
                image: null

            }
        },
        provide() {
            return {
                menuVisible: false
            }
        },
        methods: {
            login() {
                if (this.checkData()) {
                    localStorage.setItem("phone", this.formData.phone);
                    localStorage.setItem("password", this.formData.password);

                    localStorage.setItem("savePassword", this.checked ? "t" : "");
                    let data = {
                        phone: this.formData.phone,
                        password: this.formData.password,
                    };
                    if (this.imageCode) {
                        data.result = this.formData.result
                    }
                    api.login.requestPOSTUrlParam(this, data, res => {
                        this.spinning = false;
                        if (res.data.flag) {
                            this.$router.push({path: '/main'});
                            this.$store.commit("initUserInfo", res.data.data);
                            this.userName = res.data.data.name;
                            let password = localStorage.getItem("password");
                            if (password === "000000" || password === "123456") {
                                this.$router.push({path: '/resetPassword'});
                            }
                            api_item_task_process_employee.returnDate.requestPOST(this, {}, res => {
                                if (res.data.flag) {
                                    localStorage.setItem("time", res.data.data);
                                }
                            })
                            setInterval(() => {//读取系统时间
                                api_item_task_process_employee.returnDate.requestPOST(this, {}, res => {
                                    if (res.data.flag) {
                                        localStorage.setItem("time", res.data.data);
                                    }
                                })
                            }, 1000 * 60 * 10);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                        if (res.data.count >= 3) {
                            this.imageCode = true;
                            this.getCaptcha()
                        }
                    });
                }
            },
            checkData() {
                if (!this.formData.phone) {
                    this.$message.error("请输入用户名");
                    return false;
                }
                if (!this.formData.password) {
                    this.$message.error("请输入密码");
                    return false;
                }
                if (this.imageCode && !this.formData.result) {
                    this.$message.error("请输入验证码");
                    return false;
                }
                return true;
            },
            getCaptcha() {
                let data = {
                    phone: this.formData.phone
                };
                api.getCaptcha.requestGET(this, data, res => {
                    if (res.data.flag) {
                        this.image = res.data.data.image
                    }
                })
            },
            forgetPassword() {
                this.$router.push({path: '/ForgetPassword'});
            }
        },
        created() {
            this.formData.phone = localStorage.getItem("phone");
            this.checked = (localStorage.getItem("savePassword") === "t");
            if (this.checked) {
                this.formData.password = localStorage.getItem("password");
            }
        }
    }
</script>

<style scoped>
    .card {
        width: 300px;
        position: absolute;
        right: 200px;
        top: 40%;
        box-shadow: 3px 3px 4px 0px #c6c4c4;
    }
</style>