/**
 * 成果属性配置
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"

export default class achieveConfig_item extends Table{
    constructor() {
        super();
        this.name = "achieveConfig_item";
        this.CNName = "成果属性配置";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("成果类别id","typeId",ColumnType.String,false).setVisible(false),
            new Column("属性名称","name",ColumnType.String,true).setTableView(100),
            new Column("属性编码","code",ColumnType.String,true).setTableView(100),
            new Column("是否必填","require",ColumnType.Boolean,true).setTableView(80),
            new Column("数据类型","dataType",ColumnType.Enum,true).setTableView(80)
                .setEnum(["string","number","date"],["字符串","数字","日期"],["Orchid","blue","pink"]),
            new Column("输入框类型","inputType",ColumnType.Enum,true).setTableView(80)
                .setEnum(["input","textarea","data","select","radio","checkbox",],
                    ["输入框","文本框","日期","下拉选择","单选","多选"],
                    ["Pink","MediumPurple","CornflowerBlue","PowderBlue","Plum","DarkViolet"])
                .setTriggerOtherColumn("select",
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="valueList"})[0].setVisible(true);
                    },
                    (formData,cols)=>{
                        if(formData["inputType"] === 'radio'|| formData["inputType"] === 'checkbox'){
                            cols.filter(col=>{return col.dataIndex==="valueList"})[0].setVisible(true);
                        }else{
                            cols.filter(col=>{return col.dataIndex==="valueList"})[0].setVisible(false);
                        }
                    }
                ),
            new Column("类型值","valueList",ColumnType.TextArea,true),
            new Column("描述","remarks",ColumnType.TextArea,true),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(100)];
    }
}