/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_work_situation  工作日志记录管理
 * @create: 2021-04-25 11:50
 * 用于记录项目任的工作
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_work_situation extends Table{
    constructor() {
        super();
        this.name = "item_work_situation";
        this.CNName = "工作日志记录管理";
        this.columnArray = [
            new Column("日期","workDate",ColumnType.Date,false).setTableView(100),
           // new Column("员工-工序ID","processEmployeeId",ColumnType.String,false).setVisible(false),
            new Column("工序名称","processTitle",ColumnType.String,false).setTableView(100),
            new Column("工作占比","workRatio",ColumnType.Number,true).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("工作内容","workContent",ColumnType.String,true).setTableView(150),
            new Column("备注","remarks",ColumnType.String,true).setTableView(100),
            new Column("记录时间","createTime",ColumnType.Date,false).setTableView(100),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}