<!--
* @program: tyh-oa 
* @author: ly
* @description: nonComponent 
* @create: 2021-03-25 11:41
-->
<template>
    <div style="position: absolute;top: 40%;left: 50%; font-size: 60px" class="non-select">
        功能开发中
    </div>
</template>

<script>
    export default {
        name: "nonComponent"
    }
</script>

<style scoped>

</style>