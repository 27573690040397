<!--
* @program: tyh-oa 
* @author: ly
* @description: baseTable 
* @create: 2021-03-23 14:48
-->
<template>
    <a-table
            class="myTable"
            bordered
            :dataSource="source"
            :columns="cols" rowKey="id"
            :pagination="pagination"
            :loading="loading"
    >
        <template #String="{column,record}">
            <type-string :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Number="{column,record}">
            <type-number :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Enum="{column,record}">
            <type-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Date="{column,record}">
            <type-date :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Month="{column,record}">
            <type-month :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Index="{column,record,index}">
            <type-index  :index="index" :passedNum="passedNum" :currentIndex="currentIndex"  :viewModel="viewModel" :column="column" :record="record"/>
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Category="{column,record}">
            <type-category :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #CategoryAssets="{column,record}">
            <type-category-assets :viewModel="viewModel" :column="column" :record="record" />
        </template>
    </a-table>
</template>

<script>

    import typeNumber from "../dataPresentType/typeNumber";
    import typeString from "../dataPresentType/typeString";
    import typeEnum from "../dataPresentType/typeEnum";
    import typeDate from "../dataPresentType/typeDate";
    import typeBooleanCheck from "../dataPresentType/typeBooleanCheck";
    import typeIndex from "../dataPresentType/typeIndex";
    import typeIdReplace from "../dataPresentType/typeIdReplace";
    import {computed} from "vue";
    import {checkAuth} from "../../assets/utils/general"
    import emitDataCheck from "../emitDataCheck";
    import typeMonth from "../dataPresentType/typeMonth";
    import typeCategory from "../dataPresentType/typeCategory";
    import typePreViewFile from "../dataPresentType/typePreViewFile";
    import typeAvatar from "../dataPresentType/typeAvatar";
    import typeIdReplace2 from "../dataPresentType/typeIdReplace2";
    import typeCategoryAssets from "../dataPresentType/typeCategoryAssets";
    export default {
        name: "baseTable",
        inject:["dataSource","tableColumns","authority"], //// viewModel true为查看模式，false为一般模式
        mixins:[emitDataCheck],
        props:["loading"],
        components:{
            typeNumber,
            typeString,
            typeEnum,
            typeDate,
            typeBooleanCheck,
            typeIndex,
            typeIdReplace,
            typeMonth,
            typeCategory,
            typePreViewFile,
            typeAvatar,
            typeIdReplace2,
            typeCategoryAssets
        },
        provide(){
            return{
                columnAuth:computed(()=>this.columnAuth),
                presentType:"table"
            }
        },
        computed:{
            passedNum(){
                return this.pagination.defaultPageSize*(this.currentPage-1);
            },
            source(){
                return this.dataSource.value;
            },
            cols(){
                return this.tableColumns.value.filter(column=>{return column.tableView});
            }
        },
        data(){
            return {
                auth: [],    //table的权限
                columnAuth: [],//tableColumn的权限,
                viewModel: false,
                currentPage:1,
                pagination:{
                    size:"small",
                    defaultPageSize: 15,
                    hideOnSinglePage:true,
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '15', '20'],
                    onChange: (page) => {
                        this.currentPage = page;
                        this.currentIndex = null

                    }
                },

                searchText:null,
                searchedColumn:null,

                currentIndex:null
            }
        },
        methods:{
            getPageSize(){
                return this.pagination.defaultPageSize;
            },
            setTotalSize(num){
               this.pagination.total = num;
            },
            setCurrentPage(num){
                this.pagination.current = num;
            },
            handleSearch(selectedKeys, confirm, dataIndex){
                confirm();
                this.searchText = selectedKeys[0];
                this.searchedColumn = dataIndex;
            },
            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            deleteData(index,record){
                this.$emit("event", this.$GetEmitData(this.$eventTags.delete,{index,record}));
            },
            showDetail(index,record){
                this.$emit("event", this.$GetEmitData(this.$eventTags.showDetail,record));
                this.currentIndex = index;
            },
            editDetail(index,record){
                this.$emit("event", this.$GetEmitData(this.$eventTags.editDetail,record));
                this.currentIndex = index;
            },
            rowClass(record,index){
                if(index === this.currentIndex){
                    return "highlight-row"
                }
                else{
                    return ""
                }
            },
        },
        mounted() {
            this.auth = this.authority;
            this.columnAuth = checkAuth(this.auth,"upd")?["upd"]:["view"];//需要修改
        }
    }
</script>

<style scoped>

</style>