/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_collection_info  收款信息 有发票
 * @create: 2021-04-16 16:39
 *
 #贵州图云慧空间信息技术有限公司  （2b5f21c6-750e-4f98-b0c1-c7068b700a27）
 #北京中色测绘院有限公司  （70f61478-c716-477c-b0e7-9f2e333e6538）
 #北京中色地科测绘有限公司贵州分公司  （0d89dd47-1f19-4e97-a565-eae226d6d743）
 #河北中色测绘有限公司  （1527858e-4494-4647-ab87-ebfcc2ec8935）
 #北京中色地科测绘有限公司  （aa60eee4-8212-4ccd-a21d-31fb7dff6f3f）
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import partyInfo from "../api/partyInfo";
import {companyIds, companyNames,companyColors} from "../system/companies";

export default class item_collection_info extends Table{
    constructor() {
        super();
        this.name = "item_collection_info";
        this.CNName = "收款信息";
        this.columnArray = [
            new Column("收款时间","collectionTime",ColumnType.Date,true).setTableView(80),
            new Column("收款金额(元)","collectionAmount",ColumnType.Number,true).setTableView(100),
            new Column("入账账户","bankId",ColumnType.Selector,true).setTableView(120)
                .setSelectorObject("bankNum",[]),
            new Column("所属发票","invoiceId",ColumnType.ObjectReplace,false).setTableView(120)
                .setObjectReplaceObject("invoiceRecord","invoiceTitle",{})
                .setTriggerOtherColumn(null,(formData,cols)=>{
                    cols.filter(col=>{return col.dataIndex==="payerId"})[0].setEditable(true);
                    cols.filter(col=>{return col.dataIndex==="receiverId"})[0].setEditable(true);
                },(formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="payerId"})[0].setEditable(false);
                        cols.filter(col=>{return col.dataIndex==="receiverId"})[0].setEditable(false);
                    }),
            new Column("付款方", "payerId", ColumnType.IdReplace, true).setGroup(1).setTableView(150)
                .setIdReplaceObject("payerName",{api: partyInfo.queryAll, tag: "queryText"}),
            new Column("收款方", "receiverId", ColumnType.Enum, true).setTableView(150)
                .setEnum(companyIds, companyNames,companyColors).setGroup(1),
            new Column("收款备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}