/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_finance_record  报账单表类型1：用于填写报账单
 * @create: 2021-04-12 09:58
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_finance_record_1 extends Table{
    constructor() {
        super();
        this.name = "item_finance_record_1";
        this.CNName = "报销单";
        this.columnArray = [
            // new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            // new Column("用户编号","userId",ColumnType.String,true).setVisible(false),
            new Column("账单标题","title",ColumnType.String,true).setTableView(150),
            new Column("账单描述","content",ColumnType.String,true),
            new Column("账单归属时间","recordYearMonth",ColumnType.Month,false).setTableView(130),
            // new Column("总金额（元）","amount",ColumnType.Number,false).setVisible(false).setDefaultValue(0),
            // new Column("有发票金额（元）","invoiceAmount",ColumnType.Number,false).setVisible(false).setDefaultValue(0),
            // new Column("无发票金额（元）","noInvoiceAmount",ColumnType.Number,false).setVisible(false).setDefaultValue(0),
            new Column("提交","submitState",ColumnType.Enum,false).setTableView(80)
                .setEnum(["0","1"],["未提交","已提交"],["blue","green"]).setDefaultValue("0"),
            new Column("审核","approvalState",ColumnType.Enum,false).setTableView(80)
                .setEnum(["0","1"],["未完成","已完成"],["pink","green"]).setDefaultValue("0"),
            new Column("入账状态","entryState",ColumnType.Enum,false)
                .setEnum(["0","1","4","2","3"],
                    ["未入账","对公入账","私人入账","拒绝入账","撤销入账"],
                    ["pink","green","purple","red","orange"])
                .setTriggerOtherColumn(["2","3"],
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="remarks"})[0]?.setVisible(true);
                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="remarks"})[0]?.setVisible(false);
                    }),
            new Column("入账时间","entryTime",ColumnType.Date,false).setTableView(100),
            new Column("情况说明","remarks",ColumnType.String,false).setVisible(false)
        ];
        this.actions = [
            new Column("操作","actions","actions",false).setTableView(100)
                .setCustomCell((record,index) => {
                    return {
                        onClick: () => {
                            //console.log(record,index)
                        },       // 点击行
                    };
                })
        ];
    }
}