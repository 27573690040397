<!--
* @program: office_automation 
* @author: ly
* @component:HygieneRecords 
* @description: 卫生记录
* @create: 2022-01-14 11:02
-->
<template>
    <div class="oa-content">
        <div class="oa-window" >
            <div v-if="visible" >
                <a-layout-content style="display: inline-flex;flex-wrap: wrap;">
                    <a-card class="mode">
                        <a-button class="cover" type="primary" @click="comp = 'HygieneRecordsFromUser'">通过人查询</a-button>
                    </a-card>
                    <a-card class="mode">
                        <a-button class="cover" type="primary" @click="comp = 'HygieneRecordsFromMonth'">通过时间查询</a-button>
                    </a-card>
                    <a-card class="mode">
                        <a-button class="cover" danger type="primary" @click="modalVisible = true"><template #icon><FolderOpenOutlined/></template>上传评分记录</a-button>
                    </a-card>
                    <a-card class="mode">
                        <a-button class="cover" type="primary" @click="downLoadFile">下载文件模板</a-button>
                    </a-card>
                </a-layout-content>
            </div>
            <div v-else style="height: 100%">
                <div v-is="comp" style="height: 100%"/>
            </div>
        </div>
    </div>
    <a-modal v-model:visible="modalVisible"
             destroyOnClose
             :footer="null"
             title="选择考勤文件和对应的月份">
        <div class="center-flex">
            <MonthSelector v-model:value="yearMonth" />
            <a-upload :accept="accept"
                      :action="uploadUrl"
                      :data="{yearMonth}"
                      :show-upload-list="false"
                      @change="handleChange"
                      v-model:file-list="fileList"
            >
                <a-tooltip title="上传记录考勤记录的excel文件">
                    <a-button type="primary" danger :loading="loading">
                        <template #icon><FolderOpenOutlined/></template>
                        上传考勤记录
                    </a-button>
                </a-tooltip>
            </a-upload>
        </div>
    </a-modal>
</template>

<script>

    import {FolderOpenOutlined} from "@ant-design/icons-vue";
    import MonthSelector from "../../../../components/SearchBar/MonthSelector";
    import moment from "moment";
    import hygiene from "../../../../assets/api/hygiene";
    import HygieneRecordsFromUser from "./HygieneRecordsFromUser";
    import HygieneRecordsFromMonth from "./HygieneRecordsFromMonth";
    export default {
        name: "HygieneRecords",
        components:{
            FolderOpenOutlined,MonthSelector,HygieneRecordsFromUser,HygieneRecordsFromMonth
        },
        computed:{
            visible(){
                return !this.comp;
            }
        },
        data(){
            return{
                comp:null,
                loading:false,
                accept:"application/vnd.ms-excel",
                uploadUrl:hygiene.importExcel.url,
                fileList:[],
                yearMonth:null,
                modalVisible:false
            }
        },
        methods:{
            handleChange({file,fileList}){
                if (file.status === 'done') {
                    if(file.response.flag){
                        this.$message.success(file.response.msg);
                    }
                    else{
                        this.$message.error(file.response.msg);
                    }
                }
            },
            downLoadFile(){
                hygiene.exportExcel.fileDownloadGet(this,{},"卫生文件模板.xls");
            }
        },
        created() {
            this.yearMonth = moment(new Date().valueOf()).format("YYYY-MM");
        }
    }
</script>

<style scoped>
    .mode{
        width: 15vw;
        height: 10vh;
        margin: 5vw;
        background: #a1a1a1;
    }
    .cover{
        width: 10vw;
        height: 10vh;
        font-size: 20px;
    }
</style>