/** 
* 自动生成文件，请勿修改 
* @name: 报销:报账单模块
* @description: 报销-报账单模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:16条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const record = { 
	/** 1 summary:报账单-分页查询	method:post */ 
	queryPage : new APIObject("/api/record/queryPage.do"), 
	/** 2 summary:报账单-查询需要我审批且已到达我这里的账单-分页	method:post */ 
	pageMyApproval : new APIObject("/api/record/pageMyApproval.do"), 
	/** 3 summary:报账单-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/record/saveOrUpd.do"), 
	/** 4 summary:报账单-撤销提交（在没有任何人审核情况下，可以撤销）	method:post */ 
	cancelSubmit : new APIObject("/api/record/cancelSubmit.do"), 
	/** 5 summary:报账单-批量删除	method:post */ 
	batchDelete : new APIObject("/api/record/batchDelete.do"), 
	/** 6 summary:报账单-批量将账单解绑（绑定了pid的账单，发现绑错时解绑）	method:post */ 
	unBindPid : new APIObject("/api/record/unBindPid.do"), 
	/** 7 summary:报账单-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/record/queryOne.do"), 
	/** 8 summary:报账单-分页查询（从现金账户扣钱的账单）	method:post */ 
	pageCashRecord : new APIObject("/api/record/pageCashRecord.do"), 
	/** 9 summary:报账单-单条删除	method:post */ 
	delete : new APIObject("/api/record/delete.do"), 
	/** 10 summary:报账单-批量将账单绑定到其他账单下	method:post */ 
	batchBindPid : new APIObject("/api/record/batchBindPid.do"), 
	/** 11 summary:报账单-所有数据查询	method:post */ 
	queryAll : new APIObject("/api/record/queryAll.do"), 
	/** 12 summary:报账单-所有数据查询(排除5个人的)	method:post */ 
	queryAllCommon : new APIObject("/api/record/queryAllCommon.do"), 
	/** 13 summary:报账单-报账（私人入账的账单，标记是否挂账人已经向公司报销了）	method:post */ 
	recordReimbur : new APIObject("/api/record/recordReimbur.do"), 
	/** 14 summary:报账单-分页查询(排除5个人的)	method:post */ 
	queryPageCommon : new APIObject("/api/record/queryPageCommon.do"), 
	/** 15 summary:报账单-入账	method:post */ 
	recordEntry : new APIObject("/api/record/recordEntry.do"), 
	/** 16 summary:报账单-查询需要我审批且已到达我这里的账单-不分页	method:post */ 
	queryMyApproval : new APIObject("/api/record/queryMyApproval.do"), 
};
export default record;