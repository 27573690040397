<!--
* @program: office_automation 
* @author: ly
* @component:MenuOpSet 
* @description: 
* @create: 2023-02-27 17:41
-->
<template>
    <a-tag style="cursor: pointer;font-size: 18px" @click="setOps(item)" v-for="item in MenuOps" :key="item" :color="getEnable(item)?'blue':''">{{item.name}}</a-tag>
</template>

<script>
    import {MenuOps} from "./MenuOps";
    import menuOperation from "../../../assets/api/menuOperation";
    export default {
        name: "MenuOpSet",
        props:["ops","menuId"],
        emits:["update:ops"],
        data() {
            return {
                MenuOps:MenuOps,
                lops:[],
            }
        },
        watch:{
            ops(){
                this.init()
            }
        },
        methods: {
            init() {
                if(this.ops){
                    this.lops = [...this.ops];
                }
                else{
                    this.lops = [];
                }
            },
            getEnable(item){
                let op = this.lops.find(i=>i.opcode === item.opcode);
                return !!op;
            },
            setOps(item){
                let op = this.lops.find(i=>i.opcode === item.opcode);
                if(op){
                    menuOperation.delete.requestPOSTUrlParam(this,{id:op.id},res=>{
                        if(res.data.flag){
                            let index = this.lops.indexOf(op);
                            this.lops.splice(index,1);
                            this.$message.success(res.data.msg);
                            this.refresh();
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                else{
                    let it = {...item};
                    it.menuId = this.menuId;
                    menuOperation.save.requestPOST(this,it,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.lops.push(item);
                            this.refresh();
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
            },
            refresh(){
                menuOperation.queryOperation.requestPOSTUrlParam(this,{menuId:this.menuId},res=>{
                    if(res.data.flag){
                        this.lops = res.data.data;
                        this.$emit("update:ops",this.lops);
                    }
                })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>