/** 
* 自动生成文件，请勿修改 
* @name: 职位:员工晋升考核打分
* @description: 员工晋升考核打分（三期新增）
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const promotionScore = { 
	/** 1 summary:查询-单条(根据主键)	method:post */ 
	queryOne : new APIObject("/api/promotionScore/queryOne.do"), 
	/** 2 summary:指定打分人：（针对晋升考核中同事评分的几项考核维度）	method:post */ 
	appoint : new APIObject("/api/promotionScore/appoint.do"), 
	/** 3 summary:查询-全部不分页	method:post */ 
	queryAll : new APIObject("/api/promotionScore/queryAll.do"), 
	/** 4 summary:修改：单条	method:post */ 
	update : new APIObject("/api/promotionScore/update.do"), 
	/** 5 summary:查询-（需要自己打分的）当前登录用户为评分人	method:post */ 
	queryByJudgeUser : new APIObject("/api/promotionScore/queryByJudgeUser.do"), 
	/** 6 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/promotionScore/batchDelete.do"), 
	/** 7 summary:修改：多条	method:post */ 
	batchUpd : new APIObject("/api/promotionScore/batchUpd.do"), 
	/** 8 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/promotionScore/delete.do"), 
};
export default promotionScore;