<!--
* @program: tyh-oa 
* @author: ly
* @component:menuTreeSelect 
* @description: 
* @create: 2021-05-21 14:30
-->
<template>
    <a-tree style="text-align: left;height:100%;overflow-y:auto"
            v-if="!loading"
            :tree-data="treeData"
            default-expand-all
            :selectable="false"
            :replace-fields="replaceFields"
            v-model:checkedKeys="checkedKeys"
            checkable
            checkStrictly
    >
        <template #title="{dataRef}">
            <div style="display: flex;height: 24px;width: 200px">
                <div style="padding: 0 5px;height: 24px">【{{dataRef.treeName}}】</div>
                <MenuOpBind :mops="dataRef.listOp" v-model:sops="dataRef.sops" />
            </div>
        </template>
    </a-tree>
</template>

<script>
    import menuViewer from "./menuViewer";
    import MenuOpBind from "./MenuOpBind";
    export default {
        name: "menuTreeSelect",
        components:{MenuOpBind},
        extends:menuViewer,
        data(){
            return{
                checkedKeys:[],
            }
        },
        methods:{
            getCheckedKeys(){
                return this.checkedKeys.checked;
            },
            getTreeData(){
                return this.treeData;
            },
            setCheckedKeys(keys){
                this.checkedKeys = keys;
            },
            bindTwoTrees(MenuByRoleData){
                this.checkedKeys = [];
                for(let item of MenuByRoleData){
                    this.checkedKeys.push(item.id);
                    let node = getNode(item.id,this.treeData);
                    if(node){
                        node.sops = item.opList;
                    }
                }
            }
        }
    }
    function getNode(key,treeData){ //递归函数
        for(let node of treeData){
            if(node.treeId === key){
                return  node;
            }
            else {
                if(node.children && node.children.length>0){
                    let res = getNode(key,node.children);
                    if(res){
                        return res
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>