<!--
* @program: office_automation 
* @author: ly
* @component:MonthProcessArrange 
* @description: 月度绩效分配
new Column("作业绩效","depManagExp",ColumnType.Number,false),
new Column("负责人绩效","leaderPercent",ColumnType.Number,false),
* @create: 2022-04-12 09:56
{
  "completeMoney": 0,
  "completeRatio": 0,
  "id": "string",
  "remarks": "string",
  "taskId": "string",
  "userId": "string",
  "yearMonth": "string"
}
-->
<template>
    <a-modal v-model:visible="visible"
             width="900px"
             :footer="null"
             title="月度绩效分配">
        <a-spin :spinning="spinning">
            <span>已分配：</span>
            作业绩效【<span v-for="item in members" :key="item.id">{{item.name}}:{{item.workMoney}}&nbsp;</span>】
            负责人绩效【<span v-for="item in members" :key="item.id">{{item.name}}:{{item.leadMoney}}&nbsp;</span>】
            <br />
            <span>可以分配的作业绩效：{{task.depManagExp - arranged.work - workSum}}</span>
            <span>&nbsp; 可以分配的负责人绩效：{{task.leaderPercent - arranged.lead - leadSum}}</span>

            <br />
            <div>
                <a-button style="margin: 0 5px" type="primary" @click="computeProcess">
                    查询项目月进度
                </a-button>
<!--                <span>项目月进度：</span>-->
                <span v-for="item in taskMonthPercent" :key="item">
                    <span v-if="item.yearMonth === yearMonth">{{item.yearMonth}}:【{{item.percent.toFixed(2)}}% | {{((task.depManagExp+task.leaderPercent)*(item.value/item.weight)).toFixed(2)}}】</span>
                </span>
                <a-button v-if="taskMonthPercent.length>0" style="margin: 0 5px" type="primary" @click="autoFill" >
                    自动填入绩效
                </a-button>
            </div>
            <div>
                <span>月份：</span><month-picker style="width: 200px" v-model:value="yearMonth" />
                <span style="margin: 0 5px">分配绩效占比（总量）：</span>
                <a-input-number
                    :formatter="value => `${value}%`"
                    :parser="value => value.replace('%', '')"
                    style="width: 100px" :max="100" :min="0"  @change="setArrangePercent" v-model:value="arrangePercent" />
                {{arrangeNum}}
            </div>
            <div style="display: flex;flex-wrap: wrap;">
                <a-card v-for="item in moneyArrange" :key="item.id" style="width: 250px">
                    <template #title>
                        {{item.name}}
                    </template>
                    <a-form layout="horizontal">
                        <a-form-item label="占比(当月)">
                            <a-input-number
                                    :formatter="value => `${value}%`"
                                    :parser="value => value.replace('%', '')"
                                    style="width: 100px" :max="100"  @change="setPercent(item)" v-model:value="item.percent" />
                        </a-form-item>
<!--                        <a-form-item label="占比(总量)">-->
<!--                            <a-input-number style="width: 100px" :max="100"  @change="setCompleteMoney(item)" v-model:value="item.completeRatio" />-->
<!--                            {{item.completeRatio}}-->
<!--                        </a-form-item>-->
                        <a-form-item label="作业绩效">
                            <a-input-number :max="arrangeNum" style="width: 100px" @change="setCompleteMoney(item)" v-model:value="item.completeMoney" />
                        </a-form-item>
                        <a-form-item label="负责人绩效">
                            <a-input-number :max="task.leaderPercent" style="width: 100px" @change="setLeaderMoney(item)"  v-model:value="item.leaderMoney" />
                        </a-form-item>
                        <a-form-item label="说明">
                            <a-textarea style="width: 100px" v-model:value="item.remarks" />
                        </a-form-item>
                    </a-form>
                </a-card>
            </div>
            <div>
                <span>分配的作业绩效：{{workSum}}</span>
                <span>&nbsp; 分配的负责人绩效：{{leadSum}}</span>
                <span>&nbsp; 合计：{{workSum+leadSum}}</span>
            </div>
            <a-button v-if="!overArranged" style="padding: 0 5px" type="primary" @click="saveData">
                保存
            </a-button>
            <div v-else>
                超过当前允许分配的绩效合
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
    import MonthPicker from "../../_components/MonthPicker";
    import taskMonthUser from "../../../assets/api/taskMonthUser";
    import workSituation from "../../../assets/api/workSituation";
    import moment from "moment"
    export default {
        name: "MonthProcessArrange3",
        props:["members","task","arranged"],//arranged 已分配的绩效合计work作业绩效  lead负责人绩效
        components:{MonthPicker},
        emits:["refresh"],
        data() {
            return {
                visible:false,
                yearMonth:null,
                moneyArrange:[],
                spinning:false,
                taskMonthPercent:[],
                arrangePercent:0
            }
        },
        computed:{
            workSum(){
                let sum = 0;
                for(let i in this.moneyArrange){
                    sum += this.moneyArrange[i].completeMoney
                }
                return sum;
            },
            leadSum(){
                let sum = 0;
                for(let i in this.moneyArrange){
                    sum += this.moneyArrange[i].leaderMoney
                }
                return sum;
            },
            arrangeNum(){//当月分配绩效合计
                return this.arrangePercent*(this.task.depManagExp+this.task.leaderPercent)/100
            },
            overArranged(){//是否超过当月分配绩效
                let bool1 = false;
                let bool2 = false;
                let bool3 = false;
                let sum = 0;
                for(let i in this.moneyArrange){
                    sum += this.moneyArrange[i].percent
                }
                bool1 =  sum >100;
                bool2 = (this.task.depManagExp - this.arranged.work - this.workSum) < 0;
                bool3 = (this.task.leaderPercent - this.arranged.lead - this.leadSum) < 0;
                return bool1 || bool2 || bool3;
            }
        },
        methods: {
            init() {
                this.moneyArrange = [];
                for(let i in this.members){
                    let {id,name} = this.members[i];
                    this.moneyArrange.push(new MoneyArrange(id,name,this.task.id))
                }
            },
            open(){
                this.init();
                this.visible = true;
                this.taskMonthPercent = [];
                this.arrangePercent = 0;
                let time = parseInt(localStorage.getItem("time"));
                this.yearMonth = new moment(time).format("YYYY-MM");
            },
            saveData(){
                if(this.task.depManagExp - this.arranged.work - this.workSum <0){
                    this.$message.error("超过可以分配的作业绩效额");
                    return
                }
                if(this.task.leaderPercent - this.arranged.lead - this.leadSum <0){
                    this.$message.error("超过可以分配的负责人绩效额");
                    return
                }
                for(let i in this.moneyArrange){
                    this.moneyArrange[i].yearMonth = this.yearMonth;
                    taskMonthUser.saveOrUpd.requestPOST(this,this.moneyArrange[i],res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                this.spinning = true;
                setTimeout(()=>{
                    this.spinning = false;
                    this.visible = false;
                    this.$emit("refresh");
                },1500)
            },
            setCompleteMoney(item){

            },
            setPercent(item){

            },
            setLeaderMoney(item){

            },
            setArrangePercent(){
                for(let i in this.moneyArrange){
                    this.moneyArrange[i].setArrangeNum(this.arrangeNum)
                }
            },
            async computeProcess(){//获取所有人工序的完成进度
                this.spinning = true;
                for(let i in this.members){
                    let currentMember = this.members[i];
                    for(let j in currentMember.processList){
                        let data = {
                            "processId": currentMember.processList[j].processId,
                            "userId": currentMember.id,
                        };
                        let res = await workSituation.queryAll.requestPOSTAsync(data);
                        if(res.flag){
                            currentMember.processList[j].workDiary = res.data;
                            currentMember.processList[j].monthRatio = [];
                            currentMember.processList[j].finishRatio = 0;
                            for(let k in res.data){
                                let yearMonth = new moment(res.data[k].workDate).format("YYYY-MM");//年月
                                let workRatio = res.data[k].workRatio;//完成比例
                                currentMember.processList[j].finishRatio += workRatio;
                                let arr = currentMember.processList[j].monthRatio.filter(item=>item.yearMonth === yearMonth);
                                if(arr.length>0){
                                    arr[0].workRatio += workRatio;
                                }
                                else{
                                    currentMember.processList[j].monthRatio.push({yearMonth,workRatio})
                                }

                            }
                        }
                        else{
                            currentMember.processList[j].workDiary = [];
                        }
                    }
                }
                this.spinning = false;

                this.taskMonthPercent = [];
                for(let i in this.members){
                    let taskMonthPercent = this.members[i].taskMonthPercent;
                    for(let j in taskMonthPercent){
                        let arr = this.taskMonthPercent.filter(item =>item.yearMonth === taskMonthPercent[j].yearMonth)
                        if(arr.length>0){
                            arr[0].value += taskMonthPercent[j].value;
                            arr[0].percent += parseFloat((taskMonthPercent[j].value/this.members[i].taskWeight*100).toFixed(2));
                        }else{
                            this.taskMonthPercent.push({
                                yearMonth:taskMonthPercent[j].yearMonth,
                                value:taskMonthPercent[j].value,
                                weight:this.members[i].taskWeight,
                                percent:parseFloat((taskMonthPercent[j].value/this.members[i].taskWeight*100).toFixed(2))
                            })
                        }
                    }
                }
            },
            autoFill(){
                if(!this.yearMonth){
                    this.$message.error("请选择月份");
                    return
                }
                let leaderMonthPercent = this.taskMonthPercent.filter(item=>item.yearMonth === this.yearMonth);
                this.arrangePercent = 0;
                if(leaderMonthPercent.length>0){
                    this.arrangePercent = leaderMonthPercent[0].percent;

                }
                for(let i in this.members){
                    if(this.members[i].leader){//项目负责人
                        let arr = this.moneyArrange.filter(item=>item.userId === this.members[i].id);
                        if(arr.length>0){
                            let MonthPercent = this.members[i].taskMonthPercent.filter(item=>item.yearMonth === this.yearMonth);
                            if(MonthPercent.length>0 ){
                                arr[0].completeMoney = MonthPercent[0].percent * this.task.depManagExp/100;
                                arr[0].completeRatio = MonthPercent[0].percent;
                                arr[0].leaderMoney = this.arrangePercent * this.task.leaderPercent/100;
                            }
                        }
                    }
                    else{
                        let arr = this.moneyArrange.filter(item=>item.userId === this.members[i].id);
                        if(arr.length>0){
                            let MonthPercent = this.members[i].taskMonthPercent.filter(item=>item.yearMonth === this.yearMonth);
                            if(MonthPercent.length>0){
                                arr[0].completeMoney = MonthPercent[0].percent * this.task.depManagExp/100;
                                arr[0].completeRatio = MonthPercent[0].percent;
                            }
                        }
                    }
                }
                this.setArrangePercent();
            }
        }
    }
    class MoneyArrange{
        constructor(id,name,taskId) {
            this.userId = id;
            this.name = name;
            this.taskId = taskId;
            this.completeMoney = 0;
            this.completeRatio = 0;
            this.leaderMoney = 0;
            this.remarks = "";
            this.arrangeNum = 0;
        }
        get percent(){
            if(this.arrangeNum){
                return parseFloat(((this.completeMoney + this.leaderMoney)/this.arrangeNum *100).toFixed(2))
            }
            else{
                return 0
            }
        }
        set percent(percent){
            let money = this.arrangeNum * percent /100;
            let surplus = money - this.completeMoney - this.leaderMoney;
            if(surplus >0){
                this.completeMoney += surplus
            }
            else{
                this.completeMoney +=surplus;
                if(this.completeMoney<0){
                    this.leaderMoney += this.completeMoney;
                    this.completeMoney = 0;
                    if(this.leaderMoney < 0){
                        this.leaderMoney = 0
                    }
                }
            }
        }
        setArrangeNum(arrangeNum){
            this.arrangeNum = arrangeNum;
        }
    }
</script>

<style scoped>

</style>