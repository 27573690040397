<!--
* @program: TYH_office_automation 
* @author: ly
* @component:typeCategoryAssets 
* @description: 
* @create: 2021-06-21 14:41
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center" >{{categoryFullName}}</div>
    </div>
    <div v-else>
        <a-spin :spinning="spinning"  v-if="editable">
            <a-tree-select
                    show-search
                    v-model:value="record[column.dataIndex]"
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    placeholder="输入查询/选择子分类"
                    :replaceFields="replaceFields"
                    :treeData="treeData"
                    :treeNodeFilterProp="'categoryName'"
                    @change="columnTrigger"
                    v-model:treeExpandedKeys="expandKey"

            />
        </a-spin>
        <div style="text-align: center" v-else >{{categoryFullName}}</div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import api_device_category from "../../assets/api/deviceCategory";
    export default {
        name: "typeCategoryAssets",
        mixins:[typeBase],
        computed:{
            categoryFullName(){
                if(this.record["category"]){
                    let pName = this.record["category"].parent.categoryName;
                    return pName + " - " + this.record["category"].categoryName;
                }
                else if(this.record.categoryName){
                    return this.record.categoryName;
                }
                else{
                    return "";
                }
            }
        },
        data(){
            return{
                replaceFields :{
                    title: 'categoryName',
                    key:"id",
                    value:"id",
                },
                treeData:[],
                spinning:false,
                expandKey:[]
            }
        },
        methods:{
            categoryInit(){
                this.spinning = true;
                api_device_category.queryOneLevel.requestPOSTUrlParam(this,{},res=>{
                    this.spinning =false;
                    if(res.data.flag){
                        this.treeData = res.data.data;
                        for(let i in this.treeData){//禁止大类被选中
                            this.treeData[i]["selectable"]=false;
                        }
                        this.expandKey = [this.treeData[0].id];
                    }
                })
            }
        },
        created() {
            if(!this.viewModel){
                this.categoryInit()
            }
        }
    }

</script>

<style scoped>

</style>