/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_qc_group 质检组管理
 * @create: 2021-04-25 10:59
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import userInfo from "../api/userInfo";
export default class item_qc_group extends Table{
    constructor() {
        super();
        this.name = "item_qc_group";
        this.CNName = "质检组管理";
        this.columnArray = [
            new Column("质检组名称","name",ColumnType.String,true).setTableView(100),
            new Column("质检组组长","groupLeader",ColumnType.IdReplace,true).setTableView(100)
                .setIdReplaceObject("leaderName",{api:userInfo.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(60)];
    }
}