<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityTaskSet
* @description: 查看/分解质检工序
* @create: 2021-07-05 14:32
-->
<template>
    <div>
        <a-button v-if="!viewModel" :disabled="disabled" type="primary" @click="modalShow">
            <template #icon><PlusOutlined /></template>
        </a-button>
        <ArBaseTable  :argument="argument" :view-model="true" :page-size="10">
            <template #action="{index,record}">
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </template>
        </ArBaseTable>
        <a-modal v-model:visible="modalVisible"
                 destroy-on-close
                 title="添加质检任务"
                 :footer="null">
            <ArBaseForm :argument="argument" :view-model="viewModel" >
                <template #action="{record}">
                    <a-button  type="primary" @click="addProcess(record)">
                        添加
                    </a-button>
                </template>
            </ArBaseForm>
        </a-modal>
    </div>
</template>

<script>
    import {DeleteOutlined, PlusOutlined} from "@ant-design/icons-vue";
    import item_qc_process from "../../../assets/tables/item_qc_process";
    import api_item_qc_process from "../../../assets/api/qcProcess";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import ArBaseForm from "../../../components/form/ArBaseForm";

    export default {
        name: "qualityTaskSet",
        components:{
            DeleteOutlined,PlusOutlined,
            ArBaseTable,ArBaseForm
        },
        props:{
            record:{required:true},//当前质检申请的record
            viewModel:{required:true},
        },
        computed:{
            status(){
                if(this.argument.dataSource.length>0){
                    return "finish";
                }
                else{
                    return "error";
                }
            }
        },
        data(){
            return{
                disabled:false,
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()

                    loading:false,//table的loading状态

                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                table:new item_qc_process(),
                modalVisible:false,
            }
        },
        methods:{
            init(){
                if(this.viewModel){
                    this.table.actions = [];
                }
                this.argument.tableColumns = this.table.getTableColumns();
                this.argument.formColumns = this.table.getFormColumns();
                let data={applyId:this.record.id};
                api_item_qc_process.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource=[];
                    }
                });
            },
            getStatus(){
                return this.status;
            },
            modalShow(){
                this.modalVisible = true;
                this.argument.formData = this.table.getFormData();
            },
            addProcess(record){
                record.applyId = this.record.id;
                api_item_qc_process.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.modalVisible =false;
                        let data = {applyId:this.record.id};
                        api_item_qc_process.queryAll.requestPOSTUrlParam(this,data,res=>{
                            if(res.data.flag){
                                this.argument.dataSource = res.data.data;
                            }
                            else{
                                this.argument.dataSource=[];
                            }
                        })
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(index,record){
                let data = {id:record.id};
                api_item_qc_process.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>