<!--
* @program: office_automation 
* @author: ly
* @component:DatePicker 
* @description: 配合数据库使用的时间类型使用的日期选择控件
* @create: 2021-12-22 15:39
-->
<template>
    <a-date-picker style="width: 100%" v-model:value="date" :format="dateFormat" />
</template>

<script>
    import moment from "moment";

    export default {
        name: "DatePicker",
        props:["value"],
        emits:["update:value"],
        watch:{
            date(){
                this.$emit("update:value",moment(this.date).locale('zh-cn').format(this.dateFormat))
            },
            value(){
                this.date = this.value?moment(this.value):null;
            }
        },
        data(){
            return{
                dateFormat: 'YYYY-MM-DD',
                date:null,
            }
        },
        mounted() {
            this.date = this.value?moment(this.value):null;
        }
    }
</script>

<style scoped>

</style>