<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityUserScore
* @description: 给项目组成员打分
* @create: 2021-07-12 14:05
-->
<template>
    <div class="flow-card" v-if="userList.length>0">
        <a-card size="small" v-for="(user,idx) in userList" :title="user.name" :key="idx" style="width: 45%;margin: 10px">
            <a-button type="primary"  @click="getState(user)">
                评分
            </a-button>
            <ArForm v-if="user.state" :argument="user.state" :view-model="viewModel" >
                <template  #action="{record}">
                    <a-button  type="primary" @click="saveState(record)">
                        提交
                    </a-button>
                </template>
            </ArForm>
        </a-card>
    </div>
    <div v-else>
        还未安排项目人员
    </div>
</template>

<script>
    import item_prod_quality from "../../../assets/tables/item_prod_quality";
    import api_item_prod_quality from "../../../assets/api/prodQuality";
    import {EditOutlined} from "@ant-design/icons-vue"
    import ArForm from "../../../components/form/ArForm";
    export default {
        name: "qualityUserScore",
        props:{
            projectId:{required:true},
        },
        components:{ArForm,EditOutlined},
        data(){
            return{
                userList:[],
                table:new item_prod_quality(),
                viewModel:false
            }
        },
        methods:{
            init(){
                let data = {itemId:this.projectId};
                api_item_prod_quality.queryUserByItem.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.userList = res.data.data;
                    }
                })
            },
            getState(user){
                let data = {userId:user.id,itemId:this.projectId};
                api_item_prod_quality.queryAll.requestPOSTUrlParam(this,data,res=>{
                    user.state={};
                    user.state.formColumns=this.table.getFormColumns();
                    if(res.data.flag){
                        user.state.formData = res.data.data[0];
                    }
                    else{
                        user.state.formData = this.table.getFormData();
                        user.state.formData.userType = user.userType;
                        user.state.formData.userId = user.id;
                        user.state.formData.itemId = this.projectId;
                    }
                })
            },
            saveState(record){
                api_item_prod_quality.saveOrUpd.requestPOST(this,record,null);
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>