<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityUserScoreView
* @description: 项目组成员打分一览
* @create: 2021-07-12 14:05
-->
<template>
    <div class="flow-card" v-if="userList.length>0">
        <a-card size="small" v-for="(user,idx) in userList" :title="user.name" :key="idx" style="width: 33%;margin: 10px">
            <a-button v-if="!user.state" type="primary" @click="getState(user)">点击查看</a-button>
            <div v-else>
                <div v-if="user.state.formData">
                    <ArBaseForm :argument="user.state" :view-model="true" />
                </div>
                <div v-else>
                    还未对该人员进行项目评分
                </div>
            </div>
        </a-card>
    </div>
    <div v-else>
        还未安排项目人员
    </div>
</template>

<script>
    import item_prod_quality from "../../../assets/tables/item_prod_quality";
    import api_item_prod_quality from "../../../assets/api/prodQuality";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    export default {
        name: "qualityUserScoreView",
        props:{
            projectId:{required:true},
        },
        components:{ArBaseForm},
        data(){
            return{
                userList:[],
                table:new item_prod_quality(),
            }
        },
        methods:{
            init(){
                let data = {itemId:this.projectId};
                api_item_prod_quality.queryUserByItem.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.userList = res.data.data;
                    }
                })
            },
            getState(user){
                let data = {userId:user.id,itemId:this.projectId};
                api_item_prod_quality.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        user.state={};
                        user.state.formData = res.data.data[0];
                        user.state.formColumns=this.table.getFormColumns();
                    }
                    else{
                        user.state={}
                    }
                })
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>