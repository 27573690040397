<!--
* @program: TYH_office_automation
* @author: ly
* @component:projectTaskViewer
* @description: 项目周报
* @create: 2021-06-30 15:06
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <TcBaseTable :title="true" :table-columns="tableColumns" :data-source="dataSource"
                         ref="weeks_table" :scroll="{x:300,y:620}"
                         :view-model="true" :page-size="pageSize">
                <template #title>
                    <div style="text-align: left">
                        <a-row :gutter="10">
                            <a-col :span="4">
                                <a-input v-model:value="itemName"  placeholder="项目名称"  @change="init"/>
                            </a-col>
                            <a-col :span="3">
                                <a-week-picker v-model:value="queryDate" placeholder="请选择周查询" @change="init"/>
                            </a-col>
                            <a-col :span="3">
                                <a-select allow-clear
                                          v-model:value="departId"
                                          label-in-value
                                          placeholder="项目所属部门"
                                          style="width: 180px;"
                                          :options="departIdOptions"
                                          @change="init"
                                >
                                </a-select>
                            </a-col>
                            <a-col :span="3">
                                <a-select allow-clear
                                          v-model:value="reviewState"
                                          label-in-value
                                          placeholder="总经办审阅状态"
                                          style="width: 180px;"
                                          :options="reviewStateOptions"
                                          @change="init"
                                >
                                </a-select>
                            </a-col>

                            <a-col :span="3">
                                <a-select allow-clear
                                          v-model:value="jgFlag"
                                          label-in-value
                                          placeholder="是否监管项目"
                                          style="width: 180px;"
                                          :options="jgFlagOptions"
                                          @change="init"
                                >
                                </a-select>
                            </a-col>
                        </a-row>
                    </div>
                </template>
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button class="action-button" type="link"  @click="showData(record,index)">
                            <SnippetsOutlined class="pointer" title="查看"/>
                        </a-button>
                        <a-button v-if="record.deptState == '1' && record.reviewState == '0'" class="action-button" type="link"  @click="auditData(record)">
                            <FormOutlined class="pointer" title="审核"/>
                        </a-button>
                        <a-button  class="action-button" type="link" @click="showDetail(index,record)">
                            <SearchOutlined class="pointer" title="项目情况"/>
                        </a-button>
                    </div>
                </template>
            </TcBaseTable>
        </div>
    </div>

    <a-modal v-model:visible="visible"
             width="900px"
             :footer="null"
             title="周报">
        <a-form
                name="custom-validation"
                ref="formRef"
                :model="formData"
                style="text-align: left"
        >
            <a-row>
                <a-col :span="12">
                    <a-form-item  label="周报时间" name="selectedDate">
                        <label>{{formData.weekDate}}</label>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item  label="总体完成进度" name="completeRatio">
                        <label>{{formData.completeRatio}}</label>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                    <a-form-item  label="项目名称" name="thisWeekWork">
                        <label>{{formData.itemName}}</label>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item  label="任务名称" name="thisWeekWork">
                        <label>{{formData.taskTitle}}</label>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                    <a-form-item  label="所属部门" name="thisWeekWork">
                        <label>{{formData.departmentName}}</label>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item  label="周报填写人" name="thisWeekWork">
                        <label>{{formData.createUserName}}</label>
                    </a-form-item>
                </a-col>
            </a-row>



            <a-form-item  label="本周工作情况" name="thisWeekWork">
                <label>{{formData.thisWeekWork}}</label>
            </a-form-item>
            <a-form-item  label="下周工作计划" name="nextWeekWork">
                <label>{{formData.nextWeekWork}}</label>
            </a-form-item>
            <a-form-item  label="目前存在问题" name="existProblem">
                <label>{{formData.existProblem}}</label>
            </a-form-item>
            <a-form-item  label="目前应对措施" name="solveMethod">
                <label>{{formData.solveMethod}}</label>
            </a-form-item>
            <a-form-item  label="备注" name="remarks">
                <label>{{formData.remarks}}</label>
            </a-form-item>
            <a-form-item  label="部门领导审核备注" name="solveMethod">
                <label>{{formData.deptApproveRemark}}</label>
            </a-form-item>

            <a-form-item label="总经办审阅意见" name="solveMethod">
                <label>{{formData.reviewSugg}}</label>
            </a-form-item>
        </a-form>
    </a-modal>

    <a-modal v-model:visible="visibleAudit"
             width="900px"
             :footer="null"
             title="审核">
        <a-form
                name="custom-validation"
                ref="formRef"
                :model="formData"
        >
            <a-form-item  label="审阅备注" name="reviewSugg">
                <a-textarea v-model:value="reviewSugg"   :rows="4" />
            </a-form-item>

            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary"  @click="reviewSubmit">保存</a-button>
            </a-form-item>
        </a-form>
    </a-modal>

    <a-modal v-model:visible="visibleItem"
             width="1000px"
             :footer="null"
             title="项目情况">
        <div style="height: 700px;overflow: auto">
            <project-tabs  ref="local_tabs"/>
        </div>
    </a-modal>
</template>

<script>
    import TwoParts from "../../_components/TwoParts";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {
        SearchOutlined,SnippetsOutlined,
        SyncOutlined,CalendarOutlined,FormOutlined
    } from "@ant-design/icons-vue"
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import moment from "moment";
    import weekWork from "../../../assets/api/weekWork";
    import department from "../../../assets/api/department";
    import projectTabs from "../projectViewer/projectTabs";
    import itemTask from "../../../assets/api/itemTask";

    export default {
        name: "projectWeekReport",
        components: {
             TwoParts,SnippetsOutlined,
             SearchOutlined, SyncOutlined,
            TcBaseTable,CalendarOutlined,FormOutlined,projectTabs
        },
        data() {
            return {
                dataSource:[],
                loading:false,
                pageSize:8,
                tableColumns:null,

                queryDate:null,
                itemName: null,
                showFlag:false,
                taskId:null,

                reviewState:null,
                reviewStateOptions:[
                    {value: '0',label: '未审阅'},
                    {value: '1',label: '已审阅'},
                ],
                departId:null,
                departIdOptions:[],

                jgFlag:null,
                jgFlagOptions:[
                    {value: true,label: '是'},
                    {value: false,label: '否'},
                ],

                visibleAudit:false,
                reviewSugg:null,
                weekId:null,

                visible:false,
                formData: {
                    id: null,
                    taskId: null,
                    weekDate: null,
                    thisWeekWork: null,
                    nextWeekWork: null,
                    completeRatio: null,
                    existProblem: null,
                    solveMethod: null,

                    reviewSugg: null,
                },

                visibleItem:false,
            }
        },
        created() {
            this.queryDepart();
            this.init();
            this.tableColumns = [
                new Column("序号","_index",ColumnType.Index).setTableView(50),
                new Column("周报时间","weekDate",ColumnType.String,true).setTableView(80),
                new Column("完成进度","completeRatio",ColumnType.Number,true).setTableView(50),
                new Column("项目名称","itemName",ColumnType.String,true).setTableView(110),
                new Column("所属部门","departmentName",ColumnType.String,true).setTableView(100),
                /*  new Column("任务名称","taskTitle",ColumnType.String,true).setTableView(120),*/
                new Column("总经办审阅","reviewState",ColumnType.String).setTableView(60)
                    .setEnum(["0","1"],["未审阅","已审阅"],["pink","green"]),
                new Column("本周工作情况","thisWeekWork",ColumnType.TextArea,false).setTableView(110).setRows(4),
                new Column("下周工作计划","nextWeekWork",ColumnType.TextArea,true).setTableView(110).setRows(4),
                new Column("目前存在的问题","existProblem",ColumnType.TextArea,false).setTableView(100).setRows(4),
                new Column("目前应对措施","solveMethod",ColumnType.TextArea,false).setTableView(100).setRows(4),
                new Column("操作","actions","actions",false).setTableView(90),
            ]
        },
        methods: {
            queryDepart(){
                department.queryAll.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        let list = res.data.data;
                        list.forEach((item)=>{
                            let parms = {
                                value:item.id,
                                label:item.name
                            }
                            this.departIdOptions.push(parms);
                        })
                    }
                    else{
                        this.workList = []
                    }
                })
            },
            init() {
                this.dataSource = [];
                this.loading = true;
                let data = {
                    page:1,
                    limit:this.pageSize,
                };
                let params = {
                    departId:this.departId == null ? null : this.departId.value,
                    deptState:'1',
                    itemName:this.itemName,
                    reviewState:this.reviewState != null ? this.reviewState.value : null,
                     jgFlag:this.jgFlag != null ? this.jgFlag.value : null,
                }
                if(this.queryDate){
                    let year = moment(this.queryDate).format('YYYY');
                    let week = moment(this.queryDate).week()// .format('WW');
                    params.weekDate = year + '年第' + week + "周";
                }
                weekWork.queryAll.requestPOSTBothParam(this,{formParam:params,urlParam:data},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.weeks_table.setTotalSize(res.data.count);
                        this.$refs.weeks_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            showData(record){
                this.visible = true;
                this.formData = record;

            },
            auditData(record){
                this.reviewSugg = null;
                this.visibleAudit = true;
                this.weekId = record.id;
            },
            reviewSubmit(){
                if(this.reviewSugg == null || this.reviewSugg == ''){
                    this.$message.info("请输入审阅备注");
                    return;
                }
                let data = {
                    id:this.weekId,
                    reviewSugg:this.reviewSugg
                }
                weekWork.review.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.visibleAudit = false;
                        this.init();
                    }
                });
            },

            showDetail(index,record){
                this.visibleItem = true;
                let data = {
                    id:record.taskId
                }
                itemTask.queryOne.requestPOSTBothParam(this,{urlParam:data,formParam:null},res=>{
                    if(res.data.flag){
                        this.$nextTick(()=>{
                            let info = res.data.data;
                            this.$refs.local_tabs.handleOpen([
                                {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:info.itemId},
                                {tag:"部门任务情况",component:"taskProcessList",viewModel:true,projectId:info.id,record:info},
                            ]);
                        })
                    }
                    else{
                        this.allTasks = [];
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })

            },
        },

    }
</script>

<style scoped>

</style>
