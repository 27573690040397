<!--
* @program: tyh-oa 
* @author: ly
* @component:firstPartyManage 
* @description: 甲方管理
* @create: 2021-06-08 15:49
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
                <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <PlusOutlined/>
                    </template>
                </a-button>
            </div>
            <TwoParts @change="handleChange" ref="TwoParts" pos="transform">
                <template #left>
                    <ArBaseTableFilter :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(index,record)" type="link">
                                    <template #icon><SearchOutlined /></template>
                                </a-button>
                                <a-button @click="editDetail(index,record)" type="link">
                                    <template #icon><EditFilled /></template>
                                </a-button>
                                <a-popconfirm
                                        @confirm="deleteData(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="删除？">
                                    <a-button style="color: red" type="link">
                                        <template #icon><DeleteOutlined /></template>
                                    </a-button>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTableFilter>
                </template>
                <template #right>
                    <div v-if="visible">
                        <a-page-header
                                style="border: 1px solid rgb(235, 237, 240)"
                                title="详情"
                        />
                        <ArForm :view-model="viewModel" :argument="formArg" >
                            <template #action="{record}">
                                <a-button type="primary" @click="saveData(record)">
                                    提交
                                </a-button>
                            </template>
                        </ArForm>
                    </div>
                    <div v-else>
                        <welcome />
                    </div>
                </template>
            </TwoParts>
        </div>
    </div>

</template>

<script>
    import item_party_info from "../../../assets/tables/item_party_info";
    import api_item_party_info from "../../../assets/api/partyInfo";
    import TwoParts from "../../_components/TwoParts";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import ArForm from "../../../components/form/ArForm";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import ICONS from "../../_components/ICONS";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import welcome from "../../welcome2";
    export default {
        name: "firstPartyManage",
        mixins: [ICONS],
        components: {
            TwoParts, ArBaseTableFilter, ArForm, SearchBar,welcome
        },
        data() {
            return {
                table: new item_party_info(),
                searchOptions: [{value: "queryText", label: "关键字"}],
                searchData: null,
                viewModel: true,
                argument: {
                    dataSource: null,//提供table的DataSource
                    tableColumns: null, //提供table的字-段集 this.table.getTableColumns()
                    loading: false,//table的loading状态
                    currentIndex: null //当前选中的条目
                },
                formArg: {
                    formColumns: null, //提供Form的字段集 this.table.getFormColumns()
                    formData: null,//提供Form的formData
                },
                visible:false,
                pageSize:15
            }
        },
        methods: {
            init() {
                this.argument.loading = true;
                this.visible = false;
                api_item_party_info.queryAll.requestPOSTUrlParam(this, {}, res => {
                    this.argument.loading = false;
                    if (res.data.flag) {
                        this.argument.dataSource = res.data.data;
                    }
                })
            },
            refresh(){
                this.init();
                this.$refs.TwoParts.reset();
            },
            doSearch(){
                api_item_party_info.queryAll.requestPOSTUrlParam(this, this.searchData, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.argument.dataSource = res.data.data;
                        this.$refs.arTable.setCurrentPage(1);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addData(){
                this.formArg.formData = this.table.getFormData();
                this.viewModel = false;
                this.visible = true;
            },
            showDetail(index,record){
                this.viewModel = true;

                this.formArg.formData = {...record} ;
                this.visible = true;
            },
            editDetail(index,record){
                this.viewModel = false;

                this.formArg.formData = {...record} ;
                this.visible = true;
            },
            deleteData(index,record){
                let data = {id: record.id};
                api_item_party_info.delete.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index, 1);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                api_item_party_info.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handleChange(pos){
                if (pos === "transform") {
                    this.argument.tableColumns = [
                        new Column("单位名称","name",ColumnType.String,true).setTableView(200),
                        new Column("操作","actions","actions").setTableView(80),
                    ]
                } else {
                    this.argument.tableColumns = this.table.getTableColumns();
                }
            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.formArg.formColumns = this.table.getFormColumns();
            this.formArg.formData = this.table.getFormData();
            this.init()
        }
    }
</script>

<style scoped>

</style>