<!--
* @description：数据成果管理查看
* @author:rzl
* @date:2022/6/7 15:11
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="55%"
           :destroyOnClose="true"
  >

   <a-form ref="bill_form"
           style="overflow: auto;height:400px"
           :label-col="labelCol"
           :wrapper-col="wrapperCol"
   >
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name"
                      label="项目名称" >
           <a-span>{{itemName}}</a-span>

         </a-form-item>
       </div>
     </div>
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name"
                       label="成果名称" >
           <a-span>{{name}}</a-span>

         </a-form-item>
       </div>
     </div>
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="treeValue_xzqh" label="行政区划" >
           <a-span>{{areaName}}</a-span>
         </a-form-item>
       </div>
     </div>
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name" label="成果类型" >
           <a-span>{{typeName}}</a-span>
         </a-form-item>
       </div>


     </div>

      <!----- 动态表单 start ------->
      <div v-for="(config,index) in confList" :key="index">
        <div class="ant-row" >
          <div class="ant-col-24">
            <a-form-item :ref="config.code" :label='config.name' >
              <div v-if="config.inputType == 'input'">
                <a-span>{{config.textValue}}</a-span>
              </div>
              <div v-else-if="config.inputType == 'textarea'">
                <a-span>{{config.textValue}}</a-span>
              </div>
              <div v-else-if="config.inputType == 'data'">
                <a-date-picker
                    show-time
                    type="date"
                    style="width: 100%"
                    format="YYYY/MM/DD"
                    disabled="true"
                    v-model:value="config.textValue"
                />
              </div>
              <div v-else-if="config.inputType == 'select'">
                <a-span>{{config.textValue}}</a-span>
              </div>

              <div v-else-if="config.inputType == 'radio'">
                <a-radio-group v-model:value="config.textValue" :name="config.code">
                  <a-radio v-for="(info,index) in config.input_list" :key="index" :value="info" disabled="true">{{info}}</a-radio>
                </a-radio-group>
              </div>

              <div v-else-if="config.inputType == 'checkbox'">
                <a-checkbox-group v-model:value="config.textValue" :name="config.code">
                  <a-checkbox v-for="(info,index) in config.input_list" :key="index" :value="info" disabled="true" name="type">{{info}}</a-checkbox>
                </a-checkbox-group>
              </div>

            </a-form-item>
          </div>
        </div>
      </div>
      <!----- 动态表单 end ------->
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name" label="样例图片" >
           <div class="clearfix">
             <a-image-preview-group  v-for="(file,i) in fileList_img" :key="i" >
               <div style="float: left;margin-left: 5px;">
                 <a-image  style="height: 60px;" :src="file" />
               </div>
             </a-image-preview-group>
           </div>
         </a-form-item>
       </div>
     </div>
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name" label="样例视频" >
           <div class="ant-row" v-for="(file,index) in fileList_v" :key="index">
             <div class="ant-col-18">
               <a @click="downloadFile(file.url,file.name)" >{{file.name}}</a>
             </div>
             <div class="ant-col-4">
               <a-button  type="link" @click="online_preview(file.url)">
                 <template #icon><EyeTwoTone title="在线预览"/></template>
               </a-button>

               <a-button type="link" @click="downloadFile(file.url,file.name)">
                 <template #icon><Download title="下载"/></template>
               </a-button>
             </div>
           </div>
         </a-form-item>
       </div>
     </div>
     <div class="ant-row">
       <div class="ant-col-24">
         <a-form-item ref="name" label="附   件" >
           <div class="ant-row" v-for="(file,index) in fileList_fj" :key="index">
             <div class="ant-col-18">
               <a @click="downloadFile(file.url,file.name)" >{{file.name}}</a>
             </div>
             <div class="ant-col-4">
               <a-button type="link" @click="downloadFile(file.url,file.name)">
                 <template #icon><Download title="下载"/></template>
               </a-button>
             </div>
           </div>
         </a-form-item>
       </div>
     </div>
   </a-form>
    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>

  <!-- 在线预览 -->
  <online-preview ref="onlinePreview"/>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref } from 'vue';

import area_api from "../config/area_api";
import achieveType_api from "../config/achieveType_api";
import file_api from "../common/config/file_api";
import {GetCurrentFileUrl} from "/src/assets/utils/general"
import achieveRecord_api from "../config/achieveRecord_api";
import onlinePreview from "../common/point/onlinePreview";
import Icons from "../../../common/Icons";
export default {
  name: "dataProductsShow",
  mixins:[Icons],
  components: {
    PlusOutlined,onlinePreview
  },
  data(){
    return{
      labelCol:{ span: 2 },
      wrapperCol:{ span: 21 },
      visible:false,
      modalTitle:null,
      viewModel:false,

      itemName:null,// 项目名称
      name:null,
      areaName:null,
      typeName:null,
      confList:[],// 动态表单内容

      /** 文件处理 ***/
      // 图片
      accept_img:"image/png,image/jpeg",
      fileList_img:[],
      previewVisible:false,
      previewImage:'',
      imageUrl:file_api.upload.url,

      // 视频
      accept_v:"video",
      fileList_v:[],
      video_url:file_api.upload.url,

      // 附件
      fileList_fj:[],
      fj_url:file_api.upload.url,



    }
  },
  methods:{
    open(title,info){

      this.modalTitle = title;

      this.itemName = info.itemName;
      this.name = info.name;
      this.areaName = info.areaName;
      this.typeName = info.typeName;
      this.fileList_img = [];
      this.fileList_v = [];
      this.fileList_fj = [];

      // 图片
      let imgUrl = info.imgUrl;
      let imgList = imgUrl != null && imgUrl != '' ? imgUrl.split(";") : [];
      if(imgList.length > 0){
        for (let i=0;i<imgList.length;i++){
          let vi = imgList[i];
          this.fileList_img.push(GetCurrentFileUrl(vi))
        }
      }

      // 视频
      let videoUrl = info.videoUrl;
      let videoList = videoUrl != null && videoUrl != '' ? videoUrl.split(";") : [];
      if(videoList.length > 0){
        for (let i=0;i<videoList.length;i++){
          let vi = videoList[i];
          let num = i+1;
          let url = GetCurrentFileUrl(vi);
          let list = {
            name: '视频'+num,
            url: vi,
          }
          this.fileList_v.push(list)
        }
      }

      // 附件
      let fileUrl = info.fileUrl;
      let fileist = fileUrl != null && fileUrl != '' ? fileUrl.split(";") : [];
      if(fileist.length > 0){
        for (let i=0;i<fileist.length;i++){
          let vi = fileist[i];
          let num = i+1;
          let url = GetCurrentFileUrl(vi);
          let list = {
            name: '附件'+num,
            url: vi,
          }
          this.fileList_fj.push(list)
        }
      }
      let str_json = info.propJson;
      let confList = str_json == null || str_json == '' ? [] : JSON.parse(str_json);

      /** 查询成果类型配置 **/
      let parms = {
        id:info.typeId,
      };
      achieveType_api.queryOne.requestPOSTUrlParam(this,parms,res=>{

        if(res.data.flag){
          let list = res.data.data.confList;
          if(list.length > 0){
            for(let infoc of list){
              let inputType = infoc.inputType;
              infoc.textValue = "";
              for(var key in confList){
                let code = infoc.code;
                if(key == code){
                  if(inputType == 'checkbox'){
                    infoc.textValue = confList[key].split(";");
                  }else{
                    infoc.textValue = confList[key];
                  }
                }
              }

              if(inputType == 'select' || inputType == 'radio' || inputType == 'checkbox'){
                let valueList = infoc.valueList;
                let list = valueList.split(";");
                infoc.input_list = [];
                if(list.length > 0){
                  for(let lt of list){
                    if(lt != ''){
                      infoc.input_list.push(lt);
                    }
                  }
                }

              }
            }
          }
          this.visible = true;
          this.confList = list;
        }
      });



    },


    /** 查看图片 start **/
    handlePreview(file){
      if (!file.url && !file.preview) {
        file.preview = this.getBase64(file.originFileObj);
      }

      let url = GetCurrentFileUrl(file.response.data.fileUrl);
      this.previewImage = url || file.preview;
      this.previewVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = error => reject(error);
      });
    },
    handleCancel(){
      this.previewVisible = false;
    },
    /** 查看图片 end **/

    downloadFile(url,name){
      let data = {
        fileUrl:url
      };
      file_api.download.fileDownload(this,data,name);
    },

    online_preview(url){
      this.$refs.onlinePreview.setFileUrl(url);
    },

  },

}
</script>

<style scoped>

</style>