/**
 * @program: office_automation
 * @author: ly
 * @description: MenuOps  菜单权限预设
 * @create: 2023-02-27 17:24
 **/

const MenuOps = [
    {name: "查询",opcode: "op_query"},
    {name: "新增",opcode: "op_add"},
    {name: "编辑",opcode: "op_edit"},
    {name: "删除",opcode: "op_delete"},
    {name: "上传",opcode: "op_upload"},
    {name: "审核",opcode: "op_check"},
];

export {MenuOps};