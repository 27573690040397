<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityTaskGroup
* @description: 选择质检组
* @create: 2021-07-05 09:56
-->
<template>
    <div style="margin-top: 30px">
        <div class="center-flex">
            <span style="width: 200px">质检组：</span>
            <a-select v-if="!viewModel" v-model:value="qcApply.qcGroupId" style="width: 400px" @change="selectChange" >
                <a-select-option v-for="option in qcGroups" :key="option.id">{{option.name}}</a-select-option>
            </a-select>
            <div v-else>{{record.qcGroupName}}</div>
            <a-button v-if="!viewModel" style="margin-left: 10px" type="primary" @click="saveGroup">
                保存
            </a-button>
        </div>
        <div style="margin-top: 30px">
            <span>质检组长：{{leaderName}}</span>
            <ArBaseTable  :argument="argument" :view-model="true" :page-size="10"/>
        </div>
    </div>
</template>

<script>

    import item_qc_group_member from "../../../assets/tables/item_qc_group_member";
    // import api_item_qc_group_member from "../../../assets/api/qcGroupMember";
    // import api_item_qc_group from "../../../assets/api/qcGroup";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import qcApply from "../../../assets/api/qcApply";
    export default {
        name: "qualityTaskGroup",
        emits:["refresh","finish"],
        components:{
           ArBaseTable
        },
        props:{
            record:{required:true},//当前质检申请的record
            viewModel:{required:true},
        },
        computed:{
            status(){
                if(this.hasMembers){
                    return "finish";
                }
                else{
                    return "error";
                }
            }
        },
        data(){
            return{
                qcApply:null,
                qcGroups:[],
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                table:new item_qc_group_member(),
                modalVisible:false,
                disabled:true,
                hasMembers:false,
                leaderName:null
            }
        },
        methods:{
            init(){
                if(this.viewModel){
                    this.table.actions = [];
                }
                this.argument.tableColumns = this.table.getTableColumns();
                this.argument.formColumns = this.table.getFormColumns();
                let data = {groupId:this.qcApply.qcGroupId};
                this.argument.loading = true;
                // api_item_qc_group_member.queryAll.requestPOSTUrlParam(this,data,res=>{
                //     this.argument.loading = false;
                //     if(res.data.flag){
                //         this.argument.dataSource = res.data.data;
                //         if(res.data.data.length > 0){
                //             this.hasMembers = true
                //         }
                //     }
                //     else{
                //         this.argument.dataSource=[];
                //     }
                // })
            },
            tableInit(groupId){
                let data = {groupId:groupId};
                this.argument.loading = true;
                // api_item_qc_group_member.queryAll.requestPOSTUrlParam(this,data,res=>{
                //     this.argument.loading = false;
                //     if(res.data.flag){
                //         this.argument.dataSource = res.data.data;
                //     }
                //     else{
                //         this.argument.dataSource=[];
                //     }
                // })
            },
            getStatus(){
                return this.status;
            },
            saveGroup(){
                let data = {
                    id:this.qcApply.id,
                    qcGroupId:this.qcApply.qcGroupId
                };
                qcApply.bindQcGroup.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                        this.$emit("refresh");
                        this.$emit("finish");
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            modalShow(){
                this.modalVisible = true;
                this.argument.formData = this.table.getFormData();
            },
            selectChange(){
                let group = this.qcGroups.filter(item=>{
                    return item.id === this.qcApply.qcGroupId
                })[0];
                this.leaderName = group.leaderName;
                this.tableInit(this.qcApply.qcGroupId)
            }
        },
        created() {
            this.qcApply = {...this.record};
            if(this.qcApply.qcGroupId){
                this.qcGroups = [{
                    id:this.qcApply.qcGroupId,name:this.qcApply.qcGroupName
                }];
                this.leaderName = this.qcApply.leaderName
            }
            else{
                this.qcGroups = [];
            }
            if(!this.viewModel){
                // api_item_qc_group.queryAll.requestPOSTUrlParam(this,{},res=>{
                //     if(res.data.flag){
                //         this.qcGroups = this.qcGroups.concat(res.data.data);
                //     }
                // });
            }
            this.init()
        }
    }
</script>

<style scoped>

</style>