<!--
* @program: tyh-oa 
* @author: ly
* @component:personalBillStatement 账单流水
* @description: 
* @create: 2021-04-07 14:02
-->
<template>
    <div>
        <a-tooltip :key="'#fff'" title="添加新流水（继承）">
            <a-button v-if="!viewModel" class="search-item"  @click="modalAdd(true)">
                <template #icon><PlusOutlined /></template>
            </a-button>
        </a-tooltip>
        <a-tooltip :key="'#fff'" title="添加新流水（空白）">
            <a-button v-if="!viewModel" class="search-item" type="dashed" shape="circle"  @click="modalAdd(false)">
                <template #icon><PlusOutlined /></template>
            </a-button>
        </a-tooltip>
        <a-button  class="search-item" type="dashed"  @click="tableShow">
            <template #icon><SearchOutlined /></template>
        </a-button>
        <a-tooltip :key="'#fff'" title="顺序">
            <a-button class="search-item" type="dashed" shape="circle"  @click="sortItem">
                <template #icon><SortAscendingOutlined /></template>
            </a-button>
        </a-tooltip>
        <span class="search-item">排序：{{sortType}}</span>
        <slot name="action" />
        <TcBaseTable :page-size="12" :view-model="true"
                     :loading="loading1" :table-columns="tableColumns1" :data-source="flows" :current-index="currentIndex1">
            <template #action = "{index,record}">
                <div class = "action-column">
                    <a-button class="action-button" type="link" @click="showDetail(index,record)">
                        <SearchOutlined class="pointer"/>
                    </a-button>
                    <a-button v-if="!viewModel" class="action-button" type="link" @click="editDetail(index,record)">
                        <EditFilled class="pointer"/>
                    </a-button>
                    <a-popconfirm
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            v-if="!viewModel"
                            title="确定删除吗?"
                            @confirm="deleteData(index,record)">
                        <DeleteOutlined  style="color: red" class="pointer"/>
                    </a-popconfirm>
                </div>
            </template>
        </TcBaseTable>
        <div>
            <a-row>
                <a-col :span="6">
                    <a-statistic title="发票合计" :precision="2" :value="calcSum.ISum" >
                        <template #suffix>元</template>
                    </a-statistic>
                </a-col>
                <a-col :span="6">
                    <a-statistic title="报销（有发票部分）合计" :precision="2" :value="calcSum.WSum" >
                        <template #suffix>元</template>
                    </a-statistic>
                </a-col>
                <a-col :span="6">
                    <a-statistic title="报销（无发票部分）合计" :precision="2" :value="calcSum.NSum" >
                        <template #suffix>元</template>
                    </a-statistic>
                </a-col>
                <a-col :span="6">
                    <a-statistic title="报销合计" :precision="2" :value="calcSum.ASum" >
                        <template #suffix>元</template>
                    </a-statistic>
                </a-col>
            </a-row>
        </div>
        <a-modal v-model:visible="visible"
                 destroyOnClose
                 :maskClosable="false"
                 :footer="null">
            <ArBaseForm :view-model="VModel" :argument="formArg" >
                <template #action = "{record}">
                    <a-button type="primary"  @click="saveData(record)">提交</a-button>
                </template>
            </ArBaseForm>
        </a-modal>
        <a-modal v-model:visible="tableVisible"
                 destroyOnClose
                 wrapClassName="full-modal"
                 :footer="null"
                 title="详情一览">
            <TcBaseTable  :view-model="true" :page-size="12" :filter-on="false" :show-all="true"
                          :loading="loading1" :table-columns="tableColumns2" :data-source="flows" :current-index="currentIndex2"/>
        </a-modal>
    </div>
</template>

<script>
    import {PlusOutlined,SearchOutlined,SortAscendingOutlined,EditFilled,DeleteOutlined} from "@ant-design/icons-vue";
    import item_finance_record_flow from "../../../assets/tables/item_finance_record_flow";
    import api_item_finance_record_flow from "../../../assets/api/recordFlow";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import moment from "moment";
    import {getNextMonth,getPreviousMonth} from "../../../assets/utils/dataUtil";
    export default {
        name: "personalBillStatement",
        props:["viewModel","record","flowIds"],
        components:{
            PlusOutlined,ArBaseForm, SearchOutlined,SortAscendingOutlined, TcBaseTable,DeleteOutlined,EditFilled
        },
        computed:{
            flows(){
                if(this.flowIds){
                    if(this.flowIds === "all"){
                        return this.dataSource
                    }
                    else{
                        let ids = this.flowIds.split(",");
                        let arr = [];
                        for(let id of ids){
                            for(let item of this.dataSource){
                                if(id === item.id){
                                    arr.push(item);
                                }
                            }
                        }
                        return arr
                    }
                }
                else{
                    return this.dataSource
                }
            },
            calcSum(){
                // ISum 发票合计
                // WSum 报销（有发票）
                // NSum 报销（无发票）
                // ASum 总合计
                let ISum = 0;
                let WSum = 0;
                let NSum = 0;
                let ASum = 0;
                for(let i in this.flows){
                    ISum += this.flows[i].invoiceAmount;
                    ASum += this.flows[i].applyAmount;
                    if(this.flows[i].invoiceState){ //是否有发票
                        if(this.flows[i].applyAmount>this.flows[i].invoiceAmount){//报销金额大于发票金额
                            WSum += this.flows[i].invoiceAmount;
                            NSum += this.flows[i].applyAmount - this.flows[i].invoiceAmount;
                        }
                        else{
                            WSum += this.flows[i].applyAmount ;
                        }
                    }
                    else{
                        NSum += this.flows[i].applyAmount ;
                    }
                }
                ISum = ISum.toFixed(2);
                WSum = WSum.toFixed(2);
                NSum = NSum.toFixed(2);
                ASum = ASum.toFixed(2);
                return {ISum,WSum,NSum,ASum}
            }
        },
        data(){
            return{
                visible:false,
                table: new item_finance_record_flow(),

                dataSource:[],
                loading1:false,
                VModel:false,
                tableColumns1:null, //提供table的字-段集 this.table.getTableColumns()
                currentIndex1:null,
                tableColumns2:null, //提供table的字-段集 this.table.getTableColumns()
                currentIndex2:null,
                formArg:{
                    formColumns:[], //提供Form的字段集 this.table.getFormColumns()
                    formData:{},//提供Form的formData
                },

                tableVisible:false,
                sortType:"默认", //0 默认 1 添加时间正序  2 添加时间倒叙   3 项目名称正序
            }
        },
        methods:{
            init(){
                if(this.record.id){
                    this.loading1 = true;
                    let data = {recordId:this.record.id};
                    api_item_finance_record_flow.queryAll.requestPOSTUrlParam(this,data,res=>{
                        this.loading1 = false;
                        if(res.data.flag){
                            this.dataSource = res.data.data;
                            /**
                             * 因为前后台对于 invoiceAmount 理解不一致，前台为发票金额 ，后台为支出金额
                             * 在存储无发票的时候，需要将invoiceAmount与applyAmount的值保持一致，所以导致在查询无发票的时候结果中，
                             * invoiceAmount字段会有属性值，但是前台展示的为发票金额，并不是支出金额，所以现在需要将查询结果中无发票
                             * 的条件下，将invoiceAmount值清空
                             */
                            for(let i in this.dataSource){
                                if(!this.dataSource[i].invoiceState){
                                    this.dataSource[i].invoiceAmount = 0;
                                }
                            }

                        }
                        else{
                            this.dataSource = []
                            //this.$message.error(res.data.msg);
                        }
                    });
                }
            },
            saveData(record){
                record.recordId = this.record.id;
                if(this.checkRecordFlow(record)){
                    api_item_finance_record_flow.saveOrUpd.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.formArg.formData = record;
                            this.visible =false;
                            this.init();
                        }
                        else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }
            },
            modalAdd(bool){//添加新流水 true 继承前账单  false空白账单
                if(bool){
                    this.formArg.formData = Object.keys(this.formArg.formData).length !== 0?this.formArg.formData:this.table.getFormData();
                    if(this.formArg.formData.invoiceUrl){
                        this.formArg.formData.invoiceUrl="";
                    }
                    delete this.formArg.formData.id;
                    this.visible = true;
                    this.VModel = false;
                }
                else{
                    this.formArg.formData=this.table.getFormData();
                    this.visible = true;
                    this.VModel = false;
                }
            },
            showDetail(index,record){
                this.currentIndex1 = index;
                this.VModel=true;
                this.visible = true;
                this.formArg.formData = record;
            },
            editDetail(index,record){
                this.currentIndex1 = index;
                this.VModel=false;
                this.visible = true;
                this.formArg.formData = record;
            },
            deleteData(index,record){
                let data = {id:record.id};
                api_item_finance_record_flow.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            checkRecordFlow(flow){
                let step1=false,step2=false;
                //flow.invoiceState  没有发票的情况需要报销凭证，除 技术服务费A（bd2637b3-81cb-4526-82e9-736aba6db811）出差餐补（fbadaa53-2412-44ad-87d1-3ba46ec18157） 除外
                let nonList = ["bd2637b3-81cb-4526-82e9-736aba6db811","fbadaa53-2412-44ad-87d1-3ba46ec18157"];
                //无发票的情况
                if(!flow.invoiceState){
                    /**
                     * 因为前后台对于 invoiceAmount 理解不一致，前台为发票金额 ，后台为支出金额
                     * 在存储无发票的时候，需要将invoiceAmount与applyAmount的值保持一致，所以导致在填写无发票的账单中，
                     * invoiceAmount 需要在这里进行赋值（将报销金额赋给发票金额），而不是由弹出框Form中进行填写
                     */
                    flow.invoiceAmount = flow.applyAmount;
                    /** -------------*/
                    if(flow.invoiceUrl){
                        step1 = true
                    }
                    else {
                        if(nonList.indexOf(flow.categoryId )!==-1) {//不需要凭证的数组除外
                            step1 =  true;
                        }
                        else {
                            this.$message.error("p1没有报销凭证");
                            step1 = false
                        }
                    }
                }
                //有发票的情况 且 发票金额不等于报销金额的情况需要报销凭证
                if(flow.invoiceState && flow.invoiceAmount < flow.applyAmount){
                    if(flow.invoiceUrl){
                        step1 = true
                    }
                    else {
                        this.$refs.personalBillForm.init();
                        this.$message.error("p2没有报销凭证");
                        step1 = false
                    }
                }
                if(flow.invoiceState && flow.invoiceAmount >= flow.applyAmount){
                    delete flow.invoiceUrl;
                    step1 = true;
                }
                if(flow.itemExpenditure){
                    if(flow.itemId){
                        step2 = true;
                    }
                    else{
                        step2 = false;
                        this.$message.error("请选择所属项目")
                    }

                }
                else{
                    if(!flow.itemId){
                        step2 = true;
                    }
                    else{
                        step2 = false;
                        this.$message.error("并非为项目支付")
                    }
                }
                return step1 && step2;
            },
            tableShow(){
                this.tableVisible=true;
            },
            sortItem(){
                switch (this.sortType) {// 默认  添加时间正序   添加时间倒叙    项目名称正序
                    case "默认" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            return a.createTime - b.createTime;
                        });
                        this.sortType = "添加时间正序";
                        break;
                    }
                    case "添加时间正序" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            return b.createTime - a.createTime;
                        });
                        this.sortType = "添加时间倒叙";
                        break;
                    }
                    case "添加时间倒叙" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            let name1 = a.itemName?a.itemName:"无";
                            let name2 = b.itemName?b.itemName:"无";
                            return name1.localeCompare(name2, 'zh-CN');
                        });
                        this.sortType = "项目名称正序";
                        break;
                    }
                    case "项目名称正序" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            let name1 = a.itemName?a.itemName:"无";
                            let name2 = b.itemName?b.itemName:"无";
                            return name2.localeCompare(name1, 'zh-CN');
                        });
                        this.sortType = "项目名称倒序";
                        break;
                    }
                    case "项目名称倒序" :{
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            return a.createTime - b.createTime;
                        });
                        this.sortType = "添加时间正序";
                        break;
                    }

                }
            },
        },
        created() {
            this.tableColumns1 = this.table.getTableColumns();
            this.tableColumns2 = this.table.getTableColumns();
            this.formArg.formColumns = this.table.getFormColumns();
            let timeCol = this.formArg.formColumns.find(item=>item.dataIndex ==="flowTime");
            if(timeCol && this.record){
                let recordYearMonth = this.record.recordYearMonth;
                let end = new moment(recordYearMonth).format("yyyy-MM-DD");
                let month_Previou = getPreviousMonth(end);// 上个月
                let month_Next = getNextMonth(end);// 下个月
                timeCol.setEnableTime([month_Previou,month_Next]);
            }
            this.init();
        }
    }
</script>

<style scoped>

</style>