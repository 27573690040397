/**
 * 成果管理
 * @description：
 * @author:rzl
 * @date:2022/6/6 9:39
 **/
import APIObject from "../../../../assets/utils/APIObject";
const api={
    saveOrUpd:new APIObject("/api/achieveRecord/saveOrUpd1.do"),// 保存/修改-单条
    delete:new APIObject("/api/achieveRecord/delete.do"),// 删除-单条
    queryPage:new APIObject("/api/achieveRecord/queryPage.do"),// 查询-分页
    queryOne:new APIObject("/api/achieveRecord/queryOne.do"),// 查询-单条
    batchDelete:new APIObject("/api/achieveRecord/batchDelete.do"),// 删除-批量
    shpToWkt:new APIObject("/api/achieveRecord/shpToWkt.do"),// shp转wkt格式
    queryAll:new APIObject("/api/achieveRecord/queryAll.do"),// 查询-所有数据


    queryAll_item:new APIObject("/api/itemInfo/queryAll.do"),// 查询项目
};
export default api;