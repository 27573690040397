/**
 * 文件api
 * @description：
 * @author:rzl
 * @date:2022/6/8 9:47
 **/
import APIObject from "../../../../../assets/utils/APIObject";

const api={
    download:new APIObject("/api/file/download.do"),// 文件操作模块-文件下载/查看
    uploads:new APIObject("/api/file/uploads.do"),// 文件操作模块-多个文件上传
    batchDeleteFile:new APIObject("/api/file/batchDeleteFile.do"),// 文件操作模块-文件删除(可批量，可单个)
    upload:new APIObject("/api/file/upload.do"),// 文件操作模块-单个文件上传
};
export default api;