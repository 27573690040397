<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityStepFinish 
* @description: 
* @create: 2021-07-05 15:06
-->
<template>
    <div style="margin:50px 0;font-size:40px">
        点击完成完成设置
    </div>
</template>

<script>
    export default {
        name: "qualityStepFinish",
    }
</script>

<style scoped>

</style>