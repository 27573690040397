/**
 * @description：固定资产类别
 * @author:rzl
 * @date:2022/7/25 14:30
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class fixedAssetsType_item extends Table {
    constructor() {
        super();
        this.name = "fixedAssetsType_item";
        this.CNName = "固定资产类别";
        this.columnArray = [
            new Column("主键", "id", ColumnType.String, false).setVisible(false),
            new Column("类别名称", "categoryName", ColumnType.String, true).setTableView(150),
            new Column("描述", "remarks", ColumnType.String, true).setTableView(300),

        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(100)];
    }
}