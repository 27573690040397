/** 
* 自动生成文件，请勿修改 
* @name: 报销:人员报账审批流程模块
* @description: 报销-人员报账审批流程-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const processAudit = { 
	/** 1 summary:人员报账审批流程-批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/processAudit/batchSaveOrUpd.do"), 
	/** 2 summary:人员报账审批流程-分页查询	method:post */ 
	queryPage : new APIObject("/api/processAudit/queryPage.do"), 
	/** 3 summary:人员报账审批流程-批量删除	method:post */ 
	batchDelete : new APIObject("/api/processAudit/batchDelete.do"), 
	/** 4 summary:人员报账审批流程-单条删除	method:post */ 
	delete : new APIObject("/api/processAudit/delete.do"), 
	/** 5 summary:人员报账审批流程-所有数据查询	method:post */ 
	queryAll : new APIObject("/api/processAudit/queryAll.do"), 
	/** 6 summary:人员报账审批流程-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/processAudit/saveOrUpd.do"), 
	/** 7 summary:人员报账审批流程-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/processAudit/queryOne.do"), 
};
export default processAudit;