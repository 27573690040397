/** 
* 自动生成文件，请勿修改 
* @name: 微信:小程序菜单-角色关联管理模块
* @description: 小程序菜单-角色关联管理模块
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:2条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const wxMenuRole = { 
	/** 1 summary:清空-某角色拥有的菜单	method:post */ 
	clearByRole : new APIObject("/api/wxMenuRole/clearByRole.do"), 
	/** 2 summary:菜单角色绑定	method:post */ 
	bind : new APIObject("/api/wxMenuRole/bind.do"), 
};
export default wxMenuRole;