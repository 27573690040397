<!--
* @program: office_automation 
* @author: ly
* @component:UserPicker 
* @description: 部门员工选择器
* @create: 2022-03-03 14:04
-->
<template>
    <div v-if="selectVisible">
        <a-radio-group class="search-item" v-model:value="type">
            <a-radio value="按部门选择">按部门选择</a-radio>
        </a-radio-group>
        <a-select v-model:value="departmentId" @select="departmentChange" style="width: 120px" class="search-item">
            <a-select-option v-for="item in departments" :key="item.id">
                {{ item.name }}
            </a-select-option>
        </a-select>
        <a-tree-select
                v-model:value="selectIds"
                style="width: 150px"
                :tree-data="treeData"
                tree-checkable
                allow-clear
                :show-checked-strategy="SHOW_CHILD"
                search-placeholder="Please select"
        />
        <a-button  type="primary" @click="selectConform">
            确定
        </a-button>
    </div>
    <div v-else style="display: flex;">
        <a-button  type="primary" @click="selectShow" >
            选择人员
        </a-button>
        <div v-if="selectedData.length>0" style="margin: 5px">
            <a-tag v-for="(item,idx) in selectedData" :key="idx.toString()" @close="remove(item)"
                   v-model:visible="item.visible" closable>{{item.title}}</a-tag>
        </div>
        <div v-else style="margin: 5px">
            只能选择到设定了【岗位级别】的员工
        </div>
    </div>
</template>

<script>
    import { TreeSelect } from 'ant-design-vue';
    import department from "../../assets/api/department";
    import userDepartRelation from "../../assets/api/userDepartRelation";
    export default {
        name: "UserPicker",
        props:["value"],
        emits:["update:value"],
        computed:{
            users(){
                let users = [];
                for(let i in this.selectedData){
                    if(this.selectedData[i].visible){
                        users.push({id:this.selectedData[i].key,name:this.selectedData[i].title});
                    }
                }
                return users;
            }
        },
        data(){
            return{
                type:"按部门选择",
                SHOW_CHILD:TreeSelect.SHOW_CHILD,
                departments:[],
                departmentId:null,
                treeData:[],
                selectIds:null,
                selectVisible:false,
                selectedData:[],
            }
        },
        methods:{
            init(){
                this.selectedData = [];
                this.$emit("update:value",this.users);
            },
            remove(){
                this.$emit("update:value",this.users);
            },
            departmentChange(){
                this.treeData = [];
                this.selectedData = [];
                this.selectIds = null;
                let data = {
                    departmentId:this.departmentId,
                    disableState:"1",
                    loginState:"0",
                    haveLevel:"1"
                };
                userDepartRelation.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        let treeData = new treeDataItem("all","全部",false,[]);
                        for(let i in res.data.data){
                            let item = new treeDataItem(res.data.data[i].userId,res.data.data[i].userName,false);
                            treeData.addChild(item)
                        }
                        this.treeData.push(treeData);
                    }
                })
            },
            selectConform(){
                this.selectedData = this.treeData[0].children;
                this.selectedData = this.selectedData.filter(item=>{return this.selectIds.indexOf(item.key) !== -1});
                this.selectVisible = false;
                this.$emit("update:value",this.users);
            },
            selectShow(){
                this.selectVisible = true;
                this.selectIds = null;
            }
        },
        created() {
            this.init();
            department.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.departments = res.data.data;
                }
                else{
                    this.departments = [];
                }
            })
        }
    }
class treeDataItem{
        constructor(value,title,disabled,children) {
            this.value = value;
            this.key = value;
            this.title = title;
            this.disabled = !!disabled;
            this.children = children?children:[];
            this.visible = true;
        }
        setChildren(children){
            this.children = children;
            return this;
        }
        addChild(child){
            this.children.push(child);
            return this;
        }
}
</script>

<style scoped>

</style>