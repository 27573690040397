<!--
* @program: tyh-oa 
* @author: ly
* @component:billSetting 报账相关设定，基础设定
* @description: 
* @create: 2021-04-14 11:15
-->
<template>

    <div>
        <a-tabs   v-model:activeKey="activeKey" >
            <a-tab-pane  key="setting1" tab="定制报账审核流程" >
                <billStepsSettings />
            </a-tab-pane>
<!--            <a-tab-pane style="height: 100%;overflow-y: auto" key="setting2" tab="费用类别设定" >-->
<!--                <billCategoryTable />-->
<!--            </a-tab-pane>-->
            <template #renderTabBar="{ DefaultTabBar, ...props }">
                <component :is="DefaultTabBar" v-bind="props" :style="{ zIndex: 1, background: '#fff', }"/>
            </template>
        </a-tabs>
    </div>
</template>

<script>
    import billCategoryTable from "./billComponents/billCategoryTable";
    import billStepsSettings from "./billComponents/billStepsSettings";
    export default {
        name: "billSetting",
        components:{
            billCategoryTable,billStepsSettings
        },
        data(){
            return{
                activeKey:"setting1",
                size:"large",
            }
        }
    }
</script>

<style scoped>

</style>