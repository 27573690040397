/** 
* 自动生成文件，请勿修改 
* @name: 考核:卫生评分管理-月度卫生评分统计
* @description: 月度卫生评分统计(三期新增)
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const hygiene = { 
	/** 1 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/hygiene/delete.do"), 
	/** 2 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/hygiene/batchDelete.do"), 
	/** 3 summary:导出-卫生评分模板(员工实时名单数据)	method:get */ 
	exportExcel : new APIObject("/api/hygiene/exportExcel.do"), 
	/** 4 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/hygiene/queryAll.do"), 
	/** 5 summary:根据id查询	method:post */ 
	queryById : new APIObject("/api/hygiene/queryById.do"), 
	/** 6 summary:新增/修改-单条	method:post */ 
	saveOrUpdate : new APIObject("/api/hygiene/saveOrUpdate.do"), 
	/** 7 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/hygiene/queryPage.do"), 
	/** 8 summary:导入-卫生评分模板	method:post */ 
	importExcel : new APIObject("/api/hygiene/importExcel.do"), 
};
export default hygiene;