<!--
* @program: tyh-oa 
* @author: ly
* @description: typeBase 
* @create: 2021-03-23 10:51
-->
<template>
    <div>

    </div>
</template>

<script>
    import OverSizeColString2 from "./OverSizeColString2";
    export default {
        name: "typeBase",
        inject:["provideTrigger","presentType"],
        props:{
            record:{required:true},
            column:{required: true},
            size:{required:false},
            viewModel:{required:true}
        },
        components:{OverSizeColString2},
        watch:{
            record(){
                this.columnTrigger();
            }
        },
        emits:["update:record","update"],
        methods:{
            setRecord(data){
                this.$emit("update:record",data);
            },
            columnTrigger(){
                this.$emit("update");
                if(this.presentType === "table"){
                    return
                }
                let triggers = this.column.triggerOtherColumn;
                if(triggers && triggers.length>0){
                    //遍历设置的触发器
                    for(let i in triggers){
                        if(triggers[i].value instanceof Array){//trigger.value 为 数组的情况
                            let colValue = this.record[this.column.dataIndex];
                            if(triggers[i].value.indexOf(colValue) !== -1 && triggers[i].action){
                                this.provideTrigger(triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.provideTrigger(triggers[i].action2);
                                }
                            }
                        }
                        else if(isFunction(triggers[i].value)){////trigger.value 为 条件函数的情况
                            if(triggers[i].value(this.record) && triggers[i].action){
                                this.provideTrigger(triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.provideTrigger(triggers[i].action2);
                                }
                            }
                        }
                        else{//trigger.value 为 普通值的情况
                            if(triggers[i].value === this.record[this.column.dataIndex] && triggers[i].action){
                                this.provideTrigger(triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.provideTrigger(triggers[i].action2);
                                }
                            }
                        }
                    }
                }
            }
        },
        computed:{
            editable(){ //字段是否允许编辑
                return this.column.editable && !this.viewModel;
            },
            cellValue(){
                if(this.dataIndex.length ===1){
                    return this.record[this.dataIndex[0]]
                }
                else{
                    if(this.record[this.dataIndex[0]]){
                        return this.record[this.dataIndex[0]][this.dataIndex[1]];
                    }
                    else{
                        return null;
                    }
                }
            },
            dataIndex(){
                return this.column.dataIndex.split(".")
            },
        },
        mounted() {
            this.columnTrigger();
        },
    }
    function isFunction(fn) {
        return Object.prototype.toString.call(fn) === '[object Function]';
    }
</script>

<style scoped>

</style>