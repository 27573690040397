<!--
* @description：年初余额管理
* @author:rzl
* @date:2022/7/27 15:43
-->
<template>
  <a-tooltip  title="添加角色">
    <a-button class="search-item" type="dashed"  @click="addData">
      <template #icon><Add /></template>
    </a-button>
  </a-tooltip>
  <a-tooltip  title="刷新">
    <a-button class="search-item" type="dashed"  @click="refresh">
      <template #icon><Reset /></template>
    </a-button>
  </a-tooltip>

  <!-- table start -->
  <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true"  style="margin:0 30%;width: 40%;">
    <template v-slot:action="{index,record}">
      <div style="justify-content: center;;display: flex;">
        <a-button type="link" @click="editData(index,record)">
          <template #icon><Edit title="编辑"/></template>
        </a-button>
        <a-popconfirm
            class="action-button"
            title="确定删除吗?"
            okText="确定"
            cancelText="取消"
            @confirm="deleteData(index,record)"
        >
          <a-button type="link">
            <template #icon><delete title="删除" style="color: red"/></template>
          </a-button>
        </a-popconfirm>
      </div>
    </template>
  </ArBaseTable>
  <!-- table end -->

  <a-modal v-model:visible="visible"
             :maskClosable="false"
             :title="title"  width="30%"
             :destroyOnClose="true"
    >

    <ar-form  ref="bill_form" :argument="argument"  :viewModel="false"  />

    <template #footer>
      <a-button type="primary" @click="submit">提交</a-button>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import Icons from "../../common/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import ArForm from "../../../components/form/ArForm";

import yearBalance_item from "./configs/yearBalance_item";
import CashBalance from "../../../assets/api/CashBalance";
export default {
  name: "yearBalance",
  mixins:[Icons],
  components:{
    ArForm,ArBaseTable
  },
  data(){
    return{
      pageParam:{
        page: 1,
        limit: 10,
      },
      argument:{
        dataSource:[],//提供table的DataSource
        tableColumns:new yearBalance_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        formColumns:new yearBalance_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
        loading:false,
      },
      title:null,
      visible:false,//角色弹出框
    }
  },
  created() {
    this.init();
  },
  methods:{
    init(){
      this.argument.loading = true;
      CashBalance.queryAll.requestPOST(this,{},res=>{
        this.argument.loading = false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data;
        }
      });
    },
    refresh(){
      this.init()
    },
    addData(){
      this.argument.formData = {};
      this.visible = true;
      this.title = "新增信息";
    },
    deleteData(index,record){
      let data ={id:record.id};
      CashBalance.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.init();
          this.$message.success(res.data.msg);
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },
    editData(index,record){
      this.argument.formData = record;
      this.visible = true;
      this.title = "编辑信息";
    },
    submit(){
      let parms = this.argument.formData;
      CashBalance.saveOrUpd.requestPOST(this,parms,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.init();
          this.visible = false;
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },

  },
}
</script>

<style scoped>

</style>
