/**
 * @description：收入信息
 * @author:rzl
 * @date:2022/7/25 14:29
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class finance_income_item extends Table{
    constructor() {
        super();
        this.name = "finance_income_item";
        this.CNName = "项目收款信息";
        this.columnArray = [
            new Column("所属项目","itemName",ColumnType.String,true).setTableView(200),
            new Column("收款日期","collectionTime",ColumnType.Date,true).setTableView(80),
            new Column("收款金额（元）","collectionAmount",ColumnType.Number,true).setTableView(100),
           /* new Column("入账账户","bankId",ColumnType.Selector,true).setTableView(100)
                .setSelectorObject("bankNum",[]),*/
          /*  new Column("收款方式","collectType",ColumnType.Enum,true).setTableView(100)
                .setEnum(["1","2"],["现金","对公打款"]),*/

        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}