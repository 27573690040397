<!--
* @description：部门兑付情况
* @author:rzl
* @date:2022/7/27 15:43
-->
<template>
    <div class="center-flex">
        <a-button v-if="!viewModel" class="search-item" type="primary" @click="addData">
            <template #icon><PlusOutlined /></template>添加兑付
        </a-button>
        <a-button  class="search-item" type="dashed"  @click="refresh">
            <template #icon><SyncOutlined /></template>
        </a-button>
    </div>
    <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="12" >
        <template #action="{index,record}">
            <div class="action-column">
                <a-button  type="link"  @click="showDetail(index,record)">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button v-if="!viewModel"  type="link"  @click="editDetail(index,record)">
                    <template #icon><EditOutlined /></template>
                </a-button>
                <a-popconfirm
                        v-if="!viewModel"
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </ArBaseTableFilter>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="兑付信息"
             :maskClosable="vModel"
             :footer="null">
        <div v-if="departments.length > 1" style="display: flex;justify-content: center;align-items: center;">
            选择部门：
            <a-select v-model:value="selectId" style="width: 200px" placeholder="选择部门" :options="departments"></a-select>
        </div>
        <ArForm ref="ArForm" :view-model="vModel" :argument="argument" />
        <a-button v-if="!vModel" class="search-item" type="primary"  @click="saveData">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import ArForm from "../../../components/form/ArForm";
    import departPayment from "../../../assets/api/departPayment";
    import departPayment_item from "./config/departPayment_item";

    export default {
        name: "departPayment",
        props:["projectId","viewModel","record"],
        components:{
            PlusOutlined, ArBaseTableFilter,DeleteOutlined,EditOutlined,
            ArForm,SyncOutlined,SearchOutlined
        },
        computed:{
           departments(){
               let arr = [];
               arr.push({
                   label: this.record.depName,
                   value: this.record.departmentId
               });
               if(this.record.childTaskList && this.record.childTaskList.length > 0){
                   for(let item of this.record.childTaskList){
                       arr.push({
                           label: item.depName,
                           value: item.departmentId
                       })
                   }
               }
               return arr;
           },
        },
        data(){
            return{
                table:null,
                argument:{
                  dataSource:[],//提供table的DataSource
                  tableColumns:new departPayment_item().getTableColumns(), //提供table的字-段集 this.table.getTableColumns()
                  formColumns:new departPayment_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
                  formData:{},//提供Form的formData
                  loading:false,//table的loading状态
                },
                selectId:null,
                visible:false,
                vModel:true,
            }
        },
        created() {
          this.init();
        },
        methods:{
            init(){
                this.selectId = this.record.departmentId;
                let parms = {
                  itemId:this.projectId,
                  taskId:this.record.id
                };
                this.argument.loading = true;
                departPayment.queryAll.requestPOSTUrlParam(this,parms,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                      let listData = res.data.data;
                      this.argument.dataSource = listData;
                    }
                })

            },
            refresh(){
                this.init()
            },
            addData(){
                this.vModel = false;
                this.visible = true;
            },
            showDetail(index,record){
                this.visible = true;
                this.vModel = true;
                this.argument.formData = record;
            },
            editDetail(index,record){
                this.visible = true;
                this.vModel = false;
                this.argument.formData = record;
            },
            deleteData(index,record){
                let data = {id:record.id};
                departPayment.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(){
                let record = this.argument.formData;
                record.itemId = this.projectId;
                record.taskId = this.record.id;
                record.departId = this.selectId;
                departPayment.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });

            },

        },


    }
</script>

<style scoped>

</style>