
/**
 * @description：Excel工具类
 * @author:rzl
 * @date:2022/7/20 10:17
 **/
import * as XLSX from 'xlsx'
/**
 *  将数据转换成Excel，单个sheet保存
 * @param fileName      文件名
 * @param tableHeader   表头
 * @param dataList      表数据
 * @param sheet         工作表默认名字是sheet1
 */
const dataToExcel = (fileName,tableHeader,dataList,sheet="sheet1")=> {
    // excel的表头和数据
    let aoa = [];
    // aoa的数据格式：[[],[],[],[]]   数组的第一个子数组可以指定为表头  根据情况而定
    aoa = tableHeader.concat(dataList);

    let workSheet = XLSX.utils.aoa_to_sheet(aoa);
    let workBook = XLSX.utils.book_new();

    // 把数据写到工作簿中
    XLSX.utils.book_append_sheet(workBook,workSheet,sheet)
    //如果一个工作工作簿中有多个工作表，可以修改参数类型并遍历添加，期中workBook是同一个，workSheet和sheet根据自己的需求添加，
    //比如在此处添加第二个工作表叫‘第二张表’，把数据封装好后，数据格式同上,假如数据叫workSheet2，执行下面代码，工作簿就会多一张工作表叫‘第二张表’
    //XLSX.utils.book_append_sheet(workBook,workSheet2,'第二张表')

    //保存
    XLSX.writeFile(workBook, fileName + ".xlsx")

}
/**
 * 将数据转换成Excel，多个sheet保存
 * @param fileName
 * @param tableInfo=[
 *       {
 *           sheetName:'sheet1',// 工作表名称
 *           tableHeader:[],// 表头
 *           dataList:[],// 表数据
 *       }
 * ]
 */
const dataToExcel_list = (fileName="下载表格",tableInfo)=> {
    if(tableInfo.length <= 0){
        this.$message.info("请传入需要下载的数据信息！");
        return;
    }
    let workBook = XLSX.utils.book_new();
    for (const i in tableInfo) {
        // excel的表头和数据
        let aoa = [];
        // aoa的数据格式：[[],[],[],[]]   数组的第一个子数组可以指定为表头  根据情况而定
        let tableHeader = tableInfo[i].tableHeader;// 获取表格信息
        let dataList = tableInfo[i].dataList;// 获取表数据
        aoa = tableHeader.concat(dataList);

        let workSheet = XLSX.utils.aoa_to_sheet(aoa);

        // 把数据写到工作簿中
        let sheet = tableInfo[i].sheetName;
        XLSX.utils.book_append_sheet(workBook,workSheet,sheet)
    }
    //保存
    XLSX.writeFile(workBook, fileName + ".xlsx")

}

export { dataToExcel,dataToExcel_list }