/** 
* 自动生成文件，请勿修改 
* @name: 报销:报账单审核记录流水模块
* @description: 报销-报账单审核记录流水模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const recordApproval = { 
	/** 1 summary:报账单审核记录流水-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/recordApproval/saveOrUpd.do"), 
	/** 2 summary:批量替换审核流水	method:post */ 
	batchReplace : new APIObject("/api/recordApproval/batchReplace.do"), 
	/** 3 summary:报账单审核记录流水-批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/recordApproval/batchSaveOrUpd.do"), 
	/** 4 summary:报账单审核记录流水-分页查询	method:post */ 
	queryPage : new APIObject("/api/recordApproval/queryPage.do"), 
	/** 5 summary:报账单审核记录流水-批量删除	method:post */ 
	batchDelete : new APIObject("/api/recordApproval/batchDelete.do"), 
	/** 6 summary:报账单审核记录流水-单条删除	method:post */ 
	delete : new APIObject("/api/recordApproval/delete.do"), 
	/** 7 summary:报账单-审核报账单流水	method:post */ 
	updApprovalFlow : new APIObject("/api/recordApproval/updApprovalFlow.do"), 
	/** 8 summary:报账单审核记录流水-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/recordApproval/queryOne.do"), 
	/** 9 summary:报账单审核记录流水-查询所有	method:post */ 
	queryAll : new APIObject("/api/recordApproval/queryAll.do"), 
};
export default recordApproval;