<!--
* @program: TYH_office_automation 
* @author: ly
* @component:projectIncomeViewer 项目收款统计
* @description: 
* @create: 2021-06-30 10:59
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
            </div>
            <TcBaseTable ref="project_table" :data-source="dataSource" :view-model="true" @pageChange="handlePageChange"
                             :table-columns="tableColumns" :page-size="pageSize" :loading="loading" />
        </div>
    </div>
</template>

<script>
    import api_item_collection_info from "../../../assets/api/collectionInfo"
    import item_collection_info2 from "../../../assets/tables/item_collection_info2";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import {SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    export default {
        name: "projectIncomeViewer",
        components:{
            TcBaseTable,SearchBar,SearchOutlined,SyncOutlined
        },
        data(){
            return{
                dataSource: [],
                tableColumns:[],
                searchOptions:[
                    {value:"itemName",label:"项目名称"},
                ],
                searchData:null,
                table:null,
                loading:false,
                pageOptions:{
                    // "page":1,
                    // "limit":15,
                },
                pageSize:24
            }
        },
        methods:{
            init(){
                this.table = new item_collection_info2();
                this.table.actions = [];
                this.tableColumns = this.table.getTableColumns();
                this.loading = true;
                api_item_collection_info.queryPage.requestPOSTBothParam(this,{formParam:this.pageOptions,urlParam:{}},res=>{
                    this.loading =false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.project_table.setTotalSize(res.data.count);
                        this.$refs.project_table.setCurrentPage(1);
                    }
                });
            },
            doSearch(){
                api_item_collection_info.queryPage.requestPOSTBothParam(this,{formParam:this.pageOptions,urlParam:this.searchData},res=>{
                    this.loading =false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.project_table.setTotalSize(res.data.count);
                        this.$refs.project_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                        this.dataSource=[];
                    }
                });
            },
            refresh(){
                this.init();
            },
            handlePageChange(arg){
                api_item_collection_info.queryPage.requestPOSTBothParam(this,{formParam:arg,urlParam:this.searchData},res=>{
                    this.loading =false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.project_table.setTotalSize(res.data.count);
                    }
                    else{
                        this.$message.error(res.data.msg);
                        this.dataSource=[];
                    }
                });
            }
        },
        created() {
            this.pageOptions = {
                "page":1,
                "limit":this.pageSize
            };
            this.init();
        }
    }
</script>

<style scoped>

</style>