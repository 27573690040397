<!--
* @program: office_automation 
* @author: ly
* @component:PromotionInfo 
* @description: 
* @create: 2022-03-08 16:05
-->
<template>
    <div style="max-height: 580px;overflow: auto;display: flex;flex-wrap: wrap;">
        <div>
            <TagShow color="blue" tag="基础信息" >
                <template #content>
                    <FcForm style="width:600px" :form-columns="formColumns0" v-model:form-data="formData" :view-model="true" />
                </template>
            </TagShow>
        </div>
        <div>
            <TagShow color="blue" tag="部门填写">
                <template #content>
                    <FcForm style="width:600px" :form-columns="formColumns1" v-model:form-data="formData" :view-model="viewModel1" />
                    <a-divider style="margin: 0 0 5px 0"/>
                    <a-button v-if="!viewModel" @click="viewModel1 = !viewModel1">
                        编辑
                    </a-button>
                    <a-popconfirm
                            v-if="!viewModel1"
                            ok-text="是"
                            cancel-text="否"
                            title="确定修改后，会以当前用户作为本条意见的签名人。"
                            @confirm="saveData">
                        <a-button  type="primary">
                            提交
                        </a-button>
                    </a-popconfirm>
                </template>
            </TagShow>
            <TagShow color="blue" tag="人力资源部填写">
                <template #content>
                    <FcForm style="width:600px" :form-columns="formColumns2" v-model:form-data="formData" :view-model="viewModel2" />
                    <a-divider style="margin: 0 0 5px 0"/>
                    <a-button v-if="!viewModel"  @click="viewModel2 = !viewModel2">
                        编辑
                    </a-button>
                    <a-popconfirm
                            v-if="!viewModel2"
                            ok-text="是"
                            cancel-text="否"
                            title="确定修改后，会以当前用户作为本条意见的签名人。"
                            @confirm="saveData">
                        <a-button  type="primary">
                            提交
                        </a-button>
                    </a-popconfirm>
                </template>
            </TagShow>
            <TagShow color="blue" tag="总经办填写">
                <template #content>
                    <FcForm style="width:600px" :form-columns="formColumns3" v-model:form-data="formData" :view-model="viewModel3" />
                    <a-divider style="margin: 0 0 5px 0"/>
                    <a-button v-if="!viewModel"  @click="viewModel3 = !viewModel3">
                        编辑
                    </a-button>
                    <a-popconfirm
                            v-if="!viewModel3"
                            ok-text="是"
                            cancel-text="否"
                            title="确定修改后，会以当前用户作为本条意见的签名人。"
                            @confirm="saveData">
                        <a-button type="primary">
                            提交
                        </a-button>
                    </a-popconfirm>
                </template>
            </TagShow>
        </div>
    </div>
</template>

<script>

    import promotion_audit from "../../../../assets/tables/promotion_audit";
    import FcForm from "../../../../components/form/FcForm";
    import TagShow from "../../../_components/TagShow";
    import promotionApply from "../../../../assets/api/promotionApply";
    export default {
        name: "PromotionInfo",
        props:["value","viewModel"],
        emits:["update:value"],
        components:{FcForm,TagShow},
        watch:{
            value(){
                this.formData = {...this.value};
                this.viewModel1 = true;
                this.viewModel2 = true;
                this.viewModel3 = true;
            },
        },
        data() {
            return {
                table:new promotion_audit(),
                formColumns0:null,
                formColumns1:null,
                formColumns2:null,
                formColumns3:null,
                formColumns4:null,
                formData:null,
                viewModel1:true,
                viewModel2:true,
                viewModel3:true,
            }
        },
        methods: {
            saveData(){
                if(this.formData.departSugg !== this.value.departSugg){
                    this.formData.departSuggUser = this.$store.getters.userInfo.id;
                    this.formData.departSuggUserName = this.$store.getters.userInfo.name;
                }
                if(this.formData.hrSugg !== this.value.hrSugg){
                    this.formData.hrSuggUser = this.$store.getters.userInfo.id;
                    this.formData.hrSuggUserName = this.$store.getters.userInfo.name;
                }
                if(this.formData.gmoSugg !== this.value.gmoSugg){
                    this.formData.gmoSuggUser = this.$store.getters.userInfo.id;
                    this.formData.gmoSuggUserName = this.$store.getters.userInfo.name;
                }
                promotionApply.saveOrUpd.requestPOST(this,this.formData,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.$emit("update:value",this.formData);
                    }
                    else{
                        this.$message.error(res.data.msg);
                        this.formData = {...this.value};
                    }
                })
            },
        },
        created() {
            this.formColumns0 = this.table.getFormColumns().filter(item=>item.group === 0);
            this.formColumns1 = this.table.getFormColumns().filter(item=>item.group === 1);
            this.formColumns2 = this.table.getFormColumns().filter(item=>item.group === 2);
            this.formColumns3 = this.table.getFormColumns().filter(item=>item.group === 3);
            this.formData = {...this.value};
        }
    }
</script>

<style scoped>

</style>