/** 
* 自动生成文件，请勿修改 
* @name: 项目:生产工序管理模块============新增了一检附件上传
* @description: 项目-生产工序管理模块-增删改查(二期修改，增加字段)
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const itemTaskProcess = { 
	/** 1 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/itemTaskProcess/queryOne.do"), 
	/** 2 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/itemTaskProcess/batchDelete.do"), 
	/** 3 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/itemTaskProcess/queryAll.do"), 
	/** 4 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/itemTaskProcess/batchSaveOrUpd.do"), 
	/** 5 summary:上传一检附件===========新增接口	method:post */ 
	qcFileUpload : new APIObject("/api/itemTaskProcess/qcFileUpload.do"), 
	/** 6 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/itemTaskProcess/delete.do"), 
	/** 7 summary:保存/修改-单个生产工序	method:post */ 
	saveOrUpd : new APIObject("/api/itemTaskProcess/saveOrUpd.do"), 
	/** 8 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/itemTaskProcess/queryPage.do"), 
};
export default itemTaskProcess;