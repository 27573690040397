<!--
* @program: office_automation 
* @author: ly
* @component:detailInfo 
* @description: 
* @create: 2022-06-09 16:32
-->
<template>
    <FcForm style="width: 600px" :view-model="viewModel" :form-columns="formColumns" :form-data="record" >
        <template #action="{record}">
            <a-button type="primary" @click="saveData(record)">
                提交
            </a-button>
        </template>
    </FcForm>
</template>

<script>
    import api_item_outsourc_info from "../../../../assets/api/outSourceInfo";
    import FcForm from "../../../../components/form/FcForm";
    import item_outsourc_info from "../../../../assets/tables/item_outsourc_info";

    export default {
        name: "detailInfo",
        props:["viewModel","record"],
        components:{FcForm},
        data() {
            return {
                table: new item_outsourc_info(),
                formColumns:null,
            }
        },
        methods: {
            saveData(record){
                api_item_outsourc_info.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
        },
        created() {
            this.formColumns = this.table.getFormColumns();

        }
    }
</script>

<style scoped>

</style>