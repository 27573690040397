<!--
* @program: office_automation 
* @author: ly
* @component:SMSSetting 
* @description: 
* @create: 2022-04-22 09:30
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="init" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
            </div>
            <ArBaseTable :argument="argument" :view-model="true" :page-size="pageSize">
                <template #action="{record}">
                    <div class="action-column">
                        <a-tooltip  title="发送/不发送">
                            <a-button class="action-button" type="link" @click="enableRow(record)">
                                <SwapOutlined  style="color: green" class="pointer"/>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip v-if="record.templateName === '员工生日提醒'"  title="指定接收号码">
                            <a-button class="action-button" type="link" @click="settingPhone(record)">
                                <SettingOutlined  style="color: green" class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </ArBaseTable>
        </div>
    </div>
    <a-modal :footer="null"
             :maskClosable="true"
             title="设定号码"
             v-model:visible="visible">
        <div>当前接收号码：{{currentRecord.phone}}</div>
        <a-input :maxlength="11" style="width:200px" v-model:value="phone" placeholder="输入新号码"/>
        <a-button class="action-button" type="link" @click="savePhone">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import {SwapOutlined,SyncOutlined,SettingOutlined} from "@ant-design/icons-vue"
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import smsConf from "../../../assets/api/smsConf";
    import {isPhoneNumber} from "../../../assets/utils/general";

    export default {
        name: "SMSSetting",
        components:{
            ArBaseTable,SwapOutlined,SyncOutlined,SettingOutlined
        },
        data() {
            return {
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("签名公司","signName",ColumnType.String).setTableView(100),
                        new Column("模板","templateName",ColumnType.String).setTableView(100),
                        new Column("模板码","templateCode",ColumnType.String),
                        new Column("参数","param",ColumnType.String),
                        new Column("模板内容","templateContent",ColumnType.String).setTableView(300),
                        new Column("示例","msgSample",ColumnType.String).setTableView(300),
                        new Column("说明","describ",ColumnType.String).setTableView(100),
                        new Column("备注","remark",ColumnType.String).setTableView(100),
                        new Column("信息发送","sendFlag",ColumnType.Boolean).setTableView(80)
                        .setBoolTags(["不发送","发送"]),
                        new Column("操作","actions","actions").setTableView(60)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                pageSize:15,
                visible:false,
                currentRecord:{},
                phone:null
            }
        },
        methods: {
            init() {
                smsConf.queryAll.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            enableRow(record){
                let data = {id:record.id};
                smsConf.enDisable.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        record.sendFlag = !record.sendFlag;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            settingPhone(record){
                this.currentRecord = record;
                this.visible = true;
                this.phone = null;
            },
            savePhone(){
                if(!isPhoneNumber(this.phone)){
                    this.$message.error("请输入正确手机号码");
                    return
                }
                let data = {
                    id:this.currentRecord.id,
                    phone:this.phone
                };
                smsConf.setPhone.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.currentRecord.phone = this.phone;
                        this.$message.success(res.data.msg);
                        this.visible = false
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>