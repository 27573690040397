<!--
* @program: tyh-oa 
* @author: ly
* @description: typeEnum 
* @create: 2021-03-23 10:44
-->
<template>
    <div v-if="viewModel" style="text-align: center">
        <a-tag  :color="color">{{getTags(cellValue)}}</a-tag>
<!--        <div v-else >{{getTags(record[column.dataIndex])}} </div>-->
    </div>
    <div v-else>
        <a-select v-if="editable"  v-model:value="record[column.dataIndex]" style="width: 100%" @change="columnTrigger">
            <a-select-option v-for="option in options" :value="option" :key="option">{{getTags(option)}}</a-select-option>
        </a-select>
        <div v-else style="text-align: center">
            <a-tag   :color="color">{{getTags(cellValue)}}</a-tag>
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeEnum",
        mixins:[typeBase],
        computed:{
            options(){
                return this.column.enums;
            },
        },
        data(){
            return{
                color:"",
                defaultColor:"purple",
            }
        },
        methods:{
            getTags(option){
                let value = "";
                let index = this.column.enums.indexOf(option);
                if(this.column.enumTags){
                    if(this.column.enums.length === this.column.enumTags.length){
                        value = this.column.enumTags[index]?this.column.enumTags[index]:"无";
                    }
                    else{
                        if(index !== -1){
                            value = this.column.enumTags[index]?this.column.enumTags[index]:"无";
                        }
                        else{
                            value = this.column.enumTags[this.column.enumTags.length -1];
                        }
                    }
                }
                else{
                    value =  option?option:"无";
                }
                if(this.column.enumColors){
                    this.color = this.column.enumColors[index]?this.column.enumColors[index]:this.defaultColor;
                }
                return value;
            },
        }
    }
</script>

<style scoped>

</style>