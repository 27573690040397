<!--
* @program: office_automation 
* @author: ly
* @component:billViewer2SP 
* @description:报账审核-部门入账管理
* @create: 2022-04-26 09:55
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <div style="margin: 10px;color: black">查询范围:</div>
                <a-select v-model:value="options.departmentId"
                          style="width: 200px"
                          class="search-item"
                          @change="refresh"
                >
                    <a-select-option v-for="department in departments" :key="department.id">
                        {{ department.name }}
                    </a-select-option>
                </a-select>
                <a-select v-model:value="options.entryState"
                          style="width: 120px"
                          class="search-item"
                          @change="refresh"
                >
                    <a-select-option v-for="option in kinds" :key="option.value">
                        {{ option.label }}
                    </a-select-option>
                </a-select>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
                    <template #icon><SyncOutlined /></template>
                </a-button>
            </div>
            <TwoParts ref="TwoParts" @change="handleChange" pos="transform">
                <template #left>
                    <bill-table-view2 ref="bill_table" :loading="loading" :data-source="dataSource" :table-columns="columns"  @event="eventHandle"/>
                </template>
                <template #right>
                    <billTabs ref="local_tabs" @event="eventHandle"/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import billViewer2 from "./billViewer2";
    import api_item_finance_record from "../../../assets/api/record";
    import {SyncOutlined}  from "@ant-design/icons-vue"
    export default {
        name: "billViewer2SP",
        mixins:[billViewer2],
        components:{SyncOutlined},
        data() {
            return {
                value:"0",
                kinds:[
                    {value:"0",label:"待入账"},
                    //{value:"2",label:"拒绝入账"},
                    {value:"3",label:"撤销入账"},
                    {value:"1",label:"对公入账"},
                    {value:"4",label:"私人入账"},
                ],
                options:{
                    departmentId:null,
                    approvalState:"1",
                    submitState:"1",
                    entryState:"0"//入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                },
                departments:null,
            }
        },
        methods:{
            init(){
                this.departments = this.$store.getters.userInfo.departmentDTOList;
                this.options.departmentId = this.$store.getters.userInfo.departmentDTOList[0].id;
                this.loading = true;
                this.columns= this.table.getTableColumns();
                api_item_finance_record.queryAll.requestPOSTUrlParam(this,this.options,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            refresh(){
                api_item_finance_record.queryAll.requestPOSTUrlParam(this,this.options,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>