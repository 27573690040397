/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class contractList_item extends Table {
    constructor() {
        super();
        this.name = "contractList_item";
        this.CNName = "合同记录";
        this.columnArray = [
            new Column("项目名称","itemName",ColumnType.String,true).setTableView(150),
            new Column("合同名称","name",ColumnType.String,true).setTableView(150),
            new Column("合同金额","amount",ColumnType.Number,true).setTableView(80),
            new Column("合同类别","type",ColumnType.Enum,true).setTableView(80)
                .setEnum(["1","2"],
                    ["甲方合同","外协合同"],
                    ["Pink","MediumPurple","CornflowerBlue","PowderBlue"]),
            new Column("签订公司","sigCompanyName",ColumnType.String,true).setTableView(80),
            new Column("签订人姓名","sigUserName",ColumnType.String,true).setTableView(80),
            new Column("签订日期","sigDate",ColumnType.Date,true).setTableView(80),
        ];

    }
}