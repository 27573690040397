<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityTaskArrangement 
* @description: 安排质检工序给质检组成员
* @create: 2021-07-05 14:37
-->
<template>
    <div v-if="!viewModel">
        <a-button class="search-item" shape="circle"  @click="arrangementShow">
            <template #icon><SearchOutlined /></template>
        </a-button>
        <div v-for="(process,index) in processes" :key="index">
            <span>{{process.title}}【{{process.workRatio}}%】【{{new Date(process.startDate).toLocaleDateString()}}-{{new Date(process.endDate).toLocaleDateString()}}】:</span>
            <a-tooltip title="分配质检工序到人员">
                <a-button class="search-item" type="link"  @click="processClick(process)">
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </a-tooltip>
        </div>
        <a-modal v-model:visible="visible"
                 destroy-on-close
                 title="任务人员添加"
                 :width="800"
                 :footer="null">
            <a-button v-if="currentSum < 100" class="search-item" shape="circle"  @click="addModalShow">
                <template #icon><PlusOutlined /></template>
            </a-button>
            <span>已安排：<span style="color: red">{{currentSum}}%</span></span>
            <ArBaseTable :argument="argument" :view-model="true" :page-size="10">
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button  class="action-button"  type="link" @click="editData(record)">
                            <EditOutlined  class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <a-button  class="action-button" style="color: red"  type="link" >
                                <DeleteOutlined  class="pointer"/>
                            </a-button>
                        </a-popconfirm>
                    </div>
                </template>
            </ArBaseTable>
            <a-modal v-model:visible="visible2"
                     destroy-on-close
                     title="工序安排"
                     :footer="null">
                <div>成员</div>
                <ArBaseForm :argument="argument" :view-model="viewModel" >
                    <template #action="{record}">
                        <a-button  type="primary" @click="addMember(record)">
                            提交
                        </a-button>
                    </template>
                </ArBaseForm>
            </a-modal>
        </a-modal>
        <a-modal v-model:visible="visible3"
                 destroy-on-close
                 title="人员分配一览"
                 :width="800"
                 :footer="null">
            <ArBaseTable :argument="argument2" :view-model="true" :page-size="10"/>
        </a-modal>
    </div>
    <div v-else>
        <ArBaseTable :argument="argument2" :view-model="true" :page-size="10"/>
    </div>

</template>

<script>
    import api_item_qc_process from "../../../assets/api/qcProcess";
    // import api_item_qc_group_member from "../../../assets/api/qcGroupMember"
    import api_item_task_process_employee from "../../../assets/api/processEmployee"
    import {PlusOutlined,SearchOutlined,DeleteOutlined,EditOutlined} from "@ant-design/icons-vue"
    import item_task_process_employee2 from "../../../assets/tables/item_task_process_employee2";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import ArBaseForm from "../../../components/form/ArBaseForm";

    /**
     "content": "string",
     "personType": "string",
     "processId": "string",
     "processType": "2",
     "remarks": "string",
     "userId": "string",
     "workRatio": 0
     */
    export default {
        name: "qualityTaskArrangement",
        components:{
            PlusOutlined,SearchOutlined,ArBaseForm,DeleteOutlined,EditOutlined,ArBaseTable
        },
        props:{
            record:{required:true},//当前质检申请的record
            viewModel:{required:true},
        },
        computed:{
            status(){
                return "finish";
            },
            currentSum(){
                let sum = 0;
                for(let i in this.argument.dataSource){
                    sum += this.argument.dataSource[i].workRatio
                }
                return sum;
            }
        },
        data(){
            return{
                disabled:false,
                processes:[],
                members:[],
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态

                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                table:new item_task_process_employee2(),
                currentProcess:null,
                visible:false,
                visible2:false,

                argument2:{//查看整体的任务分配情况
                    dataSource:[],//提供table的DataSource
                    tableColumns:[
                        new Column("人员类别","personType",ColumnType.Number,false).setTableView(80)
                            .setEnum(["1","2","3"],["内部员工","外协单位","外协人员"],["green","purple","purple"]),
                        new Column("工序名称","processTitle",ColumnType.String,false).setTableView(100),
                        new Column("工序内容","content",ColumnType.String,false).setTableView(100),
                        new Column("分配人员","userName",ColumnType.String,false).setTableView(100),
                        new Column("工序占比","workRatio",ColumnType.Number,false)
                            .setNumberRange(0,100).setTableView(100)
                            .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                        new Column("备注","remarks",ColumnType.String,false).setTableView(100)
                    ], //提供table的字-段集 this.table.getTableColumns()

                    loading:false,//table的loading状态
                },
                visible3:false
            }
        },
        methods:{
            init(){
                let data={applyId:this.record.id};
                this.argument.tableColumns = this.table.getTableColumns();
                this.argument.formColumns = this.table.getFormColumns();
                api_item_qc_process.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.processes = res.data.data;
                    }
                    else{
                        this.processes =[];
                    }
                });
                let data2 = {
                    groupId:this.record.qcGroupId
                };
                // api_item_qc_group_member.queryAll.requestPOSTUrlParam(this,data2,res=>{
                //     if(res.data.flag){
                //         this.members = [];
                //         for(let i in res.data.data){
                //             this.members.push({
                //                 id:res.data.data[i].userId,
                //                 name:res.data.data[i].userName,
                //                 personType:res.data.data[i].userType
                //                 });
                //         }
                //         this.table._columnArray.filter(column=>{return column.dataIndex === "userId"})[0]
                //             .setSelectorObject("userName",this.members)
                //     }
                //     else{
                //         this.members =[];
                //     }
                // });
            },
            getStatus(){
                return this.status;
            },
            processClick(process){
                this.currentProcess = process;
                this.visible = true;
                let data = {
                    "processId": this.currentProcess.id
                };
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            arrangementShow(){
                let data = {
                    itemId:this.record.itemId,
                    processType:"2"
                };
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument2.dataSource = res.data.data;
                        this.visible3 = true;
                    }
                    else{
                        this.argument2.dataSource = [];
                    }
                })
            },
            deleteData(index,record){
                let data= {id:record.id};
                api_item_task_process_employee.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1)
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editData(record){
                this.argument.formData = record;
                this.visible2 = true;
            },
            addMember(record){
            /*
                 "content": "string",
                 "personType": "string",
                 "processId": "string",
                 "processType": "2",
                 "remarks": "string",
                 "userId": "string",
                 "workRatio": 0
             */
            record.processId = this.currentProcess.id;
            record.personType = this.members.filter(member=>{return member.id === record.userId})[0].personType;
            api_item_task_process_employee.saveOrUpd.requestPOST(this,record,res=>{
                if(res.data.flag){
                    let data = {
                        "processId": this.currentProcess.id
                    };
                    api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                        if(res.data.flag){
                            this.argument.dataSource = res.data.data;
                            this.visible2 = false;
                        }
                        else{
                            this.argument.dataSource = [];
                        }
                    })
                }
                else{
                    this.$message.error(res.data.msg);
                }
            })

            },
            addModalShow(){
                this.visible2 = true;
                this.argument.formData = this.table.getFormData();
                this.argument.formData.startDate = this.currentProcess.startDate;
                this.argument.formData.endDate = this.currentProcess.endDate;
            }
        },
        created() {
            if(this.viewModel){
                this.arrangementShow()
            }
            else{
                this.init();
            }

        }
    }
</script>

<style scoped>

</style>