/**
 * @description：每年度期初余额记录
 * @author:rzl
 * @date:2022/7/25 14:30
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class yearBalance_item extends Table {
    constructor() {
        super();
        this.name = "yearBalance_item";
        this.CNName = "每年度期初余额记录";
        this.columnArray = [
            new Column("主键", "id", ColumnType.String, false).setVisible(false),
            new Column("年度", "year", ColumnType.String, true).setTableView(100),
            new Column("期初余额（￥）", "balance", ColumnType.Number, true).setTableView(100),

        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(100)];
    }
}