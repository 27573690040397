/** 
* 自动生成文件，请勿修改 
* @name: 站内消息：站内消息-用户关系管理
* @description: 站内消息：站内消息-用户关系管理
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const messageUser = { 
	/** 1 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/messageUser/batchDelete.do"), 
	/** 2 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/messageUser/delete.do"), 
	/** 3 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/messageUser/queryOne.do"), 
	/** 4 summary:批量标记为已读	method:post */ 
	batchRead : new APIObject("/api/messageUser/batchRead.do"), 
	/** 5 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/messageUser/queryAll.do"), 
	/** 6 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/messageUser/saveOrUpd.do"), 
	/** 7 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/messageUser/queryPage.do"), 
};
export default messageUser;