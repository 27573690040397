<!--
* @description：项目设计书审核
* @author:rzl
* @date:2022/7/12 10:08
-->
<template>
    <div class="oa-content">
        <div class="oa-window" >
          <!-- 搜索栏 start -->
          <project-doc-tool-bar ref="projectDocToolBar" @event="eventHandle"/>
          <!-- 搜索栏 end -->

            <TwoParts ref="TwoParts"  :trans="[60,50]" :enable-change="false" >
                <template #left>
                    <ArBaseTable  ref="project_table" :argument="argument" :page-size="pageSize" :view-model="true" >
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button type="link" @click="showDetail(index,record)">
                                    <template #icon><Show title="查看"/></template>
                                </a-button>
                              <a-button v-if="record.approveState == '0'" type="link" @click="auditDetail(index,record)">
                                <template #icon><Audit title="审核"/></template>
                              </a-button>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
              <template #right>
                <a-page-header v-if="visible" style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;" title="查看详情"/>
                <ArForm v-if="visible" :argument="formArg" :view-model="viewModel" >

                </ArForm>
                <div v-else style="font-size: 40px;margin: 20vh" class="non-select">
                  欢迎使用{{$sysName}}
                </div>
                <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
                  版本：{{$version}}
                </div>
              </template>
            </TwoParts>
        </div>
    </div>

  <!-- 审核信息 -->
  <audit-tk ref="audit_tk" @event="eventHandle"/>
</template>

<script>
    import TwoParts from "../../_components/TwoParts";
    import Icons from "../../common/Icons";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import ArForm from "../../../components/form/ArForm";

    import projectDesignBookAudit_item from "./config/projectDesignBookAudit_item";
    import ItemDoc from "../../../assets/api/ItemDoc";
    import projectDocToolBar from "./components/projectDocToolBar";
    import auditTk from "./components/auditTk";
    export default {
        name: "projectDesignBookAudit",
        mixins:[Icons],
        components:{
            TwoParts,ArBaseTable,projectDocToolBar,ArForm,auditTk
        },
        data(){
            return{
                table:new projectDesignBookAudit_item(),
                searchData:null,
                searchOptions:[],
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                    currentIndex:null //当前选中的条目
                },
                formArg:{
                  formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                  formData:null,//提供Form的formData
                },
                pageSize:15,
                viewModel:true,
                visible:false,
            }
        },
      created() {
        this.argument.tableColumns = this.table.getTableColumns();
        this.formData = this.table.getFormData();
        this.init()
      },
        methods:{
            init(){
              this.formArg.formColumns = this.table.getFormColumns();
                this.argument.loading = true;
                let parms = {
                  approveState:"",
                }
                ItemDoc.queryAll.requestPOST(this,parms,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
          /** 回调方法  **/
          eventHandle({type,arg}){
            switch (type) {
              case this.$eventTags.search:{
                let parms = this.$refs.projectDocToolBar.getParms();
                this.argument.loading = true;
                ItemDoc.queryAll.requestPOST(this,parms,res=>{
                  this.argument.loading = false;
                  if(res.data.flag){
                    this.argument.dataSource = res.data.data;
                  }
                  else{
                    this.argument.dataSource = []
                  }
                })
                break;
              }
              case this.$eventTags.refresh:{
                this.init();
                break;
              }
              case this.$eventTags.audit:{
                ItemDoc.audit.requestPOSTUrlParam(this,arg,res=>{
                  if(res.data.flag){
                    this.$message.success(res.data.msg);
                    this.$refs.audit_tk.closeAudit();
                    this.init();
                  }
                  else{
                    this.$message.error(res.data.msg);
                  }
                })
                break;
              }

            }
          },
            showDetail(index,record){
                this.visible = true;
                this.viewModel = true;
                this.formArg.formData = record ;
            },
          auditDetail(index,arg){
            this.$refs.audit_tk.openAudit(arg.id);
          },


        },
    }
</script>

<style scoped>

</style>