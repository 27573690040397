/**
 * 成果红线
 * @description：
 * @author:rzl
 * @date:2022/6/6 9:39
 **/
import APIObject from "../../../../assets/utils/APIObject";
const api={
    queryPage:new APIObject("/api/achieveGeom/queryPage.do"),// 查询-分页
    batchDelete:new APIObject("/api/achieveGeom/batchDelete.do"),// 删除-批量
    saveOrUpd:new APIObject("/api/achieveGeom/saveOrUpd.do"),// 保存/修改-单条
    queryOne:new APIObject("/api/achieveGeom/queryOne.do"),// 查询-单条
    delete:new APIObject("/api/achieveGeom/delete.do"),// 删除-单条
    queryAll:new APIObject("/api/achieveGeom/queryAll.do"),//查询-所有数据

};
export default api;