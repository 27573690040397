<!--
* @program: office_automation 
* @author: ly
* @component:AccountInfo
* @description: 
* @create: 2022-04-11 11:23
-->
<template>
    <a-spin :spinning="loading" >
        <div v-if="department" style="font-size: 16px">起始绩效：{{department.balance}} 剩余绩效合计：{{sum}}</div>
        <div v-if="department" style="display: flex">
            <a-tabs v-model:activeKey="activeKey" >
                <a-tab-pane class="center-flex" key="first" :tab="'任务合计：'+num1.toFixed(2)" >
                        <TcBaseTable  :table-columns="tc1" :title="true"
                                     :data-source="department.taskList" :view-model="true" :page-size="15" >
                            <template #title>
                                统计说明：合计：∑收入（预下绩效 - 作业绩效 - 负责人绩效）- ∑支出（预下绩效）
                            </template>
                        </TcBaseTable>
                </a-tab-pane>
                <a-tab-pane class="center-flex"  key="second" :tab="'部门间支付/收入合计：' + num2.toFixed(2)" >
                    <TcBaseTable style="max-width:1000px"  :table-columns="tc2" :title="true"
                                 :data-source="incomePayList" :view-model="true" :page-size="15" >
                        <template #title>
                            <a-button  type="primary"  @click="addData">
                                发起部门转账
                            </a-button>
                            <a-button style="margin-left: 20px"  type="primary"  @click="refresh">
                                刷新
                            </a-button>
                        </template>
                        <template #action="{index,record}">
                            <div class="action-column">
                                <div v-if="record.child">
                                    <span>转往部门：{{record.child.departName}}</span>
                                </div>
                                <div v-if="record.parent">
                                    <span>来源部门：{{record.parent.departName}}</span>
                                </div>
                                <a-popconfirm
                                        v-if="record.parent"
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="退回这笔转账?"
                                        @confirm="deleteData(index,record)">
                                    <a-tooltip title="退回这笔转账">
                                        <RollbackOutlined   style="color: red" class="pointer"/>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                        </template>
                    </TcBaseTable>
                </a-tab-pane>
                <a-tab-pane  key="third" :tab="'部门内消耗合计：'+num3.toFixed(2)" >
                    <div>只统计了【部门活动费】</div>
                    <TcBaseTable style="max-width:1000px;margin: 0 20%"  :table-columns="tc3"
                                 :data-source="department.flowList" :view-model="true" :page-size="15" />
                </a-tab-pane>
            </a-tabs>
        </div>
        <div v-else>部门数据出错，请联系管理员</div>
    </a-spin>
    <a-modal v-model:visible="visible"
             :footer="null"
             title="部门转账">
        <DepartmentPicker v-model:value="selectedId"/>
        <FcForm :form-columns="fc" :form-data="formData" :view-model="false" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-modal>
</template>

<script>
    import TcBaseTable from "../../../components/table/TcBaseTableFilter";
    import DepIncomePay from "../../../assets/tables/DepIncomePay";
    import api_department from "../../../assets/api/department";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import DepartmentPicker from "../../../components/UserPicker/DepartmentPicker";
    import FcForm from "../../../components/form/FcForm";
    import depIncomePay from "../../../assets/api/depIncomePay";
    import {RollbackOutlined} from "@ant-design/icons-vue"
    export default {
        name: "AccountInfo",
        props:["departmentId","viewModel"],
        components:{
            DepartmentPicker,
            TcBaseTable,FcForm,
            RollbackOutlined
        },
        watch:{
            departmentId(){
                this.init()
            }
        },
        computed:{
            sum(){
                if(this.department){
                    return (this.department.balance + this.num1 +  this.num2 + this.num3).toFixed(2)
                }
                else{
                    return  0
                }
            },
            num1(){//任务合计
                if(this.department){
                    let sum = 0;
                    for(let i in this.department.taskList){
                        if(this.department.taskList[i]._incomeType === "1"){ //类型：1：收入；2：支出
                            sum += this.department.taskList[i].money;
                            sum -= this.department.taskList[i].depManagExp;
                            sum -= this.department.taskList[i].leaderPercent;
                        }
                        else if(this.department.taskList[i]._incomeType === "2"){
                            sum -= this.department.taskList[i].money;
                        }
                    }
                    return sum;
                }
                else{
                    return  0
                }
            },
            num2(){//部门间转账统计
                if(this.incomePayList){
                    let sum = 0;
                    for(let i in this.incomePayList){
                        if(this.incomePayList[i].type === "1"){ //类型：1：收入；2：支出
                            sum += this.incomePayList[i].amount;
                        }
                        else if(this.incomePayList[i].type === "2"){
                            sum -= this.incomePayList[i].amount;
                        }
                    }
                    return sum;
                }
                else{
                    return 0
                }
            },
            num3(){
                if(this.department.flowList && this.department.flowList.length>0){
                    let sum = 0;
                    for(let item of this.department.flowList){
                        sum += item.applyAmount
                    }
                    return sum;
                }
                else{
                    return 0
                }
            }
        },
        data() {
            return {
                activeKey:"first",
                tc1:[
                    new Column("序号","_index",ColumnType.Index).setTableView(60),
                    new Column("类型","_incomeType",ColumnType.Enum).setTableView(60)
                        .setEnum(["1","2"],["收入","支出"],["blue","red"]),
                    new Column("任务类型","pid",ColumnType.Enum).setTableView(80)
                        .setEnum(["0"],["主任务","子任务"],["green","red"]),
                    new Column("项目","itemName",ColumnType.String).setTableView(200),
                    new Column("任务标题","taskTitle",ColumnType.String).setTableView(200),
                    new Column("预下绩效","money",ColumnType.Number).setTableView(80),
                    new Column("作业绩效", "depManagExp", ColumnType.Number).setTableView(100),
                    new Column("负责人绩效", "leaderPercent", ColumnType.Number).setTableView(100),
                    //new Column("拟下绩效","confirmMoney",ColumnType.Number).setTableView(80),
                    new Column("时间","createTime",ColumnType.Date).setTableView(80),
                ],
                tc2:new DepIncomePay().getTableColumns(),
                tc3:[
                    new Column("支出时间","flowTime",ColumnType.Date,true).setTableView(80),
                    new Column("发票金额(元)","invoiceAmount",ColumnType.Number,true).setTableView(100),
                    new Column("报销金额(元)","applyAmount",ColumnType.Number,true).setTableView(100),
                    new Column("报销凭证","invoiceUrl",ColumnType.PreViewFile,true).setTableView(100),
                    new Column("备注","remarks",ColumnType.String,true).setTableView(200),
                ],
                fc:new DepIncomePay().getFormColumns(),
                formData:null,
                department:null,
                visible:false,
                selectedId:null,
                incomePayList:[],
                loading:false
            }
        },
        methods: {
            init() {
                this.loading = true;
                this.department = null;
                let data = {
                    id:this.departmentId
                };
                api_department.queryInfoId.requestPOSTUrlParam(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.department = res.data.data;
                        for(let i in this.department.taskList){//处理项目任务的money是收入或支出
                            this.department.taskList[i]._incomeType = "1";
                            let children = this.department.taskList[i].children;
                            if(children && children.length>0){
                                for(let j in this.department.taskList[i].children){
                                    let child = this.department.taskList[i].children[j];
                                    child._incomeType = "2";
                                    child.depManagExp = 0;
                                    child.leaderPercent = 0;
                                    this.department.taskList.push(child);
                                }
                            }
                        }
                        this.department.taskList = this.department.taskList.sort((a, b) => {
                            return b.createTime - a.createTime;
                        })
                    }
                    else{
                        this.department = null;
                    }
                });
                this.refresh()
            },
            addData(){
                this.visible = true;
                this.selectedId = null;
                this.formData = new DepIncomePay().getFormData();
            },
            saveData(record){
                if(!this.selectedId){
                    this.$message.error("请选择转账部门");
                    return
                }
                record.departId = this.departmentId;
                record.recDepartId = this.selectedId;
                depIncomePay.save.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.refresh();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                let data = {
                    "departId": this.departmentId,
                };
                depIncomePay.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.incomePayList = res.data.data.sort((a, b) => {
                            return b.happenDate - a.happenDate;
                        });
                    }
                    else{
                        this.incomePayList = [];
                    }
                })
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                depIncomePay.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.incomePayList.splice(index,1);
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            }

        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>