    <!--
* @program: tyh-oa 
* @author: ly
* @description: subGroupMenu 
* @create: 2021-03-22 16:56
-->
<template>
    <a-sub-menu  :key="groupMenus.id" >
        <template #title>
            <div v-is="icon" />
            <span>{{groupMenus.title}}</span>
        </template>
        <sub-menu v-for="menus in menuList" :menus="menus" :key="menus.id" />
    </a-sub-menu>
</template>

<script>
    import subMenu from "./subMenu";
    import {AppstoreTwoTone,ControlTwoTone,DatabaseTwoTone,GoldTwoTone} from "@ant-design/icons-vue"
    export default {
        name: "subGroupMenu",
        props:["groupMenus","index"],
        components:{
            subMenu,AppstoreTwoTone,ControlTwoTone,DatabaseTwoTone,GoldTwoTone
        },
        data(){
            return{
                icons:[
                    "AppstoreTwoTone",
                    "ControlTwoTone",
                    "DatabaseTwoTone",
                    "GoldTwoTone"
                ],
            }
        },
        computed:{
            menuList(){
                return this.groupMenus.children.filter(menu=>{
                    return menu.enable ===true;
                });
            },
            icon(){
                let icon = this.icons[this.index];
                if(icon){
                    return icon;
                }
                else{
                    return this.icons[0]
                }
            }
        }
    }
</script>

<style scoped>

</style>