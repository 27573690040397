<!--
* @program: office_automation 
* @author: ly
* @component:workCalendar 
* @description: 
* @create: 2022-01-10 17:45
completeDate: null
content: "撒旦发射点"
createTime: 1640742107015
createUser: "2"
deleted: false
departmentName: "大数据应用研究中心"
describe: null
endDate: 1703174400000
id: "2f2a7586-e203-44d5-93c4-ada8a130245b"
itemName: "云岩区社区管理争议界线勘测定界项目"
personType: "1"
processId: "7b174014-643b-4fc0-aabd-52c490e789c2"
processRatio: 50
processTitle: "工序一"
processType: "1"
remarks: null
startDate: 1640707200000
state: null
taskTitle: "云岩区社区管理争议界线勘测定界项目"
updateTime: null
updateUser: null
userId: "80"
userName: "顾杰"
workRatio: 100
-->
<template>
    <a-spin :spinning="spinning">
        <a-calendar  v-if="userId"  mode="month" :locale="locale">
            <template #dateCellRender="{ current: value }">
                <ul class="events">
                    <li v-for="item in getWorkList(value)" :key="item.id">
                        <a-badge @click="showWork(value)" status="success" :text="item.taskTitle" />
                    </li>
                </ul>
            </template>
            <template #monthCellRender="{ current: value }">
                <div  class="notes-month">
                    <ul class="events">
                        <li v-for="item in getMonthWorkList(value)" :key="item.id">
                            <a-badge @click="showMonthWork(value)" status="success" :text="item.taskTitle" />
                        </li>
                    </ul>
                </div>
            </template>
        </a-calendar>
        <div v-else>
            <div style="height: 100%;overflow: hidden">
                <div style="font-size: 60px;margin-top: 300px" class="non-select">
                    请选择想要查看工作的员工
                </div>
                <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
                    版本：{{$version}}
                </div>
            </div>
        </div>
    </a-spin>
    <a-drawer v-model:visible="visible"
              height="700px"
              placement="bottom"
             :title="null">
        <ArBaseTable :argument="argument" :view-model="true" :page-size="15"/>
        <div v-if="yearMonthSum">
            <span>按月统计绩效完成情况：</span>
            {{yearMonthSumStr}}
        </div>
    </a-drawer>
</template>

<script>
    import processEmployee from "../../../assets/api/processEmployee";
    import moment from "moment";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    export default {
        name: "workCalendar",
        props:["userId"],
        components:{
            ArBaseTable
        },
        computed:{
            yearMonthSumStr(){
                let str = "";
                for(let i in this.yearMonthSum){
                    str += this.yearMonthSum[i].yearMonth+"："+ this.yearMonthSum[i].money.toFixed(2) + "  ";
                }
                return str;
            }
        },
        watch:{
           userId(){
               this.init()
           }
        },
        data(){
            return{
                locale:{
                    "lang": {
                        "month": "月",
                        "year": "年",
                    },
                    "dateFormat": "YYYY-MM-DD",
                    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
                    "weekFormat": "YYYY-wo",
                    "monthFormat": "YYYY-MM"
                },
                spinning:false,
                workList:[],
                visible:false,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("项目任务","taskTitle",ColumnType.String).setTableView(200),
                        new Column("项目工序","processTitle",ColumnType.String).setTableView(200),
                        new Column("本工序占比","workRatio",ColumnType.Number).setTableView(50)
                            .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                        new Column("工序绩效","money",ColumnType.Number).setTableView(50),
                        new Column("完成进度","workRecs2",ColumnType.String).setTableView(400),
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                yearMonthSum:null,
            }
        },
        methods:{
            init(){
                if(this.userId){
                    let data = {
                        userId :this.userId,
                    };
                    this.spinning = true;
                    processEmployee.queryAll.requestPOST(this,data,res=>{
                        this.spinning = false;
                        if(res.data.flag){
                            this.workList = res.data.data;
                            for(let i in this.workList){
                                this.workList[i].processTitle = this.workList[i].processTitle +"(权重："+ this.workList[i].processRatio + ")";
                                this.workList[i].workRecs2 = "";
                                for(let j in this.workList[i].workRecs){
                                    let {yearMonth,money,workRatio} = this.workList[i].workRecs[j];
                                    this.workList[i].workRecs2 += yearMonth+"："+ workRatio +"%(绩效："+ money +") ";
                                }
                            }
                        }
                        else{
                            this.workList = []
                        }
                    })
                }
            },
            getFullWorkList(value){//用于展示详情，把所有满足条件的都列出
                let works = [];
                for(let i in this.workList){
                    if(value.isBetween(moment(this.workList[i].startDate),moment(this.workList[i].endDate).add(1, 'day'))  ){
                        works.push(this.workList[i])
                    }
                }
                return works;
            },
            getFullMonthWorkList(value){//用于展示详情，把所有满足条件的都列出
                let works = [];
                for(let i in this.workList){
                    let startMonth = moment(moment(this.workList[i].startDate).format('YYYY-MM'));
                    let endDate = this.workList[i].completeDate?this.workList[i].completeDate:this.workList[i].endDate;
                    let endMonth = moment(endDate);
                    if(value.isBetween(startMonth,endMonth.add(1, 'month'))){
                        works.push(this.workList[i])
                    }
                }
                return works;
            },
            getWorkList(value){//用于在日历上展示，相同项目任务只显示一个
                let works = [];
                for(let i in this.workList){
                    let endDate = this.workList[i].completeDate?this.workList[i].completeDate:this.workList[i].endDate;
                    if(value.isBetween(moment(this.workList[i].startDate),moment(endDate).add(1, 'day'))  ){
                        works.push(this.workList[i])
                    }
                }
                let tasks =[];
                for(let i in works){
                    let arr = tasks.filter(item=>item.taskTitle === works[i].taskTitle);
                    if(arr.length === 0){
                        tasks.push(works[i])
                    }
                }
                return tasks;
            },
            getMonthWorkList(value){////用于在日历上展示，相同项目任务只显示一个
                let works = [];
                for(let i in this.workList){
                    let startMonth = moment(moment(this.workList[i].startDate).format('YYYY-MM'));
                    let endMonth = moment(moment(this.workList[i].endDate).format('YYYY-MM'));
                    if(value.isBetween(startMonth,endMonth.add(1, 'month'))){
                        works.push(this.workList[i])
                    }
                }
                let tasks =[];
                for(let i in works){
                    let arr = tasks.filter(item=>item.taskTitle === works[i].taskTitle);
                    if(arr.length === 0){
                        tasks.push(works[i])
                    }
                }
                return tasks;
            },
            showWork(value){
                let works = this.getFullWorkList(value);
                if(works.length>0){
                    this.visible = true;
                    this.argument.dataSource = works;
                }
                this.yearMonthSum = null;
            },
            showMonthWork(value){
                let works = this.getFullMonthWorkList(value);
                if(works.length>0){
                    this.visible = true;
                    this.argument.dataSource = works;
                }
                this.yearMonthSum = [];
                for(let i in works){
                    for(let j in works[i].workRecs){
                        let {yearMonth,money} = works[i].workRecs[j];
                        let arr = this.yearMonthSum.filter(item=>{return item.yearMonth === yearMonth});
                        if(arr.length >0){
                            arr[0].money += money;
                        }
                        else{
                            this.yearMonthSum.push({yearMonth,money});
                        }
                    }
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>