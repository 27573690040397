

/**
 * @program: TYH_office_automation
 * @author: ly
 * @description: personal_task  个人质检任务
 * @create: 2021-07-01 17:05
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";
export default class personal_task extends Table{
    constructor() {
        super();
        this.name = "personal_task";
        this.CNName = "个人项目工序";
        this.columnArray = [
            new Column("项目","itemName",ColumnType.String,false).setTableView(200),
            new Column("质检工序","processTitle",ColumnType.String,false).setTableView(150),
            new Column("工作内容","content",ColumnType.String,false),
            new Column("工序占比","workRatio",ColumnType.Number,false).setNumberRange(0,100).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("任务开始时间","startDate",ColumnType.Date),
            new Column("预计完成时间","endDate",ColumnType.Date).setTableView(100),
            new Column("实际完成时间","completeDate",ColumnType.Date,false),
            new Column("完成状态","state",ColumnType.Enum,false)
                .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"]),
            new Column("完成情况说明","describe",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}