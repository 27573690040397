<!--
* @program: tyh-oa 
* @author: ly
* @component:typeMonth 选择月份
* @description: 
* @create: 2021-04-15 15:47
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center">{{presentValue}}</div>
    </div>
    <div v-else>
        <a-month-picker placeholder="请选择月份" style="width: 100%" v-model:value="date" :format="dateFormat" @change="columnTrigger"/>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import moment from "moment";
    export default {
        name: "typeMonth",
        mixins:[typeBase],
        watch:{
            date(){
                if(this.date instanceof moment)
                    this.record[this.column.dataIndex] = this.date.format(this.dateFormat);
            },
            record(){
                this.date = this.record[this.column.dataIndex]?moment(this.record[this.column.dataIndex]):null;
            }
        },
        data(){
            return{
                dateFormat: 'YYYY-MM',
                date:null,
            }
        },
        computed:{
            presentValue(){
                let date = this.cellValue;
                if(date){
                    return moment(date).format(this.dateFormat);
                }
                return "无";
            }
        },
        // mounted() {
        //     this.date = this.record[this.column.dataIndex]?moment(this.record[this.column.dataIndex]):null;
        // }

    }
</script>

<style scoped>

</style>