/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";
import api_sys_department from "../../../../assets/api/department";
import api_item_info from "../../../../assets/api/itemInfo";

export default class departPayment_item extends Table {
    constructor() {
        super();
        this.name = "departPayment_item";
        this.CNName = "项目兑付：拨付给部门记录";
        this.columnArray = [
           /* new Column("项目名称","itemId",ColumnType.IdReplace2,false).setTableView(200)
                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                        "startTime": "", "tenderWay": ""
                    }}),*/
            new Column("分配部门","departName",ColumnType.String,false).setTableView(100),
            new Column("兑付金额","payAmount",ColumnType.Number,true).setTableView(80),
            new Column("兑付日期","payDate",ColumnType.Date,true).setTableView(80),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(100)];
    }
}