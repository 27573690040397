/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_finance_record  报账单表类型2：用于审核显示
 * @create: 2021-04-12 09:58
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_finance_record_2 extends Table{
    constructor() {
        super();
        this.name = "item_finance_record_2";
        this.CNName = "报销单";
        this.columnArray = [
            new Column("用户名","userName",ColumnType.String,false).setTableView(80),
            new Column("账单标题","title",ColumnType.String,true).setTableView(100),
            new Column("账单描述","content",ColumnType.String,true),
            new Column("账单归属时间","recordYearMonth",ColumnType.Month,true).setTableView(100),
            new Column("提交","submitState",ColumnType.String,false)
                .setEnum(["0","1"],["未提交","已提交"],["blue","green"]).setDefaultValue("0"),
            new Column("审核","approvalState",ColumnType.String,false)
                .setEnum(["0","1"],["未完成","已完成"],["pink","green"]).setDefaultValue("0"),
            new Column("入账状态","entryState",ColumnType.String,false)
                .setEnum(["0","1","4","2","3"],["未入账","对公入账","私人入账","拒绝入账","撤销入账"],["pink","green","purple","red","orange"]),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(100)];
    }
}