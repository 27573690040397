/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_task  任务管理-部门任务使用
 * 与原版相比 隐去 分配部门 字段 调整table显示字段
 * @create: 2021-04-16 16:42
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import userInfo from "../api/userInfo";
export default class item_task2 extends Table{
    constructor() {
        super();
        this.name = "item_task2";
        this.CNName = "任务管理";
        this.columnArray = [
            new Column("任务类型","pid",ColumnType.Enum,false).setTableView(100)
                .setEnum(["0"],["主任务","子任务"],["green","red"]),
            new Column("任务标题","taskTitle",ColumnType.String,true).setTableView(200),
            new Column("是否监管项目","monitorFlag",ColumnType.Enum,true).setTableView(120)
                .setEnum([true,false],["是","否"],["red","#79CDCD"]).setDefaultValue(false),
            new Column("任务内容","content",ColumnType.String,true),
            new Column("进度","completeRatio",ColumnType.Number).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            // new Column("分配部门","departmentId",ColumnType.IdReplace,true).setTableView(100)
            //     .setIdReplaceObject("departmentName",{api:api_sys_department.queryAll,tag:"queryText"}),
            new Column("任务负责人","taskLeader",ColumnType.IdReplace,true).setTableView(120)
                .setIdReplaceObject("taskLeaderName",{api:userInfo.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("任务开始时间","startDate",ColumnType.Date,true),
            new Column("预计完成时间","endDate",ColumnType.Date,true).setTableView(100),
            new Column("实际完成时间","completeDate",ColumnType.Date,false),
            new Column("完成状态","state",ColumnType.Enum,false)
                .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"]),
            new Column("完成情况说明","describe",ColumnType.String,false).setVisible(false),
            new Column("任务分配绩效","money",ColumnType.Number,false).setDefaultValue(0).setTableView(100),
            new Column("作业绩效","depManagExp",ColumnType.Number,false),
            new Column("负责人绩效","leaderPercent",ColumnType.Number,false),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(120)];
    }
}