/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_contract_info
 * 合同管理使用
 * @create: 2021-06-09 09:42
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";
import api_item_party_info from "../api/partyInfo";
import api_item_outsourc_info from "../api/outSourceInfo";
import api_sys_user_info from "../api/userInfo";
import {companyIds,companyNames,companyColors} from "../system/companies";
import contractType from "../../view/projectManager/contractType";

export default class item_contract_info2 extends Table{
    constructor() {
        super();
        this.name = "item_contract_info2";
        this.CNName = "合同信息表";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String).setVisible(false),
            new Column("所属项目","itemName",ColumnType.String,false).setTableView(250), //
            new Column("合同类别","type",ColumnType.Enum,false).setTableView(100)
                .setEnum(contractType.value,contractType.label,contractType.color) //合同类别：1：甲方合同；2：外协合同
                .setTriggerOtherColumn("1",
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="associatedKey"})[0]
                            .setIdReplaceObject("associatedName",{api:api_item_party_info.queryAll,tag:"queryText"})
                            .setTitle("甲方");

                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="associatedKey"})[0]
                            .setIdReplaceObject("associatedName",{api:api_item_outsourc_info.queryAll,tag:"queryText"})
                            .setTitle("外协单位");

                    }),
            new Column("签署方","associatedKey",ColumnType.IdReplace,true)
                .setIdReplaceObject("associatedName",{api:api_item_party_info.queryAll,tag:"queryText"}),
            new Column("合同名称","name",ColumnType.String,true).setTableView(250),
            new Column("合同编号","number",ColumnType.String,true).setTableView(100),

            /*new Column("收款责任人","collectUser",ColumnType.IdReplace,true).setGroup(1).setTableView(120)
                .setIdReplaceObject("collectUserName",{api:api_sys_user_info.queryAll,tag:"queryText"}),*/

            new Column("收款责任人","collectUser",ColumnType.String,true).setGroup(1).setTableView(120),

            new Column("合同金额","amount",ColumnType.Number,true).setTableView(100),
            new Column("已付金额/已收金额","colPayAmount",ColumnType.Number,false).setTableView(200),
            new Column("未付金额/未收金额","colPayAmountNo",ColumnType.Number,false).setTableView(200),
            new Column("合同审核人","auditUser",ColumnType.IdReplace,true)
                .setIdReplaceObject("auditUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("合同回收人","receiveUser",ColumnType.IdReplace,true)
                .setIdReplaceObject("receiveUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("签订公司","sigCompany",ColumnType.String,true)
                .setEnum(companyIds, companyNames,companyColors),
            new Column("签订时间","sigDate",ColumnType.Date,true).setTableView(100),
            new Column("签订人","sigUser",ColumnType.IdReplace,true).setTableView(100)
                .setIdReplaceObject("sigUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("合同递交人","submitUser",ColumnType.IdReplace,true)
                .setIdReplaceObject("submitUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("签收时间","signDate",ColumnType.Date,true).setTableView(100),
            new Column("签收状态","signState",ColumnType.Enum,true)
                .setEnum(["0","1"],["未签收","已签收"],["red","green"]), //办公人事部签收状态:0:未签收；1：已签收
            new Column("签收人","signUser",ColumnType.IdReplace,true)
                .setIdReplaceObject("signUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("盖章情况","sealFlag",ColumnType.Enum,true)
                .setEnum(["0","1"],["未盖章","已盖章"],["red","green"]), //合同盖章情况：0：未盖章；1：已盖章
            new Column("合同文件","fileUrl",ColumnType.PreViewFile,true)
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80).setPosition("right")];
    }
}