/**
 * @program: tyh-oa
 * @author: ssr
 * @description: sys_menu  菜单
 * @create: 2021-04-16 15:44
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import {uuid} from "../utils/general";
export default class sys_menu extends Table{
    constructor() {
        super();
        this.name = "sys_menu";
        this.CNName = "菜单";
        this.columnArray = [
            new Column("组件","component",ColumnType.String,true),
            new Column("树Key","key",ColumnType.String,false)
                .setDefaultValue(uuid(10,16)),//唯一
            new Column("父节点id","pid",ColumnType.String,false).setDefaultValue("0"),
            new Column("标题","title",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(200)];
    }
}