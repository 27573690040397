<!--
* @program: office_automation 
* @author: ly
* @component:MessageReader 
* @description:
* @create: 2022-06-23 13:58
-->
<template>
    <a-modal v-model:visible="visible"
             width="700px"
             destroyOnClose
             :footer="null"
             title="站内信">
        <a-radio-group @change="radioChange" class="search-item" v-model:value="readFlag">
            <a-radio value="0">未读</a-radio>
            <a-radio value="1">已读</a-radio>
        </a-radio-group>
        <TwoParts :enable-change="false">
            <template #left>
                <TcScrollTable @showRecord="showDetail"  :view-model="true" :loading="loading" :table-columns="tableColumns" :data-source="dataSource"
                               :page-size="15" :scrolls="{y:300}" />
            </template>
            <template #right>
                <FcForm v-if="currentRecord" :view-model="true" :form-columns="formColumns" :form-data="currentRecord" />
                <div v-else >
                    点击查看消息
                </div>
            </template>
        </TwoParts>
    </a-modal>


</template>

<script>
    import TwoParts from "./_components/TwoParts";
    import TcScrollTable from "../components/table/TcScrollTable";
    import Column from "../assets/tables/parts/column";
    import ColumnType from "../assets/tables/parts/column_type";
    import messageUser from "../assets/api/messageUser";
    import FcForm from "../components/form/FcForm";
    import message from "../assets/tables/message";
    export default {
        name: "MessageReader",
        props:["value"],
        emits:["update:value"],
        components:{
            TwoParts,TcScrollTable,FcForm
        },
        data() {
            return {
                readFlag:"0", //0 未读  1 已读
                dataSource:[],
                tableColumns:[
                    new Column("标题","title",ColumnType.title).setTableView(100),
                    new Column("时间","createTime",ColumnType.Date).setTableView(50),
                ],
                loading:false,
                formColumns:new message().getFormColumns(),
                currentRecord:null,
                visible:false,
            }
        },
        methods: {
            open() {
                this.visible = true;
                this.readFlag = "0";
                this.radioChange();
            },
            radioChange(){
                this.currentRecord = null;
                if(this.$store.getters.userInfo){
                    let data = {
                        "readFlag": this.readFlag,
                        "receiveUser": this.$store.getters.userInfo.id
                    };
                    this.loading = true;
                    messageUser.queryAll.requestPOST(this,data,res=>{
                        this.loading = false;
                        if(res.data.flag){
                            this.dataSource = res.data.data;
                            for(let i in this.dataSource){
                                this.dataSource[i].title = this.dataSource[i].message.title;
                                this.dataSource[i].createTime = this.dataSource[i].message.createTime;
                            }
                            if(this.readFlag === "0"){
                                this.$emit("update:value",this.dataSource.length)
                            }
                            this.dataSource.sort((a, b) => {
                                return b.createTime - a.createTime;
                            })
                        }
                        else{
                            this.dataSource = [];
                            if(this.readFlag === "0"){
                                this.$emit("update:value",this.dataSource.length)
                            }
                        }
                    })
                }
            },
            showDetail(index,record){
                this.currentRecord = record.message;
                if(this.readFlag === "0"){
                    let data = {
                        batchId:record.id
                    };
                    messageUser.batchRead.requestPOSTUrlParam(this,data,res=>{
                        if(res.data.flag){
                            this.dataSource.splice(index,1);
                            this.$emit("update:value",this.dataSource.length)
                        }
                    })
                }
            }
        },
        created() {
            this.radioChange()
        }
    }
</script>

<style scoped>

</style>