<!--
* @program: office_automation 
* @author: ly
* @component:BankManager2 
* @description: 
* @create: 2022-03-18 14:14
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-tooltip title="添加新的银行账户">
                    <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                        <template #icon><PlusOutlined/></template>
                    </a-button>
                </a-tooltip>
                <div style="color: #022d5c;font-size: 18px;font-weight: 900;margin:10px">
                    余额合计：{{allSum}}元<a-divider type="vertical" style="height: 40px; background-color: #7cb305" />
                  其中贵州中色蓝图：{{sum1}}元<a-divider type="vertical" style="height: 40px; background-color: #7cb305" />
                  分公司：{{sum2}}元<a-divider type="vertical" style="height: 40px; background-color: #7cb305" />
                  中色：{{sum3}}元<span style="font-size: 5px;color: #faad14;">（项目收入*91.4%）+其他收入-项目支出-其他支出</span>
                </div>
            </div>
            <a-spin :spinning="loading" tip="正在查询数据库...">
                <div style="display: flex;flex-wrap: wrap;overflow: auto;height: 750px">
                    <a-card v-for="(item,idx) in dataSource" :key="idx" style="width: 400px;margin: 8px 8px;box-shadow: 0px 0px 5px #888888;">
                        <template #title>
                            {{item.bankName}}
                        </template>
                        <template #extra>
                            <a-button v-if="!item.visible" @click="showFlows(item)" type="link" >
                                流水
                            </a-button>
                            <a-button v-else @click="back(item)" danger type="link" >
                                返回
                            </a-button>
<!--                          v-if="item.balance === item.currentBlance && !item.visible"-->
                            <a-tooltip title="修改初始余额">
                              <a-button style="padding: 0 5px" type="link" @click="editData(item)"><Edit /></a-button>
                            </a-tooltip>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(item)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </template>
                        <span style="color: #5265ff;font-size: 16px;font-weight: 500;">余额：{{item.currentBlance}}元</span>
                        <span style="color: #faad14;font-size: 14px;">（{{new Date(item.updateTime).toLocaleDateString()}}）</span>
                        <FcForm style="width: 350px;margin-top: 10px;" :form-columns="getFormColumns(item)" :form-data="item" :view-model="true" />
                    </a-card>
                    <a-tabs v-if="visible" style="max-width: calc(100% - 450px)" v-model:activeKey="activeKey" >
                        <a-tab-pane  key="7" tab="流水汇总" >
                            <CardFlows  :record="currentRecord" :show-bill="showBill" />
                        </a-tab-pane>
                        <a-tab-pane v-if="showBill"  key="6" tab="账单一览" >
                            <ShowBills  :record="currentRecord"/>
                        </a-tab-pane>
                        <a-tab-pane  key="1" tab="项目收入流水" >
                            <ProjectInflow  :record="currentRecord" />
                        </a-tab-pane>
                        <a-tab-pane  key="2" tab="项目支出流水" >
                            <ProjectOutflow :record="currentRecord" />
                        </a-tab-pane>
                        <a-tab-pane  key="3" tab="其他收入" >
                            <OtherInflow  :record="currentRecord" />
                        </a-tab-pane>
                        <a-tab-pane  key="4" tab="其他支出" >
                            <OtherOutflow  :record="currentRecord" />
                        </a-tab-pane>
                        <a-tab-pane  key="5" tab="其他账户转入" >
                            <FromOtherBank  :record="currentRecord" />
                        </a-tab-pane>
                    </a-tabs>
                </div>
                <div style="border: 1px solid rgb(235, 237, 240);margin: 10px;font-size: 15px;color: red;">让账户的金额变动的流水日期需要在【账户初始余额日期】之后，录入在【账户初始余额日期】之前的流水会导致该银行卡出现各部分流水金额统计起来跟系统余额不同的情况。</div>
            </a-spin>
        </div>
    </div>
  <!-- 新增 -->
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             :title="null">
        <span>账户类型：</span>
        <a-select v-model:value="accountType"
                  style="width: 120px" class="search-item"
                  :options="accountTypes" />
        <FcForm  :form-columns="formColumns" :form-data="formData" :view-model="viewModel" >
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-modal>

  <!-- 修改 -->
  <a-modal v-model:visible="visible_edit"
           :maskClosable="false"
           title="编辑信息"  width="35%"
           :destroyOnClose="true"
           style="margin-top: 2%;"
  >
    <a-form v-model:value="formtData_edit" :label-col="4" :wrapper-col="14">

      <a-form-item label="初始余额(元)">
        <a-input v-model:value="formtData_edit.balance" type="number"/>
      </a-form-item>

<!--      <a-form-item label="初始余额日期">
        <a-time-picker
            v-model:value="formtData_edit.balanceDate"
            show-time
            type="date"
            placeholder="Pick a date"
            style="width: 100%"
        />
      </a-form-item>-->

    </a-form>

    <template #footer>
      <a-button key="back" @click="visible_edit =false">取消</a-button>
      <a-button  key="back" type="primary" @click="submit">提交</a-button>
    </template>
  </a-modal>
</template>

<script>
import Icons from "../../common/Icons";
import {DeleteOutlined, PlusOutlined,  SyncOutlined} from "@ant-design/icons-vue";
import ProjectInflow from "./ProjectInflow";
import ProjectOutflow from "./ProjectOutflow";
import OtherInflow from "./OtherInflow";
import OtherOutflow from "./OtherOutflow";
import FromOtherBank from "./FromOtherBank";
import item_finance_bank_balance from "../../../assets/tables/item_finance_bank_balance";
import bankBalance from "../../../assets/api/bankBalance";
import Column from "../../../assets/tables/parts/column";
import ColumnType from "../../../assets/tables/parts/column_type";
import FcForm from "../../../components/form/FcForm";
import ShowBills from "./ShowBills";
import CardFlows from "./CardFlows";

export default {
    name: "BankManager2",
    mixins:[Icons],
    components: {
        FcForm,
        SyncOutlined,PlusOutlined,DeleteOutlined,
        ProjectInflow,ProjectOutflow,OtherInflow,OtherOutflow,FromOtherBank,
        ShowBills,CardFlows
    },
    computed:{
        allSum(){
            let sum = 0;
            for(let i in this.allAccounts){
                sum += this.allAccounts[i].currentBlance
            }
            return sum.toFixed(2);
        },
        formColumns(){
            if(this.accountType === "1"){
                return this.type1Cols
            }
            else{
                return this.type2Cols
            }
        },
        dataSource(){
            let arr = this.allAccounts.filter(item=>item.visible);
            if(arr.length>0){
                return arr;
            }
            else{
                return this.allAccounts
            }
        },
        /**
         * 分公司账户id:bb93e7c7-c2c7-4a52-897d-4f8ccf2bb660
         * 中色账户Id:313a0c6f-38d6-4213-bbfc-b784ac116a65
         * 之外的账户归属贵州中色蓝图
         * @returns {string}
         */
        sum1(){//贵州中色蓝图账户合计
            return  (parseFloat(this.allSum) - parseFloat(this.sum2) - parseFloat(this.sum3)).toFixed(2);
        },
        sum2(){//分公司账户合计
            let sum = 0;
            let arr = this.allAccounts.filter(item=>item.id === "bb93e7c7-c2c7-4a52-897d-4f8ccf2bb660")
            for(let i in arr){
                sum += arr[i].currentBlance
            }
            return sum.toFixed(2);
        },
        sum3(){//中色账户合计
            let sum = 0;
            let arr = this.allAccounts.filter(item=>item.id === "313a0c6f-38d6-4213-bbfc-b784ac116a65")
            for(let i in arr){
                sum += arr[i].currentBlance
            }
            return sum.toFixed(2);
        },
        showBill(){
            return this.dataSource.length === 1 && this.dataSource[0].bankName === "现金账户";
        }
    },
    data(){
        return{
            type1Cols:[
                new Column("银行名称","bankName",ColumnType.String,true),
                new Column("办卡支行名称","subBank",ColumnType.String,true),
                new Column("银行卡号","bankNum",ColumnType.String,true),
                new Column("初始余额(元)","balance",ColumnType.Number,true),
                new Column("初始余额日期","balanceDate",ColumnType.Date,true),
                new Column("账户说明","remarks",ColumnType.String,true),
            ],
            type2Cols:[
                new Column("初始余额(元)","balance",ColumnType.Number,true),
                new Column("初始余额日期","balanceDate",ColumnType.Date,true),
                new Column("账户说明","remarks",ColumnType.String,true),
            ],
            accountTypes:[
                {label:"银行",value:"1"},
                {label:"现金",value:"2"},
            ],
            accountType:"1",//["1","2"],["银行","现金"]
            table: new item_finance_bank_balance(),
            viewModel:true,
            pageSize:15,
            allAccounts:[],//所有的账户
            loading:false,//table的loading状态
            formData:null,//提供Form的formData
            activeKey:"7",
            visible:false,
            visible2:false,
            currentRecord:null,

            /** 编辑 **/
          visible_edit:false,
          formtData_edit:null,
        }
    },
    methods:{
        init(){
            this.visible = false;
            this.visible2 = false;
            this.loading = true;
            bankBalance.queryAll.requestPOST(this,{},res=>{
                this.loading = false;
                if(res.data.flag){
                    this.allAccounts = res.data.data;
                }
                else{
                    this.allAccounts = [];
                    this.$message.error(res.data.msg);
                }

            })
        },
        refresh(){
            this.init()
        },
        addData(){
            this.visible2 = true;
            this.formData = this.table.getFormData();
            this.viewModel = false;
        },
      editData(record){
        this.visible_edit = true;
          this.formtData_edit = record;
        /*this.formtData_edit.id = record.id;
        this.formtData_edit.balance = record.balance;
        this.formtData_edit.balanceDate = record.balanceDate;*/
      },
      submit(){
        bankBalance.saveOrUpdate.requestPOST(this,this.formtData_edit,res=>{
          this.visible_edit = false;
          if(res.data.flag){
            this.$message.success(res.data.msg);
          }
          else{
            this.$message.error(res.data.msg);
          }
          this.init();
        })
      },
        saveData(record){
            if(this.accountType === "2"){
                record.bankName = "现金账户";
                record.subBank = "现金账户";
                record.bankNum = "0000000000"
            }
            record.accountType = this.accountType;
            bankBalance.saveOrUpdate.requestPOST(this,record,res=>{
                if(res.data.flag){
                    this.init();
                    this.$message.success(res.data.msg);
                }
                else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        showDetail(record){
            this.formData = record;
            this.viewModel = true;
            this.visible2 = true;
            this.currentRecord = record;
        },
        deleteData(item){
            if(item.createUser !== this.$store.getters.userInfo.id){
                this.$message.error("不能删除他人创建的账户，请联系管理员");
                return;
            }
            let data = {
                id:item.id
            };
            bankBalance.delete.requestPOSTUrlParam(this,data,res=>{
                if(res.data.flag){
                    this.$message.success(res.data.msg);
                    this.init()
                }
                else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        getFormColumns(item){
            if(item.accountType ==="1"){
                return this.type1Cols;
            }
            else{
                return this.type2Cols;
            }
        },
        showFlows(item){
            this.currentRecord = item;
            this.visible = true;
            item.visible = true;
            this.activeKey = "7";
        },
        back(item){
            item.visible = false;
            this.visible = false;
        }
    },
    created() {
        this.formColumns = this.table.getFormColumns();
        this.init()
    }
}
</script>

<style scoped>

</style>