<!--
* @program: office_automation 
* @author: ly
* @component:BillCategorySetting 
* @description: 
* @create: 2022-02-17 15:18
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <a-page-header
                    style="border: 1px solid rgb(235, 237, 240)"
                    title="内部账务类别设定"
            />
            <billCategoryTable />
        </div>
    </div>
</template>

<script>

    import billCategoryTable from "../../billManager/billComponents/billCategoryTable";

    export default {
        name: "BillCategorySetting",
        components:{
            billCategoryTable
        }
    }
</script>

<style scoped>

</style>