import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: seal_apply
 * @create: 2022-06-08 14:40
 **/

export default class seal_apply extends Table{
    constructor() {
        super();
        this.name = "seal_apply";
        this.CNName = "用章申请";
        this.columnArray = [
            new Column("印章名","sealNames",ColumnType.StrArr).setTableView(100),
            new Column("用途","purposeName",ColumnType.String).setTableView(100),
            new Column("使用项目","itemName",ColumnType.String),
            new Column("盖章文件","fileUrl",ColumnType.PreViewFile).setTableView(100),
            new Column("申请理由","purposeDescri",ColumnType.String,true).setTableView(300),
            new Column("申请人","userName",ColumnType.String).setTableView(100),
            new Column("审核状态","auditState",ColumnType.Enum).setTableView(100)
                .setEnum(["0","1","2"],["未完成","通过","未通过"]), //审核完成状态：0：未审核完成（未全部审核完成时）；1：通过（全部人通过）；2：不通过（任一人不通过）
            new Column("审核评语","remarks",ColumnType.String).setTableView(100),

        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}