<!--
* @description：审核弹框
* @author:rzl
* @date:2022/1/12 14:59
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="45%"
           :destroyOnClose="true"
  >

    <div style="height: 100%; width: 100%; ">
      <a-form-item label="审核意见"  :label-col="labelCol" :wrapper-col="wrapperCol"  >
        <a-textarea v-model:value="auditOpinion" style="height: 100px;"/>
      </a-form-item>
    </div>

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
      <a-button key="back" type="primary" @click="approve">通过</a-button>
      <a-button key="back"  type="danger"  @click="reject">不通过</a-button>
    </template>
  </a-modal>
</template>

<script>
import Icons from "../../../common/Icons";
export default {
  name: "auditTk",
  mixins:[Icons],
  data(){
    return{
      visible:false,
      modalTitle:null,
      labelCol:{ span: 6 },
      wrapperCol:{ span: 16 },

      infoId:null,
      auditOpinion:null,
    }
  },
  methods:{
    openAudit(id){
      this.visible = true;
      this.modalTitle = "审核信息";
      this.auditOpinion = null;

      this.infoId = id;
    },
    closeAudit(){
      this.visible = false;
    },
    approve(){
      let parms = {
        id:this.infoId,
        auditOpinion:this.auditOpinion,
        approveState:"1"
      }
      let data = this.$GetEmitData(this.$eventTags.audit,parms);
      this.$emit("event",data);
    },
    reject(){
      if(this.auditOpinion == null || this.auditOpinion == ''){
        this.$message.error('请输入审核意见');
        return;
      }
      let parms = {
        id:this.infoId,
        approveRemarks:this.auditOpinion,
        approveState:"2"
      }
      let data = this.$GetEmitData(this.$eventTags.audit,parms);
      this.$emit("event",data);
    },
  },

}
</script>
