<!--
* @program: office_automation 
* @author: ly
* @component:delayDataShower 
* @description: 
* @create: 2021-12-31 16:30

id: "2f2a7586-e203-44d5-93c4-ada8a130245b"
itemId: "52"
itemName: "云岩区社区管理争议界线勘测定界项目"
qcApplyId: null
qcApplyTitle: null
qcProcessId: null
qcProcessName: null
taskId: "4d55ae7f-7a60-49c4-9bb2-018bfb616dce"
taskName: "云岩区社区管理争议界线勘测定界项目"
taskProcessId: "7b174014-643b-4fc0-aabd-52c490e789c2"
taskProcessName: "工序一"
userName: "超级管理员"
-->
<template>
    <div>
        <a-page-header style="border: 1px solid rgb(235, 237, 240)">
            <template #title>
                <a-button type="primary" @click="visible = !visible">
                    申请来源结构
                </a-button>
            </template>
        </a-page-header>
        <MindMap v-if="visible" style="height: 100px" v-model="mapData" />
        <project-tabs ref="local_tabs" />
    </div>
</template>

<script>
    import MindMap from 'vue3-mindmap'
    import 'vue3-mindmap/dist/style.css'
    import projectTabs from "../projectViewer/projectTabs";
    export default {
        name: "delayDataShower",
        props:["record"],
        components:{
            MindMap,projectTabs
        },
        watch:{
            record(){
                this.init()
            }
        },
        data(){
            return{
                visible:false,
                itemFlowTemp:null,
                applyType:null,
                mapData:[{
                    "name":"如何学习D3",
                    "children": [
                        {
                            "name":"预备知识",
                            "children": [
                                { "name":"HTML & CSS" },
                                { "name":"JavaScript" },
                            ]
                        },
                        {
                            "name":"安装",
                            "collapse": true,
                            "children": [ { "name": "折叠节点" } ]
                        },
                        { "name":"进阶", "left": true },
                    ]
                }],
            }
        },
        methods:{
            init(){
                this.itemFlowTemp = this.record?.itemFlowTemp;
                this.applyType = this.record?.applyType;
                if(!this.itemFlowTemp){
                    return
                }
                let {taskName,itemName,taskProcessName,qcProcessName,qcApplyTitle} = this.itemFlowTemp;
                taskName = "项目任务："+taskName;
                itemName = "项目："+itemName;
                taskProcessName = "工序标题："+taskProcessName;
                qcProcessName = "质检工序标题：" + qcProcessName;
                qcApplyTitle = "质检申请：" + qcApplyTitle;
                switch (this.applyType) { //["1","2","3","4"],["生产任务","生产工序","质检工序","员工工序"]
                    case "1" :{
                        this.mapData = [{
                            name:taskName,
                            children:[
                                {name:itemName, left:true}
                                ]
                        }];
                        try{
                            this.$refs.local_tabs.handleOpen([
                                {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:this.itemFlowTemp.itemId},
                                {tag:"任务详情",component:"projectTaskInfoPart",viewModel:true,projectId:this.itemFlowTemp.itemId},
                                {tag:"项目合同信息",component:"projectContract",viewModel:true,projectId:this.itemFlowTemp.itemId},
                            ]);
                        }
                        catch (e) {
                            console.log(e)
                        }
                        break;
                    }
                    case "2" :{
                        this.mapData = [{
                            name:taskName,
                            children:[
                                {name:itemName, left:true},
                                {name:taskProcessName},
                                ]
                        }];
                        try{
                            this.$refs.local_tabs.handleOpen([
                                {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:this.itemFlowTemp.itemId},
                                {tag:"任务详情",component:"projectTaskInfoPart",viewModel:true,projectId:this.itemFlowTemp.itemId},
                                {tag:"项目合同信息",component:"projectContract",viewModel:true,projectId:this.itemFlowTemp.itemId},
                                {tag:"部门任务情况",component:"taskProcessList",viewModel:true,projectId:this.itemFlowTemp.itemId,record:{}},
                            ]);
                        }
                        catch (e) {
                            console.log(e)
                        }
                        break;
                    }
                    case "3" :{
                        this.mapData = [{
                            name:qcApplyTitle,
                            children:[
                                {name:itemName, left:true},
                                {name:qcProcessName}
                            ]
                        }];
                        break;
                    }
                    case "4" :{
                        this.mapData = [{
                            name:taskName,
                            children:[
                                {name:itemName, left:true},
                                {
                                    name:taskProcessName,
                                },
                            ]
                        }];
                        try{
                            this.$refs.local_tabs.handleOpen([
                                {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:this.itemFlowTemp.itemId},
                                {tag:"任务详情",component:"projectTaskInfoPart",viewModel:true,projectId:this.itemFlowTemp.itemId},
                                {tag:"项目合同信息",component:"projectContract",viewModel:true,projectId:this.itemFlowTemp.itemId},
                                {tag:"部门任务情况",component:"taskProcessList",viewModel:true,projectId:this.itemFlowTemp.itemId,record:{}},
                            ]);
                        }
                        catch (e) {
                            console.log(e)
                        }
                        break;
                    }
                }
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>