/** 
* 自动生成文件，请勿修改 
* @name: 支出:其他支出申请
* @description: 其他支出申请(四期新增（董灿提出）)
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const otherPay = { 
	/** 1 summary:其他支出-统计每月度支出总计（月度下类别总计）	method:post */ 
	otherPayStat : new APIObject("/api/otherPay/otherPayStat.do"), 
	/** 2 summary:分页查询	method:post */ 
	queryPage : new APIObject("/api/otherPay/queryPage.do"), 
	/** 3 summary:查询需要当前登录用户审批且已到达的申请-不分页	method:post */ 
	queryMyApproval : new APIObject("/api/otherPay/queryMyApproval.do"), 
	/** 4 summary:保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/otherPay/saveOrUpd.do"), 
	/** 5 summary:所有数据查询	method:post */ 
	queryAll : new APIObject("/api/otherPay/queryAll.do"), 
	/** 6 summary:查询需要当前登录用户审批且已到达我这里的申请-分页	method:post */ 
	pageMyApproval : new APIObject("/api/otherPay/pageMyApproval.do"), 
	/** 7 summary:单条数据查询	method:post */ 
	queryOne : new APIObject("/api/otherPay/queryOne.do"), 
	/** 8 summary:强制修改	method:post */ 
	forceUpd : new APIObject("/api/otherPay/forceUpd.do"), 
	/** 9 summary:单条删除	method:post */ 
	delete : new APIObject("/api/otherPay/delete.do"), 
};
export default otherPay;