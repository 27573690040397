<!--
* @program: office_automation 
* @author: ly
* @component:StepSetting 
* @description: 
* @create: 2021-12-21 09:52
-->
<template>
    <a-steps style="width: 1200px">
        <a-step status="process" v-for="(step,index) in steps" :key="index" >
            <template #title>
                {{step}}
            </template>
            <template #subTitle>
                <div class="center-flex">
                    <a-tooltip title="左边插入">
                        <a-button class="search-item" type="dashed"  @click="leftAdd(step)">
                            <template #icon><PlusOutlined /></template>
                        </a-button>
                    </a-tooltip>
                    <a-popconfirm
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            title="确定删除吗?"
                            @confirm="remove(step)">
                        <a-button class="search-item" type="dashed">
                            <template #icon><DeleteOutlined  style="color: red"/></template>
                        </a-button>
                    </a-popconfirm>
                    <a-tooltip title="右边插入">
                        <a-button class="search-item" type="dashed"  @click="rightAdd(step)">
                            <template #icon><PlusOutlined /></template>
                        </a-button>
                    </a-tooltip>
                </div>
            </template>
        </a-step>
    </a-steps>
</template>

<script>
    import {PlusOutlined,DeleteOutlined} from "@ant-design/icons-vue"
    export default {
        name: "StepSetting",
        props:["steps"],
        emits:["leftAdd","remove","rightAdd"],
        components:{
            PlusOutlined,DeleteOutlined
        },
        methods:{
            leftAdd(step){
                this.$emit("leftAdd",step);
            },
            remove(step){
                this.$emit("remove",step);
            },
            rightAdd(step){
                this.$emit("rightAdd",step);
            }
        }
    }
</script>

<style scoped>

</style>