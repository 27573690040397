<!--
* @program: office_automation 
* @author: ly
* @component:projectOutSourcePayAuditHistory 
* @description: 审核历史
* @create: 2021-12-22 14:25
-->
<template>
    <div  class="search-bar">
        <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
            <template #icon>
                <SyncOutlined/>
            </template>
        </a-button>
    </div>
    <TwoParts :enableChange="false" ref="TwoParts" >
        <template #left>
            <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="15" >
                <template #action = "{index,record}">
                    <div class="center-flex">
                        <a-tooltip title="查看支付申请">
                            <a-button class="action-button" type="link" @click="showDetail(index,record)">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </ArBaseTableFilter>
        </template>
        <template #right>
            <div v-if="formArg.formData">
                <ArForm  :argument="formArg" :view-model="true" :span-col="true" />
                <a-divider>审核情况</a-divider>
                <projectOutSourcePayAuditStatus  :record="formArg.formData" />
            </div>
            <div v-else>
                <welcome2 />
            </div>
        </template>
    </TwoParts>
</template>

<script>
    import TwoParts from "../../_components/TwoParts";
    import {SyncOutlined,SearchOutlined} from "@ant-design/icons-vue";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import ArForm from "../../../components/form/ArForm";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {companyColors, companyIds, companyNames} from "../../../assets/system/companies";
    import payApply from "../../../assets/api/payApply";
    import payApproval from "../../../assets/api/payApproval";
    import projectOutSourcePayAuditStatus from "../projectRelateInfo/projectOutSourcePayAuditStatus";
    import welcome2 from "../../welcome2";
    export default {
        name: "projectOutSourcePayAuditHistory",
        components:{
            TwoParts,SyncOutlined,ArBaseTableFilter,ArForm,SearchOutlined,welcome2,
            projectOutSourcePayAuditStatus
        },
        data(){
            return{
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                    currentIndex:null //当前选中的条目
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                }
            }
        },
        methods:{
            init(){
                let data = {
                    "applyId": "",
                    "approvalUser": this.$store.getters.userInfo.id,
                    "state": ""
                };
                payApproval.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            showDetail(index,record){
                let data = {
                    id : record.applyId
                };
                payApply.queryOne.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.formArg.formData = res.data.data;
                    }
                })
            },
            refresh(){
                this.init()
            }
        },
        created() {
            this.argument.tableColumns = [
                new Column("序号","_index",ColumnType.Index).setTableView(60),
                new Column("审核时间","updateTime",ColumnType.Date).setTableView(100),
                new Column("审核状态","state",ColumnType.Enum).setTableView(100)
                .setEnum(["1","2","3"],["待审核","已通过","未通过"],["pink","green","red"]), //审核状态：0：未到达；1：待审核；2：已通过；3：未通过 ；
                new Column("审核意见","remarks",ColumnType.String).setTableView(200),
                new Column("操作","actions","actions",false).setTableView(60),
            ];
            this.formArg.formColumns = [ //与projectOutSourcePayViewer 保持一致
                new Column("项目名称","itemName",ColumnType.String).setTableView(200),
                new Column("付款合同名称","contractName",ColumnType.String).setTableView(100),
                new Column("付款合同编号","contractNum",ColumnType.String).setTableView(100),
                new Column("收款单位/个人","outSourceName",ColumnType.String).setTableView(100),
                new Column("申请支付金额","payAmount",ColumnType.Number).setTableView(100),
                new Column("付款公司", "payCompanyId", ColumnType.Enum).setTableView(100)
                    .setEnum(companyIds, companyNames,companyColors),
                new Column("付款部门", "applyDepartName", ColumnType.String).setTableView(100),
                new Column("支付描述","payReason",ColumnType.String).setTableView(100),
            ];
        }

    }
</script>

<style scoped>

</style>