<!--
* @program: TYH_office_automation 
* @author: ly
* @component:OverSizeCol 
* @description: 
* @create: 2021-12-06 16:47
-->
<template>
    <div ref="OverSizeCol" style="width: 100%;">
        <div v-if="overSize">
            <a-tooltip :color="'#022d5c'" :title="text">
                <div style="display: flex;color:#022d5c;justify-content: space-around;">
                    <div>{{presentValue}}</div>
                </div>
            </a-tooltip>
        </div>
        <div v-else style="text-align: center;">
            {{presentValue}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "OverSizeCol",
        props:["text"],
        inject:["presentType"],
        data(){
            return{
                overSize:false,
                width:0
            }
        },
        computed: {
            presentValue() {
                let str = this.text ? this.text : "无";
                let size = Math.ceil(this.width / 14) - 3;
                if (this.overSize) {
                    return str.substr(0, size) + "...";
                } else {
                    return str;
                }
            }
        },
        mounted() {
            this.width = this.$refs.OverSizeCol.offsetWidth;
            if (this.presentType === "table") {
                let str = this.text ? this.text.toString() : "无";//
                let str2 = str.replace(/[^\u4e00-\u9fa5]/gi, "");//中文字符
                let cnLength = str2.length;
                let enLength = str.length - str2.length;
                this.overSize = this.width < (cnLength * 13 + enLength * 8);
            } else {
                this.overSize = false;
            }
        }
    }
</script>

<style scoped>

</style>