<!--
* @program: office_automation 
* @author: ly
* @component:MonthlyAssessmentViewer2
* @description: 月度考核信息展示控件 按月查询使用
* @create: 2022-02-28 09:55
-->
<template>
    <div >
        <h3 v-if="assessmentData.length>0">考核类型【{{assessmentName}}】总分：{{fullPoints}}    得分：{{sumPoints.toFixed(2)}}</h3>
        <a-tabs v-if="assessmentData.length>0"   v-model:activeKey="activeKey" >
            <a-tab-pane v-for="(item,index) in tabList"  :key="index.toString()" :tab="item.label" >
                <TcBaseTable :view-model="true" :page-size="15" :data-source="item.dataSource"
                             :table-columns="tableColumns" >
                    <template #action="{record}">
                        <div v-if="!viewModel"  class="action-column">
                            <div v-if="userType === 'user' && record.judgeEnd === true">
<!--                                <a-popconfirm-->
<!--                                        v-if="record.agreeFlag === '0' || record.agreeFlag === '2'"-->
<!--                                        ok-text="是"-->
<!--                                        cancel-text="否"-->
<!--                                        class="action-button"-->
<!--                                        title="确定同意本项考核结果吗?"-->
<!--                                        @confirm="agree(record)">-->
<!--                                    <a-tooltip  title="同意本项考核结果">-->
<!--                                        <a-button type="link">-->
<!--                                            <template #icon><CheckOutlined /></template>-->
<!--                                        </a-button>-->
<!--                                    </a-tooltip>-->
<!--                                </a-popconfirm>-->
                                <a-popconfirm
                                        v-if="record.agreeFlag === '0' "
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="不同意本项考核结果吗?"
                                        @confirm="disagree(record)">
                                    <a-tooltip title="不同意本项考核结果">
                                        <a-button danger  type="link">
                                            <template #icon><CloseOutlined /></template>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                            <a-tooltip v-if="record.agreeFlag !== '1' && record.editFlag === true && userType === 'judge'" title="打分">
                                <a-button @click="judge(record)"  type="link">
                                    打分
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </TcBaseTable>
            </a-tab-pane>
            <template #renderTabBar="{ DefaultTabBar, ...props }">
                <component :is="DefaultTabBar" v-bind="props" :style="{ zIndex: 1, background: '#fff', }"/>
            </template>
        </a-tabs>
        <div v-else>
            未查询到相关数据
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroy-on-close
             :footer="null"
             title="评分">
        <AssessmentRecordHistory :record="currentRecord" @init="dataHandle"/>
        <TagShow :tag="fullScore===999?'请按要求规定输入分数':'满分为：'+fullScore" color="blue">
            <template #content>
                <span>得分：</span><a-input-number :max="fullScore" v-model:value="score"
                                                style="width: 188px; margin-bottom: 8px;"
                                                @pressEnter="saveData"/>
                <br />
                <span>评语：</span><a-input placeholder="请输入评语"
                                         v-model:value="remarks" style="width: 188px; margin-bottom: 8px;"/>
                <br />
                <a-button @click="saveData"  type="primary">
                    提交
                </a-button>
            </template>
        </TagShow>

    </a-modal>
</template>

<script>
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import TcBaseTable from "../../../../components/table/TcBaseTable";
    import Welcome2 from "../../../welcome2";
    import assessmentUser from "../../../../assets/api/assessmentUser";
    import userScore from "../../../../assets/api/userScore";
    import {CheckOutlined,CloseOutlined} from "@ant-design/icons-vue"
    import AssessmentRecordHistory from "./AssessmentRecordHistory";
    import TagShow from "../../../_components/TagShow";
    export default {
        name: "MonthlyAssessmentViewer2",
        props:["userData","viewModel","userType"],//userData 要查看的人的相关数据userType user查看分数的人  judge 对分项进行打分的人
        components:{
            Welcome2,TagShow,
            TcBaseTable,CloseOutlined,CheckOutlined,AssessmentRecordHistory
        },
        computed:{
            sumPoints(){
                let num = 0;
                for(let i in this.assessmentData){
                    num += this.assessmentData[i].obtainScore
                }
                return num;
            },
            fullPoints(){
                let num = 0;
                for(let i in this.assessmentData){
                    num += this.assessmentData[i].standardScore
                }
                return num;
            }
        },
        watch:{
            userData(){
                this.init()
            }
        },
        data(){
            return{
                activeKey:"0",
                tabList:[],
                tableColumns:[],
                visible:false,
                fullScore:null,
                score:null,
                currentRecord:null,
                remarks:null,
                historyRecord:null,
                assessmentData:[],
                assessmentName:null,
            }
        },
        methods:{
            init(){
                let data ={
                    "userId": this.userData.userId,
                    "yearMonth": this.userData.yearMonth
                };
                assessmentUser.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.tabList = [];
                        this.assessmentName = res.data.data[0].typeName;
                        this.assessmentData = res.data.data[0].userDTOS;
                        for(let i in this.assessmentData){
                            let tabs = this.tabList.filter(item =>item.label === this.assessmentData[i].bigIndex);
                            if(tabs.length === 0){
                                this.tabList.push(
                                    {
                                        label:this.assessmentData[i].bigIndex,
                                        dataSource:[this.assessmentData[i]]
                                    }
                                )
                            }
                            else{
                                tabs[0].dataSource.push(this.assessmentData[i])
                            }
                        }
                    }
                    else{
                        this.assessmentData = []
                    }
                })
            },
            agree(record){
                let data = {
                    ids:record.id,
                    agreeFlag:"1"
                };
                assessmentUser.agree.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        record.agreeFlag = "1";
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            disagree(record){
                let data = {
                    ids:record.id,
                    agreeFlag:"2"
                };
                assessmentUser.agree.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        record.agreeFlag = "2";
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            judge(record){
                this.score = 0;
                this.remarks = null;
                this.fullScore = record.standardScore?record.standardScore:999;
                this.visible = true;
                this.currentRecord = record;
                this.historyRecord = null;
            },
            saveData(){
                let data = {
                    "actualFinish":this.score,
                    "assessmentId": this.currentRecord.id,
                    "obtainScore": this.score,
                    "remarks": this.remarks
                };
                if(this.historyRecord){
                    data.id = this.historyRecord.id;
                }
                userScore.saveOrUpd.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.currentRecord.obtainScore = this.score;
                        this.currentRecord.judgeEnd = true;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            dataHandle(historyRecord){//评分历史数据
                this.historyRecord = historyRecord;
            }
        },
        created() {
            this.init();
            if(this.viewModel){
                this.tableColumns.pop();
            }
            if(this.userType === "user"){
                this.tableColumns = [
                    //new Column("排序","sortOrder",ColumnType.Number).setTableView(60),
                    //new Column("大类","bigIndex",ColumnType.String).setTableView(100),
                    new Column("类别","smallIndex",ColumnType.String).setTableView(100),
                    new Column("打分角色","judgeRole",ColumnType.IdReplace,false).setTableView(100)
                        .setIdReplaceObject("judgeRoleName",{}),
                    new Column("打分人","judgeUserNames",ColumnType.String).setTableView(80),
                    new Column("评分规则","describe",ColumnType.String).setTableView(200),
                    new Column("指标","indexDescribe",ColumnType.String).setTableView(100),
                    new Column("实际完成","actualFinish",ColumnType.String).setTableView(100),
                    new Column("满分","standardScore",ColumnType.Number).setTableView(60),
                    new Column("得分","obtainScore",ColumnType.Number,true).setTableView(60),
                    new Column("评分","judgeEnd",ColumnType.Boolean).setTableView(60)
                        .setBoolTags(["未完成","已完成"]),
                    new Column("评语","remarks",ColumnType.String).setTableView(100),
                    new Column("同意","agreeFlag",ColumnType.Enum).setTableView(80)
                        .setEnum(["0","1","2"],["未表态","同意","不同意"],["pink","green","red"]),
                    new Column("操作","actions","actions").setTableView(60)
                ]
            }
            else if(this.userType === "judge"){
                this.tableColumns = [
                    //new Column("排序","sortOrder",ColumnType.Number).setTableView(60),
                    //new Column("大类","bigIndex",ColumnType.String).setTableView(100),
                    new Column("类别","smallIndex",ColumnType.String).setTableView(100),
                    new Column("打分角色","judgeRole",ColumnType.IdReplace,false).setTableView(100)
                        .setIdReplaceObject("judgeRoleName",{}),
                    new Column("打分人","judgeUserNames",ColumnType.String).setTableView(80),
                    new Column("评分规则","describe",ColumnType.String).setTableView(200),
                    new Column("满分","standardScore",ColumnType.Number).setTableView(60),
                    new Column("得分","obtainScore",ColumnType.Number,true).setTableView(60),
                    new Column("评分","judgeEnd",ColumnType.Boolean).setTableView(60)
                        .setBoolTags(["未完成","已完成"]),
                    new Column("评语","remarks",ColumnType.String).setTableView(100),
                    new Column("同意","agreeFlag",ColumnType.Enum).setTableView(80)
                        .setEnum(["0","1","2"],["未表态","同意","不同意"],["pink","green","red"]),
                    new Column("操作","actions","actions").setTableView(60)
                ]
            }
        }
    }
</script>

<style scoped>

</style>