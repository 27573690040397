<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentAudits 
* @description: 部门审核
* @create: 2022-06-09 09:39
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
                <template #title>
                    <div class="center-flex">
                        <div style="margin-left:30px ">部门审核</div>
                        <a-button v-if="visible" class="search-item" type="dashed" shape="circle" @click="init" >
                            <template #icon><SyncOutlined /></template>
                        </a-button>
                        <a-button class="search-item" v-else  danger @click = "visible = true">返回</a-button>
                    </div>
                </template>
            </a-page-header>
            <div v-if="visible" style="display: flex;">
                <a-card v-for="(item,index) in departmentAudits" :key="index" style="width: 250px;">
                    <template #title>
                        {{item.name}}
                    </template>
                    <a-badge :count="item.list.length">
<!--                        :disabled="item.list.length === 0"-->
                        <a-button :loading="item.loading" :disabled="item.list.length === 0"  type="primary" @click="showApply(item)">待审核</a-button>
                    </a-badge>
                    <a-button style="margin-left: 15px"  danger type="primary" @click="showHistory(item)">{{item.label}}审核历史</a-button>
                </a-card>
            </div>
            <div v-else>
                <div v-is="comp" :viewModel="viewModel" v-model:dataSource="dataSource" ></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SyncOutlined} from "@ant-design/icons-vue"
    import DepartmentSealAudit from "./DepartmentSealAudit";
    import DepartmentAssetsAuditB from "./DepartmentAssetsAuditB";
    import DepartmentAssetsAuditR from "./DepartmentAssetsAuditR";
    import sealApply from "../../../assets/api/sealApply";
    import deviceApply from "../../../assets/api/deviceApply";
    import deviceBack from "../../../assets/api/deviceBack";
    export default {
        name: "DepartmentAudits",
        components:{
            SyncOutlined,DepartmentSealAudit,DepartmentAssetsAuditR,DepartmentAssetsAuditB
        },
        data() {
            return {
                comp:null,
                departmentAudits:[
                    new Audit("设备申领","DepartmentAssetsAuditB"),
                    new Audit("设备归还","DepartmentAssetsAuditR"),
                    new Audit("印章使用","DepartmentSealAudit"),
                ],
                visible:true,
                viewModel:true,
                dataSource:[],
            }
        },
        methods: {
            init() {
                let data = {
                    userId:this.$store.getters.userInfo.id,
                    state:"1"
                };
                /**
                 * 查询待审核的印章申请
                 */
                deviceApply.queryMyApproval.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.departmentAudits[0].list = res.data.data;
                    }
                    else{
                        this.departmentAudits[0].list = [];
                    }
                });
                deviceBack.queryMyApproval.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.departmentAudits[1].list = res.data.data;
                    }
                    else{
                        this.departmentAudits[1].list = [];
                    }
                });
                sealApply.allMyApproval.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.departmentAudits[2].list = res.data.data;
                    }
                    else{
                        this.departmentAudits[2].list = [];
                    }
                })
            },
            showApply(item){
                this.comp = item.component;
                this.dataSource = item.list;
                this.visible = false;
                this.viewModel = false;
            },
            showHistory(item){
                this.comp = item.component;
                this.visible = false;
                this.viewModel = true;
            }
        },
        created() {
            this.init()
        }
    }
    class Audit{
        /**
         *
         * @param name 待审核名称
         * @param component 查看待审核条目使用的模块
         * @param list 待审核的条目
         * @param loading 查询使用的状态
         */
        constructor(name,component) {
            this.name = name;
            this.list = [];
            this.component = component;
            this.loading = false;
        }
    }
</script>

<style scoped>

</style>