<!--
* @program: office_automation 
* @author: ly
* @component:staffAssessment 
* @description: 员工考核
* @create: 2022-01-14 09:27
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div v-if="visible" style="display: flex;flex-wrap: wrap;max-height: 100%;overflow: auto;">
                <div class="flat-component" v-for="item in showComps" :key="item.comp" @click="clickItem(item)" :style="item.style" >
                    {{item.title}}
                </div>
            </div>
            <div style="right: 100%" v-else>
                <a-page-header style="padding: 0; border: 1px solid rgb(235, 237, 240)">
                    <template #title>
                        <div style="display: flex;">
                            <div style="margin: 20px">
                                <a-button style="margin-right: 20px" danger @click = "visible = true" >返回</a-button>
                                <span >{{currentComp.title}}</span>
                            </div>
                        </div>
                    </template>
                </a-page-header>
                <div v-is="currentComp.comp" />
            </div>
        </div>
    </div>
</template>

<script>
    import MonthlyAssessmentSetting from "./MonthlyAssessment/MonthlyAssessmentSetting";
    import DepartmentAimSetting from "./YearlyAssessment/DepartmentAimSetting";
    import PositionAimSetting from "./MonthlyAssessment/PositionAimSetting";
    import YearlyAssessment from "./YearlyAssessment/YearlyAssessment";
    import MonthlyAssessment from "./MonthlyAssessment/MonthlyAssessment";
    import AttendanceRecords from "./AttendanceRecords/AttendanceRecords";
    import HygieneRecords from "./HygieneRecords/HygieneRecords";
    import AttendanceRuleSetting from "./AttendanceRecords/AttendanceRuleSetting";
    import YearlyAssessmentSetting from "./YearlyAssessment/YearlyAssessmentSetting";
    import AssessmentRoleSetting from "./MonthlyAssessment/AssessmentRoleSetting";
    export default {
        name: "StaffAssessment",
        components:{
            MonthlyAssessmentSetting,//月度考核相关配置
            AssessmentRoleSetting,//
            DepartmentAimSetting,//部门年度绩效目标配置
            PositionAimSetting, //职位月度绩效目标配置
            YearlyAssessment, //年度考核
            YearlyAssessmentSetting,//年度考核相关配置
            MonthlyAssessment, //月度考核
            AttendanceRecords,//考勤记录
            AttendanceRuleSetting,//考勤扣分规则
            HygieneRecords //卫生记录

        },
        computed:{
            showComps(){
                return this.components.filter(item=>item.enable)
            }
        },
        data(){
            return{
                components:[
                    {title:"月度考核",comp:"MonthlyAssessment",enable:true,
                        style:"width:300px;background-image: linear-gradient(#1890ff, #fff);"},
                    {title:"职位月度绩效目标配置",comp:"PositionAimSetting",enable:true,
                        style:"width:300px;background-image: linear-gradient(#1890ff, #fff);"},
                    {title:"月度考核科目配置",comp:"MonthlyAssessmentSetting",enable:true,
                        style:"width:300px;background-image: linear-gradient(#1890ff, #fff);"},
                    {title:"月度考核评分角色设置",comp:"AssessmentRoleSetting",enable:true,
                        style:"width:300px;background-image: linear-gradient(#1890ff, #fff);"},
                    {title:"年度考核",comp:"YearlyAssessment",enable:true,
                        style:"width:300px;background-image: linear-gradient(#5265FF, #fff);"},
                    {title:"生产部门年度绩效目标配置",comp:"DepartmentAimSetting",enable:true,
                        style:"width:300px;background-image: linear-gradient(#5265FF, #fff);"},
                    {title:"年度考核科目配置",comp:"YearlyAssessmentSetting",enable:true,
                        style:"width:300px;background-image: linear-gradient(#5265FF, #fff);"},
                    {title:"考勤记录",comp:"AttendanceRecords",enable:true,
                        style:"width:300px;background-image: linear-gradient(#CC3439, #fff);"},
                    {title:"考勤扣分规则",comp:"AttendanceRuleSetting",enable:true,
                        style:"width:300px;background-image: linear-gradient(#CC3439, #fff);"},
                    {title:"卫生记录",comp:"HygieneRecords",enable:true,
                        style:"width:300px;background-image: linear-gradient(#CC3439, #fff);"},
                ],
                currentComp:null,
                visible:true
            }
        },
        methods:{
            clickItem(item){
                this.currentComp = item;
                this.visible = false;
            }
        }

    }
</script>

<style scoped>

</style>