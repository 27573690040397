/**
 * @program: tyh-oa
 * @author: ssr
 * @description: sys_role  角色表
 * @create: 2021-04-25 10:55
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class sys_role extends Table{
    constructor() {
        super();
        this.name = "sys_role";
        this.CNName = "角色表";
        this.columnArray = [
            new Column("角色名称","name",ColumnType.String,true).setTableView(100),
            new Column("说明","remarks",ColumnType.String,true).setTableView(120),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(100).setFixed("right")];
    }
}