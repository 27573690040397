/** 
* 自动生成文件，请勿修改 
* @name: 报销:费用类别与角色对应模块
* @description: 报销-费用类别与角色对应模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const categoryRole = { 
	/** 1 summary:费用类别与角色对应-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/categoryRole/queryOne.do"), 
	/** 2 summary:费用类别与角色对应-分页查询	method:post */ 
	queryPage : new APIObject("/api/categoryRole/queryPage.do"), 
	/** 3 summary:费用类别与角色-重新授权类别给角色(该角色原来的会被删除)	method:post */ 
	categoryReAlloc : new APIObject("/api/categoryRole/categoryReAlloc.do"), 
	/** 4 summary:费用类别与角色对应-所有数据查询	method:post */ 
	queryAll : new APIObject("/api/categoryRole/queryAll.do"), 
	/** 5 summary:费用类别与角色对应-单条删除	method:post */ 
	delete : new APIObject("/api/categoryRole/delete.do"), 
	/** 6 summary:费用类别与角色对应-批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/categoryRole/batchSaveOrUpd.do"), 
	/** 7 summary:费用类别与角色对应-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/categoryRole/saveOrUpd.do"), 
	/** 8 summary:费用类别与角色-根据用户id查询其能报销的费用类别	method:post */ 
	queryCategoryByUser : new APIObject("/api/categoryRole/queryCategoryByUser.do"), 
	/** 9 summary:费用类别与角色对应-批量删除	method:post */ 
	batchDelete : new APIObject("/api/categoryRole/batchDelete.do"), 
};
export default categoryRole;