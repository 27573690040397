<!--
* @description：地图预览查询信息列表
* @author:rzl
* @date:2022/4/21 10:37
-->

<template>

  <!-- list列表 start -->
  <div class="layer_list" :id="listStyle">
    <div class="ant-row"
         style="height: 25px;font-size: 18px;font-weight: 500;color: #33485B;line-height: 20px;margin-left: 100px;margin-top: 8px;">
      <div class="ant-col-20">数据信息</div>
      <div class="ant-col-2">
        <span role="img" aria-label="close-circle" class="anticon anticon-close-circle" >
            <a @click="closeShow" title="关闭">
            <svg focusable="false" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor"
                 aria-hidden="true" viewBox="64 64 896 896"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512
                 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9
                 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5
                 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448
                 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372
                 372-166.6 372-372 372z"></path>
            </svg></a>
          </span>
      </div>

    </div>
    <hr style="width: 270px;height: 1px;background: #DBDBDB;border-radius: 0px 0px 0px 0px;opacity: 1; "/>
    <div class="ant-row" style="height: 83%;overflow: auto;">
      <div v-if="info_list.length > 0" >
        <div v-for="(info,i) in info_list" :key="i"   accordion style="margin-top: 6px;margin-left: 15px;">
          <a @click="show_info(info)" style="font-size: 16px;font-weight: 500;color: #668260;line-height: 25px;">
            {{info.name}}
          </a>
        </div>
      </div>
      <div v-else style="margin-top: 55%;margin-left: 35%;">
        <h3>暂无数据</h3>
      </div>
    </div>
  </div>
  <!-- list列表 end -->

  <!-- 信息 start -->
  <div class="layer_info" :id="infoStyle">
    <div class="ant-row"
         style="font-weight: 500;color: #33485B;margin-left: 15px;margin-top: 8px;">
      <div class="ant-col-4"><img style="cursor: pointer;" :src="fh_url" @mouseenter="enter_op()" @mouseleave="leave_op()" @click="info_fn"></div>
      <div class="ant-col-20">{{infoName}}</div>
    </div>
    <hr style="width: 90%;height: 1px;background: #DBDBDB;border-radius: 0px 0px 0px 0px;opacity: 1; "/>
    <a-form ref="bill_form"
              style="overflow: auto;"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
      >
      <div class="ant-row">
        <div class="ant-col-24">
          <a-form-item ref="name"
                       label="项目名称" >
            <a-span>{{itemName}}</a-span>

          </a-form-item>
        </div>
      </div>
        <div class="ant-row">
          <div class="ant-col-24">
            <a-form-item ref="name"
                         label="成果名称" >
              <a-span>{{name}}</a-span>

            </a-form-item>
          </div>
        </div>
        <div class="ant-row">
          <div class="ant-col-24">
            <a-form-item ref="treeValue_xzqh" label="行政区划" >
              <a-span>{{areaName}}</a-span>
            </a-form-item>
          </div>
        </div>
        <div class="ant-row">
          <div class="ant-col-24">
            <a-form-item ref="name" label="成果类型" >
              <a-span>{{typeName}}</a-span>
            </a-form-item>
          </div>


        </div>

        <!----- 动态表单 start ------->
        <div v-for="(config,index) in confList" :key="index">
          <div class="ant-row" >
            <div class="ant-col-24">
              <a-form-item :ref="config.code" :label='config.name' >
                <div v-if="config.inputType == 'input'">
                  <a-span>{{config.textValue}}</a-span>
                </div>
                <div v-else-if="config.inputType == 'textarea'">
                  <a-span>{{config.textValue}}</a-span>
                </div>
                <div v-else-if="config.inputType == 'data'">
                  <a-date-picker
                      show-time
                      type="date"
                      style="width: 100%"
                      format="YYYY/MM/DD"
                      disabled="true"
                      v-model:value="config.textValue"
                  />
                </div>
                <div v-else-if="config.inputType == 'select'">
                  <a-span>{{config.textValue}}</a-span>
                </div>

                <div v-else-if="config.inputType == 'radio'">
                  <a-radio-group v-model:value="config.textValue" :name="config.code">
                    <a-radio v-for="(info,index) in config.input_list" :key="index" :value="info" disabled="true">{{info}}</a-radio>
                  </a-radio-group>
                </div>

                <div v-else-if="config.inputType == 'checkbox'">
                  <a-checkbox-group v-model:value="config.textValue" :name="config.code">
                    <a-checkbox v-for="(info,index) in config.input_list" :key="index" :value="info" disabled="true" name="type">{{info}}</a-checkbox>
                  </a-checkbox-group>
                </div>

              </a-form-item>
            </div>
          </div>
        </div>
        <!----- 动态表单 end ------->
        <div class="ant-row">
          <div class="ant-col-24">
            <a-form-item ref="name" label="样例图片" >
              <div class="clearfix">
                <a-image-preview-group  v-for="(file,i) in fileList_img" :key="i">
                  <div style="float: left;margin-left: 5px;">
                    <a-image  style="height: 60px;" :src="file" />
                  </div>
                </a-image-preview-group>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="ant-row">
          <div class="ant-col-24">
            <a-form-item ref="name" label="样例视频" >
              <div class="ant-row" v-for="(file,index) in fileList_v" :key="index">
                <div class="ant-col-12">
                  <a @click="downloadFile(file.url,file.name)" >{{file.name}}</a>
                </div>
                <div class="ant-col-8">
                  <a-button  type="link" @click="online_preview(file.url)">
                    <template #icon><EyeTwoTone title="在线预览"/></template>
                  </a-button>

                  <a-button type="link" @click="downloadFile(file.url,file.name)">
                    <template #icon><Download title="下载"/></template>
                  </a-button>
                </div>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="ant-row">
          <div class="ant-col-24">
            <a-form-item ref="name" label="附   件" >
              <div class="ant-row" v-for="(file,index) in fileList_fj" :key="index">
                <div class="ant-col-12">
                  <a @click="downloadFile(file.url,file.name)" >{{file.name}}</a>
                </div>
                <div class="ant-col-8">
                  <a-button type="link" @click="downloadFile(file.url,file.name)">
                    <template #icon><Download title="下载"/></template>
                  </a-button>
                </div>
              </div>
            </a-form-item>
          </div>
        </div>
      </a-form>
  </div>
  <!-- 信息 end -->

  <!-- 在线预览 -->
  <online-preview ref="onlinePreview"/>
</template>

<script>
import file_api from "../common/config/file_api";
import {GetCurrentFileUrl} from "../../../../assets/utils/general";
import achieveType_api from "../config/achieveType_api";
import Icons from "../../../common/Icons";
import {PlusOutlined} from "@ant-design/icons-vue";
import onlinePreview from "../common/point/onlinePreview";

export default {
  name: "mapInfo_list",
  mixins:[Icons],
  components: {
    PlusOutlined,onlinePreview
  },
  data(){
    return{
      labelCol:{ span: 8 },
      wrapperCol:{ span: 14 },
      info_list:[],

      listStyle:'layerList_n',

      infoStyle:'layerInfo_n',
      infoName:"",
      fh_url:require('../common/images/map/info-默认.png'),


      /** 信息查看 start **/
      itemName:null,
      name:null,
      areaName:null,
      typeName:null,
      confList:[],// 动态表单内容

      /** 文件处理 ***/
      // 图片
      accept_img:"image/png,image/jpeg",
      fileList_img:[],
      previewVisible:false,
      previewImage:'',
      imageUrl:file_api.upload.url,

      // 视频
      accept_v:"video",
      fileList_v:[],
      video_url:file_api.upload.url,

      // 附件
      fileList_fj:[],
      fj_url:file_api.upload.url,
      /** 信息查看 end **/
    }
  },
  methods:{
    openShow(list_info){
      if(list_info == null){
        this.closeShow();
        this.info_list = [];
        return;
      }
      this.listStyle = "layerInfo_s";
      this.infoStyle = "layerInfo_n";
      this.info_list = list_info;
    },
    /**
    * @Description:关闭
    * @params:
    * @return:
    * @Author: rzl
    * @Date: 2022/4/21
    */
    closeShow(){
      this.listStyle = "layerList_n";
      this.infoStyle = "layerInfo_n";
    },
    /**
    * @Description: 查看信息
    * @params:
    * @return:
    * @Author: rzl
    * @Date: 2022/6/15
    */
    show_info(info){
      let geomList = info.geomList;
      let geom = geomList.length > 0 ? geomList[0].geom : null;
      let data = this.$GetEmitData(this.$eventTags.wkt_show,geom);
      this.$emit("event",data);

      this.infoName = info.name;
      this.listStyle = "layerList_n";
      this.infoStyle = "layerInfo_s";
      this.itemName = info.itemName;
      this.name = info.name;
      this.areaName = (info.provName !=null ? info.provName : '')
          + (info.cityName != null ? info.cityName : '')
          + (info.countyName != null ? info.countyName : '')
          + (info.streetName != null ? info.streetName : '')
          + (info.villageName != null ? info.villageName : '') ;
      this.typeName = info.typeName;
      this.fileList_v = [];
      this.fileList_fj = [];
      this.fileList_img = [];
      let imgUrl = info.imgUrl;
      this.fileList_img = imgUrl != null && imgUrl != '' ? imgUrl.split(";") : [];
      let videoUrl = info.videoUrl;
      let videoList = videoUrl != null && videoUrl != '' ? videoUrl.split(";") : [];
      if(videoList.length > 0){
        for (let i=0;i<videoList.length;i++){
          let vi = videoList[i];
          let num = i+1;
          let url = GetCurrentFileUrl(vi);
          let list = {
            name: '视频'+num,
            url: vi,
          }
          this.fileList_v.push(list)
        }
      }

      let fileUrl = info.fileUrl;
      let fileist = fileUrl != null && fileUrl != '' ? fileUrl.split(";") : [];
      if(fileist.length > 0){
        for (let i=0;i<fileist.length;i++){
          let vi = fileist[i];
          let num = i+1;
          let url = GetCurrentFileUrl(vi);
          let list = {
            name: '附件'+num,
            url: vi,
          }
          this.fileList_fj.push(list)
        }
      }
      let str_json = info.propJson;
      let confList = str_json == null || str_json == '' ? [] : JSON.parse(str_json);
      let parms = {
        id:info.typeId,
      };
      achieveType_api.queryOne.requestPOSTUrlParam(this,parms,res=>{

        if(res.data.flag){
          let list = res.data.data.confList;
          if(list.length > 0){
            for(let infoc of list){
              let inputType = infoc.inputType;
              infoc.textValue = "";
              for(var key in confList){
                let code = infoc.code;
                if(key == code){
                  if(inputType == 'checkbox'){
                    infoc.textValue = confList[key].split(";");
                  }else{
                    infoc.textValue = confList[key];
                  }
                }
              }

              if(inputType == 'select' || inputType == 'radio' || inputType == 'checkbox'){
                let valueList = infoc.valueList;
                let list = valueList.split(";");
                infoc.input_list = [];
                if(list.length > 0){
                  for(let lt of list){
                    if(lt != ''){
                      infoc.input_list.push(lt);
                    }
                  }
                }

              }
            }
          }
          this.visible = true;
          this.confList = list;
        }
      });
    },
    info_fn(){
      this.listStyle = "layerList_s";
      this.infoStyle = "layerInfo_n";
      this.$emit("event",this.$GetEmitData(this.$eventTags.mapInfo_back));
    },
    enter_op(){
      this.fh_url = require('../common/images/map/info-悬停.png');
    },
    leave_op(){
      this.fh_url = require('../common/images/map/info-默认.png');
    },
    /** 文件 **/
    downloadFile(url,name){
      let data = {
        fileUrl:url
      };
      file_api.download.fileDownload(this,data,name);
    },

    /** 在线预览 **/
    online_preview(url){
      this.$refs.onlinePreview.setFileUrl(url);
    },

  }
}
</script>

<style scoped>
.layer_list{
  z-index: 1000;
  color: #ffffff;
  position: absolute;
  margin-left: 73% !important;
  margin-top: 1%;

  text-align: left;

  width: 300px;
  height: 60%;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}
.layer_info{
  z-index: 1000;
  color: #ffffff;
  position: absolute;
  margin-left: 64% !important;
  margin-top: 1%;

  text-align: left;

  width: 25%;
  height: 70%;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}
#layerList_s{
  display: block;
}
#layerList_n{
  display: none;
}
#layerInfo_s{
  display: block;
}
#layerInfo_n{
  display: none;
}
.info_title{
  font-size: 18px;
  font-weight: 500;
  color: #33485B;
  line-height: 20px;
  float: left;
  padding: 10px;
}
.info_tb{
  padding: 10px;
  margin-left: 85%;
}
</style>