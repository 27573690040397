/** 
* 自动生成文件，请勿修改 
* @name: 职位：职位级别晋升申请
* @description: 职位级别晋升申请(三期新增)
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const promotionApply = { 
	/** 1 summary:单条数据查询	method:post */ 
	queryOne : new APIObject("/api/promotionApply/queryOne.do"), 
	/** 2 summary:所有数据查询	method:post */ 
	queryAll : new APIObject("/api/promotionApply/queryAll.do"), 
	/** 3 summary:查询-（有维度需要自己打分的）当前登录用户为评分人	method:post */ 
	queryByJudgeUser : new APIObject("/api/promotionApply/queryByJudgeUser.do"), 
	/** 4 summary:单条删除	method:post */ 
	delete : new APIObject("/api/promotionApply/delete.do"), 
	/** 5 summary:分页查询	method:post */ 
	queryPage : new APIObject("/api/promotionApply/queryPage.do"), 
	/** 6 summary:保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/promotionApply/saveOrUpd.do"), 
	/** 7 summary:提交	method:post */ 
	submit : new APIObject("/api/promotionApply/submit.do"), 
	/** 8 summary:撤销提交	method:post */ 
	cancelSubmit : new APIObject("/api/promotionApply/cancelSubmit.do"), 
	/** 9 summary:根据申请id导出对应晋升审批表	method:get */ 
	exportById : new APIObject("/api/promotionApply/exportById.do"), 
};
export default promotionApply;