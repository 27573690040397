<!--
* @program: office_automation 
* @author: ly
* @component:appMenuOpBind 
* @description: 
* @create: 2023-05-06 13:58
-->
<template>
    <a-tag style="cursor: pointer;font-size: 18px" @click="setOps(item)" v-for="item in menuPermissions" :key="item" :color="getEnable(item)?'blue':''">{{item}}</a-tag>
</template>

<script>
    export default {
        name: "appMenuOpBind",
        props:["mops","sops"], //mops  menu所有能选的ops,sops  该角色拥有的ops
        emits:["update:sops"],
        computed:{
            menuPermissions(){
                if(this.mops){
                    return  this.mops.split(",");
                }
                else{
                    return [];
                }
            }
        },
        data() {
            return {
                lops:[],//本地暂存的角色ops
            }
        },
        watch:{
            sops(){
                this.init()
            }
        },
        methods: {
            init() {
                if(this.sops){
                    this.lops = this.sops.split(",");
                }
                else{
                    this.lops = [];
                }
            },
            getEnable(item){
                let op = this.lops.find(i=>i === item);
                return !!op;
            },
            setOps(item){
                let op = this.lops.find(i=>i === item);
                if(op){
                    let index = this.lops.indexOf(op);
                    this.lops.splice(index,1);
                }
                else{
                    this.lops.push(item);
                }
                this.$emit("update:sops",this.lops.toString());
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>