/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_task_process_employee  工序-员工关系管理--生产工序
 * @create: 2021-04-25 10:35
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import api_sys_user_info from "../api/userInfo";
import api_item_outsourc_info from "../api/outSourceInfo";
export default class item_task_process_employee extends Table{
    constructor() {
        super();
        this.name = "item_task_process_employee";
        this.CNName = "工序-员工关系管理";
        this.columnArray = [
            //new Column("工序表ID","processId",ColumnType.String,false),
            new Column("任务类别","processType",ColumnType.String,false)
                .setEnum(["1","2"],["生产","质检"],["blue","cyan"]).setDefaultValue("1"),
            new Column("成员类型","personType",ColumnType.Enum,true).setTableView(100)
                .setEnum(["1","2","3"],["内部员工","外协单位","外协人员"],["green","purple","purple"])
                .setTriggerOtherColumn("1",
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="userId"})[0]
                            .setIdReplaceObject("userName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}});
                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="userId"})[0]
                            .setIdReplaceObject("userName",{api:api_item_outsourc_info.queryAll,tag:"queryText"});
                    }),
            new Column("成员","userId",ColumnType.IdReplace,true).setTableView(150)
                .setIdReplaceObject("userName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("工作比例","workRatio",ColumnType.Number,true).setNumberRange(0,100).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("工作内容","content",ColumnType.String,true).setTableView(200),
            new Column("任务开始时间","startDate",ColumnType.Date,true).setTableView(100),
            new Column("预计完成时间","endDate",ColumnType.Date,true).setTableView(100),
            new Column("实际完成时间","completeDate",ColumnType.Date,false).setTableView(100),
            new Column("完成状态","state",ColumnType.Enum,false).setTableView(100)
                .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"]),
            new Column("完成情况说明","describe",ColumnType.String,false).setTableView(200),
            new Column("备注","remarks",ColumnType.String,true).setTableView(100),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(100)];
    }
}