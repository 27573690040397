<!--
* @program: TYH_office_automation 
* @author: ly
* @component:assetsView 
* @description: 
* @create: 2021-06-21 10:03
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar @select="handleSelect" :search-options="searchOptions" v-model:value="searchData"/>
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="addData" >
                    <template #icon><PlusOutlined /></template>
                </a-button>
                <assetsCategoryTree class="search-item" @change="doSearch" v-model:value="queryOptions.categoryId" />
                <QueryFilter :filter="filter" v-model:value="queryOptions" @change="doSearch" />
            </div>

                <TcBaseTableFilter :download="true" :loading="loading" :view-model="true" :page-size="pageSize" :table-columns="tableColumns" :data-source="dataSource" >
                    <template #action="{index,record}">
                        <div style="text-align: center">
                            <a-button  class="action-button" type="link" @click="showDetail(record)">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                            <a-button  class="action-button" type="link" @click="editDetail(record)">
                                <EditFilled class="pointer"/>
                            </a-button>
                            <a-tooltip v-if="!record.mainId"  title="绑定从属设备">
                                <a-button  class="action-button" type="link" @click="binding(record)">
                                    <LinkOutlined  class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip v-if="record.mainId"  title="解除绑定">
                                <a-button  class="action-button" type="link" @click="unbinding(record)">
                                    <LinkOutlined style="color: red"  class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-popconfirm
                                    v-if="record.state==='0'"
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="启用该设备?"
                                    @confirm="enableRow(record)">
                                <a-tooltip  title="启用该设备">
                                    <a-button  class="action-button" type="link" >
                                        <CheckCircleOutlined style="color: green" class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </a-popconfirm>
                            <a-popconfirm
                                    v-if="record.state==='1'"
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="停用该设备?"
                                    @confirm="disableRow(record)">
                                <a-tooltip  title="停用该设备">
                                    <a-button   class="action-button" type="link" >
                                        <MinusCircleOutlined style="color: orange" class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </a-popconfirm>
                            <a-tooltip  title="借还历史">
                                <a-button class="action-button" type="link" @click="deviceHistory(record)">
                                    <ClockCircleOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>

                          <a-popconfirm
                              v-if="record.currentUseUserName != null"
                              ok-text="是"
                              cancel-text="否"
                              class="action-button"
                              title="是否强制归还设备?"
                              @confirm="rollbackOutlined(record)">
                            <a-tooltip  title="强制归还设备">
                              <a-button  class="action-button" type="link" >
                                <RollbackOutlined style="color: #893cb3" class="pointer"/>
                              </a-button>
                            </a-tooltip>
                          </a-popconfirm>

                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(index,record)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </div>
                    </template>
                </TcBaseTableFilter>
            </div>
        </div>

    <a-modal v-model:visible="modalVisible"
             destroy-on-close
             :title="title"
             :maskClosable="viewModel"
             :footer="null">
        <FcForm style="width: 400px" :view-model="viewModel" :form-columns="formColumns" :form-data="currentRecord">
            <template #action="{record}">
                <a-button class="search-item" type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-modal>
    <a-drawer
            v-model:visible="visible"
            height="700px"
            placement="bottom"
            :closable="false"
            title="绑定从属设备"
            @close="selectedRows = []"
    >
        <div  class="search-bar">
            <SearchBar :search-options="searchOptions" v-model:value="searchData2"/>
            <a-button class="search-item" type="dashed" shape="circle" @click="doSearch2">
                <template #icon><SearchOutlined /></template>
            </a-button>
            <a-button class="search-item" type="dashed" shape="circle" @click="refresh2" >
                <template #icon><SyncOutlined /></template>
            </a-button>
            <QueryFilter :filter="filter" v-model:value="queryOptions2" @change="doSearch2" />
            <assetsCategoryTree class="search-item" @change="doSearch2" v-model:value="queryOptions2.categoryId" />
            <a-popconfirm
                    ok-text="是"
                    cancel-text="否"
                    title="确定绑定吗?"
                    @confirm="saveBinding">
                <a-button  type="primary" >
                    绑定
                </a-button>
            </a-popconfirm>
        </div>
        <TcSelectTable v-if="visible" @selectChange="handleSelectChange" :loading="loading2" :view-model="true" :page-size="pageSize" :table-columns="tableColumns" :data-source="dataSource2"/>
    </a-drawer>

    <a-modal v-model:visible="historyVisible"
             destroy-on-close
             :width="800"
             title="借还历史"
             :footer="null">
        <TwoParts :enable-change="false" :trans="[50,50]">
            <template #left>
                <span>申请历史</span>
                <TcBaseTable :loading="historyLoading" :view-model="true" :page-size="15" :table-columns="cols" :data-source="applyDS"/>
            </template>
            <template #right>
                <span>归还历史</span>
                <TcBaseTable :loading="historyLoading" :view-model="true" :page-size="15" :table-columns="cols" :data-source="backDS"/>
            </template>
        </TwoParts>
    </a-modal>
</template>

<script>
    import device from "../../../assets/tables/device";
    import api_device from "../../../assets/api/device";
    import FcForm from "../../../components/form/FcForm";
    import TcBaseTableFilter from "../../../components/table/TcBaseTableFilter";
    import {SearchOutlined,SyncOutlined,PlusOutlined,CheckCircleOutlined,LinkOutlined,ClockCircleOutlined,
        MinusCircleOutlined,DeleteOutlined,EditFilled,RollbackOutlined} from "@ant-design/icons-vue"
    import userInfo from "../../../assets/api/userInfo";
    import assetsCategoryTree from "./assetsCategoryTree";
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import department from "../../../assets/api/department";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import TcSelectTable from "../../../components/table/TcSelectTable";
    import QueryFilter from "./QueryFilter";
    import deviceApply from "../../../assets/api/deviceApply";
    import deviceBack from "../../../assets/api/deviceBack";
    import TwoParts from "../../_components/TwoParts";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    export default {
        name: "assetsView",
        components:{
            assetsCategoryTree,
            FcForm,TcBaseTableFilter,SearchOutlined,SyncOutlined,PlusOutlined,
            CheckCircleOutlined,MinusCircleOutlined,DeleteOutlined,RollbackOutlined,EditFilled,
            SearchBar,LinkOutlined,TcSelectTable,QueryFilter,
            ClockCircleOutlined,TwoParts,TcBaseTable
        },
        computed:{
            queryData(){
                let data = {...this.queryOptions};
                Object.keys(data).map(item=>{
                    if(!data[item]){
                        delete data[item]
                    }
                });
                return data;
            },
            queryData2(){
                let data = {...this.queryOptions2};
                Object.keys(data).map(item=>{
                    if(!data[item]){
                        delete data[item]
                    }
                });
                return data;
            }
        },
        data(){
            return{
                table:new device(),
                searchData:null,
                filter:{
                    state:[["0","1"],["停用","启用"],["orange","green"]],
                    useState:[["0","1"],["未借出","已借出"],["green","pink"]],
                    mainFlag:[["0","1"],["全部","主设备"],["red","purple"]],
                    permanentFlag:[["0","1"],["内部使用","已送出"],["blue","red"]]
                },
                dataSource:null,//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                currentRecord:null,//提供Form的formData

                pageSize:15,
                queryOptions:{
                    state:"1", // 启用状态：0：停用，1：启用
                    useState:"0", // useState 设备借出状态：0：未借出（可以借用的），1：已借出（不能借用的）
                    categoryId:null,// categoryId 所属设备分类id:(可以是大类id,也可以是小类id)
                    queryText:null, // queryText 设备名称或编号，模糊查询
                    "departId": null,
                    "mainFlag": "1", //是否主设备 0：显示全部；1：只显示主设备
                    permanentFlag:"0"
                },
                viewModel:true,
                title:"详情",
                searchOptions:[
                    new SearchSegment("设备名/编号", "queryText"),
                    new SearchSegment("用户名", "userId"),
                ],
                modalVisible:false,
                visible:false,
                dataSource2:null,//提供table的DataSource
                loading2:false,//table的loading状态
                queryOptions2:{
                    state:"1", // 启用状态：0：停用，1：启用
                    useState:"0", // useState 设备借出状态：0：未借出（可以借用的），1：已借出（不能借用的）
                    categoryId:null,// categoryId 所属设备分类id:(可以是大类id,也可以是小类id)
                    queryText:null, // queryText 设备名称或编号，模糊查询
                    "departId": null,
                    "mainFlag": "1", //是否主设备
                },
                searchData2:null,
                selectedRows:null,

                cols:[
                    new Column("申请人","userId",ColumnType.IdReplace).setTableView(80)
                        .setIdReplaceObject("userName",{}),
                    new Column("审核状态","auditState",ColumnType.Enum).setTableView(80)
                        .setEnum(["0","1","2"],["未审核","通过","不通过"],["purple","green","red"]),
                    new Column("时间","createTime",ColumnType.Date).setTableView(100),
                ],
                applyDS:[],
                backDS:[],
                historyVisible:false,
                historyLoading:false
            }
        },
        methods:{
            init(){
                this.loading = true;
                api_device.queryAll.requestPOST(this,this.queryData,res=>{
                    if(res.data.flag){
                        res.data.data.map(item=>{
                            item.children10 = item.children;
                        });
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = [];
                    }
                    this.loading = false;
                });
            },
            doSearch(){
                if(Object.keys(this.searchData)[0]==="userId"){
                    let data = {
                        queryText:this.searchData.userId,
                        disableState:"1",loginState:"0"
                    };
                    userInfo.queryAll.requestPOSTUrlParam(this,data,res=>{
                        if(res.data.flag){
                            res.data.data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                            if(res.data.data.length===1){
                                let userId = res.data.data[0].id;
                                let searchOption = {userId:userId,useState:"1"};//useState:"1" 已借出
                                Object.assign(searchOption,this.queryData);
                                this.loading = true;
                                api_device.queryAll.requestPOST(this,searchOption,res=>{
                                    this.loading = false;
                                    if(res.data.flag){
                                        res.data.data.map(item=>{
                                            item.children10 = item.children;
                                        });
                                        this.dataSource = res.data.data;
                                    }
                                    else{
                                        this.dataSource = []
                                    }
                                    this.loading = false;
                                });
                            }
                            else{
                                this.$message.info("查无此人");
                            }
                        }
                        else{
                            this.$message.info("查无此人");
                        }
                    })
                }
                else{
                    this.loading = true;
                    let searchOption = {...this.searchData};
                    Object.assign(searchOption,this.queryData);
                    api_device.queryAll.requestPOST(this,searchOption,res=>{
                        this.loading = false;
                        if(res.data.flag){
                            res.data.data.map(item=>{
                                item.children10 = item.children;
                            });
                            this.dataSource = res.data.data;
                        }
                        else{
                            this.dataSource = [];
                        }
                        this.loading = false;
                    });
                }
            },
            doSearch2(){
                if(Object.keys(this.searchData2)[0]==="userId"){
                    let data = {
                        queryText:this.searchData2.userId,
                        disableState:"1",loginState:"0"
                    };
                    userInfo.queryAll.requestPOSTUrlParam(this,data,res=>{
                        if(res.data.flag){
                            res.data.data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                            if(res.data.data.length===1){
                                let userId = res.data.data[0].id;
                                let searchOption = {userId:userId,useState:"1"};//useState:"1" 已借出
                                Object.assign(searchOption,this.queryData2);
                                api_device.queryAll.requestPOST(this,searchOption,res=>{
                                    if(res.data.flag){
                                        this.dataSource2 = res.data.data.filter(item=>{
                                            return item.children.length === 0
                                        });
                                    }
                                    else{
                                        this.dataSource2 = []
                                    }
                                    this.loading2 = false;
                                });
                            }
                            else{
                                this.$message.info("查无此人");
                            }
                        }
                        else{
                            this.$message.info("查无此人");
                        }
                    })
                }
                else{
                    let searchOption = {...this.searchData2};
                    Object.assign(searchOption,this.queryData2);
                    api_device.queryAll.requestPOST(this,searchOption,res=>{
                        if(res.data.flag){
                            this.dataSource2 = res.data.data.filter(item=>{
                                return item.children.length === 0
                            });
                        }
                        else{
                            this.dataSource2 = [];
                        }
                        this.loading2 = false;
                    });
                }
            },
            refresh(){
                this.init();
            },
            refresh2(){
                this.loading2 = true;
                api_device.queryAll.requestPOST(this,this.queryData2,res=>{
                    if(res.data.flag){
                        this.dataSource2 = res.data.data.filter(item=>{
                            return item.children && item.children.length === 0
                        });
                    }
                    else{
                        this.dataSource2 = [];
                    }
                    this.loading2 = false;
                });
            },
            addData(){
                this.title = "添加新设备";
                this.currentRecord = this.table.getFormData();
                this.modalVisible = true;
                this.viewModel = false;
            },
            showDetail(record){
                this.title = "设备详情";
                this.currentRecord = record;
                this.modalVisible = true;
                this.viewModel = true;
            },
            editDetail(record){
                this.title = "设备详情";
                this.currentRecord = record;
                this.modalVisible = true;
                this.viewModel = false;
            },
            enableRow(record){
                record.state = "1";
                api_device.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        record.state = "0";
                        this.$message.error(res.data.msg);
                    }
                });
            },
          rollbackOutlined(record){
            let data = {deviceId:record.id};
            deviceBack.forceBack.requestPOSTUrlParam(this,data,res=>{
              if(res.data.flag){
                record.currentUseUserName = null;
                this.$message.success(res.data.msg);
              }
              else{
                this.$message.error(res.data.msg)
              }
            });
          },
            disableRow(record){
                record.state = "0";
                api_device.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        record.state = "1";
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
              let fixedAsset = record.fixedAsset;
              if(fixedAsset == '0'){
                  record.fixedCategory = null;
                  record.price = null;
              }
                api_device.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.modalVisible=false;
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData(index,record){
                let data = {id:record.id};
                api_device.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            binding(record){
                this.currentRecord = record;
                this.visible = true;
                this.selectedRows = [];
                this.refresh2();
            },
            saveBinding(){
                if(this.selectedRows.length>0){
                    for(let i in this.selectedRows){
                        this.selectedRows[i].mainId = this.currentRecord.id;
                    }
                    api_device.batchSaveOrUpd.requestPOST(this,{deviceVOList:this.selectedRows},res=>{
                        if(res.data.flag){
                            this.visible = false;
                            this.$message.success(res.data.msg)
                        }
                        else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }
                else{
                    this.$message.error("请选择从属设备")
                }
            },
            handleSelectChange(selectedRowKeys,selectedRows){
                this.selectedRows = selectedRows;
            },
            unbinding(record){
                let data = {...record};
                data.mainId = "";
                api_device.saveOrUpd.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handleSelect(type){
                if(type === "userId"){
                    this.queryOptions = {
                        state:"1", // 启用状态：0：停用，1：启用
                        useState:"1", // useState 设备借出状态：0：未借出（可以借用的），1：已借出（不能借用的）
                        categoryId:null,// categoryId 所属设备分类id:(可以是大类id,也可以是小类id)
                        queryText:null, // queryText 设备名称或编号，模糊查询
                        "departId": null,
                        "mainFlag": "1", //是否主设备
                    };
                }
                if(type === "departId"){
                    this.queryOptions = {
                        state:"1", // 启用状态：0：停用，1：启用
                        useState:"1", // useState 设备借出状态：0：未借出（可以借用的），1：已借出（不能借用的）
                        categoryId:null,// categoryId 所属设备分类id:(可以是大类id,也可以是小类id)
                        queryText:null, // queryText 设备名称或编号，模糊查询
                        "departId": null,
                        "mainFlag": "1", //是否主设备
                    };
                }
            },
            async deviceHistory(record){
                this.historyVisible = true;
                this.historyLoading = true;
                let data = {
                    deviceId:record.id
                };

                let res = await deviceApply.queryAll.requestPOSTAsync(data);
                if(res.flag){
                    this.applyDS = res.data;
                }
                else{
                    this.applyDS = [];
                }
                let res1 = await deviceBack.queryAll.requestPOSTAsync(data);
                if(res1.flag){
                    this.backDS = res1.data;
                }
                else{
                    this.backDS = [];
                }
                this.historyLoading = false
            }
        },
        created() {
            this.tableColumns = this.table.getTableColumns();
            this.tableColumns.unshift(
                new Column("","expend",ColumnType.File).setTableView(60),
            );
            this.formColumns = this.table.getFormColumns();
            department.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    let ids = [] , names = [];
                    for(let i in res.data.data){
                        ids.push(res.data.data[i].id);
                        names.push(res.data.data[i].name);
                    }
                    this.searchOptions.push(new SearchSegment("部门", "departId").setEnum(ids,names))

                }
                else{
                    this.departments = [];
                }
            })
        }
    }
</script>

<style scoped>

</style>