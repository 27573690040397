<!--
* @program: office_automation 
* @author: ly
* @component:PersonalAssessment
* @description: 个人考核中心
* @create: 2022-02-25 15:10
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div v-if="visible" style="display: flex;flex-wrap: wrap;max-height: 100%;overflow: auto;">
                <div class="flat-component" v-for="item in showComps" :key="item.comp" @click="clickItem(item)" :style="item.style" >
                    {{item.title}}
                </div>
            </div>
            <div style="right: 100%" v-else>
                <a-page-header style="padding: 0; border: 1px solid rgb(235, 237, 240)">
                    <template #title>
                        <div style="display: flex;">
                            <div style="margin: 20px">
                                <a-button style="margin-right: 20px" danger @click = "visible = true" >返回</a-button>
                                <span >{{currentComp.title}}</span>
                            </div>
                        </div>
                    </template>
                </a-page-header>
                <div v-is="currentComp.comp" />
            </div>
        </div>
    </div>
</template>

<script>
    import MonthlyMyAssessment from "./Monthly/MyAssessment";
    import MonthlyDoAssessment from "./Monthly/DoAssessment";
    import DoYAssessment from "./Yearly/DoYAssessment";
    import MyYAssessment from "./Yearly/MyYAssessment";
    import MyPromotion from "./Promotion/MyPromotion";
    import DoPromotion from "./Promotion/DoPromotion";
    export default {
        name: "PersonalAssessment",
        components:{
            MonthlyMyAssessment,MonthlyDoAssessment,DoYAssessment,
            MyYAssessment,MyPromotion,DoPromotion
        },
        computed:{
            showComps(){
                return this.components.filter(item=>item.enable)
            }
        },
        data(){
            return{
                components:[
                    {title:"月度考核结果",comp:"MonthlyMyAssessment",enable:true,
                        style:"width:300px;background-image: linear-gradient(#1890ff, #fff);"},
                    {title:"月度考核评分",comp:"MonthlyDoAssessment",enable:true,
                        style:"width:300px;background-image: linear-gradient(#1890ff, #fff);"},
                    {title:"年度考核信息",comp:"MyYAssessment",enable:true,
                        style:"width:300px;background-image: linear-gradient(#5265FF, #fff);"},
                    {title:"年度考核评分",comp:"DoYAssessment",enable:true,
                        style:"width:300px;background-image: linear-gradient(#5265FF, #fff);"},
                    {title:"职位晋升申请",comp:"MyPromotion",enable:true,
                        style:"width:300px;background-image: linear-gradient(#FF3853, #fff);"},
                    {title:"职位晋升评分",comp:"DoPromotion",enable:true,
                        style:"width:300px;background-image: linear-gradient(#FF3853, #fff);"},
                ],
                currentComp:null,
                visible:true
            }
        },
        methods:{
            clickItem(item){
                this.currentComp = item;
                this.visible = false;
            }
        }
    }
</script>

<style scoped>

</style>