<!--
* @program: TYH_office_automation 
* @author: ly
* @component:projectOutSourcePayViewer 
* @description: 项目支付一览，带审核项目支付功能
* @create: 2021-06-30 13:56
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <a-tabs @change="change_init" v-model:activeKey="activeKey">
                <a-tab-pane key="1">
                    <template #tab>
                    <span>
                      我的项目支付审核&nbsp;&nbsp;
                      <a-tooltip color="#c38c1f" placement="bottom" title="没有支出时间的项目支付是由于录入的历史支付信息">
                         <InfoCircleOutlined style="color: #faad14;"/>
                      </a-tooltip>

                    </span>
                    </template>

                    <div class="search-bar">
                        <a-select
                                :options="op_state"
                                @change="refresh"
                                placeholder="审核状态"
                                style="width: 150px"
                                v-model:value="state"
                        >
                        </a-select>
                        <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                            <template #icon>
                                <SearchOutlined/>
                            </template>
                        </a-button>
                    </div>
                    <TcBaseTable :data-source="dataSource" :loading="loading" :page-size="pageSize"
                                 :scroll="{x:1000}" :table-columns="tableColumns" :view-model="true">
                        <template #action="{index,record}">
                            <div class="center-flex">
                                <a-tooltip title="查看信息">
                                    <a-button @click="show(index,record)" class="action-button" type="link"
                                              v-if="state!='1'">
                                        <SearchOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip title="审核流程">
                                    <a-button @click="stepDetail2(index,record)" class="action-button" type="link"
                                              v-if="state!='1'">
                                        <UnorderedListOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip title="支付审核">
                                    <a-button @click="audit(index,record)" class="action-button" type="link"
                                              v-if="state=='1'">
                                        <Audit class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </TcBaseTable>
                </a-tab-pane>
                <!--                <a-tab-pane  key="2" tab="我的项目支付审核记录" >
                                    <projectOutSourcePayAuditHistory ref="myPayAuditHistory"/>
                                </a-tab-pane>-->
                <a-tab-pane key="2">
                    <template #tab>
                    <span>
                      所有项目支付申请记录&nbsp;&nbsp;
                      <a-tooltip color="#c38c1f" placement="bottom"
                                 title="已完成支付的数据如果【没有支付时间或审核流程信息】，则为录入的历史数据，只作记录使用，不参与资金账户支出计算">
                         <InfoCircleOutlined style="color: #faad14;"/>
                      </a-tooltip>

                    </span>
                    </template>
                    <div class="search-bar">

                        <a-select
                                :default-active-first-option="false"
                                :filter-option="false"
                                :not-found-content="null"
                                :show-arrow="false"
                                @change="columnTrigger"
                                @search="handleSearch"
                                allowClear="true"
                                placeholder="输入项目名称查询"
                                show-search
                                style="width: 300px"
                                v-model:value="itemName"
                        >
                            <a-select-option :key="option.id" v-for="option in item_data">
                                {{ option.name }}
                            </a-select-option>
                        </a-select>

                        <a-select
                                :default-active-first-option="false"
                                :filter-option="false"
                                :not-found-content="null"
                                :show-arrow="false"
                                @change="columnTrigger_out"
                                @search="handleSearch_out"
                                allowClear="true"
                                placeholder="输入外协单位名称"
                                show-search
                                style="margin-left: 10px;width: 300px"
                                v-model:value="outName"
                        >
                            <a-select-option :key="option.id" v-for="option in outData">
                                {{ option.name }}
                            </a-select-option>
                        </a-select>


                        <a-select
                                :options="op_approvalState"
                                @change="init2"
                                allow-clear
                                placeholder="是否审核完成"
                                style="margin-left: 10px;width: 200px"
                                v-model:value="approvalState"
                        >
                        </a-select>

                        <a-select
                                :options="op_payState"
                                @change="init2"
                                allow-clear
                                placeholder="支付状态"
                                style="margin-left: 10px;width: 200px"
                                v-model:value="payState"
                        >
                        </a-select>

                        <a-select
                                @change="columnTrigger_dep"
                                allow-clear
                                class="search-item"
                                placeholder="部门选择"
                                style="width: 250px"
                                v-model:value="departmentId"
                        >
                            <a-select-option :key="item.id" v-for="item in departments">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>

                        <a-date-picker @change="init2" placeholder="请选择支付开始时间"
                                       style="margin-left: 10px;margin-right: 10px;"
                                       v-model:value="payDateStart"/>
                        <a-date-picker @change="init2" placeholder="请选择支付结束时间"
                                       v-model:value="payDateEnd"/>

                        <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                            <template #icon>
                                <SyncOutlined/>
                            </template>
                        </a-button>
                    </div>
                    <TcBaseTable :data-source="dataSource2" :download="true" :loading="loading"
                                 :page-size="pageSize" :table-columns="tableColumns2" :view-model="true">
                        <template #action="{index,record}">
                            <div class="center-flex">
                                <a-tooltip title="查看信息">
                                    <a-button @click="show(index,record)" class="action-button" type="link">
                                        <SearchOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip title="审核流程">
                                    <a-button @click="stepDetail2(index,record)" class="action-button" type="link">
                                        <UnorderedListOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </TcBaseTable>
                    <div style="border: 1px solid rgb(235, 237, 240);font-size: 15px;color: red;">
                        合同总金额：{{sumAmount_sum}}
                        <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                        已付款金额：{{sumCollect_sum}}
                        <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                        未付款金额：{{sumCollectNo_sum}}
                    </div>
                </a-tab-pane>

                <a-tab-pane key="3">
                    <template #tab>
                    <span>
                      支付申请统计
                    </span>
                    </template>

                    <div class="search-bar">
                        <a-select
                                :options="op_contractState"
                                @change="init3"
                                allow-clear
                                class="ant-col-4"
                                placeholder="是否有外协合同"
                                style="margin-left: 10px"
                                v-model:value="contractState"
                        >
                        </a-select>
                        <!--                  <a-date-picker v-model:value="payDateStart" placeholder="请选择支付开始时间"
                                                         style="margin-left: 10px;margin-right: 10px;"/>
                                          <a-date-picker v-model:value="payDateEnd" placeholder="请选择支付结束时间"/>-->
                        <a-button @click="init3" class="search-item" shape="circle" type="dashed">
                            <template #icon>
                                <SearchOutlined/>
                            </template>
                        </a-button>

                        <!--                  <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                                            <template #icon>
                                              <SyncOutlined/>
                                            </template>
                                          </a-button>-->

                    </div>
                    <TcBaseTable :data-source="dataSource3" :download="true" :loading="loading"
                                 :page-size="pageSize" :scroll="{x:1000}" :table-columns="tableColumns3" :view-model="true">
                        <template #action="{index,record}">
                            <div class="center-flex">
                                <a-tooltip title="查看信息">
                                    <a-button @click="show(index,record)" class="action-button" type="link">
                                        <SearchOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </TcBaseTable>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
    <!-- 信息 start-->
    <a-modal :closable="false"

             :destroyOnClose="true" style="margin-top: 2%;"
             title="信息详情"
             v-model:visible="visible_show"
             width="45%"
    >

        <ar-form :argument="argument" ref="bill_form" viewModel="true"/>

        <template #footer>
            <a-button @click="visible_show =false" key="back">取消</a-button>
            <a-button @click="stepDetail" key="back" style="background-color: #faad14;color: #fafafa;">审核流程</a-button>
            <a-button @click="openAudit" key="back" type="primary" v-if="viewModel">审核</a-button>
        </template>
    </a-modal>
    <!-- 信息 end-->

    <!-- 审核进度 start -->
    <a-drawer placement="bottom"
              title="审核进度" v-model:visible="stepVisible">
        <projectOutSourcePayAuditStatus :record="formData"/>
    </a-drawer>
    <!-- 审核进度 end -->

    <!-- 审核信息 start -->
    <a-modal :closable="false"
             :maskClosable="false"
             destroyOnClose
             title="审核"
             v-model:visible="auditVisible">
        <a-radio-group @change="radioChange" v-model:value="value">
            <a-radio :value="'3'">不通过</a-radio>
            <a-radio :value="'2'">通过</a-radio>
        </a-radio-group>
        <a-divider>审核意见</a-divider>
        <div style="margin: 5px">
            审核意见模板：
            <a-button @click="autoInput" size="small" type="link">{{defaultComment}}</a-button>
        </div>
        <a-textarea :rows="4" placeholder="请输入审核意见" size="large" v-model:value="comment"/>
        <template #footer>
            <a-button @click="auditVisible =false" key="back">取消</a-button>
            <a-popconfirm
                    @confirm="auditSubmit"
                    cancel-text="否"
                    ok-text="是"
                    title="提交审核吗?">
                <a-button key="back" type="primary" v-if="viewModel">提交审核</a-button>
            </a-popconfirm>
        </template>
    </a-modal>
    <!-- 审核信息 end   :body-style="{width:'80%',marginLeft:'16%'}"-->

    <a-modal :destroyOnClose="true"
             :footer="null"
             :title="payInfoName"
             style="margin-left: 16%;"
             v-model:visible="visible_info"
             width="80%">
        <ArBaseTable :argument="argument3" :download="false" :page-size="6" :view-model="true">
            <template #action="{index,record}">
                <a-button @click="showDetail_pay(record,index)" type="link">
                    <template #icon>
                        <Show title="查看"/>
                    </template>
                </a-button>
            </template>
        </ArBaseTable>

        <div v-if="flag_pay_info">
            <a-divider orientation="left">申请部门：{{applyDepartName}}
                <a-divider style="height: 85%;top: 6%; background-color: #7cb305;" type="vertical"/>
                收款单位：{{outSourceName}}
            </a-divider>
            <div style="height: 280px;overflow: auto;box-shadow: 0px 0px 5px #888888;width: 96%;margin: auto;">
                <ar-form :argument="argument3" ref="bill_form" viewModel="true"/>
            </div>

        </div>
        <span style="font-size:20px">
      合同总金额（￥）：{{sumAmount}} <a-divider style="height: 25px;background-color: #7cb305;" type="vertical"/>
      已付款金额（￥）：{{sumPay}} <a-divider style="height: 25px;background-color: #7cb305;" type="vertical"/>
      未付款金额（￥）：{{amount_wf}}
    </span>
    </a-modal>
</template>

<script>
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import Icons from "../../common/Icons";
    import ArForm from "../../../components/form/ArForm";
    import payApply from "../../../assets/api/payApply";
    import payApproval from "../../../assets/api/payApproval";
    import TcBaseTable from "../../../components/table/TcBaseTableFilter";
    import {SearchOutlined, SwapOutlined, SyncOutlined} from "@ant-design/icons-vue"
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {companyColors, companyIds, companyNames} from "../../../assets/system/companies";
    import projectOutSourcePayAuditStatus from "../projectRelateInfo/projectOutSourcePayAuditStatus";
    import projectOutSourcePayAuditHistory from "./projectOutSourcePayAuditHistory";

    import achieveRecord_api from "../../qualityManager/DataProducts/config/achieveRecord_api";
    import api_item_outsourc_info from "../../../assets/api/outSourceInfo";
    import DepartmentPicker from "../../../components/UserPicker/DepartmentPicker";
    import department from "../../../assets/api/department";
    import itemInfo from "../../../assets/api/itemInfo";
    import moment from "moment";

    export default {
        name: "projectOutSourcePayViewer",
        mixins: [Icons],
        components: {
            TcBaseTable, SearchOutlined, SyncOutlined, SwapOutlined, ArForm,
            projectOutSourcePayAuditStatus, projectOutSourcePayAuditHistory, DepartmentPicker, ArBaseTable
        },
        computed: {
            defaultComment() {
                return this.value === "2" ? "符合支付要求，通过审核。" : "不符合支付要求，不通过审核。"
            }
        },
        data() {
            return {
                activeKey: "1",
                pageSize: 15,
                formData: null,
                dataSource: null,// 我的待审核列表
                tableColumns: null, //提供table的字-段集 this.table.getTableColumns()
                loading: false,//table的loading状态

                stepVisible: false,
                auditVisible: false,
                value: "2", //是否通过 审批状态(2:通过,3:不通过)
                comment: "", //审核意见
                dataSource2: null, //所有支付数据一览
                tableColumns2: [],
                state: '1',
                op_state: [
                    /*{value: '0',label: '未到达',},*/
                    {value: '1', label: '待审核',},
                    {value: '2', label: '已通过',},
                    {value: '3', label: '未通过',},
                ],
                visible_show: false,
                viewModel: false,
                argument: {
                    formColumns: null,
                    formData: {},
                },

                /** 所有支付审核查询条件 ***/
                // 项目搜索
                itemId: null,
                itemName: null,
                item_data: [],

                // 外协单位搜索
                outId: null,// 外协单位
                outName: null,
                outData: [],

                departmentId: null,
                departments: [],

                approvalState: null,
                op_approvalState: [
                    {value: '0', label: '未完成',},
                    {value: '1', label: '已完成',},
                ],

                payState: null,
                op_payState: [
                    {value: '0', label: '未支付',},
                    {value: '1', label: '已支付',},
                ],

                dataSource3: null,// 我的待审核列表
                tableColumns3: null, //提供table的字-段集 this.table.getTableColumns()
                contractState: null,
                op_contractState: [
                    {value: '0', label: '无外协合同（但有外协付款）',},
                    {value: '1', label: '有外协合同',},
                ],
                payDateStart: null,
                payDateEnd: null,
                visible_info: false,
                payInfoName: '',
                argument3: {
                    dataSource: [],//提供table的DataSource
                    tableColumns: null, //提供table的字-段集 this.table.getTableColumns()
                    loading: false,//table的loading状态
                    formColumns: null,
                    formData: {},
                },
                table_info: null,
                flag_pay_info: false,
                applyDepartName: '',
                outSourceName: '',
                sumAmount: 0,
                sumPay: 0,
                amount_wf: 0,
                itemId3: null,
                dateStart: null,
                dateEne: null,

                sumAmount_sum: 0,
                sumCollect_sum: 0,
                sumCollectNo_sum: 0,
            }
        },
        created() {
            this.tableColumns = [
                new Column("序号", "_index", ColumnType.Index).setTableView(60).setVisible(false),
                new Column("项目名称", "itemName", ColumnType.String, true).setTableView(250),
                new Column("付款合同", "contractName", ColumnType.PreViewFile, true).setTableView(100),
                new Column("付款合同编号", "contractNum", ColumnType.String, true),
                new Column("合同总金额", "amount", ColumnType.Number, true).setTableView(100),
                new Column("已付款金额", "amount_yf", ColumnType.Number, true).setTableView(100),
                new Column("未付款金额", "amount_wf", ColumnType.Number, true).setTableView(100),
                new Column("申请支付金额", "payAmount", ColumnType.Number, true).setTableView(100),
                new Column("申请付款部门", "applyDepartName", ColumnType.String, true).setTableView(150),
                new Column("收款单位/个人", "outSourceName", ColumnType.String, true).setTableView(150),
                new Column("付款公司", "payCompanyId", ColumnType.Enum, true).setTableView(150)
                    .setEnum(companyIds, companyNames, companyColors),
                new Column("支付描述", "payReason", ColumnType.String, true),
                new Column("操作", "actions", "actions").setTableView(80).setFixed("right").setVisible(false),
            ];
            /**
             * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
             * approvalState 是否全部审核通过：0：未完成；1：已完成
             * payState 支付状态：0：未支付；1：已支付
             */
            this.tableColumns2 = [
                new Column("序号", "_index", ColumnType.Index).setTableView(60).setVisible(false),
                new Column("项目名称", "itemName", ColumnType.String, true).setTableView(250),
                new Column("付款合同", "contractName", ColumnType.PreViewFile, true).setTableView(120),
                new Column("付款合同编号", "contractNum", ColumnType.String, true),
                new Column("合同总金额", "amount", ColumnType.Number, true).setTableView(100),
                new Column("已付款金额", "amount_yf", ColumnType.Number, true).setTableView(100),
                new Column("未付款金额", "amount_wf", ColumnType.Number, true).setTableView(100),
                new Column("申请支付金额", "payAmount", ColumnType.Number, true).setTableView(100),
                new Column("申请付款部门", "applyDepartName", ColumnType.String, true).setTableView(150),
                new Column("收款单位/个人", "outSourceName", ColumnType.String, true).setTableView(150),
                new Column("付款公司", "payCompanyId", ColumnType.Enum, true)
                    .setEnum(companyIds, companyNames, companyColors),
                new Column("审核状态", "approvalState", ColumnType.String, true).setTableView(80)
                    .setEnum(["0", "1"], ["未完成", "已完成"], ["pink", "green"]),
                new Column("支付状态", "payState", ColumnType.Enum, true).setTableView(80)
                    .setEnum(["0", "1"], ["未支付", "已支付"], ["red", "cyan"]),
                new Column("支付时间", "payTime", ColumnType.Date, true),
                new Column("支付描述", "payReason", ColumnType.String, true),
                new Column("操作", "actions", "actions").setTableView(60).setFixed("right").setVisible(false),
            ];

            this.tableColumns3 = [
                new Column("序号", "_index", ColumnType.Index).setTableView(60).setVisible(false),
                new Column("项目名称", "name", ColumnType.String, true).setTableView(250),
                new Column("合同总金额", "sumAmount", ColumnType.Number, true).setTableView(100),
                new Column("已付款金额", "sumPay", ColumnType.Number, true).setTableView(100),
                new Column("未付款金额", "amount_wf", ColumnType.Number, true).setTableView(100),
                new Column("操作", "actions", "actions").setTableView(80).setFixed("right").setVisible(false),
            ];
            this.table_info = [
                new Column("序号", "_index", ColumnType.Index).setTableView(60).setVisible(false),
                new Column("付款合同", "contractName", ColumnType.String, true).setTableView(120),
                new Column("付款合同编号", "contractNum", ColumnType.String, true),
                new Column("合同总金额", "amount", ColumnType.Number, true).setTableView(100),
                new Column("已付款金额", "amount_yf", ColumnType.Number, true).setTableView(100),
                new Column("未付款金额", "amount_wf", ColumnType.Number, true).setTableView(100),
                new Column("申请支付金额", "payAmount", ColumnType.Number, true).setTableView(100),
                new Column("申请付款部门", "applyDepartName", ColumnType.String, true),
                new Column("收款单位/个人", "outSourceName", ColumnType.String, true).setTableView(150),
                new Column("付款公司", "payCompanyId", ColumnType.Enum, true)
                    .setEnum(companyIds, companyNames, companyColors),
                new Column("审核状态", "approvalState", ColumnType.String, true)
                    .setEnum(["0", "1"], ["未完成", "已完成"], ["pink", "green"]),
                new Column("支付状态", "payState", ColumnType.Enum, true)
                    .setEnum(["0", "1"], ["未支付", "已支付"], ["red", "cyan"]),
                new Column("支付时间", "payTime", ColumnType.Date, true).setTableView(100),
                new Column("支付描述", "payReason", ColumnType.String, true),
                new Column("操作", "actions", "actions").setTableView(60).setFixed("right").setVisible(false),
            ];

            this.argument3.tableColumns = this.table_info;
            department.queryAll.requestPOST(this, {}, res => {
                if (res.data.flag) {
                    this.departments = res.data.data;
                } else {
                    this.departments = [];
                }
            })
            this.init();
        },
        methods: {
            change_init() {
                let type = this.activeKey;
                switch (type) {
                    case "1": {
                        this.init();
                        break;
                    }
                    case "2": {
                        this.init2();
                        break;
                    }
                    case "3": {
                        this.init3();
                        break;
                    }
                }
            },
            refresh() {
                let type = this.activeKey;
                switch (type) {
                    case "1": {
                        this.init();
                        break;
                    }
                    case "2": {
                        this.itemId = null;
                        this.itemName = null;
                        this.outId = null;
                        this.outName = null;
                        this.departmentId = null;
                        this.approvalState = null;
                        this.payState = null;
                        this.payDateStart = null;
                        this.payDateEnd = null;
                        this.init2();
                        break;
                    }
                    case "3": {
                        this.contractState = null;

                        this.dateStart = null;
                        this.dateEne = null;
                        this.init3();
                        break;
                    }
                }

            },
            show(index, record) {
                let type = this.activeKey;
                switch (type) {
                    case "1": {
                        this.argument.formColumns = this.tableColumns;
                        this.argument.formData = record;
                        this.visible_show = true;
                        this.viewModel = false;
                        break;
                    }
                    case "2": {
                        this.argument.formColumns = this.tableColumns2;
                        this.argument.formData = record;
                        this.visible_show = true;
                        this.viewModel = false;
                        break;
                    }
                    case "3": {
                        this.sumAmount = record.sumAmount;
                        this.sumPay = record.sumPay;
                        this.amount_wf = record.amount_wf;
                        this.itemId3 = record.id;
                        this.payInfoName = "支付申请记录：" + record.name;

                        this.flag_pay_info = false;
                        this.applyDepartName = '';
                        this.outSourceName = '';
                        this.countShow();
                        break;
                    }


                }

            },
            init() {
                this.loading = true;
                let parms = {
                    state: this.state,
                };
                payApply.queryMyApproval.requestPOSTUrlParam(this, parms, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        let listData = res.data.data;
                        if (listData.length > 0) {
                            listData.forEach((item, index) => {
                                let contractInfo = item.contractInfo;
                                if (contractInfo != null) {
                                    item.amount = contractInfo.amount;
                                    item.amount_yf = contractInfo.payAmount;
                                    item.amount_wf = (contractInfo.amount - contractInfo.payAmount).toFixed(2)
                                    item.contractName = contractInfo.fileUrl;
                                }
                            })
                        }
                        this.dataSource = listData;
                    } else {
                        this.dataSource = [];
                    }
                });
            },
            stepDetail() {
                this.formData = this.argument.formData;
                this.stepVisible = true;
            },
            stepDetail2(index, record) {
                this.formData = record;
                this.stepVisible = true;
            },
            audit(index, record) {
                this.argument.formColumns = this.tableColumns;
                this.argument.formData = record;
                this.visible_show = true;
                this.viewModel = true;
            },
            openAudit() {
                this.comment = "";
                this.value = "2";
                this.formData = this.argument.formData;
                this.auditVisible = true;
            },
            autoInput() {
                this.comment += this.defaultComment;
            },
            radioChange() {
                this.comment = "";
            },
            auditSubmit() {
                let data = {
                    applyId: this.formData.id,
                    state: this.value,
                    remarks: this.comment
                };
                payApproval.approvalApply.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        this.init();
                        this.$message.success(res.data.msg);
                        this.auditVisible = false;
                        this.visible_show = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },

            /** 所有支付审核查询条件 start ***/
            init2() {
                this.sumAmount_sum = 0;
                this.sumCollect_sum = 0;
                this.sumCollectNo_sum = 0;
                this.loading = true;
                let startDate = this.payDateStart != null ? new moment(this.payDateStart).format("YYYY-MM-DD") : null;
                let endDate = this.payDateEnd != null ? new moment(this.payDateEnd).format("YYYY-MM-DD") : null;
                let parms = {
                    itemId: this.itemId,
                    outSourceId: this.outId,
                    applyDepart: this.departmentId,
                    approvalState: this.approvalState,
                    payState: this.payState,
                    submitState: '1',
                    payDateStart: startDate,
                    payDateEnd: endDate
                };
                payApply.queryAll.requestPOST(this, parms, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        let listData = res.data.data;
                        if (listData.length > 0) {
                            listData.forEach((item, index) => {
                                let contractInfo = item.contractInfo;
                                if (contractInfo != null) {
                                    item.amount = contractInfo.amount;
                                    item.amount_yf = contractInfo.payAmount;
                                    item.amount_wf = (contractInfo.amount - contractInfo.payAmount).toFixed(2)
                                    item.contractName = contractInfo.fileUrl;
                                }

                            })
                            let map = new Map()
                            let mapData = listData.filter(key => !map.has(key.contractId) && map.set(key.contractId, 1)) // 这里对itemName属性进行去重
                            mapData.forEach((itemc, indexc) => {
                                this.sumAmount_sum += itemc.amount == null || itemc.amount == undefined ? 0 : parseFloat(itemc.amount);
                                this.sumCollect_sum += itemc.amount_yf == null || itemc.amount_yf == undefined ? 0 : parseFloat(itemc.amount_yf);
                                this.sumCollectNo_sum += itemc.amount_wf == null || itemc.amount_wf == undefined ? 0 : parseFloat(itemc.amount_wf);
                            })

                        }
                        this.dataSource2 = listData.sort((a, b) => {
                            return b.createTime - a.createTime;
                        });


                    } else {
                        this.dataSource2 = [];
                    }
                });
            },
            handleSearch(val) {
                let parms = {
                    name: val
                }
                achieveRecord_api.queryAll_item.requestPOST(this, parms, res => {
                    if (res.data.flag) {
                        let data = res.data.data;
                        let option_data = [];
                        if (data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                                let info = data[i];
                                let parm = {
                                    id: info.id,
                                    name: info.name
                                };
                                option_data.push(parm);
                            }
                        }
                        this.item_data = option_data;
                    } else {
                        this.item_data = [];
                    }
                });
            },
            columnTrigger(val) {
                this.itemId = val;
                this.init2();
            },
            handleSearch_out(val) {
                let parms = {
                    queryText: val,
                    type: '1'
                }
                api_item_outsourc_info.queryAll.requestPOSTUrlParam(this, parms, res => {
                    if (res.data.flag) {
                        let data = res.data.data;
                        let option_data = [];
                        if (data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                                let info = data[i];
                                let parm = {
                                    id: info.id,
                                    name: info.name
                                };
                                option_data.push(parm);

                            }
                        }
                        this.outData = option_data;
                    } else {
                        this.outData = [];
                    }
                });
            },
            columnTrigger_out(val) {
                this.outId = val == null || val == undefined ? null : val;
                this.init2();
            },
            columnTrigger_dep(val) {
                this.departmentId = val == null || val == undefined ? null : val;
                this.init2();
            },
            /** 所有支付审核查询条件 end ***/

            /*** 支付统计 start **/
            init3() {
                this.loading = true;
                /*  let startDate = this.payDateStart != null ? new moment(this.payDateStart).format("YYYY-MM-DD") : null ;
                  let endDate = this.payDateEnd != null ? new moment(this.payDateEnd).format("YYYY-MM-DD") : null ;
                  this.dateStart = startDate;
                  this.dateEne = endDate;*/
                let parms = {
                    contractState: this.contractState,
                    /* payDateStart:startDate,
                     payDateEnd:endDate*/
                };
                itemInfo.queryOutPay.requestPOSTUrlParam(this, parms, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        let listData = res.data.data;
                        if (listData.length > 0) {
                            listData.forEach((item, index) => {
                                item.sumAmount = item.contractFlag ? item.sumAmount : item.sumPay;
                                item.amount_wf = (item.sumAmount - item.sumPay).toFixed(2);
                            })
                        }
                        this.dataSource3 = listData.sort((a, b) => {
                            return b.createTime - a.createTime;
                        });

                    } else {
                        this.dataSource3 = [];
                    }
                });
            },
            countShow() {
                this.loading = true;
                let parms = {
                    itemId: this.itemId3,
                    /*  payDateStart:this.dateStart,
                      payDateEnd:this.dateEne,*/
                };
                payApply.queryAll.requestPOST(this, parms, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        let listData = res.data.data;
                        if (listData.length > 0) {
                            let sumAmount = 0;
                            listData.forEach((item, index) => {
                                let contractInfo = item.contractInfo;
                                if (contractInfo != null) {
                                    item.amount = contractInfo.amount;
                                    item.amount_yf = contractInfo.payAmount;
                                    item.amount_wf = (contractInfo.amount - contractInfo.payAmount).toFixed(2)
                                    item.contractName = contractInfo.fileUrl;
                                }
                            })
                        }
                        this.argument3.dataSource = listData.sort((a, b) => {
                            return b.createTime - a.createTime;
                        });

                    } else {
                        this.argument3.dataSource = [];
                    }
                    this.visible_info = true;
                });
            },
            showDetail_pay(record, index) {
                this.applyDepartName = record.applyDepartName;
                this.outSourceName = record.outSourceName;
                this.flag_pay_info = true;
                this.argument3.formColumns = this.table_info;
                this.argument3.formData = record;
            },
            /*** 支付统计 end **/
        },

    }
</script>

<style scoped>


</style>