<!--
* @program: tyh-oa 
* @author: ly
* @component:statisticsBar 
* @description: 
* @create: 2021-06-11 12:02
-->
<template>
    <div  class="search-bar">
        <a-select v-model:value="type" style="width: 200px" class="search-item" @select="selectChange">
            <a-select-option v-for="{seg,label} in options" :key="seg" >
                {{label }}
            </a-select-option>
        </a-select>
        <div v-is="currentComp" ref="i_selector"/>
        <a-button class="search-item" style="margin-left: 20px" type="dashed" shape="circle" @click="doSearch">
            <template #icon><SearchOutlined /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
            <template #icon><SyncOutlined /></template>
        </a-button>
        <a-radio-group class="search-item" v-model:value="deleteZero" @change="change">
            <a-radio-button :value="true">删除空数据项</a-radio-button>
            <a-radio-button :value="false">保留空数据项</a-radio-button>
        </a-radio-group>
    </div>
</template>

<script>
    import emitDataCheck from "../emitDataCheck";
    import {PlusOutlined, SyncOutlined, SearchOutlined} from "@ant-design/icons-vue";
    import monthSelector from "./monthSelector";
    import yearSelector from "./yearSelector";
    import periodSelector from "./periodSelector";

    export default {
        name: "statisticsBar",
        mixins:[emitDataCheck],
        components:{
            SearchOutlined,PlusOutlined,SyncOutlined,monthSelector,yearSelector,periodSelector
        },
        data(){
            return{
                options:[
                    {seg:"month",label:"按月统计",comp:"monthSelector"},
                    {seg:"year",label:"按年统计",comp:"yearSelector"},
                    {seg:"period",label:"按时间段统计",comp:"periodSelector"}
                ], //[{seg:"title",label:"账单标题",type:"input"}]
                currentComp:"monthSelector",
                type:"month",
                deleteZero:true
            }
        },
        methods:{
            doSearch(){
                let data = this.$GetEmitData(
                    this.$eventTags.search,
                    {type:this.type,data:this.$refs.i_selector.getValue()}
                    );
                this.$emit("event",data);
                this.deleteZero = true;
            },
            refresh(){
                this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
            },
            selectChange(seg){
                let option = this.options.filter(op=>{ return op.seg === seg})[0];
                this.currentComp = option.comp;
            },
            change(){
                this.$emit("event",this.$GetEmitData("change_delete",this.deleteZero));
            }
        },
    }
</script>

<style scoped>

</style>