<!--
* @program: office_automation 
* @author: ly
* @component:MyAssessment 
* @description: 我的月度考核记录
* @create: 2022-02-25 17:26
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-radio-group @change="radioChange" class="search-item" v-model:value="type">
                    <a-radio value="0">按月份查看</a-radio>
                    <a-radio value="1">查看所有记录</a-radio>
                </a-radio-group>
                <MonthSelector v-if="type === '0'" v-model:value="yearMonth" @change="monthChange"/>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[20,30]" >
                <template #left>
                    <ArBaseTable :key="'yearMonth'"  :argument="argument" :view-model="true" :page-size="12" >
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button v-if="checkJudge(record)" @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        v-if="checkAgree(record) && checkJudge(record)"
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="同意本次打分结果（请在评分完毕后进行操作）?"
                                        @confirm="agree(record)">
                                    <a-tooltip title="同意本次考核结果">
                                        <a-button class="action-button" type="link">
                                            <CheckOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <MonthlyAssessmentViewer :assessment-name="currentRecord.typeName" :view-model="false" :assessment-data="AssessmentData" user-type="user" />
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import ArBaseTable from "../../../../components/table/ArBaseTable";
    import TwoParts from "../../../_components/TwoParts";
    import { SearchOutlined, SyncOutlined,CheckOutlined} from "@ant-design/icons-vue";
    import MonthSelector from "../../../../components/SearchBar/MonthSelector";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import assessmentUser from "../../../../assets/api/assessmentUser";
    import moment from "moment";
    import MonthlyAssessmentViewer
        from "../../../talentManager/staffAssessment/MonthlyAssessment/MonthlyAssessmentViewer";

    export default {
        name: "MyAssessment",
        components: {
            ArBaseTable,TwoParts,SyncOutlined,
            MonthSelector,SearchOutlined,CheckOutlined,
            MonthlyAssessmentViewer
        },
        data(){
            return{
                yearMonth:null,
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:[
                        new Column("年月","yearMonth",ColumnType.String).setTableView(80),
                        new Column("考核标准","typeName",ColumnType.String).setTableView(100),
                        new Column("评分","judgeEnd",ColumnType.Boolean).setTableView(60)
                        .setBoolTags(["未完成","已完成"]),
                        new Column("操作","actions","actions").setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                AssessmentData:null,
                viewModel:true,
                tips:"请选择查询年月",
                loading:false,
                type:"1",
                currentRecord:{}
            }
        },
        methods:{
            init(){
                this.type = "1";
                this.viewModel = true;
                this.AssessmentData = null;
                this.radioChange();
            },
            refresh(){
                this.init();
            },
            monthChange(){
                let data = {
                    userId : this.$store.getters.userInfo.id,
                    yearMonth:this.yearMonth
                };
                this.loading = true;
                assessmentUser.queryAll.requestPOST(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                        for(let i in this.argument.dataSource){
                            this.argument.dataSource[i].judgeEnd = true;
                            for(let j in this.argument.dataSource[i].userDTOS){
                                if(this.argument.dataSource[i].userDTOS[j].judgeEnd === false){
                                    this.argument.dataSource[i].judgeEnd = false
                                }
                            }
                        }
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            tableInit(){
                let data = {
                    userId : this.$store.getters.userInfo.id,
                    yearMonth:"",
                };
                assessmentUser.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data.sort((a,b)=>{
                            return new Date(b.yearMonth) - new Date(a.yearMonth)
                        });
                        for(let i in this.argument.dataSource){
                            this.argument.dataSource[i].judgeEnd = true;
                            for(let j in this.argument.dataSource[i].userDTOS){
                                if(this.argument.dataSource[i].userDTOS[j].judgeEnd === false){
                                    this.argument.dataSource[i].judgeEnd = false
                                }
                            }
                        }
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            radioChange(){
                this.AssessmentData = null;
                switch (this.type) {
                    case "0":{
                        this.monthChange();
                        break;
                    }
                    case "1":{
                        this.tableInit();
                        break;
                    }
                }
            },
            showDetail(record){
                this.currentRecord = record;
                this.AssessmentData = record.userDTOS;
                this.AssessmentData.sort((a,b)=>{
                    return a.sortOrder - b.sortOrder
                });
            },
            agree(record){//同意本月考核信息
                this.showDetail(record);
                for(let i in this.AssessmentData){
                    if(this.AssessmentData[i].agreeFlag === "2"){
                        this.$message.error("有不同意评分的条目，不能批量完成同意操作");
                        return
                    }
                }
                let data = {
                    yearMonth:record.yearMonth,
                    agreeFlag:"1"
                }
                assessmentUser.agreeAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            checkAgree(record){
                for(let i in record.userDTOS){
                    if(record.userDTOS[i].agreeFlag !=="1"){//还未完全同意的情况
                        return true;
                    }
                }
                return  false;
            },
            checkJudge(record){
                for(let i in record.userDTOS){
                    if(!record.userDTOS[i].judgeEnd){//还未打分完成
                        return false;
                    }
                }
                return  true;
            }
        },
        created() {
            this.yearMonth = moment(new Date().valueOf()).format("YYYY-MM");
            this.init();
        }
    }
</script>

<style scoped>

</style>