<!--
* @program: tyh-oa 
* @author: ly
* @component:monthSelector 
* @description: 
* @create: 2021-06-11 14:36
-->
<template>
    <div class="search-item" style="display: flex">
        <div class="statistic-label"> 月份：</div>
        <a-month-picker @change="check" placeholder="请选择月份" style="width: 120px" v-model:value="date" :format="dateFormat" />
    </div>
</template>

<script>
    import moment from "moment";
    import emitDataCheck from "../emitDataCheck";
    import {dateFormat} from "../../assets/utils/general";
    export default {
        name: "monthSelector",
        mixins:[emitDataCheck],
        data(){
            return{
                date:null,
                dateFormat: 'YYYY-MM',
                values:["2020-01","2030-12"] //允许的时间范围
            }
        },
        methods:{
            getValue(){
                return moment(this.date).format(this.dateFormat);
            },
            check(){
                if(this.date && moment(this.date).isBefore(this.values[0])){
                    this.date = moment(this.values[0]);
                }
                if(this.date && moment(this.date).isAfter(this.values[1])){
                    this.date = moment(this.values[1]);
                }
                this.$emit("event",this.$GetEmitData("month_select",this.getValue()));
            },
            setValue(value){
                this.date = value
            }
        },
        created() {
            let date = new Date();
            let month = dateFormat(this.dateFormat,date);
            this.date = moment(month);
        }
    }
</script>

<style scoped>

</style>