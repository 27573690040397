<!--
* @program: TYH_office_automation 
* @author: ly
* @component:projectOutSourcePay 
* @description: 
* @create: 2021-06-30 14:17
-->
<template>
    <div style="display: flex;align-items: center;">
        <span class="statistic-label" >已完成合计：{{summary1}} 元</span>
        <span class="statistic-label" >未完成合计：{{summary0}} 元</span>
        <a-button class="search-item" type="dashed" shape="circle" @click="init1" >
            <template #icon><SyncOutlined /></template>
        </a-button>
        <a-button @click="modalAdd" type="primary">
            添加历史支付（不需要审核）
        </a-button>
    </div>
    <!--项目付款申请 -->
    <ArBaseTable @showRecord="showDetail1"  :page-size="15" :argument="argument1" :view-model="true">
        <template #action="{index,record}">
            <div class="center-flex">
                <a-button @click="showDetail1(index,record)"
                          class="action-button" type="link">
                    <SearchOutlined class="pointer"/>
                </a-button>
                <a-popconfirm
                        @confirm="downloadFlows(index,record)"
                        cancel-text="否"
                        class="action-button"
                        ok-text="是"
                        title="导出审批情况表？"
                        v-if="checkAction('download',record)">
                    <a-tooltip title="导出审批情况表">
                        <a-button class="action-button" style="color: green" type="link">
                            <DownloadOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </a-popconfirm>
                <a-popconfirm
                        @confirm="submit(index,record)"
                        cancel-text="否"
                        class="action-button"
                        ok-text="提交"
                        title="提交审核？"
                        v-if="checkAction('submit',record)">
                    <a-tooltip title="提交审核">
                        <a-button class="action-button" style="color: green" type="link">
                            <ArrowUpOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </a-popconfirm>
                <a-popconfirm
                        @confirm="cancelSubmit(index,record)"
                        cancel-text="否"
                        class="action-button"
                        ok-text="是"
                        title="已审核的申请无法撤销，是否撤销审核？"
                        v-if="checkAction('cancel',record)">
                    <a-tooltip title="撤销审核">
                        <a-button class="action-button" style="color: orange" type="link">
                            <RollbackOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </a-popconfirm>
                <a-tooltip v-if="checkAction('auditDone',record)"  title="完成支付">
                    <a-button @click="auditDone(index,record)" class="action-button" style="color: green" type="link">
                        <CheckOutlined class="pointer"/>
                    </a-button>
                </a-tooltip>
                <a-tooltip v-if="checkAction('editDetail',record)"  title="修改支付信息">
                    <a-button @click="editDetail(record)" class="action-button" style="color: #5265ff" type="link">
                        <EditFilled class="pointer"/>
                    </a-button>
                </a-tooltip>
                <a-tooltip v-if="checkAction('addContract',record)"  title="绑定合同信息">
                    <a-button @click="addContract(record)" class="action-button" style="color: #5265ff" type="link">
                        <PlusOutlined class="pointer"/>
                    </a-button>
                </a-tooltip>
                <a-popconfirm
                        @confirm="deleteData(index,record)"
                        cancel-text="否"
                        class="action-button"
                        ok-text="是"
                        title="删除？"
                        >
<!--                    v-if="checkAction('delete',record)"-->
                    <DeleteOutlined class="pointer" style="color: red"/>
                </a-popconfirm>
            </div>
        </template>
    </ArBaseTable>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="项目支付"
             :maskClosable="vModel"
             :footer="null">
        <projectOutSourcePayApply @refresh="init1" :view-model="false" :form-data="formData" :project-id="projectId" />
    </a-modal>
    <a-modal v-model:visible="visible2"
             :width="1200"
             destroy-on-close
             title="详情"
             :maskClosable="vModel"
             :footer="null">
        <ArBaseForm :argument="formArg" :view-model="vModel" />
        <a-divider style="margin: 0">支付卡号:{{cardInfo}}</a-divider>
        <a-divider >审核状态</a-divider>
        <projectOutSourcePayAuditStatus :record="formArg.formData" />
    </a-modal>
    <a-modal v-model:visible="visible3"
             :width="600"
             destroy-on-close
             title="确认支付完成"
             :maskClosable="vModel"
             :footer="null">
        <div class="center-flex">
            <div style="width: 200px">请选择支付日期：</div>
            <DatePicker style="width: 200px" v-model:value="date" />
        </div>
        <div class="center-flex">
            <div style="width: 200px">选择支付资金账户：</div>
            <a-select v-model:value="cardId"
                      style="width:300px" class="search-item"
                      :options="cardOptions"
            />
        </div>
        <div class="center-flex">
            <div style="width: 200px">选择支付发票：</div>
            <invoicePayoffSelector :projectId="projectId" v-model:invoiceId="invoiceId" />
        </div>
        <a-divider/>
        <a-button type="primary" @click="payConfirm">
            提交
        </a-button>
    </a-modal>

    <a-modal v-model:visible="visible4"
             destroy-on-close
             title="项目支付"
             :maskClosable="vModel"
             :footer="null">
        <projectOutSourcePayApplyAddContractId @refresh="init1" :view-model="false" :form-data="formData" :project-id="projectId" />
    </a-modal>
</template>

<script>
    import {DeleteOutlined,SearchOutlined} from "@ant-design/icons-vue";
    import item_outsourc_pay from "../../../assets/tables/item_outsourc_pay";
    import item_outsourc_pay_apply from "../../../assets/tables/item_outsourc_pay_apply";
    import payApply from "../../../assets/api/payApply";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import projectOutSourcePayApply from "./projectOutSourcePayApply";
    import projectOutSourcePayAuditStatus from "./projectOutSourcePayAuditStatus";
    import projectOutSourcePayApplyAddContractId from "./projectOutSourcePayApplyAddContractId";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import ICONS from "../../_components/ICONS";
    import DatePicker from "../../_components/DatePicker";
    import bankBalance from "../../../assets/api/bankBalance";
    import invoicePayoffSelector from "../../financialManager/invoiceManager/invoicePayoffSelector";
    export default {
        name: "projectOutSourcePay",
        props:["projectId","viewModel"],
        mixins: [ICONS],
        components:{
            ArBaseTable,projectOutSourcePayApply,DeleteOutlined,SearchOutlined,
            ArBaseForm,projectOutSourcePayAuditStatus,DatePicker,
            invoicePayoffSelector,projectOutSourcePayApplyAddContractId
        },
        computed:{
            summary0(){
                let sum=0;
                for(let i in this.argument1.dataSource){
                    if(this.argument1.dataSource[i].payState === "0"){
                        sum += this.argument1.dataSource[i].payAmount*100;
                    }
                }
                return sum/100;
            },
            summary1(){
                let sum=0;
                for(let i in this.argument1.dataSource){
                    if(this.argument1.dataSource[i].payState === "1"){
                        sum += this.argument1.dataSource[i].payAmount*100;
                    }
                }
                return sum/100;
            },
            cardInfo(){
                if(this.formArg.formData && this.formArg.formData.bankId){
                    let cards =  this.cardOptions.filter(card=>{return card.id === this.formArg.formData.bankId});
                    return cards[0].label;
                }
                else{
                    return "无银行卡信息"
                }
            }
        },
        data(){
            return{
                table:new item_outsourc_pay(),
                table1:new item_outsourc_pay_apply(),
                argument1:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                    currentIndex:null, //当前选中的条目
                },
                formArg:{
                    formColumns:[],
                    formData:null
                },
                formData:null,
                visible:false,
                visible2:false,
                vModel:true,
                visible3:false,
                date:null,//支付日期
                cardId:null,//支付银行卡号
                cardOptions:[],

                invoiceId:null,//支付发票ID

                visible4:false,
            }
        },
        methods:{
            init1(){
                this.argument1.loading = true;
                let data = {itemId:this.projectId};
                payApply.queryAll.requestPOST(this,data,res=>{
                    this.argument1.loading = false;
                    if(res.data.flag){
                        this.argument1.dataSource = res.data.data;
                    }
                    else{
                        this.argument1.dataSource = []
                    }
                })
            },
            modalAdd(){//添加历史支付
                this.visible = true;
                this.formData = this.table1.getFormData();
                this.formData.approvalState = "0" ;
                this.formData.payState = "1";
                this.formData.history = true;
            },
            showDetail1(index,record){
                this.argument1.currentIndex = index;
                this.formArg.formData = record;
                this.formArg.formColumns = this.table1.getFormColumns();
                this.visible2 = true;
                this.vModel = true;
            },
            editDetail(record){
                this.visible3 = true;
                this.formArg.formData = record;
                this.date = null;
            },
            addContract(record){//添加合同信息
                this.visible4 = true;
                this.formData = record;
            },
            submit(index,record){
                this.argument1.currentIndex = index;
                let data = {
                    id: record.id
                };
                payApply.submit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init1();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            cancelSubmit(index,record){
                this.argument1.currentIndex = index;
                let data = {
                    id: record.id
                };
                payApply.cancelSubmit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init1();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            downloadFlows(index,record){
                this.argument1.currentIndex = index;
                let data = {id: record.id};
                let fileName = "审核详情.xls";
                payApply.exportById.fileDownload(this, data, fileName);
            },
            deleteData(index,record){
                this.argument1.currentIndex = index;
                let data = {id:record.id};
                payApply.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument1.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                record.itemId = this.projectId;
                payApply.save.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            auditDone(index,record){
                this.argument1.currentIndex = index;
                this.visible3 = true;
                this.formArg.formData = record;
                this.date = null;
            },
            payConfirm(){
                if(!this.date){
                    this.$message.error("请选择支付日期");
                    return
                }
                let data = {
                    id:this.formArg.formData.id,
                    payState:"1",
                    payTime:this.date,
                    bankId:this.cardId,
                    invoiceId:this.invoiceId
                };
                payApply.payConfirm.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible3 = false;
                        this.init1();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            checkAction(action, record) {
                /**
                 * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
                 * approvalState 是否全部审核通过：0：未完成；1：已完成
                 * payState 支付状态：0：未支付；1：已支付
                 */
                switch (action) {
                    case "edit": {
                        return record.submitState === "0" && record.payState === "0" && !this.viewModel;
                    }
                    case "submit": {
                        return record.submitState === "0" && record.payState === "0" && !this.viewModel;
                    }
                    case "delete": {
                        return record.submitState === "0" && record.payState === "0" && !this.viewModel;
                    }
                    case "cancel": {
                        return record.submitState === "1" && record.approvalState === "0" && !this.viewModel;
                    }
                    case "download": {
                        return record.submitState === "1" && record.approvalState === "1";
                    }
                    case "editDetail": {//没有银行卡或者发票信息的时候可以修改
                        //待恢复 return record.approvalState === "1" && (!record.bankId || !record.invoiceId) && !this.viewModel;
                        return record.approvalState === "1" && !this.viewModel
                    }
                    case "addContract": {//没有合同信息
                        return record.approvalState === "1" && !record.contractId  && !this.viewModel;
                    }
                    case "auditDone":{
                        return record.approvalState === "1" && record.payState === "0" && !this.viewModel;
                    }
                }
            },
        },
        created() {
            this.table.actions = [];
            this.argument1.tableColumns = this.table1.getTableColumns();
            this.init1();
            bankBalance.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.cardOptions = [];
                    for(let i in res.data.data){
                        this.cardOptions.push({
                            id:res.data.data[i].id,
                            value:res.data.data[i].id,
                            label:res.data.data[i].bankName +"【"+ res.data.data[i].bankNum + "】"
                        })
                    }
                }
                else{
                    this.$message.error(res.data.msg);
                }
            });
        }

    }
</script>

<style scoped>

</style>