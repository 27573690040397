<!--
* @program: office_automation 
* @author: ly
* @component:SoftwareAdd 
* @description: 
* @create: 2022-05-26 19:29
-->
<template>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="软件"
             :width="800"
             :maskClosable="false"
             :footer="null">
        <div class="center-flex">
            <UploadFile :view-model="viewModel" v-model:value="imageUrl" title="上传示例图片" mime="image/png,image/jpeg" />
            <UploadFile :view-model="viewModel" v-model:value="videoUrl" title="上传示例视频" mime="video/mp4"/>
            <UploadFile :view-model="viewModel" v-model:value="fileUrl" title="上传软件(必传)" />
        </div>
        <FcForm style="width: 600px" :view-model="viewModel" :form-columns="fc" :form-data="fd">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-modal>
</template>

<script>
    import UploadFile from "../../_components/UploadFile";
    import software from "../../../assets/tables/software";
    import FcForm from "../../../components/form/FcForm";
    import softwareTools from "../../../assets/api/softwareTools";
    export default {
        name: "SoftwareAdd",
        emits:["refresh"],
        components:{
            UploadFile,FcForm
        },
        data() {
            return {
                visible:false,
                viewModel:true,
                record:null,
                imageUrl:null,
                videoUrl:null,
                fileUrl:null,
                fc:null,
                fd:null,
            }
        },
        methods: {
            open(formData,viewModel){//查看或修改已经录入的软件
                this.visible = true;
                this.fd = formData;
                this.viewModel = viewModel;
            },
            add(record,viewModel){//添加新软件
                this.visible = true;
                this.record = record;
                this.viewModel = viewModel;
                this.fd = new software().getFormData();
                this.imageUrl = null;
                this.videoUrl = null;
                this.fileUrl = null;
            },
            saveData(record){
                record.categoryId = this.record.id;
                record.imageUrl = this.imageUrl?this.imageUrl:record.imageUrl;
                record.videoUrl = this.videoUrl?this.videoUrl:record.videoUrl;
                record.fileUrl = this.fileUrl?this.fileUrl:record.fileUrl;
                if(!this.fileUrl){
                    this.$message.error("请上传软件");
                    return
                }
                softwareTools.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.$message.success(res.data.msg);
                        this.$emit("refresh");
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        created() {
            this.fc = new software().getFormColumns();
        }
    }
</script>

<style scoped>

</style>