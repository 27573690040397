<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualityApplyViewer
* @description: 
* @create: 2021-07-02 15:53
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData" />
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <a-radio-group class="search-item" v-model:value="auditState" @change="radioChange">
                    <a-radio value="0">未审核</a-radio>
                    <a-radio value="1">已通过</a-radio>
                    <a-radio value="2">未通过</a-radio>
                    <a-radio value="全部">全部</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[50,50]" >
                <template #left>
                    <TcBaseTable ref="local_table" :page-size="pageSize" :view-model="true" :data-source="dataSource"
                                 @pageChange = "handlePageChange"
                                 :table-columns="tableColumns" :loading="loading" >
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button class="action-button" type="link" @click="showDetail(index,record)">
                                    <SearchOutlined  class="pointer" />
                                </a-button>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <div v-if="formArg.formData">
                        <a-page-header
                                style="border: 1px solid rgb(235, 237, 240)"
                                title="申请详情"
                        />
                        <ArForm :view-model="true" :argument="formArg" />
                        <a-button v-if="formArg.formData.auditState === '0'" style="margin: 10px" type="primary"  @click="audit">
                            审核申请
                        </a-button>
                    </div>
                    <div v-else>
                        <welcome2 />
                    </div>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="modalVisible"
             destroyOnClose
             title="审核意见">
        <a-radio-group class="search-item" v-model:value="currentState">
            <a-radio value="1">通过</a-radio>
            <a-radio value="2">不通过</a-radio>
        </a-radio-group>
        <div style="display: flex">
            <a-textarea v-model:value="reason" :auto-size="{ minRows: 2, maxRows: 6 }"/>
        </div>
        <template #footer>
            <a-button key="back" @click="modalVisible =false">取消</a-button>
            <a-button key="submit" type="primary"  @click="saveData">提交</a-button>
        </template>
    </a-modal>
</template>


<script>
    import item_qc_apply2 from "../../../assets/tables/item_qc_apply2";
    import api_item_qc_apply from "../../../assets/api/qcApply";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import TwoParts from "../../_components/TwoParts";
    import {FormOutlined,SyncOutlined,SearchOutlined} from "@ant-design/icons-vue"
    import ArForm from "../../../components/form/ArForm";
    import Welcome2 from "../../welcome2";
    export default {
        name: "qualityApplyViewer",
        components:{
            Welcome2,
            TcBaseTable,
            SearchBar,SyncOutlined,TwoParts,SearchOutlined,
            ArForm
        },
        computed:{
            visible(){
                return this.formArg.formData.auditState === "0";
            }
        },
        data(){
            return{
                table: new item_qc_apply2(),
                dataSource:[],
                tableColumns:[],
                loading:false,
                pageSize:15,
                searchData:null,
                searchOptions:[
                    {value:"queryText",label:"关键字"}
                ],
                defaultQuery:{

                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                modalVisible:false,
                auditState:"0",
                reason:null,
                currentState:"1"
            }
        },
        methods:{
            init(){
                this.auditState = "0";
                this.searchData = null;
                let data = {
                    auditState:this.auditState
                };
                let pageData = {
                    page:1,
                    limit:this.pageSize,
                };
                this.loading = true;
                api_item_qc_apply.queryPage.requestPOSTBothParam(this,{urlParam:pageData,formParam:data}, res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.local_table.setTotalSize(res.data.count);
                        this.$refs.local_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            refresh(){
                this.init()
            },
            doSearch(){
                let data = {};
                Object.assign(data,this.searchData);
                let pageData = {
                    page:1,
                    limit:this.pageSize,
                };
                this.loading = true;
                api_item_qc_apply.queryPage.requestPOSTBothParam(this,{urlParam:pageData,formParam:data}, res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.local_table.setTotalSize(res.data.count);
                        this.$refs.local_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            radioChange(){
                let data = {};
                if(this.auditState !== "全部"){
                    data.auditState = this.auditState
                }
                Object.assign(data,this.searchData);
                let pageData = {
                    page:1,
                    limit:this.pageSize,
                };

                this.loading = true;
                api_item_qc_apply.queryPage.requestPOSTBothParam(this,{urlParam:pageData,formParam:data}, res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.local_table.setTotalSize(res.data.count);
                        this.$refs.local_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handlePageChange(arg){
                let data = {};
                if(this.auditState !== "全部"){
                    data.auditState = this.auditState
                }
                Object.assign(data,this.searchData);
                api_item_qc_apply.queryPage.requestPOSTBothParam(this,{urlParam:arg,formParam:data}, res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.local_table.setTotalSize(res.data.count);
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                });
            },
            showDetail(index,record){
               this.formArg.formData = record;
            },
            audit(){
                this.modalVisible = true;
                this.currentState = "1";
                this.reason = null;
            },
            saveData(){
                let data={
                    id:this.formArg.formData.id,
                    auditState:this.currentState,
                    auditNote:this.reason
                };
                api_item_qc_apply.audit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.modalVisible = false;
                        this.init();
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        mounted() {
            this.tableColumns = this.table.getTableColumns();
            this.formArg.formColumns = this.table.getFormColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>