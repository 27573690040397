/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_contract_info
 * 项目--合同使用
 * @create: 2021-06-09 09:42
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";
import api_item_party_info from "../api/partyInfo";
import api_item_outsourc_info from "../api/outSourceInfo";
import api_sys_user_info  from "../api/userInfo";
import api_item_info from "../api/itemInfo";
import {companyIds,companyNames,companyColors} from "../system/companies";
import contractType from "../../view/projectManager/contractType";

export default class item_contract_info extends Table{
    constructor() {
        super();
        this.name = "item_contract_info";
        this.CNName = "合同信息表";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String).setVisible(false),
            new Column("所属项目","itemId",ColumnType.IdReplace2,true).setVisible(false)
                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                        "startTime": "", "tenderWay": ""
                    }}), //
            new Column("类别","type",ColumnType.Enum,true).setTableView(100)
                .setEnum(contractType.value,contractType.label,contractType.color)
                .setTriggerOtherColumn("" || null,
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="associatedKey"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="name"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="number"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="amount"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="auditUser"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="receiveUser"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="sigCompany"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="sigDate"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="sigUser"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="submitUser"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="signDate"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="signState"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="signUser"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="sealFlag"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="describe"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="fileUrl"})[0].setVisible(false);

                        /*cols.filter(col=>{return col.dataIndex==="collectUser"})[0].setVisible(false);*/
                    },
                    (formData,cols)=>{
                        if(formData["type"] === '1'){
                            cols.filter(col=>{return col.dataIndex==="associatedKey"})[0]
                                .setIdReplaceObject("associatedName",{api:api_item_party_info.queryAll,tag:"queryText"})
                                .setTitle("甲方");
                        }else if(formData["type"] === '2'){
                            cols.filter(col=>{return col.dataIndex==="associatedKey"})[0]
                                .setIdReplaceObject("associatedName",{api:api_item_outsourc_info.queryAll,tag:"queryText"})
                                .setTitle("外协单位");
                        }else if(formData["type"] === '4'){
                            cols.filter(col=>{return col.dataIndex==="associatedKey"})[0]
                                .setIdReplaceObject("associatedName",{api:api_item_party_info.queryAll,tag:"queryText"})
                                .setTitle("甲方");
                        }else if(formData["type"] === '5'){
                            cols.filter(col=>{return col.dataIndex==="associatedKey"})[0]
                                .setIdReplaceObject("associatedName",{api:api_item_outsourc_info.queryAll,tag:"queryText"})
                                .setTitle("外协单位");
                        }
                        else if(formData["type"] === '6'){
                            cols.filter(col=>{return col.dataIndex==="associatedKey"})[0]
                                .setIdReplaceObject("associatedName",{api:api_item_outsourc_info.queryAll,tag:"queryText"})
                                .setTitle("个人外协");
                        }
                        else if(formData["type"] === '7'){
                            cols.filter(col=>{return col.dataIndex==="associatedKey"})[0]
                                .setIdReplaceObject("associatedName",{api:api_item_party_info.queryAll,tag:"queryText"})
                                .setTitle("甲方");
                        }
                      /*  if(formData["type"] === '1' || formData["type"] === '4' ){
                            cols.filter(col=>{return col.dataIndex==="collectUser"})[0].setVisible(true);
                        }else{
                            cols.filter(col=>{return col.dataIndex==="collectUser"})[0].setVisible(false);
                        }*/

                        if(formData["type"] === '1' || formData["type"] === '2'
                            || formData["type"] === '4' || formData["type"] === '5' || formData["type"] === '6'){
                            cols.filter(col=>{return col.dataIndex==="fileUrl"})[0]
                                .setTitle("合同文件");
                            cols.filter(col=>{return col.dataIndex==="associatedKey"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="name"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="number"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="amount"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="auditUser"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="receiveUser"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="sigCompany"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="sigDate"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="sigUser"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="submitUser"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="signDate"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="signState"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="signUser"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="sealFlag"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="describe"})[0].setVisible(false);
                        }else if(formData["type"] === '3'){
                            cols.filter(col=>{return col.dataIndex==="fileUrl"})[0]
                                .setTitle("中标文件");
                            cols.filter(col=>{return col.dataIndex==="associatedKey"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="name"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="number"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="amount"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="auditUser"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="receiveUser"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="sigCompany"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="sigDate"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="sigUser"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="submitUser"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="signDate"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="signState"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="signUser"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="sealFlag"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="describe"})[0].setVisible(true);
                        }
                        if(formData["type"] === '7'){
                            cols.filter(col=>{return col.dataIndex==="fileUrl"})[0]
                                .setTitle("说明文件");
                            cols.filter(col=>{return col.dataIndex==="associatedKey"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="name"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="number"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="amount"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="auditUser"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="receiveUser"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="sigCompany"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="sigDate"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="sigUser"})[0].setVisible(true);
                            cols.filter(col=>{return col.dataIndex==="submitUser"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="signDate"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="signState"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="signUser"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="sealFlag"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="describe"})[0].setVisible(true);
                        }
                        cols.filter(col=>{return col.dataIndex==="fileUrl"})[0].setVisible(true);
                    }),
            new Column("签署方","associatedKey",ColumnType.IdReplace,true)
                .setIdReplaceObject("associatedName",{api:api_item_party_info.queryAll,tag:"queryText"}),
            new Column("合同名称","name",ColumnType.String,true).setTableView(200),
            new Column("合同编号","number",ColumnType.String,true).setTableView(200),

          /*  new Column("收款责任人","collectUser",ColumnType.String,true).setGroup(1).setTableView(120),*/
            new Column("合同金额","amount",ColumnType.Number,true).setTableView(150),
            new Column("合同审核人","auditUser",ColumnType.IdReplace,true)
                .setIdReplaceObject("auditUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("合同回收人","receiveUser",ColumnType.IdReplace,true)
                .setIdReplaceObject("receiveUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("签订公司","sigCompany",ColumnType.String,true)
                .setEnum(companyIds, companyNames,companyColors),
            new Column("签订时间","sigDate",ColumnType.Date,true),
            new Column("签订人","sigUser",ColumnType.IdReplace,true).setTableView(100)
                .setIdReplaceObject("sigUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("合同递交人","submitUser",ColumnType.IdReplace,true)
                .setIdReplaceObject("submitUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("办公人事部签收时间","signDate",ColumnType.Date,true),
            new Column("办公人事部签收状态","signState",ColumnType.Enum,true)
                .setEnum(["0","1"],["未签收","已签收"],["red","green"]), //办公人事部签收状态:0:未签收；1：已签收
            new Column("办公人事部签收人","signUser",ColumnType.IdReplace,true)
                .setIdReplaceObject("signUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
            new Column("盖章情况","sealFlag",ColumnType.Enum,true).setTableView(100)
                .setEnum(["0","1"],["未盖章","已盖章"],["red","green"]), //合同盖章情况：0：未盖章；1：已盖章
            new Column("描述说明","describe",ColumnType.String,true),
            new Column("合同文件","fileUrl",ColumnType.PreViewFile,true).setTableView(100),

        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}