<!--
* @program: TYH_office_automation 
* @author: ly
* @component:qualitySteps 
* @description: 
* @create: 2021-07-05 15:48
-->
<template>
    <a-steps :current="currentStep">
        <a-step v-for="item in steps" :status="item.status" :key="item.title" :title="item.title" />
    </a-steps>
    <a-divider style="background-color: #a0a0a0;margin: 10px"  />
    <div class="steps-content" v-is="steps[currentStep].comp" :record="record" :viewModel="false" ref="step" @refresh="refresh"/>
    <div class="steps-action">
        <a-button v-if="currentStep > 0" style="margin: 5px" @click="prevStep">上一步</a-button>
        <a-button v-if="currentStep < steps.length - 1" style="margin: 5px" type="primary" @click="nextStep">下一步</a-button>
        <a-button
                style="margin: 5px"
                v-if="currentStep === steps.length - 1"
                type="primary"
                @click="checkSteps">完成</a-button>
    </div>
</template>

<script>
    import qualityTaskGroup from "./qualityTaskStepGroup";
    import qualityTaskSet from "./qualityTaskStepSet";
    import qualityTaskArrangement from "./qualityTaskStepArrangement";
    import qualityStepFinish from "./qualityStepFinish";
    export default {
        name: "qualitySteps",
        emits:["finish","refresh"],
        props:{
           record:{required:true}//当前质检申请的record
        },
        components:{
            qualityTaskGroup,qualityTaskSet,qualityTaskArrangement,qualityStepFinish
        },
        data(){
            return{
                currentStep:0,
                steps:[ //status wait process finish error
                    {title:"设定质检任务",comp:"qualityTaskSet",status:"process"},
                    {title:"分配质检任务",comp:"qualityTaskArrangement",status:"wait"},
                    {title:"完成",comp:"qualityStepFinish",status:"wait"},
                ]
            }
        },
        methods:{
            nextStep(){
                this.steps[this.currentStep].status = this.$refs.step.getStatus();
                this.currentStep ++;
                this.steps[this.currentStep].status = "process";
            },
            prevStep(){
                this.steps[this.currentStep].status = "wait";
                this.currentStep --;
                this.steps[this.currentStep].status = "process";
            },
            checkSteps(){
                for(let i in this.steps){
                    if(this.steps[i].status === "error"){
                        this.$message.error("有步骤没有完成");
                        return
                    }
                }
                this.$emit("finish");
            },
            refresh(){
                this.$emit("refresh");
            }
        }
    }
</script>

<style scoped>

</style>