<!--
* @program: office_automation 
* @author: ly
* @component:ContractLister 
* @description: 合同一览
* @create: 2022-03-04 15:04
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-select
                        :options="options"
                        @change="doSearch"
                        label-in-value
                        placeholder="合同类别"
                        style="width: 150px;margin: 5px"
                        v-model:value="op_value"
                >
                </a-select>
                <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>

                <a-button @click="openStatistic" style="margin-left: 10px">
                    <template #icon>
                        <BarChartOutlined style="color:green" title="合同统计"/>
                    </template>
                    合同统计
                </a-button>

            </div>
            <TwoParts :enable-change="false" :trans="[60,40]" ref="TwoParts">
                <template #left>
                    <ArBaseTable :argument="argument" :download="true" :page-size="pageSize" :scroll="{x:1000,y:620}"
                                 :view-model="true">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <!--                                <a-button @click="editDetail(record)" class="action-button" type="link">
                                                                    <EditFilled class="pointer"/>
                                                                </a-button>
                                                                <a-popconfirm
                                                                        ok-text="是"
                                                                        cancel-text="否"
                                                                        class="action-button"
                                                                        title="确定删除吗?"
                                                                        @confirm="deleteData(index,record)">
                                                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                                                </a-popconfirm>-->
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <a-page-header style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;"
                                   title="合同信息" v-if="formArg.formData"/>
                    <ArForm :argument="formArg" :view-model="viewModel" v-if="formArg.formData">
                        <template #action="{record}">
                            <a-button @click="saveData(record)" type="primary">提交</a-button>
                        </template>
                    </ArForm>
                    <welcome2 v-else/>
                </template>
            </TwoParts>

            <div style="border: 1px solid rgb(235, 237, 240);margin-right: 15%;font-size: 15px;color: red;">
                合同个数：{{cont_num}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                合同总金额：{{sumAmount_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                <span v-if="op_value == null || op_value.value == '1' || op_value.value == '4'">
              已收款总金额：{{sumCollect_sum.toFixed(2)}}<a-divider style="height: 30px; background-color: #7cb305"
                                                             type="vertical"/>
              未收款总金额：{{sumCollectNo_sum.toFixed(2)}}<a-divider style="height: 30px; background-color: #7cb305"
                                                               type="vertical"/>
            </span>
                <span v-if="op_value == null || op_value.value == '2' || op_value.value == '5' || op_value.value == '6'">
              已付款总金额：{{colPayAmount_sum.toFixed(2)}}<a-divider style="height: 30px; background-color: #7cb305"
                                                               type="vertical"/>
              未付款总金额：{{colPayAmountNo_sum.toFixed(2)}}
            </span>

            </div>

        </div>
    </div>

    <!-- 合同统计 start -->
    <a-drawer
            :closable="false"
            :title="tj_title"
            :visible="visible"
            @close="onClose"
            destroyOnClose="true"
            height="60%"
            placement="bottom"
    >
        <div class="oa-content" style="height: 430px">
            <div class="oa-window" v-if="sum_flag">
                <div class="ant-row" style="height: 100%;">
                    <div class="ant-col-24 divhover" style="box-shadow: 0px 0px 5px #888888;">
                        <div ref="chart_item_year" style="height:100% "></div>
                    </div>
                </div>
            </div>
            <div class="oa-window" v-else>
                <div class="ant-row" style="height: 8%;">
                    <a-button @click="back" danger style="margin-left: 95%;">返回</a-button>
                </div>
                <div class="ant-row" style="height: 92%;">
                    <div class="ant-col-24 divhover" style="box-shadow: 0px 0px 5px #888888;">
                        <div ref="chart_item_month" style="height:100% "></div>
                    </div>
                </div>
            </div>
        </div>
    </a-drawer>
    <!-- 合同统计 end -->

</template>

<script>

    import {DeleteOutlined, EditFilled, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import ArForm from "../../../components/form/ArForm";
    import item_contract_info2 from "../../../assets/tables/item_contract_info2";
    import api_item_contract_info from "../../../assets/api/contractInfo";
    import contractInfo from "../../../assets/api/contractInfo";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import TwoParts from "../../_components/TwoParts";
    import Welcome2 from "../../welcome2";
    import Icons from "../../common/Icons";
    import {markRaw} from "vue";
    import * as echarts from "echarts";

    export default {
        name: "ContractLister",
        mixins: [Icons],
        components: {
            Welcome2,
            ArBaseTable, ArForm,
            SearchOutlined, EditFilled, DeleteOutlined,
            SearchBar, TwoParts, SyncOutlined
        },
        data() {
            return {
                table: new item_contract_info2(),
                searchOptions: [
                    {value: "itemName", label: "项目名称"},
                    {value: "name", label: "合同名称"},
                    {value: "number", label: "合同编号"},
                ],
                viewModel: true,
                searchData: null,
                pageSize: 15,
                argument: {
                    dataSource: null,//提供table的DataSource
                    tableColumns: null, //提供table的字-段集 this.table.getTableColumns()
                    loading: false,//table的loading状态
                },
                formArg: {
                    formColumns: null, //提供Form的字段集 this.table.getFormColumns()
                    formData: null,//提供Form的formData
                },
                visible: false,
                defaultQuery: {
                    "itemId": null,
                    "itemName": null,
                    "name": null,
                    "number": null,
                    "type": null
                },

                sum_flag: true,
                chartsItem: null,
                tj_title: '合同统计',
                statCount_data: [],
                chartsCurrentData: null,
                data_ht: [],
                chartsItem_month: null,

                options: [
                    {value: '1', label: '甲方合同'},
                    {value: '2', label: '外协合同'},
                    {value: '4', label: '三方合同'},
                    {value: '5', label: '三方外协合同'},
                ],
                op_value: null,

                cont_num: 0,
                sumAmount_sum: 0,
                sumCollect_sum: 0,
                sumCollectNo_sum: 0,
                colPayAmount_sum: 0,
                colPayAmountNo_sum: 0,
            }
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                this.formArg.formColumns = this.table.getFormColumns();
                this.argument.tableColumns = this.table.getTableColumns();
                this.formArg.formData = null;
                this.defaultQuery = {
                    "itemId": null,
                    "itemName": null,
                    "name": null,
                    "number": null,
                    "type": null
                };
                this.argument.loading = true;
                api_item_contract_info.queryAll.requestPOST(this, this.queryOptions, res => {
                    this.argument.loading = false;
                    if (res.data.flag) {
                        let list_info = res.data.data;
                        this.argument.dataSource = list_info;

                        if (list_info.length > 0) {
                            this.cont_num = list_info.length;

                            list_info.forEach((item, index) => {
                                this.sumAmount_sum += item.amount;

                                if (item.amount > item.colPayAmount) {
                                    let no_num = (item.amount - item.colPayAmount).toFixed(2);
                                    item.colPayAmountNo = no_num
                                }

                                if (item.type == '1' || item.type == '4') {
                                    this.sumCollect_sum += item.colPayAmount;
                                    let noNum = item.colPayAmountNo == null || item.colPayAmountNo == undefined ? 0 : parseFloat(item.colPayAmountNo);
                                    this.sumCollectNo_sum = (parseFloat(this.sumCollectNo_sum) + noNum);

                                } else if (item.type == '2' || item.type == '5' || item.type == '6') {
                                    this.colPayAmount_sum += item.colPayAmount;
                                    let noNum = item.colPayAmountNo == null || item.colPayAmountNo == undefined ? 0 : parseFloat(item.colPayAmountNo);
                                    this.colPayAmountNo_sum = (parseFloat(this.colPayAmountNo_sum) + noNum);
                                }

                            })
                        }
                    } else {
                        this.argument.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                })
            },
            refresh() {
                this.op_value = null;
                this.init()
            },
            doSearch() {
                this.cont_num = 0;
                this.sumAmount_sum = 0;
                this.sumCollect_sum = 0;
                this.sumCollectNo_sum = 0;
                this.colPayAmount_sum = 0;
                this.colPayAmountNo_sum = 0;
                let data = {};
                Object.assign(data, this.defaultOptions);
                Object.assign(data, this.searchData);
                if (this.op_value != null) {
                    let type = this.op_value.value;
                    data.type = type;
                    let col = this.argument.tableColumns;
                    if (col != null) {
                        for (let i = 0; i < col.length; i++) {
                            let col_info = col[i];
                            if (type == '1' || type == '4') {
                                if (col_info.dataIndex == 'colPayAmount') {
                                    col_info.title = '已收金额';
                                }

                                if (col_info.dataIndex == 'colPayAmountNo') {
                                    col_info.title = '未收金额';
                                }
                            } else if (type == '2' || type == '5' || type == '6') {
                                if (col_info.dataIndex == 'colPayAmount') {
                                    col_info.title = '已付金额';
                                }

                                if (col_info.dataIndex == 'colPayAmountNo') {
                                    col_info.title = '未付金额';
                                }
                            }
                        }
                    }
                }
                this.argument.loading = true;
                api_item_contract_info.queryAll.requestPOST(this, data, res => {
                    if (res.data.flag) {
                        let list_info = res.data.data;
                        this.argument.dataSource = list_info;

                        if (list_info.length > 0) {
                            this.cont_num = list_info.length;

                            list_info.forEach((item, index) => {
                                this.sumAmount_sum += item.amount;

                                if (item.amount > item.colPayAmount) {
                                    let no_num = (item.amount - item.colPayAmount).toFixed(2);
                                    item.colPayAmountNo = no_num
                                }

                                if (item.type == '1' || item.type == '4') {
                                    this.sumCollect_sum += item.colPayAmount;
                                    let noNum = item.colPayAmountNo == null || item.colPayAmountNo == undefined ? 0 : parseFloat(item.colPayAmountNo);
                                    this.sumCollectNo_sum = (parseFloat(this.sumCollectNo_sum) + noNum);

                                } else if (item.type == '2' || item.type == '5' || item.type == '6') {
                                    this.colPayAmount_sum += item.colPayAmount;
                                    let noNum = item.colPayAmountNo == null || item.colPayAmountNo == undefined ? 0 : parseFloat(item.colPayAmountNo);
                                    this.colPayAmountNo_sum = (parseFloat(this.colPayAmountNo_sum) + noNum);
                                }

                            })
                        }
                    } else {
                        this.argument.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                    this.argument.loading = false;
                });
            },
            showDetail(record) {
                this.formArg.formData = record;
                this.viewModel = true;
            },
            editDetail(record) {
                this.formArg.formData = record;
                this.viewModel = false;
            },
            deleteData(index, record) {
                let data = {id: record.id};
                api_item_contract_info.delete.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index, 1);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record) {
                api_item_contract_info.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.viewModel = true;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            openStatistic() {
                contractInfo.statCount.requestPOSTUrlParam(this, {}, res => {
                    let data = [];
                    if (res.data.flag) {
                        this.sum_flag = true;
                        this.visible = true;
                        let dataInfo = res.data.data;
                        this.data_ht = dataInfo;

                        // 封装成分析需要的格式
                        if (dataInfo.length > 0) {
                            let list_x = [];
                            let sumCount = []; // 该年总个数
                            let partyCount = [];//甲方合同总个数
                            let outCount = []; //外协合同总个数
                            let sumAmount = [];// 该年总金额
                            let partyAmount = [];//甲方合同总金额
                            let outAmount = [];//外协合同总金额
                            dataInfo.forEach((item, i) => {
                                list_x.push(item.year);
                                sumCount.push(item.sumCount);
                                partyCount.push(item.partyCount);
                                outCount.push(item.outCount);
                                sumAmount.push((item.sumAmount / 10000).toFixed(2));
                                partyAmount.push((item.partyAmount / 10000).toFixed(2));
                                outAmount.push((item.outAmount / 10000).toFixed(2));
                            })
                            data.push(list_x);
                            data.push(sumCount);
                            data.push(partyCount);
                            data.push(outCount);
                            data.push(sumAmount);
                            data.push(partyAmount);
                            data.push(outAmount);
                        }

                    } else {
                        this.data_ht = [];
                        this.$message.error(res.data.msg);
                    }
                    setTimeout(() => {
                        this.statCount_data = data;
                        this.eachar_item(data);
                    }, 200)
                });

            },
            onClose() {
                this.visible = false;
            },
            eachar_item(data) {
                if (this.chartsItem) {
                    this.chartsItem.clear();
                }
                let option = null;
                if (data.length <= 0) {
                    option = {
                        title: {
                            extStyle: {
                                color: "grey",
                                fontSize: 20

                            },
                            textStyle: {
                                color: "#FFD700",
                            },

                            text: "暂无数据",

                            left: "center",

                            top: "center"

                        },
                    }
                } else {
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            },
                            formatter: (params) => {
                                var result = params[0].axisValueLabel + '<br>'
                                params.forEach(function (item) {
                                    if (item.value) {
                                        result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                                    }
                                })
                                // 保留数据
                                this.chartsCurrentData = params[0]
                                // 返回mousemove提示信息
                                return result
                            }
                        },
                        legend: {
                            selected: {
                                '甲方合同总数': false,
                                '外协合同总数': false,

                                '甲方合同总金额': false,
                                '外协合同总金额': false,
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: data[0],
                                axisPointer: {
                                    type: 'shadow'
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '金额（万元）',
                                interval: 500,
                                axisLabel: {
                                    formatter: '{value} '
                                }
                            },
                            {
                                type: 'value',
                                name: '数量（份）',
                                interval: 50,
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            }
                        ],
                        series: [
                            {
                                name: '合同总数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[1]
                            },
                            {
                                name: '甲方合同总数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[2]
                            },
                            {
                                name: '外协合同总数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[3]
                            },
                            {
                                name: '合同总金额',
                                type: 'bar',
                                emphasis: {
                                    focus: 'series'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[4]
                            },
                            {
                                name: '甲方合同总金额',
                                type: 'bar',
                                stack: 'Ad',
                                emphasis: {
                                    focus: 'series'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[5]
                            },
                            {
                                name: '外协合同总金额',
                                type: 'bar',
                                stack: 'Ad',
                                emphasis: {
                                    focus: 'series'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[6]
                            },

                        ]
                    };
                }
                this.chartsItem = markRaw(echarts.init(this.$refs.chart_item_year))//获取dom元素;
                this.chartsItem.setOption(option);

                /** 扩大点击范围 start **/
                // 防止点击调用多次
                this.chartsItem.getZr().off('click')
                // 鼠标移动到阴影范围 setCursorStyle('pointer')
                this.chartsItem.getZr().on('mousemove', param => {
                    var pointInPixel = [param.offsetX, param.offsetY];
                    if (this.chartsItem.containPixel('grid', pointInPixel)) {//若鼠标滑过区域位置在当前图表范围内 鼠标设置为小手
                        this.chartsItem.getZr().setCursorStyle('pointer')
                    } else {
                        this.chartsItem.getZr().setCursorStyle('default')
                    }
                })
                this.chartsItem.getZr().on('click', params => {

                    // 获取到鼠标点击位置：
                    const pointInPixel = [params.offsetX, params.offsetY]
                    // 判断点击位置是否在显示图形区域
                    if (this.chartsItem.containPixel('grid', pointInPixel)) {
                        this.sum_flag = false;
                        let year = this.chartsCurrentData.axisValueLabel;
                        setTimeout(() => {
                            this.eachar_item_month(year);
                        }, 200)
                    }
                })
                /** 扩大点击范围 end **/

            },
            // 月份统计
            eachar_item_month(year) {
                let info = this.data_ht.filter(item => item.year === year);
                let data = [];
                let dataInfo = info[0].monthList;
                // 封装成分析需要的格式
                if (dataInfo.length > 0) {
                    let list_x = [];
                    let sumCount = []; // 该年总个数
                    let partyCount = [];//甲方合同总个数
                    let outCount = []; //外协合同总个数
                    let sumAmount = [];// 该年总金额
                    let partyAmount = [];//甲方合同总金额
                    let outAmount = [];//外协合同总金额
                    dataInfo.forEach((item, i) => {
                        list_x.push(item.month);
                        sumCount.push(item.sumCount);
                        partyCount.push(item.partyCount);
                        outCount.push(item.outCount);
                        sumAmount.push((item.sumAmount / 10000).toFixed(2));
                        partyAmount.push((item.partyAmount / 10000).toFixed(2));
                        outAmount.push((item.outAmount / 10000).toFixed(2));
                    })
                    data.push(list_x);
                    data.push(sumCount);
                    data.push(partyCount);
                    data.push(outCount);
                    data.push(sumAmount);
                    data.push(partyAmount);
                    data.push(outAmount);
                }
                if (this.chartsItem_month) {
                    this.chartsItem_month.clear();
                }
                let option = null;
                if (data.length <= 0) {
                    option = {
                        title: {
                            extStyle: {
                                color: "grey",
                                fontSize: 20

                            },
                            textStyle: {
                                color: "#FFD700",
                            },

                            text: "暂无数据",

                            left: "center",

                            top: "center"

                        },
                    }
                } else {
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            },
                            formatter: (params) => {
                                var result = params[0].axisValueLabel + '<br>'
                                params.forEach(function (item) {
                                    if (item.value) {
                                        result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                                    }
                                })
                                // 保留数据
                                this.chartsCurrentData = params[0]
                                // 返回mousemove提示信息
                                return result
                            }
                        },
                        legend: {
                            selected: {
                                '甲方合同总数': false,
                                '外协合同总数': false,

                                '甲方合同总金额': false,
                                '外协合同总金额': false,
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: data[0],
                                axisPointer: {
                                    type: 'shadow'
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '金额（万元）',
                                interval: 200,
                                axisLabel: {
                                    formatter: '{value} '
                                }
                            },
                            {
                                type: 'value',
                                name: '数量（份）',
                                interval: 10,
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            }
                        ],
                        series: [
                            {
                                name: '该年合同总数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[1]
                            },
                            {
                                name: '甲方合同总数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[2]
                            },
                            {
                                name: '外协合同总数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[3]
                            },
                            {
                                name: '该年合同总金额',
                                type: 'bar',
                                emphasis: {
                                    focus: 'series'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[4]
                            },
                            {
                                name: '甲方合同总金额',
                                type: 'bar',
                                stack: 'Ad',
                                emphasis: {
                                    focus: 'series'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[5]
                            },
                            {
                                name: '外协合同总金额',
                                type: 'bar',
                                stack: 'Ad',
                                emphasis: {
                                    focus: 'series'
                                },
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data[6]
                            },

                        ]
                    };
                }
                this.chartsItem_month = markRaw(echarts.init(this.$refs.chart_item_month))//获取dom元素;
                this.chartsItem_month.setOption(option);
            },
            back() {
                this.sum_flag = true;
                setTimeout(() => {
                    this.eachar_item(this.statCount_data);
                }, 200)
            },

        },

    }
</script>

<style scoped>

</style>