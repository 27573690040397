<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentSteps 
* @description: 
* @create: 2022-06-09 14:22
-->
<template>
    <div class="center-flex">
        <a-steps style="width: 100%" size="small" >
            <a-step v-for="({approvalUserName,updateTime,remarks,status},key) in steps"
                    :status = "status"
                    :sub-title="getTime(updateTime)"
                    :key="key">
                <template #title>
                    <div style="display: flex;justify-content: space-around;height: 24px;align-items: center;">
                        <div>{{approvalUserName}}</div>
                        <a-popover v-if="remarks" trigger="hover" placement="top">
                            <template #content>
                                {{remarks}}
                            </template>
                            <a-button  type="link" >审核意见</a-button>
                        </a-popover>
                    </div>
                </template>
            </a-step>
        </a-steps>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "DepartmentSteps",
        props:["steps"],
        data(){
            return{
                modalVisible:false,
                remarks:null,
                dateFormat: 'YYYY/MM/DD HH:mm:SS',
            }
        },
        methods:{
            getTime(timeStamp){
                if(timeStamp){
                    return moment(timeStamp).locale('zh-cn').format(this.dateFormat);
                }
                else{
                    return "";
                }
            }
        }
    }
</script>

<style scoped>

</style>