<!--
* @program: office_automation 
* @author: ly
* @component:ForgetPassword
* @description: 
* @create: 2022-04-20 10:15
-->
<template>
    <div style="margin: 100px 50px;font-weight: 900;font-size: 36px;color:darkblue">
        忘记密码
        <br/>
        <span style="font-size: 16px">获取短信验证码后，请输入手机获取到的短信验证码，或许会有延迟，请耐心等待。</span>
    </div>
    <a-card class="user-card">
        <a-form style="margin: 10px" layout="horizontal"
                :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item  label="手机号" >
                <div style="display: flex">
                    <a-input :maxlength="11" style="width:200px" v-model:value="phone"  />
                    <a-button :disabled = "count>0" type="link"  @click="modalShow">
                        获取短信验证码
                        <span v-if="count">{{count}}s</span>
                    </a-button>
                </div>
            </a-form-item>
            <a-form-item  label="短信验证码" >
                <a-input v-model:value="msgCode">
                    <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
                </a-input>
            </a-form-item>
            <a-form-item  label="新密码" >
                <a-input-password  style="width:100%" v-model:value="newPW"  />
            </a-form-item>
        </a-form>
        <a-button  class="search-item" type="primary"  @click="saveData">
            提交
        </a-button>
    </a-card>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="获取短信验证码"
             :maskClosable="false"
             :footer="null">
        <a-form style="margin: 10px" layout="horizontal"
                :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item label="计算" >
                <img  :src="image"/>
            </a-form-item>
            <a-form-item  label="填入结算结果" >
                <a-input v-model:value="result">
                    <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
                </a-input>
            </a-form-item>
        </a-form>
        <a-button  class="search-item" type="primary"  @click="getMsgCode">
            获取短信验证码
        </a-button>
    </a-modal>
</template>

<script>
    import api from "../assets/api/_default";
    import {LockOutlined} from "@ant-design/icons-vue"
    import {isPhoneNumber} from "../assets/utils/general";

    export default {
        name: "ForgetPassword",
        components:{LockOutlined},
        data(){
            return{
                labelCol:{ span: 6 },
                wrapperCol:{ span: 14 },
                phone:null,
                image:null,
                result:null,
                msgCode:null,
                newPW:null,
                visible:false,
                disabled:false,
                count:0
            }
        },
        methods: {
            init() {
                setInterval(()=>{
                    if(this.count >0){
                        this.count --
                    }
                },1000)
            },
            getCaptcha(){
                let data = {
                    phone:this.phone
                };
                api.getCaptcha.requestGET(this,data,res=>{
                    if(res.data.flag){
                        this.image = res.data.data.image
                    }
                })
            },
            saveData(){
                let data = {
                    phone:this.phone,
                    password:this.newPW,
                    msgCode:this.msgCode
                };
                api.resetPwd.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.$router.push({ path: '/' });
                    }
                    else{
                        this.$message.success(res.data.msg);
                        this.count = 0;
                        this.$message.success("请重新获取短信验证码");
                    }
                })
            },
            modalShow(){
                if(isPhoneNumber(this.phone)){
                    this.visible  = true;
                    this.getCaptcha();
                }
                else{
                    this.$message.error("请输入正确的电话号码")
                }
            },
            getMsgCode(){
                let data = {
                    phone:this.phone,
                    result:this.result
                };
                api.getMsgCode.requestGET(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.disabled = true;
                        setTimeout(()=>{
                            this.count = 30
                        });
                    }
                    else{
                        this.$message.success(res.data.msg)
                    }
                })
            }
        },
        created() {
            this.init()
        }
    }

</script>

<style scoped>

</style>