/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class payApply_item extends Table {
    constructor() {
        super();
        this.name = "payApply_item";
        this.CNName = "外协支付";
        this.columnArray = [
            new Column("项目名称","itemName",ColumnType.String,true).setTableView(150),
            new Column("外协单位","outSourceName",ColumnType.String,true).setTableView(80),
            new Column("合同名称","contractName",ColumnType.String,true).setTableView(80),
            new Column("合同签订日期","contractSigDate",ColumnType.Date,true).setTableView(80),
            new Column("支付金额","payAmount",ColumnType.Number,true).setTableView(80),
            new Column("支付日期","payTime",ColumnType.Date,true).setTableView(80),
            new Column("支付公司名称","payCompanyName",ColumnType.String,true).setTableView(80),
            new Column("申请支付部门名称","applyDepartName",ColumnType.String,true).setTableView(80),
            new Column("付款银行名称","bankName",ColumnType.String,true).setTableView(80),
            new Column("支付状态","payState",ColumnType.Enum,true).setTableView(80)
            .setEnum(["0","1"],
                ["未支付","已支付"],
                ["Pink","MediumPurple"]),
        ];

    }
}