import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";
import api_item_info from "../api/itemInfo";

/**
 * @program: office_automation
 * @author: ly
 * @description: important_item
 * @create: 2022-06-14 16:57
 "itemId": "string",
 "majorFlag": "string",
 "month": "string",
 "remarks": "string",
 "targetDescribe": "string",
 "targetRatio": 0
 **/

export default class important_item extends Table{
    constructor() {
        super();
        this.name = "important_item";
        this.CNName = "其他收入记录";
        this.columnArray = [
            new Column("部门","departments",ColumnType.String).setTableView(100),
            new Column("项目","itemId",ColumnType.IdReplace2,true).setTableView(200)
                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                        "startTime": "", "tenderWay": ""
                    }}),
            new Column("月份","month",ColumnType.Month,true).setTableView(100),
            new Column("目标描述","targetDescribe",ColumnType.String,true).setTableView(300),
            new Column("目标进度","targetRatio",ColumnType.Number,true).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("完成进度","completeRatio",ColumnType.Number,false).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("备注","remarks",ColumnType.String,true).setTableView(100),

        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}