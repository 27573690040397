/** 
* 自动生成文件，请勿修改 
* @name: 财务:会计科目设置模块
* @description: 财务-会计科目设置-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:5条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const subject = { 
	/** 1 summary:会计科目-全部数据查询（只模糊一级科目名称）	method:post */ 
	queryAll : new APIObject("/api/subject/queryAll.do"), 
	/** 2 summary:会计科目-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/subject/queryOne.do"), 
	/** 3 summary:会计科目-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/subject/saveOrUpd.do"), 
	/** 4 summary:会计科目-批量删除	method:post */ 
	batchDelete : new APIObject("/api/subject/batchDelete.do"), 
	/** 5 summary:会计科目-单条删除	method:post */ 
	delete : new APIObject("/api/subject/delete.do"), 
};
export default subject;