<!--
* @program: office_automation 
* @author: ly
* @component:DepartmentAssessmentViewer 
* @description: 部门成员考核结果一览
* @create: 2022-05-07 09:16
-->
<template>
    <div style="height: 100%">
        <div class="search-bar">
            <span>月份：</span>
            <MonthPicker @change="handleChange" style="width: 120px" v-model:value="yearMonth" />
            <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
              <template #icon><SyncOutlined /></template>
            </a-button>
        </div>

        <TwoParts ref="TwoParts" :trans="[35,35]" :enable-change="false">
            <template #left>
                <ArBaseTable   :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                    <template #action="{record}">
                        <div class = "action-column">
                            <a-button @click="showDetail(record)" class="action-button" type="link">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                            <a-button @click="downLoadFile(record)" class="action-button" type="link">
                                <DownloadOutlined class="pointer"/>
                            </a-button>
                        </div>
                    </template>
                </ArBaseTable>
            </template>
            <template #right>
                <div v-if="currentRecord" >
                    <MonthlyAssessmentViewer2  :view-model="true" user-type="user" :user-data="currentRecord"   />
                </div>
                <div v-else>
                    <welcome2 />
                </div>
            </template>
        </TwoParts>
    </div>
</template>

<script>
    import MonthPicker from "../../_components/MonthPicker";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import welcome2 from "../../welcome2";
    import MonthlyAssessmentViewer2
        from "../../talentManager/staffAssessment/MonthlyAssessment/MonthlyAssessmentViewer2";
    import {SearchOutlined,DownloadOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import moment from "moment";
    import TwoParts from "../../_components/TwoParts";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import assessmentUser from "../../../assets/api/assessmentUser";
    import kpi from "../../../assets/api/kpi";
    export default {
        name: "DepartmentAssessmentViewer",
        props:["department"],
        components:{
            MonthPicker,ArBaseTable,welcome2,MonthlyAssessmentViewer2,TwoParts,
            SearchOutlined,DownloadOutlined,SyncOutlined
        },
        data() {
            return {
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                pageSize:15,
                yearMonth:null,
                currentRecord:null
            }
        },
        created() {
          this.init();
        },
        methods: {
            init() {
                this.yearMonth = moment(new Date().valueOf()).format("YYYY-MM");
                this.argument.tableColumns = [
                    new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                    //new Column("部门","depName",ColumnType.String,true).setTableView(100),
                    new Column("姓名","name",ColumnType.String,true).setTableView(100),
                    new Column("得分","score",ColumnType.Number,false).setTableView(60),
                    new Column("评级","level",ColumnType.String,false).setTableView(60),
                    new Column("情况","judgeEnd",ColumnType.Boolean,false).setTableView(60)
                        .setBoolTags(["未同意","已同意"]),
                    new Column("操作","actions","actions",false).setTableView(50)
                ];
                this.argument.dataSource = [];
            },
            refresh(){
              this.handleChange();
            },
            handleChange(){
                let data = {
                    yearMonth:this.yearMonth
                };
                this.argument.loading = true;
                assessmentUser.queryScore.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        for(let i in res.data.data){
                            if(res.data.data[i].list.length > 0){
                                res.data.data[i].id = res.data.data[i].userId;
                                res.data.data[i].score = parseFloat(res.data.data[i].list[0].sumScore);
                                res.data.data[i].yearMonth = res.data.data[i].list[0].yearMonth;
                                res.data.data[i].judgeEnd = res.data.data[i].list[0].judgeEnd;
                                res.data.data[i].level = getLevel(res.data.data[i].score);
                            }
                        }
                        this.argument.dataSource = res.data.data.filter(item=>item.depName === this.department.name);
                    }
                    else{
                        this.argument.dataSource = []
                    }
                });
            },
            showDetail(record){
                this.currentRecord = record;
            },
            downLoadFile(record){
                let {yearMonth,userId,userName} = record;
                let data = {
                    yearMonth:yearMonth,
                    userId : userId,
                };
                kpi.exportByUserMonth.fileDownloadGet(this,data,"月度考核报告【"+yearMonth+"-"+userName+"】.xls");
            },
        },

    }
    function getLevel(score) {
        if(0 <= score && score<=10 ){return "一等"}
        else if (10 < score && score <= 20){return "二等"}
        else if (20 < score && score <= 30){return "三等"}
        else if (30 < score && score <= 40){return "四等"}
        else if (40 < score && score <= 50){return "五等"}
        else if (50 < score && score <= 60){return "六等"}
        else if (60 < score && score <= 70){return "七等"}
        else if (70 < score && score <= 80){return "八等"}
        else if (80 < score && score <= 90){return "九等"}
        else if (90 < score && score <= 100){return "十等"}
        else {return "数据错误"}
    }
</script>

<style scoped>

</style>