<!--
* @program: tyh-oa 
* @author: ly
* @description: billManager 
* @create: 2021-03-22 10:22
-->
<template>
    <a-layout style="height: 100%" >
        <a-layout-sider :collapsible="true" :collapsedWidth="40">
            <a-menu
                    theme="dark"
                    mode="inline"
                    v-model:openKeys="openKeys"
                    v-model:selectedKeys="selectedKeys"
                    @openChange="onOpenChange"
                    @click="handleClick">
                <sub-group-menu v-for="(group,index) in menuLists" :index="index" :groupMenus="group" :key="group.id" />
            </a-menu>
        </a-layout-sider>
        <a-layout-content class="main-window">
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import menuContent from "../../components/menuContent";
    import billViewer from "./billViewer/billViewer";
    import billSetting from "./billSetting";
    import billViewer2 from "./billViewer2/billViewer2";
    import billViewer2SP from "./billViewer2/billViewer2SP";
    import billViewerAll from "./billViewerAll/billViewerAll";
    import billViewerAllsp from "./bilViewerAllsp/billViewerAllsp";
    import billViewerAllsp2 from "./bilViewerAllsp/billViewerAllsp2";
    import billViewerAllsp3 from "./bilViewerAllsp/billViewerAllsp3";
    import billViewerAllsp4 from "./bilViewerAllsp/billViewerAllsp4";
    import billViewerAllsp5 from "./bilViewerAllsp/billViewerAllsp5";
    import billViewerAllsp6 from "./bilViewerAllsp/billViewerAllsp6";
    import billViewerAllsp7 from "./bilViewerAllsp/billViewerAllsp7";
    import billStatisticsByCategory from "./billStatistics/billStatisticsByCategory";
    import billStatisticsByProject from "./billStatistics/billStatisticsByProject";
    import billStatisticsByDepartment from "./billStatistics/billStatisticsByDepartment";

    import billViewerAll_super from "./billViewerAll/billViewerAll_super";
    export default {
        name: "billManager",
        mixins:[menuContent],
        components:{
          billViewer,// 待审核
          billSetting,// 定制报账审核流程
          billViewer2,// 入账管理
          billViewerAll,// 账单一览/全公司
          billViewerAllsp,// 账单一览/财务
          billViewerAllsp2,// 账单一览/本部门
          billViewerAllsp3,// 账单一览/总经理
          billViewerAllsp4,// 账单一览/副总经理
          billViewerAllsp5,// 账单一览/财务总监
          billViewerAllsp6,// 账单一览/个人
          billViewerAllsp7,// 账单一览/私人入账
          billStatisticsByCategory,// 按报账类别统计
          billStatisticsByProject,// 项目消耗统计
          billStatisticsByDepartment,// 部门报账统计
          billViewer2SP,//部门入账账单

          billViewerAll_super,// 账单一览/全公司---超管操作
        }
    }
</script>

<style scoped>

</style>