<!--
* @program: office_automation 
* @author: ly
* @component:delayApply 
* @description: 
* @create: 2022-01-08 10:16
-->
<template>
    <div>
        <ArForm :argument="formArg" :view-model="false" >
            <template #action="{record}">
                <a-button type="primary" @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArForm>
        <TagShow :color="'red'" :visible="true" :tag="'申请历史'">
            <template #content>
                <ArBaseTable :argument="argument" :view-model="true" :page-size="10" >
                    <template #action="{index,record}">
                        <div class="action-column">
                            <a-popconfirm
                                    v-if="record.approveState ==='0'"
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确认撤销吗?"
                                    @confirm="deleteData(index,record)">
                                <a-tooltip title="撤销申请">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-tooltip>
                            </a-popconfirm>
                        </div>
                    </template>
                </ArBaseTable>
            </template>
        </TagShow>
    </div>
</template>

<script>
    import ArForm from "../../../components/form/ArForm";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import progressApply from "../../../assets/api/progressApply";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import TagShow from "../../_components/TagShow";
    import {DeleteOutlined} from "@ant-design/icons-vue"
    export default {
        name: "delayApply",
        props:["record","type","deadLine"],//类型：1:生产任务；2：生产工序；3：质检工序；4：员工工序
        watch:{
            record(){
                this.init()
            }
        },
        components:{
            ArForm,ArBaseTable,TagShow,DeleteOutlined
        },
        data(){
            return{
                formArg:{
                    formColumns:[
                        //new Column("原定完成时间","oldDate",ColumnType.Date,true),
                        new Column("延期到","newDate",ColumnType.Date,true),
                        new Column("申请缘由","reason",ColumnType.TextArea,true),
                    ], //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("审核状态","approveState",ColumnType.String).setTableView(80)
                            .setEnum(["0","1","2"],["未审核","通过","不通过"],["pink","green","red"]),
                        new Column("申请人","applyUserName",ColumnType.String).setTableView(80),
                        new Column("原计划时间","oldDate",ColumnType.Date).setTableView(80),
                        new Column("延期到","newDate",ColumnType.Date,true).setTableView(80),
                        new Column("延迟天数","delayDay",ColumnType.Number).setTableView(80),
                        new Column("申请缘由","reason",ColumnType.String,true).setTableView(150),
                        new Column("审核批注","approveRemark",ColumnType.String).setTableView(100),
                        new Column("操作","actions","actions").setTableView(40)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                }
            }
        },
        methods:{
            init(){
                this.formArg.formData = {
                    originalId:this.record.id,
                    applyType:this.type, //类型：1:生产任务；2：生产工序；3：质检工序；4：员工工序
                    newDate:null,
                    reason:null,
                    oldDate:this.record.endDate
                };
                this.argument.loading = true;
                let data = {
                    originalId:this.record.id
                };
                progressApply.queryAll.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            saveData(record){
                progressApply.save.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                progressApply.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource.splice(index,1);
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        created() {
            this.init();
            if(this.deadLine){
                let arr = this.formArg.formColumns.filter(item=>item.dataIndex ==="newDate");
                let time = parseInt(localStorage.getItem("time"));
                arr[0].setEnableTime([time,this.deadLine])
            }
        }
    }
</script>

<style scoped>

</style>