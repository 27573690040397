/**
 * @description：员工年度工资记录
 * @author:rzl
 * @date:2022/7/25 14:27
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class userSalary_item extends Table{
    constructor() {
        super();
        this.name = "userSalary_item";
        this.CNName = "员工年度工资记录";
        this.columnArray = [
            new Column("姓名","userName",ColumnType.String,false).setTableView(80),
            //new Column("年度","year",ColumnType.String,false).setTableView(80),
            //new Column("月度绩效","calBeforeMoney",ColumnType.Number,false).setTableView(120),
            new Column("岗位级别","userInfo.positionLevel",ColumnType.String,false).setTableView(100),
            new Column("基本工资","basicSalary",ColumnType.Number,false).setTableView(100),
            new Column("绩效工资","jixiao",ColumnType.Number,false).setTableView(100),
            new Column("公积金","gjj",ColumnType.Number,false).setTableView(100),
            new Column("个人合计","sum3",ColumnType.Number,false).setTableView(100),
            new Column("社保","sb",ColumnType.Number,false).setTableView(100),
            new Column("医保","yb",ColumnType.Number,false).setTableView(100),
            new Column("项目拟下","calConMoney",ColumnType.Number,false).setTableView(100),
            new Column("二次分配","ecfp",ColumnType.Number,true).setTableView(100),
            new Column("拟下绩效","sum2",ColumnType.Number,false).setTableView(100),
            new Column("骨干绩效","ggjl",ColumnType.Number,true).setTableView(100),
            new Column("其他","qt",ColumnType.Number,true),
            new Column("年终绩效","calYearMoney",ColumnType.Number,false).setTableView(120),
            new Column("年总收入","calYearSumMoney",ColumnType.Number,false).setTableView(120),
            new Column("锁定状态","lockFlag",ColumnType.Enum,false)
                .setEnum([true,false],
                    ["锁定","未锁定"],
                    ["cyan","yellow"]),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80).setPosition("right")];
    }
}