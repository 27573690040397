<!--
* @program: tyh-oa 
* @author: ly
* @component:billStepsViewer 
* @description: 
* @create: 2021-04-22 17:22
-->
<template>
    <div v-if="currentSteps.value.length>0">
        <a-steps type="navigation">
            <a-step status="finish" style="margin: 10px" v-for="(name,index) in currentSteps.value" :key="index" :title="name" >
                <template #icon><user-outlined /></template>
            </a-step>
        </a-steps>
    </div>
    <div v-else>
        还未设定报账审批流程
    </div>
</template>

<script>
    import {UserOutlined} from "@ant-design/icons-vue";

    export default {
        name: "billStepsViewer",
        inject:["currentSteps"],
        components:{UserOutlined},
    }
</script>

<style scoped>

</style>