<!--
* @program: office_automation 
* @author: ly
* @component:DoPromotion 
* @description: 晋升评分
* @create: 2022-03-10 14:58
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-radio-group @change="radioChange" class="search-item" v-model:value="type">
                    <a-radio value="0">待评分</a-radio>
                    <a-radio value="2">完成评分(通过)</a-radio>
                    <a-radio value="1">完成评分(未通过)</a-radio>
                </a-radio-group>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[40,30]" pos="transform">
                <template #left>
                    <ArBaseTable :argument="argument" :view-model="true" :page-size="12" >
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-button @click="editDetail(record)" class="action-button" type="link">
                                    <EditOutlined class="pointer"/>
                                </a-button>
                            </div>
                        </template>
                    </ArBaseTable>
                </template>
                <template #right>
                    <PromotionPoints v-if="currentRecord" user-type="judge" :view-model="viewModel"  v-model:value="currentRecord" />
                    <welcome2 v-else />
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import ArBaseTable from "../../../../components/table/ArBaseTable";
    import TwoParts from "../../../_components/TwoParts";
    import {SearchOutlined, SyncOutlined,EditOutlined} from "@ant-design/icons-vue";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import promotionApply from "../../../../assets/api/promotionApply";
    import userInfo from "../../../../assets/api/userInfo";
    import PromotionPoints from "../../../talentManager/StaffPromotion/StaffPromotionSetting/PromotionPoints";
    import Welcome2 from "../../../welcome2";

    export default {
        name: "DoPromotion",
        components: {
            Welcome2,
            ArBaseTable,TwoParts,SyncOutlined, SearchOutlined,PromotionPoints,
            EditOutlined
        },
        data(){
            return{
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:[
                        new Column("申请人","userName",ColumnType.String).setTableView(80),
                        new Column("现岗位","currentLevel",ColumnType.PositionLevel).setTableView(100),
                        new Column("晋升岗位","applyLevel",ColumnType.PositionLevel).setTableView(100),
                        new Column("是否通过审核","success",ColumnType.Enum,true).setTableView(80)
                            .setEnum(["0","1","2"],["未审核","未通过审核","审核通过"],["pink","red","green"]),
                        new Column("操作","actions","actions").setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                currentRecord:null,
                viewModel:true,
                tips:"请选择查询年月",
                type:"0",
                selectOptions:[]
            }
        },
        methods:{
            init(){
                this.type = "0";
                this.viewModel = true;
                this.currentRecord = null;
                this.radioChange();
            },
            refresh(){
                this.init();
            },

            tableInit(){
                let data = {
                    success:this.type
                };
                this.argument.loading = true;
                promotionApply.queryByJudgeUser.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            radioChange(){
                this.currentRecord = null;
                this.selectOptions = [];
                this.userId = null;
                this.tableInit();
            },
            showDetail(record){
                this.currentRecord = record;
                this.viewModel = true;
            },
            editDetail(record){
                this.currentRecord = record;
                this.viewModel = false;
            },
            handleSearch(key){
                if(key){
                    key = key.replace(/'/g, '');
                    let data={
                        queryText:key,
                        disableState:"1",loginState:"0"
                    };
                    userInfo.queryAll.requestPOSTUrlParam(this,data,res=>{
                        if(res.data.flag){
                            res.data.data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                            this.selectOptions = res.data.data;
                        }
                        else{
                            this.selectOptions = [];
                        }
                    })
                }
            },
            handleSelect(){
                let data = {
                    userId:this.userId
                };
                this.argument.loading = true;
                promotionApply.queryByJudgeUser.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>