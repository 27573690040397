/**
 * 地图
 * @description：
 * @author:rzl
 * @date:2021/8/25 10:26
 **/

import APIObject from "../../../../assets/utils/APIObject";

let webKey = '4ed50da3a61d11e0e3b0a23eec4e276d';
const api={
    wmtsUrl_bz:'http://t0.tianditu.gov.cn/cva_c/wmts?tk=' + webKey, //矢量注记
    wmtsUrl_sl:'http://t0.tianditu.gov.cn/vec_c/wmts?tk=' + webKey, //矢量底图
    wmtsUrl_yx:'http://t0.tianditu.gov.cn/img_c/wmts?tk=' + webKey, //影像底图
    wmtsUrl_dx:'http://t0.tianditu.gov.cn/ter_c/wmts?tk=' + webKey, //地形底图

    geoserver_url:'geoserver', // 项目红线图层

};
export default api;