<!--
* @program: office_automation 
* @author: ly
* @component:QCManager2
* @description: 质检管理 二检
* @create: 2022-06-10 15:50
-->
<template>
    <div class="search-bar">
        <a-button @click="init" class="search-item" shape="circle" type="dashed">
            <template #icon><SyncOutlined/></template>
        </a-button>
        <a-button class="search-item" type="dashed" v-if="!viewModel" shape="circle" @click="addData" >
            <template #icon><PlusOutlined /></template>
        </a-button>
        <span>二检需要提交质检申请</span>
    </div>
    <a-spin :spinning="loading" tip="正在查询数据库...">
        <div v-if="dataSource.length>0" class="center-flex" style="width: 100%;overflow-x: auto">
            <a-card v-for="(record,idx) in dataSource" :key="idx" style="width: 300px">
                <FcForm style="width: 250px" :form-columns="fcMini" :form-data="record" :view-model="true" >
                    <template #action2>
                        <a-button class="action-button" type="link" @click="showDetail(record)">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-tootip v-if="record.state === '1'" title="质检结果">
                            <a-button class="action-button" type="link" @click="resultShow(record)">
                                <OrderedListOutlined class="pointer"/>
                            </a-button>
                        </a-tootip>
                        <a-popconfirm
                                v-if="!viewModel && record.auditState !=='1'"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </template>
                </FcForm>
            </a-card>
        </div>
        <div v-else style="font-size: 20px">没有二检记录</div>
    </a-spin>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="质检申请"
             :maskClosable="true"
             :footer="null">
        <FcForm :form-columns="formColumns" :form-data="formData" :view-model="true" />
    </a-modal>
    <a-modal v-model:visible="visibleAdd"
             v-if="!viewModel"
             destroy-on-close
             title="添加质检申请"
             :maskClosable="false"
             :footer="null">
        <QCApplyAdd @refresh="addFinish"  :projectId="projectId" />
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroy-on-close
             title="质检结果"
             :maskClosable="true"
             :footer="null">
        <qualityProjectReport v-if="visible2" :view-model="true" :record="currentRecord" />
    </a-modal>
</template>

<script>
    import {PlusOutlined,SyncOutlined,SearchOutlined,DeleteOutlined,OrderedListOutlined} from "@ant-design/icons-vue"
    import QCApplyAdd from "./QCApplyAdd";
    import item_qc_apply from "../../../assets/tables/item_qc_apply";
    import qcApply from "../../../assets/api/qcApply";
    import qualityProjectReport from "../../qualityManager/qualityReportViewer/qualityProjectReport";
    import FcForm from "../../../components/form/FcForm";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    export default {
        name: "QCManager2",
        props:["projectId","viewModel"],
        components:{
            PlusOutlined,SyncOutlined,SearchOutlined,DeleteOutlined,OrderedListOutlined,
            QCApplyAdd,qualityProjectReport,FcForm
        },
        data() {
            return {
                pageSize:15,
                dataSource:[],//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                fcMini:null,
                formData:null,//提供Form的formData
                visible:false,
                visibleAdd:false,
                currentRecord:null,
                visible2:false
            }
        },
        methods: {
            init() {
                this.tableColumns = new item_qc_apply().getTableColumns();
                this.fcMini = [
                    new Column("质检类型","type",ColumnType.Enum,false)
                        .setEnum(["1","2","3"],["项目质检","任务质检","工序质检"],["blue","green","cyan"]),//质检类型（1：项目质检；2：任务质检；3：工序质检）
                    new Column("质检标题","title",ColumnType.String,false),
                    new Column("质检描述","content",ColumnType.String,true),
                    new Column("申请时间","applyTime",ColumnType.Date,false),
                    new Column("申请人","applyUserName",ColumnType.String,false),
                    new Column("审核状态","auditState",ColumnType.String,false)
                        .setEnum(["0","1","2"],["未审核","已通过","未通过"],["purple","green","red"]),
                ];
                this.formColumns = new item_qc_apply().getFormColumns();
                let data = {
                    itemId:this.projectId
                };
                qcApply.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            addData(){
                this.visibleAdd = true;
            },
            addFinish(){
                this.init();
                this.visibleAdd = false;
            },
            showDetail(record){
                this.visible = true;
                this.formData = record;
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                qcApply.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            resultShow(record){
                this.visible2 = true;
                this.currentRecord = record;
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>