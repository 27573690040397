<!--
* @description：财务报表：现金流量表
* @author:rzl
* @date:2022/8/1 10:52
-->

<template>
  <div class="oa-content">
    <div class="oa-window">
      <a-tabs v-model:activeKey="activeKey" @change="init">
        <a-tab-pane key="1" tab="现金流量表" >

          <!-- table start -->
          <ArBaseTable ref="pstable" :pagination="false" :argument="argument_xjll"
                       :view-model="true"   :page-size="99"/>
          <!-- table end -->
        </a-tab-pane>
        <a-tab-pane key="2"  tab="利润表">
          <!-- table start -->
          <ArBaseTable ref="pstable" :pagination="false" :argument="argument_lr" :view-model="true"  :page-size="99"/>
          <!-- table end -->
        </a-tab-pane>
        <a-tab-pane key="3" tab="资产负债表">
          <!-- table start -->
          <ArBaseTable ref="pstable" :pagination="false" :argument="argument_zcfz" :view-model="true"  :page-size="99"/>
          <!-- table end -->
        </a-tab-pane>

        <template #tabBarExtraContent >
          <a-month-picker   @change="check" placeholder="请选择" style="width: 200px"
                            v-model:value="year" :format="dateFormat" />

          <a-button type="primary" @click="downFile" style="margin-left: 10px">
            <template #icon><Download title="导出报表"/></template>导出报表
          </a-button>
        </template>
      </a-tabs>

    </div>
  </div>

  <a-drawer
      placement="bottom"
      :closable="false"
      :destroyOnClose="true"
      height="600px"
      :title="title"
      :visible="visible"
      @close="onClose"
  >
   <finance-report-details ref="financeReportDetails" :downFileName="downFileName"/>
  </a-drawer>

</template>

<script>
import Icons from "../../common/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import moment from "moment";

import cashSubject from "../../../assets/api/cashSubject";
import Column from "../../../assets/tables/parts/column";
import ColumnType from "../../../assets/tables/parts/column_type";
import financeReportDetails from "./financeReportDetails";

export default {
  name: "financeReport",
  mixins:[Icons],
  components:{
    ArBaseTable,financeReportDetails
  },
  data(){
    return{

      activeKey:'1',
      year:null,
      pageParam:{
        page: 1,
        limit: 1000000,
      },
      dateFormat: 'YYYY',

      tableColumns:[],
      tableColumns_zcfz:[],
      /** 现金流量表**/
      argument_xjll:{
        dataSource:[],//提供table的DataSource
        tableColumns:null,
        loading:false,
      },

      /** 利润表 **/
      argument_lr:{
        dataSource:[],//提供table的DataSource
        tableColumns:null,
        loading:false,
      },

      /** 资产负债 **/
      argument_zcfz:{
        dataSource:[],//提供table的DataSource
        tableColumns:null,
        loading:false,
      },

      /** 报表详情 **/
      title:null,
      visible:false,
      yearMonth:null,// 年月
      subjectId:null,// 科目id
      money:0,// 金额

      downFileName:'',
    }
  },
  created() {
    this.tableColumns = [
      new Column("主键", "subjectId", ColumnType.String, false).setVisible(false),
      new Column("级别", "level", ColumnType.String, true),
      new Column("科目名称", "subjectName", ColumnType.String2, true).setTableView(400).setFixed("left")
          .setCustomCell((record,index) => {
            let subjectName = record.subjectName;
            let level = record.level;
            if(level == '1'){
              return {
                style: {
                  //可以定义样式
                  color: '#faad14',
                  'text-align': 'left',
                  'font-size': '18px',
                },
              };
            }else if(subjectName.indexOf("减：") != -1 || subjectName.indexOf("加：") != -1 ){
              return {
                style: {
                  //可以定义样式
                  'padding-left': '20px',
                  'font-size': '14px',
                },
              };
            }else{
              return {
                style: {
                  //可以定义样式
                  'padding-left': '40px',
                  'font-size': '14px',
                },
              };
            }
          }),
      new Column("排序", "sort", ColumnType.Number, true),
      new Column("1月份", "one", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("2月份", "two", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("3月份", "three", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("4月份", "four", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("5月份", "five", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("6月份", "six", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("7月份", "seven", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("8月份", "eight", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("9月份", "nine", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("10月份", "ten", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("11月份", "eleven", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("12月份", "twelve", ColumnType.Number, true).setTableView(120)
          .setCustomCell((record,index)=>{
            return{
              style: {
                'cursor': 'pointer',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler(record,column);
          }),
      new Column("本年累计", "yearCount", ColumnType.Number, true).setTableView(200).setFixed("right")
        .setCustomCell((record,index)=>{
          return{
            style:{
              fontSize: '18px',
            }
          }
        }),
    ];
    this.tableColumns_zcfz = [
      new Column("主键", "subjectId", ColumnType.String, false).setVisible(false),
      new Column("资产:级别", "assetLevel", ColumnType.String, true),
      new Column("负债:级别", "debtLevel", ColumnType.String, true),
      new Column("资产", "assetName", ColumnType.String2, true).setTableView(300).setFixed("left")
          .setCustomCell((record,index) => {
            let assetLevel = record.assetLevel;
            if(assetLevel == '1'){
              return {
                style: {
                  //可以定义样式
                  color: '#faad14',
                  'text-align': 'left',
                  'font-size': '18px',
                },
              };
            }else{
              return {
                style: {
                  //可以定义样式
                  'padding-left': '30px',
                  'font-size': '14px',
                },
              };
            }
          }),
      new Column("资产期末余额", "assetBalance", ColumnType.Number, true).setTableView(200)
          .setCustomCell((record,index)=>{
            return{
              style: {
                cursor: 'pointer',
                color: '#096dd9',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler_zcfz_zc(record,column);
          }),
      new Column("资产期初余额", "assetStartBalance", ColumnType.Number, true).setTableView(200),
      new Column("负债", "debtName", ColumnType.String2, true).setTableView(300).setFixed("left")
          .setCustomCell((record,index) => {
            let debtLevel = record.debtLevel;
            if(debtLevel == '1'){
              return {
                style: {
                  //可以定义样式
                  color: '#faad14',
                  'text-align': 'left',
                  'font-size': '18px',
                },
              };
            }else{
              return {
                style: {
                  //可以定义样式
                  'padding-left': '30px',
                  'font-size': '14px',
                },
              };
            }
          }),

      new Column("负债期末余额", "debtBalance", ColumnType.Number, true).setTableView(200)
          .setCustomCell((record,index)=>{
            return{
              style: {
                cursor: 'pointer',
                color: '#096dd9',
              },
            }
          })
          .setClickHandler((record,column) => {
            this.clickHandler_zcfz_fz(record,column);
          }),
      new Column("负债期初余额", "debtStartBalance", ColumnType.Number, true).setTableView(200),
    ];
    this.argument_xjll.tableColumns = this.tableColumns;
    this.argument_lr.tableColumns = this.tableColumns;
    this.argument_zcfz.tableColumns = this.tableColumns_zcfz;
    var date = new Date();
    this.year = date.getFullYear().toString();
    let time = parseInt(localStorage.getItem("time"));
    this.init();
  },
  methods: {
    init() {
      let active_key = this.activeKey;
      switch (active_key){
        case "1":{
          this.dateFormat = "YYYY";
          this.year = new moment(this.year).format("YYYY");
          this.init_xjll();
          break;
        }
        case "2":{
          this.dateFormat = "YYYY";
          this.year = new moment(this.year).format("YYYY");
          this.init_lr();
          break;
        }
        case "3":{
          this.dateFormat = "YYYY-MM";
          this.year = new moment(this.year).format("YYYY-MM");
          this.init_zcfz();
          break;
        }
      }
    },
    check() {
      this.year = this.year != null ? moment(this.year).format(this.dateFormat) : null;
      if(this.year != null){
        this.init();
      }else{
        this.argument_xjll.dataSource = [];
        this.argument_lr.dataSource = [];
        this.argument_zcfz.dataSource = [];
      }

    },
    setValue(value) {
      this.year = value;
    },
    /** 现金流量 **/
    init_xjll(){
      this.argument_xjll.tableColumns[0].tableView = this.argument_xjll.tableColumns[0].dataIndex == "_index" ? false : this.argument_xjll.tableColumns[0].tableView;
      let parms = {
        year: this.year,
      }
      this.argument_xjll.loading = true;
      cashSubject.calCashReport.requestGET(this, parms, res => {
        this.argument_xjll.loading = false;
        if (res.data.flag) {
          let data = res.data.data;
          if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              let yearCount = 0;
              let subjectName = data[i].subjectName;
              if(subjectName == "加：期初现金及现金等价物余额"){
                yearCount = data[i].one;
              }else if(subjectName == "六、期末现金及现金等价物余额"){
                yearCount = data[i].twelve;
              }else{
                yearCount = data[i].one + data[i].two + data[i].three + data[i].four + data[i].five + data[i].six +
                    data[i].seven + data[i].eight + data[i].nine + data[i].ten + data[i].eleven + data[i].twelve;
              }

              data[i].yearCount = yearCount.toFixed(2);
            }
          }
          this.argument_xjll.dataSource = data;
        }
      });
    },
    /** 利润表 **/
    init_lr(){
      this.argument_lr.tableColumns[0].tableView = this.argument_lr.tableColumns[0].dataIndex == "_index" ? false : this.argument_lr.tableColumns[0].tableView;
      let parms = {
        year: this.year,
      }
      this.argument_lr.loading = true;
      cashSubject.calProfitReport.requestGET(this, parms, res => {
        this.argument_lr.loading = false;
        if (res.data.flag) {
          let data = res.data.data;
          if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              let yearCount = 0;
              let subjectName = data[i].subjectName;
              yearCount = data[i].one + data[i].two + data[i].three + data[i].four + data[i].five + data[i].six +
                  data[i].seven + data[i].eight + data[i].nine + data[i].ten + data[i].eleven + data[i].twelve;

              data[i].yearCount = yearCount.toFixed(2);
            }
          }
          this.argument_lr.dataSource = data;
        }
      });
    },
    /** 资产负债 **/
    init_zcfz(){
      this.argument_zcfz.tableColumns[0].tableView = this.argument_zcfz.tableColumns[0].dataIndex == "_index" ? false : this.argument_zcfz.tableColumns[0].tableView;
      let parms = {
        yearMonth: this.year,
      }
      this.argument_zcfz.loading = true;
      cashSubject.calAssetReport.requestGET(this, parms, res => {
        this.argument_zcfz.loading = false;
        if (res.data.flag) {
          let data = res.data.data;
          this.argument_zcfz.dataSource = data;
        }
      });
    },
    downFile() {
      let active_key = this.activeKey;
      switch (active_key){
        case "1":{
          let parms = {
            year: this.year
          };
          cashSubject.exportCashReport.fileDownloadGet(this, parms, "现金流量报表-"+this.year+".xlsx");
          break;
        }
        case "2":{
          let parms = {
            year: this.year
          };
          cashSubject.exportProfitReport.fileDownloadGet(this, parms, "利润表-"+this.year+".xlsx");
          break;
        }
        case "3":{
          let parms = {
            yearMonth: this.year
          };
          cashSubject.exportAssetReport.fileDownloadGet(this, parms, "资产负债表-"+this.year+".xlsx");
          break;
        }
      }

    },
    /**
     * 点击表格行触发---现金流量、利润
     * @param {Object} record - 行数据
     * @param {Number} columnR - 列值
     * @return Function
     */
    clickHandler(record, columnR) {
      let subjectName = record.subjectName;
      this.subjectId = record.subjectId;
      let colTitle = this.columnTitle(record,columnR);
      let active_key = this.activeKey;
      switch (active_key){
        case "1":{
          this.title = '现金流量报表：' +subjectName +" --- "+ this.year +"年"+ colTitle +"："+this.money+"（￥）";
          this.downFileName = '现金流量报表：' +subjectName +" --- "+ this.year +"年"+ colTitle;
          break;
        }
        case "2":{
          this.title = '利润表：'  +subjectName +" --- "+ this.year +"年"+ colTitle +"："+this.money+"（￥）";
          this.downFileName = '利润表：'  +subjectName +" --- "+ this.year +"年"+ colTitle;
          break;
        }
      }
      let parms ={
        month:this.yearMonth,
        subjectId:this.subjectId
      };
      this.argument_xjll.loading = true;
      this.argument_lr.loading = true;
      cashSubject.detailList.requestGET(this,parms,res=>{
        this.argument_xjll.loading = false;
        this.argument_lr.loading = false;
        if(res.data.flag){
          let data = res.data.data;
          if(data.subjectType == '1'){
            this.visible = false;
            this.$message.info("当前科目关联财务科目，无费用类别详情！");
          }else{
            if((data.collectionList == null || data.collectionList.length == 0)
                &&(data.incomeList == null || data.incomeList.length == 0)
                && (data.otherPayList == null || data.otherPayList.length == 0)
                &&(data.payApplyList == null ||data.payApplyList.length == 0)
                && (data.recordList == null || data.recordList.length == 0)
                &&(data.contractList == null || data.contractList.length == 0)
                &&(data.invoiceRecordList == null || data.invoiceRecordList.length == 0)
                &&(data.invoiceIntoList == null || data.invoiceIntoList.length == 0)
                &&(data.deviceTempList == null || data.deviceTempList.length == 0)){
              this.visible = false;
              this.$message.info("当前统计金额为0，无费用类别详情！");
            }else{
              this.visible = true;
              setTimeout(()=>{
                this.$refs.financeReportDetails.setInit(data);
              },100);
            }

          }
        }
        else{
          this.visible = false;
          this.$message.error(res.data.msg);
        }
      })
    },
    columnTitle(record,columnR){
      let title = "";
      let dataIndex = columnR.dataIndex;
      switch (dataIndex) {
        case "one":{
          title = "1月份";
          this.yearMonth = this.year + "-01";
          this.money = record.one;
          break
        }
        case "two":{
          title = "2月份";
          this.yearMonth = this.year + "-02";
          this.money = record.two;
          break
        }
        case "three":{
          title = "3月份";
          this.yearMonth = this.year + "-03";
          this.money = record.three;
          break
        }
        case "four":{
          title = "4月份";
          this.yearMonth = this.year + "-04";
          this.money = record.four;
          break
        }
        case "five":{
          title = "5月份";
          this.yearMonth = this.year + "-05";
          this.money = record.five;
          break
        }
        case "six":{
          title = "6月份";
          this.yearMonth = this.year + "-06";
          this.money = record.six;
          break
        }
        case "seven":{
          title = "7月份";
          this.yearMonth = this.year + "-07";
          this.money = record.seven;
          break
        }
        case "eight":{
          title = "8月份";
          this.yearMonth = this.year + "-08";
          this.money = record.eight;
          break
        }
        case "nine":{
          title = "9月份";
          this.yearMonth = this.year + "-09";
          this.money = record.nine;
          break
        }
        case "ten":{
          title = "10月份";
          this.yearMonth = this.year + "-10";
          this.money = record.ten;
          break
        }
        case "eleven":{
          title = "11月份";
          this.yearMonth = this.year + "-11";
          this.money = record.eleven;
          break
        }
        case "twelve":{
          title = "12月份";
          this.yearMonth = this.year + "-12";
          this.money = record.twelve;
          break
        }

      }
      return title;
    },
    onClose(){
      this.visible = false;
    },
    /**
     * 点击表格行触发---资产
     * @param {Object} record - 行数据
     * @param {Number} columnR - 列值
     * @return Function
     */
    clickHandler_zcfz_zc(record, columnR) {
      let subjectName = record.assetName;
      this.subjectId = record.assetId;
      this.title = '资产负债表：' +subjectName +" --- "+ this.year +"---资产期末余额："+record.assetBalance +"---资产期初余额："+ record.assetStartBalance;
      this.downFileName = '资产负债表：' +subjectName +" --- "+ this.year;
      let parms ={
        yearMonth:this.year,
        subjectId:this.subjectId
      };
      this.argument_zcfz.loading = true;
      cashSubject.detailListForZiChan.requestGET(this,parms,res=>{
        this.argument_zcfz.loading = false;
        if(res.data.flag){
          let data = res.data.data;
          if(data.subjectType == '1'){
            this.visible = false;
            this.$message.info("当前科目关联财务科目，无费用类别详情！");
          }else{
            if((data.collectionList == null || data.collectionList.length == 0)
                &&(data.incomeList == null || data.incomeList.length == 0)
                && (data.otherPayList == null || data.otherPayList.length == 0)
                &&(data.payApplyList == null ||data.payApplyList.length == 0)
                && (data.recordList == null || data.recordList.length == 0)
                &&(data.contractList == null || data.contractList.length == 0)
                &&(data.invoiceRecordList == null || data.invoiceRecordList.length == 0)
                &&(data.invoiceIntoList == null || data.invoiceIntoList.length == 0)
                &&(data.deviceTempList == null || data.deviceTempList.length == 0)){
              this.visible = false;
              this.$message.info("当前统计金额为0，无费用类别详情！");
            }else{
              this.visible = true;
              setTimeout(()=>{
                this.$refs.financeReportDetails.setInit(data);
              },100);
            }

          }
        }
        else{
          this.visible = false;
          this.$message.error(res.data.msg);
        }
      })
    },
    /**
     * 点击表格行触发---负债
     * @param {Object} record - 行数据
     * @param {Number} columnR - 列值
     * @return Function
     */
    clickHandler_zcfz_fz(record, columnR) {
      let subjectName = record.debtName;
      this.subjectId = record.debtId;
      this.title = '资产负债表：' +subjectName +" --- "+ this.year +"---负债期末余额："+record.debtBalance +"---负债期初余额："+ record.debtStartBalance;
      this.downFileName = '资产负债表：' +subjectName +" --- "+ this.year;
      let parms ={
        yearMonth:this.year,
        subjectId:this.subjectId
      };
      this.argument_zcfz.loading = true;
      cashSubject.detailListForZiChan.requestGET(this,parms,res=>{
        this.argument_zcfz.loading = false;
        if(res.data.flag){
          let data = res.data.data;
          if(data.subjectType == '1'){
            this.visible = false;
            this.$message.info("当前科目关联财务科目，无费用类别详情！");
          }else{
            if((data.collectionList == null || data.collectionList.length == 0)
                &&(data.incomeList == null || data.incomeList.length == 0)
                && (data.otherPayList == null || data.otherPayList.length == 0)
                &&(data.payApplyList == null ||data.payApplyList.length == 0)
                && (data.recordList == null || data.recordList.length == 0)
                &&(data.contractList == null || data.contractList.length == 0)
                &&(data.invoiceRecordList == null || data.invoiceRecordList.length == 0)
                &&(data.invoiceIntoList == null || data.invoiceIntoList.length == 0)
                &&(data.deviceTempList == null || data.deviceTempList.length == 0)){
              this.visible = false;
              this.$message.info("当前统计金额为0，无费用类别详情！");
            }else{
              this.visible = true;
              setTimeout(()=>{
                this.$refs.financeReportDetails.setInit(data);
              },100);
            }

          }
        }
        else{
          this.visible = false;
          this.$message.error(res.data.msg);
        }
      })
    },
  }
}
</script>

<style scoped>
.ant-tabs-nav-scroll{
  float: left;
}
.ant-table-tbody tr:hover{
  color: red;
}
</style>