<!--
* @program: office_automation 
* @author: ly
* @component:taskProgressChart 
* @description: 用于展示项目任务进度
* @create: 2022-01-06 18:17
-->
<template>
    <div style="display: flex;flex-wrap: wrap;" >
        <TagShow style="width: 300px" color="red" :visible="true" tag="总进度">
            <template #content>
                <div>总进度以各工序合计为100%计算</div>
                <a-progress type="circle"  :percent="percent" />
            </template>
        </TagShow>
        <div v-for="(item ,index) in records" :key="index">
            <taskProcessProgressChart :record="item" v-model:percent="item.percent" :visible="true" />
        </div>
    </div>
</template>

<script>
    import taskProcessProgressChart from "./taskProcessProgressChart";
    import TagShow from "../../_components/TagShow";
    export default {
        name: "taskProgressChart",
        props:["records"],
        components:{
            taskProcessProgressChart,TagShow
        },
        computed:{
           percent(){
               let percent = 0;
               for(let i in this.processList){
                   percent += this.processList[i].percent * this.processList[i].Wpercent
               }
               return percent.toFixed(2)
           }
        },
        data(){
            return{
                processList:[],
                sumWeight:0, //所有工序权重合计
            }
        },
        created() {
            this.processList = [...this.records];
            for(let i in this.processList){
                this.processList[i].percent = 0;
                this.sumWeight += this.processList[i].workRatio;
            }
            for(let i in this.processList){
                this.processList[i].Wpercent = this.processList[i].workRatio/this.sumWeight;
            }
        }
    }
</script>

<style scoped>

</style>