import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: promotion_apply
 * @create: 2022-03-08 13:58
 **/

export default class promotion_apply extends Table{
    constructor() {
        super();
        this.name = "promotion_apply";
        this.CNName = "职位晋升申请";
        this.columnArray = [
            new Column("现岗位","currentLevel",ColumnType.PositionLevel).setTableView(80),
            new Column("晋升岗位","applyLevel",ColumnType.PositionLevel,true).setTableView(80),
            new Column("申请时间","applyTime",ColumnType.Date),
            new Column("晋升自述","workDescribe",ColumnType.String,true),
            new Column("月绩效考核情况","achieve",ColumnType.String),
            new Column("部门意见","departSugg",ColumnType.String),
            new Column("部门负责人","departSuggUserName",ColumnType.String),
            new Column("人力资源部意见","hrSugg",ColumnType.String),
            new Column("人力资源部负责人","hrSuggUserName",ColumnType.String),
            new Column("总经办意见","gmoSugg",ColumnType.String),
            new Column("总经办成员","gmoSuggUserName",ColumnType.String),
            new Column("特殊人才","specialFlag",ColumnType.Boolean).setDefaultValue(false)
                .setBoolTags(["否","是"]),
            new Column("晋升评分","score",ColumnType.String),
            new Column("审核状态","success",ColumnType.Enum)
                .setEnum(["0","1","2"],["待审核","未通过审核","审核通过"],["pink","red","green"]),//0：申请中（等待走流程）；1：未成功; 2:成功
            new Column("生效日期","effectDate",ColumnType.Date).setTableView(80),
            new Column("状态","submitState",ColumnType.Boolean).setTableView(80)
                .setBoolTags(["未提交审核","已提交审核"]),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}