<!--
*@description：成果查询组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <div class="ant-row">
      <div class="ant-col-19">
        <a-select
            v-model:value="op_value"
            label-in-value
            style="width: 200px"
            :options="options"
            @change="handleChange"
        >
        </a-select>

        <a-month-picker   v-if="op_value.value === '0' || op_value.value === '1'"   @change="check" placeholder="请选择" style="width: 200px" v-model:value="yearMonth" :format="dateFormat" />
        <a-select
            show-search
            v-else
            v-model:value="itemName"
            placeholder="输入项目名称查询"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            style="width: 40%"
            @search="handleSearch"
            @change="columnTrigger"
        >
          <a-select-option v-for="option in itemData" :key="option.id">
            {{ option.name }}
          </a-select-option>
        </a-select>

        <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
          <template #icon><Search /></template>
        </a-button>
<!--        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
          <template #icon><Reset /></template>
        </a-button>-->
      </div>


    <div class="ant-col-4">
      <a-button  danger style="margin-left: 80%;" @click="back">返回</a-button>
    </div>




  </div>
</template>

<script>
import Icons from "../../../common/Icons";
import monthSelector from "../../../../components/SearchBar/MonthSelector";
import {getNowMonth} from "../../../../assets/utils/dataUtil"
import moment from "moment";
import achieveRecord_api from "../../../qualityManager/DataProducts/config/achieveRecord_api";

export default {
      name: "achieveTypeToolBar",
      mixins:[Icons],
      components:{
        monthSelector
      },
      data(){
        return{
          options:[
            {value: '0',label: '年度汇总',},
            {value: '1',label: '月度汇总',},
            {value: '2',label: '项目汇总',},
          ],
          op_value:{value: '0',label: '年度汇总',},
          yearMonth:null,
          dateFormat: 'YYYY-MM',
          //values:["2020-01","2099-12"], //允许的时间范围
          // 项目搜索
          itemId:null,
          itemName:null,
          itemData:[],
        }
      },

      methods:{
        doSearch(){
          let parms = this.getParms();
          let data = this.$GetEmitData(this.$eventTags.search,parms);
          this.$emit("event",data);
        },
        refresh(){
          this.setValue(null);
          this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        },
        back(){
          this.$emit("event",this.$GetEmitData(this.$eventTags.back));
        },
        handleChange(op){
          this.setValue(null);
          let type = op.value;
          switch (type){
            case "0":{
              this.setDateFormat("YYYY");
              break;
            }
            case "1":{
              this.setDateFormat("YYYY-MM");
              break;
            }

          }
        },
        /** 日期选择 start ***/
        setDateFormat(dateFormat){
          this.dateFormat = dateFormat;
        },
        getValue(){
          if(this.yearMonth){
            return moment(this.yearMonth).format(this.dateFormat);
          }
          else{
            return null;
          }
        },
        check(){
         /* if(this.yearMonth && moment(this.yearMonth).isBefore(this.values[0])){
            this.yearMonth = moment(this.values[0]);
          }
          if(this.yearMonth && moment(this.yearMonth).isAfter(this.values[1])){
            this.yearMonth = moment(this.values[1]);
          }*/
          this.$emit('update:value', this.getValue());
          this.$emit('change', this.getValue());
        },
        setValue(value){
          this.yearMonth = value;
          this.itemId = null;
        },
        /** 日期选择 end ***/

        /********** 项目 start ************/
        handleSearch(val){
          let parms = {
            name:val
          }
          achieveRecord_api.queryAll_item.requestPOST(this,parms,res=>{
            if(res.data.flag){
              let data = res.data.data;
              let option_data = [];
              if(data.length > 0){
                for(let i=0;i<data.length;i++){
                  let info = data[i];
                  let parm = {
                    id:info.id,
                    name:info.name
                  };
                  option_data.push(parm);
                }
              }
              this.itemData = option_data;
            }else{
              this.itemData = [];
            }
          });
        },
        columnTrigger(val){
          this.itemId = val;
        },
        getFmt(){
          return this.dateFormat;
        },
        /********** 项目 end  *************/
        getParms(){
          let parms = {
            yearMonth:this.getValue(),
            itemId:this.itemId,
          }
          return parms;
        },

      }
    }
</script>

<style scoped>

</style>