<!--
* @description：公司系统管理
* @author:rzl
* @date:2022/7/27 15:43
-->
<template>
    <a-input placeholder="系统名称" style="width: 15%;" v-model:value="queryText"/>
    <a-tooltip title="查询">
        <a-button @click="init" class="search-item" type="dashed">
            <template #icon>
                <Search/>
            </template>
        </a-button>
    </a-tooltip>
    <a-tooltip title="刷新">
        <a-button @click="refresh" class="search-item" type="dashed">
            <template #icon>
                <Reset/>
            </template>
        </a-button>
    </a-tooltip>
    <a-tooltip title="添加角色">
        <a-button @click="addData" class="search-item" type="dashed">
            <template #icon>
                <Add/>
            </template>
        </a-button>
    </a-tooltip>


    <!-- table start -->
    <ArBaseTable :argument="argument" :page-size="pageParam.limit" :view-model="true" ref="pstable"
                 style="margin:0 10%;width: 80%;">
        <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
                <a-button @click="editData(index,record)" type="link">
                    <template #icon>
                        <Edit title="编辑"/>
                    </template>
                </a-button>
                <a-popconfirm
                        @confirm="deleteData(index,record)"
                        cancelText="取消"
                        class="action-button"
                        okText="确定"
                        title="确定删除吗?"
                >
                    <a-button type="link">
                        <template #icon>
                            <delete style="color: red" title="删除"/>
                        </template>
                    </a-button>
                </a-popconfirm>
            </div>
        </template>
    </ArBaseTable>
    <!-- table end -->

    <a-modal :destroyOnClose="true"
             :maskClosable="false"
             :title="title" v-model:visible="visible"
             width="30%"
    >

        <ar-form :argument="argument" :viewModel="false" ref="bill_form"/>

        <template #footer>
            <a-button @click="submit" type="primary">提交</a-button>
            <a-button @click="visible =false" key="back">取消</a-button>
        </template>
    </a-modal>
</template>

<script>
    import Icons from "../../common/Icons";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import ArForm from "../../../components/form/ArForm";

    import companySystemMange_item from "./configs/companySystemMange_item";
    import system from "../../../assets/api/system";

    export default {
        name: "companySystemMange",
        mixins: [Icons],
        components: {
            ArForm, ArBaseTable
        },
        data() {
            return {
                pageParam: {
                    page: 1,
                    limit: 10,
                },
                argument: {
                    dataSource: [],//提供table的DataSource
                    tableColumns: new companySystemMange_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
                    formColumns: new companySystemMange_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
                    formData: {},
                    loading: false,
                },
                title: null,
                visible: false,
                queryText: '',
            }
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                let parms = {
                    name: this.queryText
                };
                this.argument.loading = true;
                system.queryAll.requestPOST(this, parms, res => {
                    this.argument.loading = false;
                    if (res.data.flag) {
                        this.argument.dataSource = res.data.data;
                    }
                });
            },
            refresh() {
                this.queryText = '';
                this.init()
            },
            addData() {
                this.argument.formData = {};
                this.visible = true;
                this.title = "新增信息";
            },
            deleteData(index, record) {
                let data = {id: record.id};
                system.delete.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        this.init();
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editData(index, record) {
                this.argument.formData = record;
                this.visible = true;
                this.title = "编辑信息";
            },
            submit() {
                let parms = this.argument.formData;
                system.saveOrUpd.requestPOST(this, parms, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },

        },
    }
</script>

<style scoped>

</style>