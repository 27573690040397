<!--
* 地图公用控件
* @description：
* @author:rzl
* @date:2021/7/16 9:21
-->

<template>
    <div id="map" ref="rootmap" class="divTdtMap" style="height: 400px; width: 100%; ">
      <!-- 鼠标位置 -->
      <div id="mouse-position"></div>
    </div>
</template>

<script>
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import {OSM,Vector as VectorSource,XYZ,WMTS,ImageWMS} from 'ol/source'
import {Tile as TileLayer,Vector as VectorLayer,Image} from 'ol/layer'
import {ScaleLine,MousePosition,OverviewMap} from 'ol/control'
import {fromLonLat,get as getProjection,Projection,transform} from 'ol/proj'
import {createStringXY} from 'ol/coordinate'
import {getWidth,getTopLeft,getCenter} from 'ol/extent'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import WKT from 'ol/format/WKT'

import map_api from "../../config/map_api";
export default {
  name: "map_info",
  inject:["wkt_geom"],
  data(){
    return{
      map:null,// 地图容器
      geom:null,
      wktGeom:null,
      vectorLayer:null,// 高亮图层
    }
  },
  methods:{
    /** 初始化地图 **/
    initMap(wktGeom){
      var projection = getProjection('EPSG:4326');// 4326
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 256;
      var resolutions = new Array(18);
      var matrixIds = new Array(18);
      for(var z=1;z<19;++z){
        resolutions[z]=size/Math.pow(2,z);
        matrixIds[z]=z;
      }

      // 视图
      var view = new View({
        center: fromLonLat([106.7572,26.7782]),//106.6472,26.4332
        zoom:12
      });
      var TiandiMap_img = new TileLayer({
        name: "天地图影像图层",
        opacity:0.7,
        source:new WMTS({
          url:map_api.wmtsUrl_yx,
          layer:'img',
          matrixSet:'c',
          format:'tiles',
          style:'default',
          projection:projection,
          tileGrid:new WMTSTileGrid({
            origin:getTopLeft(projectionExtent),
            resolutions:resolutions,
            matrixIds:matrixIds
          }),
          wrapX:true
        })
      });
      var TiandiMap_cia = new TileLayer({
        name: "天地图影像注记图层",
        source: new WMTS({
          url:map_api.wmtsUrl_bz,
          layer:'cva',
          matrixSet:'c',
          format:'tiles',
          style:'default',
          projection:projection,
          tileGrid:new WMTSTileGrid({
            origin:getTopLeft(projectionExtent),
            resolutions:resolutions,
            matrixIds:matrixIds
          }),
          wrapX:true
        })
      });

      // 地图
      this.map = new Map({
        target:'map',
        layers:[
          TiandiMap_img,
          TiandiMap_cia
        ],
        /**
         * 让地图视图拥有动画效果：关键点loadTilesWhileAnimating、vie.animate;最基本效果：移动、旋转、缩放
         * */
        loadTilesWhileAnimating: true,//将这个设置为true，默认为false
        view:view
      });

      //添加比例尺控件
      var scaleLineControl = new ScaleLine({
        units: 'metric',
        target: 'scalebar',
        className: 'ol-scale-line'
      });
      this.map.addControl(scaleLineControl);


      //实例化鼠标位置控件
      var mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(4),//坐标格式
        //地图投影坐标系
        projection: 'EPSG:4326',
        //className:'tip',
        target: document.getElementById('mouse-position'),//显示鼠标位置信息的目标容器
        undefinedHTML: '&nbsp;'//未定义坐标标记
      });

      //添加鼠标位置控件
      this.map.addControl(mousePositionControl);

      // 添加项目红线图层
      // this.layers_list();

      // 红线
      this.wkt_show(wktGeom);

    },
    /** 添加图层 **/
    layers_list(){
      var cql_zjd = '';// 宅基地

      this.leyer_zjd_land_plots = new Image({     //TileLayer、ImageLayer
        source: new ImageWMS({    //TileWMS、ImageWMS
          ratio: 1,
          params: {
            //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
            'VERSION': '1.1.0',
            'LAYERS': 'zhaijidi:zjd_land_plots',// geoserver发布宅基地线服务
            'STYLES': '',
            'CQL_FILTER': cql_zjd,  // 图层属性参数绑定
          },
          url: map_api.geoserver_url
        })
      })
      this.map.addLayer(this.leyer_zjd_land_plots);
    },
    /** wkt处理 **/
    wkt_show(wktGeom){
      if(wktGeom == null || wktGeom == ''){
        return;
      }

      if(this.vectorLayer != null){
        this.map.removeLayer(this.vectorLayer);
      }
      this.wktGeom = wktGeom;
      var source = new VectorSource();
      // 解析 wkt字符串
      var feature = new WKT().readFeature(this.wktGeom, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
      source.addFeature(feature);
      this.vectorLayer = new VectorLayer({source: source});
      this.map.addLayer(this.vectorLayer);
      let view = this.map.getView();
      view.fit(feature.getGeometry(),{
        duration:1000,
        maxZoom:12,
      });
    }

  },
}
</script>

<style scoped>
.divTdtMap {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  z-index: 0;
}
.navigation{
  width: 100%;
  height: 10%;
  z-index: 999;
  border: 1px solid;
  border-radius: 0;
  cursor: pointer;
  padding: 0 10px;
  opacity: 0.8; /**透明度**/
  background-color: #bfbfbf;
}
#mouse-position{
  z-index: 999;
  left: 10%;
  bottom: 8%;
  position: absolute;
  color: white;
  width: 150px;
}
.xm_query{
  position: absolute;
  z-index: 1000;
 /* border: 1px solid #1e76d2;*/
  /*border-radius: 14px;*/

  margin-left: 3%;
  margin-top: 2%;
}
.layerContent_list{
  width: 15%;
  background-color: #bfbfbf;
  border-radius: 5px;
  z-index: 1000;
  color: #ffffff;
  padding: 0 10px 10px 10px;
  opacity: 0.9;
  position: absolute;
  margin-left: 3%;
  margin-top: 5%;
  height: 40%;
}

.layerContent{
  width: 90%;
  background-color: #bfbfbf;
  border-radius: 5px;
  z-index: 1000;
  color: #ffffff;
  padding: 0 10px 10px 10px;
  opacity: 0.9;
  position: absolute;
  bottom: 1px;
  height: 35%;

}
.tabClass{
  overflow-y: auto;
  height: 290px;
}
.tabClass2{
  overflow-y: auto;
  height: 290px;
  margin-top: -290px;
}
.tabClass_file{
  overflow-y: auto;
  height: 240px;
}

</style>


