<!--
* @program: tyh-oa 
* @author: ly
* @component:thirdPartyUserManage
* @description: 外协单位管理
* @create: 2021-06-08 15:49
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
                <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <PlusOutlined/>
                    </template>
                </a-button>

              <a-button  @click="openShow" style="margin-left: 10px">
                <template #icon><BarChartOutlined title="外协合同统计" style="color:green"/></template>外协合同统计
              </a-button>
            </div>
            <TwoParts @change="handleChange" ref="TwoParts" pos="transform">
                <template #left>
                    <ArBaseTableFilter :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(index,record)" type="link">
                                    <template #icon><SearchOutlined /></template>
                                </a-button>
                                <a-button @click="editDetail(index,record)" type="link">
                                    <template #icon><EditFilled /></template>
                                </a-button>
                                <a-popconfirm
                                        v-if="checkDeletable(record)"
                                        @confirm="deleteData(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="删除？">
                                    <a-button style="color: red" type="link">
                                        <template #icon><DeleteOutlined /></template>
                                    </a-button>
                                </a-popconfirm>
                            </div>
                        </template>
                    </ArBaseTableFilter>
                </template>
                <template #right>
                    <otherPartyTabs :pane-list="paneList" />
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="添加外协">
        <ArForm :argument="formArg" :view-model="false" >
            <template #action="{record}">
                <a-button  type="primary" @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArForm>
    </a-modal>

  <!-- 外协合同统计 start -->
  <a-drawer
      title="外协合同统计"
      placement="bottom"
      :closable="false"
      :visible="visible_tj"
      height="60%"
      destroyOnClose="true"
      @close="onClose"
  >
    <div class="oa-content" style="height: 430px">
      <div v-if="sum_flag" class="oa-window">
        <div class="ant-row" style="height: 10%;">
<!--          <a-month-picker   @change="check" placeholder="请选择时间" style="width: 200px"
                            v-model:value="year" :format="dateFormat" />-->

          <a-select
              show-search
              v-model:value="itemName"
              placeholder="输入外协单位名称"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              style="width: 30%;"
              @search="handleSearch"
              @change="columnTrigger"
              allowClear="true"
          >
            <a-select-option v-for="option in itemData" :key="option.id">
              {{ option.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ant-row" style="height: 90%;">
          <div class="ant-col-24 divhover" style="box-shadow: 0px 0px 5px #888888;">
            <div ref="chart_item_year" style="height:100% "></div>
          </div>
        </div>
      </div>
      <div v-else class="oa-window">
        <div class="ant-row" style="height: 8%;">
          <a-button  danger style="margin-left: 95%;" @click="back">返回</a-button>
        </div>
        <div class="ant-row" style="height: 92%;">
          <div class="ant-col-24 divhover" style="box-shadow: 0px 0px 5px #888888;">
            <div ref="chart_item_month" style="height:100% "></div>
          </div>
        </div>
      </div>
    </div>
  </a-drawer>
  <!-- 外协合同统计 end -->
</template>

<script>
    import item_outsourc_info from "../../../assets/tables/item_outsourc_info";
    import api_item_outsourc_info from "../../../assets/api/outSourceInfo";
    import TwoParts from "../../_components/TwoParts";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import ICONS from "../../_components/ICONS";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import otherPartyTabs from "./otherPartyTabs";
    import ArForm from "../../../components/form/ArForm";
    import {markRaw} from "vue";
    import * as echarts from "echarts";
    import contractInfo from "../../../assets/api/contractInfo";
    import moment from "moment";
    import achieveRecord_api from "../../qualityManager/DataProducts/config/achieveRecord_api";
    export default {
        name: "thirdPartyUserManage",
        mixins: [ICONS],
        components: {
            TwoParts, ArBaseTableFilter, SearchBar,
            otherPartyTabs,ArForm
        },
        data() {
            return {
                table: new item_outsourc_info(),
                searchOptions: [{value: "queryText", label: "关键字"}],
                searchData: null,
                argument: {
                    dataSource: null,//提供table的DataSource
                    tableColumns: null, //提供table的字-段集 this.table.getTableColumns()
                    loading: false,//table的loading状态
                },
                formArg: {
                    formColumns: null, //提供Form的字段集 this.table.getFormColumns()
                    formData: null,//提供Form的formData
                },
                visible:false,
                pageSize:15,
                paneList:[],

              visible_tj:false,
              sum_flag:true,
              year:null,

              dateFormat: 'YYYY',
              chartsItem:null,
              statCount_data:[],
              chartsCurrentData:null,
              data_ht:[],
              chartsItem_month:null,

              // 外协单位搜索
              outId:null,// 外协单位
              itemName:null,
              itemData:[],
            }
        },
        methods: {
            init() {
                this.argument.loading = true;
                this.visible = false;
                api_item_outsourc_info.queryAll.requestPOSTUrlParam(this, {}, res => {
                    this.argument.loading = false;
                    if (res.data.flag) {
                        this.argument.dataSource = res.data.data;
                    }
                })
            },
            refresh(){
                this.init();
                this.$refs.TwoParts.reset();
            },
            doSearch(){
                api_item_outsourc_info.queryAll.requestPOSTUrlParam(this, this.searchData, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.argument.dataSource = res.data.data;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addData(){
                this.formArg.formData = this.table.getFormData();
                this.visible = true;
            },
            showDetail(index,record){
                this.paneList = [
                    {tag:"外协项目详情",component:"partnerInfo",viewModel:true,record:record},
                    {tag:"基本信息",component:"detailInfo",viewModel:true,record:record},
                ]
            },
            editDetail(index,record){
                this.paneList = [
                    {tag:"基本信息",component:"detailInfo",viewModel:false,record:record}
                ]
            },
            deleteData(index,record){
                let data = {id: record.id};
                api_item_outsourc_info.delete.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index, 1);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                api_item_outsourc_info.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handleChange(pos){
                if (pos === "transform") {
                    this.argument.tableColumns = [
                        new Column("姓名/单位名称","name",ColumnType.String,true).setTableView(200),
                        new Column("操作","actions","actions").setTableView(80),
                    ]
                } else {
                    this.argument.tableColumns = this.table.getTableColumns();
                }
            },
            checkDeletable(record){
                return  record.createUser === this.$store.getters.userInfo.id || this.$store.getters.userInfo.id === "2";
            },

          check() {
            this.year = this.year != null ? moment(this.year).format(this.dateFormat) : null;
            if(this.year != null){
              this.openStatistic();
            }

          },
          handleSearch(val){
            let parms = {
              queryText:val,
              type:'1'
            }
            api_item_outsourc_info.queryAll.requestPOSTUrlParam(this,parms,res=>{
              if(res.data.flag){
                let data = res.data.data;
                let option_data = [];
                if(data.length > 0){
                  for(let i=0;i<data.length;i++){
                    let info = data[i];
                    let parm = {
                      id:info.id,
                      name:info.name
                    };
                    option_data.push(parm);
                  }
                }
                this.itemData = option_data;
              }else{
                this.itemData = [];
              }
            });
          },
          columnTrigger(val){
            this.outId = val == null || val == undefined ? null : val;
            this.openStatistic();
          },
          openShow(){
              this.outId = null;
              this.itemName = null;
              this.itemData = [];
              this.openStatistic();
          },
          openStatistic(){
              let parms = {
                outId:this.outId,
              };
            contractInfo.statOutCount.requestPOSTUrlParam(this,parms,res=>{
              let data = [];
              if(res.data.flag){
                this.sum_flag = true;
                this.visible_tj = true;
                let dataInfo = res.data.data;
                this.data_ht = dataInfo;
                // 封装成分析需要的格式
                if(dataInfo.length > 0){
                  let list_x = [];
                  let sumCount = []; // 该年总个数
                  let sumAmount = [];// 该年总金额
                  let payAmount = [];//已支付总金额
                  let noPayAmount = [];//未支付总金额
                  dataInfo.forEach((item,i)=>{
                    list_x.push(item.year);
                    sumCount.push(item.sumCount);
                    sumAmount.push((item.sumAmount/10000).toFixed(2));
                    payAmount.push((item.payAmount/10000).toFixed(2));
                    noPayAmount.push((item.noPayAmount/10000).toFixed(2));
                  })
                  data.push(list_x);
                  data.push(sumCount);
                  data.push(sumAmount);
                  data.push(payAmount);
                  data.push(noPayAmount);
                }

              }else{
                this.data_ht = [];
                this.$message.error(res.data.msg);
              }
              setTimeout(()=>{
                this.statCount_data = data;
                this.eachar_item(data);
              },200)
            });

          },
          onClose(){
            this.visible_tj = false;
          },
          eachar_item(data){
            if(this.chartsItem){
              this.chartsItem.clear();
            }
            let option = null;
            if(data.length <= 0){
              option = {
                title: {
                  extStyle: {
                    color: "grey",
                    fontSize: 20

                  },
                  textStyle:{
                    color:"#FFD700",
                  },

                  text: "暂无数据",

                  left: "center",

                  top: "center"

                },
              }
            }else{
              option = {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                    crossStyle: {
                      color: '#999'
                    }
                  },
                  formatter: (params) => {
                    let sum = 0;
                    var result = params[0].axisValueLabel + '<br>';
                    params.forEach(function (item) {
                      if (item.value) {
                          result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                      }
                    })

                    /*params.forEach(function (item) {
                      if (item.value) {
                        if(item.componentSubType == "bar"){
                          sum += parseFloat(item.value);
                        }else if(item.componentSubType == "line"){
                          result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                        }
                      }
                    })
                    result += '合同总金额：'+sum.toFixed(2)+ '<br>';
                    params.forEach(function (item) {
                      if (item.value) {
                        if(item.componentSubType == "bar"){
                          result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                        }
                      }
                    })*/

                    // 保留数据
                    this.chartsCurrentData = params[0]
                    // 返回mousemove提示信息
                    return result
                  }
                },
                legend: {
                  selected: {
                    '已支付总金额':false,
                    '未支付总金额':false,
                  }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: [
                  {
                    type: 'category',
                    data: data[0],
                    axisPointer: {
                      type: 'shadow'
                    }
                  }
                ],
                yAxis: [
                  {
                    type: 'value',
                    name: '金额（万元）',
                    interval: 500,
                    axisLabel: {
                      formatter: '{value} '
                    }
                  },
                  {
                    type: 'value',
                    name: '数量（份）',
                    interval: 50,
                    axisLabel: {
                      formatter: '{value}'
                    }
                  }
                ],
                series: [
                  {
                    name: '年合同总数',
                    type: 'line',
                    yAxisIndex: 1,
                    tooltip: {
                      valueFormatter: function (value) {
                        return value ;
                      }
                    },
                    data: data[1]
                  },
                  {
                    name: '年合同总金额',
                    type: 'bar',
                    emphasis: {
                      focus: 'series'
                    },
                    tooltip: {
                      valueFormatter: function (value) {
                        return value;
                      }
                    },
                    data: data[2]
                  },
                  {
                    name: '已支付总金额',
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                      focus: 'series'
                    },
                    tooltip: {
                      valueFormatter: function (value) {
                        return value ;
                      }
                    },
                    data: data[3]
                  },
                  {
                    name: '未支付总金额',
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                      focus: 'series'
                    },
                    tooltip: {
                      valueFormatter: function (value) {
                        return value ;
                      }
                    },
                    data: data[4]
                  },

                ]
              };
            }
            this.chartsItem = markRaw(echarts.init(this.$refs.chart_item_year))//获取dom元素;
            this.chartsItem.setOption(option);

            /** 扩大点击范围 start **/
            // 防止点击调用多次
            this.chartsItem.getZr().off('click')
            // 鼠标移动到阴影范围 setCursorStyle('pointer')
            this.chartsItem.getZr().on('mousemove', param => {
              var pointInPixel= [param.offsetX, param.offsetY];
              if (this.chartsItem.containPixel('grid',pointInPixel)) {//若鼠标滑过区域位置在当前图表范围内 鼠标设置为小手
                this.chartsItem.getZr().setCursorStyle('pointer')
              }else{
                this.chartsItem.getZr().setCursorStyle('default')
              }
            })
            this.chartsItem.getZr().on('click', params => {

              // 获取到鼠标点击位置：
              const pointInPixel = [params.offsetX, params.offsetY]
              // 判断点击位置是否在显示图形区域
              if (this.chartsItem.containPixel('grid', pointInPixel)) {
                this.sum_flag = false;
                let year = this.chartsCurrentData.axisValueLabel;
                setTimeout(()=>{
                  this.eachar_item_month(year);
                },200)
              }
            })
            /** 扩大点击范围 end **/

          },
          // 月份统计
          eachar_item_month(year){
            let info = this.data_ht.filter(item=>item.year === year);
            let data = [];
            let dataInfo = info[0].monthList;
            // 封装成分析需要的格式
            if(dataInfo.length > 0){
              let list_x = [];
              let sumCount = []; // 该年总个数
              let sumAmount = [];// 该年总金额
              let payAmount = [];//已支付总金额
              let noPayAmount = [];//未支付总金额
              dataInfo.forEach((item,i)=>{
                list_x.push(item.month);
                sumCount.push(item.sumCount);
                sumAmount.push((item.sumAmount/10000).toFixed(2));
                payAmount.push((item.payAmount/10000).toFixed(2));
                noPayAmount.push((item.noPayAmount/10000).toFixed(2));
              })
              data.push(list_x);
              data.push(sumCount);
              data.push(sumAmount);
              data.push(payAmount);
              data.push(noPayAmount);
            }
            if(this.chartsItem_month){
              this.chartsItem_month.clear();
            }
            let option = null;
            if(data.length <= 0){
              option = {
                title: {
                  extStyle: {
                    color: "grey",
                    fontSize: 20

                  },
                  textStyle:{
                    color:"#FFD700",
                  },

                  text: "暂无数据",

                  left: "center",

                  top: "center"

                },
              }
            }else{
              option = {
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                    crossStyle: {
                      color: '#999'
                    }
                  },
                  formatter: (params) => {
                    var result = params[0].axisValueLabel + '<br>'
                    params.forEach(function (item) {
                      if (item.value) {
                        result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                      }
                    })
                    // 保留数据
                    this.chartsCurrentData = params[0]
                    // 返回mousemove提示信息
                    return result
                  }
                },
                legend: {
                  selected: {
                    '已支付总金额':false,
                    '未支付总金额':false,
                  }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: [
                  {
                    type: 'category',
                    data: data[0],
                    axisPointer: {
                      type: 'shadow'
                    }
                  }
                ],
                yAxis: [
                  {
                    type: 'value',
                    name: '金额（万元）',
                    interval: 200,
                    axisLabel: {
                      formatter: '{value} '
                    }
                  },
                  {
                    type: 'value',
                    name: '数量（份）',
                    interval: 10,
                    axisLabel: {
                      formatter: '{value}'
                    }
                  }
                ],
                series: [
                  {
                    name: '该年合同总数',
                    type: 'line',
                    yAxisIndex: 1,
                    tooltip: {
                      valueFormatter: function (value) {
                        return value ;
                      }
                    },
                    data: data[1]
                  },
                  {
                    name: '该年合同总金额',
                    type: 'bar',
                    emphasis: {
                      focus: 'series'
                    },
                    tooltip: {
                      valueFormatter: function (value) {
                        return value;
                      }
                    },
                    data: data[2]
                  },
                  {
                    name: '已支付总金额',
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                      focus: 'series'
                    },
                    tooltip: {
                      valueFormatter: function (value) {
                        return value ;
                      }
                    },
                    data: data[3]
                  },
                  {
                    name: '未支付总金额',
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                      focus: 'series'
                    },
                    tooltip: {
                      valueFormatter: function (value) {
                        return value ;
                      }
                    },
                    data: data[4]
                  },

                ]
              };
            }
            this.chartsItem_month = markRaw(echarts.init(this.$refs.chart_item_month))//获取dom元素;
            this.chartsItem_month.setOption(option);
          },
          back(){
            this.sum_flag = true;
            setTimeout(()=>{
              this.eachar_item(this.statCount_data);
            },200)
          },
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.formArg.formColumns = this.table.getFormColumns();
            this.formArg.formData = this.table.getFormData();
            this.init()
        }
    }
</script>

<style scoped>

</style>