/**
 * @description：财务科目已经关联的费用类别
 * @author:rzl
 * @date:2022/7/25 14:30
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class sujectRelated_item extends Table {
    constructor() {
        super();
        this.name = "sujectRelated_item";
        this.CNName = "财务科目已经关联的费用类别";
        this.columnArray = [
            new Column("主键", "id", ColumnType.String, false).setVisible(false),
            new Column("费用类别大类", "type", ColumnType.Enum, true).setTableView(80)
                .setEnum(["1","2","3","4","5","6","7","8","9",],
                    ["项目收款","其他收入","其他支出","外协支付","员工报账","财务科目","合同","销项发票","进项发票","固定资产"],
                    ["Pink","MediumPurple","CornflowerBlue","PowderBlue","Plum","DarkViolet","CadetBlue","#FFC1C1","#CD9B9B",'#CD6889']),
            new Column("费用类别名称", "categoryName", ColumnType.String, true).setTableView(160),


        ];
    }
}