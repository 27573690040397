/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_task  任务管理-
 * 由部门分配给其他部门的子任务
 * @create: 2021-04-16 16:42
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import department from "../api/department";
export default class item_task3 extends Table{
    constructor() {
        super();
        this.name = "item_task3";
        this.CNName = "任务管理";
        this.columnArray = [
            new Column("任务类型","pid",ColumnType.Enum,false)
                .setEnum(["0"],["主任务","子任务"],["green","red"]),
            new Column("任务标题","taskTitle",ColumnType.String,true).setTableView(150),
            new Column("任务内容","content",ColumnType.String,true),
            new Column("分配部门","departmentId",ColumnType.IdReplace,true).setTableView(150)
                .setIdReplaceObject("departmentName",{api:department.queryAll,tag:"queryText"}),
            new Column("预下绩效","money",ColumnType.Number,true).setDefaultValue(0).setTableView(80),
            new Column("拟下绩效","confirmMoney",ColumnType.Number,true).setDefaultValue(0).setTableView(80),
            new Column("工作量占比","ratio",ColumnType.Number,true).setTableView(80)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("任务开始时间","startDate",ColumnType.Date,true),
            new Column("预计完成时间","endDate",ColumnType.Date,true).setTableView(100),
            new Column("实际完成时间","completeDate",ColumnType.Date),
            new Column("进度","completeRatio",ColumnType.Number).setTableView(100)
                .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
            new Column("完成状态","state",ColumnType.Enum)
                .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"]),
            new Column("备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}