<!--
* @program: office_automation
* @author: ly
* @component:TaskLeaderTool
* @description: 项目任务负责人
* @create: 2022-03-05 13:19
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar v-model:value="searchData" :search-options="searchOptions" />
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <div style="margin: 10px;color: black">任务完成状态:</div>
                <a-radio-group @change="radioChange" class="search-item" v-model:value="defaultOptions.state">
                    <a-radio :value="'3'">未完成</a-radio>
                    <a-radio :value="'0'">按时完成</a-radio>
                    <a-radio :value="'1'">提前完成</a-radio>
                    <a-radio :value="'2'">延期完成</a-radio>
                </a-radio-group>
                <a-tooltip title="任务延期申请">
                    <a-button  class="search-item" type="default" @click="visible = !visible">
                        <template #icon><AlertOutlined /></template>
                    </a-button>
                </a-tooltip>
            </div>
            <TwoParts v-if="!visible" ref="TwoParts" @change="handleChange" pos="transform" :trans="[45,35]" >
                <template #left>
                    <TcBaseTable :view-model="true" :page-size="pageSize" :current-index="currentIndex" @pageChange="handlePageChange"
                                 :data-source="dataSource" :table-columns="tableColumns" ref="project_table" :loading="loading">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button  class="action-button" type="link" @click="showDetail(index,record)">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
<!--                                v-if="!record.state"-->
                                <a-tooltip  title="任务工作">
                                    <a-button  class="action-button" type="link" @click="editDetail(index,record)">
                                        <ApartmentOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
<!--                                v-if="!record.state"-->
                                <a-tooltip   title="提交任务完成">
                                    <a-button  class="action-button" type="link" @click="finishTask(index,record)">
                                        <CheckOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip v-if="!record.state"  title="任务延期申请">
                                    <a-button  class="action-button" type="link" @click="delayApply(index,record)">
                                        <FallOutlined  style="color: red" class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip v-if="record.state"  title="任务评分">
                                    <a-button  class="action-button" type="link" @click="workScoreSet(index,record)">
                                        <FormOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <project-tabs ref="local_tabs" />
                </template>
            </TwoParts>
            <TaskWorkDelayManager v-else />
        </div>
    </div>
    <a-modal v-model:visible="visible3"
             destroy-on-close
             title="提交任务完成情况"
             :width="800"
             :footer="null">
        <span>完成时间：</span><DatePicker style="width: 200px" v-model:value="taskDate" />
        <ArBaseForm :view-model="false" :argument="formArg" >
            <template #action="{record}">
                <a-button type="primary" @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
    <a-modal v-model:visible="visible4"
             destroy-on-close
             :footer="null"
             :width="1000"
             title="任务延期申请">
        <delayApply :record="currentRecord" type="1" />
    </a-modal>
</template>

<script>
    import api_item_task from "../../../assets/api/itemTask";
    import {
      ApartmentOutlined,
      ArrowUpOutlined,
      CheckOutlined,
      FallOutlined, FormOutlined,
      SearchOutlined,
      SyncOutlined, AlertOutlined, InfoCircleOutlined
    } from "@ant-design/icons-vue";
    import projectTabs from "../../projectManager/projectViewer/projectTabs";
    import TwoParts from "../../_components/TwoParts";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import delayApply from "../../projectManager/projectDelayManager/delayApply";
    import item_task2 from "../../../assets/tables/item_task2";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import DatePicker from "../../_components/DatePicker";
    import TaskWorkDelayManager from "./TaskWorkDelayManager";
    import {notification} from "ant-design-vue";
    import {h} from "vue";
    export default {
        name: "TaskLeaderTool",
        components:{
            CheckOutlined,SyncOutlined, projectTabs,
            TwoParts,TcBaseTable,
            SearchOutlined,
            SearchBar,ArBaseForm,FallOutlined,ApartmentOutlined,FormOutlined,
            delayApply,DatePicker,AlertOutlined,TaskWorkDelayManager
        },
        data(){
            return{
                dataSource: [],
                tableColumns:[],
                currentIndex:null,
                searchOptions:[
                    {value:"queryText",label:"关键字"}],
                searchData:null,
                authority:[],
                table:new item_task2(),
                loading:false,
                pageSize:15,
                defaultOptions:{
                    state:"3",
                    leaderUser:this.$store.getters.userInfo.id
                },
                visible2:false,//质检发起申请modal
                currentRecord:null,
                visible3:false,
                formArg:{
                    formColumns:[
                        new Column("完成情况说明","describe",ColumnType.TextArea,true),
                    ], //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible4:false,
                taskDate:null,
                visible:false
            }
        },
        methods:{
          openInit(){
            api_item_task.queryAll.requestPOSTUrlParam(this,this.defaultOptions,res=>{
              if(res.data.flag){
                let list = res.data.data;
                if(list.length > 0){
                  let itemNames = "";
                  list.forEach((item,index)=>{
                    let monitorFlag = item.monitorFlag;
                    if(monitorFlag){
                      itemNames += item.itemName +";";
                    }
                  })
                  if(itemNames.length > 0){
                    this.openNotification(itemNames);
                  }
                }
              }
            })
          },
          openNotification(itemNames){
            notification.open({
              message: '以下为监管项目，请及时处理！',
              key:'item_jg',
              placement:'bottomRight',
              duration:'3',
              icon: h(InfoCircleOutlined, {
                style: 'color: red',
              }),
              style: {
                height:'100px',
                marginBottom:'1px',
                overflow:"auto",
                backgroundColor:'#bfbfbf',

              },
              description:itemNames,

            });
          },
            init(){
                this.searchData = null;
                this.loading = true;
                let data = {
                    page:1,
                    limit:this.pageSize,
                };
                api_item_task.queryPage.requestPOSTBothParam(this,{formParam:data,urlParam:this.defaultOptions},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.project_table.setTotalSize(res.data.count);
                        this.$refs.project_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            radioChange(){
                this.loading = true;
                let data = {
                    page:1,
                    limit:this.pageSize
                };
                api_item_task.queryPage.requestPOSTBothParam(this,{formParam:data,urlParam:this.defaultOptions},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.project_table.setTotalSize(res.data.count);
                        this.$refs.project_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            showDetail(index,record){
                this.currentRecord = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"任务详情",component:"projectTaskInfoPart",viewModel:true,projectId:record.itemId},
                    {tag:"任务工序",component:"taskProcessList",viewModel:true,record:record},
                    {tag:"月度工作量总结",component:"MonthArrange",viewModel:true,record:record},
                    {tag:"年度工作量分配",component:"YearMoneyArrangePer",viewModel:true,record:record},
                    // {tag:"质检报告",component:"qualityCheckResult",viewModel:true,projectId:record.itemId},
                    // {tag:"成员生产质量评价",component:"qualityUserScoreView",viewModel:true,projectId:record.itemId},
                    {tag:"用章申请",component:"ProjectSealApply",viewModel:false,projectId:record.itemId},
                    {tag:"质检情况",component:"QCManager",viewModel:false,projectId:record.itemId},
                    {tag:"周报",component:"weeksReport",viewModel:false,record:record},
                ]);
            },
            editDetail(index,record){
                this.currentRecord = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"任务工序",component:"taskProcessList",viewModel:false,record:{...record,taskLeaderFlag:true}},
                    {tag:"月度工作量总结",component:"MonthArrange",viewModel:false,record:record},
                    {tag:"年度工作量分配",component:"YearMoneyArrangePer",viewModel:true,record:record},
                    {tag:"用章申请",component:"ProjectSealApply",viewModel:false,projectId:record.itemId},
                    {tag:"质检情况",component:"QCManager",viewModel:false,projectId:record.itemId},
                    {tag:"周报",component:"weeksReport",viewModel:false,record:record},
                    //taskLeaderFlag 用于标记是否允许指定任务负责人，默认False 允许，true：不允许
                ]);
            },
            handlePageChange(arg){
                this.loading = true;
                api_item_task.queryPage.requestPOSTBothParam(this,{formParam:arg,urlParam:this.defaultOptions},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.project_table.setTotalSize(res.data.count);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                });
            },
            refresh(){
                this.init();
            },
            doSearch(){
                let arg = {};
                Object.assign(arg,this.defaultOptions);
                Object.assign(arg,this.searchData);
                let data = {
                    page:1,
                    limit:this.pageSize
                };
                api_item_task.queryPage.requestPOSTBothParam(this,{formParam:data,urlParam:arg},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.project_table.setTotalSize(res.data.count);
                        this.$refs.project_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                });
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.tableColumns = [
                        new Column("任务类型","pid",ColumnType.Enum,false).setTableView(80)
                            .setEnum(["0"],["主任务","子任务"],["green","red"]),
                        new Column("任务标题","taskTitle",ColumnType.String,false).setTableView(200),
                      new Column("是否监管项目","monitorFlag",ColumnType.Enum,true).setTableView(120)
                          .setEnum([true,false],["是","否"],["red","#79CDCD"]).setDefaultValue(false),
                        new Column("操作", "actions", "actions", false).setTableView(150)
                    ]
                } else {
                    this.tableColumns = this.table.getTableColumns();
                }
            },
            finishTask(index,record){
                this.visible3 = true;
                this.formArg.formData = record;
            },
            saveData(record){
                /** 以当前时间为完成时间
                let time = localStorage.getItem("time");
                record.completeDate = parseInt(time);
                 */
                record.completeDate = new Date(this.taskDate).valueOf();
                api_item_task.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.visible3 = false;
                        this.$message.success(res.data.msg);
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            delayApply(index,record){
                this.currentRecord = record;
                this.visible4 = true;
            },
            workScoreSet(index,record){
                this.currentRecord = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"成员生产质量评价",component:"qualityUserScore",viewModel:false,projectId:record.itemId},
                ]);
            }
        },
        created() {
            this.tableColumns = this.table.getTableColumns();
          this.openInit();
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>

</style>
