<!--
* @program: TYH_office_automation 
* @author: ly
* @component:ICONS 
* @description: 
* @create: 2021-12-07 11:44
-->
<template>
    <div>
        <BarChartOutlined />
    </div>
</template>

<script>
    import {SearchOutlined,SyncOutlined,RollbackOutlined,DownloadOutlined,ArrowUpOutlined,
        DeleteOutlined,CheckOutlined,BarChartOutlined,
        PlusOutlined,EditFilled} from "@ant-design/icons-vue"
    export default {
        name: "ICONS",
        components:{
            SearchOutlined,SyncOutlined,PlusOutlined,EditFilled,CheckOutlined,
            RollbackOutlined,DownloadOutlined,ArrowUpOutlined,DeleteOutlined,BarChartOutlined
        }
    }
</script>

<style scoped>

</style>