/** 
* 自动生成文件，请勿修改 
* @name: 项目:发票接收记录管理模块(进项发票)
* @description: 发票接收记录管理模块
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const invoiceInto = { 
	/** 1 summary:根据id查询	method:post */ 
	queryById : new APIObject("/api/invoiceInto/queryById.do"), 
	/** 2 summary:查询-收票付款情况	method:post */ 
	paySituation : new APIObject("/api/invoiceInto/paySituation.do"), 
	/** 3 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/invoiceInto/queryPage.do"), 
	/** 4 summary:进项发票数量统计：年、月、条数、金额等）	method:post */ 
	statCount : new APIObject("/api/invoiceInto/statCount.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/invoiceInto/delete.do"), 
	/** 6 summary:新增/修改-单条	method:post */ 
	saveOrUpdate : new APIObject("/api/invoiceInto/saveOrUpdate.do"), 
	/** 7 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/invoiceInto/queryAll.do"), 
	/** 8 summary:冲红-单条	method:post */ 
	flushRed : new APIObject("/api/invoiceInto/flushRed.do"), 
	/** 9 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/invoiceInto/batchDelete.do"), 
};
export default invoiceInto;