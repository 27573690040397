<!--
* @program: office_automation 
* @author: ly
* @component:appPermissions 
* @description: 
* @create: 2023-05-05 17:17
-->
<template>
    <div v-if="viewModel" class="align-tag">
        <a-tag v-for="item in sEnums" :key="item">
            <div >{{item}}</div>
        </a-tag>
    </div>
    <div v-else>
        <a-select mode="multiple"
                  v-model:value="value" style="width: 200px"
                  @change="changeHandler">
            <a-select-option v-for="option in sEnums" :value="option" :key="option">{{option}}</a-select-option>
        </a-select>
    </div>
</template>

<script>
    import {MenuOps} from "./MenuOps";

    export default {
        name: "appPermissions",
        props:["permissions","viewModel"],
        emits:["update:permissions"],
        watch:{
            permissions(){
                this.value = this.permissions?this.permissions.split(","):undefined
            }
        },
        computed:{
            sEnums(){
                let value = this.permissions;
                if(value){
                    return value.split(",");
                }
                else{
                    return [];
                }
            }
        },
        data() {
            return {
                defaultEnums:MenuOps,
                value:undefined,
            }
        },
        methods: {
            init() {
                this.value = this.permissions?this.permissions.split(","):""
            },
            changeHandler(){
                if(this.value){
                    this.$emit("update:permissions",this.value.toString())
                }
                else{
                    this.$emit("update:permissions","")
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>