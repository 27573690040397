<!--
* @program: office_automation 
* @author: ly
* @component:SealStepsViewer 
* @description: 
* @create: 2022-06-07 17:10
-->
<template>
    <div v-for="(item,idx) in mPurposes" :key="idx">
        <span style="color: #1890ff">{{item.label}}:</span>
        <a-steps size="small" v-if="item.steps &&item.steps.length>0" type="navigation">
            <a-step status="finish" style="margin: 10px" v-for="(name,index) in item.steps" :key="index" :title="name" >
                <template #icon><user-outlined /></template>
            </a-step>
        </a-steps>
        <span v-else>还未设定</span>
    </div>
</template>

<script>
    import {UserOutlined} from "@ant-design/icons-vue";
    import sealAuditProcess from "../../../assets/api/sealAuditProcess";
    export default {
        name: "SealStepsViewer",
        props:["purposes","user"],
        components:{UserOutlined},
        data(){
            return{
                mPurposes:null
            }
        },
        watch:{
            user(){
                this.init()
            }
        },
        methods:{
            init(){
                this.mPurposes = [...this.purposes];
                let record = this.user[0];
                let data;
                if(Object.prototype.hasOwnProperty.call(record,"userId")){
                    data = {
                        userId:record.userId,
                    };
                }
                else{
                    data = {
                        userId:record.id,
                    };
                }
                sealAuditProcess.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        for(let i in this.mPurposes){
                            let arr = res.data.data.filter(item=>{
                                return  item.purposeId === this.mPurposes[i].value
                            });
                            if(arr.length>0){
                                this.mPurposes[i].steps = arr[0].auditUsersName
                            }
                            else{
                                this.mPurposes[i].steps = []
                            }
                        }
                    }
                    else{
                        for(let i in this.mPurposes){
                            this.mPurposes[i].steps = [];
                        }
                    }
                });
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>