/**
 * @description：合同信息
 * @author:rzl
 * @date:2022/7/25 14:27
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class userMoneys_item extends Table{
    constructor() {
        super();
        this.name = "userMoneys_item";
        this.CNName = "员工绩效总和对象";
        this.columnArray = [
            new Column("系统编号","userId",ColumnType.String).setVisible(false),
            new Column("员工姓名","userName",ColumnType.String).setTableView(120),
            new Column("预下绩效和","beforeMoney",ColumnType.Number).setTableView(120),
            new Column("拟下绩效和","confirmMoney",ColumnType.Number).setTableView(120),
        ];
    }
}