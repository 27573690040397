<!--
* @program: tyh-oa 
* @author: ly
* @component:projectIncome 项目收款
* @description: 
* @create: 2021-04-16 10:55
-->
<template>
    <div class="center-flex">
        <a-button v-if="!viewModel" class="search-item" type="primary" @click="modalShow(true)">
            <template #icon><PlusOutlined /></template>添加收款(有发票)
        </a-button>
        <a-button v-if="!viewModel" class="search-item" type="dashed" @click="modalShow(false)">
            <template #icon><PlusOutlined /></template>添加收款(无发票)
        </a-button>
        <a-button  class="search-item" type="dashed"  @click="refresh">
            <template #icon><SyncOutlined /></template>
        </a-button>
        <span class="statistic-label"> &emsp;&emsp;合计：{{summary}} 元</span>
    </div>
    <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="12" >
        <template #action="{index,record}">
            <div class="action-column">
                <a-button  type="link"  @click="showDetail(index,record)">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button v-if="!viewModel"  type="link"  @click="editDetail(index,record)">
                    <template #icon><EditOutlined /></template>
                </a-button>
                <a-popconfirm
                        v-if="!viewModel"
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </ArBaseTableFilter>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="收款"
             :maskClosable="vModel"
             :footer="null">
        <ArForm ref="ArForm" :view-model="vModel" :argument="formArg" />
        <a-divider v-if="hasInvoice" ><a-tag :color="'red'">发票信息</a-tag></a-divider>
        <invoiceSelector v-if="hasInvoice" :projectId="projectId" :view-model="vModel" v-model:formArg="formArg" />
        <a-divider />
        <a-button v-if="!vModel" class="search-item" type="primary"  @click="saveData">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import {PlusOutlined,DeleteOutlined,SyncOutlined,SearchOutlined,EditOutlined} from "@ant-design/icons-vue";
    import item_collection_info from "../../../assets/tables/item_collection_info";
    import api_item_collection_info from "../../../assets/api/collectionInfo";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import ArForm from "../../../components/form/ArForm";
    import invoiceSelector from "../../financialManager/invoiceManager/invoiceSelector";
    import bankBalance from "../../../assets/api/bankBalance";
    export default {
        name: "projectIncome",
        props:["projectId","viewModel"],
        components:{
            PlusOutlined, ArBaseTableFilter,DeleteOutlined,EditOutlined,
            ArForm,invoiceSelector,SyncOutlined,SearchOutlined
        },
        computed:{
            summary(){
                let sum=0;
                for(let i in this.argument.dataSource){
                    sum += this.argument.dataSource[i].collectionAmount*100;
                }
                return sum/100;
            }
        },
        data(){
            return{
                table:null,
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()

                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                vModel:true,
                hasInvoice:true,
            }
        },
        methods:{
            init(){
                this.argument.tableColumns = this.table.getTableColumns();
                this.formArg.formColumns = this.table.getFormColumns().filter(item=>item.group === 0);
                let data = {itemId:this.projectId};
                this.argument.loading = true;
                api_item_collection_info.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                })

            },
            refresh(){
                this.init()
            },
            modalShow(bool){
                this.hasInvoice = bool;
                this.vModel = false;
                this.visible = true;
                this.formArg.formData = this.table.getFormData();
                if(bool){
                    this.formArg.formColumns = this.table.getFormColumns().filter(item=>item.group === 0);
                }
                else{
                    this.formArg.formColumns = this.table.getFormColumns();
                }
            },
            showDetail(index,record){
                this.hasInvoice = !!record.invoiceId;
                this.visible = true;

                this.vModel = true;
                this.formArg.formData = record;
                this.formArg.formColumns = this.table.getFormColumns();
            },
            editDetail(index,record){
                this.hasInvoice = true;
                this.visible = true;

                this.vModel = false;
                this.formArg.formData = record;
                this.formArg.formColumns = this.table.getFormColumns();
            },
            deleteData(index,record){
                let data = {id:record.id};
                api_item_collection_info.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(){
                let record = this.formArg.formData;
                if(record.id){
                    api_item_collection_info.update.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            this.init();
                            this.visible = false;
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else{
                    record.itemId = this.projectId;
                    api_item_collection_info.save.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            this.init();
                            this.visible = false;
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }

            },
            deleteCheck(record){
                let time = parseInt(localStorage.getItem("time"));
                return new Date(record.createTime).toLocaleDateString() === new Date(time).toLocaleDateString();
            },
        },
        created() {
            this.table = new item_collection_info();
            this.init();
            bankBalance.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    let cards = [];
                    for(let i in res.data.data){
                        cards.push({
                            id:res.data.data[i].id,
                            name:res.data.data[i].bankName +"【"+ res.data.data[i].bankNum + "】"
                        })
                    }
                    this.table.getFormColumns().filter(item=>item.dataIndex === "bankId")[0]
                        .setSelectorObject("bankNum",cards);
                }
                else{
                    this.$message.error(res.data.msg);
                }
            });
        }

    }
</script>

<style scoped>

</style>