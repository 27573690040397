/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_outsourc_info   外协管理
 * @create: 2021-04-16 17:01
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_outsourc_info extends Table{
    constructor() {
        super();
        this.name = "item_outsourc_info";
        this.CNName = "项目支付";
        this.columnArray = [
            new Column("外协类别","type",ColumnType.String,true)
                .setEnum(["1","2"],["单位","个人"],["blue","purple"]).setTableView(100)
                .setTriggerOtherColumn("1",
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="unitAddr"})[0].setVisible(true);
                        cols.filter(col=>{return col.dataIndex==="unitTaxNo"})[0].setVisible(true);
                        cols.filter(col=>{return col.dataIndex==="unitPhone"})[0].setVisible(true);
                        cols.filter(col=>{return col.dataIndex==="unitContactUser"})[0].setVisible(true);
                        cols.filter(col=>{return col.dataIndex==="idCard"})[0].setVisible(false);
                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="unitAddr"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="unitTaxNo"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="unitPhone"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="unitContactUser"})[0].setVisible(false);
                        cols.filter(col=>{return col.dataIndex==="idCard"})[0].setVisible(true);
                    }),
            new Column("姓名/单位名称","name",ColumnType.String,true).setTableView(200),
            new Column("开户银行","openBank",ColumnType.String,true),
            new Column("银行账号","bankNo",ColumnType.String,true),
            new Column("联系电话","contactPhone",ColumnType.String,true).setTableView(100),
            new Column("邮箱","email",ColumnType.String,true),
            new Column("身份证号","idCard",ColumnType.String,true),
            new Column("单位地址","unitAddr",ColumnType.String,true),
            new Column("单位税号","unitTaxNo",ColumnType.String,true),
            new Column("单位联系人","unitContactUser",ColumnType.String,true),
            new Column("单位电话","unitPhone",ColumnType.String,true),
            new Column("说明","remarks",ColumnType.String,true),
            new Column("录入人","createUserName",ColumnType.String),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}