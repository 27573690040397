<!--
* @program: tyh-oa 
* @author: ly
* @description: oaHeader  菜单初始化
* @create: 2021-03-19 16:50
-->
<template>
    <div class="header">
        <img  src="../assets/image/logo2.png" class="non-select"  width="64" height="64">
        <h1 v-if="!menuVisible" class="title non-select">{{$sysName}}</h1>
        <a-menu
                v-if="menuVisible"
                theme="dark"
                mode="horizontal"
                class="top-menu"
                @select="handleClick"
                v-model:selectedKeys="selectedKeys"
        >
            <sub-menu v-for="menus in menuLists" :menus="menus" :key="menus.id" />
        </a-menu>
        <div  class="user" @click="showMessage2">
            <a-badge :count="unReadMessage" class="pointer" >
                <a-avatar :size="50" :src="headUrl" />
            </a-badge>
        </div>
        <a-tooltip v-if="menuVisible"  title="站内信">
            <a-button type="link" class="user-book"  @click="showMessage">
                <template #icon><MailOutlined /></template>
            </a-button>
        </a-tooltip>
        <a-button v-if="menuVisible" type="link" class="logout-btn"  @click="logout">
            <template #icon><LogoutOutlined /></template>
        </a-button>
    </div>
    <MessageReader ref="MessageReader" v-model:value="unReadMessage" />
</template>

<script>
    import {LogoutOutlined,ReadOutlined,MailOutlined} from "@ant-design/icons-vue";
    import subMenu from "../components/subMenu";
    import {menuGenerate,menuHasOwnKey} from "/src/assets/system/menu";
    import {BaseMenu} from "/src/assets/system/menu";
    import api_role_menu from "../assets/api/roleMenuRelation";
    import Column from "../assets/tables/parts/column";
    import ColumnType from "../assets/tables/parts/column_type";
    import api_item_finance_record from "../assets/api/record";
    import {GetCurrentFileUrl} from "/src/assets/utils/general";
    import _default from "../assets/api/_default";
    import messageUser from "../assets/api/messageUser";
    import MessageReader from "./MessageReader";
    export default {
        name: "oaHeader",
        props:["userName","remainTime"],
        components:{
            LogoutOutlined,ReadOutlined, subMenu,MailOutlined,MessageReader
        },
        computed:{
            menuLists(){
                return this.menuObjects.children.filter(menu=>{
                    return menu.enable === true;
                })
            }
        },
        data(){
            return{
                menuObjects:new BaseMenu(),
                userInfo:{},
                column:new Column("照片","headUrl",ColumnType.Avatar,true),
                selectedKeys:[], //
                selectedKey:null,  //审核菜单的key
                headUrl:null,
                rzr:false, //入账人
                unReadMessage:0
            }
        },
        inject:["menuVisible"],
        methods:{
            handleClick(menu){
                let key = menu.key;
                let callback = object=>{
                    this.$emit("event",this.$GetEmitData("menuClick",object))
                };
                this.menuObjects.enableCurrentMenu(key,callback);
            },
            logout(){
                this.$emit("event",this.$GetEmitData("logout"));
            },
            showMessage(){//自动定位到待审核菜单
                // if(this.works>0){
                //     this.selectedKeys = [this.selectedKey];
                //     let callback = (object)=>{
                //         this.$emit("event",this.$GetEmitData("workCheck","5870f9a2-c799-4d37-945c-9d2958b5c921"));
                //         this.$emit("event",this.$GetEmitData("menuClick",object));
                //     };
                //     this.menuObjects.enableCurrentMenu(this.selectedKey,callback);
                // }
                this.$refs.MessageReader.open();
            },
            showMessage2(){//自动定位到待审核菜单
                if(this.unReadMessage>0){
                    this.$refs.MessageReader.open();
                }
            },
            locateUserBook(){
                window.open("../tyhoa_wiki/index.html");
            },
            login(){
                let phone = localStorage.getItem("phone");
                let password = localStorage.getItem("password");
                let data = {
                    phone:phone,
                    password:password
                };
                _default.login.requestPOSTUrlParam(this,data,res=>{
                    this.spinning=false;
                    if(res.data.flag){
                        this.userInfo = res.data.data;
                        if(this.rzr){
                            this.userInfo.rzr = true;
                        }
                        this.$store.commit("initUserInfo",this.userInfo);
                        let password = localStorage.getItem("password");
                        if(password === "000000" || password === "123456"){
                            this.$router.push({ path: '/resetPassword' });
                        }
                    }
                    else{
                        this.$router.push({ path: '/' });
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        created() {
            api_role_menu.userMenuOpTree.requestPOST(this,{},res=>{
                if(res.data.flag){
                    menuGenerate(this.menuObjects,res.data.data);
                    let menu = new BaseMenu();
                    menuGenerate(menu,res.data.data);
                    for(let i in res.data.data){
                        if(res.data.data[i].treeName === "账单管理"){//必须与待审核账单的大类名称保持一致，不一致的情况慧导致无法定位菜单
                            this.selectedKey = res.data.data[i].treeId
                        }
                    }
                    /**
                     * 添加入账人权限，用于添加账单是否拥有添加私人入账的按钮
                     * 813fb966-3ced-4715-9190-754317c230e2  账单一览/私人入账 id
                     * @type {*[]}
                     */
                    let arr = [];
                    menuHasOwnKey(menu.children,"813fb966-3ced-4715-9190-754317c230e2",arr);
                    if(arr.length>0){
                        this.rzr = true;
                    }
                    this.userInfo = this.$store.getters.userInfo;
                    if(this.userInfo){
                        this.headUrl = GetCurrentFileUrl(this.userInfo.headUrl);
                        if(this.rzr){
                            this.userInfo.rzr = true;
                        }
                        this.$store.commit("initUserInfo",this.userInfo);
                    }
                    else{
                        this.login();
                    }
                    /**——————————————————————————*/
                }
                else{
                    this.$router.push({ path: '/' });
                }
            });
        }
    }
</script>

<style scoped>
    .title{
        font-size: 40px;
        float: right;
        color: white;
        margin: 10px;
        font-weight: 900;
        font-family:"楷体","楷体_GB2312",serif;
    }
    .logout-btn{
        position: absolute;
        right: 0;
        margin: 10px;
        color: #ffa4a4;
        font-size: 24px;
    }
    .user{
        color: white;
        position: absolute;
        right: 100px;
    }
    .timer{
        color: white;
        position: absolute;
        right: 180px;
        font-weight: 900;
    }
    .user-book{
        position: absolute;
        right: 40px;
        margin: 10px;
        color: #57c3ff;
        font-size: 24px;
    }
</style>