<!--
* @program: office_automation 
* @author: ly
* @component:HygieneRecordsFromMonth 
* @description: 
* @create: 2022-01-17 09:41
-->
<template>
    <div style="height: 100%">
        <div class="search-bar">
            <MonthSelector v-model:value="yearMonth" @change="monthChange"/>
        </div>
        <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="15" >
<!--            <template #action="{record}">-->
<!--                <div class="action-column">-->
<!--                    <a-button @click="showDetail(record)" class="action-button" type="link">-->
<!--                        <SearchOutlined class="pointer"/>-->
<!--                    </a-button>-->
<!--                </div>-->
<!--            </template>-->
        </ArBaseTableFilter>
    </div>
</template>

<script>
    import MonthSelector from "../../../../components/SearchBar/MonthSelector";
    import ArBaseTableFilter from "../../../../components/table/ArBaseTableFilter";
    import {SearchOutlined} from "@ant-design/icons-vue";
    import hygiene from "../../../../assets/api/hygiene";
    import moment from "moment";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";

    export default {
        name: "HygieneRecordsFromMonth",
        components:{
            MonthSelector,ArBaseTableFilter,SearchOutlined
        },
        data(){
            return{
                yearMonth:null,
                argument: {
                    dataSource: null,//提供table的DataSource
                    tableColumns: [
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("年月","yearMonth",ColumnType.String).setTableView(100),
                        new Column("用户名","userName",ColumnType.String).setTableView(100),
                        new Column("得分","score",ColumnType.String).setTableView(100),
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading: false,//table的loading状态
                },
                visible:false,
                currentRecord:null,
                formColumns:[],
            }
        },
        methods:{
            init(){
                let data = {
                    userId:"",
                    yearMonth:this.yearMonth
                };
                hygiene.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            monthChange(){
                this.init()
            },
            showDetail(record){
                this.currentRecord = record;
                this.visible = true;
            }
        },
        created() {
            this.yearMonth = moment(new Date().valueOf()).format("YYYY-MM");
            this.init();
        }
    }
</script>

<style scoped>

</style>