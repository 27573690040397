<!--
* @program: office_automation 
* @author: ly
* @component:AssessmentRecordHistory 
* @description: 
* @create: 2022-03-01 16:56
-->
<template>
    <TagShow v-if="source.length>0" color="red" tag="评分记录" >
        <template #content>
            <TcBaseTable :view-model="true" :loading="loading" :page-size="15"
                         :data-source="source" :table-columns="tableColumns" />
        </template>
    </TagShow>
    <h3 v-else>还未进行评分</h3>
</template>

<script>
    import userScore from "../../../../assets/api/userScore";
    import TcBaseTable from "../../../../components/table/TcBaseTable";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import TagShow from "../../../_components/TagShow";
    export default {
        name: "AssessmentRecordHistory",
        props:["record","showAll"],
        components:{
            TcBaseTable,TagShow
        },
        computed:{
            source(){
                if(this.showAll){
                    return this.dataSource
                }
                else{
                    let source = this.dataSource.filter(item=>{return item.createUser === this.$store.getters.userInfo.id});
                    if(source.length === 1){
                        this.$emit("init",source[0]);
                    }
                    return source
                }
            }
        },
        data(){
            return{
                dataSource:[],
                tableColumns:[
                    new Column("评分人","judgeUserName",ColumnType.String,false).setTableView(80),
                    new Column("评分","obtainScore",ColumnType.String,false).setTableView(80),
                    new Column("评语","remarks",ColumnType.String,false).setTableView(150),
                ],
                loading:false,
            }
        },
        methods:{
            init(){
                let data = {
                    "assessmentId": this.record.id
                   // "judgeUser": "string"
                };
                this.loading = true;
                userScore.queryAll.requestPOST(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else {
                        this.dataSource = [];
                    }

                })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>