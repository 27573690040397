<!--
* @program: office_automation 
* @author: ly
* @component:DataProducts 
* @description: 数据成果管理
* @create: 2022-05-30 11:50
-->
<template>
  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <achieve-record-tool-bar ref="achieveRecordToolBar" @event="eventHandle"/>
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange" :scroll="{ x: 1500,y:650}">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-button
                class="action-button" type="link"
                @click="uploadZdhx(index,record)"
            >
              <template #icon><zdhx title="上传红线"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)"
            >
              <a-button type="link">
                <template #icon><delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>

          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>


  </div>

  <!-- 数据成果编辑 -->
  <data-products-edit ref="dataProductsEdit" @event="eventHandle"/>
  <!-- 数据成果查看 -->
  <data-products-show ref="dataProductsShow" />
  <!-- 上传红线 -->
  <sc_hx ref="scZdhx" @event="eventHandle"/>
</template>

<script>
import Icons from "../../common/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import achieveRecordToolBar from "./components/achieveRecordToolBar";
import achieveRecord_item from "./config/achieveRecord_item";
import achieveRecord_api from "./config/achieveRecord_api";
import dataProductsEdit from "./point/dataProductsEdit";
import dataProductsShow from "./point/dataProductsShow";
import sc_hx from "./point/sc_hx";
import {dataToExcel} from "../../../assets/utils/excelUtil"

export default {
  name: "DataProducts",
  mixins:[Icons],
  components:{
    ArBaseTable,achieveRecordToolBar,dataProductsEdit,dataProductsShow,sc_hx
  },
  data() {
      return {
        argument:{
          dataSource:[],//提供table的DataSource
          tableColumns:new achieveRecord_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
          loading:false,//table的loading状态
          currentIndex:null,
          formColumns:new achieveRecord_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
          formData:{},
        },
        pageParam:{
          page: 1,
          limit: 10,
        },
      }
  },
  created() {
    this.init();
  },
  methods: {
    /** 初始化 **/
    init(){
      this.argument.loading = true;
      let parms = {};
      this.pageParam.page = 1;
      this.pageParam.limit = 10;
      achieveRecord_api.queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          let listInfo = res.data.data;
          if(listInfo.length > 0){
            for(let item of listInfo){
              item.areaName = (item.provName !=null ? item.provName : '')
                  + (item.cityName != null ? item.cityName : '')
                  + (item.countyName != null ? item.countyName : '')
                  + (item.streetName != null ? item.streetName : '')
                  + (item.villageName != null ? item.villageName : '') ;
            }
          }

          this.argument.dataSource =  listInfo;
          this.$refs.pstable.setTotalSize(res.data.count);
        }
      });
    },
    /** 回调方法  **/
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{

       /*   var data = [
            {age:'24',name:'阿三',sex:"男"},
            {age:'16',name:'阿梅',sex:"女"}
          ]
          var tableHeader = [
            [ '序号','姓名2','性别','年龄'],
          ]
          var dataList = []
          data.forEach((item,index) => {
            dataList.push([ index+1, item.name,item.sex,item.age]);
          })
          dataToExcel('人员', tableHeader, dataList)*/

          this.argument.loading = true;
          let parms = this.$refs.achieveRecordToolBar.getParms();
          this.pageParam.page = 1;
          this.pageParam.limit = 10;
          this.$refs.pstable.setCurrentPage(1);
          achieveRecord_api.queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              let listInfo = res.data.data;
              if(listInfo.length > 0){
                for(let item of listInfo){
                  item.areaName = (item.provName !=null ? item.provName : '')
                      + (item.cityName != null ? item.cityName : '')
                      + (item.countyName != null ? item.countyName : '')
                      + (item.streetName != null ? item.streetName : '')
                      + (item.villageName != null ? item.villageName : '') ;
                }
              }

              this.argument.dataSource =  listInfo;
              this.$refs.pstable.setTotalSize(res.data.count);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          this.$refs.pstable.setCurrentPage(1);
          this.init();
          break;
        }
        case this.$eventTags.add:{
          this.argument.formData = {};
          this.argument.formData.pid = this.pid;
          this.$refs.dataProductsEdit.open("新增信息","add");
          break;
        }
      }
    },
    /** 分页查询 **/
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.achieveRecordToolBar.getParms();
      this.argument.loading = true;
      achieveRecord_api.queryPage.requestPOSTBothParam(this,{urlParam:this.pageParam,formParam:parms},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          let listInfo = res.data.data;
          if(listInfo.length > 0){
            for(let item of listInfo){
              item.areaName = (item.provName !=null ? item.provName : '')
                  + (item.cityName != null ? item.cityName : '')
                  + (item.countyName != null ? item.countyName : '')
                  + (item.streetName != null ? item.streetName : '')
                  + (item.villageName != null ? item.villageName : '') ;
            }
          }

          this.argument.dataSource =  listInfo;
          this.$refs.pstable.setTotalSize(res.data.count);
        }
      });
    },
    /** 编辑信息 **/
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.dataProductsEdit.open("编辑信息","edit",arg);
    },
    /** 上传红线 **/
    uploadZdhx(index,arg){
      this.$refs.scZdhx.openFile(arg.id,arg.geomList);
    },
    /** 删除信息 **/
    deleteData(index,arg){
      let data = {id:arg.id};
      achieveRecord_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    /** 查看信息 **/
    showData(index,arg){
      this.$refs.dataProductsShow.open("查看信息",arg);
    },
  },

}
</script>

<style scoped>

</style>