<!--
* @program: HXQTDZSRemake 
* @author: ly
* @component:SearchBar
* @description: 
* @create: 2021-11-19 16:02
searchOptions:[
{value:"name",label:"姓名"}
]
value:{key:value}
-->
<template>
    <div>
        <a-select v-model:value="type" @select="handleSelect"
                  style="width: 120px" class="search-item"
                  :options="searchOptions" />

        <a-input v-if="visible" class="search-item" @change="emitTrigger"
                 v-model:value="keyValue" style="width:200px" placeholder="请输入关键字" />
        <a-select v-else v-model:value="keyValue"
                  style="width:200px" class="search-item"
                  :options="enumOptions"
                  @select="emitTrigger"
        />
    </div>
</template>

<script>
    import {SearchSegment} from "../../assets/tables/parts/tableUtils";
    import {companyIds, companyNames,companyColors} from "../../assets/system/companies";

    export default {
        name: "SearchBar",
        emits: ['update:value',"select"],
        props:{
            searchOptions:{required:true},//选项
            value:{required:true}//当前选中的键值对
        },
        watch:{
            value(){
                this.init()
            }
        },
        data(){
          return{
            type:null,
            keyValue:null,
            currentOption:null,
            visible:true,
            enumOptions:[],
          }
        },
        methods:{
            init(){
                if(this.value){
                    this.type = Object.keys(this.value)[0];
                    this.keyValue = this.value[this.type];
                }
                else{
                    this.keyValue = null;
                }
            },
            handleSelect(value,option){
                this.keyValue = null;
                this.visible = true;
                this.currentOption = option;
                this.enumOptions = [];
                if(this.currentOption.enums){
                    this.visible = false;
                    for(let i in this.currentOption.enums){
                        this.enumOptions.push(new SearchSegment(this.currentOption.enumTags[i],this.currentOption.enums[i]));
                    }
                    this.keyValue = this.currentOption.enums[0];
                }
                this.emitTrigger();
            },
            emitTrigger(){
                let data = {};
                data[this.type] = this.keyValue;
                this.$emit("update:value",data);
                this.$emit("select",this.type);
            },
            setvalue(){
              this.type = this.searchOptions[0].value;
              this.keyValue = null;
              this.visible = true;
              this.emitTrigger();
            },
        },
        created() {
            this.init();
            this.visible = true;
            if(this.searchOptions.length>0){
                this.type = this.searchOptions[0].value;
                this.currentOption = this.searchOptions[0];
                if(this.currentOption.enums){
                    this.visible = false;
                    for(let i in this.currentOption.enums){
                        this.enumOptions.push(new SearchSegment(this.currentOption.enumTags[i],this.currentOption.enums[i]));
                    }
                    this.keyValue = this.currentOption.enums[0];
                }
                this.emitTrigger();
            }
        }
    }
</script>

<style scoped>

</style>