/** 
* 自动生成文件，请勿修改 
* @name: 系统:短信发送配置管理
* @description: 短信发送动态配置管理(三期新增)
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:4条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const smsConf = { 
	/** 1 summary:所有数据查询	method:post */ 
	queryAll : new APIObject("/api/smsConf/queryAll.do"), 
	/** 2 summary:开启/关闭-是否发送该类短信	method:post */ 
	enDisable : new APIObject("/api/smsConf/enDisable.do"), 
	/** 3 summary:分页查询	method:post */ 
	queryPage : new APIObject("/api/smsConf/queryPage.do"), 
	/** 4 summary:设置接收短信人号码	method:post */ 
	setPhone : new APIObject("/api/smsConf/setPhone.do"), 
};
export default smsConf;