<!--
* @program: tyh-oa 
* @author: ly
* @component:resetDefaultPassword 
* @description: 
* @create: 2021-06-15 10:53
-->
<template>
    <div style="margin: 100px 50px;font-weight: 900;font-size: 36px;color:darkblue">
        初次使用请查看系统使用说明
        <a-button type="link" style="font-size: 36px;" shape="circle" @click="locateUserBook">
            <template #icon><ReadOutlined /></template>
        </a-button>
    </div>
    <a-card class="user-card">
        <a-form style="margin: 10px" layout="horizontal"
                :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item  label="原密码" >
                <a-input  style="width:100%" v-model:value="originPW" @input="checkPassword" />
            </a-form-item>
            <a-form-item  label="新密码" >
                <a-input-password  style="width:100%" v-model:value="newPW" @input="checkPassword" />
            </a-form-item>
            <a-form-item  label="确认新密码" >
                <a-input-password  style="width:100%" v-model:value="newPW2" @input="checkPassword" />
            </a-form-item>
            <a-form-item  label="提示" >
                <a-alert :message="tips" :type="tipType" />
            </a-form-item>
        </a-form>
        <a-button  class="search-item" type="primary"  @click="saveData">
            提交
        </a-button>
    </a-card>
</template>

<script>
    import userPasswordModify from "./personalCenter/userCenter/userPasswordModify";
    import {ReadOutlined} from "@ant-design/icons-vue";
    export default {
        name: "resetDefaultPassword",
        mixins:[userPasswordModify],
        components:{
            ReadOutlined
        },
        data(){
            return{
                tips:"禁止使用默认密码登录，请重设密码"
            }
        },
        methods:{
            locateUserBook(){
                window.open("./tyhoa_wiki/index.html");
            }
        }

    }
</script>

<style scoped>

</style>