<!--
* @program: tyh-oa 
* @author: ly
* @component:billStatisticsToolBar
* @description: 
* @create: 2021-06-11 14:03
-->
<template>
    <div  class="search-bar">
        <div style="margin: 8px;font-weight: 900;font-size: 16px"> 统计方式：</div>
        <a-select v-model:value="type" style="width: 200px" class="search-item" @select="selectChange">
            <a-select-option v-for="{seg,label} in options" :key="seg" >
                {{label }}
            </a-select-option>
        </a-select>
        <div v-is="currentComp" ref="i_selector"/>
        <a-button class="search-item" style="margin-left: 20px" type="dashed" shape="circle" @click="doSearch">
            <template #icon><SearchOutlined /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
            <template #icon><SyncOutlined /></template>
        </a-button>
        <a-radio-group class="search-item" v-model:value="deleteZero" @change="change">
            <a-radio-button :value="true">删除空数据项</a-radio-button>
            <a-radio-button :value="false">保留空数据项</a-radio-button>
        </a-radio-group>
        <a-button  class="search-item" type="link" @click="tipsShow" >
            <template #icon><InfoCircleOutlined /></template>
        </a-button>
    </div>
</template>

<script>
    import statisticsBar from "../../../components/toolBar/statisticsBar";
    import {InfoCircleOutlined} from "@ant-design/icons-vue"
    export default {
        name: "billStatisticsToolBar",
        mixins:[statisticsBar],
        components:{
            InfoCircleOutlined
        },
        data(){
            return{
                options:[
                    {seg:"month",label:"按月统计",comp:"monthSelector"},
                    {seg:"year",label:"按年统计",comp:"yearSelector"},
                    {seg:"period",label:"按时间段统计",comp:"periodSelector"}
                ], //[{seg:"title",label:"账单标题",type:"input"}]
                currentComp:"monthSelector",
                type:"month",

            }
        },
        methods:{
            tipsShow(){
                this.$message.info("金额统计只统计已入账的账单，未入账的账单不进行统计")
            }
        }
    }
</script>

<style scoped>

</style>