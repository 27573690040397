<!--
* @program: office_automation 
* @author: ly
* @component:UploadFile 
* @description: 
* @create: 2022-05-27 09:49
-->
<template>
    <div v-if="viewModel" style="display: flex;text-align: center;justify-content: center;">
        <a-button v-for="url in imageUrls" :key="url" type="link" shape="circle" @click="showDetail(url)">
            <template #icon>
                <FileImageTwoTone v-if="preViewType(url) ===2"  />
                <FilePdfOutlined v-else-if="preViewType(url) ===1" style="color:green"/>
                <PlayCircleOutlined  v-else-if="preViewType(url) ===3" style="color:blue"/>
                <FileZipOutlined  v-else-if="preViewType(url) ===4" style="color:purple"/>
                <QuestionCircleOutlined v-else style="color:red"/>
            </template>
        </a-button>
    </div>
    <div v-else>
        <a-upload
                :action="actionUrl"
                list-type="picture-card"
                v-model:file-list="fileList"
                @preview="handlePreview"
                :accept="accept"
                @change="handleChange"
                :remove="handleRemove"
                withCredentials
        >
            <plus-outlined />
            <div class="ant-upload-text">{{title}}</div>
        </a-upload>
    </div>
    <a-modal :visible="previewVisible"
             :footer="null"
             destroy-on-close
             @cancel="handleCancel">
        <a-image v-if="preView===2" :width="400" :src="previewImage"/>
        <embed v-else-if="preView ===1" style="width: 100%;height: 700px;text-align: center" :src="previewImage" />
        <embed v-else-if="preView ===3" style="width: 100%;height: 700px;text-align: center" :src="previewImage" />
        <div v-else>当前文件格式不支持在线浏览</div>
    </a-modal>
</template>

<script>
    import {FileImageTwoTone, FilePdfOutlined, PlusOutlined, QuestionCircleOutlined,PlayCircleOutlined,FileZipOutlined} from "@ant-design/icons-vue";
    import api_file from "../../assets/api/file";
    import {GetCurrentFileUrl, uuid} from "../../assets/utils/general";

    export default {
        name: "UploadFile",
        components:{PlusOutlined,FileImageTwoTone,FilePdfOutlined,QuestionCircleOutlined,PlayCircleOutlined,FileZipOutlined},
        props:["value","viewModel","title","mime"],
        emits:["update:value"],
        watch:{
            record(){
                this.init();
            }
        },
        computed:{
            imageUrls(){
                if(this.fileUrl){
                    return this.fileUrl.split(",");
                }
                else {
                    return []
                }
            },
            accept(){
                return this.mime?this.mime:"*";
            }
        },
        data(){
            return{
                fileList:[],
                previewVisible:false,
                previewImage:null,
                actionUrl:api_file.upload.url,
                preView:false, //预览的类型   1 为pdf  2为 图片文件  3为zip
                fileUrl:null,
            }
        },
        methods:{
            handlePreview({response}){
                this.previewImage = GetCurrentFileUrl(response.data.fileUrl);
                this.previewVisible = true;
            },
            handleCancel(){
                this.previewVisible=false;
            },
            handleChange({file,fileList,event}){
                if(file.status==="done"){//status: "done"
                    this.fileUrl = this.getRealUrls(fileList);
                    this.$emit("update:value",this.fileUrl);
                }
            },
            showDetail(url){
                this.previewImage = GetCurrentFileUrl(url);
                this.previewVisible = true;
                this.preView = this.preViewType(url);
            },
            preViewType(url){
                let suffix = url.split(".").pop();
                switch (suffix) {
                    case "pdf":{return 1;}
                    case "jpg":{return 2;}
                    case "png":{return 2;}
                    case "gif":{return 2;}
                    case "mp4":{return 3;}
                    case "zip":{return 4;}
                    default:{return 0;}
                }
            },
            async handleRemove(file){
                let data = "";
                if(Object.prototype.hasOwnProperty.call(file,"response")){
                    data = {batchFileUrl:file.response.data.fileUrl};
                }
                else{
                    data = {batchFileUrl:file.path};
                }
                await api_file.batchDeleteFile.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        //let index = this.fileList.indexOf(file);
                        //this.fileList.splice(index,1);
                        //let urls = this.getRealUrls(this.fileList);
                        this.fileUrl = this.getRealUrls(this.fileList);
                        this.$emit("update:value",this.fileUrl);
                        return true
                    }
                    else{
                        this.$message.error(res.data.msg);
                        return false
                    }
                });
            },
            handleClear(){
                this.fileList = [];
            },
            getRealUrls(fileList){
                let urls ="";
                for(let i in fileList){
                    if(fileList[i].response && fileList[i].response.flag){
                        let url = fileList[i].response.data.fileUrl;
                        urls += url + ",";
                    }
                    else{
                        let url = fileList[i].path;
                        urls += url + ",";
                    }
                }
                urls = urls.substr(0,urls.length-1);
                return urls;
            },
            init(){
                this.fileUrl = this.value;
                this.fileList = [];
                if(this.fileUrl){
                    let urls = this.fileUrl.split(",");
                    for(let i in urls){
                        let id = uuid(3,16);
                        let img = {
                            uid: id,
                            name: id,
                            status: 'done',
                            url: GetCurrentFileUrl(urls[i]),
                            thumbUrl:GetCurrentFileUrl(urls[i]),
                            path:urls[i]
                        };
                        this.fileList.push(img);
                    }
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
    .ant-upload-list-picture-card-container{
        float: left;
        width: 80px;
        height: 80px;
        margin: 0 8px 8px 0;
    }
</style>