<!--
* @program: TYH_office_automation 
* @author: ly
* @component:personalBillViewer 
* @description: 账单详情综合版  包括审核步骤 账单概况 账单流水
* @create: 2021-12-07 09:40
-->
<template>
    <div>
        <a-radio-group style="position: absolute;left: 0;margin:5px" v-model:value="visible">
            <a-radio-button :value="true">账单概况</a-radio-button>
            <a-radio-button :value="false">账单流水</a-radio-button>
        </a-radio-group>
        <personal-bill-statement v-if="!visible" :view-model="viewModel" :record="mRecord" :flowIds="flowIds"/>
        <a-divider style="margin: 0" v-if="!visible">审核进度</a-divider>
        <personal-bill-steps v-if="!visible" :record="mRecord" v-model:flowIds="flowIds"/>
        <div v-if="visible" style="width: 800px;margin: 100px">
            <ArBaseForm :view-model="viewModel" :argument="formArg" >
                <template #action="{record}">
                    <a-button  type="primary" @click="saveData(record)">
                        提交
                    </a-button>
                </template>
            </ArBaseForm>
        </div>
    </div>
</template>

<script>
    import personalBillStatement from "./personalBillStatement";
    import personalBillSteps from "./personalBillSteps";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import item_finance_record from "../../../assets/tables/item_finance_record_1";
    import api_item_finance_record from "../../../assets/api/record";
    export default {
        name: "personalBillViewer",
        props:["viewModel","record"],
        components:{
            personalBillStatement,personalBillSteps,ArBaseForm
        },
        data(){
            return{
                table: new item_finance_record(),
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                mRecord:null,
                flowIds:null,
            }
        },
        methods:{
            saveData(record){
                api_item_finance_record.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.mRecord = record;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        created() {
            this.formArg.formColumns = this.table.getFormColumns();
            this.formArg.formData = {...this.record};
            this.mRecord = {...this.record};
        }
    }
</script>

<style scoped>

</style>