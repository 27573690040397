<!--
* @program: tyh-oa 
* @author: ly
* @component:billViewer
* @description: 报账审核-待审核页面
* @create: 2021-04-07 14:31
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <TwoParts ref="TwoParts" @change="handleChange" pos="transform">
                <template #left>
                    <bill-table-view :loading="loading" :data-source="dataSource" :table-columns="columns" @event="eventHandle"/>
                </template>
                <template #right>
                    <billTabs ref="local_tabs" @event="eventHandle"/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import billTabs from "../billComponents/billTabs";
    import billTableView from "./billTableView";
    import baseProvide from "../../baseProvide";
    import item_finance_record from "../../../assets/tables/item_finance_record_2";
    import api_item_finance_record from "../../../assets/api/record";
    import api_item_finance_record_approval from "../../../assets/api/recordApproval";
    import api_item_finance_record_flow from "../../../assets/api/recordFlow";
    import TwoParts from "../../_components/TwoParts";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";

    export default {
        name: "billViewer",
        mixins:[baseProvide],
        components:{
            billTabs,billTableView,TwoParts
        },
        data(){
            return{
                table:new item_finance_record(),
                userId:this.$store.getters.userInfo.id,
                columns:[],
                loading:false
            }
        },
        methods:{
            init(){
                this.columns= this.table.getTableColumns();
                let data = {userId:this.userId};
                this.loading = true;
                api_item_finance_record.queryMyApproval.requestPOSTUrlParam(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            eventHandle({type,arg}){
                switch (type) {
                    case "admit":{ //通过
                        /*
                        recordId	报账单id
                        userId	审批人id
                        state	 审批状态(2:通过,3:不通过)
                        remarks  拒绝原因
                         */
                        let data ={
                            recordId:arg.id,
                            userId:this.userId,
                            state:"2",
                        };
                        api_item_finance_record_approval.updApprovalFlow.requestPOSTUrlParam(this,data,res=>{
                            if(res.data.flag){
                                this.$message.success(res.data.msg);
                                this.init()
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                        break;
                    }
                    case "reject":{//拒绝
                        let data ={
                            recordId:arg.record.id,
                            userId:this.$store.getters.userInfo.id,
                            state:"3",
                            remarks:arg.reason
                        };
                        api_item_finance_record_approval.updApprovalFlow.requestPOSTUrlParam(this,data,res=>{
                            if(res.data.flag){
                                this.$message.success(res.data.msg);
                                this.init()
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                        break;
                    }
                    case this.$eventTags.showDetail:{
                        this.formData = arg;
                        this.$refs.local_tabs.handleOpen([
                            {tag:"账单详情",component:"personalBillViewer",record:arg},
                        ]);
                        this.$refs.TwoParts.transform();
                        break;
                    }
                    case this.$eventTags.refresh:{
                        this.init();
                        this.$refs.TwoParts.reset();
                        break;
                    }
                    case "downloadFlows" :{
                        let data = {recordId:arg.id};
                        let fileName = arg.title+".xls";
                        api_item_finance_record_flow.exportExcelByRecord.fileDownloadGet(this,data,fileName);
                        break;
                    }
                }
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.columns = [
                        //new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                        new Column("用户名","userName",ColumnType.String,false).setTableView(80),
                        new Column("账单标题", "title", ColumnType.String, true).setTableView(150),
                        new Column("操作", "actions", "actions", false).setTableView(80)
                    ]
                } else {
                    this.columns = this.table.getTableColumns();
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>
    .left-part{
        height: 100%;
        width: 40%;
        min-width: 640px;
        overflow-y: auto;
    }
    .right-part{
        height: 100%;
        width: 60%;
        min-width: 640px;
        overflow-y: auto;
    }
</style>