<!--
* @program: office_automation 
* @author: ly
* @component:AttendanceRecordsFromUser
* @description: 考勤记录 通过人查看
* @create: 2022-01-14 11:01
-->
<template>
    <div style="height: 100%">
        <div class="search-bar">
            <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
            <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                <template #icon>
                    <SearchOutlined/>
                </template>
            </a-button>
            <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <span style="margin-left: 10px">状态：</span>
            <a-radio-group @change="radioChange" class="search-item" v-model:value="defaultQuery.disableState">
                <a-radio value="1">在职</a-radio>
                <a-radio disabled value="0">离职</a-radio>
            </a-radio-group>
        </div>
        <TwoParts ref="TwoParts" pos="transform" :enable-change="false">
            <template #left>
                <ArBaseTableFilter  :argument="argument" :page-size="pageSize" :view-model="true" ref="arTable">
                    <template #action="{index,record}">
                        <div class = "action-column">
                            <a-button @click="showDetail(index,record)" class="action-button" type="link">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                        </div>
                    </template>
                </ArBaseTableFilter>
            </template>
            <template #right>
                <div v-if="record" >
                    <AttendanceSingleView :record="record" />
                </div>
                <div v-else>
                    <welcome2 />
                </div>
            </template>
        </TwoParts>
    </div>
</template>

<script>
    import userInfoManager from "../../userInfoManager/userInfoManager";
    import AttendanceSingleView from "./AttendanceSingleView";
    export default {
        name: "AttendanceRecordsFromUser",
        mixins:[userInfoManager],
        components:{
            AttendanceSingleView
        },
        methods:{
            showDetail(index,record){
                this.record = record;
            }
        }

    }
</script>

<style scoped>

</style>