<!--
* @program: office_automation 
* @author: ly
* @component:MonthArrange 
* @description: 任务负责人月工作量分配
* @create: 2022-05-20 15:46
-->
<template>
    <a-spin :spinning="spinning">

        <a-tooltip  title="添加月度工作量分配">
            <a-button @click="addData" type="primary" style="margin-right: 10px">
                <template #icon><PlusOutlined /></template>
            </a-button>
        </a-tooltip>
        <a-button @click="showAll">分配记录</a-button>
        <span>成员：</span>
        <a-radio-group v-model:value="currentMember" >
            <a-radio v-for="(item,index) in members" :value="item" :key="index">
                {{item.name}}
            </a-radio>
        </a-radio-group>
        <div style="display: flex">
            <TcBaseTable :title="true" :table-columns="tableColumns" :data-source="ds" :view-model="true" :page-size="10">
                <template #title>
                    工作量分配记录
                    <span>月份：</span>
                    <MonthPicker  style="width: 120px" v-model:value="filterMonth" />
                </template>
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button v-if="allowMonth(record.yearMonth,yearMonth)" class="action-button" type="link" @click="editDetail(record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="allowMonth(record.yearMonth,yearMonth)"
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </TcBaseTable>
        </div>
    </a-spin>
    <MonthArrangeAdd ref="MonthProcessArrangeAdd" :arranged="{work:moneySum,lead:moneySum2}"  :members="members" :task="task" @refresh="init"/>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :width="300"
             :footer="null"
             :title="getTitle">
        <a-form layout="horizontal">
            <a-form-item label="作业占比">
                <a-input-number  style="width: 150px"  v-model:value="completeRatio" />
            </a-form-item>
            <a-form-item label="说明">
                <a-textarea style="width: 150px" v-model:value="remarks" />
            </a-form-item>
        </a-form>
        <a-button size="small" type="primary" style="margin: 20px" @click="saveData">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import MonthProcessArrange from "../../projectManager/departmentTaskViewer/MonthProcessArrange3";
    import MonthArrangeAdd from "./MonthArrangeAdd";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {allowMonth} from "../../../assets/utils/general";
    import taskMonthUser from "../../../assets/api/taskMonthUser";

    export default {
        name: "MonthArrange",
        mixins:[MonthProcessArrange],
        components:{
            MonthArrangeAdd
        },
        data(){
            return{
                allowMonth:allowMonth
            }
        },
        methods:{
            saveData(){
                this.currentRecord.completeRatio = this.completeRatio;
                this.currentRecord.remarks = this.remarks;
                taskMonthUser.saveOrUpd.requestPOST(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.tableColumns = [
                new Column("序号","_index",ColumnType.Index).setTableView(60),
                new Column("年月","yearMonth",ColumnType.Month,true).setTableView(80),
                new Column("用户名","userName",ColumnType.String,false).setTableView(100),
                new Column("工作占比","completeRatio",ColumnType.Number,true).setTableView(100)
                    .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                new Column("说明","remarks",ColumnType.String,true).setTableView(200),
                new Column("操作","actions","actions",false).setTableView(60),
            ];
        }
    }
</script>

<style scoped>

</style>