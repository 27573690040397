<!--
* @program: tyh-oa 
* @author: ly
* @component:emitDataCheck 
* @description: 
* @create: 2021-03-30 09:59
-->
<template>
    <div>
        
    </div>
</template>

<script>
    import {EmitData} from "../assets/utils/general";
    export default {
        name: "emitDataCheck",
        emits:{
            event:(data)=>{
                if(data instanceof EmitData){
                    return true;
                }
                else{
                    console.warn("EmitData event data type error!");
                    return false;
                }
            }
        },
    }
</script>

<style scoped>

</style>