import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

export default class user_info extends Table{
    constructor(name,CNName) {
        super(name,CNName);
        this.name = "user_info";
        this.CNName = "用户信息表";
        this.columnArray = [
            new Column("姓名","name",ColumnType.String,false).setTableView(120).setPosition("left"),
            new Column("状态","loginState",ColumnType.Enum,false)
                .setEnum(["0","1","2"],["正式员工","试用期","实习生"],["cyan","green","pink"]).setTableView(100),

            new Column("籍贯","userExtra.nativePlace",ColumnType.String,true).setTableView(100),
            new Column("身份证号","userExtra.idCardNumber",ColumnType.String,true).setTableView(100)
                .setTriggerOtherTableColumn(null,(formData,cols)=>{
                    if(formData.userExtra && formData.userExtra.idCardNumber.length === 18){
                        formData.userExtra.sex = getSex(formData.userExtra.idCardNumber);
                        let birthday = getBirth(formData.userExtra.idCardNumber);
                        formData.userExtra.birthday = birthday;
                        formData.userExtra.age = getAge(birthday);
                    }
                },(formData,cols)=>{
                    if(formData.userExtra && formData.userExtra.idCardNumber.length === 18){
                        formData.userExtra.sex = getSex(formData.userExtra.idCardNumber);
                        let birthday = getBirth(formData.userExtra.idCardNumber);
                        formData.userExtra.birthday = birthday;
                        formData.userExtra.age = getAge(birthday);
                    }
                }),
            new Column("性别","userExtra.sex",ColumnType.String).setTableView(80),
            new Column("生日","userExtra.birthday",ColumnType.Date),
            new Column("年龄","userExtra.age",ColumnType.Number).setTableView(80),
            new Column("现住址","userExtra.currentAddr",ColumnType.String,true),
            new Column("婚姻状态","userExtra.maritalStatus",ColumnType.Enum,true).setTableView(120)
                .setEnum(["未婚","已婚","离婚","丧偶"],["未婚","已婚","离婚","丧偶"]),
            new Column("政治面貌","userExtra.politiOutLook",ColumnType.String,true).setTableView(120),
            new Column("民族","userExtra.nation",ColumnType.String,true).setTableView(120),
            new Column("紧急联系人","userExtra.emergContact",ColumnType.String,true),
            new Column("紧急联系电话","userExtra.emergPhone",ColumnType.String,true),

            new Column("毕业院校","userExtra.graduSchool",ColumnType.String,true).setGroup(1).setTableView(120),
            new Column("毕业时间","userExtra.graduDate",ColumnType.Date,true).setGroup(1).setTableView(120),
            new Column("学历","userExtra.education",ColumnType.String,true).setGroup(1).setTableView(100),
            new Column("学位","userExtra.degree",ColumnType.String,true).setGroup(1).setTableView(120),
            new Column("专业","userExtra.major",ColumnType.String,true).setGroup(1).setTableView(100),
            new Column("职称资质","userExtra.titleQualify",ColumnType.String,true).setGroup(1).setTableView(120)
                .setEnum(["助理工程师","工程师","高级工程师","无"],
                    ["助理工程师","工程师","高级工程师","无"],["blue","purple","red","white"]
                ),
            new Column("职称专业","userExtra.titleProfession",ColumnType.String,true).setGroup(1).setTableView(120),
            new Column("职称任职时间","userExtra.titleGetDate",ColumnType.Date,true).setGroup(1),
            new Column("人事档案所在地","userExtra.archivesPlace",ColumnType.String,true).setGroup(1),

            new Column("入职日期","userExtra.inductionDate",ColumnType.Date,true).setGroup(2).setTableView(120)
                .setTriggerOtherTableColumn(null,(formData,cols)=>{
                    if(formData.disableState === "1" && formData.userExtra && formData.userExtra.inductionDate){
                        formData.userExtra.workingYear = workingYear(formData.userExtra.inductionDate);
                    }
                },(formData,cols)=>{
                    if(formData.disableState === "1" && formData.userExtra && formData.userExtra.inductionDate){
                        formData.userExtra.workingYear = workingYear(formData.userExtra.inductionDate);
                    }
                }),
            new Column("入职年限","userExtra.workingYear",ColumnType.Number).setGroup(2).setTableView(120),
            new Column("职位","userExtra.positionName",ColumnType.Enum,true).setGroup(2)
                .setEnum(["总经理","常务副总经理","副总经理","主任","副主任","项目组长","技术骨干","技术员","内勤","财务"],
                    ["总经理","常务副总经理","副总经理","主任","副主任","项目组长","技术骨干","技术员","内勤","财务"]),
            new Column("岗位级别","userExtra.positionLevel",ColumnType.PositionLevel,true).setGroup(2).setTableView(120),
            new Column("劳动合同","userExtra.laborContractFlag",ColumnType.Boolean,true).setGroup(2)
                .setBoolTags(["未签订","已签订"]).setDefaultValue(false)
                .setTriggerOtherColumn(false,(formData,cols)=>{
                    cols.filter(col=>{return col.dataIndex==="contractNum"})[0]?.setVisible(false);
                    cols.filter(col=>{return col.dataIndex==="sigContractDate"})[0]?.setVisible(false);
                    cols.filter(col=>{return col.dataIndex==="contractTerm"})[0]?.setVisible(false);
                },(formData,cols)=>{
                    cols.filter(col=>{return col.dataIndex==="contractNum"})[0]?.setVisible(true);
                    cols.filter(col=>{return col.dataIndex==="sigContractDate"})[0]?.setVisible(true);
                    cols.filter(col=>{return col.dataIndex==="contractTerm"})[0]?.setVisible(true);
                }),
            new Column("劳动合同编号","userExtra.contractNum",ColumnType.String,true).setGroup(2).setTableView(120),
            new Column("签订劳动合同时间","userExtra.sigContractDate",ColumnType.Date,true).setGroup(2).setTableView(120),
            new Column("签订劳动合同期限","userExtra.contractTerm",ColumnType.String,true).setGroup(2).setTableView(120),
            new Column("任职资格证书","userExtra.qualifCertif",ColumnType.String,true).setGroup(2).setTableView(120),
            new Column("技能证书","userExtra.certificate",ColumnType.String,true).setGroup(2).setTableView(120),
            new Column("社保缴纳起始日期","userExtra.insuranceDate",ColumnType.Date,true).setGroup(2),
            new Column("公积金缴纳起始日期","userExtra.publicFundDate",ColumnType.Date,true).setGroup(2),
            new Column("试用期截止日期","userExtra.trialExpireDate",ColumnType.Date,true).setGroup(3),
            new Column("工作经历","userExtra.remarks",ColumnType.String,true).setGroup(3),
            new Column("简历原件","userExtra.fileUrl",ColumnType.PreViewFile,true).setGroup(3),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(120).setPosition("right")];
    }
}

function getBirth(idCard) {
    let birthday = "";
    if(idCard !== null && idCard !== ""){
        if(idCard.length === 15){
            birthday = "19"+idCard.slice(6,12);
        } else if(idCard.length === 18){
            birthday = idCard.slice(6,14);
        }
        birthday = birthday.replace(/(.{4})(.{2})/,"$1/$2/");
        //通过正则表达式来指定输出格式为:1990-01-01
    }
    return birthday;
}

function getSex(idCard) {
    let sexStr = '';
    if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
        sexStr = '男';
    }
    else {
        sexStr = '女';
    }
    return sexStr;
}

function getAge(birthday) { //1900/01/01
    let date = new Date().toLocaleDateString();
    let year = parseInt(date.split("/")[0]);
    let mouth = parseInt(date.split("/")[1]);
    let day = parseInt(date.split("/")[2]);
    if(!birthday){
        return 0
    }
    let birthYear = parseInt(birthday.split("/")[0]);
    let birthMouth = parseInt(birthday.split("/")[1]);
    let birthDay = parseInt(birthday.split("/")[2]);
    let a = mouth*30 + day;
    let b = birthMouth*30 + birthDay;
    if(a - b >0){
        return year - birthYear;
    }
    else{
        return year - birthYear - 1;
    }
}

function workingYear(startDate) { //1900/01/01
    let date = new Date().toLocaleDateString();
    let year = parseInt(date.split("/")[0]);
    let mouth = parseInt(date.split("/")[1]);
    let day = parseInt(date.split("/")[2]);
    if(!startDate){
        return 0
    }
    let nDate = new Date(startDate).toLocaleDateString();
    let birthYear = parseInt(nDate.split("/")[0]);
    let birthMouth = parseInt(nDate.split("/")[1]);
    let birthDay = parseInt(nDate.split("/")[2]);
    let a = mouth*30 + day;
    let b = birthMouth*30 + birthDay;
    if(a - b >0){
        return year - birthYear;
    }
    else{
        return year - birthYear - 1;
    }
}