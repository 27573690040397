<!--
* @program: office_automation 
* @author: ly
* @component:appMenuViewer 
* @description: 
* @create: 2023-04-27 11:23
-->
<template>
    <div style="height: 100%;width: 100%;background: white">
        <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
            <template #title>
                微信小程序菜单设定
                <a-tooltip  title="添加大类">
                    <a-button class="search-item" type="dashed"  @click="addGroup">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                </a-tooltip>
                <a-tooltip  title="刷新">
                    <a-button class="search-item" type="dashed"  @click="refresh">
                        <template #icon><SyncOutlined /></template>
                    </a-button>
                </a-tooltip>
            </template>
        </a-page-header>
        <div style="width: 100%;height:calc(100% - 120px);display: flex;">
            <div style="width: 50%; height: 100%;min-width: 550px">
                <a-tree style="text-align: left;margin-left: 50px;height:100%;overflow-y:auto"
                        v-if="!loading"
                        :tree-data="treeData"
                        default-expand-all
                        :replace-fields="replaceFields"
                        showLine
                        :selectable="false"
                >
                    <template #title="{dataRef}">
                        <div style="display: flex;height: 24px">
                            <div style="padding: 0 5px;height: 24px">{{dataRef.text}}</div>
                            <app-permissions :permissions="dataRef.permissions" :view-model="true"/>
                            <a-button style="padding: 0 5px;height: 24px"  type="link" @click="menuAdd(dataRef)"><PlusOutlined /></a-button>
                            <a-button style="padding: 0 5px;height: 24px" type="link" @click="menuEdit(dataRef)"><EditOutlined /></a-button>
                            <a-popconfirm
                                    v-if="dataRef.children.length === 0"
                                    ok-text="是"
                                    cancel-text="否"
                                    title="确定删除吗?"
                                    @confirm="menuDelete(dataRef)"><!---->
                                <a-button style="padding: 0 5px;height: 24px"  type="link"><DeleteOutlined  style="color: red" class="pointer"/></a-button>
                            </a-popconfirm>
                        </div>
                    </template>
                </a-tree>
            </div>
            <div v-if="visible" style="width: 50%; height: 100%;overflow-y:auto">
                <a-page-header style="border: 1px solid rgb(235, 237, 240)"
                               :title="modalTitle"/>
                <a-form style="margin: 10px 100px" layout="horizontal">
                    <a-form-item label="菜单名">
                        <a-input style="width: 300px" v-model:value="currentMenu.text" />
                    </a-form-item>
                    <a-form-item label="菜单路径">
                        <a-input style="width: 300px" v-model:value="currentMenu.pagePath" />
                    </a-form-item>
                    <a-form-item label="菜单图标路径">
                        <a-input style="width: 300px" v-model:value="currentMenu.iconPath" />
                    </a-form-item>
                    <a-form-item label="菜单图标选中路径">
                        <a-input style="width: 300px" v-model:value="currentMenu.selectedIconPath" />
                    </a-form-item>
                    <a-form-item label="权限">
                        <a-input style="width: 300px" v-model:value="currentMenu.permissions" />
                    </a-form-item>
                    <a-form-item label="排序">
                        <a-input-number style="width: 100px" v-model:value="currentMenu.sort" />
                    </a-form-item>
                </a-form>
<!--                <a-page-header  style="border: 1px solid rgb(235, 237, 240)">-->
<!--                    <template #title>-->
<!--                        <div style="display: flex;">-->
<!--                            <div>菜单权限设定(请勿随意修改)</div>-->
<!--                            <div style="margin-left: 20px">-->
<!--                                <app-permissions v-model:permissions="currentMenu.permissions" :view-model="false"/>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </a-page-header>-->
                <a-button key="back" @click="visible = false">关闭</a-button>
                <a-button key="submit" type="primary"  @click="update">提交</a-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        DeleteOutlined,
        EditOutlined,
        PlusOutlined,
        SyncOutlined
    } from "@ant-design/icons-vue";
    import wxMenu from "../../../assets/api/wxMenu";
    import sys_app_menu from "../../../assets/tables/sys_app_menu";
    import appPermissions from "./appPermissions";
    export default {
        name: "appMenuViewer",
        components:{
            PlusOutlined,EditOutlined,
            DeleteOutlined,SyncOutlined,appPermissions
        },
        data(){
            return{
                loading:false,
                replaceFields :{
                    children: 'children',
                    title: 'treeName',
                    key:"id"
                },
                treeData:[],
                visible:false,
                currentMenu:{},
                modalTitle:null,
                operationTitle:"菜单权限",
            }
        },
        methods:{
            init(){
                this.visible = false;
                this.loading = true;
                wxMenu.queryAllTree.requestPOST(this,{},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.treeData = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            menuAdd(node){
                this.currentMenu = new sys_app_menu().getFormData();
                this.currentMenu.pid = node.id;
                this.visible = true;
                this.modalTitle = node.text+"——添加子菜单";
            },
            menuEdit(node){
                this.modalTitle = node.text+"——菜单编辑";
                this.currentMenu = node;
                this.visible = true;
            },
            menuDelete(node){
                let data = {id:node.id};
                wxMenu.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            addGroup(){
                this.currentMenu = new sys_app_menu().getFormData();
                this.visible = true;
                this.modalTitle = "添加新菜单"
            },
            refresh(){
                this.init()
            },
            update(){
                wxMenu.saveOrUpd.requestPOST(this,this.currentMenu,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>