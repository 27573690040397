<!--
* @program: office_automation 
* @author: ly
* @component:projectRegister 
* @description: 用于发起项目申请
* @create: 2022-01-08 11:52
["0","1","2"],["未审核","已通过","未通过"]
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button  class="search-item" type="dashed" shape="circle" @click="addData">
                    <template #icon><PlusOutlined /></template>
                </a-button>
                <a-radio-group class="search-item" v-model:value="value" @change="selectChange">
                    <a-radio value="0">未审核</a-radio>
                    <a-radio value="1">已通过</a-radio>
                    <a-radio value="2">未通过</a-radio>
                    <a-radio value="全部">全部</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" @change="handleChange" pos="transform" >
                <template #left>
                    <TcBaseTableFilter :table-columns="tableColumns" :data-source="dataSource" :view-model="true" :page-size="pageSize">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(index,record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-button v-if="record.auditState ==='0'"
                                        @click="editDetail(index,record)" class="action-button" type="link">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        v-if="record.auditState ==='0'"
                                        @confirm="cancel(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="是否撤销审核？">
                                    <a-tooltip title="撤销审核">
                                        <a-button class="action-button" style="color: orange" type="link">
                                            <RollbackOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                                <a-popconfirm
                                        v-if="record.auditState ==='2'"
                                        @confirm="cancel(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="是否删除？">
                                    <a-tooltip title="删除">
                                        <a-button class="action-button" style="color: red" type="link">
                                            <DeleteOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                        </template>
                    </TcBaseTableFilter>
                </template>
                <template #right>
                    <div v-is="comp" :projectId="projectId" :viewModel="viewModel"></div>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>

    import SearchBar from "../../../components/SearchBar/SearchBar";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import TwoParts from "../../_components/TwoParts";
    import {SearchOutlined, SyncOutlined,PlusOutlined,EditFilled,RollbackOutlined,DeleteOutlined} from "@ant-design/icons-vue";
    import item_info from "../../../assets/tables/item_info";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import itemInfo from "../../../assets/api/itemInfo";
    import TcBaseTableFilter from "../../../components/table/TcBaseTableFilter";
    import welcome2 from "../../welcome2";
    import projectInfo from "./projectInfo";
    export default {
        name: "projectRegister",
        components: {
            SearchBar,TcBaseTableFilter,ArBaseForm,TwoParts,SearchOutlined,SyncOutlined,PlusOutlined,
            welcome2,projectInfo,EditFilled,RollbackOutlined,DeleteOutlined
        },
        data(){
            return{
                dataSource: [],
                tableColumns:[],
                formData:{},
                table:new item_info(),
                loading:false,
                pageSize:15,
                comp:"welcome2",
                viewModel:true,
                projectId:null,
                value:"0",
                all:[],
                unfinished:[],
                accepted:[],
                refuse:[],
            }
        },
        methods:{
            init(){
                this.loading = true;
                this.pageSize = 15;
                this.value = "0";
                itemInfo.queryAllByUser.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.all = res.data.data;
                        this.unfinished = this.all.filter(item=>item.auditState === "0");
                        this.accepted = this.all.filter(item=>item.auditState === "1");
                        this.refuse = this.all.filter(item=>item.auditState === "2");
                        this.dataSource = this.unfinished;
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.tableColumns = [
                        new Column("项目名称","name",ColumnType.String,false).setTableView(200),
                        new Column("操作", "actions", "actions", false).setTableView(80)
                    ]
                } else {
                    this.tableColumns = this.table.getTableColumns();
                }
            },
            addData(){
                this.projectId = null;
                this.comp = "projectInfo";
                this.viewModel = false;
            },
            refresh(){
                this.init();
                this.comp = "welcome2";
            },
            showDetail(index,record){
                this.comp = "projectInfo";
                this.projectId = record.id;
                this.viewModel = true;
            },
            editDetail(index,record){
                this.comp = "projectInfo";
                this.projectId = record.id;
                this.viewModel = false;
            },
            cancel(index,record){
                let data = {id:record.id};
                itemInfo.delete.requestPOSTUrlParam(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                        this.comp = "welcome2";
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            selectChange(){
                switch (this.value) {
                    case "0":{
                        this.dataSource = this.unfinished;
                        break;
                    }
                    case "1":{
                        this.dataSource = this.accepted;
                        break;
                    }
                    case "2":{
                        this.dataSource = this.refuse;
                        break;
                    }
                    case "全部":{
                        this.dataSource = this.all;
                        break;
                    }
                }
            }
        },
        created() {
            this.tableColumns = this.table.getTableColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>