/** 
* 自动生成文件，请勿修改 
* @name: 报销:费用类别模块
* @description: 报销-费用类别-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const category = { 
	/** 1 summary:费用类别-批量删除	method:post */ 
	batchDelete : new APIObject("/api/category/batchDelete.do"), 
	/** 2 summary:费用类别-批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/category/batchSaveOrUpd.do"), 
	/** 3 summary:费用类别-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/category/saveOrUpd.do"), 
	/** 4 summary:查询所有未绑定会计科目的类别-树形结构	method:post */ 
	noBindSuject : new APIObject("/api/category/noBindSuject.do"), 
	/** 5 summary:费用类别-多条数据查询	method:post */ 
	queryOneLevel : new APIObject("/api/category/queryOneLevel.do"), 
	/** 6 summary:费用类别-单条删除	method:post */ 
	delete : new APIObject("/api/category/delete.do"), 
	/** 7 summary:费用类别-根据一个大类id查询其下的所有小类	method:post */ 
	findSmallCategory : new APIObject("/api/category/findSmallCategory.do"), 
	/** 8 summary:费用类别-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/category/queryOne.do"), 
};
export default category;