<!--
* @program: office_automation 
* @author: ly
* @component:AttendanceSingleView 
* @description: 单人考勤查看
* @create: 2022-01-14 14:53
-->
<template>
    <div class="search-bar">
        <a-radio-group @change="radioChange" class="search-item" v-model:value="type">
            <a-radio value="0">按月份查询</a-radio>
            <a-radio value="1">查看所有记录</a-radio>
        </a-radio-group>
        <MonthSelector v-if="type === '0'" v-model:value="yearMonth" @change="monthChange"/>
        <a-button v-if="type === '0'" danger  @click="editData">
            手动添加本月考核
        </a-button>
    </div>
    <div>
        <a-spin v-if="type === '0'" :spinning="loading">
            <ArForm style="width: 400px" :span-col="true" v-if="argument.formData" :argument="argument" :view-model="viewModel" >
                <template #action="{record}">
                    <a-button type="primary" @click="saveData(record)">
                        提交
                    </a-button>
                </template>
            </ArForm>
            <div v-else style="font-size: 40px;margin: 20vh" class="non-select">
                {{tips}}
            </div>
        </a-spin>
        <a-spin v-if="type === '1'" :spinning="loading">
            <ArBaseTable v-if="argument.dataSource.length>0" :argument="argument" :view-model="true" :page-size="12" />
            <div v-else style="font-size: 40px;margin: 20vh" class="non-select">
                {{tips}}
            </div>
        </a-spin>
    </div>
</template>

<script>
    import MonthSelector from "../../../../components/SearchBar/MonthSelector";
    import sys_attendance from "../../../../assets/tables/sys_attendance";
    import ArForm from "../../../../components/form/ArForm";
    import attendance from "../../../../assets/api/attendance";
    import moment from "moment";
    import ArBaseTable from "../../../../components/table/ArBaseTable";
    export default {
        name: "AttendanceSingleView",
        props:["record"],
        watch:{
           record(){
               this.init()
           }
        },
        components:{
            MonthSelector,ArForm,ArBaseTable
        },
        data(){
            return{
                table:new sys_attendance(),
                yearMonth:null,
                argument:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData

                    dataSource:[],//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                viewModel:true,
                tips:"请选择查询年月",
                loading:false,
                type:"0"
            }
        },
        methods:{
            init(){
                this.type = "0";
                this.viewModel = true;
                let data = {
                    userId : this.record.id,
                    yearMonth:this.yearMonth
                };
                this.loading = true;
                attendance.queryAll.requestPOST(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.argument.formData = res.data.data[0];
                    }
                    else{
                        this.argument.formData = null;
                        this.tips = "未查询到考勤记录"
                    }
                })
            },
            monthChange(){
                let data = {
                    userId : this.record.id,
                    yearMonth:this.yearMonth
                };
                this.loading = true;
                attendance.queryAll.requestPOST(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.argument.formData = res.data.data[0];
                    }
                    else{
                        this.argument.formData = null;
                        this.tips = "未查询到考勤记录"
                    }
                })
            },
            tableInit(){
                let data = {
                    userId : this.record.id,
                    yearMonth:"",
                };
                attendance.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            saveData(record){
                if(record.id){
                    attendance.saveOrUpdate.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            this.viewModel = true;
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                else{
                    record.userId = this.record.id;
                    record.yearMonth = this.yearMonth;
                    attendance.saveOrUpdate.requestPOST(this,record,res=>{
                        if(res.data.flag){
                            this.viewModel = true;
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
            editData(){
                if(this.argument.formData){
                    this.$message.error("本月考勤已经存在")
                }
                else{
                    this.argument.formData = this.table.getFormData();
                    this.viewModel = false;
                }
            },
            radioChange(){
                switch (this.type) {
                    case "0":{
                        this.monthChange();
                        break;
                    }
                    case "1":{
                        this.tableInit();
                        break;
                    }
                }
            }
        },
        created() {
            this.yearMonth = moment(new Date().valueOf()).format("YYYY-MM");
            this.argument.formColumns = this.table.getFormColumns();
            this.argument.tableColumns = this.table.getTableColumns();
            this.init();
        }
    }
</script>

<style scoped>

</style>