<!--
* @program: office_automation 
* @author: ly
* @component:SealAuditSetting 
* @description: 印章审核流程设定
* @create: 2022-06-07 14:38
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar" >
                <a-button class="search-item" type="dashed"  @click="init">
                    刷新
                </a-button>
                <div style="margin: 10px">选择部门</div>
                <a-select v-model:value="department" style="width: 250px"
                          class="search-item"
                          @change="departmentSelect">
                    <a-select-option v-for="item in departments"  :key="item.id">
                        {{item.fullName}}
                    </a-select-option>
                </a-select>
                <a-button class="search-item" type="dashed"  @click="groupSet">
                    批量申请流程设定
                </a-button>
            </div>
            <div style="width: 600px" >
                <TcSSTable :loading="loading"  @selectChange="handleSelectChange" :scrolls="{y:600}" :view-model="true" :table-columns="tableColumns" :data-source="dataSource" :page-size="15" >
                    <template #action="{record}">
                        <div style="text-align: center">
                            <a-tooltip title="申请流程">
                                <a-button class="action-button" type="link" @click="editDetail(record)">
                                    <EditFilled class="pointer"/>
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </TcSSTable>
            </div>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <TagShow color="green" v-if="selectedRows.length ===1"  :visible="true" tag="当前流程">
            <template #content>
                <SealStepsViewer :user="selectedRows" :purposes="sealPurposes" />
            </template>
        </TagShow>
        <TagShow color="blue" :visible="true" tag="流程设定">
            <template #content>
                <SealStepsAdd :users="selectedRows" :purposes="sealPurposes" />
            </template>
        </TagShow>
    </a-modal>
</template>

<script>
    import TagShow from "../../_components/TagShow";
    import TcSSTable from "../../../components/table/TcSSTable";
    import {EditFilled} from "@ant-design/icons-vue";
    import SealStepsAdd from "./SealStepsAdd";
    import SealStepsViewer from "./SealStepsViewer";
    import sys_department from "../../../assets/tables/sys_department";
    import sys_user_info from "../../../assets/tables/sys_user_info";
    import sys_user_info_2 from "../../../assets/tables/sys_user_info_2";
    import api_sys_user_info from "../../../assets/api/userInfo";
    import api_sys_department from "../../../assets/api/department";
    import api_sys_department_relation from "../../../assets/api/userDepartRelation";
    import sealPurpose from "../../../assets/api/sealPurpose";

    export default {
        name: "SealAuditSetting",
        components:{
            TagShow,
            TcSSTable,EditFilled,SealStepsAdd,SealStepsViewer
        },
        data() {
            return {
                departmentTable:new sys_department(),
                userTable:new sys_user_info(),
                userTable2:new sys_user_info_2(),
                departments:[],
                tableColumns:[],
                dataSource:[],
                loading:false,
                visible:false,
                selectedRows:[],
                currentSteps:[],
                sealPurposes:[],
                purposeIds:null
            }
        },
        methods: {
            init() {
                this.tableColumns = this.userTable.getTableColumns();
                this.loading = true;
                api_sys_user_info.queryAll.requestPOST(this,{disableState:"1",loginState:"0"},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                });
            },
            departmentSelect(){
                let param  ={
                    departmentId:this.department,
                    disableState:"1",
                    haveLevel:"0"
                };
                this.loading = true;
                this.dataSource=[];
                api_sys_department_relation.queryAll.requestPOSTUrlParam(this,param,res=>{
                    this.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        this.dataSource = res.data.data;
                        this.tableColumns = this.userTable2.getTableColumns();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handleSelectChange(selectedRowKeys,selectedRows){
                this.selectedRows = selectedRows;
            },
            editDetail(record){
                this.selectedRows = [record];
                this.visible = true;
            },
            groupSet(){
                if(this.selectedRows.length>0){
                    this.visible = true;
                }
                else{
                    this.$message.error("请选择需要设定的用户");
                }
            },
        },
        created() {
            this.sealPurposes = []
            api_sys_department.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.departments = res.data.data;
                }
            });
            sealPurpose.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    for(let i in res.data.data){
                        this.sealPurposes.push({
                            label:res.data.data[i].name,
                            value:res.data.data[i].id
                        })
                    }

                }
            });
            this.init();
        }
    }
</script>

<style scoped>

</style>