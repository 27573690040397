/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_finance_record_flow
 * 报账流水使用
 * @create: 2021-04-12 10:07
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import api_item_info from "../api/itemInfo";
export default class item_finance_record_flow extends Table{
    constructor() {
        super();
        this.name = "item_finance_record_flow";
        this.CNName = "账单流水";
        this.columnArray = [
            new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(120)
                .setCategoryTrigger(
                    (formData,cols)=>{
                        formData.itemExpenditure = true;
                        cols.filter(col=>{return col.dataIndex==="itemExpenditure"})[0]?.setEditable(false);
                        cols.filter(col=>{return col.dataIndex==="itemId"})[0]?.setVisible(true);
                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="itemExpenditure"})[0]?.setEditable(true);
                        if(formData.itemExpenditure === false){
                            formData.itemId = null;
                            formData.itemName = null;
                            cols.filter(col=>{return col.dataIndex==="itemId"})[0]?.setVisible(false);
                        }
                    }),
            new Column("支出时间","flowTime",ColumnType.Date,true).setTableView(80),
            new Column("项目支付","itemExpenditure",ColumnType.Boolean,true)
                .setTriggerOtherColumn(true,
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="itemId"})[0]?.setVisible(true);},
                    (formData,cols)=>{
                        formData.itemId = null;
                        formData.itemName = null;
                        cols.filter(col=>{return col.dataIndex==="itemId"})[0]?.setVisible(false);
                    }).setDefaultValue(false),
            new Column("项目","itemId",ColumnType.IdReplace2,true).setVisible(false).setTableView(200)
                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                        "startTime": "", "tenderWay": ""
                    }}),
            new Column("发票","invoiceState",ColumnType.Boolean,true)
                .setTriggerOtherColumn(true,
                    /**
                     * 有发票 则显示发票金额字段
                     * @param formData
                     * @param cols
                     */
                    (formData,cols)=>{
                        if(cols.filter(col=>{return col.dataIndex==="invoiceAmount"}).length>0){
                            cols.filter(col=>{return col.dataIndex==="invoiceAmount"})[0]?.setVisible(true);
                        }
                    },
                    /**
                     * 无发票 则 隐藏发票金额字段，显示凭证字段
                     * @param formData
                     * @param cols
                     */
                    (formData,cols)=>{
                        if(cols.filter(col=>{return col.dataIndex==="invoiceAmount"}).length>0)
                        cols.filter(col=>{return col.dataIndex==="invoiceAmount"})[0]?.setVisible(false);
                        if(cols.filter(col=>{return col.dataIndex==="invoiceUrl"}).length>0)
                        cols.filter(col=>{return col.dataIndex==="invoiceUrl"})[0]?.setVisible(true);
                        formData["invoiceAmount"] =0;
                    }).setBoolTags(["无发票","有发票"]).setDefaultValue(false),
            new Column("发票金额(元)","invoiceAmount",ColumnType.Number,true).setTableView(100)
                .setTriggerOtherColumn((record)=>{
                        return record.applyAmount <= record.invoiceAmount;// 发票金额 <= 报销金额 的时候不需要报销凭证
                    },
                    (formData,cols)=>{
                        if(cols.filter(col=>{return col.dataIndex==="invoiceUrl"}).length>0)
                        cols.filter(col=>{return col.dataIndex==="invoiceUrl"})[0]?.setVisible(false);
                    },
                    (formData,cols)=>{
                        if(cols.filter(col=>{return col.dataIndex==="invoiceUrl"}).length>0)
                        cols.filter(col=>{return col.dataIndex==="invoiceUrl"})[0]?.setVisible(true);
                    }).setDefaultValue(0),
            new Column("报销金额(元)","applyAmount",ColumnType.Number,true).setTableView(100)
                .setTriggerOtherColumn(
                    (record)=>{
                        return record.applyAmount <= record.invoiceAmount;// 发票金额 <= 报销金额 的时候不需要报销凭证
                    },
                    (formData,cols)=>{
                        if(cols.filter(col=>{return col.dataIndex==="invoiceUrl"}).length>0)
                        cols.filter(col=>{return col.dataIndex==="invoiceUrl"})[0]?.setVisible(false);
                    },
                    (formData,cols)=>{
                        if(cols.filter(col=>{return col.dataIndex==="invoiceUrl"}).length>0)
                        cols.filter(col=>{return col.dataIndex==="invoiceUrl"})[0]?.setVisible(true);
                    }).setDefaultValue(1),
            new Column("备注","remarks",ColumnType.String,true),
            new Column("报销凭证","invoiceUrl",ColumnType.PreViewFile,true).setTableView(80),
        ];
        this.actions = new Column("操作","actions","actions",false).setTableView(80);
    }
}