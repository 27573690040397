<!--
* @program: office_automation 
* @author: ly
* @component:invoiceSelector 
* @description: 
* @create: 2021-12-17 15:38
-->
<template>
    <div class="center-flex">
        <a-select
                v-if="!viewModel"
                style="width: 250px"
                v-model:value="mFormArg.formData.invoiceId"
                @select="handleSelect"
        >
            <a-select-option v-for="option in selectOptions" :key="option.id">
                <a-tooltip :title="getTitle(option)">
                    <a-button style="padding: 0" type="text">
                        开票金额:{{ option.invoiceAmount}}
                        付款方:{{ option.partyInfo.name}}
                        开票日期:{{ new Date(option.invoiceTime).toLocaleDateString()}}
                    </a-button>
                </a-tooltip>
            </a-select-option>
        </a-select>
    </div>
    <a-popover v-if="visible" trigger="hover" placement="bottom">
        <template #content>
            <div>付款方：{{partyInfo?partyInfo.name:"未查询到相关数据"}}</div>
            <div>收款方：{{companyInfo?companyInfo.name:"未查询到相关数据"}}</div>
            <div>项目：{{itemInfo?itemInfo.name:"未查询到相关数据"}}</div>
            <div>发票金额：{{invoiceAmount}}</div>
            <div>已收金额：{{collectAmount}}</div>
        </template>
        <a-button  type="link" >
            发票相关信息
        </a-button>
    </a-popover>
</template>

<script>
    import invoiceRecord from "../../../assets/api/invoiceRecord";
    export default {
        name: "invoiceSelector",
        props:["viewModel","formArg","projectId"],
        emits:["update:formArg","refresh"],
        data(){
            return{
                mFormArg:null,
                queryData:{
                    "checkCode": "",
                    "collectState": "",
                    "companyId": "",
                    "flushRed": null,
                    "invoiceCode": "",
                    "invoiceNumber": "",
                    "invoiceTitle": "",
                    "invoiceType": "",
                    "itemId": "",
                    "partyId": ""
                },
                options:[],
                selectOptions:[],
                partyInfo:{}, //付款方
                companyInfo:{}, //收款方
                itemInfo:{},//项目信息
                visible:false,
                invoiceAmount:0, //开票金额
                collectAmount:0 //已收金额
            }
        },

        methods:{
            handleSelect(id){
                this.mFormArg.formData.payerId=null;
                this.mFormArg.formData.receiverId=null;
                this.$emit("update:formArg",this.mFormArg);
                let data = {id:id};
                invoiceRecord.queryById.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.visible = true;
                        let {partyInfo,companyInfo,itemInfo,invoiceAmount,collectAmount} = res.data.data;
                        this.partyInfo = partyInfo;
                        this.companyInfo = companyInfo;
                        this.itemInfo = itemInfo;
                        this.collectAmount = collectAmount;
                        this.invoiceAmount = invoiceAmount;
                    }
                })
            },
            getTitle(option){
                return "开票金额:"+ option.invoiceAmount + "\n付款方:" +
                    option.partyInfo.name + "\n开票日期:"+ new Date(option.invoiceTime).toLocaleDateString();
            }
        },
        created() {
            this.mFormArg = this.formArg;
            let data = {itemId:this.projectId};
            invoiceRecord.queryAll.requestPOST(this,data,res=>{
                if(res.data.flag){
                    this.selectOptions = res.data.data;
                }
                else{
                    this.selectOptions =[];
                }
            })
            if(this.mFormArg.formData.invoiceId){
                let data = {id:this.mFormArg.formData.invoiceId};
                invoiceRecord.queryById.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.visible = true;
                        let {partyInfo,companyInfo,itemInfo,invoiceAmount,collectAmount} = res.data.data;
                        this.partyInfo = partyInfo;
                        this.companyInfo = companyInfo;
                        this.itemInfo = itemInfo;
                        this.collectAmount = collectAmount;
                        this.invoiceAmount = invoiceAmount;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>