<!--
*@description：成果类型配置查询组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar" style="margin-top: -15px;margin-bottom: 5px;">
        <a-input class="search-item ant-col-4" v-model:value="name" placeholder="属性名称" />
        <a-input class="search-item ant-col-4" v-model:value="code" placeholder="属性编码" />
        <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
            <template #icon><Search /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
            <template #icon><Reset /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="addData">
          <template #icon><Add /></template>
        </a-button>
    </div>
</template>

<script>
    import Icons from "../../../common/Icons";
    export default {
      name: "achieveTypeToolBar",
      mixins:[Icons],
      data(){
        return{
          name:null,
          code:null,
        }
      },
      methods:{
        doSearch(){
            let data = this.$GetEmitData(this.$eventTags.search,{name:this.name,code:this.code});
            this.$emit("event",data);
        },
        refresh(){
            this.name = null;
            this.code = null;
            this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        },
        addData(){
          this.$emit("event",this.$GetEmitData(this.$eventTags.add));
        },
        getParms(){
          let parms = {
            name:this.name,
            code:this.code,
          }
          return parms;
        },

      }
    }
</script>

<style scoped>

</style>