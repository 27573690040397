<!--
* @program: office_automation 
* @author: ly
* @component:ShowBills 
* @description: 展示所有报账单
* @create: 2022-04-18 17:36
-->
<template>
    <div class="center-flex">
        <span>月份：</span>
        <MonthPicker  style="width: 120px" v-model:value="filterMonth" />
        <span class="statistic-label" v-if="summary"> 合计：{{summary.toFixed(2)}} 元</span>
        <MonthMoneySum v-model:summary="summary" :start-date="record.balanceDate" :records="dataSource" date-seg="recordYearMonth" sum-seg="amount" />
    </div>
    <div style="height:600px">
    <TcScrollTable :page-size="15" :scrolls="{y:600}" :data-source="dataSource" :loading="loading"
                 :table-columns="tableColumns" :view-model="true" />
    </div>
</template>

<script>
    import TcScrollTable from "../../../components/table/TcScrollTable";
    import {SearchOutlined} from "@ant-design/icons-vue";
    import MonthMoneySum from "./MonthMoneySum";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import record from "../../../assets/api/record";
    import MonthPicker from "../../_components/MonthPicker";
    import moment from "moment"
    export default {
        name: "ShowBills",
        components: {
            TcScrollTable,SearchOutlined,MonthMoneySum,MonthPicker
        },
        props:["record"],
        computed:{
            dataSource(){
                let arr = this.ds.concat(this.ds2).sort((a,b)=>{
                    return new Date(b.recordYearMonth) - new Date(a.recordYearMonth)
                });
                return arr.filter(item=>{
                    if(this.filterMonth){
                        return item.recordYearMonth === this.filterMonth
                    }
                    else{
                        return true
                    }
                })
            }
        },
        data() {
            return {
                pageSize:15,
                ds:[],//提供table的DataSource
                ds2:[],//提供table的DataSource
                tableColumns:[
                    new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                    new Column("账单归属时间","recordYearMonth",ColumnType.Month,true).setTableView(100),
                    new Column("用户名","userName",ColumnType.String,false).setTableView(80),
                    new Column("账单标题","title",ColumnType.String,true).setTableView(200),
                    new Column("总金额（元）","amount",ColumnType.Number,false).setTableView(100),
                    // new Column("有发票金额（元）","invoiceAmount",ColumnType.Number,false).setTableView(100),
                    // new Column("无发票金额（元）","noInvoiceAmount",ColumnType.Number,false).setTableView(100),
                    // new Column("提交","submitState",ColumnType.String,false)
                    //     .setEnum(["0","1"],["未提交","已提交"],["blue","green"]).setDefaultValue("0"),
                    // new Column("审核","approvalState",ColumnType.String,false).setTableView(100)
                    //     .setEnum(["0","1"],["未完成","已完成"],["pink","green"]).setDefaultValue("0"),
                    new Column("入账状态","entryState",ColumnType.String,false).setTableView(100)
                        .setEnum(["0","1","4","2","3"],["未入账","对公入账","私人入账","拒绝入账","撤销入账"],["pink","green","purple","red","orange"]),
                ], //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                visible:false,
                defaultQuery:{
                    entryState:"4", //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                    approvalState:"1", //审核状态（0：未完成；1：已完成）
                    submitState:"1" //提交状态（0：未提交；1：已提交）
                },
                summary:0,
                filterMonth:null,
                startMoment:null
            }
        },
        methods: {
            init() {
                this.loading = true;
                let data = {
                    entryState:"4", //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                    approvalState:"1", //审核状态（0：未完成；1：已完成）
                    submitState:"1" //提交状态（0：未提交；1：已提交）
                };
                let data2 = {
                    entryState:"1", //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                    approvalState:"1", //审核状态（0：未完成；1：已完成）
                    submitState:"1" //提交状态（0：未提交；1：已提交）
                };
                record.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.ds = res.data.data.filter(item=>{
                            return  moment(item.recordYearMonth).isSameOrAfter(this.startMoment)
                        });
                    }
                    else{
                        this.ds = []
                    }
                });
                record.queryAll.requestPOSTUrlParam(this,data2,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.ds2 = res.data.data.filter(item=>{
                            return  moment(item.recordYearMonth).isSameOrAfter(this.startMoment)
                        });
                    }
                    else{
                        this.ds2 = []
                    }
                })
            }
        },
        created() {
            this.startMoment = new moment(this.record.balanceDate).format("YYYY-MM");
            this.init()
        }
    }
</script>

<style scoped>

</style>