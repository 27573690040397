<!--
* @program: tyh-oa 
* @author: ly
* @component:categoryTree 
* @description: 
* @create: 2021-05-26 16:51
-->
<template>
    <a-tree style="text-align: left;margin-left: 40%;"
            :tree-data="treeData"
            default-expand-all
            :selectable="false"
            :replace-fields="replaceFields"
            v-if="treeData.length"
            v-model:checkedKeys="checkedKeys"
            checkable
    >
        <template #mytitle="node">
            <a-row>
                <a-col>
                    <div style="margin: 5px">{{node.categoryName}}</div>
                </a-col>
            </a-row>
        </template>
    </a-tree>
</template>

<script>
    import api_category from "../../../assets/api/category";
    import api_category_role from "../../../assets/api/categoryRole";

    export default {
        name: "categoryTree",
        props:["disabled","roleId"],
        data(){
            return{
                replaceFields :{
                    children: 'children',
                    title: 'categoryName',
                    key:"id"
                },
                treeData:[],
                checkedKeys:[],
                topKeys:[],//用于存放大类Id，上传时候剔除
            }
        },
        methods:{
            init(){
                this.checkedKeys = [];
                api_category.queryOneLevel.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.treeData = res.data.data;
                        let data  = {roleId:this.roleId};
                        disableCheckBox(this.treeData,this.disabled,this.topKeys);

                        api_category_role.queryAll.requestPOSTUrlParam(this,data,res=>{
                            if(res.data.flag){
                                let keys = [];
                                for(let i in res.data.data){
                                    keys.push(res.data.data[i].categoryId);
                                }
                                setTimeout(()=>{
                                    this.checkedKeys = keys;
                                },100)
                            }
                        })
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            getCheckedKeys(){
                let keys = this.checkedKeys.concat();
                for(let i in this.topKeys){
                    let index = keys.indexOf(this.topKeys[i]);
                    if( index !== -1){
                        keys.splice(index,1);
                    }
                }
                return keys; //剔除掉大类Id
            },
            setCheckedKeys(keys){
                this.checkedKeys = keys;
            },
        },
        created() {
            this.init();
        }
    }
    function disableCheckBox(treeData,bool,topKeys){ //递归函数
        for(let i in treeData){
            treeData[i]["disableCheckbox"] = bool;
            if(topKeys)topKeys.push(treeData[i].id);
            if(Object.prototype.hasOwnProperty.call(treeData[i],"children")){
                for(let j in treeData[i].children){
                    disableCheckBox(treeData[i].children,bool)
                }
            }
        }
    }
    function disableCheckBox2(treeData,bool){
        for(let i in treeData){
            treeData[i]["disableCheckbox"] = true;
            if(Object.prototype.hasOwnProperty.call(treeData[i],"children")){
                for(let j in treeData[i].children){
                    treeData[i].children[j]["disableCheckbox"] = bool;
                }
            }
        }
    }
</script>

<style scoped>

</style>