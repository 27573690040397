<!--
* @program: tyh-oa 
* @author: ly
* @description: mainPage
* @create: 2021-03-19 16:16
-->
<template>
    <a-layout style="height: 100%">
        <a-layout-header class="header">
            <oa-header ref="oaHeader" @event="headMenuHandle"/>
        </a-layout-header>
        <a-layout>
            <a-layout-content >
                <keep-alive>
                    <div v-is="type" ref="menuContent" @event="contentHandle" />
                </keep-alive>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
    import oaHeader from "./oaHeader";
    import assetsManager from "./assetsManager/assetsManager";
    import billManager from "./billManager/billManager";
    import projectManager from "./projectManager/projectManager";
    import talentManager from "./talentManager/talentManager";
    import personalCenter from "./personalCenter/personalCenter";
    import qualityManager from "./qualityManager/qualityManager";
    import financialManager from "./financialManager/financialManager";
    import SystemSetting from "./SystemSetting/SystemSetting";
    import welcome from "./welcome";
    import {computed} from "vue"
    import api from "../assets/api/_default"
    export default {
        name: "mainPage",
        components:{
            oaHeader,assetsManager,billManager,
            projectManager,talentManager,
            personalCenter,qualityManager,welcome,
            financialManager,SystemSetting
        },
        data(){
            return{
                type:"welcome",
                currentLeftMenu:[],
                userName:null,
                menuKey:null, //自动展开的menuKey
                tipVisible:true,
                remainTime:0,
            }
        },
        provide(){
            return{
                subMenus:computed(() => this.currentLeftMenu),
                menuVisible:true,
                menuKey:computed(() => this.menuKey)
            }
        },
        methods:{
            headMenuHandle({type,arg}){
                switch (type) {
                    case "menuClick":{//{type:"menuClick",menu:object}
                        this.type = arg.component;
                        this.currentLeftMenu = arg.children;
                        break;
                    }
                    case "workCheck":{
                        this.menuKey = arg;
                        break;
                    }
                    case "logout":{
                        api.logout.requestPOST(this,null,res=>{
                            if(res.data.flag){
                                localStorage.setItem("password","");
                                this.$router.push({ path: '/' });
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                        break;
                    }
                    default:{
                        this.$emit("event",{type,arg});
                        break;
                    }
                }
            },
            contentHandle({type,arg}){
                switch (type) {
                    case "mounted":{//{type:type,arg:object}

                        break;
                    }
                    default:{
                        this.$emit("event",{type,arg});
                        break;
                    }
                }
            }
        },
        created() {
            // let phone = localStorage.getItem("phone");
            // let password = localStorage.getItem("password");
            // let data = {
            //     phone:phone,
            //     password:password
            // };
            // api.login.requestPOSTUrlParam(this,data,res=>{
            //     this.spinning=false;
            //     if(res.data.flag){
            //         this.$store.commit("initUserInfo",res.data.data);
            //         this.userName = res.data.data.name;
            //         let password = localStorage.getItem("password");
            //         if(password === "000000" || password === "123456"){
            //             this.$router.push({ path: '/resetPassword' });
            //         }
            //         api_item_task_process_employee.returnDate.requestPOST(this,{},res=>{
            //             if(res.data.flag){
            //                 localStorage.setItem("time",res.data.data);
            //             }
            //         })
            //         setInterval(()=>{//读取系统时间
            //             api_item_task_process_employee.returnDate.requestPOST(this,{},res=>{
            //                 if(res.data.flag){
            //                     localStorage.setItem("time",res.data.data);
            //                 }
            //             })
            //         },1000*60*10);
            //     }
            //     else{
            //         this.$router.push({ path: '/' });
            //         this.$message.error(res.data.msg);
            //     }
            // });
        }
    }
</script>

<style scoped>

</style>