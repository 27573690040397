<!--
* @program: office_automation 
* @author: ly
* @component:AuditSetting 
* @description: 资金账户【其他支付】审核流程设定
* @create: 2022-02-17 10:20
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <a-page-header
                    style="border: 1px solid rgb(235, 237, 240)"
                    title="资金账户【其他支付】审核流程设定"
            />
            <a-spin :spinning="spinning">
                <StepSetting style="margin: 10px" :steps="approvalUserName" @leftAdd="leftAdd" @remove="remove" @rightAdd="rightAdd"/>
                <div v-if="approvalUserName.length === 0">
                    还未设定审核步骤
                </div>
                <a-button class="search-item" type="dashed"  v-if="approvalUserName.length === 0" @click="handleAdd">
                    <template #icon><PlusOutlined /></template>
                </a-button>
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定保存吗？"
                        @confirm="handleSave">
                    <a-button style="padding: 0 5px" type="primary">
                        保存
                    </a-button>
                </a-popconfirm>
                <a-button style="padding: 0 5px" type="dashed" @click="init">
                    <template #icon><SyncOutlined /></template>
                    刷新
                </a-button>
            </a-spin>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroy-on-close
             title="选择审核人"
             :maskClosable="false"
             :footer="null">
        <a-select
                style="width: 300px" show-search v-model:value="value"
                placeholder="输入关键字查询"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @select="handleSelect"
                @search="handleSearch"
        >
            <a-select-option v-for="option in options" :key="option.id">
                <a-tooltip :title="option.name">
                    <a-button style="padding: 0" type="text">
                        {{ option.name }}
                    </a-button>
                </a-tooltip>
            </a-select-option>
        </a-select>
    </a-modal>
</template>

<script>
    import {CheckOutlined, PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import StepSetting from "../../_components/StepSetting";
    import otherPayAudit from "../../../assets/api/otherPayAudit";
    import api_sys_user_info from "../../../assets/api/userInfo";

    export default {
        name: "AuditSetting",
        components:{
            PlusOutlined,CheckOutlined,StepSetting,SyncOutlined
        },
        data(){
            return{
                spinning:false,
                value:null,
                addType:"",
                currentIndex:null,
                options:[],
                step:{},
                approvalUsers:[],
                approvalUserName:[],
                visible:false
            }
        },
        methods:{
            init(){
                this.spinning = true;
                otherPayAudit.queryAll.requestPOST(this,{},res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        if(res.data.data.length === 1){
                            this.approvalUsers = res.data.data[0].approvalUsers.split(",");
                            this.approvalUserName = res.data.data[0].approvalUserName?res.data.data[0].approvalUserName:[];
                            this.step= res.data.data[0];
                        }
                    }
                })
            },
            handleSearch(key){
                if(!key) return;
                let data={queryText:key,disableState:"1",loginState:"0"};
                api_sys_user_info.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.options = res.data.data;
                    }
                })
            },
            handleAdd(){
                this.currentIndex = 0;
                this.addType = "left";
                this.visible = true;
                this.value = null;
            },
            handleSelect(){
                this.visible = false;
                let name = this.options.filter(option=>{return option.id === this.value})[0]?.name;
                switch (this.addType) {
                    case "left":{
                        this.approvalUserName.splice(this.currentIndex,0,name);
                        this.approvalUsers.splice(this.currentIndex,0,this.value);
                        break;
                    }
                    case "right":{
                        this.approvalUserName.splice(this.currentIndex+1,0,name);
                        this.approvalUsers.splice(this.currentIndex+1,0,this.value);
                        break;
                    }
                }
            },
            handleSave(){
                let data = {
                    approvalUsers : this.approvalUsers.toString(),
                    stepCount : this.approvalUsers.length
                };
                Object.assign(this.step,data);
                otherPayAudit.saveOrUpd.requestPOST(this,this.step,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            leftAdd(step){
                this.currentIndex = this.approvalUserName.indexOf(step);
                this.addType = "left";
                this.visible = true;
                this.value = null;
            },
            remove(step){
                let index = this.approvalUserName.indexOf(step);
                this.approvalUserName.splice(index,1);
                this.approvalUsers.splice(index,1);
            },
            rightAdd(step){
                this.currentIndex = this.approvalUserName.indexOf(step);
                this.addType = "right";
                this.visible = true;
                this.value = null;
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>