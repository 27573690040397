<!--
* @program: tyh-oa 
* @author: ly
* @description: welcome
* @create: 2021-03-22 18:15
-->
<template>
    <div style="height: 100%;overflow: hidden">
        <div style="font-size: 60px;margin-top: 300px" class="non-select">
            {{$sysName}}
        </div>
        <span style="font-size: 20px;margin: 30px" v-if="substr">{{substr}}</span>
        <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
            版本：{{$version}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "welcome2",
        props:["substr"],
    }
</script>

<style scoped>

</style>