/** 
* 自动生成文件，请勿修改 
* @name: 系统:角色-菜单关联关系模块
* @description: 角色-菜单关联关系模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:4条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const roleMenuRelation = { 
	/** 1 summary:新增-给一个角色授权多个菜单	method:post */ 
	save : new APIObject("/api/roleMenuRelation/save.do"), 
	/** 2 summary:查询-当前用户有权限的所有菜单及菜单下的操作码	method:post */ 
	userMenuOpTree : new APIObject("/api/roleMenuRelation/userMenuOpTree.do"), 
	/** 3 summary:查询-所有菜单及菜单下的操作码	method:post */ 
	allMenuOpTree : new APIObject("/api/roleMenuRelation/allMenuOpTree.do"), 
	/** 4 summary:授权-一次性授权菜单及下属按钮(也可以只授权菜单或菜单操作按钮)	method:post */ 
	authorization : new APIObject("/api/roleMenuRelation/authorization.do"), 
};
export default roleMenuRelation;