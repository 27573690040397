<!--
* @description：人员绩效管理
* @author:rzl
* @date:2022/12/12 10:32
-->

<template>
  <div class="oa-content">
    <div class="oa-window">
      <div  class="search-bar">
        <a-select v-model:value="departmentId" style="width: 200px"
                  class="search-item" @change="init"
                  placeholder="选择部门"
                  allow-clear>
          <a-select-option v-for="{id,fullName} in departments" :key="id" >
            {{fullName}}
          </a-select-option>
        </a-select>
          <div>年度：</div>
          <a-select @select="init"  v-model:value="parms.year" style="width:200px" class="search-item" :options="years"/>
          <div>时间段：{{sDate}}——{{eDate}}</div>
        <a-input  class="search-item"
                  v-model:value="userName" style="width:150px" placeholder="请输入用户姓名" />

        <a-button class="search-item" style="margin-left: 10px" type="dashed" shape="circle" @click="init">
          <template #icon><SearchOutlined /></template>
        </a-button>

        <a-button type="primary" @click="downTemplate" style="margin-left: 10px;background: #faad14;border-color: #faad14;">
          <template #icon><DownloadOutlined title="模板下载"/></template>模板下载
        </a-button>
        <a-button type="primary" @click="importExcel" style="margin-left: 10px;background: #52c41a;border-color: #52c41a;">
          <template #icon><UploadOutlined title="导入数据"/></template>导入数据
        </a-button>
        <a-button type="primary" @click="downFile" style="margin-left: 10px">
          <template #icon><DownloadOutlined title="导出报表"/></template>导出报表
        </a-button>
      </div>
      <TcBaseTable ikey="userId"   :table-columns="tableColumn" :loading="loading" :scroll="{x:1000}"
                     :data-source="dataSource" :view-model="true" :page-size="15" >
        <template #action="{record}">
          <div class="action-column">

            <a-popconfirm
                v-if="!record.lockFlag"
                ok-text="是"
                cancel-text="否"
                class="action-button"
                title="确定锁定吗?"
                @confirm="lockFlags(record)">
              <a-button type="link">
                <template #icon><LockOutlined style="color: #faad14" class="action-button" title="锁定"/></template>
              </a-button>
            </a-popconfirm>

            <a-popconfirm
                v-if="record.lockFlag"
                ok-text="是"
                cancel-text="否"
                class="action-button"
                title="确定解锁吗?"
                @confirm="lockFlags(record)">
              <a-button type="link">
                <template #icon><KeyOutlined style="color: #52c41a"  class="action-button" title="解锁"/></template>
              </a-button>
            </a-popconfirm>
            <a-button v-if="!record.lockFlag" class="action-button" type="link" @click="editDetail(record)">
              <EditFilled class="pointer" title="编辑"/>
            </a-button>
          </div>
        </template>
      </TcBaseTable>
      <div>字段说明：【项目拟下 = ∑项目拟下绩效】【个人合计 = 基本工资 + 绩效工资 + 公积金】【拟下绩效 = 项目拟下 + 二次分配】
        【年终绩效 = 拟下绩效 + 骨干绩效 - 个人合计】【年总收入 = 年终绩效 + 个人合计 + 社保 + 医保】</div>
    </div>
  </div>

  <!--文件信息信息 start-->
  <a-modal v-model:visible="visible_wj"
           :maskClosable="false"
           title="导入员工年度绩效"  width="25%"
           :destroyOnClose="true"
  >

      <div class="wj-jx">
        <a-form-item  label="所属年份：" style="margin-left: 16%;">
          <a-input  class="search-item"
                    v-model:value="parms.year" style="width:200px" placeholder="请输入绩效所属年份" />
        </a-form-item>

        <a-form-item  label="上传附件：" style="margin-left: 16%;">
          <a-upload
              name="file"
              :data='parms'
              :action="actionUrl"
              @change="handleChange"
              :before-upload="beforeUpload"
              :showUploadList="false"
              :accept="accept"
              withCredentials
          >
            <a-button>
              <upload-outlined></upload-outlined>
              文件上传
            </a-button>
          </a-upload>
        </a-form-item>
      </div>

    <template #footer>
      <a-button key="back" @click="visible_wj =false">取消</a-button>
    </template>
  </a-modal>
  <!--文件信息信息 end-->

  <a-modal v-model:visible="visible_show"
           destroyOnClose
           @ok="handleOk"
           ok-text="确认"
           cancel-text="取消"
           title="修改员工年度绩效">
    <ArBaseForm ref="bill_form" :argument="formArg" :view-model="false" >

    </ArBaseForm>
  </a-modal>
</template>

<script>
import TcBaseTable from "../../../components/table/TcBaseTableFilter";
import {DownloadOutlined, SearchOutlined,UploadOutlined,
  EditFilled,LockOutlined,KeyOutlined} from "@ant-design/icons-vue";
import api_sys_department from "../../../assets/api/department";
import userSalary from "../../../assets/api/userSalary";
import userSalary_item from "./config/userSalary_item";
import ArBaseForm from "../../../components/form/ArBaseForm";

export default {
  name: "userPerformanceMange",
  components:{
    TcBaseTable,DownloadOutlined,SearchOutlined,
    UploadOutlined,EditFilled,ArBaseForm,LockOutlined,KeyOutlined
  },
    computed: {
        sDate() {
            return this.parms.year - 1 + "-12"
        },
        eDate() {
            return this.parms.year + "-11"
        },
    },
  data(){
    return{
      departments:[],
      departmentId:null,
      tableColumn:new userSalary_item().getTableColumns(),
      dataSource:[],
      loading:false,
        years:[
            {title:2021,value:2021},
            {title:2022,value:2022},
            {title:2023,value:2023},
            {title:2024,value:2024},
        ],
      userName:null,
      lockFlag:null,
      op_lockFlag:[
        {value: true,label: '锁定',},
        {value: false,label: '未锁定',},
      ],

      dateFormat: 'YYYY-MM',

      visible_wj:false,
      accept:".xls",//Vimage/png,image/jpeg
      file:null,
      previewVisible:false,
      actionUrl:userSalary.importExcel.url,
      baseImageUrl:"d:",
      parms:{
        year:null,
      },

      visible_show:false,
      formArg:{
        formColumns:new userSalary_item().getFormColumns(),
        formData:null,
      },

    }
  },
  created() {
    var date = new Date();
    this.parms.year = date.getFullYear()-1;
    this.depart();
    this.init();
  },
  methods:{
    depart(){
      api_sys_department.queryAll.requestPOST(this,{},res=>{
        if(res.data.flag){
          this.departments=this.departments.concat(res.data.data);
        }
        else{
          this.$message.error(res.data.msg);
        }
      });

    },
    init() {
      this.dataSource = [];
      let parms = {
        depId:this.departmentId,
        userName:this.userName,
        year:this.parms.year,
        lockFlag:this.lockFlag,
          startMonth:this.sDate,
          endMonth:this.eDate,
      };
      this.loading = true;
      userSalary.personReport.requestPOST(this,parms,res=>{
        this.loading = false;
        if(res.data.flag){
            this.dataSource = res.data.data.filter(item=>item.userId !== "2");

            //个人合计
            for(let record of this.dataSource){
                record.sum3 = record.basicSalary + record.jixiao + record.gjj
                record.sum2 = record.calConMoney + record.ecfp
            }


        }
        else{
          this.dataSource = [];
          this.$message.error(res.data.msg);
        }
      });
    },
    lockFlags(record){
      record.lockFlag = !record.lockFlag;
      userSalary.saveOrUpdate.requestPOST(this,record,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          //this.init();
        }
        else{
          this.$message.error(res.data.msg);
        }
      });
    },
    editDetail(record){
        this.visible_show = true;
        this.formArg.formData = record;
    },
    handleOk(){
      let data = this.$refs.bill_form.getSource();
      data.yxjx = data.calBeforeMoney;
      data.nxjx = data.calConMoney;
      data.nzjx = data.calYearMoney;
      data.ndzsr = data.calYearSumMoney;
      userSalary.saveOrUpdate.requestPOST(this,data,res=>{
        if(res.data.flag){
          this.visible_show = false;
          this.init();
        }
        else{
          this.$message.error(res.data.msg);
        }
      });
    },
    downTemplate(){
      userSalary.exportExcel.fileDownloadGet(this, {},"员工年终绩效计算汇总表模板.xls" );
    },
    importExcel(){
      this.visible_wj = true;
    },
    downFile(){
      this.loadingVisible = true;
      let parms = {
        depId:this.departmentId,
        userName:this.userName,
        year:this.parms.year,
        lockFlag:this.lockFlag,
        startMonth:this.sDate,
        endMonth:this.eDate,
      };
      let fileName = this.parms.year == null || this.parms.year == undefined || this.parms.year == '' ? "员工年终绩效计算汇总表.xls" : this.parms.year+"员工年终绩效计算汇总表.xls";
      userSalary.personExport.fileDownloadPost(this, parms,fileName );
      setTimeout(()=>{
        this.loadingVisible = false;
      },1000);
    },

    beforeUpload(){
      if(this.parms.year == null){
        this.$message.info("请先输入绩效所属年份！");
        return false;
      }
      return true;
    },
    handleChange({file,fileList,event}){
      if(file.status === "uploading"){ //status: "uploading"
        this.loading = true;
        console.log(1)
      }
      else if(file.status==="done"){//status: "done"
        let flag = file.response.flag;
        this.loading = false;
        if(flag){
          this.visible_wj = false;
          this.init();
          this.$message.success(file.response.msg);
        }else{
          this.$message.error(file.response.msg);
        }

      }
    },

  }
}
</script>

<style scoped>
:global(.wj-jx .ant-form-item-control-input-content){
  flex: inherit;
}


</style>