<!--
* @program: office_automation 
* @author: ly
* @component:UserRoleSetting
* @description: 用户角色设定
* @create: 2022-03-07 10:30
-->
<template>
    <div v-if="!viewModel">
        <a-page-header style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;">
            <template #title>
                角色设定
                <a-button @click="init" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
            </template>
        </a-page-header>
        <a-spin :spinning="spinning">
            <a-checkable-tag style="font-size: 16px;margin: 5px" v-for="(item,idx) in allRoles" :key="idx.toString()"
                              v-model:checked="item.checked">{{item.name}}</a-checkable-tag>
        </a-spin>
        <a-divider />
        <a-button type="primary" @click="saveData">
            提交
        </a-button>
    </div>
    <div v-else>
        <a-spin :spinning="spinning">
            <a-tag style="font-size: 16px;margin: 5px" v-for="(item,idx) in currentRoles" :key="idx.toString()"
                          :color="tagColor(item.roleName)"   >{{item.roleName}}</a-tag>
        </a-spin>
    </div>
</template>

<script>
    import role from "../../../assets/api/role";
    import api_sys_user_role_relation from "../../../assets/api/userRoleRelation";
    import {getTagColor} from "../../../assets/system/tagColors";
    import {SyncOutlined} from "@ant-design/icons-vue"
    export default {
        name: "UserRoleSetting",
        props:["userId","viewModel","roles"],
        components:{
            SyncOutlined
        },
        data() {
            return {
                allRoles:[],
                currentRoles:[],
                spinning:false
            }
        },
        watch:{
            userId(){
                this.init()
            }
        },
        methods: {
            tagColor(name){
                return getTagColor(name);
            },
            init() {
                for(let i in this.allRoles){
                    this.allRoles[i].checked = false;
                }
                this.spinning = true;
                let data = {userId:this.userId};
                api_sys_user_role_relation.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        this.currentRoles = res.data.data;//{id:xx,roleId:xx,roleName:xx};
                        this.allRoles.forEach((item)=>{
                            for(let i in this.currentRoles){
                                if(item.id === this.currentRoles[i].roleId){
                                    item.checked = true;
                                }
                            }
                        })
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(){
                let data = [];
                let roles = this.allRoles.filter(item=>item.checked);
                for(let i in roles){
                    data.push({roleId:roles[i].id,userId:this.userId})
                }
                if(data.length > 1){
                    api_sys_user_role_relation.batchBinding.requestPOST(this,{userRoleRelationVOList:[data[0]]},res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            data.shift();
                            for(let i in data){
                                api_sys_user_role_relation.saveOrUpd.requestPOST(this,data[i],res=>{
                                    if(res.data.flag){
                                        this.$message.success(res.data.msg);
                                    }
                                    else{
                                        this.$message.error(res.data.msg);
                                    }
                                })
                            }
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                else{
                    api_sys_user_role_relation.batchBinding.requestPOST(this,{userRoleRelationVOList:data},res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }

            }
        },
        created() {
            if(this.roles){
                this.allRoles = [...this.roles];
                this.init()
            }
            else{
                let data = {
                    roleState:"1"
                };
                role.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.allRoles = res.data.data.filter(role=>role.name !=="超级管理员");
                        this.init()
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>