<!--
* @program: office_automation 
* @author: ly
* @component:outflow 
* @description: 项目外协支付
* @create: 2022-01-25 11:06
-->
<template>
    <div class="center-flex">
        <span class="statistic-label"> 合计：{{summary}} 元</span>
        <MonthMoneySum :start-date="record.balanceDate" :records="argument.dataSource" date-seg="payTime" sum-seg="payAmount" />
    </div>
    <!--项目付款申请 -->
    <div style="height:600px">
    <ArScrollTable :scrolls="{x:800,y:600}"   :page-size="15" :argument="argument" :view-model="true">
        <template #action="{index,record}">
            <div class="center-flex">
                <a-button @click="showDetail(index,record)"
                          class="action-button" type="link">
                    <SearchOutlined class="pointer"/>
                </a-button>
            </div>
        </template>
    </ArScrollTable>
    </div>
    <a-modal v-model:visible="visible2"
             :width="1200"
             destroy-on-close
             title="详情"
             :maskClosable="vModel"
             :footer="null">
        <ArBaseForm :argument="formArg" :view-model="vModel" />
        <a-divider >审核状态</a-divider>
        <projectOutSourcePayAuditStatus :record="formArg.formData" />
    </a-modal>
</template>

<script>
    import ArScrollTable from "../../../components/table/ArScrollTable";
    import { SearchOutlined} from "@ant-design/icons-vue";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import projectOutSourcePayAuditStatus from "../../projectManager/projectRelateInfo/projectOutSourcePayAuditStatus";
    import item_outsourc_pay_apply from "../../../assets/tables/item_outsourc_pay_apply";
    import payApply from "../../../assets/api/payApply";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {companyColors, companyIds, companyNames} from "../../../assets/system/companies";
    import MonthMoneySum from "./MonthMoneySum";
    import moment from "moment";

    export default {
        name: "outflow",
        props:["record","value"],
        components:{
            ArScrollTable,SearchOutlined,
            ArBaseForm,projectOutSourcePayAuditStatus,MonthMoneySum
        },
        watch:{
            record(){
                this.init()
            }
        },
        computed:{
            summary(){
                let sum=0;
                for(let i in this.argument.dataSource){
                    if(this.argument.dataSource[i].payState === "1"){
                        let date = this.argument.dataSource[i].payTime;
                        if(moment(date).isBefore(moment(this.record.balanceDate))){
                            continue
                        }
                        sum += this.argument.dataSource[i].payAmount*100;
                    }
                }
                return sum/100;
            }
        },
        data(){
            return{
                table1:new item_outsourc_pay_apply(),
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                    currentIndex:null, //当前选中的条目
                },
                formArg:{
                    formColumns:[],
                    formData:null
                },
                formData:null,
                visible:false,
                visible2:false,
                vModel:true,
                visible3:false,
                date:null,//支付日期
                cardId:null,//支付银行卡号
                cardOptions:[],
            }
        },
        methods:{
            init(){
                this.visible = false;
                this.argument.loading = true;
                let data = {
                    bankId: this.record.id,
                    payState:"1"
                };
                payApply.queryAll.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                        this.$emit("update:value",{value:this.summary,loading:false});
                    }
                    else{
                        this.argument.dataSource = [];
                        this.$emit("update:value",{value:0,loading:false});
                    }
                })
            },
            showDetail(index,record){
                this.argument.currentIndex = index;
                this.formArg.formData = record;
                this.formArg.formColumns = this.table1.getFormColumns();
                this.visible2 = true;
                this.vModel = true;
            }
        },
        created() {
            this.argument.tableColumns = [
                new Column("序号","_index",ColumnType.Index).setTableView(60),
                new Column("项目名称","itemName",ColumnType.String).setTableView(150),
                new Column("支付时间","payTime",ColumnType.Date).setTableView(80),
                new Column("付款合同名称","contractName",ColumnType.String,true).setTableView(120),
                new Column("收款单位/个人","outSourceName",ColumnType.String,true).setTableView(120),
                new Column("申请支付金额","payAmount",ColumnType.Number,true).setDefaultValue("0").setTableView(80),
                new Column("付款公司", "payCompanyId", ColumnType.Enum, true).setTableView(150)
                    .setEnum(companyIds, companyNames,companyColors),
                new Column("付款部门", "applyDepartName", ColumnType.String, true).setTableView(100),
                //new Column("支付备注","remarks",ColumnType.String,true).setTableView(100),
                new Column("支付描述","payReason",ColumnType.String,true).setTableView(80),
                new Column("操作","actions","actions").setTableView(80)
            ];
            this.init();
        }
    }
</script>

<style scoped>

</style>