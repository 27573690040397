<!--
* @program: tyh-oa 
* @author: ly
* @component:billStepsSettings 账单审核步骤设定
* @description: 
* @create: 2021-04-22 16:06
-->
<template>
    <div style="display: flex;flex-wrap: wrap;justify-content: center;">
        <div style="display: flex;width: 100%;justify-content: center;" >
            <div style="margin: 10px">选择部门</div>
            <a-select v-model:value="department" style="width: 250px"
                      class="search-item"
                      @change="departmentSelect">
                <a-select-option v-for="item in departments"  :key="item.id">
                    {{item.fullName}}
                </a-select-option>
            </a-select>
            <a-input class="search-item" @change="searchData" style="width:150px" v-model:value="queryText" placeholder="请输入名字"/>
            <a-button class="search-item" type="dashed"  @click="groupSet">
                批量设定
            </a-button>
            <a-button class="search-item" type="dashed"  @click="init">
                刷新
            </a-button>
        </div>
        <div style="width: 500px;height: 700px;">
            <ArSelectTable   :argument="argument" :view-model="true" :page-size="200" @selectChange="handleSelectChange">
                <template #action="{record}">
                    <div style="text-align: center">
                        <a-button class="action-button" type="link" @click="showDetail(record)">
                            <OrderedListOutlined class="pointer"/>
                        </a-button>
                        <a-tooltip  title="切换项目报账模式">
                            <a-button class="action-button" type="link" @click="changeBillType(record)">
                                <retweet-outlined  class="pointer"/>
                            </a-button>
                        </a-tooltip>
                        <a-button class="action-button" type="link" @click="editDetail(record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                    </div>
                </template>
            </ArSelectTable>
        </div>
        <a-modal :title="modalTitle"
                 :visible="modalVisible"
                 :footer="null"
                 @cancel="modalVisible=false"
                 :maskClosable="closable"
                 destroyOnClose
        >
            <div v-is="modalComponent" />
        </a-modal>
    </div>
</template>

<script>
    import billStepsAdd from "./billStepsAdd";
    import billStepsViewer from "./billStepsViewer";
    import sys_department from "../../../assets/tables/sys_department";
    import sys_user_info from "../../../assets/tables/sys_user_info";
    import api_sys_department from  "../../../assets/api/department"
    import api_sys_department_relation from  "../../../assets/api/userDepartRelation"
    import api_sys_user_info from "../../../assets/api/userInfo"
    import api_processAudit from "../../../assets/api/processAudit"
    import sys_user_info_2 from "../../../assets/tables/sys_user_info_2";
    import ArSelectTable from "../../../components/table/ArSelectTable";
    import {computed} from "vue";
    import {OrderedListOutlined,EditFilled,RetweetOutlined} from "@ant-design/icons-vue"
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    export default {
        name: "billStepsSettings",
        components:{
            billStepsAdd,billStepsViewer,
            ArSelectTable,OrderedListOutlined,EditFilled,RetweetOutlined
        },
        computed:{
            closable(){
                return this.modalTitle==='报账流程一览'
            }
        },
        provide(){
            return{
                selectedUsers:computed(()=>this.selectedUsers),
                currentSteps:computed(()=>this.currentSteps)
            }
        },
        data(){
            return{
                model:null,
                models:["按部门","按用户"],
                argument:{
                    dataSource:[],//提供table的DataSource
                    tableColumns:[], //提供table的字-段集
                    loading:false,//table的loading状态
                },
                departmentTable:new sys_department(),
                userTable:new sys_user_info(),
                userTable2:new sys_user_info_2(),
                modalVisible:false,
                modalTitle:null,
                modalComponent:"",
                departments:[],
                department:null,
                selectedUsers:[],
                currentSteps:[],//当前用户的审批流程
                queryText:null
            }
        },
        methods:{
            init(){
                this.department = null;
                this.queryText = null;
                this.argument.tableColumns = [
                    new Column("姓名","name",ColumnType.String,true).setTableView(100),
                    new Column("报账模式","allItemFlag",ColumnType.Boolean,true)
                        .setTableView(200).setBoolTags(["允许部门项目报账","允许所有项目报账"]),
                    new Column("操作","actions","actions",false).setTableView(120)
                ];
                this.searchData();
            },
            searchData(){
                this.argument.loading = true;
                api_sys_user_info.queryAll.requestPOSTUrlParam(this,{queryText:this.queryText,disableState:"1",loginState:"0"},res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                    this.argument.loading=false;
                });
            },
            showDetail(record){
                this.currentSteps=[];
                this.modalVisible = true;
                this.modalTitle = "报账流程一览";
                this.modalComponent = "billStepsViewer";
                let data;
                if(Object.prototype.hasOwnProperty.call(record,"userId")){
                    data = {id:record.userId};
                }
                else{
                    data = {id:record.id};
                }
                api_processAudit.queryOne.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.currentSteps = res.data.data.approvalUserName;
                    }
                });
            },
            editDetail(record){
                this.modalVisible = true;
                this.modalTitle = "报账流程编辑";
                this.modalComponent = "billStepsAdd";
                this.selectedUsers = [record];
            },
            departmentSelect(){
                let param  ={
                    departmentId:this.department,
                    disableState:"1",
                    haveLevel:"0"
                };
                this.argument.loading = true;
                this.argument.dataSource=[];
                api_sys_department_relation.queryAll.requestPOSTUrlParam(this,param,res=>{
                    this.argument.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        this.argument.dataSource = res.data.data;
                        this.argument.tableColumns = [
                            new Column("姓名","userName",ColumnType.String).setTableView(100),
                            new Column("报账模式","allItemFlag",ColumnType.Boolean,true)
                                .setTableView(200).setBoolTags(["允许部门项目报账","允许所有项目报账"]),
                            new Column("操作","actions","actions",false).setTableView(120)
                        ];
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handleSelectChange(selectedRowKeys,selectedRows){
                this.selectedUsers = selectedRows;
            },
            groupSet(){
                if(this.selectedUsers.length>0){
                    this.modalVisible = true;
                    this.modalTitle = "报账流程编辑";
                    this.modalComponent = "billStepsAdd";
                }
                else{
                    this.$message.error("请选择用户")
                }
            },
            changeBillType(record){
                let data = {
                    id:record.userId?record.userId:record.id,
                    allItemFlag:!record.allItemFlag
                };
                api_sys_user_info.allItemFlag.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        record.allItemFlag = !record.allItemFlag;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init();
            api_sys_department.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.departments = res.data.data;
                }
            });
        }
    }
</script>

<style scoped>

</style>