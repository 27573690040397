<!--
* @program: tyh-oa
* @author: ly
* @component:projectTabs
* @description:
* @create: 2021-04-07 09:51
-->
<template>
    <div class="tab-container" >
        <a-tabs @change="handleChange"  v-if="local_visible" v-model:activeKey="activeKey" :size="size">
            <a-tab-pane  v-for="(pane,index) in paneList" :key="index.toString()" :tab="pane.tag" >
                <div style="height: 100%;overflow-y: auto"
                     :ref="pane.component"
                     v-is="pane.component"
                     :viewModel="pane.viewModel"
                     :projectId="pane.projectId"
                     :record="pane.record"
                     :contractInfo="contractInfo"
                     @contractInfo="getContractInfo"
                     @refresh="refresh" />
            </a-tab-pane>
        </a-tabs>
        <div v-else style="font-size: 40px;margin: 20vh" class="non-select">
            欢迎使用{{$sysName}}
            <div style="position: absolute;bottom: 20px;right: 100px;font-size: 16px" class="non-select">
                版本：{{$version}}
            </div>
        </div>
    </div>
</template>

<script>
    import baseTabs from "../../../components/tabs/baseTabs";
    import projectIncome from "../projectRelateInfo/projectIncome";
    import projectUseUp from "../projectRelateInfo/projectUseUp";
    import projectContract from "../projectRelateInfo/projectContract";
    import projectOutSourcePay from "../projectRelateInfo/projectOutSourcePay";
    import projectTaskInfo from "../projectTaskViewer/projectTaskInfo";
    import taskProcessList from "../departmentTaskViewer/taskProcessList";
    import qualityCheckResult from "../departmentTaskViewer/qualityCheckResult";
    import qualityUserScoreView from "../departmentTaskViewer/qualityUserScoreView";
    import qualityUserScore from "../departmentTaskViewer/qualityUserScore";
    import projectInfo from "./projectInfo";
    import departmentPayApply from "../departmentTaskViewer/departmentPayApply";
    import departmentArrangement from "../departmentTaskViewer/departmentArrangement";
    import projectDelayHistory from "./projectDelayHistory";
    import projectInvoice from "../projectRelateInfo/projectInvoice";
    import projectInvoicePayoff from "../projectRelateInfo/projectInvoicePayoff";
    import projectTaskInfoPart from "../projectTaskViewer/projectTaskInfoPart";
    import MonthArrange from "../../personalCenter/TaskLeaderTool/MonthArrange";
    import ProjectSealApply from "../ProjectSealApply/ProjectSealApply";
    import QCManager from "../QCManager/QCManager";
    import projectDesignBook from "../projectRelateInfo/projectDesignBook";
    import projectOtherInfoList from "../projectRelateInfo/projectOtherInfoList";

    import MonthProcessArrange3 from "../departmentTaskViewer/MonthProcessArrange3";// 历史
    import MonthProcessArrange from "../departmentTaskViewer/MonthProcessArrange";

    import departPayment from "../departPayment/departPayment";// 兑付记录
    import YearMoneyArrange from "../departmentTaskViewer/YearMoneyArrange"; //年度个人绩效分配
    import YearMoneyArrangePer from "../../personalCenter/TaskLeaderTool/YearMoneyArrangePer"; //年度绩效分配--任务负责人使用

    import weeksReport from "../../personalCenter/weeks/weeksReport";

    export default {
        name: "projectTabs",
        mixins:[baseTabs],
        emits:["refresh"],
        components:{
            projectIncome,projectUseUp,projectOtherInfoList,
            projectContract,projectOutSourcePay,taskProcessList,qualityCheckResult,
            qualityUserScoreView,qualityUserScore,projectTaskInfo,projectInfo,
            departmentPayApply,departmentArrangement,projectDelayHistory,
            projectInvoice,projectInvoicePayoff,projectTaskInfoPart,MonthProcessArrange,
            MonthArrange,ProjectSealApply,QCManager,

          projectDesignBook,weeksReport,
          MonthProcessArrange3,departPayment,YearMoneyArrange,YearMoneyArrangePer
        },
        data(){
            return{
                projectId:null,
                drawerVisible:true,
                contractInfo:null //甲方合同信息
            }
        },
        methods:{
            getContractInfo(contractInfo){
                this.contractInfo = contractInfo;
            },
            refresh(){
                this.$emit("refresh");
            }
        }
    }
</script>

<style scoped>

</style>
