<!--
* @program: office_automation 
* @author: ly
* @component:typeFinancialSubject 
* @description: 
* @create: 2022-02-14 10:58
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center" >{{categoryFullName}}</div>
    </div>
    <div v-else>
        <a-spin :spinning="spinning"  v-if="editable">
            <a-tree-select
                    v-model:value="record[column.dataIndex]"
                    style="width: 100%"
                    show-search
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    placeholder="输入关键字查询"
                    :replaceFields="replaceFields"
                    :treeData="treeData"
                    :treeNodeFilterProp="'categoryName'"
                    @change="columnTrigger"
                    tree-default-expand-all
            >

            </a-tree-select>
        </a-spin>
        <div style="text-align: center" v-else >{{categoryFullName}}</div>
    </div>
<!--    <a-spin :spinning="spinning" >-->
<!--        <a-tree-select-->
<!--                v-model:value="record[column.dataIndex]"-->
<!--                style="width: 100%"-->
<!--                :disabled="viewModel"-->
<!--                show-search-->
<!--                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"-->
<!--                placeholder="输入关键字查询"-->
<!--                :replaceFields="replaceFields"-->
<!--                :treeData="treeData"-->
<!--                :treeNodeFilterProp="'categoryName'"-->
<!--                @change="columnTrigger"-->
<!--                tree-default-expand-all-->
<!--        >-->
<!--        </a-tree-select>-->
<!--    </a-spin>-->
</template>

<script>
    import typeBase from "./typeBase";
    import api_category_role from "../../assets/api/categoryRole";

    export default {
        name: "typeFinancialSubject",
        mixins:[typeBase],
        computed:{
            categoryFullName(){
                if(this.record["categoryName"]){
                    return this.record["categoryName"];
                }
                else{
                    return "";
                }
            }
        },
        data(){
            return{
                replaceFields :{
                    title: 'categoryFullName',
                    key:"id",
                    value:"id",
                },
                treeData:[],
                spinning:false,
            }
        },
        methods:{
            categoryInit(){
                this.spinning = true;
                let data = {userId:this.$store.getters.userInfo.id};
                api_category_role.queryCategoryByUser.requestPOSTUrlParam(this,data,res=>{
                    this.spinning =false;
                    if(res.data.flag){
                        this.treeData = res.data.data;
                        let i = 0;
                        while (i < this.treeData.length){//把除了收入类之外的类型删除
                          // || this.treeData[i].categoryName.indexOf("其他资金中转") !== -1
                            if(this.treeData[i].categoryName.indexOf("收入类") !== -1 ){
                                this.treeData[i]["selectable"]=false;
                                let pName = this.treeData[i]["categoryName"];
                                this.treeData[i]["categoryFullName"]=pName;
                                if(this.treeData[i].children && this.treeData[i].children.length>0){
                                    for(let j in this.treeData[i].children){
                                        this.treeData[i].children[j]["categoryFullName"] = pName +" - "+ this.treeData[i].children[j]["categoryName"];
                                    }
                                }
                                i++
                            }
                            else{
                                this.treeData.splice(i,1);
                            }
                        }
                    }
                })
            },
        },
        created() {
            this.categoryInit();
        }
    }
</script>

<style scoped>

</style>