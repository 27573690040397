<!--
* @program: tyh-oa
* @author: ly
* @component:baseTabs
* @description:
* @create: 2021-04-08 11:42
-->
<template>
    <a-tabs @change="handleChange" v-if="local_visible"  v-model:activeKey="activeKey" :size="size">
        <a-tab-pane v-for="(pane,index) in paneList" :key="index.toString()" :tab="pane.tag" >
            <div :ref="pane.component" v-is="pane.component" @event="eventHandle" />
        </a-tab-pane>
    </a-tabs>
</template>

<script>
    import emitDataCheck from "../emitDataCheck";
    export default {
        name: "baseTabs",
        mixins:[emitDataCheck],
        props:["viewModel"],
        data(){
            return{
                local_visible:false,
                paneList:[],
                activeKey:"0",
                size:"small",
                tabArg:null
            }
        },
        methods:{
            handleOpen(paneList){
                this.activeKey = "0";
                if(this.local_visible){
                    this.local_visible = false;
                    setTimeout(()=>{
                        this.local_visible = true;
                        this.paneList=paneList;
                    },100)
                }
                else{
                    this.local_visible = true;
                    this.paneList=paneList;
                }
            },
            eventHandle(data){
                this.$emit("event",data);
            },
            handleChange(activeKey){
                let index = parseInt(activeKey);
                let tabComponent = this.paneList[index].component;
                if(this.$refs[tabComponent] && this.$refs[tabComponent].init){
                    this.$refs[tabComponent].init()
                }
            }
        }
    }
</script>

<style scoped>

</style>
