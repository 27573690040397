<!--
* @program: office_automation 
* @author: ly
* @component:invoiceSelector 
* @description: 
* @create: 2021-12-17 15:38
-->
<template>
    <div class="center-flex">
        <a-select
                v-if="!viewModel"
                style="width: 250px"
                v-model:value="LId"
                @select="handleSelect"
        >
            <a-select-option v-for="option in selectOptions" :key="option.id">
                <a-tooltip :title="getTitle(option)">
                    <a-button style="padding: 0" type="text">
                        开票金额:{{ option.invoiceAmount}}
                        开票日期:{{ new Date(option.invoiceTime).toLocaleDateString()}}
                    </a-button>
                </a-tooltip>
            </a-select-option>
        </a-select>
    </div>
    <a-popover v-if="visible" trigger="hover" placement="bottom">
        <template #content>
            <div>收款方：{{partyInfo?partyInfo.name:"未查询到相关数据"}}</div>
            <div>项目：{{itemInfo?itemInfo.name:"未查询到相关数据"}}</div>
            <div>发票标题：{{invoiceTitle}}</div>
            <div>发票金额：{{invoiceAmount}}</div>
            <div>发票号：{{invoiceNumber}}</div>
            <div>发票代码：{{invoiceCode}}</div>
        </template>
        <a-button  type="link" >
            发票相关信息
        </a-button>
    </a-popover>
</template>

<script>
    import invoiceInto from "../../../assets/api/invoiceInto";
    export default {
        name: "invoiceSelector",
        props:["viewModel","invoiceId","projectId"],
        emits:["update:invoiceId","refresh"],
        data(){
            return{
                LId:null,
                queryData:{
                    "checkCode": "",
                    "collectState": "",
                    "companyId": "",
                    "flushRed": null,
                    "invoiceCode": "",
                    "invoiceNumber": "",
                    "invoiceTitle": "",
                    "invoiceType": "",
                    "itemId": "",
                    "partyId": ""
                },
                options:[],
                selectOptions:[],
                partyInfo:{}, //收款方
                itemInfo:{},//项目信息
                invoiceAmount:null,//发票金额
                invoiceTitle:null,//发票标题
                invoiceNumber:null,//发票号
                invoiceCode:null,//发票代码
                visible:false,
            }
        },

        methods:{
            handleSelect(id){
                this.$emit("update:invoiceId",this.LId);
                let data = {id:id};
                invoiceInto.queryById.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.visible = true;
                        this.setData(res.data.data);
                    }
                })
            },
            setData(data){
                let {partyInfo,itemInfo,invoiceAmount,invoiceCode,invoiceNumber,invoiceTitle} = data;
                this.partyInfo = partyInfo;
                this.itemInfo = itemInfo;
                this.invoiceAmount = invoiceAmount;
                this.invoiceCode = invoiceCode;
                this.invoiceNumber = invoiceNumber;
                this.invoiceTitle = invoiceTitle;
            },
            getTitle(option){
                return "开票金额:"+ option.invoiceAmount + "\n开票日期:"+ new Date(option.invoiceTime).toLocaleDateString();
            }
        },
        created() {
            this.LId = this.invoiceId;
            let data = {itemId:this.projectId};
            invoiceInto.queryAll.requestPOST(this,data,res=>{
                if(res.data.flag){
                    this.selectOptions = res.data.data;
                }
                else{
                    this.selectOptions =[];
                }
            });
            if(this.invoiceId){
                let data = {id:this.invoiceId};
                invoiceInto.queryById.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.visible = true;
                        this.defaultOptions = [res.data.data];
                        this.setData(res.data.data);
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>