<!--
* @program: office_automation 
* @author: ly
* @component:DataProductsViewer 
* @description: 数据成果一览
* @create: 2022-05-30 11:52
-->
<template>
    <div id="map" ref="rootmap" class="divTdtMap">
        <!-- 鼠标位置 -->
        <div id="mouse-position"></div>
        <!-- 查询条件 -->
        <map-tool-bar ref="mapToolBar" @event="mapQuery" />
        <!-- 查询信息list start-->
        <map-info_list ref="InfoList" @event="mapQuery"/>
        <!-- 查询信息list end-->

        <div class="ant-row layer_mange" >
            <div class="dtys" @click="handleSwitchMap('vector')">
                <div>
                    <img src="../../../assets/image/map/dt.png" class="layer_img">
                </div>
                <div class="dtys_name">地图</div>
            </div>
            <div class="dtys" @click="handleSwitchMap('image')">
                <div>
                    <img src="../../../assets/image/map/yx.png" class="layer_img">
                </div>
                <div class="dtys_name">卫星</div>
            </div>
            <div class="dtys" @click="handleSwitchMap('terrain')">
                <div>
                    <img src="../../../assets/image/map/dx.png" class="layer_img">
                </div>
                <div class="dtys_name">地形</div>
            </div>
        </div>

    </div>
</template>

<script>
    import "./config/ol.css";
    import Map from 'ol/Map'
    import View from 'ol/View'
    import {OSM,Vector as VectorSource,XYZ,WMTS,ImageWMS} from 'ol/source'
    import {Tile as TileLayer,Vector as VectorLayer,Image} from 'ol/layer'
    import {ScaleLine,MousePosition,OverviewMap} from 'ol/control'
    import Feature from 'ol/Feature'
    import {fromLonLat,get as getProjection,Projection,transform} from 'ol/proj'
    import {createStringXY} from 'ol/coordinate'
    import Draw from 'ol/interaction/Draw';
    import {Style,Fill,Stroke,Circle as CircleStyle} from 'ol/style'
    import Overlay from 'ol/Overlay'
    import { LineString, Point, Polygon, MultiPolygon } from "ol/geom";
    import {unByKey} from 'ol/Observable';
    import {getWidth,getTopLeft,getCenter} from 'ol/extent'
    import WMTSTileGrid from 'ol/tilegrid/WMTS'
    import WKT from 'ol/format/WKT';

    import map_api from "./config/map_api";
    import mapToolBar from "./components/mapToolBar";
    import mapInfo_list from "./point/mapInfo_list";
    import achieveRecord_api from "./config/achieveRecord_api";

    export default {
        name: "DataProductsViewer",
        components:{
            mapToolBar,mapInfo_list
        },
        data() {
            return {
                map:null,// 地图容器
                layer_callout:null,// 标注
                layer_vector:null,// 矢量
                layer_image:null,// 影像
                layer_terrain:null,// 地形

                geom:null,
                layer_list:[],// 图层
                layer_dr:[],// 导入图层
                layers:[],

                /** 测量 **/
                vector_draw:null,
                draw:null,// 定义一个交互式绘图对象
                listener:null,//定义一个事件监听
                count:0, //定义一个控制鼠标点击次数的变量

                helpTooltipElement:null,//创建一个帮助提示框对象
                helpTooltip:null,//创建一个帮助提示信息对象
                measureTooltipElement:null,//创建一个测量提示框对象
                measureTooltip:null,//创建一个测量提示信息对象
                continuePolygonMsg:null,//继续绘制多边形的提示信息
                continueLineMsg:null,//继续绘制线段的提示信息
                /** 测量 **/

            }
        },
        mounted() {
            this.initMap()
        },
        methods: {
            /** 初始化地图 **/
            initMap(){
                var projection = getProjection('EPSG:4326');// 4326
                var projectionExtent = projection.getExtent();
                var size = getWidth(projectionExtent) / 256;
                var resolutions = new Array(18);
                var matrixIds = new Array(18);
                for(var z=1;z<19;++z){
                    resolutions[z]=size/Math.pow(2,z);
                    matrixIds[z]=z;
                }

                // 视图
                var view = new View({
                    center: fromLonLat([106.6265,26.6531]),//106.6472,26.4332
                    zoom:7
                });
                this.layer_callout = new TileLayer({
                    name: "天地图注记图层",
                    source: new WMTS({
                        url:map_api.wmtsUrl_bz,
                        layer:'cva',
                        matrixSet:'c',
                        format:'tiles',
                        style:'default',
                        projection:projection,
                        tileGrid:new WMTSTileGrid({
                            origin:getTopLeft(projectionExtent),
                            resolutions:resolutions,
                            matrixIds:matrixIds
                        }),
                        wrapX:true
                    })
                });
                this.layer_vector = new TileLayer({
                    name: "天地图矢量图层",
                    opacity:0.7,
                    source:new WMTS({
                        url:map_api.wmtsUrl_sl,
                        layer:'vec',
                        matrixSet:'c',
                        format:'tiles',
                        style:'default',
                        projection:projection,
                        tileGrid:new WMTSTileGrid({
                            origin:getTopLeft(projectionExtent),
                            resolutions:resolutions,
                            matrixIds:matrixIds
                        }),
                        wrapX:true
                    })
                });
                this.layer_image = new TileLayer({
                    name: "天地图影像图层",
                    opacity:0.7,
                    source:new WMTS({
                        url:map_api.wmtsUrl_yx,
                        layer:'img',
                        matrixSet:'c',
                        format:'tiles',
                        style:'default',
                        projection:projection,
                        tileGrid:new WMTSTileGrid({
                            origin:getTopLeft(projectionExtent),
                            resolutions:resolutions,
                            matrixIds:matrixIds
                        }),
                        wrapX:true
                    }),
                    visible:false,
                });
                this.layer_terrain = new TileLayer({
                    name: "天地图地形图层",
                    opacity:0.7,
                    source:new WMTS({
                        url:map_api.wmtsUrl_dx,
                        layer:'ter',
                        matrixSet:'c',
                        format:'tiles',
                        style:'default',
                        projection:projection,
                        tileGrid:new WMTSTileGrid({
                            origin:getTopLeft(projectionExtent),
                            resolutions:resolutions,
                            matrixIds:matrixIds
                        }),
                        wrapX:true
                    }),
                    visible:false,
                });

                // 地图
                this.map = new Map({
                    target:'map',
                    layers:[
                        this.layer_vector,
                        this.layer_image,
                        this.layer_terrain,
                        this.layer_callout,
                    ],
                    /**
                     * 让地图视图拥有动画效果：关键点loadTilesWhileAnimating、vie.animate;最基本效果：移动、旋转、缩放
                     * */
                    loadTilesWhileAnimating: true,//将这个设置为true，默认为false
                    view:view
                });


                //添加比例尺控件
                this.add_scaleLine();

                //实例化鼠标位置控件
                this.add_mousePosition();
            },
            /** 添加比例尺控件 **/
            add_scaleLine(){
                var scaleLineControl = new ScaleLine({
                    units: 'metric',
                    target: 'scalebar',
                    className: 'ol-scale-line'
                });
                this.map.addControl(scaleLineControl);
            },
            /** 实例化鼠标位置控件 **/
            add_mousePosition(){
                //实例化鼠标位置控件
                var mousePositionControl = new MousePosition({
                    coordinateFormat: createStringXY(4),//坐标格式
                    //地图投影坐标系
                    projection: 'EPSG:4326',
                    //className:'tip',
                    target: document.getElementById('mouse-position'),//显示鼠标位置信息的目标容器
                    undefinedHTML: '&nbsp;'//未定义坐标标记
                });

                //添加鼠标位置控件
                this.map.addControl(mousePositionControl);
            },

            /**图层切换 */
            handleSwitchMap(type) {
                switch (type) {
                    case "vector":
                        this.layer_vector.setVisible(true);
                        this.layer_image.setVisible(false);
                        this.layer_terrain.setVisible(false);
                        this.active = 1;
                        break;
                    case "image":
                        this.layer_vector.setVisible(false);
                        this.layer_image.setVisible(true);
                        this.layer_terrain.setVisible(false);
                        this.active = 2;
                        break;
                    case "terrain":
                        this.layer_vector.setVisible(false);
                        this.layer_image.setVisible(false);
                        this.layer_terrain.setVisible(true);
                        this.active = 2;
                        break;
                }
            },
            /********** 输入查询 start **************/
            /** 查询条件 **/
            mapQuery({type,arg}){
                // 移除之前绘制的信息
                this.remove_measurement();
                switch (type){
                    case this.$eventTags.query_text:{
                        this.$refs.InfoList.openShow(arg);
                        break;
                    }
                    case this.$eventTags.map_cd:{
                        this.clChange("LineString");
                        break;
                    }
                    case this.$eventTags.map_mj:{
                        this.clChange("Polygon");
                        break;
                    }
                    case this.$eventTags.map_ydyp:{
                        this.$refs.InfoList.closeShow();
                        // 清除查询条件geomWkt
                        this.$refs.mapToolBar.close_geomWkt();
                        this.ydypChange();
                        break;
                    }
                    case this.$eventTags.map_dr_shp:{
                        this.$refs.InfoList.closeShow();
                        // 清除查询条件geomWkt
                        this.$refs.mapToolBar.close_geomWkt();
                        this.wkt_show(arg);
                        this.showHg(arg);
                        break;
                    }
                    case this.$eventTags.map_qc:{
                        this.$refs.InfoList.closeShow();
                        // 清除查询条件geomWkt
                        this.$refs.mapToolBar.close_geomWkt();
                        // 清除绘制
                        this.remove_measurement();
                        let view = this.map.getView();
                        view.setZoom(7);
                        view.setCenter(fromLonLat([106.6265,26.6531]))
                        break;
                    }
                    case this.$eventTags.mapInfo_back:{
                        // 清除查询条件geomWkt
                        this.$refs.mapToolBar.close_geomWkt();
                        // 清除绘制
                        this.remove_measurement();
                        let view = this.map.getView();
                        view.setZoom(7);
                        break;
                    }
                    case this.$eventTags.wkt_show:{
                        // 定位
                        this.wkt_show(arg);
                        break;
                    }
                    case this.$eventTags.refresh:{
                        this.$refs.InfoList.closeShow();
                        // 清除查询条件geomWkt
                        this.$refs.mapToolBar.close_geomWkt();
                        break;
                    }

                }
            },
            /********** 输入查询 end ***************/

            /***************** 测量:长度、面积 start ****************/
            /** 绘制测量 **/
            clChange(type){
                //定义矢量数据源
                var source = new VectorSource();
                this.vector_draw = new VectorLayer({
                    source: source,
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)',
                        }),
                        stroke: new Stroke({
                            color: '#ffcc33',
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 7,
                            fill: new Fill({
                                color: '#ffcc33',
                            }),
                        }),
                    }),
                });
                // 将矢量图层放入map
                this.map.addLayer(this.vector_draw);

                //创建一个当前要绘制的对象
                var sketch = new Feature();


                //创建一个交互式绘图对象
                this.draw = new Draw({
                    //绘制的数据源
                    source: source,
                    //绘制类型
                    type: type,
                    //样式
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineDash: [10, 10],
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgba(0, 0, 0, 0.7)',
                            }),
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.2)',
                            }),
                        }),
                    }),
                });
                //将交互绘图对象添加到地图中
                this.map.addInteraction(this.draw);
                //创建测量提示框
                this.createMeasureTooltip();
                //创建帮助提示框
                this.createHelpTooltip();


                //绘制开始事件
                this.draw.on('drawstart',  (evt)=> {
                    //The feature being drawn.
                    sketch = evt.feature;
                    //提示框的坐标
                    var tooltipCoord = evt.coordinate;

                    this.listener = sketch.getGeometry().on('change',  (evt) =>{
                        //The event target.
                        //获取绘制的几何对象
                        var geom = evt.target;
                        //定义一个输出对象，用于记录面积和长度
                        var output;

                        if (geom instanceof Polygon) {
                            this.map.removeEventListener('singleclick');
                            this.map.removeEventListener('dblclick');
                            this.geom = geom;
                            //输出多边形的面积
                            output = this.formatArea(geom);
                            //获取多变形内部点的坐标
                            tooltipCoord = geom.getInteriorPoint().getCoordinates();
                        } else if (geom instanceof LineString) {
                            //输出多线段的长度
                            output = this.formatLength(geom);
                            this.geom = geom;
                            //获取多线段的最后一个点的坐标
                            tooltipCoord = geom.getLastCoordinate();
                        }

                        //设置测量提示框的内标签为最终输出结果
                        this.measureTooltipElement.innerHTML = output;
                        //设置测量提示信息的位置坐标
                        this.measureTooltip.setPosition(tooltipCoord);
                    });

                    //地图单击事件
                    this.map.on('singleclick',  (evt)=> {

                        //设置测量提示信息的位置坐标，用来确定鼠标点击后测量提示框的位置
                        this.measureTooltip != null ? this.measureTooltip.setPosition(evt.coordinate) : null;
                        //根据鼠标点击位置生成一个点
                        var point = new Point(evt.coordinate);
                        //将该点要素添加到矢量数据源中
                        source.addFeature(new Feature(point));
                        //点击次数增加
                        this.count++;
                    });

                    //地图双击事件
                    this.map.on('dblclick',  (evt)=> {
                        var point = new Point(evt.coordinate);
                        source.addFeature(new Feature(point));
                    });

                }, this);
                //绘制结束事件
                this.draw.on('drawend',  (evt)=> {
                    // 绘制结束之后 绘制出的点线面数据
                    evt.feature.getGeometry().getCoordinates()
                    this.count = 0;
                    //设置测量提示框的样式
                    this.measureTooltipElement.className = 'tooltip tooltip-static';
                    //设置偏移量
                    this.measureTooltip.setOffset([0, -7]);
                    //清空绘制要素
                    sketch = null;
                    //清空测量提示要素
                    this.measureTooltipElement = null;
                    //创建测量提示框
                    this.createMeasureTooltip();
                    //移除事件监听
                    unByKey(this.listener);
                    //移除地图单击事件
                    this.map.removeEventListener('singleclick');
                }, this);

            },

            /** 创建帮助提示框 **/
            createHelpTooltip() {
                //如果已经存在帮助提示框则移除
                if (this.helpTooltipElement) {
                    this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
                }
                //创建帮助提示要素的div
                this.helpTooltipElement = document.createElement('div');
                //设置帮助提示要素的样式
                this.helpTooltipElement.className = 'tooltip hidden';
                //创建一个帮助提示的覆盖标注
                this.helpTooltip = new Overlay({
                    element: this.helpTooltipElement,
                    offset: [15, 0],
                    positioning: 'center-left'
                });
                //将帮助提示的覆盖标注添加到地图中
                this.map.addOverlay(this.helpTooltip);
            },
            /** 创建测量提示框 **/
            createMeasureTooltip() {
                //创建测量提示框的div
                this.measureTooltipElement = document.createElement('div');
                this.measureTooltipElement.setAttribute('id', 'lengthLabel');
                //设置测量提示要素的样式
                this.measureTooltipElement.className = 'tooltip tooltip-measure';
                //创建一个测量提示的覆盖标注
                this.measureTooltip = new Overlay({
                    element: this.measureTooltipElement,
                    offset: [0, -15],
                    positioning: 'bottom-center'
                });
                //将测量提示的覆盖标注添加到地图中
                this.map.addOverlay(this.measureTooltip);
            },
            /** 格式化测量长度 **/
            formatLength(line) {
                //定义长度变量，计算平面距离
                var length = Math.round(line.getLength() * 100) / 100;
                //定义输出变量
                var output;
                //如果长度大于1000，则使用km单位，否则使用m单位
                if (length > 1000) {
                    output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km'; //换算成KM单位
                } else {
                    output = (Math.round(length * 100) / 100) + ' ' + 'm'; //m为单位
                }
                return output;
            },
            /** 格式化测量面积 **/
            formatArea(polygon) {
                //定义面积变量，获取平面面积
                var area = polygon.getArea();
                //定义输出变量
                var output;
                //当面积大于10000时，转换为平方千米，否则为平方米
                if (area > 10000) {
                    output = (Math.round(area / 1000000 * 100) / 100) + ' ' + 'km<sup>2</sup>';
                } else {
                    output = (Math.round(area * 100) / 100) + ' ' + 'm<sup>2</sup>';
                }
                return output;
            },
            /*************** 测量:长度、面积 end *****************/

            /*************** 绘制分析 start ****************/
            /** 绘制分析 **/
            ydypChange(){
                //定义矢量数据源
                var source = new VectorSource();
                this.vector_draw = new VectorLayer({
                    source: source,
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)',
                        }),
                        stroke: new Stroke({
                            color: '#ffcc33',
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 7,
                            fill: new Fill({
                                color: '#ffcc33',
                            }),
                        }),
                    }),
                });
                // 将矢量图层放入map
                this.map.addLayer(this.vector_draw);

                //创建一个当前要绘制的对象
                var sketch = new Feature();


                //创建一个交互式绘图对象
                this.draw = new Draw({
                    //绘制的数据源
                    source: source,
                    //绘制类型
                    type: 'Polygon',
                    //样式
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineDash: [10, 10],
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgba(0, 0, 0, 0.7)',
                            }),
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.2)',
                            }),
                        }),
                    }),
                });
                //将交互绘图对象添加到地图中
                this.map.addInteraction(this.draw);

                //绘制开始事件
                this.draw.on('drawstart',  (evt)=> {

                    sketch = evt.feature;
                    //提示框的坐标
                    var tooltipCoord = evt.coordinate;

                    this.listener = sketch.getGeometry().on('change',  (evt) =>{
                        //The event target.
                        //获取绘制的几何对象
                        var geom = evt.target;
                        //定义一个输出对象，用于记录面积和长度
                        var output;

                        if (geom instanceof Polygon) {
                            this.geom = geom;
                            //输出多边形的面积
                            output = this.formatArea(geom);
                            //获取多变形内部点的坐标
                            tooltipCoord = geom.getInteriorPoint().getCoordinates();
                        }
                    });

                    //地图单击事件
                    this.map.on('singleclick',  (evt)=> {
                        //根据鼠标点击位置生成一个点
                        var point = new Point(evt.coordinate);
                        //将该点要素添加到矢量数据源中
                        source.addFeature(new Feature(point));
                        //点击次数增加
                        this.count++;
                    });

                    //地图双击事件
                    this.map.on('dblclick',  (evt)=> {
                        var point = new Point(evt.coordinate);
                        source.addFeature(new Feature(point));
                    });

                }, this);
                //绘制结束事件
                this.draw.on('drawend',  (evt)=> {
                    // 绘制结束之后 绘制出的点线面数据
                    evt.feature.getGeometry().getCoordinates();

                    // 绘制结束后去获取绘制图形
                    var polygon = evt.feature.getGeometry();
                    // 绘制的是polygon, 此处使用 WKT().writeGeometry() 方法
                    //文档有其他要素方法, 可以自己查一下
                    var polygonWKT = new WKT().writeGeometry(polygon,{
                        dataProjection : "EPSG:4326",
                        featureProjection : "EPSG:3857"
                    });
                    this.showHg(polygonWKT);

                    this.count = 0;
                    //清空绘制要素
                    sketch = null;
                    //移除事件监听
                    unByKey(this.listener);
                    //移除地图单击事件
                    this.map.removeEventListener('singleclick');
                    //移除之前的绘制对象
                    this.map.removeInteraction(this.draw);
                    this.map.removeEventListener('dblclick');
                }, this);

            },
            /** 绘制范围查询 **/
            showHg(polygonWKT){
                this.$refs.mapToolBar.reset_tool();
                // 设置查询条件geomWkt
                this.$refs.mapToolBar.set_geomWkt(polygonWKT);

                let parms ={
                    geomWkt:polygonWKT
                };
                achieveRecord_api.queryAll.requestPOST(this,parms,res=>{
                    if(res.data.flag){
                        this.$refs.InfoList.openShow(res.data.data);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            /*************** 绘制分析 end ****************/

            /**************** 其他方法 start ********************/
            /** 清除绘制所用到的元素 **/
            remove_measurement() {
                //移除之前的绘制对象
                if (this.draw != null) {
                    this.map.removeInteraction(this.draw);
                }
                // 移除测量矢量图层
                if (this.vector_draw != null) {
                    this.map.removeLayer(this.vector_draw);
                }

                if(this.layer_list.length > 0){
                    for(let i=0;i<this.layer_list.length;i++){
                        this.map.removeLayer(this.layer_list[i]);
                    }
                }

                if(this.layer_dr.length > 0){
                    for(let i=0;i<this.layer_dr.length;i++){
                        this.map.removeLayer(this.layer_dr[i]);
                    }
                }

                // 清除帮助提示信息对象
                if (this.helpTooltip != null) {
                    this.helpTooltip = null;
                    this.helpTooltipElement = null;
                    // 清除测量提示信息对象
                    this.measureTooltip = null;
                    this.measureTooltipElement = null;
                    this.map.getOverlays().clear();//关键点
                }

            },
            /** 清除绘制、提示信息 ***/


            /** wkt处理 **/
            wkt_show(wktGeom){
                if(wktGeom == null){
                    return;
                }
                var source = new VectorSource();
                // 解析 wkt字符串
                var feature = new WKT().readFeature(wktGeom, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });
                source.addFeature(feature);
                // wkt加入图层
                let wkt_layer = new VectorLayer({source: source});
                this.layer_dr.push(wkt_layer)
                this.map.addLayer(wkt_layer);
                let view = this.map.getView();
                view.fit(feature.getGeometry(),{
                    duration:1000,
                    maxZoom:12,
                });

            },
            /**************** 其他方法 end ********************/

        },
    }
</script>

<style scoped>
    .divTdtMap {
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        z-index: 0;
    }

    #mouse-position{
        z-index: 999;
        left: 10%;
        bottom: 8%;
        position: absolute;
        color: white;
        width: 150px;
    }
    .layer_mange{
        z-index: 999;
        /* right: 8%;
         top: 10%;*/
        position: absolute;
        color: white;
        width: 150px;
        bottom: 10px;
        left: 11%
    }
    .dtys{
        padding-right: 10px;
    }
    .dtys_name{
        margin-top: -20px;
        background-color: rgb(0 0 0 / 45%);
        position: relative;
    }
    .layer_img{
        width: 80px;
        height: 80px;
    }
</style>