<!--
* @program: tyh-oa 
* @author: ly
* @component:roleMenuSetting 
* @description: 角色管理
* @create: 2021-05-26 14:28
-->
<template>
    <div style="height: 100%;width: 100%;background: white">
        <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
            <template #title>
                角色菜单
                <a-tooltip  title="添加角色">
                    <a-button class="search-item" type="dashed"  @click="addRole">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                </a-tooltip>
                <a-tooltip  title="刷新">
                    <a-button class="search-item" type="dashed"  @click="refresh">
                        <template #icon><SyncOutlined /></template>
                    </a-button>
                </a-tooltip>
            </template>
        </a-page-header>
        <div style="width: 100%;height:calc(100% - 120px);display: flex;">
            <div style="width: 40%; height: 100%;min-width: 600px">
                <TcBaseTable :loading="loading" :data-source="dataSource" :table-columns="cols" :page-size="99" :view-model="true">
                    <template #action="{index,record}">
                        <div style="text-align: center">
                            <a-button  class="action-button" type="link" @click="editDetail(record)">
                                <EditFilled class="pointer"/>
                            </a-button>
                            <a-tooltip  title="当前拥有该角色的用户">
                                <a-button  class="action-button" type="link" @click="showMember(record)">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip  title="菜单权限设定">
                                <a-button class="action-button" type="link" @click="viewMenus(record)">
                                    <ApartmentOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip  title="报账类别设定">
                                <a-button class="action-button" type="link" @click="categoryEdit(record)">
                                    <BarsOutlined  class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(index,record)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </div>
                    </template>
                </TcBaseTable>
            </div>
            <div v-show="menuVisible" style="width: 60%; height: 100%;display: flex">
                <div style="height: 100%;width: 50%">
                    <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
                        <template #title>
                            菜单权限绑定
                        </template>
                    </a-page-header>
                    <div style="height:calc(100% - 200px);overflow-y:auto">
                        <menu-tree-select ref="menuTreeSelect" />
                    </div>
                    <a-divider />
                    <a-button key="back" @click="menuVisible =false">取消</a-button>
                    <a-button key="submit" type="primary"  @click="roleMenuSave">提交</a-button>
                </div>
                <div style="height: 100%;width: 50%">
                    <a-page-header style="border: 1px solid rgb(235, 237, 240)" >
                        <template #title>
                            微信小程序菜单权限绑定
                        </template>
                    </a-page-header>
                    <div style="height:calc(100% - 200px);overflow-y:auto">
                        <appMenuTreeSelect ref="appMenuTreeSelect" />
                    </div>
                    <a-divider />
                    <a-button key="back" @click="menuVisible =false">取消</a-button>
                    <a-button key="submit" type="primary"  @click="roleAppMenuSave">提交</a-button>
                </div>
            </div>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             title="详情">
        <template #footer>
            <a-button key="back" @click="visible =false">取消</a-button>
            <a-button key="submit" type="primary"  @click="update">提交</a-button>
        </template>
        <a-form style="margin: 10px " layout="horizontal"
                :model="currentRecord">
            <a-form-item label="角色名">
                <a-input style="width: 200px" v-model:value="currentRecord.name" />
            </a-form-item>
            <a-form-item label="说明">
                <a-textarea style="width: 200px" v-model:value="currentRecord.remarks" />
            </a-form-item>
        </a-form>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             title="详情">
        <div style="height: 500px">
            <ArBaseTable :pageSize="10" :view-model="true" :argument="argument" >
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                title="确定删除吗?"
                                @confirm="removeMember(index,record)">
                            <a-button type="link">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-button>
                        </a-popconfirm>
                    </div>
                </template>
            </ArBaseTable>
        </div>
    </a-modal>
    <a-modal v-model:visible="visible3"
             destroyOnClose
             title="报账类别设定">
        <template #footer>
            <a-button key="back" @click="visible3 =false">取消</a-button>
            <a-button key="submit" type="primary"  @click="roleCategorySave" >提交</a-button>
        </template>
        <div style="height: 600px;overflow-y: auto;margin:0;padding: 0">
            <category-tree ref="categoryTree" :roleId="currentRecord.id"/>
        </div>
    </a-modal>
</template>

<script>
    import {ApartmentOutlined,BarsOutlined,SearchOutlined, DeleteOutlined, EditFilled, PlusOutlined, SyncOutlined,AppstoreFilled} from "@ant-design/icons-vue";
    import menuTreeSelect from "./menuTreeSelect";
    import sys_role from "../../../assets/tables/sys_role";
    import api_sys_role from "../../../assets/api/role";
    import api_sys_menu from "../../../assets/api/menu";
    import roleMenuRelation from "../../../assets/api/roleMenuRelation";
    import userRoleRelation from "../../../assets/api/userRoleRelation";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import categoryTree from "./categoryTree";
    import api_category_role from "../../../assets/api/categoryRole";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import appMenuTreeSelect from "./appMenuTreeSelect";
    import wxMenuRole from "../../../assets/api/wxMenuRole";
    import wxMenu from "../../../assets/api/wxMenu";
    export default {
        name: "roleMenuSetting",
        components:{
            DeleteOutlined,PlusOutlined,EditFilled,SyncOutlined,ApartmentOutlined,AppstoreFilled,
            menuTreeSelect,ArBaseTable,SearchOutlined,categoryTree,BarsOutlined,
            TcBaseTable,appMenuTreeSelect
        },
        data(){
            return{
                activeKey:"setting1",
                dataSource:[],
                table: new sys_role(),
                cols:[],
                loading:false,
                pagination:{
                    size:"small",
                    hideOnSinglePage: true,
                    defaultPageSize: 15,
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '15', '20'],
                    onChange: (page) => {
                        this.currentPage = page;
                    }
                },
                visible:false,//角色弹出框
                viewModel:false,
                currentRecord:{},
                menuVisible:false ,//菜单弹出框
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                visible2:false,
                visible3:false
            }
        },
        methods:{
            init(){
                this.loading = true;
                api_sys_role.queryAll.requestPOST(this,{},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                });
                this.argument.tableColumns = [
                    new Column("序号","_index",ColumnType.Index).setTableView(40),
                    new Column("姓名","userName",ColumnType.String).setTableView(80),
                    new Column("操作","actions","actions").setTableView(40)
                ];
            },
            addRole(){//添加角色
                this.currentRecord = {};
                this.visible = true
            },
            viewMenus(record){//   查看/编辑角色菜单
                this.currentRecord = {roleId:record.id};
                this.menuVisible = true;
                api_sys_menu.queryMenuByRole.requestPOSTUrlParam(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$refs.menuTreeSelect.bindTwoTrees(res.data.data);
                    }
                    else{
                        this.$refs.menuTreeSelect.bindTwoTrees([]);
                    }
                });
                wxMenu.menuByRole.requestPOSTUrlParam(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$refs.appMenuTreeSelect.bindTwoTrees(res.data.data);
                    }
                    else{
                        this.$refs.appMenuTreeSelect.bindTwoTrees([])
                    }
                })

            },
            roleMenuSave(){//保存当前角色菜单
                let roleMenuList = [];
                let menuIds=this.$refs.menuTreeSelect.getCheckedKeys();
                let treeData = this.$refs.menuTreeSelect.getTreeData();
                for(let id of menuIds){
                    let node = getNode(id,treeData);
                    if(node && node.sops && node.sops.length>0){
                        let opIds = [];
                        node.sops.forEach(item=>{
                            opIds.push(item.id)
                        });
                        roleMenuList.push(
                            {
                                "menuId": node.treeId,
                                "menuOps": opIds.toString(),
                                "roleId": this.currentRecord.roleId
                            }
                        )
                    }
                    else{
                        roleMenuList.push(
                            {
                                "menuId": id,
                                "menuOps": "",
                                "roleId": this.currentRecord.roleId
                            }
                        )
                    }
                }
                if(roleMenuList.length>0){
                    roleMenuRelation.authorization.requestPOST(this,{roleMenuList},res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
            roleAppMenuSave(){
                let wxMenuRoleVOList = [];
                let menuIds = this.$refs.appMenuTreeSelect.getCheckedKeys();
                let treeData = this.$refs.appMenuTreeSelect.getTreeData();
                for(let id of menuIds){
                    let node = getAppNode(id,treeData);
                    if(node && node.sops){
                        wxMenuRoleVOList.push(
                            {
                                "menuId": node.id,
                                "permissions": node.sops,
                                "roleId": this.currentRecord.roleId
                            }
                        )
                    }
                    else{
                        wxMenuRoleVOList.push(
                            {
                                "menuId": id,
                                "permissions": "",
                                "roleId": this.currentRecord.roleId
                            }
                        )
                    }
                }
                if(wxMenuRoleVOList.length>0){
                    wxMenuRole.bind.requestPOST(this,{wxMenuRoleVOList},res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
            deleteData(index,record){
                let data ={id:record.id};
                api_sys_role.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editDetail(record){
                this.visible = true;
                this.currentRecord = record;
            },
            showMember(record){
                let data = {
                    roleId:record.id
                };
                this.visible2 = true;
                this.argument.loading = true;
                userRoleRelation.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            categoryEdit(record){
                this.currentRecord = record;
                this.visible3 = true;
            },
            roleCategorySave(){
                let categoryIds=this.$refs.categoryTree.getCheckedKeys();
                let data = {roleId:this.currentRecord.id,categoryId:categoryIds};
                api_category_role.categoryReAlloc.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            update(){
                api_sys_role.saveOrUpd.requestPOST(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.init()
            },
            removeMember(index,record){
                let data = {
                    id:record.id
                };
                this.argument.loading = true;
                userRoleRelation.delete.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource.splice(index,1);
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        created() {
            this.cols = this.table.getForTable();
            this.init();
        }
    }

    function getNode(key,treeData){ //递归函数
        for(let node of treeData){
            if(node.treeId === key){
                return  node;
            }
            else {
                if(node.children && node.children.length>0){
                    let res = getNode(key,node.children);
                    if(res){
                        return res
                    }
                }
            }
        }
    }
    function getAppNode(key,treeData){ //递归函数
        for(let node of treeData){
            if(node.id === key){
                return  node;
            }
            else {
                if(node.children && node.children.length>0){
                    let res = getAppNode(key,node.children);
                    if(res){
                        return res
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>