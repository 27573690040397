/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_qc_group_member  质检组-成员管理
 * @create: 2021-04-25 11:26
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import api_sys_user_info from "../api/userInfo";
import api_item_outsourc_info from "../api/outSourceInfo";
export default class item_qc_group_member extends Table{
    constructor() {
        super();
        this.name = "item_qc_group_member";
        this.CNName = "员工生产质量管理";
        this.columnArray = [
            new Column("人员类别","userType",ColumnType.String,true).setTableView(120)
                .setEnum(["1","2","3"],["内部员工","外协单位","外协人员"],["green","purple","purple"])
                .setTriggerOtherColumn("1",
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="userId"})[0]
                            .setIdReplaceObject("userName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}});
                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="userId"})[0]
                            .setIdReplaceObject("userName",{api:api_item_outsourc_info.queryAll,tag:"queryText"});
                    }).setDefaultValue("1"),
            new Column("成员","userId",ColumnType.IdReplace,true)
                .setIdReplaceObject("userName",{}).setTableView(200),
            //new Column("质检组ID","groupId",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(60)];
    }
}