<!--
* @program: office_automation 
* @author: ly
* @component:projectShower 
* @description: 与projectViewer类似，但是没有编辑功能
* @create: 2021-12-10 13:44
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="visibleType = !visibleType" class="search-item" type="default">
                    <template #icon>
                        <BarChartOutlined style="color:green"/>
                    </template>
                    项目分类统计
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="default">
                    <template #icon>
                        <SyncOutlined title="重置"/>
                    </template>
                </a-button>
                <a-tooltip title="有收款无合同的项目，除去了不需要合同的类型">
                    <a-badge :count="unSetterSource.length">
                        <a-button :type="unSetterSource === dataSource?'primary':''" @click="unSetterSourceShow"
                                  class="search-item"
                                  style="margin-left: 10px">
                            <div>有收款无合同</div>
                        </a-button>
                    </a-badge>
                </a-tooltip>
                <a-tooltip title="待支付的外协支付申请">
                    <a-badge :count="payApplySource.length">
                        <a-button @click="showPayApply" class="search-item" style="margin-left: 10px" type="default">
                            外协支付申请
                        </a-button>
                    </a-badge>
                </a-tooltip>
                <a-tooltip title="已完成工作，收款未完成的项目">
                    <a-badge :count="notReceived.length">
                        <a-button :type="notReceived === dataSource?'primary':''" @click="notReceivedShow"
                                  class="search-item"
                                  style="margin-left: 10px">
                            <div>待收款</div>
                        </a-button>
                    </a-badge>
                </a-tooltip>
                <div style="margin:0 5px">项目筛选：</div>
                <a-input @change="doSearch" placeholder="项目名称" style="width: 150px" v-model:value="defaultQuery.name" />
                <a-input @change="doSearch" placeholder="甲方名称" style="width: 150px" v-model:value="defaultQuery.partyInfoName" />
                <a-input @change="doSearch" placeholder="分配部门" style="width: 150px" v-model:value="defaultQuery.departmentName" />
                <QueryFilter :filter="filter" @change="doSearch" v-model:value="defaultQuery"/>
            </div>
            <div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: center;" v-if="stateVisible">
                项目个数：{{item_sum}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                预算总金额：{{itemBudget_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                合同总金额：{{sumAmount_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                等效合同总额：{{dxhte_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                已收款总金额：{{sumCollect_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                未收款总金额：{{sumCollectNo_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                无合同但收款合计：{{unSetter_sum.toFixed(2)}}
            </div>
            <TwoParts :trans="[100,25]" @change="handleChange" pos="transform" ref="TwoParts">
                <template #left>
                    <TcBaseTable :data-source="tableSource" :download="true" :loading="loading" :page-size="15"
                                 :scroll="{x:300,y:620}" :table-columns="tableColumns" :view-model="true"
                                 ref="project_table">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(index,record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer" title="查看"/>
                                </a-button>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <project-tabs ref="local_tabs"/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import projectViewer from "./projectViewer";
    import itemInfo from "../../../assets/api/itemInfo";
    import {companyIds, companyNames} from "../../../assets/system/companies";

    export default {
        name: "projectShower",
        mixins: [projectViewer],
        methods: {
            init() {//不需要查询待支付的数据
                this.loading = true;
                this.searchData = null;
                this.pageSize = 15;
                itemInfo.queryAll.requestPOST(this, {auditState: "1"}, res => {
                    if (res.data.flag) {
                        this.unSetterSource = [];
                        let years = []; //项目年度
                        let colors = [];
                        let list_info = res.data.data;
                        if (list_info.length > 0) {
                            list_info.forEach((item) => {
                                item.sumAmount = 0;
                                item.contractTypes = [];
                                if (!years.includes(item.year)) {
                                    years.push(item.year);
                                    colors.push("red");
                                }
                                let contractInfoDTOs = item.contractInfoDTOs;
                                if (contractInfoDTOs != null && contractInfoDTOs.length > 0) {
                                    item.partyContract = "1";
                                    let contractTypes = []; //合同类型
                                    for (let item_c of contractInfoDTOs) {
                                        contractTypes.push(item_c.type);
                                        if (item_c.type === '1' || item_c.type === '4') {
                                            item.partyInfoId = item_c.associatedKey;
                                            item.partyInfoName = item_c.associatedName;

                                        }
                                        if (item_c.type === '1' || item_c.type === '4' || item_c.type === '7') {
                                            item.sumAmount += item_c.amount;
                                            item.sigCompany = item_c.sigCompany;
                                            let index = companyIds.indexOf(item_c.sigCompany);
                                            item.sigCompanyName = companyNames[index];
                                        }
                                    }
                                    item.contractTypes = contractTypes;
                                } else {
                                    item.partyContract = "0";
                                }
                                item.sumCollectNo = item.sumAmount;

                                if (item.sumCollectNo >= item.sumCollect) {
                                    item.sumCollectNo -= item.sumCollect
                                } else {
                                    item.sumCollectNo = 0;
                                    if (item.contractState !== "2") {
                                        this.unSetterSource.push(item)
                                    }
                                }

                                item.collecState = item.sumCollect ? "1" : "0";

                                item.departmentName = item.itemTaskDTO?.departmentName
                            })
                        }
                        this.projectList = list_info.sort((a, b) => {
                            return b.startTime - a.startTime;
                        });
                        this.notReceived = this.projectList.filter(item => { //itemState 1 工作完成   collectionState 0 收款未完成  completeFlag 2 项目未完结
                            return item.itemState === "1" && item.collectionState === "0" && item.completeFlag !== "2"
                        });
                        this.dataSource = this.projectList;
                        this.filter.year = {
                            name:"年度",
                            values:years,
                            labels:years,
                            colors:colors
                        };
                        this.doSearch();
                    } else {
                        this.projectList = [];
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>