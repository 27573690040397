<!--
*@description：科目类别树
*@author:rzl
*@date:2022/02/17
-->
<template>
  <a-radio-group v-model:value="sujectType" @change="radioChange">
    <a-radio-button value="1">现金流量表</a-radio-button>
    <a-radio-button value="2">利润表</a-radio-button>
    <a-radio-button value="3">资产负债表</a-radio-button>
  </a-radio-group>
  <a-divider style="margin: 10px 0;"></a-divider>


  <!-- 现金流量表/利润表 树 -->
  <div v-if="sujectType == '1' || sujectType == '2'" class="dicTree" style="background-color: #f0f0f0;" >
    <!-- 操作栏 -->
    <div style="text-align: left;">
      <a-input style="width: 60%;margin: 10px 5px 10px 45px;"  placeholder="输入关键字搜索" v-model:value="searchVal" @change="searchOnChange" @search="onSearch" />
      <a-tooltip  :title="sujectName">
        <a-button class="search-item" type="dashed"  @click="addInfo">
          <template #icon><Add /></template>
        </a-button>
      </a-tooltip>
      <a-tooltip  title="刷新">
        <a-button class="search-item" type="dashed"  @click="refresh">
          <template #icon><Reset /></template>
        </a-button>
      </a-tooltip>
    </div>

    <a-divider style="margin: 10px 0;"></a-divider>

    <!-- 树数据 -->
    <div class="dicTree" style="height:700px;overflow: auto;" >
      <a-tree
          v-if="showData.length > 0"
          :expanded-keys="iExpandedKeys"
          :auto-expand-parent="autoExpandParent"
          default-expanded-keys=""
          :tree-data="showData"
          @expand="onExpand"
          @select="selectShow"
          :replace-fields="{children:'children', key:'id', value: 'id', title: 'label',sort:'sort',parentId:'parentId'}"
          style="margin-left: 20px;text-align: left;width: 100%"
          showLine
      >
        <template #title="node">
          <div style="display: flex">
             <span v-if="node.label.indexOf(searchValue) > -1" style="padding: 2px 0;">
              {{ node.label.substr(0, node.label.indexOf(searchValue)) }}
              <span style="color: #f50;padding: 2px 0;">{{ searchValue }}</span>
              {{ node.label.substr(node.label.indexOf(searchValue) + searchValue.length) }}
            </span>
            <span v-else  style="padding: 2px 0;">{{ node.label }}</span>
            <a-tooltip title="添加子类">
              <a-button style="padding: 0 5px;"  type="link" @click="addData(node)"><Add /></a-button>
            </a-tooltip>
            <a-tooltip title="编辑">
              <a-button style="padding: 0 5px" type="link" @click="editData(node)"><Edit /></a-button>
            </a-tooltip>
            <a-tooltip v-if="flag_sujectType(node)"  title="科目统计关联费用类别">
              <a-button style="padding: 0 5px" type="link" @click="association(node)"><HddTwoTone /></a-button>
            </a-tooltip>
            <a-popconfirm
                ok-text="是"
                cancel-text="否"
                title="确定删除吗?"
                @confirm="deleteData(node)">
              <a-tooltip title="删除">
                <a-button style="padding: 0 5px"   type="link"><Delete  style="color: red" class="pointer"/></a-button>
              </a-tooltip>
            </a-popconfirm>
          </div>
        </template>
      </a-tree>
    </div>
  </div>

  <!-- 资产负债表 树 -->
  <div v-else class="dicTree" style="background-color: #f0f0f0;" >

    <a-divider style="margin: 10px 0;">
      <a-radio-group v-model:value="sujectType" @change="radioChange">
        <a-radio value="3">资产</a-radio>
        <a-radio value="4">负债</a-radio>
        <a-radio value="5">权益</a-radio>
      </a-radio-group>
      &nbsp;&nbsp;
      <a-tooltip  title="录入资产负债表期初余额">
        <a-button class="search-item" type="dashed"  @click="period_balance">
          <template #icon><DiffTwoTone /></template>
        </a-button>
      </a-tooltip>
    </a-divider>
    <a-divider style="margin: 15px 0;background-color: #faad14;" dashed ></a-divider>

    <!-- 操作栏 -->
    <div style="text-align: left;">
      <a-input style="width: 60%;margin: 10px 5px 10px 45px;"  placeholder="输入关键字搜索" v-model:value="searchVal" @change="searchOnChange" @search="onSearch" />
      <a-tooltip  title="刷新">
        <a-button class="search-item" type="dashed"  @click="refresh">
          <template #icon><Reset /></template>
        </a-button>
      </a-tooltip>
    </div>

    <a-divider style="margin: 10px 0;"></a-divider>

    <!-- 树数据 -->
    <div class="dicTree" style="height:600px;overflow: auto;" >
      <a-tree
          v-if="showData.length > 0"
          :expanded-keys="iExpandedKeys"
          :auto-expand-parent="autoExpandParent"
          default-expanded-keys=""
          :tree-data="showData"
          @expand="onExpand"
          @select="selectShow"
          :replace-fields="{children:'children', key:'id', value: 'id', title: 'label',sort:'sort',parentId:'parentId'}"
          style="margin-left: 20px;text-align: left;width: 100%"
          showLine
      >
        <template #title="node">
          <div style="display: flex">
             <span v-if="node.label.indexOf(searchValue) > -1" style="padding: 2px 0;">
            {{ node.label.substr(0, node.label.indexOf(searchValue)) }}
            <span style="color: #f50;padding: 2px 0;">{{ searchValue }}</span>
            {{ node.label.substr(node.label.indexOf(searchValue) + searchValue.length) }}
          </span>
            <span v-else  style="padding: 2px 0;">{{ node.label }}</span>
            <a-tooltip v-if="flag_sujectType(node)"  title="科目统计关联费用类别">
              <a-button style="padding: 0 5px" type="link" @click="association(node)"><HddTwoTone /></a-button>
            </a-tooltip>
          </div>
        </template>
      </a-tree>
    </div>
  </div>


  <!-- 遮罩层 -->
  <div v-if="loadingVisible" class="loading">
    <a-space>
      <a-spin size="large" />
    </a-space>
  </div>
  <!-- 编辑 -->
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="35%"
           :destroyOnClose="true"
           style="margin-top: 2%;"
  >

    <ar-form  ref="bill_form" :argument="argument"  :viewModel="viewModel"  />

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
      <a-button v-if="!viewModel" key="back" type="primary" @click="submit">提交</a-button>
    </template>
  </a-modal>
</template>

<script>
import Icons from "../../../common/Icons";
import ArForm from "../../../../components/form/ArForm";

import cashSubject from "../../../../assets/api/cashSubject";
import sujectType_item from "../config/sujectType_item";
export default {
  name: 'sujectTypeTree',
  mixins:[Icons],
  components:{
    ArForm,
  },
  data () {
    return {
      loadingVisible:false,
      showData: [],
      defaultData: [],
      expandedKeys:[],
      searchVal: "",
      searchValue: "",
      iExpandedKeys: [],
      autoExpandParent: true,
      tree_data: [],

      visible:false,
      modalTitle:null,
      viewModel:null,
      argument:{
        formColumns:new sujectType_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      sujectType:'1',// 1：现金流量表；2：利润表；3：资产负债表:3：资产；4：负债；5：权益
      sujectName:'新增现金流量表大类',

    }
  },
  methods: {
    radioChange(val){
      let type = this.sujectType;
      this.searchVal = "";
      this.searchValue = "";
      if(type == '1'){
        this.sujectName = "新增现金流量表大类";
      }else{
        this.sujectName = "新增利润表大类";
      }
      this.setTreeData();
      this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
    },
    setTreeData() {
      this.loadingVisible = true;
      this.showData = [];
      this.defaultData = [];
      this.tree_data = [];

      let parms = {
        type:this.sujectType,
      };
      cashSubject.queryAll.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          let level = 0;
          var tempObj = this.convertToTreedatas_dic(res.data.data,level);
          this.tree_data = tempObj;
          for (let i = 0; i < this.tree_data.length; i++) {
            let temp = this.tree_data[i];
            this.defaultData.push(JSON.parse(JSON.stringify(temp)))
            this.showData = [...this.defaultData];
            this.recursionData(this.defaultData);//将每一层数据都赋上title的slot,以高亮显示搜索字段
            this.setThisExpandedKeys(temp)
          }
          this.loadingVisible = false;
        }
        else{
          this.$message.error(res.data.msg)
        }
      })

    },
    getTreeData(){
      let type = this.sujectType;
      if(type == "1" || type == "2"){// 1：现金流量表；2：利润表；
        return this.tree_data;
      }else{// 3：资产负债表
        let tree_zc_data = [];
        for(let i=5;i>2;i--){
          let parms = {
            type:i,
          };
          cashSubject.queryAll.requestPOSTUrlParam(this,parms,res=>{
            if(res.data.flag){
              let level = 0;
              let dataList = this.convertToTreedatas_dic(res.data.data,level);
              if(dataList.length > 0){
                dataList.forEach((item,index)=>{
                  tree_zc_data.push(item);
                })
              }
            }
            else{
              this.$message.error(res.data.msg)
            }
          })
        }
        return tree_zc_data;
      }
    },

    /*** 树操作 start ***/
    recursionData (node) {
      node.forEach(item => {
        item.scopedSlots = { title: 'label' }
        if (item.children && item.children.length) {
          this.recursionData(item.children)
        }
      })
    },
    setThisExpandedKeys(node) {
      //只展开一级目录
      /*if (node.children && node.children.length > 0) {
        this.iExpandedKeys.push(node.id)
        //下方代码放开注释则默认展开所有节点
        /!* for (let a = 0; a < node.children.length; a++) {
           this.setThisExpandedKeys(node.children[a])
         }*!/
      }*/
    },
    onExpand(expandedKeys) {
      this.iExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    searchOnChange () {
      this.showData= [...this.defaultData];
      if (this.searchVal) {
        this.onSearch(this.searchVal);
      } else {
        this.searchValue = "";
        this.iExpandedKeys = [this.showData[0].id];
      }
    },
    onSearch(val){
      const value = val
      this.searchValue = value
      if (value != '') {
        let treeData = JSON.parse(JSON.stringify(this.showData));

        let listData = this.getkeyList(treeData, val);
        this.showData= [...listData];
        // 展开所有树数据
        this.expandAll(this.showData);
      } else {
        this.iExpandedKeys = [this.showData[0].id];
      }
    },
    //获取节点中含有value的所有key集合
    getkeyList(tree,val){
      var returnData = [];
      //遍历数据
      for(var i = 0; i < tree.length; i++){
        let tempObj =[];
        let str = tree[i].label;
        if(str.indexOf(val) > -1){
          tempObj = tree[i];
          returnData.push(tempObj);
        }else{
          if(tree[i].children != null && tree[i].children.length > 0){
            let children = this.getkeyList(tree[i].children,val);
            if(children.length > 0){
              tempObj = tree[i];
              tempObj.children = children;
              returnData.push(tempObj);
            }
          }
        }
      }

      return returnData;
    },
    expandAll (node) {
      node.forEach(item => {
        if (item.children && item.children.length) {
          this.iExpandedKeys.push(item.id)
          this.expandAll(item.children)
        }
      })
    },
    selectShow(expandedKeys,info){
    /*  let label = info.selectedNodes.length > 0 ? info.selectedNodes[0].props.label : null;
      let level = info.selectedNodes.length > 0 ? info.selectedNodes[0].props.level : null;
      let parms = {
        id:expandedKeys[0],
        label:label,
        level:level,
      };
      this.$emit("event",this.$GetEmitData(this.$eventTags.search,parms));*/
    },
    /*** 树操作 end ***/

    /**
     * 多级
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedatas_dic(data,level){
      var returnData = [];
      //遍历数据
      for(var i = 0; i < data.length; i++){
        var tempObj = {
          id:data[i].id,
          label:data[i].subjectName,
          level:data[i].level,
          sort:data[i].sort,
          parentId:data[i].parentId,
          remarks:data[i].remarks
        };

        //判断是否存在子节点，如果存在则递归
        if(data[i].childrens != null && data[i].childrens.length > 0){
          tempObj.children = this.convertToTreedatas_dic(data[i].childrens,level + 1);
        }
        //push到数据数组中
        returnData.push(tempObj);
      }
      return returnData;
    },

    flag_sujectType(node){
      let label = node.label;
      if( label.indexOf("期初现金及现金等价物余额") == -1
          && label.indexOf("期末现金及现金等价物余额") == -1){
        return true;
      }else {
        return false;
      }

    },
    refresh(){
      this.setTreeData();
      this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
    },
    addInfo(){
      this.visible = true;
      this.modalTitle = this.sujectName;
      this.argument.formData = {};
    },
    addData(data){
      this.argument.formData ={};
      this.argument.formData = {
        parentId:data.id,
      };
      this.visible = true;
      this.modalTitle = data.label +"：新增子类";
    },
    editData(data){
      this.argument.formData ={};
      this.argument.formData = {
        id:data.id,
        parentId:data.parentId,
        subjectName:data.label,
        sort:data.sort,
        remarks:data.remarks
      };

      this.visible = true;
      this.modalTitle = data.label +"：编辑信息";
    },
    /** 关联费用类别 **/
    association(data){
      this.$emit("event",this.$GetEmitData(this.$eventTags.relevancy,data));
    },
    /** 设置期初余额 **/
    period_balance(){
      this.$emit("event",this.$GetEmitData(this.$eventTags.periodBalance));
    },
    deleteData(data){
      let parms = {
        id:data.id
      };
      cashSubject.delete.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.setTreeData();
          this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    submit(){
      let parms = this.argument.formData;
      parms.type = this.sujectType;
      cashSubject.saveOrUpd.requestPOST(this,parms,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.setTreeData();
          this.visible = false;
          this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },
    get_sujectType(){
      return this.sujectType;
    },
  }

}
</script>
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.loading{
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.5);
  display:flex;
  justify-content:center;
  align-items:center;
}
</style>

