/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_collection_info  收款信息
 * @create: 2021-04-16 16:39
 **/
import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
import partyInfo from "../api/partyInfo";
import {companyColors, companyIds, companyNames} from "../system/companies";
export default class item_collection_info extends Table{
    constructor() {
        super();
        this.name = "item_collection_info";
        this.CNName = "收款信息";
        this.columnArray = [
            new Column("项目名称","itemName",ColumnType.String,true).setTableView(300),
            new Column("收款时间","collectionTime",ColumnType.Date,true).setTableView(150),
            new Column("收款金额(元)","collectionAmount",ColumnType.Number,true).setTableView(150),
            new Column("所属发票","invoiceId",ColumnType.ObjectReplace,false).setTableView(250)
                .setObjectReplaceObject("invoiceRecord","invoiceTitle",{}),
            new Column("付款方", "payerId", ColumnType.IdReplace, true).setGroup(1).setTableView(250)
                .setIdReplaceObject("payerName",{api: partyInfo.queryAll, tag: "queryText"}),
            new Column("收款方", "receiverId", ColumnType.Enum, true).setTableView(250)
                .setEnum(companyIds, companyNames,companyColors).setGroup(1),
            new Column("收款备注","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}