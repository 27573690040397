import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: software
 * @create: 2022-05-26 14:46
 **/

export default class software extends Table{
    constructor() {
        super();
        this.name = "software";
        this.CNName = "软件";
        this.columnArray = [
            new Column("工具类别","categoryName",ColumnType.String).setTableView(100),
            new Column("工具名","name",ColumnType.String,true).setTableView(100),
            new Column("功能说明","functionExplain",ColumnType.String,true).setTableView(300),
            new Column("更新时间","developDate",ColumnType.Date,true).setTableView(100),
            new Column("版本","remarks",ColumnType.String,true).setTableView(100),
            new Column("示例图片","imageUrl",ColumnType.PreViewFile).setTableView(100),
            new Column("示例视频","videoUrl",ColumnType.PreViewFile).setTableView(100),
            new Column("作者","developer",ColumnType.String,true).setTableView(100),
            //new Column("软件下载","fileUrl",ColumnType.String).setTableView(100),

        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}