/** 
* 自动生成文件，请勿修改 
* @name: 支付:外协支付申请审批流水记录
* @description: 外协支付申请审批流水记录（三期新增）
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const payApproval = { 
	/** 1 summary:查询所有（可根据申请id查询该申请的审核流水状态）	method:post */ 
	queryAll : new APIObject("/api/payApproval/queryAll.do"), 
	/** 2 summary:分页查询（可根据申请id查询该申请的审核流水状态）	method:post */ 
	queryPage : new APIObject("/api/payApproval/queryPage.do"), 
	/** 3 summary:审核支付申请	method:post */ 
	approvalApply : new APIObject("/api/payApproval/approvalApply.do"), 
	/** 4 summary:保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/payApproval/saveOrUpd.do"), 
	/** 5 summary:批量删除	method:post */ 
	batchDelete : new APIObject("/api/payApproval/batchDelete.do"), 
	/** 6 summary:批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/payApproval/batchSaveOrUpd.do"), 
	/** 7 summary:单条删除	method:post */ 
	delete : new APIObject("/api/payApproval/delete.do"), 
	/** 8 summary:单条数据查询	method:post */ 
	queryOne : new APIObject("/api/payApproval/queryOne.do"), 
};
export default payApproval;