<!--
* @program: tyh-oa
* @author: ly
* @description: projectManager
* @create: 2021-03-22 10:22
-->
<template>
    <a-layout style="height: 100%" >
        <!-- 侧面菜单 -->
        <a-layout-sider :collapsible="true" :collapsedWidth="40" style="overflow: auto;height:100%">
            <a-menu
                    theme="dark"
                    mode="inline"
                    v-model:openKeys="openKeys"
                    v-model:selectedKeys="selectedKeys"
                    @openChange="onOpenChange"
                    @click="handleClick">
                <sub-group-menu v-for="(group,index) in menuLists" :index="index" :groupMenus="group" :key="group.id" />
            </a-menu>
        </a-layout-sider>
        <!-- 右侧框体 -->
        <a-layout-content class="main-window">
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import menuContent from "../../components/menuContent";
    import projectViewer from "./projectViewer/projectViewer";
    import projectIncomeViewer from "./projectIncomeViewer/projectIncomeViewer";
    import projectIncomeViewer2 from "./projectIncomeViewer/projectIncomeViewer2";
    import projectOutSourcePayViewer from "./projectOutSourcePayViewer/projectOutSourcePayViewer";
    import projectTaskViewer from "./projectTaskViewer/projectTaskViewer";
    import departmentTaskViewer from "./departmentTaskViewer/departmentTaskViewer";
    import projectShower from "./projectViewer/projectShower";
    import invoiceManagerIncome from "../financialManager/invoiceManager/invoiceManagerIncome";
    import projectTaskShower from "./projectTaskViewer/projectTaskShower";
    import projectDelayManager from "./projectDelayManager/projectDelayManager";
    import projectRegister from "./projectViewer/projectRegister";
    import projectAudit from "./projectViewer/projectAudit";
    import workerProcessShower from "./workerProcessShower/workerProcessShower";
    import workerProcessShowerAll from "./workerProcessShower/workerProcessShowerAll";
    import ContractLister from "./ContractLister/ContractLister";
    import DepartmentAccount from "./DepartmentAccount/DepartmentAccount";
    import ProjectFullFilled from "./ProjectFullFilled/ProjectFullFilled";
    import departmentDelayManager from "./departmentTaskViewer/departmentDelayManager";
    import DepartmentAudits from "./DepartmentAudits/DepartmentAudits";
    import ImportantProject from "./ImportantProject/ImportantProject";
    import projectDesignBookAudit from "./projectRelateInfo/projectDesignBookAudit";
    import projectSummaryAnalyze from "./projectSummary/projectSummaryAnalyze";
    import performance from "./DepartmentAccount/performance";
    import performanceSummary from "./honour/performanceSummary";
    import deptUserPerformance from "./userPerformance/deptUserPerformance";
    //import userPerformance from "./userPerformance/userPerformance";
    import userPerformanceMange from "./userPerformance/userPerformanceMange";
    import DepartmentReport from "./weeks/DepartmentReport";
    import projectWeekReport from "./weeks/projectWeekReport";

    export default {
        name: "projectManager",
        mixins:[menuContent],
        components:{
          projectViewer,// 项目管理
          projectIncomeViewer,// 项目收款统计
          projectOutSourcePayViewer,// 外协支付审核
          projectTaskViewer,// 项目任务分配
          departmentTaskViewer,// 任务管理
          projectShower,// 项目一览
          invoiceManagerIncome,// 发票管理
          projectTaskShower,// 项目任务一览
          projectDelayManager,// 延期审核
          projectRegister,// 项目创建
          projectAudit,// 项目审核
          workerProcessShower,// 成员情况一览
          workerProcessShowerAll,// 员工工作日历
          projectIncomeViewer2,// 项目收支管理
          ContractLister,// 合同管理
          DepartmentAccount,// 绩效池
          ProjectFullFilled,// 项目完结申请
          departmentDelayManager,// 部门延期审核
          DepartmentAudits, //部门审核功能
          ImportantProject,//重大项目列表
          projectDesignBookAudit,// 项目设计书审核
          projectSummaryAnalyze,// 项目概况
          performance,// 绩效一览
          performanceSummary,// 项目资金情况管理
          deptUserPerformance,// 部门人员收入
          //userPerformance,// 公司人员绩效
          userPerformanceMange,// 人员绩效管理
            DepartmentReport,// 部门周报
            projectWeekReport,// 项目周报


        }
    }
</script>

<style scoped>

</style>
