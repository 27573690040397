<!--
* @program: office_automation 
* @author: ly
* @component:StaffPromotionAudit 
* @description: 员工晋升审核
* @create: 2022-03-08 15:52
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <span>审核状态：</span>
                <a-radio-group class="search-item" v-model:value="auditSuccess" @change="init">
                    <a-radio value="0">未审核</a-radio>
                    <a-radio value="1">审核未通过</a-radio>
                    <a-radio value="2">审核通过</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts"  @change="handleChange" pos="transform"  >
                <template #left>
                    <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="pageSize">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button v-if="record.success !=='0'"  class="action-button"  type="link" @click="showData(record)">
                                    <SearchOutlined  class="pointer"/>
                                </a-button>
                                <a-button v-else  class="action-button"  type="link" @click="editData(record)">
                                    审核
                                </a-button>
                                <a-tooltip title="下载晋升评价表">
                                    <a-button v-if="record.success ==='2'"  class="action-button"  type="link" @click="downLoad(record)">
                                        <ArrowDownOutlined  class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </ArBaseTableFilter>
                </template>
                <template #right>
                    <div v-if="currentRecord">
                        <a-page-header style="border: 1px solid rgb(235, 237, 240)">
                            <template #title>
                                <a-button @click="showHistory">
                                    晋升历史
                                </a-button>
                                <span style="margin-left: 20px">晋升数据：</span>
                                <a-radio-group button-style="solid" v-model:value="comp" >
                                    <a-radio-button value="PromotionInfo">评价表</a-radio-button>
                                    <a-radio-button value="PromotionPoints">分项得分表</a-radio-button>
                                </a-radio-group>
                                <span style="margin-left: 20px" v-if="comp === 'PromotionPoints'">若未指定【同事评分】的人则需要手动指定评分人</span>
                            </template>
                        </a-page-header>
                        <div v-is="comp" :view-model="viewModel" v-model:value="currentRecord" :userType="'judgeView'"/>
                    </div>
                    <welcome2 v-else/>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :width="800"
             :footer="null"
             title="晋升历史">
        <span>晋升详情请在【审核通过】的记录里面进行查看</span>
        <ArBaseTableFilter :argument="argument2" :view-model="true" :page-size="pageSize" />
    </a-modal>
</template>

<script>
    import Welcome2 from "../../../welcome2";
    import ArBaseTableFilter from "../../../../components/table/ArBaseTableFilter";
    import TwoParts from "../../../_components/TwoParts";
    import {
        EditOutlined,
        SearchOutlined,
        SyncOutlined,ArrowDownOutlined
    } from "@ant-design/icons-vue";
    import promotion_audit from "../../../../assets/tables/promotion_audit";
    import promotionApply from "../../../../assets/api/promotionApply";
    import PromotionInfo from "./PromotionInfo";
    import Column from "../../../../assets/tables/parts/column";
    import ColumnType from "../../../../assets/tables/parts/column_type";
    import PromotionPoints from "./PromotionPoints";
    import UserPicker from "../../../../components/UserPicker/UserPicker";

    export default {
        name: "StaffPromotionAudit",
        components: {
            Welcome2,
            ArBaseTableFilter,TwoParts,SearchOutlined,SyncOutlined,ArrowDownOutlined,
            EditOutlined,PromotionInfo,PromotionPoints,UserPicker
        },
        data(){
            return{
                table:new promotion_audit(),
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                argument2:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("申请人","userName",ColumnType.String).setTableView(80),
                        new Column("现岗位","currentLevel",ColumnType.PositionLevel).setTableView(100),
                        new Column("晋升岗位","applyLevel",ColumnType.PositionLevel).setTableView(100),
                        new Column("申请时间","applyTime",ColumnType.Date).setTableView(80),
                        new Column("是否通过审核","success",ColumnType.Enum,true).setTableView(80)
                            .setEnum(["0","1","2"],["未审核","未通过审核","审核通过"],["pink","red","green"]),
                        new Column("生效日期","effectDate",ColumnType.Date,true).setTableView(80),
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                currentRecord:null,
                visible:false,
                auditSuccess:"0",
                comp:"PromotionPoints",
            }
        },
        methods:{
            init(){
                let data = {
                    submitState:true,
                    success:this.auditSuccess
                };
                this.argument.loading = true;
                this.currentRecord = null;
                promotionApply.queryAll.requestPOST(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            refresh(){
                this.init();
            },
            showData(record){
                this.currentRecord = record;
                this.viewModel = true;
            },
            editData(record){
                this.viewModel = false;
                this.currentRecord = record;
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.argument.tableColumns = [
                        new Column("申请人","userName",ColumnType.String).setTableView(80),
                        new Column("晋升岗位","applyLevel",ColumnType.PositionLevel).setTableView(100),
                        new Column("操作","actions","actions").setTableView(60)
                    ]
                } else {
                    this.argument.tableColumns = this.table.getTableColumns();
                }
            },
            showHistory(){
                this.visible = true;
                let data = {
                    submitState:true,
                    success: "2", // 0"未审核",1"未通过审核",2"审核通过"
                    userId: this.currentRecord.userId,
                };
                this.argument2.loading = true;
                promotionApply.queryAll.requestPOST(this,data,res=>{
                    this.argument2.loading = false;
                    if(res.data.flag){
                        this.argument2.dataSource = res.data.data;
                    }
                    else{
                        this.argument2.dataSource = [];
                    }
                })
            },
            downLoad(record){
                let fileName = record.userName + "【" +record.applyLevel+ "晋升】.xls";
                let data = {
                    id:record.id
                };
                promotionApply.exportById.fileDownload(this,data,fileName);
            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.init();
        }
    }
</script>

<style scoped>

</style>