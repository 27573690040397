<!--
* @program: TYH_office_automation 
* @author: ly
* @component:taskProcessList 
* @description: 部门任务
* @create: 2021-07-01 11:38
-->
<template>
    <div>
        <span v-if="argument4.formData.taskLeaderName">任务负责人：{{argument4.formData.taskLeaderName}}</span>
        <a-tooltip v-if="!viewModel && !record.taskLeaderFlag"  title="指定任务负责人">
            <a-button class="search-item" type="dashed" shape="circle" @click="modalAddOwner">
                <template #icon><UserAddOutlined /></template>
            </a-button>
        </a-tooltip>
        <a-tooltip v-if="!viewModel"  title="添加任务工序">
            <a-button class="search-item" type="dashed" shape="circle" @click="modalAdd">
                <template #icon><PlusOutlined /></template>
            </a-button>
        </a-tooltip>
        <a-tooltip   title="任务分配详情">
            <a-button  class="action-button" type="dashed" shape="circle" @click="showDetailAll">
                <OrderedListOutlined class="pointer" style="color: #1890ff"/>
            </a-button>
        </a-tooltip>
        <a-tooltip   title="查看每个工序完成进度">
            <a-button  class="action-button" type="dashed" shape="circle" @click="progressShow">
                <PieChartOutlined class="pointer" style="color: #1890ff"/>
            </a-button>
        </a-tooltip>
        <div >每个任务工序的占比为：本工序的【工作量权重】/所有工序的【工作量权重】合计，目前工作量权重合计：<span style="color: red">{{percentSum}}</span>。任务预计完成时间为<span style="color: red">{{new Date(record.endDate).toLocaleDateString()}}</span></div>
        <ArBaseTable  :view-model="true" :page-size="15" :argument="argument">
            <template #action="{index,record}">
                <div class="action-column">
                    <a-button class="action-button" type="link" @click="showDetail(index,record)">
                        <SearchOutlined class="pointer"/>
                    </a-button>
                    <a-tooltip  title="工序编辑">
                        <a-button v-if="!viewModel"  class="action-button" type="link" @click="editDetail(index,record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip  title="工序人员分配情况">
                        <a-button  class="action-button" type="link" @click="addUser(record)">
                            <TeamOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip  title="查看工序完成进度">
                        <a-button  class="action-button" type="link" @click="processProgressShow(record)">
                            <PieChartOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip  title="提交工序完成">
                        <a-button v-if="!viewModel"  class="action-button" type="link" @click="finishProcess(record)">
                            <CheckOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip  title="工序延期申请">
                        <a-button  class="action-button" type="link" @click="delayApply(index,record)">
                            <FallOutlined  style="color: red" class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-popconfirm
                            v-if="!viewModel"
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            title="确定删除吗?"
                            @confirm="deleteData(index,record)">
                        <DeleteOutlined  style="color: red" class="pointer"/>
                    </a-popconfirm>
                </div>
            </template>
        </ArBaseTable>
    </div>
    <a-drawer v-model:visible="visible2"
              height="400px"
              placement="bottom"
              :closable="false"
    >
        <template #title>
            <div class="center-flex">
                <a-tooltip v-if="!viewModel && percentSum2 !== 100"  title="工序安排">
                    <a-button class="search-item" type="dashed" shape="circle" @click="modalUserAdd">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                </a-tooltip>
                <div>【{{currentProcess.title}}】工作量已安排：<span style="color: red">{{percentSum2}}%</span></div>
            </div>
        </template>
        <ArBaseTable @showRecord="showDetail2" :view-model="true" :page-size="5" :argument="argument2">
            <template #action="{index,record}">
                <div class="action-column">
                    <a-button class="action-button" type="link" @click="showDetail2(index,record)">
                        <SearchOutlined class="pointer"/>
                    </a-button>
                    <a-button v-if="!viewModel"  class="action-button" type="link" @click="editDetail2(index,record)">
                        <EditFilled class="pointer"/>
                    </a-button>
                    <a-tooltip title="录入外协工作量">
                        <a-button v-if="!viewModel && record.personType !=='1'"  class="action-button" type="link" @click="addWorkProcess(record)">
                            <CalendarOutlined  class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-popconfirm
                            v-if="!viewModel"
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            title="确定删除吗?"
                            @confirm="deleteData2(index,record)">
                        <DeleteOutlined  style="color: red" class="pointer"/>
                    </a-popconfirm>
                </div>
            </template>
        </ArBaseTable>
    </a-drawer>
    <a-modal v-model:visible="visible"
             destroy-on-close
             :maskClosable="vModel"
             title="任务工序"
             :footer="null">
        <div v-if="vModel">工序占比：{{workRatio.toFixed(2)}}%</div>
        <div v-else>工序分配完毕后，起止日期禁止修改，延期需要提交审核</div>
        <ArBaseForm :view-model="vModel" :argument="argument" >
            <template #action="{record}">
                <a-button class="search-item" type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>

    <a-modal v-model:visible="visible3"
             destroy-on-close
             :maskClosable="vModel"
             title="工序安排"
             :footer="null">
        <ArBaseForm :view-model="vModel" :argument="argument2" >
            <template #action="{record}">
                <a-button class="search-item" type="primary"  @click="saveData2(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>

    <a-modal v-model:visible="visible4"
             destroy-on-close
             title="任务人员分配一览"
             :width="800"
             :footer="null">
        <ArBaseTable :view-model="true" :argument="argument3" :page-size="15"/>
    </a-modal>

    <a-modal v-model:visible="visible5"
             destroy-on-close
             title="指定任务负责人"
             :width="400"
             :footer="null">
        <ArBaseForm :view-model="false" :argument="argument4" >
            <template #action="{record}">
                <a-button class="search-item" type="primary"  @click="saveData3(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>

    <a-modal v-model:visible="visible6"
             destroy-on-close
             title="完成情况"
             :width="600"
             :footer="null">
        <taskProcessProgressChart :record="currentProcess" :visible="true" />
    </a-modal>
    <a-drawer v-model:visible="visible7"
              width="1000px"
              placement="right"
              :closable="false"
              title="完成情况"
    >
        <taskProgressChart :records="argument.dataSource" />
    </a-drawer>

    <a-modal v-model:visible="visible8"
             destroy-on-close
             :maskClosable="vModel"
             title="工序完成"
             :footer="null">
        <FcBaseForm :view-model="vModel" :form-columns="formColumns" :form-data="currentProcess" >
            <template #action="{record}">
                <a-button class="search-item" type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </FcBaseForm>
    </a-modal>

    <a-modal v-model:visible="visible9"
             destroy-on-close
             :maskClosable="vModel"
             title="工序完成"
             :width="800"
             :footer="null">
        <delayApply :record="currentProcess" type="2" :dead-line="record.endDate"/>
    </a-modal>
    <addWorkProcess ref="addWorkProcess" />
</template>

<script>
    import {
        DeleteOutlined,
        EditFilled,
        OrderedListOutlined,
        PlusOutlined,
        SearchOutlined,
        TeamOutlined,
        UserAddOutlined,
        PieChartOutlined,CheckOutlined,FallOutlined,
        CalendarOutlined
    } from "@ant-design/icons-vue";
    import item_task_process from "../../../assets/tables/item_task_process";
    import item_task_process_2 from "../../../assets/tables/item_task_process_2";
    import api_item_task_process from "../../../assets/api/itemTaskProcess";
    import item_task_process_employee from "../../../assets/tables/item_task_process_employee";
    import api_item_task_process_employee from "../../../assets/api/processEmployee";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import api_item_task from "../../../assets/api/itemTask";
    import userInfo from "../../../assets/api/userInfo";
    import taskProcessProgressChart from "./taskProcessProgressChart";
    import taskProgressChart from "./taskProgressChart";
    import FcBaseForm from "../../../components/form/FcBaseForm";
    import delayApply from "../projectDelayManager/delayApply";
    import addWorkProcess from "./addWorkProcess";
    export default {
        name: "taskProcessList",
        props:["viewModel","record"],
        emits:["refresh"],
        components:{
            PlusOutlined,ArBaseForm,
            ArBaseTable,DeleteOutlined,SearchOutlined,EditFilled,TeamOutlined,OrderedListOutlined,
            UserAddOutlined,PieChartOutlined,taskProcessProgressChart,taskProgressChart,CheckOutlined,
            FcBaseForm,FallOutlined,delayApply,CalendarOutlined,
            addWorkProcess
        },
        computed:{
            percentSum(){
                let sum = 0;
                for(let i in this.argument.dataSource){
                    sum += this.argument.dataSource[i].workRatio;
                }
                return sum;
            },
            percentSum2(){
                let sum = 0;
                for(let i in this.argument2.dataSource){
                    sum += this.argument2.dataSource[i].workRatio;
                }
                return sum;
            },
            workRatio(){
                try{
                    return this.argument.formData.workRatio / this.percentSum * 100
                }
                catch (e) {
                    return 0
                }
            }
        },
        data(){
            return{
                table:null,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态

                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                table2:null,
                argument2:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态

                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                visible2:false,
                visible3:false,
                currentProcess:{},
                vModel:true,
                visible4:false,
                argument3: {
                    dataSource: null,//提供table的DataSource
                    tableColumns: [
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("工序名称","processTitle",ColumnType.String).setTableView(100),
                        new Column("人员类别","personType",ColumnType.Number).setTableView(80)
                            .setEnum(["1","2","3"],["内部员工","外协单位","外协人员"],["green","purple","purple"]),
                        new Column("工序内容","content",ColumnType.String).setTableView(100),
                        new Column("分配人员","userName",ColumnType.String).setTableView(100),
                        new Column("人员工序占比","workRatio",ColumnType.Number)
                            .setNumberRange(0,100).setTableView(100)
                            .setNumberFormatter(value => `${value}%`,value => value.replace('%', '')),
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading: false,//table的loading状态
                },

                visible5:false,
                argument4:{
                    formColumns:[
                        new Column("任务负责人","taskLeader",ColumnType.IdReplace,true)
                            .setIdReplaceObject("taskLeaderName",{api:userInfo.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
                    ], //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible6:false,
                visible7:false,
                visible8:false,
                formColumns:new item_task_process_2().getFormColumns(),

                visible9:false,
            }
        },
        methods:{
            init(){
                this.table = new item_task_process();
                /**
                 * 设定允许选择的时间范围
                 * */
                let sDate = this.table.columnArray.filter(item=>item.dataIndex ==="startDate")[0];
                let eDate = this.table.columnArray.filter(item=>item.dataIndex ==="endDate")[0];
                sDate.setEnableTime([this.record.startDate,this.record.endDate]);
                eDate.setEnableTime([this.record.startDate,this.record.endDate]);


                this.argument.tableColumns = this.table.getTableColumns();
                this.argument.formColumns = this.table.getFormColumns();
                let data = {taskId:this.record.id};
                api_item_task_process.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                });
                this.table2 = new item_task_process_employee();
                if(this.viewModel){
                    this.table2.actions=[]
                }
                this.argument2.tableColumns = this.table2.getTableColumns();
                this.argument2.formColumns = this.table2.getFormColumns();
            },
            modalAdd(){
                this.visible = true;
                this.vModel = false;
                this.argument.formColumns = this.table.getFormColumns();
                this.argument.formData = this.table.getFormData();
                this.argument.formData.startDate = this.record.startDate;
                this.argument.formData.endDate = this.record.endDate;
            },
            showDetail(index,record){
                this.argument.formColumns = this.table.getFormColumns();
                this.argument.formData = record;
                this.visible = true;
                this.vModel = true;
            },
            editDetail(index,record){
                /** 是否允许修改工序时间*/
                // this.argument.formColumns = [
                //     new Column("工序标题","title",ColumnType.String,true),
                //     new Column("工序内容","content",ColumnType.String,true),
                //     new Column("工作量权重","workRatio",ColumnType.Number,true).setNumberRange(0,200),
                //     new Column("备注","remarks",ColumnType.String,true).setTableView(100)
                // ];
                this.argument.formData = record;
                this.visible = true;
                this.vModel = false;
            },
            addUser(record){
                this.visible2 = true;
                this.currentProcess = record;
                let data = {processId:this.currentProcess.id};
                this.argument2.loading = true ;
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    this.argument2.loading = false ;
                    if(res.data.flag){
                        this.argument2.dataSource = res.data.data;
                    }
                    else{
                        this.argument2.dataSource=[];
                    }
                });
            },
            deleteData(index,record){
                let data = {id:record.id};
                api_item_task_process.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            modalUserAdd(){
                this.visible3 = true;
                this.vModel = false;
                this.argument2.formData = this.table2.getFormData();
                this.argument2.formData.startDate = this.currentProcess.startDate;
                this.argument2.formData.endDate = this.currentProcess.endDate;
            },
            showDetail2(index,record){
                this.argument2.formData = record;
                this.visible3 = true;
                this.vModel = true;
            },
            editDetail2(index,record){
                this.argument2.formData = record;
                this.visible3 = true;
                this.vModel = false;
            },
            addWorkProcess(record){
                this.$refs.addWorkProcess.open(record);
            },
            saveData(record){
                record.taskId = this.record.id;
                api_item_task_process.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible8 = false;
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData2(record){
                record.processId = this.currentProcess.id;
                api_item_task_process_employee.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.initProcessTable();
                        this.visible3 = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData2(index,record){
                let data = {id:record.id};
                api_item_task_process_employee.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument2.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            initProcessTable(){
                let data = {processId:this.currentProcess.id};
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument2.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            showDetailAll(){
                let data = {taskId:this.record.id};
                this.visible4 = true;
                this.argument3.loading = true;
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    this.argument3.loading = false;
                    if(res.data.flag){
                        this.argument3.dataSource = res.data.data;
                    }
                    else{
                        this.argument3.dataSource = [];
                        this.$message.error(res.data.msg)
                    }
                });
            },
            modalAddOwner(){
                this.visible5 = true;
            },
            saveData3(record){
                api_item_task.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.visible5 = false;
                        this.$emit("refresh");
                        this.argument4.formData = record;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            progressShow(){
                this.visible7 = true;
            },
            processProgressShow(record){
                this.visible6 = true;
                this.currentProcess = record;
            },
            finishProcess(record){
                this.visible8 = true;
                this.currentProcess = record;
                this.vModel = false;
            },
            delayApply(index,record){
                this.currentProcess = record;
                this.visible9 = true;
            }
        },
        created() {
            this.init();
            this.argument4.formData = {...this.record};
        }

    }
</script>

<style scoped>

</style>