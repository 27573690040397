<!--
* @program: office_automation 
* @author: ly
* @component:ImportantProject 
* @description: 
* @create: 2022-06-14 16:43
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <a-button  class="search-item" type="default" shape="circle" @click="init">
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <a-button  class="search-item" type="dashed" shape="circle" @click="addData">
                    <template #icon><PlusOutlined /></template>
                </a-button>
                <MonthPicker @change="init"  style="width: 120px" v-model:value="yearMonth" />
            </div>
            <TwoParts  ref="TwoParts" @change="handleChange"  :trans="[100,25]" pos="transform">
                <template #left>
                    <TcBaseTable :download="true" ref="project_table" :view-model="true" :page-size="pageSize"
                                 :loading="loading" :data-source="dataSource" :table-columns="tableColumns">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button  class="action-button" type="link" @click="showDetail(record)">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-button  class="action-button" type="link" @click="editDetail(record)">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(index,record)">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <project-tabs  ref="local_tabs"/>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             :footer="null"
             title="重大项目">
        <FcForm :view-model="viewModel" :form-columns="formColumns" :form-data="formData">
            <template #action="{record}">
                <a-button class="search-item" type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-modal>
</template>

<script>
    import projectTabs from "../projectViewer/projectTabs";
    import TwoParts from "../../_components/TwoParts";
    import {
        EditFilled,
        SearchOutlined,
        SyncOutlined,
        PlusOutlined,DeleteOutlined
    } from "@ant-design/icons-vue";
    import TcBaseTable from "../../../components/table/TcBaseTableFilter";
    import important_item from "../../../assets/tables/important_item";
    import FcForm from "../../../components/form/FcForm";
    import MonthPicker from "../../_components/MonthPicker";
    import moment from "moment";
    import itemExtra from "../../../assets/api/itemExtra";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    export default {
        name: "ImportantProject",
        components:{projectTabs,TwoParts,SearchOutlined,SyncOutlined,EditFilled,
            TcBaseTable,PlusOutlined,DeleteOutlined,FcForm,MonthPicker
        },
        data() {
            return {
                tableColumns:new important_item().getTableColumns(),
                dataSource:[],
                pageSize:15,
                loading:false,
                formColumns:new important_item().getFormColumns(),
                formData:null,
                visible:false,
                viewModel:true,
                yearMonth:null
            }
        },
        methods: {
            init(){
                let data = {
                    month:this.yearMonth
                };
                this.loading = true;
                itemExtra.queryAll.requestPOST(this,data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        for(let i in this.dataSource){
                            this.dataSource[i].departments = "";
                            if(this.dataSource[i].taskList && this.dataSource[i].taskList.length>0){
                                let departments = [];
                                for(let j in this.dataSource[i].taskList){
                                    if(this.dataSource[i].taskList[j].pid === "0"){ //暂时只记录主任务部门
                                        departments.push(this.dataSource[i].taskList[j].departmentName);
                                    }
                                }
                                this.dataSource[i].departments = departments.toString()
                            }
                        }
                    }
                    else{
                        this.dataSource = [];
                    }
                })
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.tableColumns = [
                        //new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                        new Column("项目","itemName",ColumnType.String,false).setTableView(200),
                        new Column("操作", "actions", "actions", false).setTableView(80)
                    ]
                } else {
                    this.tableColumns = new important_item().getTableColumns();
                }
            },
            addData(){
                this.visible = true;
                this.formData = new important_item().getFormData();
                this.viewModel = false;
            },
            showDetail(record){
                this.viewModel = true;
                this.formData = record;
                this.$refs.local_tabs.handleOpen([
                    {tag:"项目基本信息",component:"projectInfo",viewModel:true,projectId:record.itemId},
                    {tag:"项目任务",component:"projectTaskInfo",viewModel:true,projectId:record.itemId},
                    {tag:"项目合同信息",component:"projectContract",viewModel:true,projectId:record.itemId},
                    {tag:"项目消耗一览",component:"projectUseUp",viewModel:true,projectId:record.itemId},
                    {tag:"项目收款一览",component:"projectIncome",viewModel:true,projectId:record.itemId},
                    {tag:"项目支付一览",component:"projectOutSourcePay",viewModel:true,projectId:record.itemId},
                    {tag:"项目收入发票",component:"projectInvoice",viewModel:true,projectId:record.itemId},
                    {tag:"项目支出发票",component:"projectInvoicePayoff",viewModel:true,projectId:record.itemId},
                    {tag:"质检情况",component:"QCManager",viewModel:true,projectId:record.itemId},
                ]);
                this.$refs.TwoParts.transform();
            },
            editDetail(record){
                this.formData = record;
                this.visible = true;
                this.viewModel = false;
            },
            deleteData(index,record){
                let data ={id:record.id};
                itemExtra.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record){
                record.majorFlag = "1";
                itemExtra.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        created() {
            this.yearMonth =  moment(new Date().valueOf()).format("YYYY-MM");
            this.init()
        }
    }
</script>

<style scoped>

</style>