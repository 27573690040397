import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: DepIncomePay
 * @create: 2022-04-11 14:14
 **/

export default class DepIncomePay extends Table{
    constructor() {
        super();
        this.name = "DepIncomePay";
        this.CNName = "部门其他收入/支出管理";
        this.columnArray = [
            new Column("类型","type",ColumnType.Enum).setTableView(100)//类型：1：收入；2：支出
                .setEnum(["1","2"],["收入","支出"],["blue","red"]).setDefaultValue("2"),
            new Column("金额","amount",ColumnType.Number,true).setTableView(100),
            new Column("发生日期","happenDate",ColumnType.Date,true).setTableView(100),
            new Column("说明","describe",ColumnType.String,true).setTableView(300),
        ];
        this.actions = new Column("操作","actions","actions").setTableView(200);
    }
}