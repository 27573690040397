<!--
* @program: office_automation 
* @author: ly
* @component:MonthArrangeAdd 
* @description: 
* @create: 2022-05-20 16:22
-->
<template>
    <a-modal v-model:visible="visible"
             width="900px"
             :footer="null"
             title="月度工作量分配">
        <a-spin :spinning="spinning">
            <div>
                <a-button style="margin: 0 5px" type="primary" @click="computeProcess">
                    查询项目月进度
                </a-button>
                <span>项目月进度：</span>
                <span v-for="item in taskMonthPercent" :key="item">
                    <span v-if="item.yearMonth === yearMonth">{{item.yearMonth}}:【{{item.percent.toFixed(2)}}% | {{((task.depManagExp+task.leaderPercent)===0)?"无法自动填入,请提示部门负责人下发绩效":""}}】</span>
                </span>
                <a-button v-if="taskMonthPercent.length>0" style="margin: 0 5px" type="primary" @click="autoFill" >
                    自动填入
                </a-button>
            </div>
            <div>
                <span>月份：</span><month-picker style="width: 200px" v-model:value="yearMonth" />
                <span style="margin: 0 5px">分配工作量占比（当月/总量）：</span>
                <a-input-number
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                        style="width: 100px" :max="100" :min="0"  @change="setArrangePercent" v-model:value="arrangePercent" />
            </div>
            <div style="display: flex;flex-wrap: wrap;">
                <a-card v-for="item in moneyArrange" :key="item.id" style="width: 250px">
                    <template #title>
                        {{item.name}}
                    </template>
                    <a-form layout="horizontal">
                        <a-form-item label="占比(当月)">
                            <a-input-number
                                    :formatter="value => `${value}%`"
                                    :parser="value => value.replace('%', '')"
                                    style="width: 100px" :max="100"  v-model:value="item.completeRatio" />
                        </a-form-item>
<!--                            <a-form-item label="占比(总量)">-->
<!--                                <a-input-number style="width: 100px" :max="100"   v-model:value="item.completeRatio" />-->
<!--                                {{item.completeRatio}}-->
<!--                            </a-form-item>-->
<!--                        <a-form-item label="作业绩效">-->
<!--                            <a-input-number :max="arrangeNum" style="width: 100px" @change="setCompleteMoney(item)" v-model:value="item.completeMoney" />-->
<!--                        </a-form-item>-->
<!--                        <a-form-item label="负责人绩效">-->
<!--                            <a-input-number :max="task.leaderPercent" style="width: 100px" @change="setLeaderMoney(item)"  v-model:value="item.leaderMoney" />-->
<!--                        </a-form-item>-->
                        <a-form-item label="说明">
                            <a-textarea style="width: 100px" v-model:value="item.remarks" />
                        </a-form-item>
                    </a-form>
                </a-card>
            </div>
            <a-button  style="padding: 0 5px" type="primary" @click="saveData">
                保存
            </a-button>
        </a-spin>
    </a-modal>
</template>

<script>
    import MonthProcessArrangeAdd from "../../projectManager/departmentTaskViewer/MonthProcessArrangeAdd3";
    import taskMonthUser from "../../../assets/api/taskMonthUser";
    import {fixedNumber} from "../../../assets/utils/general";

    export default {
        name: "MonthArrangeAdd",
        mixins:[MonthProcessArrangeAdd],
        methods:{
            saveData(){
                for(let i in this.moneyArrange){
                    this.moneyArrange[i].yearMonth = this.yearMonth;
                    this.moneyArrange[i].confirmMoney = 0;
                    taskMonthUser.saveOrUpd.requestPOST(this,this.moneyArrange[i],res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                this.spinning = true;
                setTimeout(()=>{
                    this.spinning = false;
                    this.visible = false;
                    this.$emit("refresh");
                },1500)
            },
            autoFill(){
                if(!this.yearMonth){
                    this.$message.error("请选择月份");
                    return
                }
                let leaderMonthPercent = this.taskMonthPercent.filter(item=>item.yearMonth === this.yearMonth);
                this.arrangePercent = 0;
                if(leaderMonthPercent.length>0){
                    this.arrangePercent = leaderMonthPercent[0].percent;

                }
                for(let i in this.members){
                    if(this.members[i].leader){//项目负责人
                        let arr = this.moneyArrange.filter(item=>item.userId === this.members[i].id);
                        if(arr.length>0){
                            let MonthPercent = this.members[i].taskMonthPercent.filter(item=>item.yearMonth === this.yearMonth);
                            if(MonthPercent.length>0 ){
                                arr[0].completeMoney = MonthPercent[0].percent * this.task.depManagExp/100;
                                arr[0].leaderMoney = this.arrangePercent * this.task.leaderPercent/100;
                                arr[0].completeRatio = fixedNumber(MonthPercent[0].percent /this.arrangePercent *100);

                                arr[0].completeMoney = 0;
                                arr[0].leaderMoney = 0 ;
                            }
                        }
                    }
                    else{
                        let arr = this.moneyArrange.filter(item=>item.userId === this.members[i].id);
                        if(arr.length>0){
                            let MonthPercent = this.members[i].taskMonthPercent.filter(item=>item.yearMonth === this.yearMonth);
                            if(MonthPercent.length>0){
                                arr[0].completeMoney = MonthPercent[0].percent * this.task.depManagExp/100;
                                arr[0].completeRatio = fixedNumber(MonthPercent[0].percent /this.arrangePercent *100);
                                arr[0].completeMoney = 0;
                            }
                        }
                    }
                }
                this.setArrangePercent();
            }
        }


    }
</script>

<style scoped>

</style>