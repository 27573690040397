/**
 * @description：
 * @author:rzl
 * @date:2022/8/9 13:46
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type";

export default class invoiceRecordList_item extends Table {
    constructor() {
        super();
        this.name = "invoiceRecordList_item";
        this.CNName = "销项发票";
        this.columnArray = [
            new Column("项目名称","itemName",ColumnType.String,true).setTableView(150),
            /*new Column("发票标题","invoiceTitle",ColumnType.String,true).setTableView(150),*/
            new Column("开票金额","invoiceAmount",ColumnType.Number,true).setTableView(80),
            new Column("开票日期","invoiceTime",ColumnType.Date,true).setTableView(80),
            new Column("发票类型","invoiceType",ColumnType.Enum,true).setTableView(80)
                .setEnum(["1","2"],
                    ["普票","专票"],
                    ["Pink","MediumPurple","CornflowerBlue","PowderBlue"]),
            new Column("收款状态","collectState",ColumnType.Enum,true).setTableView(80)
                .setEnum(["0","1","2"],
                    ["未收款","已收款","部分收款"],
                    ["MediumPurple","CornflowerBlue","PowderBlue"]),
            new Column("甲方名称","partyInfoName",ColumnType.String,true).setTableView(150),
        ];

    }
}