/**
 * @program: office_automation
 * @author: ly
 * @description: contractType
 * @create: 2022-12-26 17:06
 **/

const value = ["1","2","3","4","5","6","7"];
const label = ["甲方合同","外协合同","中标通知书","三方合同","三方外协合同","个人支付说明","无需合同情况说明"];
const color = ["blue","purple","violet","purple","yellow","red","purple"];

const contractType = {value,label,color};
export default contractType;

