<!--
* @program: office_automation 
* @author: ly
* @component:projectOutSourcePayAuditStatus 
* @description: 查看当前支付申请的审核状态
* @create: 2021-12-22 10:17
-->
<template>
    <div class="center-flex" style="display: flex;overflow-x: auto">
        <a-steps :style="style" size="small" >
            <a-step v-for="({approvalUserName,updateTime,remarks,status},key) in steps"
                    :status = "status"
                    :sub-title="getTime(updateTime)"
                    :key="key">
                <template #title>
                    <div style="display: flex;justify-content: space-around;height: 24px;align-items: center;">
                        <div>{{approvalUserName}}</div>
                        <a-popover v-if="remarks" trigger="hover" placement="top">
                            <template #content>
                                {{remarks}}
                            </template>
                            <a-button  type="link" >审核意见</a-button>
                        </a-popover>
                    </div>
                </template>
            </a-step>
        </a-steps>
    </div>
</template>

<script>
    import payApproval from "../../../assets/api/payApproval"
    import moment from "moment";
    export default {
        name: "projectOutSourcePayAuditStatus",
        props:["record"],
        computed:{
            style(){
                return "width: " + this.steps.length * 250 + "px"
            }
        },
        watch:{
            record(){
                this.init()
            }
        },
        data(){
            return{
                steps:[{approvalUserName:"提交审核",state:"0"}],
                modalVisible:false,
                remarks:null,
                dateFormat: 'YYYY/MM/DD HH:mm:SS',
                current:0
            }
        },
        methods:{
            init(){
                this.steps = [{approvalUserName:"提交审核",state:"0"}];
                if(this.record.id){
                    let data = {
                        "applyId": this.record.id,
                        "approvalUser": "",
                        "state": ""
                    };
                    payApproval.queryAll.requestPOST(this,data,res=>{
                        if(res.data.flag){
                            this.steps = this.steps.concat(res.data.data);
                            for(let i in this.steps){
                                switch (this.steps[i].state) {
                                    case "0":{
                                        this.steps[i].status = "wait" ; //wait process finish error
                                        break;
                                    }
                                    case "1":{
                                        this.steps[i].status = "process" ; //wait process finish error
                                        this.current = parseInt(i);
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                    case "2":{
                                        this.steps[i].status = "finish" ; //wait process finish error
                                        this.current = parseInt(i);
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                    case "3":{
                                        this.steps[i].status = "error" ; //wait process finish error
                                        this.current = parseInt(i);
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                }
                            }
                        }
                    })
                }
            },
            getTime(timeStamp){
                if(timeStamp){
                    return moment(timeStamp).locale('zh-cn').format(this.dateFormat);
                }
                else{
                    return "";
                }
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>