/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工月度考核表-参与评分角色管理
* @description: 员工月度考核表-参与评分角色管理（三期新增）
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assessmentRole = { 
	/** 1 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/assessmentRole/delete.do"), 
	/** 2 summary:查询-全部不分页(根据name,roleState)	method:post */ 
	queryAll : new APIObject("/api/assessmentRole/queryAll.do"), 
	/** 3 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/assessmentRole/queryPage.do"), 
	/** 4 summary:查询-单条(根据主键)	method:post */ 
	queryOne : new APIObject("/api/assessmentRole/queryOne.do"), 
	/** 5 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/assessmentRole/saveOrUpd.do"), 
	/** 6 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/assessmentRole/batchSaveOrUpd.do"), 
	/** 7 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/assessmentRole/batchDelete.do"), 
};
export default assessmentRole;