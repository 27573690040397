<!--
* @program: office_automation 
* @author: ly
* @component:ExtraInfoShower 
* @description: 
* @create: 2021-12-23 11:53
-->
<template>
    <a-spin :spinning="spinning">
        <a-tabs   v-model:activeKey="activeKey">
            <a-tab-pane  key="1" tab="账号信息" >
                <ArForm  :argument="formArg" :view-model="viewModel" >
                    <template #action="{record}">
                        <a-button  @click="saveData1(record)" type="primary">
                            提交
                        </a-button>
                    </template>
                </ArForm>
            </a-tab-pane>
            <a-tab-pane  key="2" tab="花名册" >
                <div style="display: flex;flex-wrap: wrap;">
                    <TagShow :color="'blue'" :tag="'基础信息'">
                        <template #content>
                            <FcForm style="width:400px" :form-columns="formColumns" v-model:form-data="mFormData" :view-model="viewModel" />
                        </template>
                    </TagShow>
                    <TagShow :color="'cyan'"  :tag="'任职信息一'">
                        <template #content>
                            <FcForm style="width:600px" :form-columns="formColumns1" v-model:form-data="mFormData" :view-model="viewModel" />
                        </template>
                    </TagShow>
                    <TagShow :color="'cyan'"  :tag="'任职信息二'">
                        <template #content>
                            <FcForm style="width:600px" :form-columns="formColumns2" v-model:form-data="mFormData" :view-model="viewModel" />
                        </template>
                    </TagShow>
                    <TagShow :color="'purple'"  :tag="'其他'">
                        <template #content>
                            <FcForm style="width:400px" :form-columns="formColumns3" v-model:form-data="mFormData" :view-model="viewModel" />
                        </template>
                    </TagShow>
                </div>
                <a-divider v-if="!viewModel"/>
                <a-button v-if="!viewModel" @click="saveData" type="primary">
                    提交
                </a-button>
            </a-tab-pane>
            <a-tab-pane v-if="viewModel"  key="3" tab="角色" >
                <UserRoleSetting :view-model="viewModel" :user-id="record.id" :roles="allRoles" />
            </a-tab-pane>
            <template #renderTabBar="{ DefaultTabBar, ...props }">
                <component
                        :is="DefaultTabBar"
                        v-bind="props"
                        :style="{ zIndex: 1, background: '#fff', }"
                />
            </template>
        </a-tabs>
    </a-spin>
</template>

<script>
    import sys_user_info_extra from "../../../assets/tables/sys_user_info_extra";
    import FcForm from "../../../components/form/FcForm";
    import TagShow from "../../_components/TagShow";
    import userExtra from "../../../assets/api/userExtra";
    import ArForm from "../../../components/form/ArForm";
    import sys_user_info from "../../../assets/tables/sys_user_info";
    import userInfo from "../../../assets/api/userInfo";
    import UserRoleSetting from "../roleManage/UserRoleSetting";
    import role from "../../../assets/api/role";
    export default {
        name: "ExtraInfoShower",
        props:["record","viewModel"],
        watch:{
            record(){
                this.init()
            }
        },
        components:{
            FcForm,TagShow,ArForm,UserRoleSetting
        },
        data(){
            return{
                activeKey:"2",
                table:new sys_user_info_extra(),
                formColumns:[],
                formColumns1:[],
                formColumns2:[],
                formColumns3:[],
                mFormData:null,
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                spinning:false,
                allRoles:null,
            }
        },
        methods:{
            init(){
                this.activeKey = "2";
                this.formArg.formData = {...this.record};
                let data = {
                    userId:this.record.id
                };
                this.spinning = true;
                userExtra.queryAll.requestPOST(this,data,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        this.mFormData = res.data.data[0];
                    }
                    else{
                        this.mFormData = this.table.getFormData();
                    }
                })
            },
            saveData(){
                this.mFormData.userId = this.record.id;
                userExtra.saveOrUpdate.requestPOST(this,this.mFormData);
            },
            saveData1(record){
                userInfo.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.formColumns = this.table.getFormColumns().filter(item=>item.group === 0);
            this.formColumns1 = this.table.getFormColumns().filter(item=>item.group === 1);
            this.formColumns2 = this.table.getFormColumns().filter(item=>item.group === 2);
            this.formColumns3 = this.table.getFormColumns().filter(item=>item.group === 3);
            this.mFormData = this.table.getFormData();
            this.formArg.formColumns =  new sys_user_info().getFormColumns();
            this.init();
            let data = {
                roleState:"1"
            };
            role.queryAll.requestPOST(this,data,res=>{
                if(res.data.flag){
                    this.allRoles = res.data.data.filter(role=>role.name !=="超级管理员");
                    this.init()
                }
            })
        }

    }
</script>

<style scoped>

</style>