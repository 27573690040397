<!--
* @program: TYH_office_automation 
* @author: ly
* @component:propsBaseTable2 
* @description: viewModel 为prop进来的版本
* @create: 2021-06-29 10:40
-->
<template>
    <a-table
            class="myTable"
            bordered
            :dataSource="source"
            :columns="cols" rowKey="id"
            :pagination="pagination"
            :loading="loading"
    >
        <template #String="{column,record}">
            <type-string :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Number="{column,record}">
            <type-number :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Enum="{column,record}">
            <type-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Date="{column,record}">
            <type-date :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Month="{column,record}">
            <type-month :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Boolean="{column,record}">
            <type-boolean :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Index="{column,record,index}">
            <type-index :currentIndex="currentIndex" :index="index" :passedNum="passedNum" :viewModel="viewModel" :column="column" :record="record"/>
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Category="{column,record}">
            <type-category :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #CategoryAssets="{column,record}">
            <type-category-assets :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
                <a-input
                        ref="searchInput"
                        :placeholder="`查询 ${column.title}`"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                />
                <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                    <template #icon><SearchOutlined /></template>
                    查询
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    重置
                </a-button>
            </div>
        </template>
    </a-table>
</template>

<script>
    import emitDataCheck from "../emitDataCheck";
    import typeNumber from "../dataPresentType/typeNumber";
    import typeString from "../dataPresentType/typeString";
    import typeEnum from "../dataPresentType/typeEnum";
    import typeDate from "../dataPresentType/typeDate";
    import typeBooleanCheck from "../dataPresentType/typeBooleanCheck";
    import typeIndex from "../dataPresentType/typeIndex";
    import typeIdReplace from "../dataPresentType/typeIdReplace";
    import typeMonth from "../dataPresentType/typeMonth";
    import typeCategory from "../dataPresentType/typeCategory";
    import typePreViewFile from "../dataPresentType/typePreViewFile";
    import typeAvatar from "../dataPresentType/typeAvatar";
    import typeIdReplace2 from "../dataPresentType/typeIdReplace2";
    import typeBoolean from "../dataPresentType/typeBoolean";
    import typeCategoryAssets from "../dataPresentType/typeCategoryAssets";
    import {computed} from "vue";
    import {checkAuth} from "../../assets/utils/general";
    import {DeleteOutlined, EditFilled, SearchOutlined} from "@ant-design/icons-vue";

    export default {
        name: "propsBaseTable",
        props:{
            /**
             argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()

                    loading:false//table的loading状态
                }
             */
            argument:{required:true},
            viewModel:{required:true}
        },
        mixins:[emitDataCheck],
        components:{
            typeNumber,
            typeString,
            typeEnum,
            typeDate,
            typeBooleanCheck,
            typeIndex,
            typeIdReplace,
            typeMonth,
            typeCategory,
            typePreViewFile,
            typeAvatar,typeIdReplace2,typeBoolean,typeCategoryAssets,
            DeleteOutlined,EditFilled,SearchOutlined
        },
        watch:{
            argument(){
                this.init()
            }
        },
        provide(){
            return{
                columnAuth:computed(()=>this.columnAuth),
                provideTrigger:this.provideTrigger
            }
        },
        computed:{
            passedNum(){
                return this.pagination.defaultPageSize*(this.currentPage-1);
            },
            source(){
                return this.argument.dataSource;
            },
            cols(){
                return this.argument.tableColumns.filter(column=>{return column.tableView})
            },
            loading(){
                return this.argument.loading;
            },
        },
        data(){
            return {
                auth: [],    //table的权限
                columnAuth: [],//tableColumn的权限,
                currentPage:1,
                pagination:{
                    size:"small",
                    hideOnSinglePage: true,
                    defaultPageSize: 15,
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '15', '20'],
                    onChange: (page) => {
                        this.currentPage = page;
                        this.currentIndex = null
                    }
                },
                selectedRowKeys: [],
                selectedRows:[],

                searchText:null,
                searchedColumn:null,

                currentIndex:null
            }
        },
        methods:{
            init(){
                this.auth = computed(()=>this.argument.authority);
                this.columnAuth = checkAuth(this.auth,"upd")?["upd"]:["view"];//需要修改
            },
            provideTrigger(action){
                if(action){
                    action(this.source,this.argument.tableColumns);
                }
            },
            onSelectChange(selectedRowKeys, selectedRows){
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
            },
            handleSearch(selectedKeys, confirm, dataIndex){
                confirm();
                this.searchText = selectedKeys[0];
                this.searchedColumn = dataIndex;
            },
            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            deleteData(index,record){
                this.$emit("event", this.$GetEmitData(this.$eventTags.delete,{index,record}));
            },
            showDetail(index,record){
                this.$emit("event", this.$GetEmitData(this.$eventTags.showDetail,record));
                this.currentIndex = index;
            },
            editDetail(index,record){
                this.$emit("event", this.$GetEmitData(this.$eventTags.editDetail,record));
                this.currentIndex = index;
            },
            setTotalSize(num){
                this.pagination.total = num;
            },
            setCurrentPage(num){
                this.pagination.current = num;
            },
            rowClass(record,index){
                if(index === this.currentIndex){
                    return "highlight-row"
                }
                else{
                    return ""
                }
            },
        },
        mounted() {
            this.init()
        }
    }
</script>

<style scoped>

</style>