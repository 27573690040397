<!--
* @program: TYH_office_automation 
* @author: ly
* @component:projectTaskViewer 
* @description: 项目任务分配
* @create: 2021-06-30 15:06
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="visibleType = !visibleType" class="search-item" type="default">
                    <template #icon>
                        <BarChartOutlined style="color:green"/>
                    </template>
                    {{btnText}}
                </a-button>
                <SearchBar :search-options="searchOptions" v-if="!condition1 || condition1 == null"
                           v-model:value="searchData"/>

                <span v-if="condition1">
                   <a-input class="search-item"
                            placeholder="请输入项目名称" style="width:200px" v-model:value="queryText"/>
                  <a-select
                          allow-clear
                          class="search-item"
                          placeholder="部门选择"
                          style="width: 250px"
                          v-model:value="departmentId"
                  >
                        <a-select-option :key="item.id" v-for="item in departments">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>

                   <a-month-picker format="YYYY"
                                   placeholder="请选择任务开始日期" style="width: 200px"
                                   v-model:value="year"/>
                </span>
                <a-button @click="doSearch" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="default">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
                <span>任务分配：</span>
                <a-radio-group @change="radioChange" class="search-item" v-model:value="condition1">
                    <a-radio :value="false">未分配</a-radio>
                    <a-radio :value="true">已分配</a-radio>
                    <a-radio :value="null">不需要</a-radio>
                </a-radio-group>
                <a-tooltip title="任务已提交完成的项目">
                    <a-badge :count="dataSource2.length">
                        <a-button @click="visible = true" class="search-item" type="default">
                            <template #icon>
                                <AlertOutlined/>
                            </template>
                        </a-button>
                    </a-badge>
                </a-tooltip>
            </div>
            <TwoParts :enable-change="false" :trans="[50,30]" ref="TwoParts">
                <template #left>
                    <TcBaseTable :data-source="dataSource" :loading="loading"
                                 :page-size="pageSize" :scroll="{x:800}"
                                 :table-columns="tableColumns"
                                 :view-model="true">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-button @click="editDetail(record)" class="action-button" type="link">
                                    <EditFilled class="pointer"/>
                                </a-button>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <project-tabs ref="local_tabs"/>
                </template>
            </TwoParts>
            <a-drawer
                    :closable="false"
                    :height="800"
                    :title="null"
                    placement="bottom"
                    v-model:visible="visibleType">
                <projectTaskCategoryShower :view-model="false" v-if="visibleType"/>
            </a-drawer>
            <a-drawer
                    :closable="false"
                    :height="500"
                    placement="bottom"
                    title="提交项目工作完成"
                    v-model:visible="visible">
                <TcBaseTable :data-source="dataSource2" :page-size="5"
                             :table-columns="tableColumns2" :view-model="true">
                    <template #action="{index,record}">
                        <div class="action-column">
                            <a-popconfirm
                                    @confirm="finishProject(index,record)"
                                    cancel-text="否"
                                    ok-text="是"
                                    title="提交完成吗?">
                                <a-button class="action-button" type="link">
                                    项目工作完成
                                </a-button>
                            </a-popconfirm>
                        </div>
                    </template>
                </TcBaseTable>
            </a-drawer>
        </div>
    </div>
</template>

<script>
    import itemInfo from "../../../assets/api/itemInfo";
    import projectTabs from "../projectViewer/projectTabs";
    import TwoParts from "../../_components/TwoParts";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import {
        AlertOutlined,
        BarChartOutlined,
        CheckOutlined,
        EditFilled,
        SearchOutlined,
        SyncOutlined
    } from "@ant-design/icons-vue"
    import projectTaskCategoryShower from "./projectTaskCategoryShower";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    import department from "../../../assets/api/department";
    import moment from "moment";
    import itemTask from "../../../assets/api/itemTask";

    export default {
        name: "projectTaskViewer",
        components: {
            projectTabs, TwoParts, projectTaskCategoryShower,
            SearchBar, SearchOutlined, SyncOutlined, BarChartOutlined, EditFilled,
            TcBaseTable, CheckOutlined, AlertOutlined
        },
        computed: {
            btnText() {
                return "项目任务统计"
            },
            dataSource() {
                if (this.condition1 === null) {
                    return this.tableSource2;
                } else if (this.condition1 === false) {
                    return this.tableSource.filter(item => {
                        return !!item.itemTaskDTO === this.condition1
                    });
                } else {
                    return this.tableSource3;
                }
            }
        },
        data() {
            return {
                tableSource: [],//需要分配任务的项目
                tableSource2: [],//不需要分配任务的项目
                tableSource3: [],// 已分配
                tableColumns: [],
                searchOptions: [ //contractState
                    new SearchSegment("项目名称", "name"),
                    new SearchSegment("甲方名称", "partyInfoName"),
                    new SearchSegment("合同情况", "contractState")
                        .setEnum(["0", "1", "2"], ["无", "有", "不需要"]),
                ],
                searchData: null,
                formData: {},
                loading: false,
                pageSize: 15,
                visibleType: false,
                defaultQuery: {
                    auditState: "1" //已通过审核
                },
                condition1: false,
                visible: false,
                dataSource2: [],
                tableColumns2: [],

                departmentId: null,
                departments: [],
                year: null,
                queryText: null,
            }
        },
        methods: {
            init() {
                this.loading = true;
                this.searchData = null;
                this.pageSize = 15;
                this.tableSource = [];
                this.tableSource2 = [];
                let data = {...this.defaultQuery};

                itemInfo.queryAll.requestPOST(this, data, res => {
                    if (res.data.flag) {
                        let data = res.data.data.sort((a, b) => {
                            return b.startTime - a.startTime;
                        });
                        for (let i in data) {
                            /**不需要分配任务的项目，项目状态已完成，且没有分配过任务 */
                            if (!data[i].itemTaskDTO && data[i].itemState === "1") {
                                this.tableSource2.push(data[i]);
                            }
                            /**可以分配任务的项目，除上面状态之外的项目 */
                            else {
                                this.tableSource.push(data[i]);
                            }
                            /** 分配的任务已经完成，需要提交项目工作完成的项目
                             *  state 不为null 的时候表示已经提交任务完成
                             *  itemState  ”0“ 则表示项目未标记工作完成
                             */
                            if (data[i].itemTaskDTO && data[i].itemTaskDTO.state && data[i].itemState === "0") {
                                let aaa = {...data[i].itemTaskDTO};
                                aaa.itemObject = {...data[i]};
                                this.dataSource2.push(aaa);
                            }
                        }
                        for (let i in this.tableSource) {
                            if (this.tableSource[i].itemTaskDTO) {
                                this.tableSource[i].completeRatio = this.tableSource[i].itemTaskDTO.completeRatio;
                                this.tableSource[i].money = this.tableSource[i].itemTaskDTO.money;
                            }
                        }
                        this.radioChange();
                    } else {
                        this.tableSource = [];
                        this.tableSource2 = [];
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
                this.doSearch_fp();
                department.queryAll.requestPOST(this, {}, res => {
                    if (res.data.flag) {
                        this.departments = res.data.data;
                    } else {
                        this.departments = [];
                    }
                })
            },
            doSearch() {
                if (this.condition1) {
                    this.doSearch_fp();
                } else {
                    let arg = {};
                    this.loading = true;
                    Object.assign(arg, this.searchData);
                    Object.assign(arg, this.defaultQuery);
                    this.tableSource = [];
                    this.tableSource2 = [];
                    itemInfo.queryAll.requestPOST(this, arg, res => {
                        if (res.data.flag) {
                            let data = res.data.data.sort((a, b) => {
                                return b.startTime - a.startTime;
                            });
                            for (let i in data) {
                                if (!data[i].itemTaskDTO && data[i].itemState === "1") {
                                    this.tableSource2.push(data[i]);
                                } else {
                                    this.tableSource.push(data[i]);
                                }
                            }
                            for (let i in this.tableSource) {
                                if (this.tableSource[i].itemTaskDTO) {
                                    this.tableSource[i].completeRatio = this.tableSource[i].itemTaskDTO.completeRatio;
                                    this.tableSource[i].money = this.tableSource[i].itemTaskDTO.money;
                                }
                            }
                        } else {
                            this.tableSource = [];
                            this.tableSource2 = [];
                            this.$message.error(res.data.msg);
                        }
                        this.loading = false;
                    });
                }
            },
            doSearch_fp() {
                let arg = {};
                this.loading = true;
                this.tableSource3 = [];
                if (this.year != null) {
                    arg.year = moment(this.year).format('YYYY');
                }
                arg.departmentId = this.departmentId;
                arg.queryText = this.queryText;
                itemTask.queryAll.requestPOSTUrlParam(this, arg, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        let list = res.data.data;
                        if (list.length > 0) {
                            for (let i = 0; i < list.length; i++) {
                                let info = list[i];
                                if (info.pid == "0") {
                                    info.taskType = "0";
                                } else {
                                    info.taskType = "1";
                                }
                                let paymentList = info.paymentList;
                                if (paymentList != null && paymentList.length > 0) {
                                    for (let j = 0; j < paymentList.length; j++) {
                                        let paymentInfo = paymentList[j];

                                        info.paymentSum = (info.paymentSum == null || info.paymentSum == undefined ? 0 : parseFloat(info.paymentSum)) + paymentInfo.payAmount;
                                    }
                                }
                            }
                        }
                        this.tableSource3 = list;
                    } else {
                        this.tableSource3 = [];
                        this.$message.error(res.data.msg);
                    }
                });
            },
            refresh() {
                this.condition1 = false;
                this.init();
            },
            editDetail(record) {
                this.formData = record;
                let projectId = null;
                if (this.condition1) {
                    projectId = record.itemId;
                } else {
                    projectId = record.id;
                }
                if (record.itemType === "0") {//自研项目
                    this.$refs.local_tabs.handleOpen([
                        {
                            tag: "项目任务",
                            component: "projectTaskInfo",
                            viewModel: false,
                            projectId: projectId,
                            record: record
                        },
                        {tag: "项目基本信息", component: "projectInfo", viewModel: true, projectId: projectId},
                        {tag: "项目合同信息", component: "projectContract", viewModel: true, projectId: projectId},
                        {tag: "质检情况", component: "QCManager", viewModel: true, projectId: projectId},
                        {
                            tag: "部门兑付情况",
                            component: "departPayment",
                            viewModel: false,
                            projectId: projectId,
                            record: record
                        },
                    ]);
                } else {
                    this.$refs.local_tabs.handleOpen([
                        {
                            tag: "项目任务",
                            component: "projectTaskInfo",
                            viewModel: false,
                            projectId: projectId,
                            record: record
                        },
                        {tag: "项目基本信息", component: "projectInfo", viewModel: true, projectId: projectId},
                        {tag: "项目合同信息", component: "projectContract", viewModel: true, projectId: projectId},
                        {tag: "质检情况", component: "QCManager", viewModel: true, projectId: projectId},
                        {
                            tag: "部门兑付情况",
                            component: "departPayment",
                            viewModel: false,
                            projectId: projectId,
                            record: record
                        },
                    ]);
                }
            },
            showDetail(record) {
                this.formData = record;
                let projectId = null;
                if (this.condition1) {
                    projectId = record.itemId;
                } else {
                    projectId = record.id;
                }
                if (record.itemType === "0") {//自研项目
                    this.$refs.local_tabs.handleOpen([
                        {
                            tag: "项目任务",
                            component: "projectTaskInfo",
                            viewModel: true,
                            projectId: projectId,
                            record: record
                        },
                        {tag: "项目基本信息", component: "projectInfo", viewModel: true, projectId: projectId},
                        {tag: "项目合同信息", component: "projectContract", viewModel: true, projectId: projectId},
                        {tag: "质检情况", component: "QCManager", viewModel: true, projectId: projectId},
                        {
                            tag: "部门兑付情况",
                            component: "departPayment",
                            viewModel: true,
                            projectId: projectId,
                            record: record
                        },
                    ]);
                } else {
                    this.$refs.local_tabs.handleOpen([
                        {
                            tag: "项目任务",
                            component: "projectTaskInfo",
                            viewModel: true,
                            projectId: projectId,
                            record: record
                        },
                        {tag: "项目基本信息", component: "projectInfo", viewModel: true, projectId: projectId},
                        {tag: "项目合同信息", component: "projectContract", viewModel: true, projectId: projectId},
                        {tag: "质检情况", component: "QCManager", viewModel: true, projectId: projectId},
                        {
                            tag: "部门兑付情况",
                            component: "departPayment",
                            viewModel: true,
                            projectId: projectId,
                            record: record
                        },
                    ]);
                }
            },
            radioChange() {
                if (this.condition1) {
                    this.tableColumns = [
                        new Column("序号", "_index", ColumnType.Index, false).setTableView(40),
                        new Column("项目名称", "itemName", ColumnType.String, false).setTableView(200),
                        new Column("分配部门", "departmentName", ColumnType.String, true).setTableView(100),
                        new Column("绩效", "money", ColumnType.Number, true).setDefaultValue(0).setTableView(100),
                        new Column("兑付总金额", "paymentSum", ColumnType.Number, true).setTableView(100),
                        new Column("任务开始日期", "startDate", ColumnType.Date, true).setTableView(100),
                        new Column("操作", "actions", "actions", false).setTableView(60).setPosition("right")
                    ];
                } else {
                    this.tableColumns = [
                        new Column("序号", "_index", ColumnType.Index, false).setTableView(40),
                        new Column("项目名称", "name", ColumnType.String, false).setTableView(200),
                        new Column("工作状态", "itemState", ColumnType.String, true)
                            .setTableView(100).setEnum(["0", "1"], ["未完成", "已完成"], ["pink", "green"]).setDefaultValue("0"),
                        new Column("启动时间", "startTime", ColumnType.Date, true).setTableView(100),
                        new Column("操作", "actions", "actions", false).setTableView(60).setPosition("right")
                    ]
                }
            },
            finishProject(index, record) {
                let data = record.itemObject;
                delete data.itemTaskDTO;
                data.itemState = "1";
                itemInfo.saveOrUpd.requestPOST(this, data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.dataSource2.splice(index, 1)
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },

        },
        created() {
            this.init();
            this.tableColumns2 = [
                new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                new Column("任务标题", "taskTitle", ColumnType.String).setTableView(250),
                new Column("分配部门", "departmentName", ColumnType.String).setTableView(120),
                new Column("任务负责人", "taskLeaderName", ColumnType.String).setTableView(100),
                new Column("任务开始时间", "startDate", ColumnType.Date).setTableView(80),
                new Column("预计完成时间", "endDate", ColumnType.Date).setTableView(80),
                new Column("实际完成时间", "completeDate", ColumnType.Date).setTableView(80),
                new Column("完成状态", "state", ColumnType.Enum).setTableView(80)
                    .setEnum(["0", "1", "2"], ["按时完成", "提前完成", "延期完成"], ["blue", "green", "red"]),
                new Column("完成情况说明", "describe", ColumnType.String).setTableView(200),
                new Column("备注", "remarks", ColumnType.String).setTableView(100),
                new Column("操作", "actions", "actions", false).setTableView(60)
            ]
        }
    }
</script>

<style scoped>

</style>