/**
 * @program: tyh-oa
 * @author: ly
 * @description: tagColors 用于设定各个角色与各个部门的颜色设定
 * "pink","red","yellow","orange","cyan","green","blue","purple","geekblue","magenta","volcano","gold","lime"
 * @create: 2021-05-25 16:36
 **/

export function getTagColor(tag) {
    switch (tag) {
        case "航测遥感中心":{
            return "volcano"
        }
        case "大数据应用研究中心":{
            return "cyan"
        }
        case "空间规划中心":{
            return "blue"
        }
        case "综合部":{
            return "orange"
        }
        case "人力资源部":{
            return "pink"
        }
        case "基础测绘工程中心":{
            return "red"
        }
        case "地理信息工程中心":{
            return "lime"
        }
        case "办公财务部":{
            return "green"
        }
        case "总经理办公室":{
            return "purple"
        }
        case "超级管理员":{
            return "gold"
        }
        case "正式成员":{
            return "blue"
        }
        case "总经理":{
            return "geekblue"
        }
        case "常务副总经理":{
            return "geekblue"
        }
        case "生产经营副总经理":{
            return "geekblue"
        }
        case "质量技术副总经理":{
            return "geekblue"
        }
        case "办公财务部负责人":{
            return "magenta"
        }
        case "综合部负责人":{
            return "magenta"
        }
        case "人力资源部负责人":{
            return "magenta"
        }
        case "航测遥感中心负责人":{
            return "magenta"
        }
        case "大数据应用研究中心负责人":{
            return "magenta"
        }
        case "基础测绘工程中心负责人":{
            return "magenta"
        }
        case "空间规划研究中心负责人":{
            return "magenta"
        }
        case "地理信息工程中心负责人":{
            return "magenta"
        }
        case "质量技术管理中心负责人":{
            return "magenta"
        }
        case "报账审核人":{
            return "red"
        }
        case "账单入账人":{
            return "purple"
        }
        default:{
            return randomRgbColor()
        }
    }
    function randomHexColor() { //随机生成十六进制颜色
        return '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6);
    }
    function randomRgbColor() { //随机生成RGB颜色
        let r = Math.floor(Math.random() * 128); //随机生成256以内r值
        let g = Math.floor(Math.random() * 128); //随机生成256以内g值
        let b = Math.floor(Math.random() * 128); //随机生成256以内b值
        return `rgb(${r},${g},${b})`; //返回rgb(r,g,b)格式颜色
    }


    


    


    


    


    


    


    



}