import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

/**
 * @program: tyh-oa
 * @author: ly
 * @description: item_party_info 甲方信息
 * @create: 2021-06-08 15:57
 **/

export default class item_party_info extends Table{
    constructor() {
        super();
        this.name = "item_party_info";
        this.CNName = "甲方信息";
        this.columnArray = [
            new Column("单位名称","name",ColumnType.String,true).setTableView(200),
            new Column("单位地址","address",ColumnType.String,true),
            new Column("单位联系电话","phone",ColumnType.String,true),
            new Column("税号","nubr",ColumnType.String,true),
            new Column("开户银行","openBank",ColumnType.String,true),
            new Column("开户行账号","bankNo",ColumnType.String,true),
            new Column("联系人","contactUser",ColumnType.String,true).setTableView(100),
            new Column("联系人电话","contactPhone",ColumnType.String,true).setTableView(100),
            new Column("email","email",ColumnType.String,true),
            new Column("说明","remarks",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}