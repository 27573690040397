<template>
    <router-view />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
    html,
    body,
    #app {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>
