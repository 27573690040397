<!--
* @program: TYH_office_automation 
* @author: ly
* @component:personalScore 
* @description: 个人项目评分
* @create: 2021-07-12 15:31
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
            </div>

                <ArBaseTable ref="assets_table" :page-size="15"
                             :argument="argument"
                             :view-model="true"/>

        </div>
    </div>
</template>

<script>
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import api_item_prod_quality from "../../../assets/api/prodQuality";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {SyncOutlined} from "@ant-design/icons-vue"
    export default {
        name: "personalScore",
        components:{
            ArBaseTable,SyncOutlined
        },
        data(){
            return{
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false//table的loading状态
                },
                defaultOptions:{//当前页面的查询默认条件
                    userId:this.$store.getters.userInfo.id,
                },
            }
        },
        methods:{
            init(){
                let data = {};
                Object.assign(data,this.defaultOptions);
                this.argument.loading = true;
                api_item_prod_quality.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                    this.argument.loading = false;
                });
            },
            refresh(){
                this.init();
            },
        },
        created() {
            this.argument.tableColumns = [
                new Column("序号","id",ColumnType.Index,false).setTableView(100),
                new Column("项目名称","itemName",ColumnType.String,true).setTableView(300),
                new Column("综合评价","assessment",ColumnType.Enum,true).setTableView(150)
                    .setEnum(["0","1","2","3"],["不合格","合格","良好","优秀"],["red","pink","gold","green"]),
                new Column("评价总结","sumDescri",ColumnType.String,true).setTableView(400)
            ];
            this.init()
        }
    }
</script>

<style scoped>

</style>