<!--
* @program: office_automation 
* @author: ly
* @component:SoftwareCategory 
* @description: 工具软件设置
* @create: 2022-05-24 10:29
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <TwoParts ref="TwoParts" :trans="[25,30]" :enable-change="false">
                <template #left>
                    <a-page-header
                            style="border: 1px solid rgb(235, 237, 240)"
                            title="工具软件类别设置"
                    />
                    <div style="display: flex">
                        <a-tooltip  title="添加大类">
                            <a-button class="search-item" type="dashed"  @click="addGroup">
                                <template #icon><PlusOutlined /></template>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip :key="'#fff'" title="刷新">
                            <a-button class="search-item" type="dashed"  @click="refresh">
                                <template #icon><SyncOutlined /></template>
                            </a-button>
                        </a-tooltip>
                    </div>
                    <a-table style="height: 600px;overflow-y: auto;max-width: 600px" :loading="loading" bordered :dataSource="dataSource" :columns="cols" rowKey="id" >
                        <template #actions="{index,record}">
                            <div style="text-align: center">
                                <a-tooltip  title="添加子类">
                                    <a-button v-if="checkColumn(record)" class="action-button" type="link" @click="addDetail(record)">
                                        <PlusOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-button  class="action-button" type="link" @click="editDetail(record)">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-tooltip  title="查看本类软件">
                                    <a-button  class="action-button" type="link" @click="showSoft(record)">
                                        <SearchOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(index,record)">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </a-table>
                </template>
                <template #right>
                    <SoftwareShower v-if="visible2" :record="currentRecord" />
                    <welcome2 v-else />
                </template>
            </TwoParts>
        </div>
    </div>
   <a-modal v-model:visible="visible"
             title="详情">
        <template #footer>
            <a-button key="back" @click="visible =false">取消</a-button>
            <a-button key="submit" type="primary"  @click="update">提交</a-button>
        </template>
        <a-form style="margin: 10px 100px" layout="horizontal"
                :model="currentRecord">
            <a-form-item label="类别">
                <a-input style="width: 200px" v-model:value="currentRecord.categoryName" />
            </a-form-item>
            <a-form-item label="备注">
                <a-textarea style="width: 200px" v-model:value="currentRecord.remarks" />
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import {DeleteOutlined, EditFilled, PlusOutlined, SyncOutlined,SearchOutlined} from "@ant-design/icons-vue";
    import category from "../../../assets/tables/category";
    import api_category from "../../../assets/api/softwareCategory";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import TwoParts from "../../_components/TwoParts";
    import SoftwareShower from "./SoftwareShower";
    import Welcome2 from "../../welcome2";
    export default {
        name: "SoftwareCategory",
        components:{
            Welcome2,
            DeleteOutlined,PlusOutlined,EditFilled,SyncOutlined,TwoParts,
            SearchOutlined,SoftwareShower,
        },
        data(){
            return{
                dataSource:[],
                table: new category(),
                cols:[],
                secondSource:[],
                visible:false,
                viewModel:false,
                currentRecord:{},
                loading:false,
                visible2:false,
            }
        },
        methods:{
            init(){
                this.loading = true;
                api_category.queryOneLevel.requestPOST(this,{},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                })
            },
            addGroup(){//添加大类
                this.currentRecord = {};
                this.visible = true;
                this.visible2 = false;
            },
            addDetail(record){//添加子分类
                this.currentRecord = {parentId:record.id};
                this.visible = true;
                this.visible2 = false;
            },
            deleteData(index,record){
                let data ={id:record.id};
                api_category.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editDetail(record){
                this.visible = true;
                this.visible2 = false;
                this.currentRecord = record;
            },
            checkColumn(record){
                return Object.prototype.hasOwnProperty.call(record, "children");
            },
            update(){
                api_category.saveOrUpd.requestPOST(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.init()
            },
            showSoft(record){
                this.currentRecord = record;
                this.visible2 = true;
            }
        },
        created() {
            this.cols = [
                new Column("类别","categoryName",ColumnType.String,true).setTableView(150),
                new Column("备注","remarks",ColumnType.String,true).setTableView(100),
                new Column("操作","actions","actions").setTableView(120)];
            this.init();
        }
    }
</script>

<style scoped>

</style>