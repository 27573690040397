<!--
* @program: tyh-oa 
* @author: ly
* @component:billViewer2
* @description: 报账审核-入账管理
* @create: 2021-04-26 13:53
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <bill-tool-bar2 @event="eventHandle" />
            <TwoParts ref="TwoParts" @change="handleChange" pos="transform">
                <template #left>
                    <bill-table-view2 ref="bill_table" :loading="loading" :data-source="dataSource" :table-columns="columns"  @event="eventHandle"/>
                </template>
                <template #right>
                    <billTabs ref="local_tabs" @event="eventHandle"/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import baseProvide from "../../baseProvide";
    import billToolBar2 from "./billToolBar2";
    import billTabs from "../billComponents/billTabs";
    import billTableView2 from "./billTableView2";
    import item_finance_record from "../../../assets/tables/item_finance_record_2";
    import api_item_finance_record from "../../../assets/api/record";
    import api_item_finance_record_flow from "../../../assets/api/recordFlow";
    import TwoParts from "../../_components/TwoParts";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";

    export default {
        name: "billViewer2",
        mixins:[baseProvide],
        components:{
            billToolBar2,billTabs,billTableView2,TwoParts
        },
        data(){
            return{
                table:new item_finance_record(),
                userId:this.$store.getters.userInfo.id,
                options:{
                    approvalState:"1",
                    submitState:"1",
                    entryState:"0"//入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                },
                columns:[],
                loading:false
            }
        },
        methods:{
            init(){
                this.loading = true;
                this.columns= this.table.getTableColumns();
                api_item_finance_record.queryAll.requestPOSTUrlParam(this,this.options,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            eventHandle({type,arg}){
                switch (type) {
                    case this.$eventTags.saveData:{ //通过
                        api_item_finance_record.recordEntry.requestPOSTUrlParam(this,arg,res=>{
                            if(res.data.flag){
                                this.$message.success(res.data.msg);
                                this.init();
                                this.$refs.bill_table.setModalVisible(false);
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                        break;
                    }
                    case this.$eventTags.showDetail:{
                        this.formData = arg;
                        this.$refs.local_tabs.handleOpen([
                            {tag:"账单详情",component:"personalBillViewer",record:arg},
                        ]);
                        this.$refs.TwoParts.transform();
                        break;
                    }
                    case this.$eventTags.refresh:{
                        this.init();
                        this.$refs.TwoParts.reset();
                        break;
                    }
                    case "downloadFlows" :{
                        let data = {recordId:arg.id};
                        let fileName = arg.title+".xls";
                        api_item_finance_record_flow.exportExcelByRecord.fileDownloadGet(this,data,fileName);
                        break;
                    }
                    case "filter_change":{
                        Object.assign(this.options,arg);
                        this.loading = true;
                        api_item_finance_record.queryAll.requestPOSTUrlParam(this,this.options,res=>{
                            this.loading = false;
                            if(res.data.flag){
                                this.dataSource = res.data.data;
                            }
                            else{
                                this.dataSource = []
                            }
                        });
                        break;
                    }
                }
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.columns = [
                        //new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                        new Column("用户名","userName",ColumnType.String,false).setTableView(80),
                        new Column("账单标题", "title", ColumnType.String, true).setTableView(150),
                        new Column("操作", "actions", "actions", false)
                            .setTableView(80)
                    ]
                } else {
                    this.columns = this.table.getTableColumns();
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>
    .left-part{
        height: 100%;
        width: 40%;
        min-width: 640px;
        overflow-y: auto;
    }
    .right-part{
        height: 100%;
        width: 60%;
        min-width: 640px;
        overflow-y: auto;
    }
</style>