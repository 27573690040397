<!--
* @program: TYH_office_automation 
* @author: ly
* @component:assetsReturn 
* @description: 设备归还审核
* @create: 2021-06-21 17:53
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh" >
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <a-radio-group class="search-item" v-model:value="queryOptions.state" @change="selectChange">
                    <a-radio-button value="1">待审核</a-radio-button>
                    <a-radio-button value="2">已审核通过</a-radio-button>
                    <a-radio-button value="3">未通过审核</a-radio-button>
                </a-radio-group>
            </div>
            <ArBaseTable :view-model="true" :page-size="15" :argument="argument" >
                    <template #action="{record}">
                        <div style="text-align: center">
                            <a-tooltip v-if="record.auditState ==='0'"  title="通过审核">
                                <a-button class="action-button" type="link" @click="enableRow(record)">
                                    <CheckOutlined style="color: green" class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip v-if="record.auditState ==='0'"  title="拒绝通过">
                                <a-button  class="action-button" type="link" @click="disableRow(record)">
                                    <CloseOutlined style="color: red" class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-popover v-if="record.auditState !=='0'" title="审核意见">
                                <template #content>
                                    {{record.remarks}}
                                </template>
                                <a-button  class="action-button" type="link" >
                                    <InfoCircleOutlined style="color: red" class="pointer"/>
                                </a-button>
                            </a-popover>
                            <a-tooltip title="查看审核状态">
                                <a-button  type="link" @click="showStep(record)" >
                                    <template #icon><SearchOutlined /></template>
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </ArBaseTable>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroy-on-close
             :title="title"
             :maskClosable="false"
             :footer="null">
        <a-textarea v-model:value="reason" :auto-size="{ minRows: 2, maxRows: 6 }"/>
        <a-button size="small" type="primary" style="margin: 20px" @click="saveAudit">
            提交
        </a-button>
    </a-modal>

    <a-modal v-model:visible="visible2"
             destroyOnClose
             :footer="null"
             :width="800"
             title="审核状态">
        <DepartmentSteps v-if="visible2" :steps="steps" />
    </a-modal>
</template>

<script>
    import device_back from "../../../assets/tables/device_back";
    import api_device_back from "../../../assets/api/deviceBack";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    import assetBackApproval from "../../../assets/api/assetBackApproval";
    import {
        CheckOutlined,
        CloseOutlined,
        InfoCircleOutlined,
        SearchOutlined,
        SyncOutlined
    } from "@ant-design/icons-vue";
    import DepartmentSteps from "../../projectManager/DepartmentAudits/DepartmentSteps";
    export default {
        name: "assetsReturn",
        components:{
            ArBaseTable,CheckOutlined,CloseOutlined,InfoCircleOutlined,SearchOutlined,SyncOutlined,
            SearchBar,DepartmentSteps
        },
        data(){
            return{
                table:new device_back(),
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                pageSize:15,
                queryOptions:{
                    // "applyType": "", //申领类型：1：主动申领；2：转借  如果我将设备转借给别人，就得在该表新增一条申请
                    // "auditState": "0", 审核状态：0：未审核；1：通过；2：不通过
                    // "auditUser": "",
                    // "deviceId": "",
                    // "queryText": "",
                    // "userId": ""
                    userId: this.$store.getters.userInfo.id,
                    state: "1"
                },
                viewModel:true,
                title:"详情",
                searchOptions:[
                    new SearchSegment("关键字", "queryText"),
                ],

                visible:false,
                currentRecord:null,
                auditState:null,
                searchData:null,
                reason:null,
                steps:null,
                visible2:false
            }
        },
        methods:{
            init(){
                this.argument.loading = true;
                api_device_back.queryMyApproval.requestPOSTUrlParam(this,this.queryOptions,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                    this.argument.loading = false;
                });
            },
            selectChange(){
                this.init()
            },
            doSearch(){
                let data = {...this.searchData};
                Object.assign(data,this.queryOptions);
                api_device_back.queryMyApproval.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                    this.argument.loading = false;
                });
            },
            refresh(){
                this.init()
            },
            enableRow(record){
                this.visible = true;
                this.title = "通过备注";
                this.auditState = "2";
                this.reason = null;
                this.currentRecord = record;
            },
            disableRow(record){
                this.visible = true;
                this.reason = null;
                this.auditState = "3";
                this.title = "拒绝通过理由";
                this.currentRecord = record;
            },
            saveAudit(){
                let data = {
                    "backId": this.currentRecord.id,
                    "state": this.auditState,
                    "userId": this.$store.getters.userInfo.id,
                    "remarks": this.reason
                };
                assetBackApproval.audit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            showStep(record){
                this.steps = [
                    {approvalUserName:"提交审核",state:"0"}
                ];
                let data = {
                    "backId": record.id,
                };
                assetBackApproval.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.steps = this.steps.concat(res.data.data);
                        for(let i in this.steps){
                            switch (this.steps[i].state) {
                                case "0":{
                                    this.steps[i].status = "wait" ; //wait process finish error
                                    break;
                                }
                                case "1":{
                                    this.steps[i].status = "process" ; //wait process finish error
                                    this.steps[0].status = "finish";
                                    break;
                                }
                                case "2":{
                                    this.steps[i].status = "finish" ; //wait process finish error
                                    this.steps[0].status = "finish";
                                    break;
                                }
                                case "3":{
                                    this.steps[i].status = "error" ; //wait process finish error
                                    this.steps[0].status = "finish";
                                    break;
                                }
                            }
                        }
                        this.visible2 = true;
                    }
                })
            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.argument.formColumns = this.table.getFormColumns();
            this.init()
        }
    }
</script>