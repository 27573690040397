/**
 * @program: office_automation
 * @author: ly
 * @description: item_progress_apply
 * @create: 2021-12-30 17:01
 *
private String applyType;                         //类型：1:生产任务；2：生产工序；3：质检工序；4：员工工序
 private String originalId;                         //原id(任务/工序id)
private Date oldDate;                              //原计划完成时间（后台自动赋值）
 @DateTimeFormat(pattern="yyyy-MM-dd")
 private Date newDate;                               //申请新的计划完成时间
 private int delayDay;                                //延迟天数（无需填，自动计算展示用就行）
 private String reason;                                //申请原因说明
 private String  approveState;                          //审核状态：0：未审核；1：通过：2：不通过
 private String  approveRemark;                          //审核意见
 private String approveUser;                              //审核人id

 //额外展示
 private String approveUserName;                           //审核人姓名
 private String applyUserName;                              //申请人姓名（也就是createUser）
 private ItemFlowTemp itemFlowTemp;                         //原项目信息：项目-任务-工序-人员
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type"
export default class item_progress_apply extends Table{
    constructor() {
        super();
        this.name = "item_progress_apply";
        this.CNName = "延期申请";
        this.columnArray = [
            new Column("类型","applyType",ColumnType.Enum)
                .setEnum(["1","2","3","4"],["生产任务","生产工序","质检工序","员工工序"]),
            new Column("原计划时间","oldDate",ColumnType.Date).setTableView(100),
            new Column("延期到","newDate",ColumnType.Date,true).setTableView(100),
            new Column("延迟天数","delayDay",ColumnType.Number).setTableView(100),
            new Column("申请缘由","reason",ColumnType.String,true).setTableView(300),
            new Column("申请人","applyUserName",ColumnType.String).setTableView(100),
            new Column("审核人","approveUserName",ColumnType.String).setTableView(100),
            new Column("审核状态","approveState",ColumnType.Enum).setTableView(100)
                .setEnum(["0","1","2"],["未审核","通过","不通过"],["pink","green","red"]),
           // new Column("申请任务详情","ItemFlowTemp",ColumnType.String).setTableView(100),
            new Column("审核批注","approveRemark",ColumnType.String,false)
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}