/** 
* 自动生成文件，请勿修改 
* @name: 资产:设备归还模块
* @description: 设备归还模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const deviceBack = { 
	/** 1 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/deviceBack/queryAll.do"), 
	/** 2 summary:新增/修改-单条（设备归还使用）	method:post */ 
	saveOrUpd : new APIObject("/api/deviceBack/saveOrUpd.do"), 
	/** 3 summary:归还申请-查询需要我审批且已到达我这里的归还申请-分页	method:post */ 
	pageMyApproval : new APIObject("/api/deviceBack/pageMyApproval.do"), 
	/** 4 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/deviceBack/batchDelete.do"), 
	/** 5 summary:归还申请-查询需要我审批且已到达我这里的申请-不分页	method:post */ 
	queryMyApproval : new APIObject("/api/deviceBack/queryMyApproval.do"), 
	/** 6 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/deviceBack/queryPage.do"), 
	/** 7 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/deviceBack/queryOne.do"), 
	/** 8 summary:删除-单条（提交的申请 未被审核时才能删除）	method:post */ 
	delete : new APIObject("/api/deviceBack/delete.do"), 
	/** 9 summary:设备管理者强制归还别人使用中的设备	method:post */ 
	forceBack : new APIObject("/api/deviceBack/forceBack.do"), 
};
export default deviceBack;