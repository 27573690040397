/** 
* 自动生成文件，请勿修改 
* @name: 项目:项目质检报告管理模块
* @description: 项目-项目质检报告管理模块-增删改查
* @create: 2024/1/11 下午4:25:21
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const qcReportApply = { 
	/** 1 summary:保存/修改-单个项目质检报告	method:post */ 
	saveOrUpd : new APIObject("/api/qcReportApply/saveOrUpd.do"), 
	/** 2 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/qcReportApply/queryAll.do"), 
	/** 3 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/qcReportApply/batchSaveOrUpd.do"), 
	/** 4 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/qcReportApply/batchDelete.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/qcReportApply/delete.do"), 
	/** 6 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/qcReportApply/queryOne.do"), 
	/** 7 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/qcReportApply/queryPage.do"), 
};
export default qcReportApply;