/**
 * 行政区划管理
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    getAllRoot:new APIObject("/api/area/getAllRoot.do"),// 查询所有-省级根节点
    delete:new APIObject("/api/area/delete.do"),// 删除-单条
    queryOne:new APIObject("/api/area/queryOne.do"),// 查询-单条
    queryPage:new APIObject("/api/area/queryPage.do"),// 查询-分页
    saveOrUpd:new APIObject("/api/area/saveOrUpd.do"),// 保存/修改-单条
    queryAll:new APIObject("/api/area/queryAll.do"),// 查询-所有数据
    batchDelete:new APIObject("/api/area/batchDelete.do"),// 删除-批量
    getAllTree:new APIObject("/api/area/getAllTree.do"),// 查询所有-树形结构（全国行政区划树形结构）

};

export default api;